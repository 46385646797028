import classNames from 'classnames';
import React from 'react';
import { createPortal } from 'react-dom';
import { Buttons, MUIcon } from '../index';

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  isVisible?: boolean;
  okLabel?: string;
  okClassNames?: string;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  hideActions?: boolean;
  cancelLabel?: string;
  hideCancel?: boolean;
  cancelClassNames?: string;
  className?: string;
  onDone?: () => void;
  onCloseRequest?: () => void;
  onCancel?: () => void;
  titleClasses?: string;
  actionClasses?: string;
  additionalButtons?: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  title,
  children,
  okDisabled = false,
  cancelDisabled = false,
  hideActions = false,
  isVisible = true,
  okClassNames,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  hideCancel = false,
  cancelClassNames,
  onDone,
  onCloseRequest,
  onCancel,
  className,
  titleClasses,
  actionClasses,
  additionalButtons,
}) => {
  if (!isVisible) return null;

  return createPortal(
    <div
      data-te-modal-init
      className="fixed left-0 top-0 z-[1545455675] h-full w-full overflow-y-auto overflow-x-hidden bg-black/60 outline-none backdrop-opacity-60 backdrop-saturate-100"
      aria-modal="true"
      role="dialog"
    >
      <div
        data-te-modal-dialog-ref
        className={`pointer-events-none relative flex max-h-[64rem] min-h-[calc(100%-1rem)] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[1024px] ${className}`}
      >
        <div className="pointer-events-auto relative flex w-full flex-col rounded-[8px] border-none bg-white bg-clip-padding text-current shadow-2xl outline-none">
          <div
            className={`flex flex-shrink-0 items-start justify-between gap-6 rounded-t-md px-6 pt-6 ${titleClasses}`}
          >
            <div className="truncate font-semibold text-gray-800">{title}</div>
            {onCloseRequest && (
              <Buttons.Secondary
                onClick={(event) => {
                  onCloseRequest?.();
                  event.preventDefault();
                  event.stopPropagation();
                }}
                disabled={cancelDisabled}
                size={'xs'}
                className={'bg-transparent shadow-none ring-0'}
                icon={<MUIcon name="close" />}
              />
            )}
          </div>
          <div className="relative py-4 px-6">{children}</div>
          {!hideActions && (
            <div
              className={`flex flex-shrink-0 flex-wrap items-center justify-between gap-2 rounded-b-md border-t border-gray-200 border-neutral-200 border-opacity-100 py-4 px-6 ${actionClasses}`}
            >
              <div>{additionalButtons}</div>
              <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 self-end">
                {!hideCancel && (
                  <Buttons.Secondary
                    disabled={cancelDisabled}
                    onClick={(event) => {
                      if (onCancel) {
                        onCancel();
                      } else {
                        onCloseRequest?.();
                      }
                      event.preventDefault();
                    }}
                    size={'lg'}
                    className={classNames(cancelClassNames)}
                    data-cy={'modal-cancel-button'}
                  >
                    {cancelLabel}
                  </Buttons.Secondary>
                )}
                <Buttons.Primary
                  disabled={okDisabled}
                  onClick={(event) => {
                    onDone?.();
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  size={'lg'}
                  className={classNames(okClassNames)}
                  data-cy={'modal-ok-button'}
                >
                  {okLabel}
                </Buttons.Primary>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
