import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { generateLink } from '../../../LinkGenerator';
import * as MenuTypes from './Menu/menu.types';
import useCopyOption from './Menu/useCopyOption';
import useDeleteOption from './Menu/useDeleteOption';
import useRenameOption from './Menu/useRenameOption';

interface Props {
  dataset: PeTypes.DatasetListItem;
}

const MoreMenu: React.FC<Props> = ({ dataset }) => {
  const [action, setAction] = useState<MenuTypes.MenuOption | null>(null);
  const handleCloseRequest = () => setAction(null);

  const [renameOption, renameModal] = useRenameOption(dataset.id, dataset.name, action, handleCloseRequest);
  const [copyOption, copyModal] = useCopyOption(dataset.id, dataset.name, action, handleCloseRequest);
  const [deleteOption, deleteModal] = useDeleteOption(dataset.id, action, handleCloseRequest);

  const DROPDOWN_OPTIONS: Dropdowns.DropdownOption[] = [
    // publishOption,
    renameOption,
    copyOption,
    deleteOption,
    {
      value: MenuTypes.MenuOption.OPEN_IN_NEW_TAB,
      name: 'Open in new tab',
      icon: <MUIcon name="open_in_new" />,
      additionalStyles: 'text-sm border-t border-gray-200',
    },
  ];

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    if (opt.value === MenuTypes.MenuOption.OPEN_IN_NEW_TAB) {
      window.open(generateLink('dataset', { workspaceId: dataset.ownerId, datasetId: dataset.id }), '_blank');
    }
    setAction(opt.value as MenuTypes.MenuOption);
  };

  return (
    <div className="relative">
      <Dropdowns.Dropdown
        icon={<MUIcon name="more_horiz" className="text-gray-600" aria-hidden="false" />}
        iconClose={null}
        options={DROPDOWN_OPTIONS}
        onSelect={onSelectDropdownOption}
        hAlignment="right"
        menuClasses="w-[256px]"
        triggerClasses="ring-0 rounded-full p-2 shadow-none border border-gray-200"
      />
      {deleteModal}
      {renameModal}
      {copyModal}
    </div>
  );
};

export default MoreMenu;
