import { ButtonGroup } from '@platform/shared/ui';
import React, { useEffect } from 'react';

const MAP_ALIASES = ['Left', 'Right'];

export interface Props {
  activeMapId?: string;
  onChange: (id: string) => void;
  options: {
    mapId: string;
    title?: string;
    disabled?: boolean;
  }[];
  fallback?: React.ReactNode;
  onToggleHighlight?: (value: boolean) => void;
}

const ActiveMapSwitcher: React.FC<Props> = ({ options, onChange, onToggleHighlight, activeMapId, fallback }) => {
  useEffect(() => {
    if (!onToggleHighlight) return;
    onToggleHighlight(true);
    return () => {
      onToggleHighlight(false);
    };
  }, [onToggleHighlight]);

  // if only map available, there is no need for the switch
  if (fallback) {
    return <div className="-mx-4 -mb-3 bg-gray-100 px-6 py-3">{fallback}</div>;
  }
  // if less then two options available, there is no need for the switch
  if (options.length < 2 || options.filter((x) => !x.disabled).length === 1) {
    return null;
  }

  const buttonGroupOptions = options.map((opt, idx) => ({
    value: opt.mapId,
    label: opt.title ?? MAP_ALIASES[idx],
    disabled: opt.disabled,
  }));

  return (
    <div className="flex items-center gap-4">
      <span>Map</span>
      <ButtonGroup options={buttonGroupOptions} value={activeMapId} onChange={(v) => onChange(v as string)} />
    </div>
  );
};

export default ActiveMapSwitcher;
