import { DatasetTypes } from '@platform/types';
import React from 'react';
import { MUIcon } from '../index';
// TODO
// import { getSuffixIcon } from '../../Datasets/Dataset/DatasetTable/utils';

interface Props {
  title: string;
  type: string | DatasetTypes.DataType;
  handleColumnChange: () => void;
  handleRemoveRule: () => void;
}

const FilterHeader: React.FC<Props> = ({ title, type, handleColumnChange, handleRemoveRule }) => {
  return (
    <div className="flex w-full items-center justify-between gap-8">
      <button className="flex items-center gap-2 text-left" onClick={() => handleColumnChange()}>
        {/*{getSuffixIcon(type)}*/}
        <span className="text-sm">{title}</span>
      </button>
      <button className="flex items-center" onClick={() => handleRemoveRule()}>
        <MUIcon name="close" className="text-[20px] text-gray-500" />
      </button>
    </div>
  );
};

export default FilterHeader;
