import { PeTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React from 'react';
import BaseError from './BaseError';

interface Props {
  workspaceStatistics: PeTypes.WorkspaceStatistics;
}

const GeocodesError: React.FC<Props> = ({ workspaceStatistics }) => {
  const availableGeocodes = workspaceStatistics.quota.hereGeocoderQuota - workspaceStatistics.quota.hereGeocoderUsed;
  return (
    <BaseError>
      <div className="flex items-center justify-between gap-2">
        <p>
          <span className="font-semibold">Limit reached.</span>
          {` The remaining geocoding credit for this workspace is ${ValueFormatter.format(
            availableGeocodes,
            ProjectTypes.ValueFormat.FORMAT_NUMBER
          )} records, and unfortunately,
          we cannot process all of the records provided. Please reach out to us to discuss exceeding the geocoding limit
          or consider reducing the number of records in your file before attempting to geocode again.`}
        </p>
      </div>
    </BaseError>
  );
};

export default GeocodesError;
