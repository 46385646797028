import { AbacusTypes } from '@platform/types';
import { useQuery } from 'react-query';

interface Props {
  datasetId: string;
  request: AbacusTypes.SummarizeRequest;
  api: (datasetId: string, request: AbacusTypes.SummarizeRequest) => Promise<AbacusTypes.SummarizeResult>;
}

const useAbacusSummarization = ({ api, request, datasetId }: Props) =>
  useQuery(['abacus-summary', datasetId, request], () => api(datasetId, request));

export default useAbacusSummarization;
