import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from '../../contexts/SessionContext';
import * as peApi from '../../pe.api';

interface Props {
  member: PeTypes.WorkspaceMember;
  activeWorkspace: PeTypes.Workspace;
  isAdmin: boolean;
}

enum ACTIONS {
  REMOVE,
  DEMOTE,
  PROMOTE,
  LEAVE,
}

const WorkspaceMember: React.FC<Props> = ({ member, activeWorkspace, isAdmin }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const session = useSessionContext();

  const invalidateMember = async () => {
    await queryClient.invalidateQueries({ queryKey: ['workspace-members', activeWorkspace.id] });
  };

  const removeUserMutation = useMutation({
    mutationFn: async (userId: number) => peApi.removeUser(userId),
    onSuccess: () => {
      invalidateMember();
    },
  });

  const promoteUserMutation = useMutation({
    mutationFn: async (userId: number) => peApi.promoteUser(userId),
    onSuccess: () => {
      invalidateMember();
    },
  });

  const demoteUserMutation = useMutation({
    mutationFn: async (userId: number) => peApi.demoteUser(userId),
    onSuccess: () => {
      invalidateMember();
    },
  });

  const leaveWorkspaceMutation = useMutation({
    mutationFn: async (userId: number) => peApi.removeUser(userId),
    onSuccess: async () => {
      await invalidateMember();
      await queryClient.invalidateQueries({ queryKey: ['pe-datahub-groups'] });
      navigate('/');
    },
  });

  const dropdownOptions = [];
  if (activeWorkspace.isAdmin) {
    if (member.isAdmin) {
      dropdownOptions.push({ value: ACTIONS.DEMOTE, name: 'Demote to editor' });
    } else {
      dropdownOptions.push({ value: ACTIONS.PROMOTE, name: 'Promote to admin' });
    }
    if (member.id !== session.user?.id) {
      dropdownOptions.push({ value: ACTIONS.REMOVE, name: 'Remove from workspace' });
    } else {
      dropdownOptions.push({ value: ACTIONS.LEAVE, name: 'Leave workspace' });
    }
  }

  const dropdownOptions2 = [{ value: ACTIONS.LEAVE, name: 'Leave workspace' }];

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case ACTIONS.REMOVE:
        removeUserMutation.mutate(member.id);
        break;
      case ACTIONS.PROMOTE:
        promoteUserMutation.mutate(member.id);
        break;
      case ACTIONS.DEMOTE:
        demoteUserMutation.mutate(member.id);
        break;
      case ACTIONS.LEAVE:
        leaveWorkspaceMutation.mutate(member.id);
        break;
    }
  };

  return (
    <div className="flex w-full items-center justify-between border-b border-gray-200 py-5 px-3">
      <div className="flex gap-4">
        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-[999px] bg-gray-200">
          <img
            className="h-[48px] w-[48px] rounded-[999px]"
            src={`${member.pictureLargeURL}?${member.updatedAt}`}
            alt={''}
          ></img>
        </div>
        <div className="flex flex-col gap-1 py-1">
          <div className="flex gap-1 text-sm font-semibold text-gray-900">
            <span>{member.firstName}</span>
            <span>{member.lastName}</span>
          </div>
          <span className="text-xs text-gray-500">{member.email}</span>
        </div>
      </div>
      <div>
        {isAdmin && (
          <Dropdowns.Dropdown
            icon={<MUIcon name="more_horiz" className="text-gray-600" aria-hidden="false" />}
            iconClose={null}
            options={dropdownOptions}
            onSelect={onSelectDropdownOption}
            hAlignment="right"
            triggerClasses="p-2 border-gray-200 rounded-full"
          />
        )}
      </div>
      {member.id === session.user?.id && !isAdmin && (
        <Dropdowns.Dropdown
          icon={<MUIcon name="more_horiz" className="text-gray-600" aria-hidden="false" />}
          iconClose={null}
          options={dropdownOptions2}
          onSelect={onSelectDropdownOption}
          hAlignment="right"
          triggerClasses="p-2 border-gray-200 rounded-full"
        />
      )}
    </div>
  );
};

export default WorkspaceMember;
