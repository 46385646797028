import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../../../contexts/WorkspaceContext';
import { generateLink } from '../../../../LinkGenerator';
import * as peApi from '../../../../pe.api';
import DeleteConfirmationModal from '../../../shared/DeleteConfirmationModal';
import * as MenuTypes from './menu.types';

const useDeleteOption = (
  datasetId: string,
  action: number | null,
  handleCloseRequest: () => void,
  softDelete = false
): [Dropdowns.DropdownOption, JSX.Element | null] => {
  const option = {
    value: MenuTypes.MenuOption.DELETE,
    name: 'Delete',
    icon: <MUIcon name="delete" />,
    additionalStyles: 'text-sm',
  };

  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspaceContext();

  const deleteDatasetMutation = useMutation({
    mutationFn: () => peApi.deleteDataset(datasetId, softDelete),
    onSuccess: () => {
      navigate(generateLink('datasets', { workspaceId: activeWorkspace.id }));
    },
  });
  const onDeleteDataset = () => {
    deleteDatasetMutation.mutate();
    handleCloseRequest();
  };

  const modal =
    action === MenuTypes.MenuOption.DELETE ? (
      <DeleteConfirmationModal
        title="Delete dataset"
        message={`Are you sure you want to delete this dataset? All data will be permanently removed. This action cannot be undone.`}
        onConfirm={onDeleteDataset}
        onCloseRequest={handleCloseRequest}
      />
    ) : null;

  return [option, modal];
};

export default useDeleteOption;
