import { useCallback, useEffect, useState } from 'react';

type OnValueChange = (value: string) => void;

const useFunctionDebounce = (onValueChange: OnValueChange, debounceDelay = 300) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  const debouncedOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          onValueChange(e.target.value);
        }, debounceDelay)
      );
    },
    [onValueChange, debounceDelay, timer]
  );

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  return debouncedOnChange;
};

export default useFunctionDebounce;
