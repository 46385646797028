import { RuleGroupType } from 'react-querybuilder';

export enum DataType {
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  TEXT = 'VARCHAR',
  BOOLEAN = 'BOOLEAN',
}

export interface Column {
  name: string;
  title: string;
  type: 'BOOLEAN' | 'VARCHAR' | 'INTEGER' | 'DOUBLE';
  allowFilter: boolean;
  metadata: {
    values: string[];
  };
}

export interface Data {
  data: Record<string, string>[];
}

export interface CountDatasetRowsRequest {
  dataQuery?: RuleGroupType;
}

export interface Metadata {
  name: string;
  datasets: Dataset[];
}

export interface Dataset {
  name: string;
  recordLabel: string;
  variables: Variable[];
}

export interface Variable {
  name: string;
  label: string;
  dataType: string;
  sqlDataType: 'BOOLEAN' | 'VARCHAR' | 'INTEGER' | 'DOUBLE';
  formatting: string;
  showInFiltersList: boolean;
  showInSummarizeByList: boolean;
  showTopValuesByFrequency: boolean;
  descriptiveStats: DescriptiveStats;
  valueLabels: any[];
}

export interface DescriptiveStats {
  nullValueCount: number;
  nonNullValueCount: number;
  uniqueValueCount: number;
  mode: string;
  mean?: number;
  median?: number;
  minimum?: number;
  maximum?: number;
  range?: number;
  variance?: number;
  standardDeviation?: number;
  firstQuartile?: number;
  thirdQuartile?: number;
  interQuartileRange?: number;
  topValuesByFrequency: TopValuesByFrequency[];
}

export interface TopValuesByFrequency {
  value: string;
  count: number;
}

export interface SummarizeRequest {
  spec: SummarizePayload;
  dataQuery?: RuleGroupType;
}

export interface SummarizeResult {
  data: [Record<string, string>];
  durationInMs: number;
}

export interface SummarizeTable {
  name: string;
  title: string;
  variables: {
    name: string;
    label: string;
    indent: string;
    formatting: string;
    valueSql: string;
  }[];
}

export interface SummarizePayload {
  universe?: {
    filter: string;
    label?: string;
  };
  geoFilter?: {
    value: string;
  };
  groupBy?: {
    value: string;
  };
  orderBy?: {
    value: string;
  };
  tables?: SummarizeTable[];
}

export interface DownloadAbacusDataRequest {
  columnNames: string[];
  name?: string;
  dataQuery?: RuleGroupType;
}

export interface DataRequest {
  columnNames: string[];
  limit?: number;
  offset?: number;
  whereClause?: string;
}

export interface AbacusInputMetadata {
  Name: string;
  Datasets: [
    {
      Name: string;
      RecordLabel: string;
      Variables: {
        Name: string;
        ShowInFiltersList: boolean;
        Format?: string;
        Label?: string;
      }[];
    }
  ];
}
