import { PanelHeader } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { useDatasetColumns } from '../../../../hooks';
import ColumnPicker from '../ColumnPicker';
import { SelectionType } from '../ColumnPicker/types';

interface Props {
  dataset: PeTypes.Dataset;
  onSelect: (colKey: string) => void;
  onCloseRequest: () => void;
}

const ColumnPickerPanel: React.FC<Props> = ({ dataset, onSelect, onCloseRequest }) => {
  const columnsQuery = useDatasetColumns({ datasetId: dataset.id, itemsPerPage: dataset.numberOfColumns ?? 999999 });

  const isLoading = columnsQuery.isIdle || columnsQuery.isLoading;
  const isError = columnsQuery.isError;

  const handleColumnSelectionChange = (payload: SelectionType | SelectionType[]) => {
    const [column] = payload as SelectionType;
    onSelect(column.uuid);
  };

  return (
    <div className="flex h-full w-full flex-col">
      <PanelHeader title="Select column" onBack={onCloseRequest} />
      {isLoading && <>Loading data... </>}
      {isError && <>Something went wrong</>}
      {columnsQuery.data && (
        <ColumnPicker columns={columnsQuery.data} datasetId={dataset.id} onChange={handleColumnSelectionChange} />
      )}
    </div>
  );
};

export default ColumnPickerPanel;
