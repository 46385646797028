import React, { ReactNode, useEffect, useState } from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type TabStyle = 'button' | 'classic';

export interface TabsProps {
  key: string;
  label: string;
  children: ReactNode;
  icon?: JSX.Element;
  tabStyle?: TabStyle;
  actions?: React.ReactNode;
}

interface Props {
  tabProps: TabsProps[];
  defaultSelectedKey?: string;
  tabStyle?: TabStyle;
}

const Tabs: React.FC<Props> = ({ tabProps, defaultSelectedKey, tabStyle = 'classic' }) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(defaultSelectedKey ?? tabProps[0]?.key);
  const indexOfActiveTab = tabProps.findIndex((tab) => tab.key === activeTab);

  useEffect(() => {
    // tabs are dynamic so internal state has to adapt if a tab which was active has been removed from props
    const activeTabStillInProps = tabProps.find((x) => x.key === activeTab);
    if (!activeTabStillInProps) setActiveTab(defaultSelectedKey ?? tabProps[0]?.key);
  }, [setActiveTab, activeTab, defaultSelectedKey, tabProps]);

  return (
    <div className="flex h-full min-h-0 flex-grow flex-col">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="focus:border-primary-500 focus:ring-primary-500 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm"
        >
          {tabProps.map((tab) => (
            <option key={tab.key}>{tab.label}</option>
          ))}
        </select>
      </div>
      <div className="hidden min-h-0 flex-grow flex-col sm:flex">
        <div className="flex items-center justify-between px-4">
          <nav className="flex" aria-label="Tabs">
            {tabProps.map((tab) => (
              <div onClick={() => setActiveTab(tab.key)} key={tab.key}>
                {tabStyle === 'button' ? (
                  <TabButton tab={tab} active={tab.key === activeTab} />
                ) : (
                  <TabClassic tab={tab} active={tab.key === activeTab} />
                )}
              </div>
            ))}
          </nav>
          <div>{tabProps[indexOfActiveTab]?.actions}</div>
        </div>
        <div className="flex flex-grow flex-col overflow-auto">{tabProps[indexOfActiveTab]?.children}</div>
      </div>
    </div>
  );
};
export default Tabs;

const TabButton: React.FC<{ tab: TabsProps; active: boolean }> = ({ tab, active }) => (
  <div
    className={classNames(
      active ? 'bg-primary-100 text-primary-700 ' : 'hover:bg-gray-100',
      'mb-3 cursor-pointer select-none rounded-md py-1.5 px-2.5 text-gray-600 transition-all '
    )}
  >
    {tab.icon}
    <span>{tab.label}</span>
  </div>
);

const TabClassic: React.FC<{ tab: TabsProps; active: boolean }> = ({ tab, active }) => (
  <div
    className={classNames(
      active
        ? 'border-primary-500 text-primary-600'
        : 'border-transparent text-gray-500 hover:cursor-pointer hover:border-gray-300 hover:text-gray-700',
      'flex select-none items-center justify-center gap-1 whitespace-nowrap border-b-2 py-4 px-2.5 text-sm font-bold'
    )}
  >
    {tab.icon}
    <span>{tab.label}</span>
  </div>
);
