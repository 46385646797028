import { Buttons } from '@platform/shared/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStory } from '../../hooks';
import ErrorPage from '../shared/ErrorPage';
import Thumb from '../Thumb';

const { Button } = Buttons;

interface Props {
  storyId: string;
}

const Story: React.FC<Props> = ({ storyId }) => {
  const { storyQuery, slidesQuery } = useStory(storyId);

  const isLoading = storyQuery.isIdle || storyQuery.isLoading || slidesQuery.isIdle || slidesQuery.isLoading;
  const isError = storyQuery.isError || slidesQuery.isError;

  if (isLoading) return <>Loading Story</>;
  if (isError) return <ErrorPage />;

  const { title, description, slidesCount } = storyQuery.data;
  const slides = slidesQuery.data;

  return (
    <div className="flex w-full flex-col gap-10">
      <div className="flex gap-8">
        <div className="flex flex-col gap-2">
          <div className="text-3xl font-semibold">{title}</div>
          <div className="text-sm text-gray-500">{`${slidesCount} Slide(s)`}</div>
        </div>
        <div className="flex  gap-2">
          <Link to={`view`}>
            <Button>Play</Button>
          </Link>
          <Link to={`edit`}>
            <Button>Edit</Button>
          </Link>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="w-full max-w-prose text-base">
          {description ?? <span className="text-sm italic">Description not provided</span>}
        </div>
        <div className="flex flex-col gap-5">
          {slides.map((item) => (
            <div key={item.id} className="flex gap-4">
              <Thumb url={item.thumbPath} className={'h-[250px] w-[350px]'} />
              <div className="mt-2 flex flex-col gap-2" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Story;
