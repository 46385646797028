import { Dropdowns } from '@platform/shared/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from '../../contexts/SessionContext';
import { generateLink } from '../../LinkGenerator';

interface Props {
  id: number;
}
const SimpleWorkspaceSwitcher: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const [activeWorkspaceId, setActiveWorkspaceId] = React.useState<number>(id);

  const { workspaces } = useSessionContext();

  const handleWorkspaceChange = (workspaceId: number) => {
    setActiveWorkspaceId(workspaceId);
    const navigateTo = generateLink('workspace', { workspaceId: workspaceId });

    navigate(navigateTo);
  };

  const options: Dropdowns.DropdownOption[] = workspaces.map((workspace) => ({
    value: workspace.id,
    name: workspace.name,
    icon: (
      <img
        src={`${workspace.imageURL}?${workspace.updatedAt}`}
        alt={workspace.name}
        className="h-[32px] w-[32px] rounded-md"
      />
    ),
  }));

  return (
    <Dropdowns.Dropdown
      options={options}
      value={activeWorkspaceId}
      menuWidth="full"
      triggerLabelClasses="flex items-center"
      triggerClasses="w-full flex items-center hover:bg-gray-100 border border-gray-300 ring-0"
      listClasses="max-h-[280px]"
      onSelect={(opt) => handleWorkspaceChange(opt.value as number)}
    />
  );
};

export default SimpleWorkspaceSwitcher;
