import React, { useCallback, useContext, useState } from 'react';

interface ContextState {
  activeView: string | undefined;
  changeActiveView: (v: string | undefined) => void;
}

const DEFAULT_VALUE: ContextState = {
  activeView: undefined,
  changeActiveView: (_: string | undefined) => ({}),
};

const MapSideBarContext = React.createContext<ContextState>(DEFAULT_VALUE);

interface Props {
  children: React.ReactNode;
  defaultView?: string;
}

export const MapSideBarContextProvider: React.FC<Props> = ({ defaultView, children }) => {
  const [activeView, setActiveView] = useState<string | undefined>(defaultView);

  const changeActiveView = useCallback((newV: string | undefined) => {
    setActiveView((oldV) => (oldV === newV ? undefined : newV));
  }, []);

  return (
    <MapSideBarContext.Provider
      value={{
        activeView,
        changeActiveView,
      }}
    >
      {children}
    </MapSideBarContext.Provider>
  );
};

export const useMapSidebarContext = (): ContextState => {
  const context = useContext(MapSideBarContext);
  if (!context) {
    throw new Error(`useMapSideBarContext must be used within a MapSideBarContextProvider`);
  }
  return context;
};
