import { Inputs } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onChange: (values: number[]) => void;
}

const WorkingLayerOpacity: React.FC<Props> = ({ workingLayer, onChange }) => {
  const { opacities } = workingLayer;
  const [primaryOpacity, secondaryOpacity] = opacities;

  const opacityHandler = (value: number, key: string) => {
    const opacityValue = Math.max(0, Math.min(value / 100, 1));
    const primary = key === 'primary' ? opacityValue : primaryOpacity;
    const secondary = key === 'secondary' ? opacityValue : secondaryOpacity;
    onChange([primary, secondary]);
  };

  return (
    <div className="flex w-full flex-col py-4">
      <div className="flex items-center justify-between gap-5 text-gray-600">
        <div className="flex w-full justify-between ">
          <div className="flex basis-1/5">
            <span className="text-sm">Fill</span>
          </div>
          <div className="flex basis-4/5 flex-wrap gap-1.5 py-1">
            <Inputs.Slider
              min={0}
              max={1}
              step={0.01}
              value={primaryOpacity}
              className="w-full"
              onChange={(value: number) => onChange([value, secondaryOpacity])}
            />
          </div>
        </div>
        <div className="flex rounded-lg border border-gray-200 p-2">
          <Inputs.DebouncedInput
            debounceValue={150}
            inputClassNames="grow max-w-[45px] text-left p-0 px-2 text-sm focus:outline-none focus:ring-0"
            defaultValue={Math.round(primaryOpacity * 100)}
            valueSubmitHandler={(value) => opacityHandler(value as number, 'primary')}
            className={'flex max-w-[200px] items-center gap-0.5 text-sm'}
            type="number"
          />
          <span className="pr-2">%</span>
        </div>
      </div>

      <div className="flex w-full flex-col gap-6 py-4">
        <div className="flex items-center justify-between gap-5 text-gray-600 ">
          <div className="flex w-full justify-between">
            <div className="flex basis-1/5">
              <span className="text-sm">Border</span>
            </div>
            <div className="flex basis-4/5 flex-wrap gap-1.5 py-1">
              <Inputs.Slider
                min={0}
                max={1}
                step={0.01}
                value={secondaryOpacity}
                className="w-full"
                onChange={(value: number) => onChange([primaryOpacity, value])}
              />
            </div>
          </div>
          <div className="flex rounded-lg border border-gray-200 p-2">
            <Inputs.DebouncedInput
              debounceValue={150}
              inputClassNames="grow max-w-[45px] text-left p-0 px-2 text-sm focus:outline-none focus:ring-0"
              defaultValue={Math.round(secondaryOpacity * 100)}
              valueSubmitHandler={(value) => opacityHandler(value as number, 'secondary')}
              className={'flex max-w-[200px] items-center gap-0.5 text-sm'}
              type="number"
            />
            <span className="pr-2">%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingLayerOpacity;
