import React from 'react';
import { Outlet } from 'react-router-dom';

const FullScreen: React.FC = () => {
  return (
    <div className="flex h-full flex-col">
      <Outlet />
    </div>
  );
};

export default FullScreen;
