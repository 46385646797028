import React from 'react';
import Snackbar from './Snackbar';
import SnackbarActionButton from './SnackbarActionButton';
import useSnackbars from './useSnackbars';

export default Snackbar;

export interface INotification {
  content: React.ReactNode;
  action?: React.ReactNode;
  icon?: React.ReactNode;
  hideAfterSec?: number;
  dismissible?: boolean;
}

export { SnackbarActionButton, useSnackbars };
