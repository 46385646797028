import { PanelHeader } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import EditSlideNote from '../Stories/Edit/EditSlideNote';
import PreviewSlideNote from '../Stories/PreviewSlideNote';

interface Props {
  currentIndex: number;
  slide: PeTypes.StorySlide;
  isEditMode: boolean;
}

const SlideStory: React.FC<Props> = ({ slide, currentIndex, isEditMode }) => {
  return (
    <div className="flex h-full w-[460px] flex-col">
      <PanelHeader title={isEditMode ? `Slide ${currentIndex + 1}` : ''} />
      {isEditMode ? (
        <EditSlideNote slide={slide} />
      ) : (
        <div className="flex h-full w-full overflow-auto p-4">
          <PreviewSlideNote content={slide.text} />
        </div>
      )}
    </div>
  );
};

export default SlideStory;
