import { MapsContext } from '@platform/maplibre';
import { MUIcon } from '@platform/shared/ui';
import React, { useEffect, useState } from 'react';
import { useSessionContext } from '../../../contexts/SessionContext';
import { hasText } from '../../../helpers/helpers';
import { useStory } from '../../../hooks';
import * as peApi from '../../../pe.api';
import SeProMaps from '../../Map/SeProMaps';
import Analysis from '../../MapSidebar/Analysis';
import MapSideBar from '../../MapSidebar/MapSideBar';
import { MapSideBarContextProvider } from '../../MapSidebar/MapSideBarContext';
import SlideStory from '../../MapSidebar/SlideStory';
import { Loader } from '../../shared';
import ErrorPage from '../../shared/ErrorPage';
import SlideNavi from '../SlideNavi';

interface Props {
  storyId: string;
  defaultSlideId?: string;
}

const PublicStory: React.FC<Props> = ({ storyId, defaultSlideId }) => {
  const [currentSlideId, setCurrentSlideId] = useState<string | undefined>(defaultSlideId);
  const { storyQuery, slidesQuery } = useStory(storyId);
  const { jwtToken } = useSessionContext();

  useEffect(() => {
    if (slidesQuery.data) {
      setCurrentSlideId(slidesQuery.data[0]?.id);
    }
  }, [slidesQuery.data]);

  const isLoading = storyQuery.isIdle || storyQuery.isLoading || slidesQuery.isIdle || slidesQuery.isLoading;
  const isError = storyQuery.isError || slidesQuery.isError;

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  const slides = slidesQuery.data;
  const currentIndex = slides.findIndex((x) => x.id === currentSlideId);
  const currentSlide = slides.find((x) => x.id === currentSlideId);

  if (!currentSlide) {
    return <ErrorPage />;
  }

  const handleMoveToPrevSlideRequest = () => {
    const prevIndex = Math.max(0, currentIndex - 1);
    setCurrentSlideId(slides[prevIndex].id);
  };

  const handleMoveToNextSlideRequest = () => {
    const nextIndex = Math.min(currentIndex + 1, slides.length - 1);
    setCurrentSlideId(slides[nextIndex].id);
  };

  const handleJumpToSlideRequest = (sId: string) => setCurrentSlideId(sId);

  const story = storyQuery.data;

  return (
    <MapsContext.MapsContextProvider
      mapContextId={currentSlide.mapContextId}
      jwtToken={jwtToken}
      doSave={false}
      apis={{
        mapContext: {
          get: peApi.getMapContext,
          update: peApi.patchMapContextItems,
        },
        baseMaps: { get: peApi.getBaseMapStyle },
        dataSourceLayers: { get: peApi.getDataSourceLayers },
      }}
    >
      <div className="flex h-full w-full">
        <MapsContext.MapsConsumer>
          {({ state }) => {
            const mapSideBarOptions = [];

            if (hasText(currentSlide.text)) {
              mapSideBarOptions.push({
                icon: <MUIcon name="article" className="text-[24px]" />,
                title: 'Story',
                key: 'SLIDE_STORY',
                content: <SlideStory slide={currentSlide} isEditMode={false} currentIndex={currentIndex} />,
              });
            }

            const anyMapHasGeoSelection = state.registeredMaps.some((x) => !!x.styleSpec.geoFeatureSelection?.length);
            if (anyMapHasGeoSelection) {
              mapSideBarOptions.push({
                icon: <MUIcon name="monitoring" className="text-[24px]" />,
                title: 'Analysis',
                key: 'ANALYSIS',
                content: <Analysis isEditMode={false} />,
              });
            }

            return (
              <MapSideBarContextProvider defaultView={mapSideBarOptions[0]?.key}>
                <MapSideBar options={mapSideBarOptions} sidebarType="STORY">
                  <div className="flex flex-grow flex-col justify-end">
                    <SlideNavi
                      isEditMode={false}
                      currentSlide={currentSlide}
                      story={story}
                      currentIndex={currentIndex}
                      totalCount={story.slidesCount}
                      onNext={handleMoveToNextSlideRequest}
                      onPrev={handleMoveToPrevSlideRequest}
                      onJumpTo={handleJumpToSlideRequest}
                    />
                  </div>
                </MapSideBar>
              </MapSideBarContextProvider>
            );
          }}
        </MapsContext.MapsConsumer>
        <MapsContext.MapsConsumer>
          {({ state }) => {
            if (!state.registeredMaps.length) return null;
            return (
              <div className="relative h-full w-full">
                <SeProMaps mapObjects={state.registeredMaps} type={state.type} inEditMode={false} />
              </div>
            );
          }}
        </MapsContext.MapsConsumer>
      </div>
    </MapsContext.MapsContextProvider>
  );
};

export default PublicStory;
