import { AbacusTypes } from '@platform/types';
import { UseQueryOptions } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import useAbacusRowsCount from './useAbacusRowsCount';

interface Props {
  datasetId: string;
  api: (datasetId: string, payload: AbacusTypes.CountDatasetRowsRequest) => Promise<number>;
  dataQuery?: RuleGroupType;
  baseQuery?: RuleGroupType;
  options?: UseQueryOptions<number>;
}

const useHintsCount = ({
  datasetId,
  dataQuery,
  baseQuery,
  options,
  api,
}: Props): {
  total: number | null;
  hits: number | null;
  isError: boolean;
  isLoading: boolean;
} => {
  const totalCountQuery = useAbacusRowsCount({ api, datasetId, options, dataQuery: baseQuery });
  const hitsCountQuery = useAbacusRowsCount({
    api,
    datasetId,
    dataQuery,
    options: {
      enabled: (options?.enabled ?? true) && dataQuery != null,
    },
  });

  const isLoading =
    options?.enabled !== false &&
    (totalCountQuery.isIdle || totalCountQuery.isLoading || hitsCountQuery.isIdle || hitsCountQuery.isLoading);

  const isError = totalCountQuery.isError || hitsCountQuery.isError;

  return {
    isError,
    isLoading,
    hits: hitsCountQuery?.data != null ? hitsCountQuery.data : null,
    total: totalCountQuery?.data != null ? totalCountQuery.data : null,
  };
};

export default useHintsCount;
