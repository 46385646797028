import { PeTypes } from '@platform/types';
import React from 'react';
import { usePeUser } from '../../hooks';
import { UpdatedAgo } from '../shared';

type Props = {
  userId: number;
  status: PeTypes.DatasetLogStatus | PeTypes.DatasetImportStatus;
  createdAt: Date;
};

const Details: React.FC<Props> = ({ userId, status, createdAt }: Props) => {
  const userQuery = usePeUser(userId);

  let timeInfo;
  switch (status) {
    case PeTypes.DatasetLogStatus.COMPLETED:
      timeInfo = <UpdatedAgo prefixLabel="" dateTime={createdAt} />;
      break;
    case PeTypes.DatasetLogStatus.PENDING:
      timeInfo = <span>Pending</span>;
      break;
    case PeTypes.DatasetLogStatus.RUNNING:
      timeInfo = <span>In progress</span>;
      break;
    case PeTypes.DatasetLogStatus.FAILED:
      timeInfo = <UpdatedAgo prefixLabel="" dateTime={createdAt} />;
      break;
  }

  return (
    <div className="flex items-center gap-1.5 text-xs text-gray-600">
      {timeInfo}
      <div className="h-[3px] w-[3px] rounded-full bg-gray-400" />
      <span>
        {userQuery.data?.firstName} {userQuery.data?.lastName}
      </span>
    </div>
  );
};

export default Details;
