import { Map as MapLibreMap, MapRequestTransformations, Utils } from '@platform/maplibre';
import { Buttons, MapComponents, MUIcon } from '@platform/shared/ui';
import { Helpers } from '@platform/utils';
import * as GeoJSON from 'geojson';
import { LayerSpecification, Map, RequestParameters, ResourceType, StyleSpecification } from 'maplibre-gl';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSessionContext } from '../../../../contexts/SessionContext';
import * as peApi from '../../../../pe.api';

const { ZoomControls } = MapComponents;
const INITIAL_ZOOM = 4;

interface Props {
  featureCollection: GeoJSON.FeatureCollection;
  baseMapId: string;
  note?: string;
}

const MaptilerGeoJsonMap: React.FC<Props> = ({ featureCollection, baseMapId, note }) => {
  const { jwtToken } = useSessionContext();
  const mapRef = useRef<Map | null>(null);

  const bounds = useMemo(() => {
    const calculatedBounds = Utils.calculateBounds(featureCollection);
    return calculatedBounds && Helpers.isValidLngLatBounds(calculatedBounds) ? calculatedBounds : null;
  }, [featureCollection]);

  const center = useMemo(() => {
    if (bounds) return Utils.calculateCenterOfBoundsLike(bounds);
    return { lat: 0, lng: 0 };
  }, [bounds]);

  const fitLayer = useCallback(() => {
    if (!bounds) return;
    mapRef.current?.fitBounds(bounds, { padding: 100, animate: true, duration: 300 });
  }, [bounds, mapRef]);

  useEffect(() => {
    fitLayer();
  }, [fitLayer, featureCollection]);

  const baseMapStyleQuery = useQuery(['se-basemaps', baseMapId], () => peApi.getBaseMapStyle(baseMapId));

  const mapTilesRequestTransformation = useCallback(
    (url: string, resourceType?: ResourceType): RequestParameters => {
      let transformedUrl;
      // check for non tile requests
      transformedUrl = MapRequestTransformations.nonTileRequests(url, resourceType);
      if (transformedUrl) return { url: transformedUrl };

      // check for map tiler requests
      transformedUrl = MapRequestTransformations.mapTilerTilesRequests(url);
      if (transformedUrl) return { url: transformedUrl, headers: { Authorization: `Bearer ${jwtToken}` } };

      return { url };
    },
    [jwtToken]
  );

  const mapStyle: StyleSpecification | null = useMemo(() => {
    if (!baseMapStyleQuery.data) return null;
    const { layers, sources } = baseMapStyleQuery.data;

    const updatedLayers = [...layers];
    const updatedSources = { ...sources };

    if (featureCollection.features.length) {
      const sourceId = 'sample-geo';
      updatedSources[sourceId] = {
        type: 'geojson',
        data: featureCollection,
      };

      let previewLayer: LayerSpecification;

      if (featureCollection.features[0].geometry.type === 'Point') {
        previewLayer = {
          id: sourceId,
          type: 'circle',
          source: sourceId,
          paint: {
            'circle-color': '#FF0000',
            'circle-radius': 6,
            'circle-stroke-width': 1,
            'circle-stroke-color': 'white',
          },
        };
      } else {
        previewLayer = {
          id: sourceId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': '#FF0000',
            'fill-opacity': 0.6,
            'fill-outline-color': 'white',
          },
        };
      }
      updatedLayers.push(previewLayer);
    }

    return {
      ...baseMapStyleQuery.data,
      layers: updatedLayers,
      sources: updatedSources,
    };
  }, [baseMapStyleQuery.data, featureCollection]);

  const handleMapMount = (map: Map) => {
    mapRef.current = map;
    fitLayer();
  };

  return (
    <div className="relative h-full w-full rounded-md bg-gray-200">
      {mapStyle ? (
        <>
          <MapLibreMap
            style={mapStyle}
            customTransformRequest={mapTilesRequestTransformation}
            zoom={INITIAL_ZOOM}
            center={center}
            maxZoom={15}
            onMount={handleMapMount}
            onUnmount={() => {
              mapRef.current = null;
            }}
          />
          {!bounds && (
            <div className="absolute top-5 left-5 rounded-md bg-red-200 px-3 py-2">
              <div className="flex items-center gap-2">
                <MUIcon name="warning" className="text-red-600" />
                <span className="text-sm">
                  The provided geographic data contains invalid latitude/longitude values and cannot be previewed
                </span>
              </div>
            </div>
          )}
          <div className="absolute bottom-5 right-5 flex flex-col gap-3">
            {bounds && (
              <Buttons.Secondary
                icon={<MUIcon name="center_focus_weak" />}
                className="h-[30px] w-[32px] rounded-lg"
                onClick={fitLayer}
              />
            )}
            <ZoomControls
              zoom={mapStyle.zoom ?? INITIAL_ZOOM}
              onZoomIn={() => mapRef.current?.zoomIn()}
              onZoomOut={() => mapRef.current?.zoomOut()}
            />
          </div>
          {note && (
            <div className="absolute bottom-0 left-0 rounded-tr-xl bg-white opacity-80">
              <p className="py-1 px-3 text-xs font-semibold">
                Note: <span className="font-normal">{note}</span>
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center text-gray-600">Loading map...</div>
      )}
    </div>
  );
};

export default MaptilerGeoJsonMap;
