import { PeTypes } from '@platform/types';
import React from 'react';
import { toHumanReadableFileSize } from '../../helpers/helpers';
import UsageVisualBreakdown, { VisualBreakdown } from './UsageVisualBreakdown';

interface Props {
  workspaceStatistics: PeTypes.WorkspaceStatistics;
}

enum chartColors {
  COLOR_DATABASE = '#fb8072',
  COLOR_TILES = '#7570b3',
  COLOR_ATTACHMENTS = 'rgb(98, 167, 226)',
  COLOR_VERSIONS = '#8dd3c7',
  COLOR_FREE_SPACE = '#efefef',
}

const WorkspaceStorage: React.FC<Props> = ({ workspaceStatistics }) => {
  const limitExceeded = workspaceStatistics.totalInBytes > workspaceStatistics.permission.maxStorage;
  const percentageDivider = limitExceeded
    ? workspaceStatistics.totalInBytes
    : workspaceStatistics.permission.maxStorage;

  const databaseSizeInBytes =
    workspaceStatistics.detailed.datasets.geoTablesSizeInBytes +
    workspaceStatistics.detailed.datasets.attTablesSizeInBytes;
  const tilesSizeInBytes =
    workspaceStatistics.detailed.datasets.geoTileLayersSizeInBytes +
    workspaceStatistics.detailed.datasets.dataTileLayersSizeInBytes;
  const attachmentsSizeInBytes = workspaceStatistics.detailed.attachments.sizeInBytes;
  const versionsSizeInBytes = workspaceStatistics.detailed.versions.sizeInBytes;
  const freeSpaceSizeInBytes = Math.max(
    workspaceStatistics.permission.maxStorage - workspaceStatistics.totalInBytes,
    0
  );

  const percentageUsedDatabase = (databaseSizeInBytes / percentageDivider) * 100;
  const percentageUsedTiles = (tilesSizeInBytes / percentageDivider) * 100;
  const percentageUsedAttachments = (attachmentsSizeInBytes / percentageDivider) * 100;
  const percentageUsedDatasetVersions = (versionsSizeInBytes / percentageDivider) * 100;
  const percentageFreeSpace = (freeSpaceSizeInBytes / percentageDivider) * 100;

  const items: VisualBreakdown[] = [
    {
      color: chartColors.COLOR_DATABASE,
      label: 'Database tables',
      widthPercentage: percentageUsedDatabase,
      legendNote: toHumanReadableFileSize(databaseSizeInBytes),
    },
    {
      color: chartColors.COLOR_TILES,
      label: 'Tiles',
      widthPercentage: percentageUsedTiles,
      legendNote: toHumanReadableFileSize(tilesSizeInBytes),
    },
    {
      color: chartColors.COLOR_ATTACHMENTS,
      label: 'Attachments',
      widthPercentage: percentageUsedAttachments,
      legendNote: toHumanReadableFileSize(attachmentsSizeInBytes),
    },
    {
      color: chartColors.COLOR_VERSIONS,
      label: 'Dataset versions',
      widthPercentage: percentageUsedDatasetVersions,
      legendNote: toHumanReadableFileSize(versionsSizeInBytes),
    },
    {
      color: chartColors.COLOR_FREE_SPACE,
      label: 'Free space',
      widthPercentage: percentageFreeSpace,
      legendNote: toHumanReadableFileSize(freeSpaceSizeInBytes),
    },
  ];
  return (
    <div className="flex flex-col gap-4 border-b border-gray-200 py-8 px-6">
      <p className="font-semibold text-gray-800">
        Storage: {toHumanReadableFileSize(workspaceStatistics.totalInBytes)} of{' '}
        {toHumanReadableFileSize(workspaceStatistics.permission.maxStorage)}
      </p>
      <UsageVisualBreakdown items={items} />
    </div>
  );
};

export default WorkspaceStorage;
