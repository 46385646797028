import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import CreateProfileModal from '../Profiles/CreateProfileModal';
import CreateProjectModal from '../Projects/CreateProjectModal';
import CreateStoryModal from '../Stories/CreateStoryModal';

interface Props {
  title?: string;
  trigger?: JSX.Element;
  action?: PeTypes.Action;
  projectId?: string | null;
}

const EmptyState: React.FC<Props> = ({ title, trigger, action, projectId }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const renderModal = () => {
    switch (action) {
      case PeTypes.Action.NEW_PROJECT:
        return <CreateProjectModal onCloseRequest={() => setIsModalVisible(false)} />;
      case PeTypes.Action.NEW_PROFILE:
        return <CreateProfileModal onCloseRequest={() => setIsModalVisible(false)} defaultProjectId={projectId} />;
      case PeTypes.Action.NEW_STORY:
        return (
          <CreateStoryModal
            mapContextId=""
            onCloseRequest={() => setIsModalVisible(false)}
            defaultProjectId={projectId}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex max-h-[196px] min-h-[146px] w-full items-center justify-center rounded-[16px] border-2 border-dotted border-gray-300 py-9">
        <div className={`flex flex-col items-center justify-center  ${title ? 'gap-6' : ''}`}>
          <div className="max-w-[860px] text-center text-gray-600">{title}</div>
          <div onClick={() => setIsModalVisible(true)}>{trigger}</div>
        </div>
      </div>
      {isModalVisible && renderModal()}
    </>
  );
};

export default EmptyState;
