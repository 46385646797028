import { Dropdowns, MUIcon } from '@platform/shared/ui';
import EditDataRowSubjectModal from '../EditDataRowSubjectModal';
import * as MenuTypes from './menu.types';

const useDataRowSubjectOption = (
  datasetId: string,
  rowSubject: string | null,
  action: number | null,
  handleCloseRequest: () => void
): [Dropdowns.DropdownOption, JSX.Element | null] => {
  const option = {
    value: MenuTypes.MenuOption.DATA_ROW_SUBJECT,
    name: 'Data row/subject',
    icon: <MUIcon name="menu_open" />,
    additionalStyles: 'text-sm border-b',
  };

  const modal =
    action === MenuTypes.MenuOption.DATA_ROW_SUBJECT ? (
      <EditDataRowSubjectModal datasetId={datasetId} rowSubject={rowSubject} onCloseRequest={handleCloseRequest} />
    ) : null;

  return [option, modal];
};

export default useDataRowSubjectOption;
