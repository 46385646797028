import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ReactComponent as Cover } from '../../../../assets/cover.svg';
import * as peApi from '../../../pe.api';
import AccountInfoForm, { Inputs } from './AccountInfoForm';

type Props = {
  user: PeTypes.User;
};

const AccountPersonalInfo: React.FC<Props> = ({ user }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      updateAccountImageMutation.mutate(formData);
    }
  };

  const updateAccountImageMutation = useMutation({
    mutationFn: (formData: FormData) => peApi.updateAccountImage(formData),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['session']);
      setIsExpanded(false);
    },
  });

  const updateAccountInfoMutation = useMutation({
    mutationFn: (payload: PeTypes.AccountInfoPayload) => peApi.updateAccountInfo(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['session']);
      setIsExpanded(false);
    },
  });

  const handleSave = (data: Inputs) => {
    updateAccountInfoMutation.mutate({ ...data, userId: user.id });
  };

  return (
    <div className="h-full w-full rounded-xl border border-gray-200 bg-white shadow-md">
      <div className="flex h-[283px] flex-col">
        <Cover className="rounded-t-xl" />
        <div className="relative flex w-full flex-col items-center justify-center gap-2">
          <div className="absolute -top-16 flex flex-col items-center justify-center gap-6 rounded-t-xl">
            <img
              src={`${user?.pictureLargeURL}?${user?.updatedAt}`}
              alt="User"
              className="h-[116px] w-[116px] rounded-full border-2 border-white "
            />
            {isExpanded ? (
              <p className="text-sm text-gray-600">Edit your personal info</p>
            ) : (
              <p className="text-xl font-semibold">{`${user.firstName} ${user.lastName}`}</p>
            )}
          </div>
          <div className="absolute -top-16 flex h-[116px] w-[116px] flex-col items-center justify-center gap-6 rounded-t-xl opacity-0 hover:opacity-100">
            <input type="file" id="file-input" className="hidden" onChange={handleFileChange} />
            <label
              htmlFor="file-input"
              className="relative flex h-full w-full cursor-pointer items-center items-center justify-center rounded rounded-full border-2 border-white bg-black px-4 py-2 text-white opacity-60"
            >
              <MUIcon name="photo_camera" className="text-[35px] text-white opacity-100" />
            </label>
          </div>
          {!isExpanded && (
            <button onClick={() => setIsExpanded(true)}>
              <MUIcon name="edit" className="absolute right-6 top-20" />
            </button>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="flex w-full flex-col items-center justify-center px-6 pb-5">
          <AccountInfoForm user={user} onSave={handleSave} onCancel={() => setIsExpanded(false)} />
          {updateAccountInfoMutation.isError && (
            <div className="w-full pt-2 text-left text-xs text-red-500">Failed to update account info.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountPersonalInfo;
