import { DatasetTypeIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React from 'react';

interface Props {
  dataset: PeTypes.DatasetBasicInfo;
}

const DatasetInfo: React.FC<Props> = ({ dataset }) => {
  const { type, numberOfColumns, numberOfRecords, sizeInMB, name } = dataset;

  return (
    <div className="flex w-full min-w-0 items-center gap-4">
      <DatasetTypeIcon
        type={type}
        backgroundClasses={`p-2 ${type === ProjectTypes.LayerType.TABLE ? 'bg-gray-500' : 'bg-primary-500'}`}
      />
      <div className="min-w-0 flex-grow flex-col pr-4">
        <div className="truncate font-semibold">{name}</div>
        <div className="text-sm text-gray-400">
          <div className="flex gap-1 text-xs text-gray-500">
            <div>{ValueFormatter.format(numberOfColumns, ProjectTypes.ValueFormat.FORMAT_NUMBER)} columns •</div>
            <div>{ValueFormatter.format(numberOfRecords, ProjectTypes.ValueFormat.FORMAT_NUMBER)} rows •</div>
            <div>{ValueFormatter.format(sizeInMB, ProjectTypes.ValueFormat.FORMAT_NUMBER_NO_DECIMAL)} MB in size</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatasetInfo;
