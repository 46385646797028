import axios from 'axios';
import { useQuery } from 'react-query';
import * as peApi from '../pe.api';

const useUploadToS3 = (pathToSign: string) => {
  const signedUrlQuery = useQuery(['signed-url', pathToSign], () => peApi.getSignedUrl(pathToSign), {
    staleTime: 3600,
    enabled: !!pathToSign,
  });

  return async (image: string) => {
    try {
      const base64Data = image.replace(/^data:image\/\w+;base64,/, '');
      if (!signedUrlQuery.data) {
        console.warn("Can't upload to SE. Signature failed");
        return;
      }

      const uint8Array = decodeBase64ToBinary(base64Data);
      const blob = new Blob([uint8Array], { type: 'image/png' });
      await axios.put(signedUrlQuery.data.signedUrl, blob, {
        headers: {
          'Content-Type': 'image/png',
        },
      });
      return signedUrlQuery.data.staticUrl;
    } catch (e) {
      console.error("Can't upload to SE", e);
    }
  };
};

export default useUploadToS3;

function decodeBase64ToBinary(base64Data: string): Uint8Array {
  // Decoding the base64 string to a binary string
  const binaryString = window.atob(base64Data);

  // Creating a Uint8Array to hold the binary data
  const binaryData = new Uint8Array(binaryString.length);

  // Converting the binary string to the actual binary data
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  return binaryData;
}
