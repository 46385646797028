import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../components/shared/ErrorPage';
import { Edit } from '../components/Stories';

const EditStoryPage: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();

  if (!storyId) return <ErrorPage />;
  return <Edit storyId={storyId} />;
};

export default EditStoryPage;
