import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import Thumb from '../Thumb';
import SlideList from './SlideList';

interface Props {
  isEditMode: boolean;
  story: PeTypes.Story;
  currentSlide: PeTypes.StorySlide;
  currentIndex: number;
  totalCount: number;
  onNext: () => void;
  onPrev: () => void;
  onJumpTo: (slideId: string) => void;
  thumbsInMemory?: Record<string, string>;
}

const BTN_CLASSNAMES = 'p-1 my-3 bg-white/10 text-white shadow-none ring-0 rounded-full hover:bg-white/30';

const SlideNavi: React.FC<Props> = ({
  story,
  currentSlide,
  onJumpTo,
  isEditMode,
  thumbsInMemory = {},
  currentIndex = 0,
  totalCount = 0,
  onPrev,
  onNext,
}) => {
  const [listIsOpen, setListIsOpen] = useState<boolean>(false);
  const thumbToUse = thumbsInMemory[currentSlide.id] ?? currentSlide.thumbPath;

  return (
    <>
      <div className="flex select-none flex-col items-center text-white">
        <Buttons.Secondary
          onClick={onPrev}
          disabled={currentIndex === 0}
          icon={<MUIcon name="expand_less" className="text-[24px]" />}
          className={BTN_CLASSNAMES}
          title="Previous slide"
        />
        <div className="mt-6 mb-4 flex flex-col items-center gap-2">
          <div onClick={() => setListIsOpen(true)}>
            <Thumb
              url={thumbToUse}
              className={`outline-acai-800 hover:outline-primary-400 h-[32px] w-[32px] cursor-pointer rounded-xl outline outline-2 transition-all hover:outline-offset-2 ${
                listIsOpen ? 'outline' : ''
              }`}
            />
          </div>
          <span className="text-[10px] leading-3 text-gray-300">{`${currentIndex + 1}/${totalCount}`}</span>
        </div>
        <Buttons.Secondary
          onClick={onNext}
          disabled={currentIndex === totalCount - 1}
          icon={<MUIcon name="expand_more" className="text-[24px]" />}
          className={BTN_CLASSNAMES}
          title="Next slide"
        />
      </div>
      {listIsOpen && (
        <>
          <div className="fixed inset-0 z-[9990] bg-black/30" onClick={() => setListIsOpen(false)} />
          <div className="fixed top-4 bottom-4 left-[80px] z-[9991] w-[222px] rounded-lg bg-white shadow-lg">
            <SlideList
              isEditMode={isEditMode}
              onJumpTo={onJumpTo}
              storyId={story.id}
              currentSlideId={currentSlide.id}
              thumbsInMemory={thumbsInMemory}
              onCloseList={() => setListIsOpen(false)}
            />
          </div>
        </>
      )}
    </>
  );
};
export default SlideNavi;
