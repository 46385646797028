import React, { useEffect, useRef, useState } from 'react';
import { Column } from './index';

interface Props {
  column: Column;
  value: unknown;
  cIdx: number;
  rIdx: number;
  onEditStart?: (rIdx: number, cIdx: number) => void;
  onEditEnd?: () => void;
}

const EditableCell: React.FC<Props> = ({ column, value, cIdx, rIdx, onEditStart, onEditEnd }: Props) => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const cellRef = useRef(null);
  const editMenuRef = useRef<HTMLDivElement>(null);
  const { renderEdit, formatter } = column;

  useEffect(() => {
    if (showEditDialog) {
      onEditStart?.(rIdx, cIdx);
    } else {
      onEditEnd?.();
    }
  }, [showEditDialog]);

  const handleDoubleClick = () => {
    setAnchorElement(cellRef?.current);
    setShowEditDialog(true);
  };

  let cellValue = <div className="italic text-gray-500">null</div>;

  if (value != null) {
    const processed = formatter ? formatter(value) : `${value}`;
    cellValue = <div className="truncate">{processed}</div>;
  }

  return (
    <>
      <div ref={cellRef} className="relative cursor-pointer" onDoubleClick={handleDoubleClick}>
        {cellValue}
      </div>
      {showEditDialog && (
        <div ref={editMenuRef}>{renderEdit?.(value, cIdx, rIdx, anchorElement, () => setShowEditDialog(false))}</div>
      )}
    </>
  );
};

export default EditableCell;
