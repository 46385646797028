import { useDebounce } from '@platform/shared/hooks';
import React, { useEffect, useState } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  debounceValue: number;
  defaultValue: number | string;
  className: string;
  inputClassNames: string;
  suffix?: string | React.ReactNode;
  valueSubmitHandler: (value: string | number) => void;
}

const DebouncedInput: React.FC<Props> = ({
  debounceValue,
  defaultValue,
  valueSubmitHandler,
  className,
  suffix,
  inputClassNames,
  ...otherInputProps
}) => {
  const [inputValue, setInputValue] = useState<string | number>(defaultValue);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const debouncedValue = useDebounce(inputValue, debounceValue);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      valueSubmitHandler(debouncedValue);
    }
  };

  return (
    <div className={`${className} flex`}>
      <input
        value={inputValue}
        className={inputClassNames}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyDown={handleKeyPress}
        onBlur={() => valueSubmitHandler(debouncedValue)}
        {...otherInputProps}
      />
      {suffix}
    </div>
  );
};

export default DebouncedInput;
