import { Map, MapGeoJSONFeature, PointLike, QueryRenderedFeaturesOptions } from 'maplibre-gl';
import { useRef } from 'react';

function useRenderedFeatures(map: Map | null) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return (
    sourceLayerIds: string[] = [],
    point: PointLike | [PointLike, PointLike] | QueryRenderedFeaturesOptions | undefined,
    callback: (features: MapGeoJSONFeature[] | undefined) => void
  ) => {
    if (map) {
      const handleMapIdleEvent = () => {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);

        timeoutRef.current = setTimeout(() => {
          try {
            const { layers } = map.getStyle();

            const layerIds = sourceLayerIds.length ? sourceLayerIds : layers.map((l) => l.id);

            const features = map.queryRenderedFeatures(point, { layers: layerIds });

            callback(features);
          } catch (error) {
            console.warn('Failed to query for features', error);
            callback(undefined);
          } finally {
            map.off('idle', handleMapIdleEvent);
          }
        }, 500);
      };

      if (map.isStyleLoaded()) {
        handleMapIdleEvent();
      } else {
        map.on('idle', handleMapIdleEvent);
      }
    }
  };
}

export default useRenderedFeatures;
