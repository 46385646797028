import classNames from 'classnames';
import React, { ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import { HorizontalAlignmentType, MenuWidthType, VerticalAlignmentType } from './menu.types';

const WINDOW_OFFSET = 10;

export interface Props {
  trigger: React.ReactNode;
  visible: boolean;
  onToggleVisibility: (visible: boolean) => void;
  children: React.ReactNode | string;
  disabled?: boolean;
  triggerPrefix?: string;
  triggerClasses?: string;
  triggerLabelClasses?: string;
  hAlignment?: HorizontalAlignmentType;
  menuWidth?: MenuWidthType;
  vAlignment?: VerticalAlignmentType;
  menuClasses?: string;
  menuMargin?: number;
  name?: string;
}

interface TriggerProps {
  children: React.ReactNode;
  onMouseDown: () => void;
  classes?: string;
  disabled?: boolean;
}

const MenuTrigger = forwardRef(
  ({ children, onMouseDown, classes }: TriggerProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div ref={ref} onMouseDown={onMouseDown} className={classNames('cursor-pointer', classes)}>
        {children}
      </div>
    );
  }
);

export const Menu: React.FC<Props> = ({
  visible,
  trigger,
  children,
  disabled,
  onToggleVisibility,
  menuMargin = 0,
  menuClasses = '',
  triggerClasses = '',
  hAlignment = 'left',
  vAlignment = 'bottom',
  menuWidth = 'auto',
  name,
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // realign modal according to the window if needed
  const getAlignment = (elementRect: DOMRect, menuRect: DOMRect) => {
    let vertical = vAlignment;
    let horizontal = hAlignment;

    if (elementRect.bottom + menuRect.height + menuMargin + WINDOW_OFFSET > window.innerHeight) {
      vertical = 'top-bottom';
    } else if (elementRect.top - menuRect.height - menuMargin - WINDOW_OFFSET < 0) {
      vertical = 'bottom';
    }

    if (elementRect.left + menuRect.width + WINDOW_OFFSET > window.innerWidth) {
      horizontal = 'right';
    } else if (elementRect.left - WINDOW_OFFSET < 0) {
      horizontal = 'left';
    }

    return {
      vertical,
      horizontal,
    };
  };

  const positionMenu = () => {
    requestAnimationFrame(() => {
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      const menuNode = menuRef.current;
      if (!triggerRect || !menuNode) return;

      if (menuWidth === 'full') {
        menuNode.style.width = `${triggerRect.width}px`;
      } else {
        menuNode.style.width = `initial`;
      }

      const menuRect = menuNode.getBoundingClientRect();
      const { vertical, horizontal } = getAlignment(triggerRect, menuRect);

      let calculatedTop = 0;

      switch (vertical) {
        case 'top':
          calculatedTop = triggerRect.top + menuMargin;
          break;
        case 'top-bottom':
          calculatedTop = triggerRect.bottom - triggerRect.height - menuRect.height - menuMargin;
          break;
        case 'bottom':
        default:
          calculatedTop = triggerRect.bottom + menuMargin;
      }

      let calculatedLeft = 0;

      switch (horizontal) {
        case 'left':
          calculatedLeft = triggerRect.left;
          break;
        case 'right':
          calculatedLeft = triggerRect.left + triggerRect.width - menuRect.width;
          break;
        case 'center':
        default:
          calculatedLeft = triggerRect.left - menuRect.width / 2;
      }

      menuNode.style.top = `${Math.max(calculatedTop, menuMargin)}px`;
      menuNode.style.left = `${Math.max(calculatedLeft, menuMargin)}px`;
      menuNode.style.visibility = 'visible';
    });
  };

  positionMenu();

  useEffect(() => {
    const handleMouseEvents = (event: MouseEvent) => {
      if (triggerRef?.current?.contains(event.target as Node)) {
        // if trigger clicked, do not hide menu here. The trigger click handler will do it instead
        return;
      }

      if (!menuRef?.current?.contains(event.target as Node)) {
        onToggleVisibility(false);
      }
    };

    const handleResizeEvents = () => {
      onToggleVisibility(false);
    };

    if (visible) {
      window.addEventListener('wheel', handleMouseEvents);
      window.addEventListener('resize', handleResizeEvents);
      window.addEventListener('mousedown', handleMouseEvents, { capture: true });
    }
    return () => {
      window.removeEventListener('wheel', handleMouseEvents);
      window.removeEventListener('resize', handleResizeEvents);
      window.removeEventListener('mousedown', handleMouseEvents, { capture: true });
    };
  }, [visible, onToggleVisibility]);

  const TriggerElement = (
    <MenuTrigger
      ref={triggerRef}
      onMouseDown={() => {
        if (disabled) return;
        onToggleVisibility(!visible);
      }}
      disabled={disabled}
      classes={triggerClasses}
    >
      {trigger}
    </MenuTrigger>
  );

  return (
    <>
      {TriggerElement}
      {visible && (
        <div className="invisible fixed z-50" ref={menuRef} data-cy={name ? `menu-${name}` : undefined}>
          <div
            className={classNames(
              'max-h-[550px] min-w-[200px] overflow-y-auto rounded-md bg-white shadow-lg focus:outline-none',
              menuClasses
            )}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
