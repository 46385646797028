import { PeTypes } from '@platform/types';
import React from 'react';
import UserAvatar from './UserAvatar';

interface Props {
  user: PeTypes.User;
}

const UserDetails: React.FC<Props> = ({ user }) => {
  const { email, firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className="flex gap-4">
      <UserAvatar user={user} />
      <div className="flex flex-col items-start">
        <span className="text-sm">{fullName}</span>
        <span className="text-xs text-gray-500">{email}</span>
      </div>
    </div>
  );
};

export default UserDetails;
