import { MapsContext } from '@platform/maplibre';
import { PeTypes } from '@platform/types';
import React, { useMemo } from 'react';
import * as peApi from '../../pe.api';
import { containCanvasToPng } from '../../utils';
import SeProMaps from '../Map/SeProMaps';
import ExportLegend from './ExportLegend';
import { getImageElement, htmlImageElementToPng } from './utils';

interface Props {
  title: string;
  mapContextId: string;
  includeLegend: boolean;
  imageDimension: PeTypes.ImageDim;
  jwtToken: string | null;
  onDone: (maps: string | undefined, legend: string | undefined) => void;
}

const MapExport: React.FC<Props> = ({ mapContextId, title, jwtToken, includeLegend, onDone, imageDimension }) => {
  const requestedW = imageDimension.width;
  const requestedH = imageDimension.height;

  const handleThumb = useMemo(
    () => async (canvas: HTMLCanvasElement) => {
      try {
        let legendExport;
        if (includeLegend) {
          const legendHtml = await getImageElement(`map-info-${mapContextId}`);
          if (legendHtml) {
            legendExport = htmlImageElementToPng(legendHtml);
          }
        }

        const mapsExport = containCanvasToPng(canvas, requestedW, requestedH);

        onDone(mapsExport, legendExport);
      } catch {
        onDone(undefined, undefined);
      }
    },
    []
  );

  return (
    <MapsContext.MapsContextProvider
      mapContextId={mapContextId}
      jwtToken={jwtToken}
      doSave={false}
      thumbWidth={requestedW}
      thumbHeight={requestedH}
      onThumb={handleThumb}
      apis={{
        mapContext: {
          get: peApi.getMapContext,
          update: () => new Promise(() => ({})),
        },
        baseMaps: { get: peApi.getBaseMapStyle },
        dataSourceLayers: { get: peApi.getDataSourceLayers },
      }}
    >
      <MapsContext.MapsConsumer>
        {({ state }) => {
          if (!state.registeredMaps.length) return null;
          return (
            <div
              className="absolute top-0 left-0 flex"
              style={{ width: imageDimension.width, height: imageDimension.height }}
            >
              {includeLegend && (
                <div>
                  <ExportLegend id={`map-info-${mapContextId}`} mapObjects={state.registeredMaps} />
                </div>
              )}
              <SeProMaps mapObjects={state.registeredMaps} type={state.type} inEditMode={false} />
            </div>
          );
        }}
      </MapsContext.MapsConsumer>
    </MapsContext.MapsContextProvider>
  );
};

export default MapExport;
