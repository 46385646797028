import { Modal, MUIcon } from '@platform/shared/ui';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import * as peApi from '../../pe.api';
import DefaultMapPicker from '../DefaultMaps/DefaultMapPicker';
import ProjectsPicker from '../Projects/ProjectsPicker';
import useCreateStory from './useCreateStory';

type Inputs = {
  title: string;
  projectId: string | null;
  defaultMapId: string;
};

interface Props {
  defaultProjectId?: string | null;
  mapContextId: string;
  thumb?: string;
  onCloseRequest?: () => void;
}

const CreateStoryModal: React.FC<Props> = ({ defaultProjectId, mapContextId, thumb, onCloseRequest }) => {
  const { create, isCreating } = useCreateStory();

  const defaultMapsQuery = useQuery({
    queryKey: ['default-map'],
    queryFn: () => peApi.getDefaultMaps(),
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      title: '',
      projectId: defaultProjectId,
      defaultMapId: undefined,
    },
  });

  useEffect(() => {
    if (!mapContextId) {
      setValue('defaultMapId', defaultMapsQuery.data?.find((x) => x.preselect)?.id ?? '');
    }
  }, [defaultMapsQuery.data, setValue]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    create({ ...data, mapContextId });
  };

  const title = (
    <div className="flex items-center gap-4">
      <div className="bg-acai flex h-[40px] w-[40px] items-center justify-center rounded-[999px] px-3">
        <MUIcon name="note_stack" className="text-[25px] text-white" />
      </div>
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-gray-800">Create a Story</div>
        <div className="text-sm font-normal text-gray-500">
          Create impactful stories using data-driven, interactive presentation editor.
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      title={title}
      okLabel="Create"
      className="w-[512px]"
      okDisabled={isCreating}
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-col gap-6">
        <div data-cy={'create-map-title-div'}>
          <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
            Name
          </label>
          <input
            autoFocus
            id="title"
            data-cy="create-story-title-input"
            placeholder="Give your story a name"
            className="border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none focus:border"
            {...register('title', { required: 'Name is required' })}
          />
          {errors.title && <span className="text-red-400">{errors.title.message}</span>}
        </div>
        <Controller
          name="projectId"
          control={control}
          render={({ field }) => (
            <div>
              <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
                Project (optional)
              </label>
              <ProjectsPicker onSelect={(x) => field.onChange(x.id)} value={field.value} />
            </div>
          )}
        />

        {!mapContextId && (
          <Controller
            name="defaultMapId"
            control={control}
            rules={{ required: 'Map is required' }}
            render={({ field }) => {
              const { data, isError, isIdle, isLoading } = defaultMapsQuery;
              if (isIdle || isLoading) {
                return <>Loading maps...</>;
              }
              if (isError) {
                return <>Something went wrong</>;
              }
              return (
                <>
                  <label htmlFor="defaultMap" className="mb-2 block text-sm font-bold text-gray-900">
                    Start with...
                  </label>
                  <DefaultMapPicker
                    activeMapId={field.value}
                    onSelect={(x) => {
                      field.onChange(x.id);
                    }}
                    maps={data}
                  />
                  {errors.defaultMapId && (
                    <span className="text-red-400" data-cy={'create-story-map-error-msg'}>
                      {errors.defaultMapId.message}
                    </span>
                  )}
                </>
              );
            }}
          />
        )}
      </form>
    </Modal>
  );
};

export default CreateStoryModal;
