import React from 'react';
import Invitation from '../components/Account/Invitation/Invitation';
import ErrorPage from '../components/shared/ErrorPage';

const InvitationPage: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const invitationId = searchParams.get('invitation_id');
  if (!invitationId) {
    return <ErrorPage />;
  }
  const decodedInvitationId = decodeURIComponent(invitationId);
  return <Invitation invitationId={decodedInvitationId} />;
};

export default InvitationPage;
