import { Abacus } from '@platform/shared/hooks';
import { AbacusTypes, ProjectTypes } from '@platform/types';
import produce from 'immer';
import React, { useState } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { v4 as uuidv4 } from 'uuid';
import { Buttons, Dropdowns, MUIcon } from '../index';
import { BooleanFilter, NumericFilter, VarCharFilter } from '../LayerFiltering';
import ColumnPicker from './AbacusColumnPicker';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  metadataApi: (datasetId: string, filterableOnly: boolean) => Promise<AbacusTypes.Column[]>;
  dataApi: (datasetId: string, payload: AbacusTypes.DataRequest) => Promise<AbacusTypes.Data>;
  onChange: (q: RuleGroupType) => void;
  onCloseRequest?: () => void;
}

const INITIAL_QUERY: RuleGroupType = {
  combinator: 'and',
  rules: [],
};

const AbacusLayerFilter: React.FC<Props> = ({ workingLayer, onChange, onCloseRequest, dataApi, metadataApi }) => {
  const { datasetId, filter = INITIAL_QUERY } = workingLayer;

  const [activeRule, setActiveRule] = useState<RuleType | RuleGroupType | null>(null);

  const abacusColumnsMetadataQuery = Abacus.useAbacusColumnsMetadata({
    datasetId,
    api: metadataApi,
    filterableOnly: true,
  });

  const abacusDataQuery = Abacus.useAbacusData({
    datasetId,
    api: dataApi,
    columnNames: abacusColumnsMetadataQuery.data?.map((col) => col.name) ?? [],
  });

  const { rules, combinator } = filter;

  const handleNewRule = () =>
    setActiveRule({
      id: uuidv4(),
      value: { value: '' },
      field: '',
      operator: '',
    });

  const handleCombinatorChange = (combinator: string) => {
    const updated = produce(filter, (draft) => {
      draft.combinator = combinator;
    });

    onChange(updated);
  };

  const handleClearAllFilters = () => {
    const updated = produce(filter, (draft) => {
      draft.rules = [];
    });
    onChange(updated);
  };

  const handleRemoveRule = (rule: RuleType | RuleGroupType) => {
    const newFilter = { ...filter };
    newFilter.rules = newFilter.rules.filter((r) => r.id !== rule.id);
    onChange(newFilter);
  };

  const handleFilterChange = (rule: RuleType, operator: string, val: unknown) => {
    const updatedFilter = structuredClone(filter);
    const indexOfRule = rules.findIndex((r) => r.id === rule.id);
    let newValue: string | number;

    if (typeof val === 'string' || typeof val === 'number') {
      newValue = val;
    } else {
      console.error('Invalid value type');
      return;
    }

    updatedFilter.rules[indexOfRule] = {
      ...rule,
      operator,
      value: {
        ...rule.value,
        value: newValue,
      },
    };
    onChange(updatedFilter);
  };

  const handleRuleColChange = (colKey: string) => {
    const updatedFilter = structuredClone(filter);
    const indexOfRule = rules.findIndex((r) => r.id === activeRule?.id);

    const updatedRule: RuleType = {
      ...activeRule,
      value: '',
      field: colKey,
      operator: '=',
    };

    if (indexOfRule > -1) {
      // updating existing rule
      updatedFilter.rules[indexOfRule] = updatedRule;
    } else {
      // adding new rule
      updatedFilter.rules.push(updatedRule);
    }
    onChange(updatedFilter);
    setActiveRule(null);
  };

  const columns = abacusColumnsMetadataQuery.data ?? [];

  return (
    <div>
      <div className="flex w-full flex-col gap-6">
        <div className="flex items-center justify-between gap-6">
          <Dropdowns.Dropdown
            onSelect={(opt) => handleCombinatorChange(opt.value as string)}
            options={[
              { value: 'and', name: 'And' },
              { value: 'or', name: 'Or' },
            ]}
            value={combinator}
            triggerWrapperClasses="max-w-fit"
            triggerClasses="text-sm text-gray-700 bg-white border border-gray-300 rounded-lg ring-0 gap-2"
          />
          <Buttons.Link className="text-primary-600 text-sm font-semibold" onClick={handleClearAllFilters}>
            Clear filters
          </Buttons.Link>
        </div>
      </div>

      <div className="flex flex-col py-1">
        {rules
          .filter((rule) => columns.find((c) => c.name === (rule as RuleType).field))
          .map((rule) => {
            const { field } = rule as RuleType;
            const column = columns.find((c) => c.name === field);
            if (!column) return null;
            const title = column.title || column.name;

            const abacusColumnMetadata = abacusColumnsMetadataQuery.data?.find((c) => c.name === field);
            switch (column.type) {
              case 'BOOLEAN': {
                return (
                  <div key={rule.id} className="flex flex-col gap-2 py-2">
                    <div className="mx-6 my-1 h-[12px] w-[2px] bg-gray-300"></div>
                    <BooleanFilter
                      key={rule.id}
                      rule={rule as RuleType}
                      title={title}
                      type={column.type}
                      onFilterChange={handleFilterChange}
                      onRemoveRule={handleRemoveRule}
                      onColumnChange={(r) => setActiveRule(r)}
                    />
                  </div>
                );
              }
              case 'INTEGER':
              case 'DOUBLE': {
                return (
                  <div key={rule.id} className="flex flex-col gap-2 py-2">
                    <div className="mx-6 my-1 h-[12px] w-[2px] bg-gray-300"></div>
                    <NumericFilter
                      key={rule.id}
                      rule={rule as RuleType}
                      title={title}
                      type={column.type}
                      onFilterChange={handleFilterChange}
                      onRemoveRule={handleRemoveRule}
                      onColumnChange={(r) => setActiveRule(r)}
                    />
                  </div>
                );
              }
              case 'VARCHAR':
                return (
                  <div key={rule.id} className="flex flex-col gap-2 py-2">
                    <div className="mx-6 my-1 h-[12px] w-[2px] bg-gray-300"></div>
                    <VarCharFilter
                      key={rule.id}
                      rule={rule as RuleType}
                      title={title}
                      type={column.type}
                      columnDiscreteValues={
                        abacusColumnMetadata?.metadata.values?.filter((x: unknown) => x != null) ?? []
                      }
                      onFilterChange={handleFilterChange}
                      onRemoveRule={handleRemoveRule}
                      onColumnChange={(r) => setActiveRule(r)}
                    />
                  </div>
                );

              default:
                return null;
            }
          })}
      </div>
      <div className="py-2">
        <Buttons.Secondary
          className="text-primary-600 h-[36px] w-[148px] gap-2 rounded-[999px] shadow-none ring-gray-200"
          icon={<MUIcon name="add" />}
          onClick={handleNewRule}
        >
          Add criteria
        </Buttons.Secondary>
      </div>
      {activeRule && (
        <ColumnPicker
          datasetId={datasetId}
          columns={abacusColumnsMetadataQuery.data ?? []}
          data={abacusDataQuery.data ?? { data: [] }}
          onSelect={handleRuleColChange}
          onCloseRequest={() => {
            setActiveRule(null);
            onCloseRequest?.();
          }}
        />
      )}
    </div>
  );
};

export default AbacusLayerFilter;
