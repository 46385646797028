import { MapsContext } from '@platform/maplibre';
import { PeTypes } from '@platform/types';
import React from 'react';
import SeProMaps from '../Map/SeProMaps';
import ProfileHeader from './ProfileHeader';

const { useMapsContext } = MapsContext;

interface Props {
  profile: PeTypes.Profile;
  mode: 'edit' | 'view';
}

const ProfileMapsWrapper: React.FC<Props> = ({ profile, mode }) => {
  const { state } = useMapsContext();
  const isEditMode = mode === 'edit';

  return (
    <div className="relative flex h-full min-w-0 flex-grow">
      <div className="absolute top-0 right-0 z-10">
        <ProfileHeader profile={profile} isEditMode={isEditMode} />
      </div>
      <SeProMaps mapObjects={state.registeredMaps} type={state.type} inEditMode={isEditMode} />
    </div>
  );
};

export default ProfileMapsWrapper;
