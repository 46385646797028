import { Dropdowns } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { typeDropdownOptions } from './DataFormatting';

interface Props {
  column: PeTypes.ImportDatasetPayloadColumn;
  disableFormatChange: boolean;
  onImportDataFormattingChange: (column: PeTypes.ImportDatasetPayloadColumn) => void;
  sampleValues?: unknown[];
  originalColumnType: DatasetTypes.DataType | undefined;
}

export const NumberFormatDropdown: Dropdowns.DropdownOption[] = [
  {
    name: 'None',
    value: '',
  },
  {
    name: 'Number',
    value: 'Number',
    disabled: true,
  },
  {
    name: '10,000.00',
    value: '0,0.00',
  },
  {
    name: '10,000',
    value: '0,0',
  },
  {
    name: 'Percentage',
    value: 'Percentage',
    disabled: true,
  },
  {
    name: '100%',
    value: '0%',
  },
  {
    name: '97.48%',
    value: '0.00%',
  },
  {
    name: 'Currency',
    value: 'Currency',
    disabled: true,
  },
  {
    name: 'United States Dollar (USD)',
    value: 'USD0,0[.]00',
  },
  {
    name: 'Euro (EUR)',
    value: 'EUR0,0[.]00',
  },
  {
    name: 'Japanese Yen (JPY)',
    value: 'JPY0,0[.]00',
  },
  {
    name: 'British Pound Sterling (GBP)',
    value: 'GBP0,0[.]00',
  },
  {
    name: 'Chinese Yuan (CNY)/Renminbi (RMB)',
    value: 'CNY0,0[.]00',
  },
  {
    name: 'Swiss Franc (CHF)',
    value: 'CHF0,0[.]00',
  },
  {
    name: 'Canadian Dollar (CAD)',
    value: 'CAD0,0[.]00',
  },
  {
    name: 'Australian Dollar (AUD)',
    value: 'AUD0,0[.]00',
  },
  {
    name: 'Russian Ruble (RUB)',
    value: 'RUB0,0[.]00',
  },
  {
    name: 'Indian Rupee (INR)',
    value: 'INR0,0[.]00',
  },
];

const DataFormattingItem: React.FC<Props> = ({
  column,
  disableFormatChange,
  sampleValues,
  originalColumnType,
  onImportDataFormattingChange,
}) => {
  const [alternateName, setAlternateName] = useState<string>(column.label ?? '');
  const onColumnTypeChange = (option: Dropdowns.DropdownOption) => {
    if (column.type === (option.value as DatasetTypes.DataType)) {
      return;
    }
    const modifiedColumn = { ...column };
    modifiedColumn.type = option.value as DatasetTypes.DataType;
    modifiedColumn.formatting = '';
    onImportDataFormattingChange(modifiedColumn);
  };

  const onColumnFormatChange = (option: Dropdowns.DropdownOption) => {
    const modifiedColumn = { ...column };
    modifiedColumn.formatting = option.value as string;
    onImportDataFormattingChange(modifiedColumn);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleFriendlyNameChange(alternateName);
  };

  const handleFriendlyNameChange = (label: string) => {
    const modifiedColumn = { ...column };
    modifiedColumn.label = label;
    onImportDataFormattingChange(modifiedColumn);
  };

  const disableTypeChange = originalColumnType === DatasetTypes.DataType.TEXT;
  const isColumnTypeNumber =
    originalColumnType && [DatasetTypes.DataType.INTEGER, DatasetTypes.DataType.DOUBLE].includes(originalColumnType);
  const filteredTypeDropdownOptions = typeDropdownOptions.filter(
    (x) => !isColumnTypeNumber || x.value !== DatasetTypes.DataType.BOOLEAN
  );

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <div className="flex min-w-0 shrink-0 basis-2/5 flex-col gap-1 text-sm">
          {column.name}
          <span className="truncate pr-4 text-xs text-gray-400">
            {sampleValues && <span>{sampleValues.join(', ')}</span>}
          </span>
        </div>
        <div className="flex min-w-0 shrink-0 basis-1/5 text-sm">
          <Dropdowns.Dropdown
            triggerWrapperClasses="w-full pr-8"
            menuWidth="full"
            triggerClasses="bg-white w-full flex grow-0 ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200 truncate"
            options={filteredTypeDropdownOptions}
            value={column.type}
            disabled={disableTypeChange}
            onSelect={onColumnTypeChange}
          />
        </div>
        <div className="flex min-w-0 shrink-0 basis-1/5 text-sm">
          <Dropdowns.Dropdown
            triggerWrapperClasses="w-full pr-8"
            menuWidth="full"
            disabled={disableFormatChange}
            triggerClasses="bg-white w-full flex grow-0 ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200 truncate"
            options={NumberFormatDropdown}
            value={column.formatting ?? ''}
            onSelect={onColumnFormatChange}
          />
        </div>
        <div className="flex min-w-0 shrink-0 basis-1/5">
          <input
            placeholder="Name"
            value={alternateName}
            className="flex h-[44px] w-full rounded-md border border-gray-300  px-3 text-sm"
            onChange={(e) => setAlternateName(e.target.value)}
            onKeyDown={handleKeyPress}
            onBlur={() => handleFriendlyNameChange(alternateName)}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default DataFormattingItem;
