import React from 'react';
import tw from 'tailwind-styled-components';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

// export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const SoftButton: React.FC<ButtonBaseProps> = (props) => <StyledButton {...props} />;
export default SoftButton;

const StyledButton = tw(ButtonBase)`
  bg-primary-50
  text-primary-600
  hover:bg-primary-100
`;
