import { useDebounce } from '@platform/shared/hooks';
import { Dropdowns, Inputs, Modal, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { validateEmail } from '../../helpers/helpers';
import * as peApi from '../../pe.api';

interface Props {
  activeWorkspace: PeTypes.Workspace;
  alreadyMemberIds?: number[];
  pendingInvitationsEmails?: string[];
  onSelect: (user: PeTypes.InviteMemberPayload) => void;
  onClose: () => void;
}

const dropdownOptions: Dropdowns.DropdownOption[] = [
  { name: 'Editor', value: false },
  { name: 'Admin', value: true },
];
const InviteUser: React.FC<Props> = ({
  activeWorkspace,
  onClose,
  onSelect,
  alreadyMemberIds,
  pendingInvitationsEmails,
}) => {
  const [userForInvite, setUserForInvite] = useState<PeTypes.InviteMemberPayload | null>(null);
  const [userRole, setUserRole] = useState<Dropdowns.DropdownOption>(dropdownOptions[0]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearch = useDebounce(searchTerm, 200);

  const usersQuery = useQuery(['user-search', debouncedSearch, activeWorkspace], () =>
    peApi.searchUser(debouncedSearch)
  );

  if (usersQuery.isError) return null;

  const users = usersQuery.data;
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value);

  const inviteUser = () => {
    if (userForInvite) {
      const user: PeTypes.InviteMemberPayload = {
        isAdmin: userForInvite.isAdmin || (userRole.value as boolean),
        groupId: userForInvite.groupId,
        memberId: userForInvite.memberId,
        email: userForInvite.email ?? '',
      };
      onSelect(user);
    }
  };

  const handleExistingUserSelect = (user: PeTypes.WorkspaceMember) => {
    let invite: PeTypes.InviteMemberPayload | null = {
      memberId: user.id,
      groupId: activeWorkspace.id,
      isAdmin: userRole.value as boolean,
    };

    // deselect if the current user is the same as the one that was selected before
    if (userForInvite && userForInvite.memberId === user.id) {
      invite = null;
    }

    setUserForInvite(invite);
  };

  const handleNewUserSelect = () => {
    setUserForInvite({ email: searchTerm, groupId: activeWorkspace.id, isAdmin: userRole.value as boolean });
  };

  if (validateEmail(searchTerm) && users?.length === 0 && userForInvite?.email !== searchTerm) {
    setUserForInvite({ email: searchTerm, groupId: activeWorkspace.id, isAdmin: userRole.value as boolean });
  }

  let content;
  if (!!searchTerm.length && users && users.length > 0) {
    content = (
      <div className="flex h-full grow flex-col">
        {users?.map((user) => {
          const isActiveUser = userForInvite?.memberId === user.id;
          const isAlreadyMember = alreadyMemberIds?.find((x) => x === user.id);
          return (
            <div key={user.id}>
              <div
                role="button"
                tabIndex={0}
                className={classNames(
                  isActiveUser ? 'bg-gray-100' : '',
                  isAlreadyMember ? 'pointer-events-none bg-gray-100 opacity-70' : '',
                  'flex min-w-full select-none items-center justify-between gap-2 rounded-xl px-2 shadow-none ring-0 last:border-none hover:cursor-pointer'
                )}
                onClick={() => handleExistingUserSelect(user)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleExistingUserSelect(user);
                  }
                }}
              >
                <div className="flex w-full items-center justify-between gap-6 py-4">
                  <div className="flex w-full items-center justify-start gap-4">
                    <div
                      className={classNames(
                        isActiveUser ? 'bg-primary-600' : '',
                        'flex min-h-[48px] min-w-[48px] items-center justify-center rounded-[999px] bg-gray-200'
                      )}
                    >
                      {isActiveUser ? (
                        <MUIcon name="check" className="text-white" />
                      ) : (
                        <img
                          className="h-[48px] w-[48px] rounded-[999px]"
                          src={`${user.pictureLargeURL}?${user.updatedAt}`}
                          alt={''}
                        ></img>
                      )}
                    </div>
                    <span className="truncate text-sm font-semibold text-gray-900">{`${user.firstName} ${user.lastName}`}</span>
                  </div>

                  <div className="flex w-4/12 items-center justify-end">
                    {isActiveUser && (
                      <Dropdowns.Dropdown
                        hAlignment="right"
                        customLabel={userRole.name}
                        icon={<MUIcon name="arrow_drop_down" />}
                        onSelect={(opt) => setUserRole(opt)}
                        options={dropdownOptions}
                        onTriggerClick={(e) => e.stopPropagation()}
                        triggerClasses="bg-white rounded-lg font-normal gap-1 text-gray-900 ring-0 border border-gray-300"
                      />
                    )}
                    {isAlreadyMember && <span className="text-xs text-gray-500">Already a member</span>}
                  </div>
                </div>
              </div>
              <div className="h-[1px] w-full bg-gray-200"></div>
            </div>
          );
        })}
      </div>
    );
  }

  if (users?.length === 0 && validateEmail(searchTerm)) {
    const isAlreadyInvited = pendingInvitationsEmails?.find((email) => email === searchTerm);
    content = (
      <div className="flex w-full">
        <div
          role="button"
          tabIndex={0}
          className={classNames(
            userForInvite?.email === searchTerm ? 'bg-gray-100' : '',
            isAlreadyInvited ? 'pointer-events-none bg-gray-100 opacity-70' : '',
            'flex w-full items-center justify-between rounded-xl border-b border-gray-200 px-2 py-4 last:border-none hover:cursor-pointer'
          )}
          onClick={handleNewUserSelect}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleNewUserSelect();
            }
          }}
        >
          <div className="flex w-8/12 gap-4">
            <div className="flex h-[48px] w-[48px] items-center justify-center rounded-[999px] border border-dashed border-gray-400">
              <div className="flex h-[48px] w-[48px] items-center justify-center rounded-[999px]">
                <MUIcon name="person" className="text-gray-500" />
              </div>
            </div>
            <div className="flex w-10/12 flex-col justify-center gap-1 py-1 text-start">
              <div className="flex flex flex-col gap-1 text-sm font-semibold text-gray-900">
                <span className="truncate" data-rh={searchTerm}>
                  {searchTerm}
                </span>
                {!isAlreadyInvited && (
                  <span className="text-start text-xs font-normal text-gray-500">invite by email</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-4/12 items-center justify-end">
            {isAlreadyInvited ? (
              <span className="text-xs text-gray-400">Invite pending</span>
            ) : (
              <div>
                <Dropdowns.Dropdown
                  hAlignment="right"
                  customLabel={userRole.name}
                  icon={<MUIcon name="arrow_drop_down" />}
                  onSelect={(opt) => setUserRole(opt)}
                  options={dropdownOptions}
                  triggerClasses="bg-white rounded-lg font-normal gap-1 text-gray-900 ring-0 border border-gray-300"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      title={`Add a new user to ${activeWorkspace.name}`}
      okLabel="Send Invite"
      okDisabled={!userForInvite}
      onDone={inviteUser}
      onCloseRequest={onClose}
      className="w-[512px]"
    >
      <div className="flex flex-col gap-6 bg-white">
        <div className="flex flex-col items-center justify-center gap-2">
          <Inputs.Input
            classes="h-[64px] w-[430px]"
            prefixComponent={
              <div className="-ml-1.5 flex h-[48px] w-[48px] items-center justify-center rounded-[999px] bg-gray-100">
                <MUIcon name="person_add" className="text-gray-600" />
              </div>
            }
            autoFocus
            value={searchTerm}
            placeholder="Enter email"
            onChange={onInputChange}
            onClear={() => setSearchTerm('')}
          />
          <span className="text-xs text-gray-500">* Enter a full email address to invite new users</span>
        </div>
        <div className="h-[240px] max-h-[240px] w-full overflow-y-auto">
          {content}
          {searchTerm === '' && users && (
            <div className="flex w-full flex-col items-center justify-center gap-2 rounded-lg px-6 py-8">
              <div className="flex w-8/12 flex-col gap-3">
                <img src="../../../assets/users_image.png" alt="Description" />
                <div className="text-center text-xs text-gray-700">
                  If the user already has an account, it will appear here so you can add it immediately.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InviteUser;
