import { User } from '@platform/shared/ui';
import React from 'react';
import { usePeUser } from '../../hooks';

type Props = {
  userId: number;
};

const UserAvatar: React.FC<Props> = ({ userId }: Props) => {
  const userQuery = usePeUser(userId);

  return <div className="ml-auto pl-6">{userQuery.data && <User.Avatar user={userQuery.data} />}</div>;
};

export default UserAvatar;
