import { Menu } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useDataSourceLayers } from '../../hooks';

interface Props {
  surveyName: string;
  geoFeatures: ProjectTypes.GeoFeatureSelection[];
  isUserSurvey: boolean;
}

const GeoSelectionSummary: React.FC<Props> = ({ surveyName, geoFeatures, isUserSurvey }) => {
  const dataLayersQuery = useDataSourceLayers(surveyName, isUserSurvey);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  if (!geoFeatures.length) return null;

  const [first] = geoFeatures;

  if (dataLayersQuery.isLoading || dataLayersQuery.isIdle) return <>Loading geography selection</>;
  if (dataLayersQuery.isError) return <>Something went wrong</>;

  const dataSource = dataLayersQuery.data.find((ds) => ds['summary-level'].id === first.summaryLevelId);
  const trigger = <span className="text-primary-600 text-xs font-semibold">View list</span>;

  if (geoFeatures.length === 1) {
    return <span className="flex-grow truncate py-1 text-sm font-semibold text-gray-800">{first.label}</span>;
  }

  return (
    <div className="flex flex-grow flex-col gap-2 py-1">
      <div className="text-sm font-semibold">{`${geoFeatures.length} ${pluralize(
        dataSource?.['summary-level']?.label ?? 'Geography',
        geoFeatures.length
      )} selected`}</div>
      <Menu
        visible={isOpen}
        onToggleVisibility={setIsOpen}
        trigger={trigger}
        hAlignment="left"
        menuClasses="py-1 mt-2 max-h-[308px] max-w-[272px] min-w-[260px]"
        vAlignment="bottom"
      >
        <div className="flex flex-col gap-1 overflow-auto py-3 px-4">
          {geoFeatures.map((x) => (
            <span className="text-sm text-gray-800" key={x.fips}>
              {x.label}
            </span>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default GeoSelectionSummary;
