import { PeTypes } from '@platform/types';
import React from 'react';
import { UserMenu } from '../shared';
import StoryOptionsMenu from './Edit/StoryOptionsMenu';

interface Props {
  story: PeTypes.Story;
  isEditMode: boolean;
}

const StoryHeader: React.FC<Props> = ({ story, isEditMode }) => (
  <div className={`flex grow items-center justify-end gap-2 px-6 py-4`}>
    <div className="flex items-center gap-4">
      <StoryOptionsMenu isEdit={isEditMode} story={story} />
      <UserMenu avatarClassNames="shadow-lg" />
    </div>
  </div>
);

export default StoryHeader;
