import { Buttons, Inputs } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as peApi from '../../../pe.api';

type Inputs = {
  email: string;
};

interface Props {
  setUserEmail: (email: string) => void;
}

const ResetPasswordForm: React.FC<Props> = ({ setUserEmail }) => {
  const resetPasswordMutation = useMutation({
    mutationFn: (payload: PeTypes.ResetPasswordPayload) => peApi.resetPassword(payload),
    onSuccess: () => setUserEmail(control._fields['email']?._f.value as string),
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { email: '' } });
  const watchedFormData = watch();
  const onSubmit: SubmitHandler<Inputs> = (data) => resetPasswordMutation.mutate(data);

  return (
    <div className="flex h-full flex-col justify-center gap-14">
      <div className="flex flex-col gap-8">
        <div className="px-10 text-2xl font-bold">Forgot your password?</div>
        <p className="px-10 text-base text-gray-600">Enter your email address and we will send you the reset link.</p>
      </div>
      <div className="flex w-full flex-col gap-8 px-8">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6" data-cy="login-form">
          <div>
            <label htmlFor="email" className="mb-2 block text-xs font-semibold text-gray-800">
              Email
            </label>
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              render={({ field }) => (
                <Inputs.Input
                  classes={'rounded-lg h-[42px]'}
                  placeholder="Email"
                  type="email"
                  id="email"
                  data-cy="email-input"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
            {errors.email && <span className="text-red-400">{errors.email.message}</span>}
          </div>
          <Buttons.Primary
            type="submit"
            data-cy="submit-button"
            className="h-[40px]"
            disabled={!watchedFormData.email.length || resetPasswordMutation.isLoading}
          >
            Reset password
          </Buttons.Primary>
          {resetPasswordMutation.isError && (
            <div className="text-red-400" data-cy="error-message">
              Failed to reset password
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
