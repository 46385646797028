import { CompareLegend } from '@platform/shared/integrated-ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';

const LEGEND_WIDTH = 350;

interface Props {
  id: string;
  mapObjects: ProjectTypes.MapObject[];
}

const ExportLegend: React.FC<Props> = ({ id, mapObjects }) => (
  <div id={id} className="flex flex-col gap-4 p-4" style={{ width: LEGEND_WIDTH }}>
    <CompareLegend mapObjects={mapObjects} isInEditMode={false} />
  </div>
);

export default ExportLegend;
