import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Menu, { MenuTypes } from '../Menu';
import MUIcon from '../MUIcon';

export interface InputOption {
  name: string;
  value: string;
  dividerAfter?: boolean;
  disabled?: boolean;
  additionalStyles?: string;
  icon?: React.ReactNode;
}

interface Props {
  inputClasses?: string;
  value?: string;
  options: InputOption[];
  hAlignment?: MenuTypes.HorizontalAlignmentType;
  menuWidth?: MenuTypes.MenuWidthType;
  vAlignment?: MenuTypes.VerticalAlignmentType;
  menuClasses?: string;
  listClasses?: string;
  optionClasses?: string;
  disabled?: boolean;
  onSelect: (option: InputOption) => void;
  fallback?: React.ReactNode;
}

const InputWithOptions: React.FC<Props> = ({
  inputClasses,
  value,
  options,
  hAlignment,
  vAlignment,
  menuWidth,
  menuClasses,
  listClasses,
  optionClasses = 'flex gap-2 items-center w-full select-none cursor-pointer px-4 py-2 text-left text-sm hover:bg-gray-100',
  disabled,
  onSelect,
  fallback,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(value ?? '');

  useEffect(() => {
    setSearchTerm(value ?? '');
  }, [value]);

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleInputBlur = () => {
    if (searchTerm !== value && !isOpen) {
      onSelect({ name: searchTerm, value: searchTerm });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    onSelect({ name: searchTerm, value: searchTerm });
    setIsOpen(false);
  };

  const trigger = (
    <div
      className={classNames(
        'flex h-[44px] w-full items-center rounded-lg border border-gray-200 bg-white focus:outline-none'
      )}
    >
      <input
        className={classNames('truncate text-sm text-gray-800 focus:outline-none', inputClasses)}
        placeholder={isOpen ? 'Search' : 'Select an option'}
        value={searchTerm}
        onChange={(e) => handleInputValueChange(e)}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyPress}
      />
      <MUIcon
        name={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        className="mr-2 select-none text-gray-600"
        iconStyle="filled"
      />
    </div>
  );

  const dropdownOptions = searchTerm
    ? options.filter((option) => searchTerm === value || option.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : options;

  let menuContent;

  if (fallback) {
    menuContent = fallback;
  } else if (dropdownOptions.length > 0) {
    menuContent = (
      <div className={classNames('flex flex-col py-2 text-sm', listClasses)}>
        <div className="flex flex-col">
          {dropdownOptions.map((option) => (
            <div key={option.value}>
              <button
                className={classNames(
                  'py-3',
                  option.value === value ? 'bg-primary-100 flex justify-between  text-gray-800' : 'text-gray-600',
                  option.additionalStyles ? `${optionClasses} ${option.additionalStyles}` : optionClasses,
                  {
                    'pointer-events-none opacity-40': option.disabled,
                  }
                )}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onSelect(option);
                  setIsOpen(false);
                }}
              >
                <div className="flex items-center gap-2 break-normal">
                  {option.icon}
                  {option.name}
                </div>
                {option.value === value}
              </button>
              {option.dividerAfter && <div className="h-[1px] w-full bg-gray-200" />}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    menuContent = <div className="p-3 text-sm text-gray-800">No results</div>;
  }

  return (
    <Menu
      disabled={disabled}
      trigger={trigger}
      vAlignment={vAlignment}
      hAlignment={hAlignment}
      menuWidth={menuWidth}
      menuClasses={menuClasses}
      visible={isOpen}
      onToggleVisibility={setIsOpen}
      menuMargin={6}
    >
      {menuContent}
    </Menu>
  );
};

export default InputWithOptions;
