import { Dropdowns, Modal, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { useSessionContext } from '../../../contexts/SessionContext';
import * as peApi from '../../../pe.api';

type Inputs = {
  title: string;
  workspaceId: number;
};

interface Props {
  datasetId: string;
  datasetName: string;
  workspaces: PeTypes.Workspace[];
  activeWorkspace: PeTypes.Workspace;
  onCloseRequest: () => void;
  onFail?: () => void;
  filter?: RuleGroupType;
  titlePrefix?: string;
}

const sortByName = (workspaces: PeTypes.Workspace[]) => workspaces.sort((a, b) => a.name.localeCompare(b.name));

const CopyDatasetModal: React.FC<Props> = ({
  datasetId,
  datasetName,
  filter,
  onFail,
  onCloseRequest,
  workspaces,
  activeWorkspace,
}) => {
  const { notify } = useSessionContext();
  const withFiltersApplied = filter && filter.rules.length > 0;
  const titlePrefix = withFiltersApplied ? 'Filtered copy of' : 'Copy of';
  const modalTitle = withFiltersApplied ? 'Create new dataset from filter' : 'Copy dataset';
  const okLabel = withFiltersApplied ? 'Save' : 'Make a copy';

  const {
    watch,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { title: `${titlePrefix} ${datasetName}`, workspaceId: activeWorkspace.id } });

  const copyDatasetMutation = useMutation({
    mutationFn: (payload: PeTypes.CopyDatasetPayload) => peApi.copyDataset(datasetId, payload),
  });

  const sortedWorkspaces = sortByName(workspaces);

  const options: Dropdowns.DropdownOption[] = sortedWorkspaces.map((workspace) => ({
    value: workspace.id,
    name: workspace.name,
    icon: (
      <img
        src={`${workspace.imageURL}?${workspace.updatedAt}`}
        alt={workspace.name}
        className="h-[32px] w-[32px] rounded-md"
      />
    ),
  }));

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    copyDatasetMutation.mutate(
      { datasetId: datasetId, title: data.title, ownerId: data.workspaceId, filter },
      {
        onSuccess: () => {
          const title = watch('title');
          notify({
            hideAfterSec: 2,
            content: `Creating "${title}"...`,
            icon: <MUIcon className="animate-spin" name="progress_activity" />,
          });
          onCloseRequest();
        },
        onError: () => {
          onFail?.();
        },
      }
    );
  };

  return (
    <Modal
      title={modalTitle}
      okLabel={okLabel}
      onDone={handleSubmit(onSubmit)}
      okDisabled={copyDatasetMutation.isLoading}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
      className="w-[480px]"
    >
      <div className="flex max-h-[600px] flex-col overflow-y-auto py-4">
        <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-col gap-8">
          <div>
            <label htmlFor="title" className="mb-2 block text-sm font-bold text-gray-900">
              Name
            </label>
            <input
              autoFocus
              id="title"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:outline-blue-500"
              {...register('title', { required: 'Name is required' })}
            />
            {errors.title && <span className="text-red-400">{errors.title.message}</span>}
          </div>
          <Controller
            name="workspaceId"
            control={control}
            rules={{ required: 'Workspace is required' }}
            render={({ field }) => {
              return (
                <div>
                  <label htmlFor="dataType" className="mb-2 block text-sm font-semibold text-gray-800">
                    Workspace
                  </label>
                  <Dropdowns.Dropdown
                    options={options}
                    value={field.value}
                    menuWidth="full"
                    triggerLabelClasses="flex items-center"
                    triggerClasses="w-full flex items-center hover:bg-gray-100 border border-gray-300 ring-0"
                    listClasses="max-h-[280px]"
                    onSelect={(opt) => field.onChange(opt.value)}
                  />
                  {errors.workspaceId && <span className="text-red-400">{errors.workspaceId.message}</span>}
                </div>
              );
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CopyDatasetModal;
