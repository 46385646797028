import { PeTypes } from '@platform/types';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';

const useCreateStory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspaceContext();
  const createMutation = useMutation({
    mutationFn: (payload: PeTypes.CreateStoryPayload) => peApi.createStory(payload),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
      queryClient.invalidateQueries({ queryKey: ['recent-stories'] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects'] });
      navigate(generateLink('story', { workspaceId: activeWorkspace.id, storyId: id, mode: 'edit' }));
    },
  });

  return {
    isCreating: createMutation.isLoading,
    create: (payload: PeTypes.CreateStoryPayload) => createMutation.mutate(payload),
  };
};

export default useCreateStory;
