import { Buttons, Dropdowns, Inputs, Modal, MUIcon } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useWorkspaceContext } from '../../../contexts/WorkspaceContext';
import { useDatasetColumns, useDatasetData } from '../../../hooks';
import * as peApi from '../../../pe.api';

const SAMPLE_SIZE = 5;

type Inputs = {
  columnIds: string[];
  separator: string;
};

interface Props {
  datasetId: string;
  onCloseRequest: () => void;
}

const CombineDatasetColumnsModal: React.FC<Props> = ({ onCloseRequest, datasetId }) => {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { columnIds: [], separator: ',' } });
  const watchedFormData = watch();
  const { activeWorkspace } = useWorkspaceContext();
  const columnsQuery = useDatasetColumns({ datasetId, itemsPerPage: 99999 });
  const IDColumn = columnsQuery.data?.find((c) => c.type === DatasetTypes.DataType.ID)?.uuid ?? '';
  const queryClient = useQueryClient();

  const dataQuery = useDatasetData({
    datasetId,
    IDColumn,
    itemsPerPage: SAMPLE_SIZE,
    columnIds: IDColumn && watchedFormData.columnIds.length > 0 ? [IDColumn, ...watchedFormData.columnIds] : [],
  });

  const combineColumnsMutation = useMutation({
    mutationFn: (payload: PeTypes.CombineColumnsPayload) => peApi.combineDatasetColumns(datasetId, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['datasets', activeWorkspace.id]);
      await queryClient.invalidateQueries(['dataset-log', datasetId]);
      onCloseRequest?.();
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => combineColumnsMutation.mutate(data);

  return (
    <Modal
      title="Combine columns"
      okLabel="Combine"
      okDisabled={combineColumnsMutation.isLoading || watchedFormData.columnIds.length < 2}
      className="w-[600px]"
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex min-h-[350px] flex-col  gap-4">
        <div className="flex gap-6">
          <Controller
            name="columnIds"
            control={control}
            rules={{ required: 'Column is required' }}
            render={({ field }) => {
              const columnOptions: Dropdowns.DropdownOption[] =
                columnsQuery.data
                  ?.filter((col) => col.uuid !== IDColumn && !field.value.includes(col.uuid))
                  .map((col) => ({
                    name: col.title,
                    value: col.uuid,
                  })) ?? [];
              return (
                <div className="flex flex-col">
                  <label htmlFor="selectColumns" className="mb-2 block text-sm font-bold text-gray-900">
                    Columns
                  </label>
                  <Dropdowns.Dropdown
                    options={columnOptions}
                    placeholder="Select column"
                    triggerClasses="h-[42px]"
                    onSelect={(opt) => field.onChange([...field.value, opt.value])}
                  />
                </div>
              );
            }}
          />
          <div>
            <label htmlFor="separator" className="mb-2 block text-sm font-semibold text-gray-900">
              Separator
            </label>
            <input
              id="separator"
              className="focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none"
              {...register('separator')}
            />
            {errors.separator && <span className="text-red-400">{errors.separator.message}</span>}
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {watchedFormData.columnIds.map((selectedColId) => (
            <div className="flex gap-2 rounded-md bg-gray-100 px-2 py-1" key={selectedColId}>
              {columnsQuery.data?.find((x) => x.uuid === selectedColId)?.title}
              <Buttons.Link
                icon={<MUIcon name="close" />}
                className="p-1"
                onClick={() =>
                  setValue(
                    'columnIds',
                    watchedFormData.columnIds.filter((f) => f !== selectedColId)
                  )
                }
              />
            </div>
          ))}
        </div>
        {watchedFormData.columnIds.length > 0 && (
          <div>
            <span>{`Sample (first ${SAMPLE_SIZE} rows):`}</span>
            <div className="flex flex-col">
              {dataQuery.data?.rows.map((rowData) => {
                const rowDataCopy = [...rowData];
                const [key] = rowDataCopy.slice(0, 1);
                return (
                  <div className="border-b" key={key as string}>
                    {rowDataCopy.splice(1).join(watchedFormData.separator ?? ', ')}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default CombineDatasetColumnsModal;
