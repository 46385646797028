import { Buttons } from '@platform/shared/ui';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

const WINDOW_OFFSET = 10;

interface Props {
  children: React.ReactNode;
  okLabel?: string;
  okDisabled?: boolean;
  hideActions?: boolean;
  cancelLabel?: string;
  className?: string;
  onDone: () => void;
  onCloseRequest?: () => void;
  position?: { top: number; left: number };
  style?: React.CSSProperties;
}

const DatasetCellEditModal: React.FC<Props> = ({
  children,
  okDisabled = false,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  onDone,
  onCloseRequest,
  className,
  style,
  position = { top: 0, left: 0 },
  hideActions = false,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number }>(position);

  useEffect(() => {
    if (modalRef.current) {
      const modalRect = modalRef.current.getBoundingClientRect();
      const { top, left } = modalPosition;

      const leftPositionDiff = left + modalRect.width + WINDOW_OFFSET - window.innerWidth;
      if (leftPositionDiff > 0) {
        setModalPosition({ top, left: left - leftPositionDiff });
      } else if (left - WINDOW_OFFSET < 0) {
        setModalPosition({ top, left: WINDOW_OFFSET });
      }
    }
  }, [modalRef]);
  return (
    <div data-te-modal-init className="fixed left-0 top-0 z-[1545455675] h-full w-full" id="edit-modal">
      <div
        data-te-modal-dialog-ref
        style={{ ...style, top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
        className={`pointer-events-none absolute w-full max-w-[300px] text-xs`}
      >
        <div
          ref={modalRef}
          className={classNames(
            'pointer-events-auto flex w-full flex-col rounded-lg border-2 border-gray-200 bg-white bg-clip-padding text-current shadow-2xl outline-none',
            className
          )}
        >
          {children}
          {!hideActions && (
            <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-neutral-200 border-opacity-100 py-2 px-2">
              <Buttons.Secondary className="text-xs" onClick={() => onCloseRequest?.()}>
                {cancelLabel}
              </Buttons.Secondary>
              <Buttons.Primary className="text-xs" disabled={okDisabled} onClick={onDone}>
                {okLabel}
              </Buttons.Primary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DatasetCellEditModal;
