import { useEffect } from 'react';

import { MetadataTypes, ProjectTypes } from '@platform/types';
import { Dropdown, DropdownOption } from '../../Dropdowns';

export interface Props {
  surveys: ProjectTypes.SurveyInfo[];
  selectedSurveyName: string;
  selectedDatasetAbbr: string | undefined;
  onChange: ({ dataset, survey }: { dataset: MetadataTypes.DataSet; survey: ProjectTypes.SurveyInfo }) => void;
  disableSurveyChange: boolean;
}

export const DatasetPicker: React.FC<Props> = ({
  surveys,
  selectedDatasetAbbr,
  selectedSurveyName,
  onChange,
  disableSurveyChange,
}) => {
  useEffect(() => {
    // do preselect a dataset if selectedDatasetAbbr is undefined
    if (!selectedDatasetAbbr && surveys) {
      onChange({ dataset: Object.values(surveys[0].metadata.datasets)[0], survey: surveys[0] });
    }
  }, [selectedDatasetAbbr, surveys, onChange]);

  const selectedSurvey = surveys.find((s) => s.metadata.name === selectedSurveyName);
  if (!selectedSurvey) return <>Survey not found</>;

  const handleSurveyChange = (item: DropdownOption) => {
    const newlySelectedSurvey = surveys.find((s) => s.metadata.uuid === item.value);

    if (!newlySelectedSurvey) return;
    // nothing has changed so return;
    if (newlySelectedSurvey.metadata.name === selectedSurveyName) return;
    const ds = getVisibleDatasets(newlySelectedSurvey, surveys)[0];
    if (!ds) return;

    onChange({ dataset: ds, survey: newlySelectedSurvey });
  };

  const handleDatasetChange = (item: DropdownOption) => {
    const datasetsInSelectedSurvey = getVisibleDatasets(selectedSurvey, surveys);
    const newlySelectedDataset = datasetsInSelectedSurvey.find((ds) => ds.uuid === item.value);
    if (!newlySelectedDataset) return;

    // nothing has changed so return;
    if (newlySelectedDataset.abbrevation === selectedDatasetAbbr) return;

    onChange({ dataset: newlySelectedDataset, survey: selectedSurvey });
  };

  const datasets = getVisibleDatasets(selectedSurvey, surveys);
  const userSurveys = surveys
    .filter((survey) => survey.isUserSurvey)
    .map((s) => ({
      value: s.metadata.uuid,
      name: `${s.metadata.displayName} ${s.enabled ? '' : '- disabled'}`,
      additionalStyles: s.enabled ? '' : 'italic text-gray-200',
    }));

  const seSurveys = surveys
    .filter((survey) => !survey.isUserSurvey)
    .map((s) => ({ value: s.metadata.uuid, name: s.metadata.displayName }));

  let surveyOptions: DropdownOption[] = [...seSurveys];

  if (userSurveys.length) {
    surveyOptions = [
      { value: '', name: 'Workspace Surveys', disabled: true, additionalStyles: 'pb-1 pt-1' },
      ...userSurveys,
      { value: '', name: 'Social Explorer Surveys', disabled: true, additionalStyles: 'pb-1 pt-1' },
      ...seSurveys,
    ];
  }
  const dataSetOptions = datasets.map((ds) => ({ value: ds.uuid, name: ds.displayName }));
  const selectedDataset = datasets.find((ds) => ds.abbrevation === selectedDatasetAbbr);

  return (
    <div className="flex flex-col gap-2 bg-gray-100 py-3 px-6">
      <Dropdown
        triggerClasses="w-full p-2.5 pl-4 pr-3 font-normal rounded-full"
        onSelect={handleSurveyChange}
        value={selectedSurvey.metadata.uuid}
        disabled={disableSurveyChange || surveyOptions.length < 2}
        options={surveyOptions}
        triggerPrefix="Survey"
        menuWidth="full"
      />
      <Dropdown
        triggerClasses="w-full p-2.5 pl-4 pr-3 font-normal rounded-full"
        onSelect={handleDatasetChange}
        value={selectedDataset?.uuid}
        options={dataSetOptions}
        triggerPrefix="Dataset"
        menuWidth="full"
      />
    </div>
  );
};

export default DatasetPicker;

const getVisibleDatasets = (
  survey: ProjectTypes.SurveyInfo,
  loadedSurveys: ProjectTypes.SurveyInfo[]
): MetadataTypes.DataSet[] => {
  const { name, datasets } = survey.metadata;
  const visibleDatasets = loadedSurveys.find((s) => s.metadata.name === name)?.availableDatasets;
  const availableDatasets = Object.values(datasets).filter((dataset) => visibleDatasets?.includes(dataset.abbrevation));
  return availableDatasets.length ? availableDatasets : Object.values(datasets);
};
