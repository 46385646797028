export enum VariableType {
  COUNT = 'Count',
  MEDIAN = 'Median',
  PERCENT = 'Percent',
  STANDARD_ERROR = 'StandardError',
  AVERAGE = 'Average',
  RATE = 'Rate',
  QUINTILE = 'Quintile',
  COMPUTED_INDEX = 'ComputedIndex',
  NONE = 'None',
}

export interface Metadata {
  surveys: Survey[];
  systemCategoryFilters: SystemCategoryFilters[];
}

export interface SystemCategoryFilters {
  name: string;
  valueFormat: string;
  filterSets: FilterSet[];
}

export interface FilterSet {
  valueFormat: string;
  filters: Filter[];
}

export interface Filter {
  // for numeric vars
  from?: string;
  to?: string;
  // or for textual variables (label and value)
  label?: string;
  valueStr?: string;
  // for enumerated
  valueNum?: string;
}

export interface Report {
  uuid: string;
}

export interface Survey {
  uuid: string;
  year: number;
  yearTo: number;
  yearFrom: number;
  priority: number;
  abbreviation: string;
  productTags: string[];
  name: string;
  publisher: string;
  displayName: string;
  index: number;
  reports: unknown;
  geoTypes: unknown;
  datasets: Record<string, DataSet>;
  isUserSurvey: boolean;
  enabled?: boolean;
}

export interface DataSet {
  uuid: string;
  index: number;
  surveyName: string | null;
  name: string;
  publisher: string;
  source: string;
  displayName: string;
  partial: boolean;
  productTags: string[];
  dataCategoriesNames: string[];
  abbrevation: string;
  tables: Record<string, Table>;
}

export interface Table {
  uuid: string;
  displayName: string;
  surveyName: string;
  datasetName: string;
  visible: 'True' | 'False';
  notes: string;
  universe: string;
  name: string;
  variables: Variable[];
  title: string;
  titleShort: string;
  categoryPriorityOrder: unknown;
  categories: unknown;
  showOnFirstPage: unknown;
  defaultFilterSetName: string | null;
  productTags: unknown;
  percentBaseMin: number;
  suggestedColorPaletteName: string | null;
  suggestedColorPaletteInverse: boolean;
  suggestedColorPaletteType: string | null;
  tableMapInfo: string | null;
  index: number;
}

export interface Variable {
  uuid: string;
  name: string;
  label: string;
  qLabel: string;
  notes: string;
  dataType: 'Double64' | 'Int32' | 'Int64' | 'String' | 'Int16';
  formatting: 'Number1Decimal' | 'Currency0Decimal' | 'Currency2Decimal' | 'NumberNoDecimal';
  indent: number;
  suggestedPaletteName: string | null;
  suggestedPaletteInverse: boolean;
  suggestedPaletteType: string | null;
  varType: VariableType;
  tooltipCustom: string | null;
  bubbleSizeHint: number;
  defaultFilterSetName: string | null;
  dbFieldName: unknown;
  mapInfoButtonHtmlText: unknown;
  preferredVisualizationType: unknown;
  preferredVisualizationValueType: unknown;
}

export interface Style {
  'color-palettes': string;
  metadata?: {
    defaultMinZoom?: number;
    defaultMaxZoom?: number;
    socialexplorer: {
      'data-source-layers'?: MetadataSocialExplorerDataSourceLayer[];
    };
  };
}

export interface MetadataSocialExplorerDataSourceLayer {
  id: string;
  'geo-layer-id': string;
  'summary-level': MetadataSummaryLevel;
  datasets: {
    columns?: string[];
    'dataset-id': string;
    'dataset-abbreviation'?: string;
    'primary-key-column'?: string;
    'geo-name-column'?: string;
    'geo-qualified-name-column'?: string;
  }[];
}

export interface MetadataSummaryLevel {
  id: string;
  title: string;
  label: string;
  minzoom: number;
  maxzoom: number;
}

export interface ColorPalette {
  id: string;
  title: string;
  type: string;
  colors: string[];
  'insufficient-data-color': string;
  'null-data-color': string;
  'stroke-colors': string[];
  'color-ramps': ColorRamp[];
}

export interface ColorRamp {
  from: string;
  to: string;
  bias: number;
}
