import classNames from 'classnames';
import React, { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Secondary } from '../Buttons';
import Menu, { MenuTypes } from '../Menu';
import MUIcon from '../MUIcon';
import { DropdownOption, DropdownOptionValue } from './index';

export interface Props {
  value?: DropdownOptionValue;
  customLabel?: React.ReactNode;
  placeholder?: React.ReactNode;
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => void;
  disabled?: boolean;
  openByDefault?: boolean;
  title?: string;
  triggerPrefix?: string;
  triggerClasses?: string;
  triggerWrapperClasses?: string;
  triggerLabelClasses?: string;
  triggerContentClasses?: string;
  hAlignment?: MenuTypes.HorizontalAlignmentType;
  menuWidth?: MenuTypes.MenuWidthType;
  vAlignment?: MenuTypes.VerticalAlignmentType;
  menuClasses?: string;
  icon?: JSX.Element | null;
  iconClose?: JSX.Element | null;
  header?: JSX.Element;
  selectedIcon?: JSX.Element;
  prefixIcon?: JSX.Element;
  listClasses?: string;
  onTriggerClick?: (e: React.MouseEvent) => void;
  onVisibilityChange?: (visible: boolean) => void;
  onClear?: () => void;
  clearIcon?: JSX.Element | null;
  name?: string;
  hideIconInTrigger?: boolean;
}

interface TriggerProps {
  children: React.ReactNode;
}

const DropdownTrigger = forwardRef(({ children }: TriggerProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div className="w-full" ref={ref}>
      {children}
    </div>
  );
});

export const Dropdown: React.FC<Props> = ({
  customLabel,
  value,
  options,
  onSelect,
  disabled,
  triggerPrefix,
  placeholder,
  icon = <MUIcon name="arrow_drop_down" className="text-gray-600" iconStyle="filled" />,
  iconClose = <MUIcon name="arrow_drop_up" className="text-gray-600" iconStyle="filled" />,
  menuClasses = '',
  triggerClasses = '',
  triggerWrapperClasses = '',
  triggerLabelClasses = '',
  triggerContentClasses = 'gap-2',
  hAlignment = 'left',
  vAlignment = 'bottom',
  menuWidth = 'auto',
  openByDefault = false,
  header,
  selectedIcon = <MUIcon name="check_circle" className="text-primary-600" />,
  prefixIcon,
  listClasses = 'max-h-[400px]',
  title,
  onTriggerClick,
  onVisibilityChange,
  onClear,
  clearIcon = <MUIcon name="close" className="text-gray-600" />,
  name,
  hideIconInTrigger = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);
  const [openNestedOption, setOpenNestedOption] = useState<{ value: DropdownOption; side: 'left' | 'right' } | null>(
    null
  );
  const parentRefs = useRef<HTMLButtonElement[] | null[]>([]);

  useEffect(() => {
    onVisibilityChange?.(isOpen);
  }, [isOpen]);

  const triggerRef = useRef<HTMLDivElement>(null);

  const Trigger = useMemo(() => {
    let triggerContent = placeholder ? (
      <span className="w-full text-sm font-normal text-gray-600">{placeholder}</span>
    ) : undefined;

    const selectedOption = options.find((opt) => opt.value === value);

    if (selectedOption) {
      triggerContent = (
        <div className="flex items-center">
          {selectedOption.icon && !hideIconInTrigger && <div className="mr-3">{selectedOption.icon}</div>}
          <div>{selectedOption.name}</div>
        </div>
      );
    }

    if (customLabel) {
      triggerContent = <>{customLabel}</>;
    }

    let triggerIcon = icon;
    if (value && onClear && clearIcon) {
      const handleClearClick = () => onClear?.();
      triggerIcon = (
        <div
          onKeyDown={handleClearClick}
          onClick={handleClearClick}
          className="flex hover:rounded-full hover:bg-gray-50"
        >
          {clearIcon}
        </div>
      );
    } else if (isOpen) {
      triggerIcon = iconClose || icon;
    }

    return (
      <DropdownTrigger ref={triggerRef}>
        <Secondary
          data-rh={title}
          disabled={disabled}
          icon={triggerIcon}
          onClick={onTriggerClick}
          data-cy={name ? `dropdown-trigger-${name}` : undefined}
          className={`flex flex-row-reverse justify-between shadow-none ${triggerClasses}`}
        >
          {(triggerContent || prefixIcon) && (
            <div className={`flex w-full min-w-0 empty:hidden ${triggerContentClasses}`}>
              {prefixIcon}
              {triggerPrefix && (
                <div className="flex items-center gap-2">
                  <span>{triggerPrefix}</span>
                  <div className="h-[3px] w-[3px] rounded bg-gray-300" />
                </div>
              )}
              {triggerContent && <span className={`truncate ${triggerLabelClasses}`}>{triggerContent}</span>}
            </div>
          )}
        </Secondary>
      </DropdownTrigger>
    );
  }, [
    isOpen,
    customLabel,
    disabled,
    icon,
    options,
    triggerClasses,
    triggerLabelClasses,
    triggerPrefix,
    prefixIcon,
    value,
  ]);

  const optionClasses =
    'flex gap-2 items-center w-full select-none cursor-pointer px-4 py-2 text-left text-sm hover:bg-primary-100';

  const handleOptionClick = (e: React.MouseEvent, option: DropdownOption, idx: number) => {
    e.stopPropagation();
    e.preventDefault();
    if (Array.isArray(option.value)) {
      const parentElement = parentRefs.current[idx];
      if (parentElement) {
        const rightEdgeDistance = window.innerWidth - parentElement.getBoundingClientRect().right;
        if (rightEdgeDistance <= 200) {
          setOpenNestedOption(openNestedOption?.value === option ? null : { value: option, side: 'right' });
        } else {
          setOpenNestedOption(openNestedOption?.value === option ? null : { value: option, side: 'left' });
        }
      }
    } else {
      onSelect(option);
      setOpenNestedOption(null);
      setIsOpen(false);
    }
  };

  const renderOptions = (options: DropdownOption[], isNested = false) => (
    <div
      className={`flex flex-col rounded-lg ${isNested ? 'min-w-[192px] bg-white p-1 shadow' : ''}`}
      data-cy="dropdown-menu"
    >
      {options.map((option, idx) => {
        const isSelected = !Array.isArray(option.value) && option.value === value;
        const parentHeight = parentRefs.current[idx]?.getBoundingClientRect().height || 0;
        return (
          <div key={`item-${idx}`}>
            <button
              ref={(el) => (parentRefs.current[idx] = el)}
              data-cy={option.dataCy}
              className={classNames(
                'py-3',
                isSelected ? 'bg-primary-100 flex justify-between text-gray-800' : 'text-gray-600',
                option.additionalStyles ? `${optionClasses} ${option.additionalStyles}` : optionClasses,
                {
                  'pointer-events-none opacity-40': option.disabled,
                }
              )}
              onClick={(e) => handleOptionClick(e, option, idx)}
            >
              <div className="flex w-full items-center justify-between">
                <div className={`flex items-center gap-2 ${option.nameClasses}`}>
                  {option.icon}
                  {option.name}
                </div>
                {option.suffixIcon && <div className="ml-auto">{option.suffixIcon}</div>}
              </div>
              {isSelected && selectedIcon}
            </button>
            {option.dividerAfter && <div key={`divider-${idx}`} className="h-[1px] w-full bg-gray-200" />}
            {Array.isArray(option.value) && openNestedOption?.value === option && (
              <div
                className={`absolute z-50 ml-1 py-2`}
                style={{
                  top: `${parentHeight * idx}px`,
                  [openNestedOption?.side]: '100%',
                }}
              >
                {renderOptions(option.value, true)}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <Menu
      name={name}
      trigger={Trigger}
      disabled={disabled}
      vAlignment={vAlignment}
      hAlignment={hAlignment}
      menuWidth={menuWidth}
      menuClasses={menuClasses}
      triggerClasses={triggerWrapperClasses}
      visible={isOpen}
      onToggleVisibility={setIsOpen}
      menuMargin={12}
    >
      <div className={classNames('flex flex-col py-2 text-sm', listClasses)}>
        {header && <div className="px-4 py-2 text-gray-500">{header}</div>}
        {renderOptions(options)}
      </div>
    </Menu>
  );
};

export default Dropdown;
