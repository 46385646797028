import { Menu, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import HeaderOptionsPicker from './HeaderOptionsPicker';

interface Props {
  isEdit: boolean;
  profileId: string;
}

const HeaderOptionsMenu: React.FC<Props> = ({ isEdit, profileId }) => {
  const { activeWorkspace } = useWorkspaceContext();
  const menuOptions: PeTypes.HeaderOptions[] = [
    {
      id: 1,
      icon: <MUIcon name="edit" className="text-gray-600" />,
      title: 'Edit mode',
      triggerTitle: 'Editing',
      label: 'Edit document directly — all changes will automatically be saved',
      linkTo: generateLink('map', { workspaceId: activeWorkspace.id, mapId: profileId, mode: 'edit' }),
    },
    {
      id: 2,
      icon: <MUIcon name="visibility" className="text-gray-600" />,
      title: 'View mode',
      triggerTitle: 'Viewing',
      label: 'View or present the content without making direct edits',
      linkTo: generateLink('map', { workspaceId: activeWorkspace.id, mapId: profileId, mode: 'view' }),
    },
    {
      id: 3,
      icon: <MUIcon name="play_arrow" className="text-gray-600" />,
      suffixIcon: <MUIcon name="open_in_new" className="text-[19px] text-gray-600" />,
      title: 'Presentation mode',
      triggerTitle: 'Viewing',
      label: 'View how it will look when shared with an audience',
      linkTo: generateLink('map', { workspaceId: activeWorkspace.id, mapId: profileId, mode: 'public' }),
      target: '_blank',
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const activeOption = isEdit ? menuOptions[0] : menuOptions[1];
  const active: JSX.Element = (
    <div
      className={`flex flex h-[36px] items-center rounded-[999px] px-3 font-semibold shadow-md ${
        isEdit ? 'border border-gray-200 bg-white' : 'bg-primary-100 border-primary-200 border'
      }`}
    >
      <div className="flex items-center justify-center gap-1">
        <div className="flex justify-center justify-center rounded-[8px]">{activeOption.icon}</div>
        <span className="flex grow items-center justify-center truncate px-2 text-sm">{activeOption.triggerTitle}</span>
        {isOpen ? (
          <MUIcon name="arrow_drop_up" className="shrink-0 text-gray-600"></MUIcon>
        ) : (
          <MUIcon name="arrow_drop_down" className="shrink-0 text-gray-600"></MUIcon>
        )}
      </div>
    </div>
  );

  return (
    <Menu
      visible={isOpen}
      onToggleVisibility={setIsOpen}
      trigger={active}
      hAlignment="right"
      menuClasses="py-0 mt-2"
      vAlignment="bottom"
    >
      <HeaderOptionsPicker
        menuOptions={menuOptions}
        activeOptionId={activeOption.id}
        menuClose={() => setIsOpen(false)}
      />
    </Menu>
  );
};

export default HeaderOptionsMenu;
