import React from 'react';
import { getTimeFromNow } from '../../helpers/helpers';

interface Props {
  dateTime: Date;
  className?: string;
  prefixLabel?: string;
}

const UpdatedAgo: React.FC<Props> = ({ dateTime, prefixLabel = 'Updated ', className = 'text-xs text-gray-600' }) => (
  <span className={className}>{`${prefixLabel}${getTimeFromNow(dateTime)}`}</span>
);

export default UpdatedAgo;
