import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import DatasetCellEditModal from './DatasetCellEditModal';

interface Props {
  value: number | null;
  onCloseRequest: () => void;
  anchorRect: DOMRect | { top: number; left: number; width: number };
  onDone: (value: number | null) => void;
}

type Input = {
  formValue: number | null;
};

const EditIntegerCellModal: React.FC<Props> = ({ value, onCloseRequest, anchorRect, onDone }) => {
  const { register, handleSubmit } = useForm<Input>({ values: { formValue: value } });
  const onSubmit: SubmitHandler<Input> = (data) => {
    const formValue = parseFloat(data.formValue?.toString() ?? '');
    if (value !== formValue) {
      onDone(data.formValue);
    }
    onCloseRequest();
  };

  return (
    <DatasetCellEditModal
      className="min-w-8 border-primary-700 shadow-none"
      position={{ top: anchorRect.top - 18, left: anchorRect.left - 16 }}
      style={{ width: anchorRect.width + 48 }}
      onCloseRequest={onCloseRequest}
      okLabel="Save"
      onDone={handleSubmit(onSubmit)}
    >
      <div className="flex min-h-[48px] items-center px-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            id="integer-cell"
            className="w-full border-0 p-0 text-xs font-normal focus:outline-none focus:ring-0"
            type="number"
            autoFocus
            {...register('formValue')}
          />
        </form>
      </div>
    </DatasetCellEditModal>
  );
};

export default EditIntegerCellModal;
