import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { useSessionContext } from '../../contexts/SessionContext';
import { useNavigateBack } from '../../hooks';
import { UserMenu } from '../shared';
import AccountPersonalInfo from './AccountInfo/AccountPersonalInfo';
import AccountSignInInformation from './AccountInfo/AccountSignInInformation';

const Account: React.FC = () => {
  const { user } = useSessionContext();
  const goBack = useNavigateBack();
  if (!user) return null;

  return (
    <div className="flex h-full w-full flex-col items-center bg-gray-100 ">
      <div className="flex h-[72px] w-full items-center justify-between border-b border-gray-200 bg-white px-20">
        <div className="flex items-center justify-between gap-12">
          <button onClick={goBack} className="flex items-center justify-center">
            <MUIcon name="arrow_back" />
          </button>
          <p className="font-semibold">My Account</p>
        </div>
        <UserMenu showManageYourAccount={false} />
      </div>
      <div className="flex h-full w-full items-start justify-center overflow-y-auto pb-4">
        <div className="mt-12 flex w-[668px] flex-col gap-12 rounded-xl">
          <div className="flex w-full flex-col items-center justify-center gap-2">
            <p className="text-3xl font-semibold text-gray-700">My Account</p>
            <p className="text-sm text-gray-600">Update your profile info, email, or password.</p>
          </div>
          <div className="flex w-full flex-col gap-12 rounded-lg">
            <AccountPersonalInfo user={user} />
            <AccountSignInInformation user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
