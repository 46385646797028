import { PeTypes } from '@platform/types';

export const IMAGE_FORMAT: Array<PeTypes.ImageDim> = [
  {
    label: 'Slide 16:9',
    width: 1920,
    height: 1080,
  },
  {
    label: 'Slide 4:3',
    width: 1600,
    height: 1200,
  },
  {
    label: 'Square 1:1',
    width: 1080,
    height: 1080,
  },
];
