import { metadataApi } from '@platform/api';
import { MetadataTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';

interface Props {
  surveyName: string;
  datasetAbbreviation: string;
  tableUuid: string;
}

function useTableMetadata(
  { surveyName, datasetAbbreviation, tableUuid }: Props,
  options?: UseQueryOptions<MetadataTypes.Table>
) {
  return useQuery<MetadataTypes.Table>(
    ['metadata/table', surveyName, datasetAbbreviation, tableUuid],
    () => metadataApi.loadTable(surveyName, datasetAbbreviation, tableUuid),
    options
  );
}

export default useTableMetadata;
