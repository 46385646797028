import { DatasetTypeIcon, MapComponents } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';
import Pattern from '../../../assets/pattern.png';
import VariableInfo from '../../VariableInfo';
import AutoAdjust from '../AutoAdjust';

const { HorizontalLegend } = MapComponents.Legend;

export interface Props {
  mapObj: ProjectTypes.MapObject;
  isInEditMode: boolean;
}

const Legend: React.FC<Props> = ({ mapObj, isInEditMode }) => {
  const { dataLayer, workingLayers } = mapObj.styleSpec;
  const { selection, rendering, filtersByData } = dataLayer;

  const legends = rendering.map((x, index) => (
    <div key={index} className="mt-1 flex flex-col gap-2">
      <VariableInfo def={selection[index]} showUniverse={false} />
      <HorizontalLegend
        key={selection[index].variableGuid}
        showLabels={index === rendering.length - 1}
        title={!isInEditMode && <VariableInfo def={selection[index]} />}
        insufficientData={x.insufficientData}
        cutPoints={x.cutPoints}
      />
    </div>
  ));

  const layersInfo = workingLayers.map((layer) => (
    <div key={layer.id} className="flex items-center justify-start gap-3 border-t border-gray-200 py-4 px-3">
      <div className="flex items-center justify-center">
        <DatasetTypeIcon type={layer.type} iconColor={layer.baseColor} backgroundClasses="px-0 py-0" />
      </div>
      <div className="truncate text-xs text-gray-700">{layer.name}</div>
    </div>
  ));

  const filterInfo = filtersByData?.filters.some((f) => f.value) && (
    <div className="flex items-center justify-start gap-3 border-t border-gray-200 py-4 px-3">
      <img className="h-[14px] w-[14px] rounded-xl" src={Pattern} alt="pattern" />
      <div className="w-62 flex items-center justify-start">
        <span className="whitespace-normal text-xs text-gray-700">Areas that don't meet the applied criteria</span>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2 py-2 px-3">{legends}</div>
      {isInEditMode && (
        <div className="flex items-center justify-end gap-1 px-4 pb-4">
          <AutoAdjust mapObjects={[mapObj]} />
        </div>
      )}
      {filterInfo}
      {layersInfo}
    </div>
  );
};

export default Legend;
