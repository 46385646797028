import { Dropdowns } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { useProjects } from '../../hooks';

interface Props {
  value?: string | null;
  onSelect: (p: PeTypes.Project) => void;
}

const ProjectsPicker: React.FC<Props> = ({ value, onSelect }) => {
  const { activeWorkspace } = useWorkspaceContext();
  const projectsQuery = useProjects(activeWorkspace.id);

  const options: Dropdowns.DropdownOption[] = projectsQuery.data?.map((x) => ({ name: x.title, value: x.id })) ?? [];

  const handleChange = (item: Dropdowns.DropdownOption) => {
    const project = projectsQuery.data?.find((x) => x.id === item.value);
    if (project) onSelect(project);
  };

  return (
    <Dropdowns.Dropdown
      placeholder="Select a project"
      value={value}
      options={options}
      onSelect={handleChange}
      menuWidth="full"
      name="select-project"
      triggerClasses="w-full rounded-lg border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
    />
  );
};

export default ProjectsPicker;
