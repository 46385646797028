import { DatasetTypes, PeTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import * as peApi from '../pe.api';

interface Prop {
  datasetId: string;
  columnIds: string[];
  itemsPerPage: number;
  IDColumn: string;
  filter?: RuleGroupType;
  sortBy?: string;
  sortOrder?: number;
  pageData?: DatasetTypes.DatasetPageData;
}

const useDatasetData = (
  {
    datasetId,
    columnIds,
    IDColumn,
    sortBy,
    sortOrder,
    itemsPerPage,
    filter = { combinator: 'and', rules: [] },
    pageData = {
      firstUuid: null,
      lastUuid: null,
      action: DatasetTypes.PageAction.FIRST,
    },
  }: Prop,
  options?: UseQueryOptions<PeTypes.QueryDataResponse>
) => {
  return useQuery<PeTypes.QueryDataResponse>(
    ['dataset-data', datasetId, columnIds, sortBy, sortOrder, pageData, filter],
    () =>
      peApi.getDatasetData({
        IDColumn,
        datasetId,
        filter,
        columnIds,
        itemsPerPage,
        sortBy,
        sortOrder,
        pageData,
      }),
    { enabled: columnIds.length > 0, ...options }
  );
};

export default useDatasetData;
