import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../assets/empty-state-analysis.svg';
import { useMapSidebarContext } from '../MapSidebar/MapSideBarContext';

const InfoPanelEmptyState: React.FC = () => {
  const { changeActiveView } = useMapSidebarContext();

  return (
    <div className="flex w-full flex-col items-center gap-12 p-14">
      <EmptyStateSVG></EmptyStateSVG>
      <div className="flex w-full flex-col items-center text-sm text-gray-700">
        <button
          onClick={() => changeActiveView('SEARCH')}
          className="text-primary-600 flex gap-3 rounded-full py-2 pl-3 pr-6 font-semibold ring-1 ring-gray-300 hover:bg-gray-100"
        >
          <MUIcon name="search" />
          Search
        </button>
        <div className="mt-4 mb-2">- or -</div>
        <div className="text-center">
          Click
          <MUIcon name="web_traffic" className="relative top-1 px-1" />
          on the map to get insights
          <p className="mt-1">about the selected area</p>
        </div>
      </div>
    </div>
  );
};
export default InfoPanelEmptyState;
