import { MetadataTypes, ProjectTypes } from '@platform/types';
import produce from 'immer';
import { LngLatLike } from 'maplibre-gl';
import { figureOutCutPoints, figureOutDataTheme } from './utils';

export const updateDataLayerSelection = async (
  oldStyleSpec: ProjectTypes.MapSpec,
  newSelection: ProjectTypes.VariableSelection[],
  colorPalettes: MetadataTypes.ColorPalette[],
  systemCategoryFilters: MetadataTypes.SystemCategoryFilters[]
) => {
  const newDataTheme = await figureOutDataTheme(newSelection, colorPalettes, systemCategoryFilters, {
    id: 'PE',
    type: 'sequential',
  });

  if (!newDataTheme) {
    console.warn("Can't figure out data theme. Exiting...");
    return;
  }

  return produce(oldStyleSpec, (draft) => {
    draft.dataLayer.selection = newSelection;
    draft.dataLayer.type = newDataTheme.type;
    draft.dataLayer.rendering = newDataTheme.rendering;
    draft.dataLayer.percentBasedVisualization = newDataTheme.percentBasedVisualization;
    draft.dataLayer.colorPalette = newDataTheme.colorPalette;
  });
};

export const updatePosition = (oldStyleSpec: ProjectTypes.MapSpec, zoom: number, center: LngLatLike) =>
  produce(oldStyleSpec, (draft) => {
    draft.zoom = zoom;
    draft.center = center;
  });

export const updateDataLayerSummaryLevel = (oldStyleSpec: ProjectTypes.MapSpec, summaryLevelId: string) =>
  produce(oldStyleSpec, (draft) => {
    draft.dataLayer.preferredSummaryLevelId = summaryLevelId;
  });
export const removeWorkingLayer = (oldStyleSpec: ProjectTypes.MapSpec, workingLayerId: string) =>
  produce(oldStyleSpec, (draft) => {
    draft.workingLayers = draft.workingLayers.filter((x) => x.id !== workingLayerId);
  });

export const setWorkingLayers = (oldStyleSpec: ProjectTypes.MapSpec, workingLayers: ProjectTypes.WorkingLayerSpec[]) =>
  produce(oldStyleSpec, (draft) => {
    draft.workingLayers = [...workingLayers];
  });

export const addWorkingLayer = (
  oldStyleSpec: ProjectTypes.MapSpec,
  addedWorkingLayers: ProjectTypes.WorkingLayerSpec[]
) =>
  produce(oldStyleSpec, (draft) => {
    draft.workingLayers = [...draft.workingLayers, ...addedWorkingLayers];
  });

export const updateWorkingLayer = (oldStyleSpec: ProjectTypes.MapSpec, workingLayer: ProjectTypes.WorkingLayerSpec) => {
  const workingLayerIndex = oldStyleSpec.workingLayers.findIndex((layer) => layer.id === workingLayer.id);
  return produce(oldStyleSpec, (draft) => {
    draft.workingLayers[workingLayerIndex] = workingLayer;
  });
};

export const updateGeoSelection = (
  oldStyleSpec: ProjectTypes.MapSpec,
  features: ProjectTypes.GeoFeatureSelection[] = []
) =>
  produce(oldStyleSpec, (draft) => {
    draft.geoFeatureSelection = features;
  });

export const adjustCutPoints = async (
  oldStyleSpec: ProjectTypes.MapSpec,
  newSteps: number[],
  currentColorPalette: MetadataTypes.ColorPalette
) => {
  const newCutPoints = await figureOutCutPoints(oldStyleSpec.dataLayer.selection, newSteps, currentColorPalette);

  if (!newCutPoints) {
    console.warn("Can't figure out cut points. Exiting...");
    return;
  }

  return produce(oldStyleSpec, (draft) => {
    draft.dataLayer.rendering = newCutPoints;
  });
};

export const addFilterByCriteria = (oldStyleSpec: ProjectTypes.MapSpec, filter: ProjectTypes.FiltersByData) =>
  produce(oldStyleSpec, (draft) => {
    draft.dataLayer.filtersByData = filter;
  });
