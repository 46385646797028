import { ProjectTypes } from '@platform/types';

const { ValueFormat } = ProjectTypes;

const formatterPercentUnsetFractions = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
  style: 'percent',
});
const formatterPercent = [0, 1, 2, 3, 4].map(
  (decimals) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      style: 'percent',
    })
);

const formatterCurrencyUnsetFractions = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
  style: 'currency',
  currency: 'USD',
});
const formatterCurrency = [0, 1, 2].map(
  (decimals) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      style: 'currency',
      currency: 'USD',
    })
);

const formatterNumberUnsetFractions = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
});
const formatterNumber = [0, 1, 2, 3, 4, 5].map(
  (decimals) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
);

const formatterNumberNoGroupingUnsetFraction = new Intl.NumberFormat('en-US', {
  useGrouping: false,
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
});
const formatterNumberNoGrouping = [0, 1, 2, 3, 4, 5].map(
  (decimals) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      useGrouping: false,
    })
);

export const format = (value: string | number | undefined, format = ValueFormat.FORMAT_NONE) => {
  if (value == null) {
    return null;
  }
  if (format === ValueFormat.FORMAT_NONE || typeof value === 'string') {
    return value.toString();
  }
  if (Number.isNaN(value) || !Number.isFinite(value)) {
    return null;
  }
  switch (format) {
    case ValueFormat.FORMAT_PERCENT:
      return formatterPercentUnsetFractions.format(value / 100);
    case ValueFormat.FORMAT_PERCENT_NO_DECIMAL:
      return formatterPercent[0].format(value / 100);
    case ValueFormat.FORMAT_PERCENT_1_DECIMAL:
      return formatterPercent[1].format(value / 100);
    case ValueFormat.FORMAT_PERCENT_2_DECIMAL:
      return formatterPercent[2].format(value / 100);
    case ValueFormat.FORMAT_PERCENT_3_DECIMAL:
      return formatterPercent[3].format(value / 100);
    case ValueFormat.FORMAT_PERCENT_4_DECIMAL:
      return formatterPercent[4].format(value / 100);

    case ValueFormat.FORMAT_REAL_PERCENT:
      return formatterPercentUnsetFractions.format(value);
    case ValueFormat.FORMAT_REAL_PERCENT_NO_DECIMAL:
      return formatterPercent[0].format(value);
    case ValueFormat.FORMAT_REAL_PERCENT_1_DECIMAL: {
      // Percentage values in (-0.05%, 0%] interval would be represented as -0.0%.
      // Following check remedies that.
      if (Math.abs(value) * 100 < 0.05) {
        value = 0;
      }
      return formatterPercent[1].format(value);
    }
    case ValueFormat.FORMAT_REAL_PERCENT_2_DECIMAL:
      return formatterPercent[2].format(value);
    case ValueFormat.FORMAT_REAL_PERCENT_3_DECIMAL:
      return formatterPercent[3].format(value);
    case ValueFormat.FORMAT_REAL_PERCENT_4_DECIMAL:
      return formatterPercent[4].format(value);

    case ValueFormat.FORMAT_CURRENCY:
      return formatterCurrencyUnsetFractions.format(value);
    case ValueFormat.FORMAT_CURRENCY_0_DECIMAL:
      return formatterCurrency[0].format(value);
    case ValueFormat.FORMAT_CURRENCY_1_DECIMAL:
      return formatterCurrency[1].format(value);
    case ValueFormat.FORMAT_CURRENCY_2_DECIMAL:
      return formatterCurrency[2].format(value);

    case ValueFormat.FORMAT_NUMBER:
      return formatterNumberUnsetFractions.format(value);
    case ValueFormat.FORMAT_NUMBER_NO_DECIMAL:
      // Values in (-0.5, 0) interval would be represented as -0.
      // Following check remedies that.
      if (Math.abs(value) < 0.5) {
        value = 0;
      }
      return formatterNumber[0].format(value);
    case ValueFormat.FORMAT_NUMBER_1_DECIMAL:
      return formatterNumber[1].format(value);
    case ValueFormat.FORMAT_NUMBER_2_DECIMAL:
      return formatterNumber[2].format(value);
    case ValueFormat.FORMAT_NUMBER_3_DECIMAL:
      return formatterNumber[3].format(value);
    case ValueFormat.FORMAT_NUMBER_4_DECIMAL:
      return formatterNumber[4].format(value);
    case ValueFormat.FORMAT_NUMBER_5_DECIMAL:
      return formatterNumber[5].format(value);

    case ValueFormat.FORMAT_NUMBER_NO_FORMAT:
      return formatterNumberNoGroupingUnsetFraction.format(value);
    case ValueFormat.FORMAT_NUMBER_NO_FORMAT_NO_DECIMAL:
      return formatterNumberNoGrouping[0].format(value);
    case ValueFormat.FORMAT_NUMBER_NO_FORMAT_1_DECIMAL:
      return formatterNumberNoGrouping[1].format(value);
    case ValueFormat.FORMAT_NUMBER_NO_FORMAT_2_DECIMAL:
      return formatterNumberNoGrouping[2].format(value);
    case ValueFormat.FORMAT_NUMBER_NO_FORMAT_3_DECIMAL:
      return formatterNumberNoGrouping[3].format(value);
    case ValueFormat.FORMAT_NUMBER_NO_FORMAT_4_DECIMAL:
      return formatterNumberNoGrouping[4].format(value);
    case ValueFormat.FORMAT_NUMBER_NO_FORMAT_5_DECIMAL:
      return formatterNumberNoGrouping[5].format(value);

    default:
      return value.toString();
  }
};
