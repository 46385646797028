import { ProjectTypes } from '@platform/types';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  cutPoints: ProjectTypes.CutPoint[];
  title?: React.ReactNode | string;
  insufficientData?: { label: string; color: string };
}

const VerticalLegend: React.FC<Props> = ({ title, cutPoints, insufficientData }) => {
  const stopsOnMap = cutPoints
    .filter((cp) => cp.displayInLegend)
    .map((cutPoint) => (
      <Item key={`${cutPoint.color}-${cutPoint.from}-${cutPoint.to}`}>
        <Color style={{ backgroundColor: cutPoint.color }} />
        <Title>{cutPoint.title}</Title>
      </Item>
    ));

  return (
    <Outer>
      <Title>{title}</Title>
      {insufficientData && (
        <Item>
          <Color style={{ backgroundColor: insufficientData.color }} /> <Title>{insufficientData.label}</Title>
        </Item>
      )}
      <CutPoints>{stopsOnMap}</CutPoints>
    </Outer>
  );
};

export default VerticalLegend;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 250px;
  padding: 10px;
`;

const Title = styled.div`
  font-size: 16px;
`;

const CutPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Color = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #e8e8e8;
  border-radius: 99px;
`;
