import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DATE_FORMAT, LANGUAGE } from '../constants';
import DatepickerContext from '../contexts/DatepickerContext';
import { formatDate, nextMonth, previousMonth } from '../helpers';
import { DatepickerType, Period } from '../types';
import Calendar from './Calendar';

const DateRangePicker: React.FC<DatepickerType> = ({
  value = null,
  onChange,
  useRange = true,
  showFooter = false,
  configs = undefined,
  asSingle = false,
  placeholder = null,
  separator = '~',
  startFrom = null,
  i18n = LANGUAGE,
  disabled = false,
  containerClassName = null,
  toggleClassName = null,
  toggleIcon = undefined,
  displayFormat = DATE_FORMAT,
  readOnly = false,
  minDate = null,
  maxDate = null,
  dateLooking = 'forward',
  disabledDates = null,
  startWeekOn = 'sun',
  classNames = undefined,
  popoverDirection = undefined,
}) => {
  // Ref
  const containerRef = useRef<HTMLDivElement | null>(null);
  const arrowRef = useRef<HTMLDivElement | null>(null);

  // State
  const [firstDate, setFirstDate] = useState<dayjs.Dayjs>(
    startFrom && dayjs(startFrom).isValid() ? dayjs(startFrom) : previousMonth(dayjs())
  );
  const [secondDate, setSecondDate] = useState<dayjs.Dayjs>(nextMonth(firstDate));
  const [period, setPeriod] = useState<Period>({
    start: null,
    end: null,
  });
  const [dayHover, setDayHover] = useState<string | null>(null);

  // Functions

  /* Start First */
  const firstGotoDate = useCallback(
    (date: dayjs.Dayjs) => {
      const newDate = dayjs(formatDate(date));
      const reformatDate = dayjs(formatDate(secondDate));
      if (newDate.isSame(reformatDate) || newDate.isAfter(reformatDate)) {
        setSecondDate(nextMonth(date));
      }
      setFirstDate(date);
    },
    [secondDate]
  );

  const previousMonthFirst = useCallback(() => {
    setFirstDate(previousMonth(firstDate));
    if (useRange) {
      setSecondDate(previousMonth(secondDate));
    }
  }, [firstDate, secondDate, useRange]);

  const nextMonthFirst = useCallback(() => {
    firstGotoDate(nextMonth(firstDate));
    if (useRange) {
      setSecondDate(nextMonth(secondDate));
    }
  }, [firstDate, firstGotoDate, secondDate, useRange]);

  const changeFirstMonth = useCallback(
    (month: number) => {
      firstGotoDate(dayjs(`${firstDate.year()}-${month < 10 ? '0' : ''}${month}-01`));
    },
    [firstDate, firstGotoDate]
  );

  const changeFirstYear = useCallback(
    (year: number) => {
      firstGotoDate(dayjs(`${year}-${firstDate.month() + 1}-01`));
    },
    [firstDate, firstGotoDate]
  );
  /* End First */

  /* Start Second */
  const secondGotoDate = useCallback(
    (date: dayjs.Dayjs) => {
      const newDate = dayjs(formatDate(date, displayFormat));
      const reformatDate = dayjs(formatDate(firstDate, displayFormat));
      if (newDate.isSame(reformatDate) || newDate.isBefore(reformatDate)) {
        setFirstDate(previousMonth(date));
      }
      setSecondDate(date);
    },
    [firstDate, displayFormat]
  );

  const previousMonthSecond = useCallback(() => {
    secondGotoDate(previousMonth(secondDate));
    if (useRange) {
      setFirstDate(previousMonth(firstDate));
    }
  }, [firstDate, secondDate, secondGotoDate, useRange]);

  const nextMonthSecond = useCallback(() => {
    setSecondDate(nextMonth(secondDate));
    if (useRange) {
      setFirstDate(nextMonth(firstDate));
    }
  }, [firstDate, secondDate, useRange]);

  const changeSecondMonth = useCallback(
    (month: number) => {
      secondGotoDate(dayjs(`${secondDate.year()}-${month < 10 ? '0' : ''}${month}-01`));
    },
    [secondDate, secondGotoDate]
  );

  const changeSecondYear = useCallback(
    (year: number) => {
      secondGotoDate(dayjs(`${year}-${secondDate.month() + 1}-01`));
    },
    [secondDate, secondGotoDate]
  );
  /* End Second */

  // UseEffects & UseLayoutEffect
  useEffect(() => {
    const container = containerRef.current;
    const arrow = arrowRef.current;

    if (container && arrow) {
      const detail = container.getBoundingClientRect();
      const screenCenter = window.innerWidth / 2;
      const containerCenter = (detail.right - detail.x) / 2 + detail.x;

      if (containerCenter > screenCenter) {
        arrow.classList.add('right-0');
        arrow.classList.add('mr-3.5');
      }
    }
  }, []);

  useEffect(() => {
    if (value && value.startDate && value.endDate) {
      const startDate = dayjs(value.startDate);
      const endDate = dayjs(value.endDate);
      const validDate = startDate.isValid() && endDate.isValid();
      const condition = validDate && (startDate.isSame(endDate) || startDate.isBefore(endDate));
      if (condition) {
        setPeriod({
          start: formatDate(startDate),
          end: formatDate(endDate),
        });
      }
    }

    if (value && value.startDate === null && value.endDate === null) {
      setPeriod({
        start: null,
        end: null,
      });
    }
    if (!value) {
      setPeriod({
        start: null,
        end: null,
      });
    }
  }, [asSingle, value, displayFormat, separator]);

  useEffect(() => {
    if (startFrom && dayjs(startFrom).isValid()) {
      const startDate = value?.startDate;
      const endDate = value?.endDate;
      if (startDate && dayjs(startDate).isValid()) {
        setFirstDate(dayjs(startDate));
        if (!asSingle) {
          if (endDate && dayjs(endDate).isValid() && dayjs(endDate).startOf('month').isAfter(dayjs(startDate))) {
            setSecondDate(dayjs(endDate));
          } else {
            setSecondDate(nextMonth(dayjs(startDate)));
          }
        }
      } else {
        setFirstDate(dayjs(startFrom));
        setSecondDate(nextMonth(dayjs(startFrom)));
      }
    }
  }, [asSingle, startFrom, value]);

  const contextValues = useMemo(() => {
    return {
      asSingle,
      configs,
      arrowContainer: arrowRef,
      period,
      changePeriod: (newPeriod: Period) => setPeriod(newPeriod),
      dayHover,
      changeDayHover: (newDay: string | null) => setDayHover(newDay),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      changeInputText: (newText: string) => {},
      updateFirstDate: (newDate: dayjs.Dayjs) => firstGotoDate(newDate),
      changeDatepickerValue: onChange,
      showFooter,
      placeholder,
      separator,
      i18n,
      value,
      disabled,

      containerClassName,
      toggleClassName,
      toggleIcon,
      readOnly,
      displayFormat,
      minDate,
      maxDate,
      dateLooking,
      disabledDates,
      startWeekOn,
      classNames,
      onChange,
      popoverDirection,
      useRange,
    };
  }, [
    asSingle,
    configs,
    period,
    dayHover,
    onChange,
    showFooter,
    placeholder,
    separator,
    i18n,
    value,
    disabled,
    containerClassName,
    toggleClassName,
    toggleIcon,
    readOnly,
    displayFormat,
    minDate,
    maxDate,
    dateLooking,
    disabledDates,
    startWeekOn,
    classNames,
    popoverDirection,
    firstGotoDate,
    useRange,
  ]);

  const containerClassNameOverload = useMemo(() => {
    const defaultContainerClassName = 'relative w-fit';
    return typeof containerClassName === 'function'
      ? containerClassName(defaultContainerClassName)
      : typeof containerClassName === 'string' && containerClassName !== ''
      ? containerClassName
      : defaultContainerClassName;
  }, [containerClassName]);

  return (
    <DatepickerContext.Provider value={contextValues}>
      <div className={containerClassNameOverload} ref={containerRef}>
        <div className="mt-2.5 rounded-lg bg-white py-0.5">
          <div className="flex flex-col py-2 lg:flex-row">
            <div className={`flex flex-col items-stretch gap-2.5 space-y-4 md:flex-row md:space-y-0`}>
              <Calendar
                date={firstDate}
                onClickPrevious={previousMonthFirst}
                onClickNext={nextMonthFirst}
                changeMonth={changeFirstMonth}
                changeYear={changeFirstYear}
                minDate={minDate}
                maxDate={maxDate}
                calendarPosition={useRange ? 'left' : undefined}
              />

              {useRange && (
                <Calendar
                  date={secondDate}
                  onClickPrevious={previousMonthSecond}
                  onClickNext={nextMonthSecond}
                  changeMonth={changeSecondMonth}
                  changeYear={changeSecondYear}
                  minDate={minDate}
                  maxDate={maxDate}
                  calendarPosition={useRange ? 'right' : undefined}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </DatepickerContext.Provider>
  );
};

export default DateRangePicker;
