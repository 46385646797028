import React from 'react';
import LogoText from './LogoText';

const Loader: React.FC = () => (
  <div className="flex h-full w-full flex-col items-center justify-center gap-2 text-xl text-gray-400">
    <LogoText />
    <div className="mt-12 h-1.5 w-40 overflow-hidden rounded bg-gray-100">
      <div className="animate-progress origin-left-right h-full w-full bg-gray-600"></div>
    </div>
  </div>
);

export default Loader;
