import React from 'react';
import Table from './Table';

export interface Column {
  id: string;
  title: React.ReactNode | string;
  isHighlighted?: boolean;
  label?: string; // Since title can be ReactNode we need column title as string for column update usages
  renderMenu?: (column: Column) => React.ReactNode;
  renderEdit?: RenderEditFunction;
  suffixIcon?: React.ReactNode;
  classes?: string;
  headerClasses?: string;
  dataClasses?: string;
  onSort?: (sortDirection: SortOrderDirection) => void;
  onClick?: (columnId: string) => void;
  onClickOutside?: (columnId: string) => void;
  sortDirection?: SortOrderDirection | null;
  formatter?: (input: unknown) => string;
}

type CloseRequestFunction = () => void;

type RenderEditFunction = (
  value: unknown,
  cIdx: number,
  rIdx: number,
  anchor: HTMLDivElement | null,
  onCloseRequest: CloseRequestFunction
) => React.ReactNode;

export enum SortOrderDirection {
  ASC,
  DESC,
}

export type SelectedCell = { rIdx: number; cIdx: number };

export default Table;
