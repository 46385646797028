import { PeTypes } from '@platform/types';
import React from 'react';
import MUIcon from '../MUIcon';

interface Props {
  user: PeTypes.User;
}

const UserAvatar: React.FC<Props> = ({ user }) => {
  const { email, pictureSmallURL, pictureLargeURL, firstName, lastName, updatedAt } = user;
  const fullName = `${firstName} ${lastName}`;
  const pictureUrl = pictureLargeURL ?? pictureSmallURL;

  return (
    <div
      className="flex h-[34px] w-[34px] flex-shrink-0 items-center justify-center rounded-full"
      data-rh={email}
      data-rh-at="left"
    >
      {pictureUrl ? (
        <img className={`h-8 w-8 rounded-full`} src={`${pictureUrl}?${updatedAt}`} alt={fullName} />
      ) : (
        <MUIcon name="person" className="text-lg text-white" />
      )}
    </div>
  );
};

export default UserAvatar;
