import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EmptyStateSVG } from '../../../../../../assets/filter-dataset-no-variables.svg';
import { useWorkspaceContext } from '../../../../../contexts/WorkspaceContext';
import { generateLink } from '../../../../../LinkGenerator';

interface Props {
  datasetId: string;
}

const WorkingLayerNoColumnsState: React.FC<Props> = ({ datasetId }) => {
  const { activeWorkspace } = useWorkspaceContext();
  return (
    <div className="mt-16 flex h-full w-full items-start justify-center">
      <div className="flex flex-col items-center gap-10">
        <EmptyStateSVG />
        <div className="flex flex-col items-center justify-center gap-4 px-6">
          <div className="flex flex-col gap-4 text-gray-700">
            <p className="px-3 text-center text-sm">
              Unfortunately, no columns are selected as filterable for this dataset.
            </p>
            <p className="px-3 text-center text-sm">
              <Link
                to={generateLink('dataset', { workspaceId: activeWorkspace.id, datasetId: datasetId })}
                className="text-primary-600 font-semibold underline underline-offset-2"
              >
                Open the dataset
              </Link>
              , set at least one column as filterable, and publish the changes so you can use it to create filters here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingLayerNoColumnsState;
