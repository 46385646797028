import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useProjects from '../../hooks/useProjects';
import { generateLink } from '../../LinkGenerator';

const ProjectsList: React.FC = () => {
  const location = useLocation();
  const { activeWorkspace } = useWorkspaceContext();
  const projectsQuery = useProjects(activeWorkspace.id);
  const unsortedProjectLink = generateLink('projects', {
    workspaceId: activeWorkspace.id,
  });

  return (
    <div className="flex flex-col gap-2">
      <Item to={unsortedProjectLink} $active={unsortedProjectLink === location.pathname}>
        <div className="truncate">Unsorted</div>
      </Item>
      {projectsQuery.data?.map((p) => {
        const projectLink = generateLink('project', { workspaceId: activeWorkspace.id, projectId: p.id });
        return (
          <Item key={p.id} to={projectLink} $active={projectLink === location.pathname}>
            <div className="truncate">{p.title}</div>
          </Item>
        );
      })}
    </div>
  );
};

export default ProjectsList;

export const Item = tw(Link)<{ $active?: boolean }>`
  rounded-md
  text-gray-600
  select-none
  p-3
  transition-all
  hover:bg-primary-100
  hover:text-primary-700
 ${(props) => props.$active && `bg-primary-100 text-primary-700 font-semibold`}
`;
