import React from 'react';

interface Props {
  map: React.ReactNode;
  children?: React.ReactNode;
}

const SingleLayout: React.FC<Props> = ({ map, children }) => {
  return (
    <div className="h-full w-full">
      {map}
      {children && <div className="absolute bottom-0 left-0 right-0 top-0">{children}</div>}
    </div>
  );
};

export default SingleLayout;
