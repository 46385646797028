import { useVariableMetadata } from '@platform/shared/hooks';
import { ProjectTypes } from '@platform/types';
import React from 'react';

export interface Props {
  def: ProjectTypes.VariableSelection;
  showUniverse?: boolean;
  showTable?: boolean;
  className?: string;
  variableClassName?: string;
}

const VariableInfo: React.FC<Props> = ({
  def,
  showUniverse = true,
  className,
  variableClassName = 'text-xs',
  showTable = false,
}) => {
  const { surveyName, datasetAbbrev, tableGuid, variableGuid } = def;
  const { variable, universeVariablesMetadata, table } = useVariableMetadata({
    surveyName,
    datasetAbbreviation: datasetAbbrev,
    tableUuid: tableGuid,
    variableUuid: variableGuid,
  });

  return (
    <div className={`flex flex-col ${className}`}>
      {showUniverse && universeVariablesMetadata.length > 0 && (
        <div className="text-xs opacity-60">{universeVariablesMetadata.map((x) => x.label).join(', ')}</div>
      )}
      <div className={variableClassName}>{variable?.label ?? '...'}</div>
      {showTable && <div className="text-xs text-gray-500">{table?.title}</div>}
    </div>
  );
};

export default VariableInfo;
