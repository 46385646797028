import { PeTypes } from '@platform/types';
import React from 'react';

interface Props {
  user: PeTypes.User;
}

const UserFullName: React.FC<Props> = ({ user }) => {
  const { email, firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className="flex flex-col items-start">
      <span className="text-sm">{fullName}</span>
      <span className="text-xs text-gray-500">{email}</span>
    </div>
  );
};

export default UserFullName;
