import { Inputs, Modal } from '@platform/shared/ui';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { columnNameValidator } from './utils';

type Input = {
  title: string;
  label: string;
};

interface Props {
  columnTitles: string[];
  onDone: (columnName: string, label: string) => void;
  okLabel: string;
  onCloseRequest: () => void;
}

const DuplicateColumnModal: React.FC<Props> = ({ onCloseRequest, columnTitles, onDone, okLabel }) => {
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Input>({ defaultValues: { title: '', label: '' } });
  const watchedValues = watch();

  const onSubmit: SubmitHandler<Input> = (data) => {
    onDone(data.title, data.label);
    onCloseRequest();
  };

  return (
    <Modal
      title="Duplicate column"
      okLabel={okLabel}
      cancelLabel="Cancel"
      okDisabled={watchedValues.title === ''}
      className="w-[448px]"
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
      titleClasses="text-base mb-3"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="mb-8 flex flex-col gap-8">
        <Controller
          name="title"
          control={control}
          rules={{
            validate: columnNameValidator(columnTitles, watchedValues.title),
            required: 'Column name cannot be empty',
          }}
          render={({ field }) => {
            return (
              <div>
                <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
                  Name
                </label>
                <Inputs.Input
                  autoFocus
                  id="title"
                  roundedClasses="rounded-lg"
                  value={field.value}
                  className="w-full py-1 text-sm outline-none"
                  onChange={(opt) => field.onChange(opt.target.value)}
                />
              </div>
            );
          }}
        />
        <Controller
          name="label"
          control={control}
          render={({ field }) => {
            return (
              <div>
                <label htmlFor="label" className="mb-2 block text-sm font-semibold text-gray-900">
                  Alternate ˝Friendly˝ Name <span className="text-xs font-normal text-gray-700">(optional)</span>
                </label>
                <Inputs.Input
                  id="title"
                  roundedClasses="rounded-lg"
                  value={field.value}
                  className="w-full py-1 text-sm outline-none"
                  onChange={(opt) => field.onChange(opt.target.value)}
                />
              </div>
            );
          }}
        />
        {errors.title && <span className="text-red-400">{errors.title.message}</span>}
      </form>
    </Modal>
  );
};

export default DuplicateColumnModal;
