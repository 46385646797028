import { MetadataTypes, ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import MUIcon from '../../MUIcon';
import { getTableElementId } from './TablePicker';
import VariablesPicker from './VariablesPicker';

interface Props {
  surveyName: string;
  datasetAbbreviation: string;
  option: {
    value: string;
    name: string;
  };
  selection: ProjectTypes.VariableSelection[];
  allowMultiSelection?: boolean;
  onVariableChange: (variables: MetadataTypes.Variable[], table: MetadataTypes.Table) => void;
  disabled?: boolean;
}

const DataTable: React.FC<Props> = ({
  surveyName,
  datasetAbbreviation,
  option,
  selection,
  onVariableChange,
  allowMultiSelection,
  disabled,
}) => {
  const selectedVariableUuids = selection.map((s) => s.variableGuid);

  useEffect(() => {
    const isTableExpanded = selection[0]?.tableGuid === option.value;
    setAreVariablesVisible(isTableExpanded);
  }, [option.value, selection]);

  const [areVariablesVisible, setAreVariablesVisible] = useState<boolean>(false);

  return (
    <div className="select-none border-b-[1px] last:border-none" id={getTableElementId(option.value)}>
      <div
        className="flex cursor-pointer items-center justify-between gap-3 py-3 pr-3"
        onClick={() => setAreVariablesVisible(!areVariablesVisible)}
      >
        <span className="w-full text-sm font-semibold">{option.name}</span>
        {areVariablesVisible ? (
          <MUIcon name="expand_less" className="rounded-full bg-gray-100 p-[6px] text-gray-600  hover:bg-gray-200" />
        ) : (
          <MUIcon name="expand_more" className="rounded-full bg-gray-100 p-[6px] text-gray-600 hover:bg-gray-200" />
        )}
      </div>
      {areVariablesVisible && (
        <VariablesPicker
          surveyName={surveyName}
          datasetAbbreviation={datasetAbbreviation}
          selectedTableUuid={option.value}
          onChange={onVariableChange}
          selectedVariableUuids={selectedVariableUuids}
          allowMultiSelection={allowMultiSelection}
        />
      )}
    </div>
  );
};

export default DataTable;
