import { Buttons } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { NIL as NIL_UUID } from 'uuid';
import EmptyState from '../shared/EmptyState';
import ProfilesListItem from './ProfilesListItem';

interface Props {
  projectId?: string;
  profiles: PeTypes.Profile[] | undefined;
}

const ProfileList: React.FC<Props> = ({ projectId, profiles }) => {
  if (!profiles) {
    return (
      <div className="mt-10 flex w-full items-center justify-center">
        <span className="text-gray-500">Loading maps...</span>
      </div>
    );
  }
  if (profiles.length === 0) {
    return (
      <div className="py-5">
        <EmptyState
          trigger={<Buttons.Primary>Create a Map</Buttons.Primary>}
          action={PeTypes.Action.NEW_PROFILE}
          projectId={projectId === NIL_UUID ? null : projectId}
        />
      </div>
    );
  }
  return (
    <div className="w-100 flex flex-col bg-white">
      <div className="w-full bg-white">
        {profiles.map((item) => (
          <ProfilesListItem key={item.id} profile={item} />
        ))}
      </div>
    </div>
  );
};

export default ProfileList;
