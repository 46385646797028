import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { INotification } from '.';
import Snackbar from './Snackbar';

interface Notification extends INotification {
  id: string;
}

const DEFAULT_DURATION_IN_SEC = 5;

const useSnackbars = (): [(notification: INotification) => () => void, React.JSXElementConstructor<unknown>] => {
  const [snackbars, setSnackbars] = useState<Notification[]>([]);

  const removeSnackbar = (snackbarId: string) => setSnackbars((prev) => prev.filter((x) => x.id !== snackbarId));

  const notify = useCallback((notification: INotification): (() => void) => {
    const id = uuidv4();

    const newNotification = {
      id,
      ...notification,
    };

    setTimeout(() => removeSnackbar(id), (notification.hideAfterSec ?? DEFAULT_DURATION_IN_SEC) * 1000);

    setSnackbars((p) => [...p, newNotification]);

    return () => removeSnackbar(id);
  }, []);

  const Snackbars = () => (
    <div className="flex flex-col items-start gap-3">
      {snackbars.map((x) => (
        <Snackbar key={x.id} notification={x} onCloseRequest={() => removeSnackbar(x.id)} />
      ))}
    </div>
  );

  return [notify, Snackbars];
};

export default useSnackbars;
