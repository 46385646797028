import { useState } from 'react';

function useClipboard(): [string, (text: string) => void] {
  const [copiedText, setCopiedText] = useState<string>('');
  const copyToClipboard = (text: string) => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      setCopiedText(text);
    } catch {
      console.error('Cant copy to clipboard');
    }
  };

  return [copiedText, copyToClipboard];
}

export default useClipboard;
