import { Buttons } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import useWorkspaceStatistics from '../../hooks/useWorkspaceStatistics';
import WorkspaceGeocodes from './WorkspaceGeocodes';
import WorkspaceStorage from './WorkspaceStorage';
interface Props {
  activeWorkspace: PeTypes.Workspace;
}

const WorkspaceStatistics: React.FC<Props> = ({ activeWorkspace }) => {
  const workspaceStatisticsQuery = useWorkspaceStatistics(activeWorkspace.id);
  if (workspaceStatisticsQuery.isLoading || workspaceStatisticsQuery.isIdle) return null;
  if (workspaceStatisticsQuery.isError) return null;

  const workspaceStatistics = workspaceStatisticsQuery.data;

  return (
    <div className="flex flex-col items-center justify-center gap-10">
      <div className="flex w-full flex-col justify-start gap-3">
        <span className="text-2xl font-semibold text-gray-900">Usage statistics</span>
        <span className="text-sm text-gray-700">
          Keep track of your usage statistics for the current billing cycle.
        </span>
      </div>
      <div className="w-full rounded-xl border border-gray-200 bg-white">
        <WorkspaceGeocodes workspaceStatistics={workspaceStatistics} />
        <WorkspaceStorage workspaceStatistics={workspaceStatistics} />
        <div className="py-8 px-6">
          <div className="flex items-center justify-between">
            <span className="text-sm  text-gray-600">Need more storage or geocoding services?</span>
            <a href="mailto:info@socialexplorer.com">
              <Buttons.Secondary className="px-12 py-2">Contact us</Buttons.Secondary>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceStatistics;
