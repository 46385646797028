import { useVariableMetadata } from '@platform/shared/hooks';
import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import { MapGeoJSONFeature } from 'maplibre-gl';
import React from 'react';
import EnumeratedValue from './EnumeratedValue';

export interface Props {
  selection: ProjectTypes.VariableSelection;
  feature: MapGeoJSONFeature;
  rendering: ProjectTypes.CutPointSet;
  isLastLine?: boolean;
  allowPercents?: boolean;
  isMultiSelection?: boolean;
}

const DataTooltipLine: React.FC<Props> = ({
  feature,
  selection,
  allowPercents,
  isLastLine,
  rendering,
  isMultiSelection,
}) => {
  const { isLoading, isError, variable, table, universeVariablesMetadata } = useVariableMetadata({
    surveyName: selection.surveyName,
    datasetAbbreviation: selection.datasetAbbrev,
    tableUuid: selection.tableGuid,
    variableUuid: selection.variableGuid,
  });

  if (isLoading) return <>Loading variable meta...</>;
  if (isError) return <>Something went wrong...</>;
  if (!variable || !table) return <>Something went wrong...</>;

  const isVariableNumber = variable.dataType !== 'String';
  const isVariableEnumerated = isVariableNumber && variable.varType === 'None';
  const isVariableMedian = isVariableNumber && variable.varType === 'Median';
  const isVariablePercent = isVariableNumber && variable.varType === 'Percent';

  const showingPercents =
    allowPercents &&
    isVariableNumber &&
    !isVariableEnumerated &&
    !isVariableMedian &&
    universeVariablesMetadata.length > 0;

  const value = isVariableNumber
    ? parseFloat(feature.properties[selection.variableGuid])
    : feature.properties[selection.variableGuid];

  let percentage;

  if (showingPercents && !isVariablePercent) {
    const universeCount = parseFloat(feature.properties[universeVariablesMetadata[0].uuid]);
    percentage = (
      <span className="text-sm font-semibold">
        {ValueFormatter.format((value / universeCount) * 100, ProjectTypes.ValueFormat.FORMAT_PERCENT_2_DECIMAL)}
      </span>
    );
  }

  let variableValue;

  if (isVariableEnumerated) {
    const suggestedFilterSetName = variable.defaultFilterSetName || table.defaultFilterSetName;
    variableValue = <EnumeratedValue value={value} selection={selection} filterSetName={suggestedFilterSetName} />;
  } else if (isVariableNumber) {
    variableValue = Number.isNaN(value)
      ? 'N/A'
      : `${ValueFormatter.format(value, variable?.formatting as ProjectTypes.ValueFormat)}`;
  } else {
    variableValue = value || 'N/A';
  }

  const values = (
    <>
      {percentage}
      <span className="text-xs first:text-sm first:font-semibold">{variableValue}</span>
    </>
  );

  const showBase = isLastLine && showingPercents;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-full items-center gap-4 px-3 py-2">
        {isMultiSelection && (
          <div className="flex h-[32px] w-[4px] rounded" style={{ backgroundColor: rendering.cutPoints[0].color }} />
        )}
        <div className="flex w-full items-start justify-between gap-2 text-xs">
          <div>{variable?.label ?? ''}</div>
          <div className="align-end flex flex-col text-right">{values}</div>
        </div>
      </div>
      {showBase && (
        <>
          <div className="h-[1px] w-full bg-gray-200" />
          <div className="flex w-full justify-between gap-2 px-3 py-2 text-xs">
            <div>Percent base: {universeVariablesMetadata[0].label}</div>
            <div>
              {ValueFormatter.format(
                parseFloat(feature.properties[universeVariablesMetadata[0].uuid]),
                universeVariablesMetadata[0]?.formatting as ProjectTypes.ValueFormat
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataTooltipLine;
