import { LngLatBoundsLike } from 'maplibre-gl';
import { RuleGroupType } from 'react-querybuilder';

export interface IEmbeddedAuthPayload {
  token: string;
  username: string;
}

export interface IUser {
  age: string;
  companyName: string;
  demographic: string;
  dev: number;
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  interestStates: string;
  isInvited: number;
  jobFunction: string;
  jobLevel: string;
  orgId: number;
  orgType: string;
  permissions: string[];
  resetToken: string;
  role: string;
  userStatus: number;
}

export interface ILayerDefinition {
  id: string;
  label: string;
  url: string;
  geoId: string;
  groupBy: string;
  labelField: string;
  filter?: RuleGroupType;
  maxBounds?: LngLatBoundsLike;
}

export interface ITestAudience {
  id: string;
  datasetId: string;
  title: string;
  description: string;
  size: number;
  zoom: number;
  center: { lng: number; lat: number };
  layers: ILayerDefinition[];
  filter?: RuleGroupType;
}

export interface IAudience {
  id: number;
  uuid: string;
  code: string;
  title: string;
  description: string;
  active: boolean;
  custom: boolean;
  size: number;
  topics: string[];
  query?: RuleGroupType;
  category?: string;
  cpm?: number;
}

export interface IUserSavedAudience extends IAudience {
  referenceId: number;
}

export interface ICreateUserAudience {
  title: string;
  size: number;
  query: RuleGroupType;
  baseAudienceId: number;
  description?: string;
  geoLevel?: GeoLevelType;
}

export interface IDataRequest {
  query: string;
}

export interface IHeadlineDetails {
  title: string;
  description: string;
  img: string;
  body?: string;
  publishedAt: string;
  url: string;
}

export interface IGetAudiencesRequest {
  category: string | null;
  includeInactive?: boolean;
  includeCustom?: boolean;
}

export interface IUserCategory {
  id: number;
  name: string;
}

export type GeoLevelType = {
  id: string;
  field: string;
  name: string;
  labelProp: string;
  parent: string;
  matchBy: string[];
  layerType: 'polygon' | 'point';
  geoJson: string;
  disabled?: boolean;
  limit?: number;
};

export interface AudienceLimitOption {
  name: string;
  value: number;
  nameClasses: string | undefined;
  label?: string;
}
