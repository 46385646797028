import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../components/shared/ErrorPage';
import { Story } from '../components/Stories';

const StoryPage: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  if (!storyId) return <ErrorPage />;

  return (
    <div className="mx-auto mt-10 flex h-full max-w-7xl grow gap-10">
      <Story storyId={storyId} />
    </div>
  );
};

export default StoryPage;
