import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../../assets/empty-state-points.svg';

const WorkingLayersEmptyState: React.FC = () => (
  <div className="mt-1 flex h-full w-full flex-col items-center justify-center gap-12 p-12">
    <EmptyStateSVG />
    <div className="text-center text-sm text-gray-700">No data yet</div>
  </div>
);

export default WorkingLayersEmptyState;
