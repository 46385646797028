import { Dropdowns } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import SelectColumnItem from './SelectColumnItem';

interface Props {
  data: unknown[];
  columns: PeTypes.ImportDatasetPayloadColumn[];
  value: PeTypes.ImportDatasetGeoCodeSelection;
  onChange: (arg: PeTypes.ImportDatasetGeoCodeSelection) => void;
}

export const getPreviewValue = (
  item: string,
  columns: PeTypes.ImportDatasetPayloadColumn[],
  data: unknown[]
): string => {
  const indexOfColumn = columns.findIndex((c) => c.name === item);
  let value = '';

  if (indexOfColumn > -1 && data.length > 0 && Array.isArray(data[0])) {
    const firstRowValue = data[0][indexOfColumn];
    value = firstRowValue != null ? String(firstRowValue) : '';
  }

  return value;
};

const MultiColumnAddress: React.FC<Props> = ({ data, columns, onChange, value }) => {
  const dropdownOptions: Dropdowns.DropdownOption[] = columns.map((c) => ({ name: c.name, value: c.name }));

  return (
    <div className="mt-8 flex flex-col gap-3">
      <SelectColumnItem
        label={'Address'}
        dropDownOptions={dropdownOptions}
        value={value.address}
        onSelect={(option: Dropdowns.DropdownOption | null) =>
          onChange({
            ...value,
            address: option != null ? (option.value as string) : '',
          })
        }
        previewValue={getPreviewValue(value.address, columns, data)}
      />
      <SelectColumnItem
        label={'City'}
        dropDownOptions={dropdownOptions}
        value={value.city}
        onSelect={(option: Dropdowns.DropdownOption | null) =>
          onChange({
            ...value,
            city: option != null ? (option.value as string) : '',
          })
        }
        previewValue={getPreviewValue(value.city ?? '', columns, data)}
      />
      <SelectColumnItem
        label={'State'}
        dropDownOptions={dropdownOptions}
        value={value.state}
        onSelect={(option: Dropdowns.DropdownOption | null) =>
          onChange({
            ...value,
            state: option != null ? (option.value as string) : '',
          })
        }
        previewValue={getPreviewValue(value.state ?? '', columns, data)}
      />
      <SelectColumnItem
        label={'ZIP Code'}
        dropDownOptions={dropdownOptions}
        value={value.zipCode}
        onSelect={(option: Dropdowns.DropdownOption | null) =>
          onChange({
            ...value,
            zipCode: option != null ? (option.value as string) : '',
          })
        }
        previewValue={getPreviewValue(value.zipCode ?? '', columns, data)}
      />
    </div>
  );
};

export default MultiColumnAddress;
