import { Buttons, MUIcon, PanelHeader } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDatasetColumns } from '../../../../../hooks';
import ColumnPicker from '../../../../Datasets/Dataset/ColumnPicker';
import { SelectionType } from '../../../../Datasets/Dataset/ColumnPicker/types';
import Panel from '../../../../shared/Panel';
import WorkingLayerTooltipColumn from './WorkingLayerTooltipColumn';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onTitleVariableChange: (value: SelectionType | SelectionType[]) => void;
  onContentAdd: (value: SelectionType) => void;
  onContentRemove: (id: string) => void;
  onContentLabelChange: (id: string, value: string) => void;
  onDragEnd: (result: DropResult) => void;
  onTitleVariableRemove: () => void;
}

const WorkingLayerTooltipEditor: React.FC<Props> = ({
  workingLayer,
  onTitleVariableChange,
  onContentAdd,
  onContentRemove,
  onContentLabelChange,
  onDragEnd,
  onTitleVariableRemove,
}) => {
  const [columnPickerAction, setColumnPickerAction] = useState<'title' | 'content' | null>(null);
  const datasetColumnsQuery = useDatasetColumns({ datasetId: workingLayer.datasetId });

  if (datasetColumnsQuery.isError || datasetColumnsQuery.isLoading) {
    return null;
  }

  const onChange = (payload: SelectionType | SelectionType[]) => {
    switch (columnPickerAction) {
      case 'title':
        onTitleVariableChange(payload);
        setColumnPickerAction(null);
        break;
      case 'content':
        onContentAdd(payload as SelectionType);
        setColumnPickerAction(null);
        break;
    }
  };

  const handleClick = (e: any) => {
    onTitleVariableRemove();
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onTitleVariableRemove();
    }
  };

  const columns = datasetColumnsQuery.data;

  const titleContent = workingLayer.tooltip?.title ? (
    <Buttons.Secondary
      className="text-primary-700 h-[54px] flex-row-reverse justify-between rounded-lg bg-gray-50 px-2 text-gray-700"
      onClick={() => {
        setColumnPickerAction('title');
      }}
      icon={
        <div
          role="button"
          tabIndex={0}
          className="flex items-center justify-center px-1"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          <MUIcon name="close" className="text-gray-600" />
        </div>
      }
    >
      <span className="flex items-start px-4 font-normal">{workingLayer.tooltip.title.name}</span>
    </Buttons.Secondary>
  ) : (
    <Buttons.Secondary
      className="text-primary-700 h-[34px] w-[110px] gap-1 rounded-[999px]"
      icon={<MUIcon name="add" />}
      iconClassName="pl-0.5"
      onClick={() => setColumnPickerAction('title')}
    >
      <span className="pr-1.5">Add title</span>
    </Buttons.Secondary>
  );

  return (
    <>
      <div className="flex flex-col gap-6 py-4">
        <div className="items-left flex w-full flex-col gap-3">
          <span className="text-sm text-gray-900">Title</span>
          {titleContent}
        </div>
        <div className="items-left flex w-full flex-col gap-3">
          <span className="text-sm text-gray-900">Content</span>
          {!!workingLayer.tooltip?.content?.length && (
            <div className="flex flex-col gap-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-grow flex-col gap-2.5  bg-white py-4"
                    >
                      {workingLayer.tooltip?.content.map((content: ProjectTypes.TooltipContent, idx) => (
                        <Draggable key={content.id} draggableId={content.id} index={idx}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <WorkingLayerTooltipColumn
                                key={content.id}
                                tooltipContent={content}
                                onContentRemove={onContentRemove}
                                onContentLabelChange={onContentLabelChange}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          <div className="flex gap-3">
            <Buttons.Secondary
              className="text-primary-700 w-fit-content h-[34px] gap-1 rounded-[999px]"
              icon={<MUIcon name="add" />}
              iconClassName="pl-0.5"
              onClick={() => setColumnPickerAction('content')}
            >
              <span className="pr-1.5">Add variable</span>
            </Buttons.Secondary>
          </div>
        </div>
      </div>
      {columnPickerAction && (
        <Panel
          children={
            <div className="flex h-full flex-col overflow-y-auto">
              <PanelHeader title="Select a column" onBack={() => setColumnPickerAction(null)} />
              <ColumnPicker columns={columns ?? []} datasetId={workingLayer.datasetId} onChange={onChange} />
            </div>
          }
        />
      )}
    </>
  );
};

export default WorkingLayerTooltipEditor;
