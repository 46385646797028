import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { default as EditableCell } from './EditableCell';
import { Column, SelectedCell } from './index';
import TableHeader from './TableHeader';

interface Props {
  classes?: string;
  columns: Column[];
  rows: unknown[][];
  freezeHeader?: boolean;
  freezeFirstColumn?: boolean;
  emptyTableComponent?: React.ReactNode;
  onSelectCell?: (cell: SelectedCell | null) => void;
  selectedRows?: string[];
  onRowSelection?: (rows: string[]) => void;
  rowsOffset?: number;
  allowRowSelection?: boolean;
}

const Table: React.FC<Props> = ({
  classes,
  columns,
  rows,
  freezeHeader = false,
  freezeFirstColumn = false,
  emptyTableComponent = <div className="text-lg text-gray-600">No data.</div>,
  onSelectCell,
  selectedRows,
  onRowSelection,
  rowsOffset = 0,
  allowRowSelection = false,
}: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [selectedCell, setSelectedCell] = useState<SelectedCell | null>(null);
  const [cellBeingEdited, setCellBeingEdited] = useState<SelectedCell | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  useEffect(() => {
    onSelectCell?.(selectedCell);
  }, [selectedCell]);

  useLayoutEffect(() => {
    const handleClickOutsideOfTable = (event: MouseEvent) => {
      if (event.target && tableRef.current && !tableRef.current.contains(event.target as Node)) {
        const highlightedColumn = columns.find((c) => c.isHighlighted);
        if (highlightedColumn) {
          highlightedColumn.onClickOutside?.(highlightedColumn.id);
        }
        setSelectedCell(null);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideOfTable);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutsideOfTable);
    };
  }, [tableRef, columns]);

  useLayoutEffect(() => {
    const highlightedColId = columns.find((c) => c.isHighlighted)?.id;
    if (!highlightedColId) return;

    const targetDiv = tableRef.current?.querySelector(`#column-header-${highlightedColId}`);
    targetDiv?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
  }, [columns]);

  const handleMouseEnter = (rowIdx: number) => {
    setHoveredRow(rowIdx);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleOnChangeRowSelection = (event: React.ChangeEvent<HTMLInputElement>, row: unknown[]) => {
    if (!selectedRows || !onRowSelection) return;
    const newSelection = event.target.checked
      ? [...selectedRows, row[0] as string]
      : selectedRows?.filter((el) => el !== row[0]);
    onRowSelection(newSelection);
  };

  const isEmpty = rows.length === 0 || columns.length === 0;
  return (
    <div className="relative w-full overflow-auto rounded-lg border-2 border-gray-200" ref={tableRef}>
      <table className={classNames('w-full text-left text-xs', classes)}>
        <TableHeader columns={columns} freezeHeader={freezeHeader} freezeFirstColumn={freezeFirstColumn} />
        <tbody>
          {rows.map((row, rIdx) => (
            <tr
              key={`row-${rIdx}`}
              onMouseEnter={() => handleMouseEnter(rIdx)}
              onMouseLeave={handleMouseLeave}
              className={classNames('group/row text-gray-600', {
                'bg-gray-50': rIdx === selectedCell?.rIdx,
                'bg-primary-50': selectedRows?.includes(row[0] as string),
                'hover:bg-gray-50': !selectedRows?.includes(row[0] as string),
              })}
            >
              {columns.map((column, cIdx) => {
                let cellContent;

                switch (true) {
                  case column.renderEdit !== undefined: {
                    cellContent = (
                      <EditableCell
                        cIdx={cIdx}
                        rIdx={rIdx}
                        column={column}
                        value={row[cIdx]}
                        onEditStart={(x, y) => setCellBeingEdited({ rIdx: x, cIdx: y })}
                        onEditEnd={() => setCellBeingEdited(null)}
                      />
                    );
                    break;
                  }
                  case cIdx === 0 &&
                    (selectedRows?.includes(row[0] as string) || (hoveredRow === rIdx && allowRowSelection)): {
                    cellContent = (
                      <input
                        name="select"
                        id="all"
                        type="checkbox"
                        checked={selectedRows?.includes(row[0] as string)}
                        className="text-primary-600 h-4 w-4 rounded border-gray-300 hover:cursor-pointer focus:ring-0"
                        onChange={(event) => handleOnChangeRowSelection(event, row)}
                      />
                    );
                    break;
                  }
                  case cIdx === 0: {
                    cellContent = rIdx + rowsOffset + 1;
                    break;
                  }
                  case column.formatter !== undefined: {
                    cellContent = column.formatter?.(row[cIdx]);
                    break;
                  }
                  default: {
                    cellContent = `${row[cIdx]}`;
                  }
                }

                return (
                  <td
                    className={classNames(
                      'h-12 min-w-[60px] max-w-[330px] p-0',
                      { 'sticky left-0 z-[10] group-hover/row:bg-gray-50': freezeFirstColumn && cIdx === 0 },
                      { 'bg-gray-50': column.isHighlighted },
                      { 'bg-white': freezeFirstColumn && cIdx === 0 && !selectedRows?.includes(row[0] as string) },
                      column.dataClasses,
                      selectedRows?.includes(row[0] as string) ? 'bg-primary-50 group-hover/row:bg-primary-50' : ''
                    )}
                    key={`cell-${rIdx}-${cIdx}`}
                  >
                    <div className="flex h-full items-center border-b border-r border-gray-300">
                      <div
                        className={classNames(
                          'h-full w-full border-2',
                          !cellBeingEdited &&
                            selectedCell?.rIdx === rIdx &&
                            selectedCell?.cIdx === cIdx &&
                            !(allowRowSelection && cIdx === 0)
                            ? `border-primary-700 border`
                            : 'border-transparent'
                        )}
                        onClick={() => setSelectedCell({ rIdx, cIdx })}
                      >
                        <div className="flex h-full items-center items-center px-3">
                          <div className={classNames('w-full truncate', column.classes)}>{cellContent}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {isEmpty && (
        <div className="absolute flex h-full w-full -translate-y-14 items-center justify-center">
          {emptyTableComponent}
        </div>
      )}
    </div>
  );
};

export default Table;
