import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../assets/empty-state-search.svg';

const SearchEmptyState: React.FC = () => (
  <div className="mt-6 flex h-full w-full flex-col items-center justify-center gap-12 p-12">
    <EmptyStateSVG></EmptyStateSVG>
    <div className="text-center text-sm text-gray-700">
      Search and select one or more places, or simply click
      <MUIcon name="web_traffic" className="relative top-1 px-2" />
      on the map — and get insigths <span className="mt-1 inline-block">for the selected area.</span>
    </div>
  </div>
);

export default SearchEmptyState;
