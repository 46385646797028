import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../../../assets/empty-filtered-table.svg';

const FilteredTableEmptyState: React.FC = () => (
  <div className="flex h-full w-full items-center justify-center">
    <div className="flex flex-col items-center gap-10">
      <EmptyStateSVG />
      <div className="flex flex-col items-center justify-center gap-4">
        <span className="w-full text-center text-sm text-gray-700">All records are filtered.</span>
      </div>
    </div>
  </div>
);

export default FilteredTableEmptyState;
