import React from 'react';
import { useParams } from 'react-router-dom';
import { NIL as NIL_UUID } from 'uuid';

import Projects from '../components/Projects/Projects';

const ProjectsPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const includeAllProjects = projectId === 'all';

  return <Projects projectId={includeAllProjects ? NIL_UUID : projectId} />;
};

export default ProjectsPage;
