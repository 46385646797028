import { LngLatLike, Map, MapGeoJSONFeature } from 'maplibre-gl';
import { useEffect, useMemo, useRef } from 'react';
import useFeaturesUnderMouse from './useFeaturesUnderMouse';

let activeMaps: { map: Map; sourceLayerIds: string[] }[] = [];

function useFeatureHighlight(map: Map | null, sourceLayerIds: string[] = [], featureStyle = 'hover') {
  const featuresPerMapRef = useRef<{ map: Map; features: MapGeoJSONFeature[] }[]>([]);

  useEffect(() => {
    featuresPerMapRef.current = [];
  }, [sourceLayerIds]);

  useEffect(() => {
    if (!map) return;

    if (map) {
      activeMaps.push({ map, sourceLayerIds });
    }
    return () => {
      activeMaps = activeMaps.filter((x) => x.map !== map);
    };
  }, [map]);

  const handleFeaturesUnderMouse = useMemo(
    () => (featuresInEvent: MapGeoJSONFeature[], lngLat: LngLatLike | null) => {
      try {
        if (!map || !lngLat) return;

        featuresPerMapRef.current
          .filter((x) => x.map)
          .forEach((x) => {
            x.features
              .filter((f) => f.id)
              .forEach((f) => {
                x.map.removeFeatureState(f, featureStyle);
              });
          });

        featuresPerMapRef.current = [{ map, features: featuresInEvent }];

        const otherActiveMaps = activeMaps.filter((x) => x.map !== map);

        if (otherActiveMaps.length) {
          otherActiveMaps.forEach((x) => {
            const featuresInThisMap = x.map.queryRenderedFeatures(x.map.project(lngLat), {
              layers: x.sourceLayerIds,
            });

            featuresPerMapRef.current.push({ map: x.map, features: featuresInThisMap });
          });
        }

        featuresPerMapRef.current
          .filter((x) => x.map)
          .forEach((x) => {
            x.features
              .filter((f) => f.id)
              .forEach((f) => {
                x.map.setFeatureState(f, {
                  ...(f.state ?? {}),
                  [featureStyle]: true,
                });
              });
          });
      } catch (e) {
        console.warn('handleFeaturesUnderMouse', e);
      }
    },
    [map]
  );

  useFeaturesUnderMouse(map, 'mousemove', handleFeaturesUnderMouse, sourceLayerIds, 10);
}

export default useFeatureHighlight;
