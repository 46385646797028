import { PeTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React from 'react';

const { ValueFormat } = ProjectTypes;

interface Props {
  workspaceStatistics: PeTypes.WorkspaceStatistics;
}

const WorkspaceGeocodes: React.FC<Props> = ({ workspaceStatistics }) => {
  return (
    <div className="flex flex-col gap-6 border-b border-gray-200 py-8 px-6">
      <span className="font-semibold text-gray-800">Geocodes</span>
      <div className="flex flex-col gap-3">
        <p className="flex items-center gap-1 text-sm text-gray-600">
          <span className="mr-2 rounded-md bg-[#46828B] px-2 py-1 text-xs font-semibold text-white">SE:</span>
          <span>{`${workspaceStatistics.quota.seGeocoderUsed > 0 ? 'Already used' : 'Used'}`}</span>
          <span className="font-semibold text-gray-800">
            {ValueFormatter.format(workspaceStatistics.quota.seGeocoderUsed, ValueFormat.FORMAT_NUMBER)}{' '}
          </span>
          SE geocodes from total of{' '}
          <span className="font-semibold text-gray-800">
            {ValueFormatter.format(workspaceStatistics.quota.seGeocoderQuota, ValueFormat.FORMAT_NUMBER)}{' '}
          </span>
          available in your plan.{' '}
        </p>
        <p className="flex items-center gap-1 text-sm text-gray-600">
          <span className="mr-2 rounded-md bg-[#627083] px-2 py-1 text-xs font-semibold text-white">HERE:</span>
          <span>{`${workspaceStatistics.quota.hereGeocoderUsed > 0 ? 'Already used' : 'Used'}`}</span>
          <span className="font-semibold text-gray-800">
            {ValueFormatter.format(workspaceStatistics.quota.hereGeocoderUsed, ValueFormat.FORMAT_NUMBER)}{' '}
          </span>
          HERE geocodes from total of{' '}
          <span className="font-semibold text-gray-800">
            {ValueFormatter.format(workspaceStatistics.quota.hereGeocoderQuota, ValueFormat.FORMAT_NUMBER)}
          </span>
          available in your plan.
        </p>
      </div>
    </div>
  );
};

export default WorkspaceGeocodes;
