import { Buttons, Inputs, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as peApi from '../../../pe.api';

type Inputs = {
  firstName: string;
  lastName: string;
  password: string;
};

type Props = {
  email: string;
  disabled: boolean;
};

const CreateAccountForm: React.FC<Props> = ({ email, disabled }) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const createAccountMutation = useMutation({
    mutationFn: (payload: PeTypes.CreateAccountPayload) => peApi.createAccount(payload),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
    },
  });
  const watchedFormData = watch();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    createAccountMutation.mutate({ ...data, email });
    navigate('/login');
  };
  const isSignUpDisabled =
    disabled ||
    !watchedFormData.firstName.length ||
    !watchedFormData.lastName.length ||
    !watchedFormData.password.length ||
    createAccountMutation.isLoading;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-6 mt-8 flex flex-col items-center gap-6"
      data-cy="signup-form"
      autoComplete="off"
    >
      <div className="w-full">
        <label htmlFor="code" className="mb-2 block text-xs font-semibold text-gray-800">
          First Name
        </label>
        <Controller
          control={control}
          name="firstName"
          rules={{ required: 'First name is required' }}
          render={({ field }) => (
            <Inputs.Input
              type="text"
              id="firstName"
              placeholder="First name"
              classes="w-full rounded-lg h-[42px] border-none outline-none ring-0 bg-transparent"
              data-cy="first-name-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.firstName && <p className="pt-1 text-xs text-red-600">{errors.firstName.message}</p>}
      </div>
      <div className="w-full">
        <label htmlFor="lastName" className="mb-2 block text-xs font-semibold text-gray-800">
          Last Name
        </label>
        <Controller
          control={control}
          name="lastName"
          rules={{ required: 'Last name is required' }}
          render={({ field }) => (
            <Inputs.Input
              type="text"
              id="lastName"
              placeholder="Last name"
              classes="w-full rounded-lg h-[42px] border-none outline-none ring-0 bg-transparent"
              data-cy="last-name-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.lastName && <p className="pt-1 text-xs text-red-600">{errors.lastName.message}</p>}
      </div>
      <div className="w-full">
        <label htmlFor="password" className="mb-2 block text-xs font-semibold text-gray-800">
          Password
        </label>
        <Controller
          control={control}
          name="password"
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <Inputs.Input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              classes="w-full rounded-lg h-[42px] border-none outline-none ring-0 bg-transparent"
              placeholder="Password"
              data-cy="password-input"
              value={field.value}
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  disabled={!watchedFormData.password.length}
                  data-rh={passwordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={passwordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.password.length === 0,
                      'text-gray-600': watchedFormData.password.length > 0,
                    })}
                  />
                </button>
              }
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.password && <p className="pt-1 text-xs text-red-600">{errors.password.message}</p>}
      </div>
      <Buttons.Primary type="submit" data-cy="submit-button" className="h-[40px] w-full" disabled={isSignUpDisabled}>
        Sign up
      </Buttons.Primary>
      {createAccountMutation.isError && (
        <div className="text-xs text-red-400" data-cy="error-message">
          Failed to create an account.
        </div>
      )}
      <p className="text-sm text-gray-500">
        By signing up I agree to the <span className="text-primary-600 font-semibold">Terms of service</span> and{' '}
        <span className="text-primary-600 font-semibold">Privacy Policy</span>
      </p>
    </form>
  );
};

export default CreateAccountForm;
