import { metadataApi } from '@platform/api';
import { MetadataTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';

interface Props {
  surveyNames: string[];
}

function useSurveysMetadata({ surveyNames }: Props, options?: UseQueryOptions<MetadataTypes.Survey[]>) {
  return useQuery<MetadataTypes.Survey[]>(
    ['metadata/surveys', surveyNames],
    () => metadataApi.getSurveys(surveyNames),
    options
  );
}

export default useSurveysMetadata;
