import { MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import GroupedButtons from '../shared/GroupedButtons';
import { MultiChartOptions } from './ChartTypes';

interface Props {
  options: MultiChartOptions;
}

export const MultipleBarChart: React.FC<Props> = ({ options }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [firstChartOptions] = options.chartData;

  const [selectedFilter, setSelectedFilter] = useState(firstChartOptions.series[0].name);
  const filters: string[] = firstChartOptions.series.map((s) => s.name);
  const isFilterChart = filters.length > 1;
  const handleFilterClick = (category: string) => setSelectedFilter(category);
  const activeSerieIndex = firstChartOptions.series.findIndex((s) => s.name === selectedFilter);

  const chartsToRender = options.chartData.map((chartData, index) => {
    const cpy = structuredClone(chartData);
    const [firstSerie] = cpy.series;

    let seriesData = firstSerie.data;
    if (isFilterChart) {
      const filteredSeries = cpy.series.filter((serie) => serie.name === selectedFilter);
      seriesData = filteredSeries[0]?.data ?? [];
    }

    const chartOptions: Options = {
      chart: {
        margin: 0,
        height: options.height ?? 160,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      tooltip: {
        hideDelay: 0,
        pointFormatter: function () {
          return `${ValueFormatter.format(this.y, options.chartData[0].valueFormat)}`;
        },
      },
      series: [
        {
          type: firstSerie.type,
          showInLegend: false,
          data: seriesData,
          colorByPoint: chartData.colors?.length >= 1,
          colors: chartData.colors,
          style: {
            fontWeight: 'light',
            fontSize: '12px',
          },
        } as Highcharts.SeriesOptionsType,
      ],
      xAxis: {
        visible: false,
        gridLineWidth: 0,
        categories: options.categories,
        labels: {
          enabled: false,
          style: {
            fontWeight: 'normal',
            fontSize: '0px',
          },
        },
      },
      yAxis: {
        visible: false,
        gridLineWidth: 0,
      },
      plotOptions: {
        bar: { pointPadding: 0, groupPadding: 0, pointWidth: 32 },
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return ValueFormatter.format(this.point.y, options.chartData[0].valueFormat);
            },
            style: {
              fontWeight: 'light',
              fontSize: '11px',
              textOutline: 'none',
              color: '#1F2937',
            },
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                itemDistance: 10,
              },
            },
          },
        ],
      },
    };

    return (
      <div key={`chart-${index + 1}-${options.title}`} className="flex flex-1 flex-col">
        <div className="flex-column mb-3 h-[50px] font-light">
          <div className="text-sm font-bold" style={{ color: options.chartData[index].titleColor }}>
            {options.chartData[index].titleChart}
          </div>
          <div className="mt-3 text-[13px] font-semibold">
            {ValueFormatter.format(
              options.chartData[index].series[activeSerieIndex].total,
              ProjectTypes.ValueFormat.FORMAT_NUMBER
            )}
          </div>
        </div>
        <div id={`chart-${options.title}-${index + 1}`} className="w-full">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    );
  });
  return (
    <div>
      <div className="flex cursor-pointer items-center justify-between" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="text-sm font-semibold">{options.title}</div>
        <div className="mr-[2px] mr-0 rounded-[9999px] bg-gray-100 p-1.5">
          {isExpanded ? <MUIcon name="keyboard_arrow_up" /> : <MUIcon name="keyboard_arrow_down" />}
        </div>
      </div>
      {isExpanded && (
        <>
          {isFilterChart && (
            <div className="mt-6 flex justify-end">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <GroupedButtons active={selectedFilter} onClick={handleFilterClick} values={filters} />
              </span>
            </div>
          )}
          <div className="mt-6 flex gap-5">
            <div className="flex max-w-[25%] flex-col">
              <div className="mb-3 h-[50px]">
                <div className="h-[25px]"></div>
                <div className="mt-1.5 text-end text-[14px] font-normal">Total</div>
              </div>
              <div className="flex flex-grow flex-col justify-around text-right">
                {options.categories.map((category: string, index) => {
                  return (
                    <span data-rh={category} key={index} className="cursor-default text-xs font-normal">
                      {category}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-1 gap-0.5">{chartsToRender}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultipleBarChart;
