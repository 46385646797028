import { Dropdowns } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { getPreviewValue } from './MultiColumnAddress';
import SelectColumnItem from './SelectColumnItem';

interface Props {
  data: unknown[];
  columns: PeTypes.ImportDatasetPayloadColumn[];
  value: string;
  onChange: (value: string) => void;
}

const SingleColumnAddress: React.FC<Props> = ({ columns, data, onChange, value }) => {
  const dropdownOptions: Dropdowns.DropdownOption[] = columns.map((c) => ({ name: c.name, value: c.name }));

  return (
    <div className="mt-8 flex flex-col gap-3">
      <SelectColumnItem
        label={'Full address'}
        dropDownOptions={dropdownOptions}
        value={value}
        onSelect={(option: Dropdowns.DropdownOption | null) => onChange(option != null ? (option.value as string) : '')}
        previewValue={getPreviewValue(value, columns, data)}
      />
    </div>
  );
};
export default SingleColumnAddress;
