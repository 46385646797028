import React from 'react';

interface Props {
  open: boolean;
  children: React.ReactNode;
  onCloseRequest?: () => void;
}

const RightDrawer: React.FC<Props> = ({ open, children, onCloseRequest }) => (
  <div className="pointer-events-none fixed inset-0 z-[9990]">
    {open && <button className="pointer-events-auto fixed inset-0 z-[9990] bg-black/30" onClick={onCloseRequest} />}
    <div
      className="pointer-events-auto absolute top-0 bottom-0 right-[-100%] z-[9991] shadow-md transition-all"
      style={{ right: open ? 0 : '-100%' }}
    >
      {children}
    </div>
  </div>
);

export default RightDrawer;
