import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { getTimeFromNow } from '../../helpers/helpers';
import LeftDrawer from '../shared/LeftDrawer';
import StoryMenu from './Edit/StoryMenu';
import RenameStoryModal from './RenameStoryModal';

interface Props {
  story: PeTypes.Story;
}

const StoryLeftDrawer: React.FC<Props> = ({ story }) => {
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { title } = story;
  return (
    <>
      <button className="w-12" onClick={() => setOpen((p) => !p)}>
        <MUIcon name="menu" className="rounded-lg p-[6px] text-gray-200 hover:text-white" />
      </button>
      <LeftDrawer open={open} onCloseRequest={() => setOpen(false)}>
        <div className="flex h-full w-full select-none flex-col justify-between bg-white text-gray-700 md:w-[400px]">
          <div className="flex min-h-0 w-full flex-col items-start gap-4 pt-8">
            <div className="text-acai flex items-center gap-2 px-6 py-2">
              <div className="bg-acai flex h-[24px] w-[24px] items-center justify-center rounded-full p-1 text-white">
                <MUIcon name="note_stack" className="text-[16px]" />
              </div>
              <span className="text-acai text-sm font-semibold">Story</span>
            </div>
            <div className="flex w-full items-center justify-between px-6">
              <div className="w-10/12 whitespace-pre-line break-words text-lg font-semibold">{title}</div>
              <div
                className="flex cursor-pointer items-center justify-center"
                onClick={() => setShowRenameModal(true)}
                data-rh="Rename"
              >
                <div className="flex h-[36px] w-[36px] items-center justify-center rounded-[999px] border border-gray-300 hover:bg-gray-200">
                  <MUIcon name="edit" />
                </div>
              </div>
            </div>
            {story.editedAt && (
              <div className="px-6 text-xs text-gray-600">{`Last edit was ${getTimeFromNow(story.editedAt)}`}</div>
            )}
            <StoryMenu story={story} />
          </div>
        </div>
      </LeftDrawer>
      {showRenameModal && (
        <RenameStoryModal
          story={story}
          onCloseRequest={() => {
            setShowRenameModal(false);
          }}
        />
      )}
    </>
  );
};

export default StoryLeftDrawer;
