import React from 'react';
import tw from 'tailwind-styled-components';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

export const LinkButton: React.FC<ButtonBaseProps> = (props) => <StyledButton {...props} />;
export default LinkButton;

const StyledButton = tw(ButtonBase)`
  text-gray-600
  bg-transparent
  shadow-none
  ring-0
  hover:bg-gray-100
`;
