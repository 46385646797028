import { Buttons, MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSessionContext } from '../../contexts/SessionContext';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useWorkspaceStatistics from '../../hooks/useWorkspaceStatistics';
import ImportWizard from '../Import/ImportWizard';
import DatasetListItem from './DatasetListItem';
import EmptyCatalog from './EmptyCatalog';
import useDatasetList from './useDatasetList';

const DATA_PROCESSING_URL = process.env.NX_DATA_PROCESSING_URL;

const DatasetsList: React.FC = () => {
  const datasetsTypes = [ProjectTypes.LayerType.TABLE, ProjectTypes.LayerType.POLYGON, ProjectTypes.LayerType.POINT];

  const { isEmptyCatalog, searchControls, noHits, datasets, paging } = useDatasetList(datasetsTypes);
  const { user } = useSessionContext();
  const { activeWorkspace } = useWorkspaceContext();
  const [isImportWizardVisible, setIsImportWizardVisible] = useState<boolean>(false);
  const fullName = user ? `${user.firstName} ${user.lastName}` : 'N/A';
  const email = user ? user.email : 'N/A';
  const workspaceName = activeWorkspace ? activeWorkspace.name : 'N/A';

  const workspaceStatisticsQuery = useWorkspaceStatistics(activeWorkspace.id);

  const queryString = `?email=${email}&workspaceId=${activeWorkspace.id}&workspaceName=${workspaceName}&name=${fullName}`;
  return (
    <div className="flex w-full min-w-0 flex-col gap-8 pb-12">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="text-3xl">All Datasets</div>
          <div className="flex items-center justify-center gap-6">
            {/*<div className="hidden">{activeWorkspace && <DatasetImportsDrawer workspaceId={activeWorkspace.id} />}</div>*/}
            {DATA_PROCESSING_URL && (
              <Link to={`${DATA_PROCESSING_URL}${queryString}`} target="_blank">
                <Buttons.Secondary
                  className="w-[149px] gap-2 rounded-[999px] p-2"
                  icon={<MUIcon name="concierge" className="text-gray-600" />}
                >
                  Data services
                </Buttons.Secondary>
              </Link>
            )}
            <Buttons.Primary
              className="w-[142px] rounded-[999px]"
              icon={<MUIcon name="upload" />}
              onClick={() => setIsImportWizardVisible(true)}
              disabled={!workspaceStatisticsQuery.data}
              data-cy="upload-data-button"
            >
              Upload data
            </Buttons.Primary>
          </div>
        </div>
        {!isEmptyCatalog && searchControls}
      </div>
      {isEmptyCatalog ? (
        <EmptyCatalog />
      ) : (
        <div className="w-full">
          {noHits}
          {datasets.map((item) => (
            <DatasetListItem key={item.id} dataset={item} />
          ))}
          {paging}
        </div>
      )}
      {isImportWizardVisible && workspaceStatisticsQuery.data && (
        <ImportWizard
          workspaceStatistics={workspaceStatisticsQuery.data}
          workspaceName={workspaceName}
          onWizardClose={() => setIsImportWizardVisible(false)}
        />
      )}
    </div>
  );
};

export default DatasetsList;
