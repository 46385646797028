import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React, { useEffect, useState } from 'react';
import { Buttons } from '..';
import MUIcon from '../MUIcon';

interface Props {
  label: string;
  count: number;
  itemsPerPage: number;
  page: number;
  onPageChange: (page: number) => void;
  nextPageIcon?: JSX.Element;
  prevPageIcon?: JSX.Element;
  nextPageTitle?: string;
  prevPageTitle?: string;
  lastPageIcon?: JSX.Element;
  firstPageIcon?: JSX.Element;
  lastPageTitle?: string;
  firstPageTitle?: string;
  disabled?: boolean;
  lastPageDisabled?: boolean;
  nextPageDisabled?: boolean;
}

const Pagination: React.FC<Props> = ({
  label,
  count,
  itemsPerPage,
  page,
  onPageChange,
  nextPageIcon = <MUIcon name="chevron_right" className="text-[24px]" />,
  prevPageIcon = <MUIcon name="chevron_left" className="text-[24px]" />,
  nextPageTitle = 'Go to next page',
  prevPageTitle = 'Go to previous page',
  lastPageIcon = <MUIcon name="last_page" className="text-[24px]" />,
  firstPageIcon = <MUIcon name="first_page" className="text-[24px]" />,
  lastPageTitle = 'Go to last page',
  firstPageTitle = 'Go to first page',
  disabled = false,
  lastPageDisabled = false,
  nextPageDisabled = false,
}) => {
  const [numberOfPages, setNumberOfPages] = useState<number>(Math.floor(count / itemsPerPage));

  useEffect(() => {
    const pagesNum = Math.floor(count / itemsPerPage);
    const diff = count - pagesNum * itemsPerPage;
    const newNumberOfPages = diff ? pagesNum + 1 : pagesNum;
    if (newNumberOfPages === numberOfPages - 1 && page > 0) {
      onPageChange(page - 1); // if we delete all items from one page, we should return to previous one
    }
    setNumberOfPages(newNumberOfPages);
  }, [count, itemsPerPage]);

  const from = page * itemsPerPage + 1;
  const to = page === numberOfPages - 1 ? count : page * itemsPerPage + itemsPerPage;

  const onPrevPageClick = () => onPageChange(page - 1);
  const onNextPageClick = () => onPageChange(page + 1);

  const total = count ? `${format(count)}` : 'unknown';

  return (
    <div className="flex items-center text-xs text-gray-600">
      <div className="mr-1">{label}</div>
      <div className="mr-4 px-3 py-2 font-semibold">{`${format(from)} - ${format(to)} of ${total}`}</div>
      <div className="flex gap-3">
        <Buttons.Secondary
          disabled={page === 0 || disabled}
          icon={firstPageIcon}
          onClick={() => onPageChange(0)}
          data-rh={firstPageTitle}
          className="h-[28px] w-[28px] rounded-full bg-gray-200 text-gray-700 ring-0 hover:bg-gray-100"
        />
        <Buttons.Secondary
          disabled={page === 0 || disabled}
          icon={prevPageIcon}
          onClick={onPrevPageClick}
          data-rh={prevPageTitle}
          className="h-[28px] w-[28px] rounded-full bg-gray-200 text-gray-700 ring-0 hover:bg-gray-100"
        />
        <Buttons.Secondary
          disabled={page === numberOfPages - 1 || disabled || nextPageDisabled}
          icon={nextPageIcon}
          onClick={onNextPageClick}
          data-rh={nextPageTitle}
          className="h-[28px] w-[28px] rounded-full bg-gray-200 text-gray-700 ring-0 hover:bg-gray-100"
        />
        <Buttons.Secondary
          disabled={page === numberOfPages - 1 || disabled || lastPageDisabled}
          icon={lastPageIcon}
          onClick={() => onPageChange(numberOfPages - 1)}
          data-rh={lastPageTitle}
          className="h-[28px] w-[28px] rounded-full bg-gray-200 text-gray-700 ring-0 hover:bg-gray-100"
        />
      </div>
    </div>
  );
};

const format = (value: number) => ValueFormatter.format(value, ProjectTypes.ValueFormat.FORMAT_NUMBER_NO_DECIMAL);

export default Pagination;
