import { Buttons } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import DatasetIcon from '../DatasetIcon';
import Details from '../Details';
import ErrorModal from '../ErrorModal';
import UserAvatar from '../UserAvatar';

interface Props {
  item: PeTypes.Notification<PeTypes.ImportDatasetNotification>;
}

const ImportDatasetNotification: React.FC<Props> = ({ item }) => {
  const { metadata, createdAt, createdBy } = item;
  const { status, progress, datasetTitle } = metadata;
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  let statusCopy;
  switch (status) {
    case PeTypes.DatasetImportStatus.COMPLETED:
      statusCopy = 'Imported';
      break;
    case PeTypes.DatasetImportStatus.PENDING:
    case PeTypes.DatasetImportStatus.RUNNING:
      statusCopy = `Importing`;
      break;
    case PeTypes.DatasetImportStatus.FAILED:
      statusCopy = <span className="text-rose-700">Failed to import</span>;
      break;
  }
  return (
    <div className="mr-0 flex w-full items-start gap-2">
      <DatasetIcon progress={progress} type={metadata.datasetType} status={item.metadata.status} />
      <div className="flex flex-col items-start gap-2 pl-2">
        <div className="whitespace-normal text-start text-sm">
          {statusCopy}{' '}
          <span className="break-all font-semibold group-hover:underline">{datasetTitle ?? metadata.filename}</span>
        </div>
        <Details userId={createdBy} status={status} createdAt={createdAt} />
        {status === PeTypes.DatasetImportStatus.FAILED && (
          <Buttons.Secondary
            className="mt-2 px-3 py-1.5 text-sm font-semibold text-gray-900"
            onClick={(event) => {
              setShowErrorModal(true);
              event.stopPropagation();
            }}
          >
            View Details
          </Buttons.Secondary>
        )}
      </div>
      <div className="ml-auto pt-1">
        <UserAvatar userId={createdBy} />
      </div>
      {showErrorModal && metadata.error && (
        <ErrorModal
          action="Importing"
          errorMessage={metadata.error.message}
          onCloseRequest={() => setShowErrorModal(false)}
        />
      )}
    </div>
  );
};

export default ImportDatasetNotification;
