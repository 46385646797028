import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface Props {
  title?: string;
  content: string;
  trigger?: React.ReactNode;
}

const Popup: React.FC<Props> = ({ title, content, trigger }) => {
  return (
    <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
      <Menu as="div" className="relative ml-3">
        <div>{trigger}</div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <div className="mx-2">
                {title && <div className="text-md font-semibold">{title}</div>}
                <div className="text-justify text-sm font-light">{content}</div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Popup;
