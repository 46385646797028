import { ButtonGroup, Table } from '@platform/shared/ui';
import React, { useState } from 'react';
import { columnFormatter, getSuffixIcon } from '../../Datasets/Dataset/DatasetTable/utils';
import { PreviewMap } from '../common';
import { ImportProgress } from '../ImportWizard';

const TableComponent = Table.default;

interface Props {
  importData: ImportProgress;
}

enum Tab {
  Table,
  Map,
}

const buttonGroupOptions = [
  {
    value: Tab.Table,
    label: 'Table',
  },
  {
    value: Tab.Map,
    label: 'Map',
  },
];

const ImportPreview: React.FC<Props> = ({ importData }) => {
  const [activeTab, setActiveTab] = useState(buttonGroupOptions[0].value);

  const cols =
    importData.columns.map((x, index: number) => ({
      id: `${x.name}_${index}`,
      title: x.name,
      label: x.label ?? '',
      suffixIcon: getSuffixIcon(x.type),
      formatter: (v: unknown) => columnFormatter(v, x.formatting),
    })) ?? [];

  const mapPreview = (
    <div className="h-[514px] max-h-[514px] w-full">
      <PreviewMap featureCollection={importData.featureCollection} />
    </div>
  );

  const table = (
    <div className="flex min-h-0 w-full flex-grow bg-white">
      <TableComponent rows={importData.data.slice(0, 20)} columns={cols} classes="w-[400px]" />
    </div>
  );
  const shouldShowMap =
    importData.fileContainsGeographies ||
    importData.geo?.latitude ||
    importData.geo?.longitude ||
    importData.geo?.geocode;

  return (
    <div className="flex w-full flex-col items-center justify-center gap-8">
      {shouldShowMap && (
        <div className="w-8/12">
          <ButtonGroup options={buttonGroupOptions} value={activeTab} onChange={(v) => setActiveTab(v as Tab)} />
        </div>
      )}
      <div className="w-11/12">{activeTab === buttonGroupOptions[0].value ? table : mapPreview}</div>
    </div>
  );
};

export default ImportPreview;
