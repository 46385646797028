import { Modal, MUIcon } from '@platform/shared/ui';
import React from 'react';

interface Props {
  onCloseRequest?: () => void;
  action: string;
  errorMessage: string;
}

const ErrorModal: React.FC<Props> = ({ onCloseRequest, errorMessage, action }) => {
  const title = (
    <div className="flex items-center gap-4">
      <div className="bg-acai flex h-[32px] w-[32px] items-center justify-center rounded-[999px] bg-rose-100 px-3">
        <MUIcon name="error" className="text-[18px] text-rose-700" />
      </div>
      <div className="flex flex-col">
        <div className="font-semibold text-gray-800">{action} error</div>
      </div>
    </div>
  );

  return (
    <Modal
      title={title}
      okLabel="OK"
      okClassNames="px-5"
      className="w-[512px]"
      hideCancel={true}
      onDone={onCloseRequest}
      onCloseRequest={onCloseRequest}
    >
      <div className="flex w-full whitespace-normal text-start text-sm text-gray-700">{errorMessage}</div>
    </Modal>
  );
};

export default ErrorModal;
