// @ts-ignore
import * as numerable from 'numerable';

// Define an array of supported currency codes according to ISO-4217 standards
// https://www.iso.org/iso-4217-currency-codes.html
const SUPPORTED_CURRENCIES = ['USD', 'EUR', 'JPY', 'GBP', 'RMB', 'CHF', 'CAD', 'AUD', 'RUB', 'INR'];

/**
 * Export a function named 'format' that takes an unknown value and a format string
 * @param value
 * @param format
 */
export const format = (value: unknown, format: string): string => {
  // Attempt to find a currency code within the format string that matches one of the supported currencies
  const currency = SUPPORTED_CURRENCIES.find((currencyIso) => format.includes(currencyIso));

  // If a supported currency code is found within the format string
  if (currency) {
    // Replace the currency code in the format string with a '$' symbol to denote currency formatting
    const processedFormat = format.replace(currency, '$');
    // Use the numerable library to format the provided value as a string, with the processed format string and currency information
    return numerable.format(value?.toString(), processedFormat, { currency });
  }
  // If no supported currency code is found, format the value using the original format string without currency information
  return numerable.format(value?.toString(), format, { scalePercentage: false });
};
