import { Hooks, MapSpecModifiers, Utils } from '@platform/maplibre';
import { useSystemPalettes } from '@platform/shared/hooks';
import { Buttons, MUIcon, Popup } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useState } from 'react';

const { useAutoAdjust } = Hooks;

export interface Props {
  mapObjects: ProjectTypes.MapObject[];
}

const AutoAdjust: React.FC<Props> = ({ mapObjects }) => {
  const [mapObj, ...rest] = mapObjects;

  const [isLoading, setIsLoading] = useState(false);
  const { colorPalette, selection } = mapObj.styleSpec.dataLayer;
  const dataLayerId = Utils.getDataLayerFingerprint(mapObj.styleSpec.dataLayer);
  const { autoAdjust, isAllowed } = useAutoAdjust(mapObj.ref, selection, dataLayerId);
  const { get: getPalettes } = useSystemPalettes();

  const handleAutoAdjust = async () => {
    try {
      setIsLoading(true);
      const steps = await autoAdjust();

      if (!steps?.length) return;

      const systemPalettes = await getPalettes(selection[0].surveyName);
      const currentPalette =
        systemPalettes.find((x) => x.id === colorPalette.id && x.type === colorPalette.type) ?? systemPalettes[0];
      const updatedWithNewCutPoints = await MapSpecModifiers.adjustCutPoints(mapObj.styleSpec, steps, currentPalette);

      if (!updatedWithNewCutPoints) return;
      mapObj.updateMapSpec(updatedWithNewCutPoints);
    } catch {
      //TODO: handle error
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAllowed) return null;

  return (
    <>
      <Buttons.Soft
        className="bg-transparent text-xs shadow-none"
        onClick={() => handleAutoAdjust()}
        disabled={isLoading}
      >
        {isLoading ? 'ADJUSTING...' : 'AUTO ADJUST'}
      </Buttons.Soft>
      <Popup
        trigger={
          <div className="flex items-center justify-center">
            <MUIcon name="info" className="text-gray-500" />
          </div>
        }
        content={
          <span className="select-none text-xs text-gray-700">
            <b>Auto adjust</b> calculates the ranges based on the currently visible part of the map. We use 8 cut points
            and the natural breaks classification method (K-means).
          </span>
        }
        vAlignment="top-bottom"
      />
    </>
  );
};

export default AutoAdjust;
