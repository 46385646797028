import React from 'react';
import { useParams } from 'react-router-dom';
import Profile from '../components/Profiles/Profile';
import ErrorPage from '../components/shared/ErrorPage';

const ProfilePage: React.FC = () => {
  const { profileId, mode = 'view' } = useParams<{ profileId: string; mode: 'edit' | 'view' }>();

  if (!profileId) return <ErrorPage />;

  return <Profile profileId={profileId} mode={mode} />;
};

export default ProfilePage;
