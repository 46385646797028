import React, { useEffect, useRef } from 'react';

export interface Props {
  tooltip: React.ReactNode;
}

const TooltipAnchor: React.FC<Props> = ({ tooltip }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popupElementRef = useRef<HTMLDivElement>(null);
  const childrenElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!containerRef.current) return;
      if (!popupElementRef.current) return;
      if (!childrenElementRef.current) return;

      const tooltipAnchorBBox = containerRef.current.getBoundingClientRect();
      const { left, width, top, height } = tooltipAnchorBBox;

      const mapX = e.clientX - left;
      const mapY = e.clientY - top;

      const eventTargetIsMap = e.target && (e.target as HTMLElement)?.className === 'maplibregl-canvas';

      const isOverAnchor = eventTargetIsMap && mapX > 0 && mapX < width && mapY > 0 && mapY < height;

      if (isOverAnchor) {
        // mouse is inside of anchor
        const childBBox = childrenElementRef.current.getBoundingClientRect();
        popupElementRef.current.style.opacity = '1';
        popupElementRef.current.style.top = `${Math.min(e.clientY - top, height - childBBox.height)}px`;
        popupElementRef.current.style.left = `${Math.min(e.clientX - left, width - childBBox.width)}px`;
      } else {
        // mouse is outside of anchor
        popupElementRef.current.style.opacity = '0';
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [containerRef]);

  return (
    <div className="pointer-events-none absolute top-1 left-1 right-1 bottom-1 z-50" ref={containerRef}>
      <div
        className="absolute m-2 h-[1px] w-[1px] -translate-x-1/2 -translate-y-1/2 transition-opacity"
        ref={popupElementRef}
      >
        <div ref={childrenElementRef} className="absolute p-2">
          {tooltip}
        </div>
      </div>
    </div>
  );
};

export default TooltipAnchor;
