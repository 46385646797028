import { ButtonGroup } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import { GeoJSON } from 'highcharts';
import React, { useState } from 'react';
import GeocodesError from '../Errors/GeocodesError';
import { ImportProgress } from '../ImportWizard';
import GeocodeGeography from './GeocodeGeography';
import LatLngGeography from './LatLngGeography';

enum Tab {
  LatLng,
  GeoCode,
}

const buttonGroupOptions = [
  {
    value: Tab.LatLng,
    label: 'Lat/Long',
  },
  {
    value: Tab.GeoCode,
    label: 'Geocode',
  },
];

interface Props {
  importData: ImportProgress;
  data: unknown[][];
  columns: PeTypes.ImportDatasetPayloadColumn[];
  workspaceStatistics: PeTypes.WorkspaceStatistics;
  onSelectLatLong: (
    lat: string | undefined,
    lng: string | undefined,
    featureCollection: GeoJSON.FeatureCollection | undefined
  ) => void;
  onSelectGeoCode: (
    geocodeObj: PeTypes.ImportDatasetGeoCode | undefined,
    featureCollection: GeoJSON.FeatureCollection | undefined
  ) => void;
  onClear: () => void;
}

const CreateGeography: React.FC<Props> = ({
  columns,
  data,
  onSelectLatLong,
  importData,
  onSelectGeoCode,
  onClear,
  workspaceStatistics,
}) => {
  const defaultTab = importData.geo?.geocode ? Tab.GeoCode : Tab.LatLng;
  const [activeTab, setActiveTab] = useState(defaultTab);
  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex w-8/12 flex-col gap-8">
        <ButtonGroup
          options={buttonGroupOptions}
          value={activeTab}
          onChange={(v) => {
            setActiveTab(v as Tab);
            onClear();
          }}
        />
        {importData.errors.geocodeLimit && activeTab === Tab.GeoCode && (
          <GeocodesError workspaceStatistics={workspaceStatistics} />
        )}
        {activeTab === buttonGroupOptions[0].value ? (
          <LatLngGeography
            columns={columns}
            data={data}
            onSelect={onSelectLatLong}
            lat={importData.geo?.latitude}
            lng={importData.geo?.longitude}
          />
        ) : (
          <GeocodeGeography columns={columns} data={data} onSelect={onSelectGeoCode} importData={importData} />
        )}
      </div>
    </div>
  );
};

export default CreateGeography;
