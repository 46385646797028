import React from 'react';

import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useRecentStories from '../../hooks/useRecentStories';
import RecentStoriesList from '../Stories/RecentStoriesList';

const ITEMS_PER_PAGE = 4;
const PAGE_NUMBER = 0;

const RecentStories: React.FC = () => {
  const { activeWorkspace } = useWorkspaceContext();
  const { data, isLoading, isIdle, isError } = useRecentStories(activeWorkspace.id, PAGE_NUMBER, ITEMS_PER_PAGE);

  if (isLoading || isIdle) return <span className="text-gray-500">Loading recent stories...</span>;
  if (isError) return <>Something went wrong</>;

  return <RecentStoriesList stories={data} title="Recent Stories" />;
};

export default RecentStories;
