export const LANGUAGE = 'en';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const START_WEEK = 'sun';

export const DATE_LOOKING_OPTIONS = ['forward', 'backward', 'middle'];

export const DAYS = [0, 1, 2, 3, 4, 5, 6];

export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const CALENDAR_SIZE = 42;
