import React from 'react';

interface Props {
  width?: number;
  height?: number;
  fillColor?: string;
}

const Spinner: React.FC<Props> = ({ width = 91, height = 91, fillColor = '#1257DD' }) => {
  return (
    <svg
      className="svg-loader__icon"
      width={width}
      height={height}
      x="0px"
      y="0px"
      viewBox="15 20 68 68"
      enableBackground="new 0 0 0 0"
    >
      <path
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        transform="rotate(72 50 50)"
        fill={fillColor}
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default Spinner;
