import { useQuery } from 'react-query';
import * as peApi from '../pe.api';

const useStory = (storyId: string, enabled = true) => {
  const storyQuery = useQuery(['story', storyId], () => peApi.getStory(storyId));
  const slidesQuery = useQuery(['story-slides', storyId], () => peApi.getStorySlides(storyId), {
    enabled,
  });

  return { storyQuery, slidesQuery };
};

export default useStory;
