import { NextFunction, Request, Response } from 'express';
import Joi, { ValidationErrorItem } from 'joi';

export const runAsyncWrapper =
  (
    callback: (
      req: Request | Request<unknown, unknown, unknown, unknown>,
      res: Response | Response<unknown>,
      next?: NextFunction
    ) => Promise<unknown>
  ) =>
  (req: Request, res: Response, next: NextFunction) =>
    callback(req, res, next).catch(next);

export const validateRequestMiddleware = (
  paramsSchema: Joi.Schema | null,
  querySchema: Joi.Schema | null,
  bodySchema: Joi.Schema | null
) => {
  return (req: Request, res: Response, next: NextFunction) => {
    const errors: ValidationErrorItem[] = [];

    const { params, query, body } = req;

    // validate request params
    if (paramsSchema) {
      const { error } = paramsSchema.validate(params);
      if (error) errors.push(...error.details);
    }

    // validate request body
    if (bodySchema) {
      const { error } = bodySchema.validate(body);
      if (error) errors.push(...error.details);
    }

    // validate request query params
    if (querySchema) {
      const { error } = querySchema.validate(query);
      if (error) errors.push(...error.details);
    }

    if (errors.length) {
      return res.status(400).send(errors);
    } else {
      return next();
    }
  };
};
