import { MetadataTypes } from '@platform/types';

type SystemFiltersApiType = (survey: string, dataset: string) => Promise<MetadataTypes.SystemCategoryFilters[]>;

let store: SystemFilters;

function useSystemFilterSets() {
  const initialize = (api: SystemFiltersApiType) => {
    store = new SystemFilters(api);
  };

  const get = (surveyName: string, datasetAbbr: string) => {
    return store.get(surveyName, datasetAbbr);
  };

  return {
    initialize,
    get,
  };
}

export default useSystemFilterSets;

class SystemFilters {
  _api;
  _loaded: Record<string, MetadataTypes.SystemCategoryFilters[]>;

  constructor(api: SystemFiltersApiType) {
    this._api = api;
    this._loaded = {};
  }

  async get(surveyName: string, datasetAbbr: string) {
    const surveyNameAndDataset = `${surveyName}.${datasetAbbr}`;

    if (!this._api) return [];

    if (this._loaded[surveyNameAndDataset]) return this._loaded[surveyNameAndDataset];
    try {
      const fetched = await this._api(surveyName, datasetAbbr);
      this._loaded[surveyNameAndDataset] = fetched;
      return fetched;
    } catch {
      // TODO:
      return [];
    }
  }
}
