import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import EmptyState from '../shared/EmptyState';
import RecentProjectItem from './RecentProjectItem';

interface Props {
  projects: PeTypes.RecentProject[];
}

const RecentProjectList: React.FC<Props> = ({ projects }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full justify-between">
        <div className="flex items-center justify-center gap-2">
          <MUIcon name="folder" className="text-gray-600" aria-hidden="false" />
          <div className="text-lg font-bold">Recent Projects</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2" data-cy="recent-projects-list">
        {projects.length === 0 && (
          <EmptyState
            title="No projects yet."
            trigger={<Buttons.Primary>Create a Project</Buttons.Primary>}
            action={PeTypes.Action.NEW_PROJECT}
          />
        )}
        {projects.map((project) => (
          <RecentProjectItem key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default RecentProjectList;
