import { MUIcon } from '@platform/shared/ui';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const BaseError: React.FC<Props> = ({ children }) => {
  return (
    <div className="mb-2 flex w-full items-center gap-4 rounded-lg bg-red-50 py-4 px-5 text-sm">
      <div className="flex h-full items-center">
        <div className="flex h-[28px] w-[28px] items-center justify-center rounded-[999px] bg-red-700">
          <MUIcon name="exclamation" className="text-white" />
        </div>
      </div>
      <div className="flex w-full">{children}</div>
    </div>
  );
};

export default BaseError;
