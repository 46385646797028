import { MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React, { useState } from 'react';

interface Props {
  title: string;
  data: number[];
  categories: string[];
  colors: string[];
  labelColors: string[];
}

const StackingChart: React.FC<Props> = ({ data, colors, title, categories, labelColors }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <div className="flex w-full min-w-[200px] flex-col">
      <div className="flex cursor-pointer items-center justify-between" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="text-sm font-semibold">{title}</div>
        <div className="mr-0 rounded-[9999px] bg-gray-100 p-1.5">
          {isExpanded ? <MUIcon name="keyboard_arrow_up" /> : <MUIcon name="keyboard_arrow_down" />}
        </div>
      </div>
      {isExpanded && (
        <>
          <div className="mt-6 flex h-[28px] min-w-full" style={{ width: '100%' }}>
            {data.map((value: number, index) => {
              return (
                <div
                  key={`${value}_${index}`}
                  className="flex items-center justify-center text-[12px] text-gray-800"
                  style={{ width: `${value}%`, backgroundColor: colors[index] }}
                >
                  {ValueFormatter.format(value, ProjectTypes.ValueFormat.FORMAT_PERCENT_2_DECIMAL)}
                </div>
              );
            })}
          </div>
          <div className="mt-4 flex items-center justify-between">
            {categories.map((category, index) => (
              <span
                key={`${category}_${index}`}
                className="text-xs font-semibold"
                style={{ color: labelColors[index] }}
              >
                {category}
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default StackingChart;
