import * as GeoJSON from 'geojson';
import { parseGeoJsonFeatureCollection } from '../utils';

/**
 * Parses Json and GeoJson files
 * @param file
 */
export default async (
  file: File,
  limit?: number
): Promise<{
  columns: string[] | null;
  data: unknown[][] | null;
  totalRowsCount: number;
  featureCollection?: GeoJSON.FeatureCollection;
  error?: unknown;
}> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;

      try {
        const jsonObj = JSON.parse(text);

        // this JSON is actually a GeoJson
        if (jsonObj.type === 'FeatureCollection' && jsonObj.features) {
          const geoJsonObj = jsonObj as GeoJSON.FeatureCollection;
          const { columns, data } = parseGeoJsonFeatureCollection(geoJsonObj, limit);
          if (!columns.length) {
            resolve({ columns: null, data: null, totalRowsCount: -1, error: 'empty' });
            return;
          }
          resolve({ columns, data, featureCollection: geoJsonObj, totalRowsCount: geoJsonObj.features.length });
        } else {
          // this JSON is a plain file
          // currently we accept following version of JSON:
          // 1. array like json with objects
          // [
          //   {
          //     "album": "The White Stripes",
          //     "year": 1999,
          //     "US_peak_chart_post": "-"
          //   },
          //   {
          //     "album": "De Stijl",
          //     "year": 2000,
          //     "US_peak_chart_post": "-"
          //   },
          // ]

          // 2. array like json with arrays
          // [
          //   ["The White Stripes",1999, "-"],
          //   ["De Stijl",2000, 1],
          // ]

          if (!Array.isArray(jsonObj)) {
            resolve({ columns: null, data: null, totalRowsCount: -1, error: 'not a valid JSON (array-like)' });
          }

          if (!jsonObj.length) {
            resolve({ columns: null, data: null, totalRowsCount: -1, error: 'empty' });
          }

          let columns: string[] = [];
          let data: unknown[][];

          if (Array.isArray(jsonObj[0])) {
            const firstItemAsArray = jsonObj[0] as unknown[];
            // each item in json is an array like ['a', 'b', 'c']
            // columns will be named by default `Column 1`, 'Column 2' etc.
            columns = firstItemAsArray.map((_, idx) => `Column ${idx + 1}`);
            data = jsonObj;
          } else {
            // each item in json is an object like { "a": "1", "b":2 }
            const firstItemAsObj = jsonObj[0] as Record<string, unknown>;
            columns = Object.keys(firstItemAsObj);
            data = jsonObj.reduce(
              (p: unknown[][], item: Record<string, unknown>) => [...p, columns.map((col) => item[col])],
              []
            );
          }

          resolve({ columns, data: data.slice(0, limit ?? data.length), totalRowsCount: data.length });
        }
      } catch (error) {
        resolve({ columns: [], data: null, totalRowsCount: -1, error: 'Error while parsing json file' });
      }
    };
    reader.onerror = (e) => {
      resolve({ columns: [], data: null, totalRowsCount: -1, error: 'Error while loading json file' });
    };
    reader.readAsText(file);
  });
};
