import { MetadataTypes } from '@platform/types';
import React from 'react';
import { Dropdowns } from '../../lib';

const { Dropdown } = Dropdowns;

export interface Props {
  options: MetadataTypes.MetadataSocialExplorerDataSourceLayer[];
  onChange: (v: MetadataTypes.MetadataSocialExplorerDataSourceLayer) => void;
  value?: MetadataTypes.MetadataSocialExplorerDataSourceLayer;
  disabled?: boolean;
  showHeader?: boolean;
}

const GeoLevelPicker: React.FC<Props> = ({ value, options, disabled, onChange, showHeader }) => {
  const handleSummaryLevelChange = (item: Dropdowns.DropdownOption) => {
    const selection = options.find((opt) => opt.id === item.value);

    if (selection) {
      onChange(selection);
    }
  };

  const selectOptions = options.map((asl) => ({
    value: asl.id,
    name: asl['summary-level'].title.replace('Data: ', ''),
  }));

  const header = showHeader ? (
    <div className="-mx-4 -my-4 flex justify-center gap-2 bg-gray-100 p-3 text-gray-700">
      <div className="text-xs">
        <b className="pr-1">Note: </b>Changing the geography will clear your current selection on the map
      </div>
    </div>
  ) : undefined;

  return (
    <Dropdown
      header={header}
      disabled={disabled}
      placeholder="Loading"
      value={value?.id}
      options={selectOptions}
      triggerClasses="bg-primary-100 text-ellipsis hover:bg-primary-200 px-3 py-2 select-none rounded-full"
      triggerLabelClasses="text-left flex items-center text-xs mx-2"
      onSelect={handleSummaryLevelChange}
      hAlignment="left"
      menuClasses="w-[288px]"
    />
  );
};

export default GeoLevelPicker;
