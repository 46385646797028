import classNames from 'classnames';
import React, { ForwardedRef } from 'react';
import tw from 'tailwind-styled-components';

type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface ButtonBaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  danger?: boolean;
  size?: ButtonSize;
  icon?: JSX.Element | null;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  ref?: ForwardedRef<HTMLButtonElement>;
}

export const ButtonBase: React.FC<ButtonBaseProps> = ({
  danger,
  children,
  size = 'md',
  icon,
  disabled,
  className,
  iconClassName,
  ref,
  ...other
}) => {
  return (
    <StyledButton
      type="button"
      className={classNames('flex items-center gap-2', className)}
      $danger={danger}
      $onlyIcon={icon != null && !children}
      $size={size}
      disabled={disabled}
      ref={ref}
      {...other}
    >
      {icon && <span className={classNames('flex-0 flex', iconClassName)}>{icon}</span>}
      {children && <span className="w-full truncate">{children}</span>}
    </StyledButton>
  );
};

export default ButtonBase;

const StyledButton = tw.button<{ $danger?: boolean; $onlyIcon?: boolean; disabled?: boolean; $size: ButtonSize }>`
  flex
  items-center
  justify-center
  whitespace-nowrap
  bg-primary-600
  hover:bg-primary-500
  focus-visible:outline-primary-600
  rounded-md
  px-3
  py-2
  text-sm
  select-none
  font-semibold
  text-white
  shadow-sm
  focus-visible:outline
  focus-visible:outline-2
  focus-visible:outline-offset-2
  ${(p) => (p.$danger ? 'bg-red-500 hover:bg-red-700 text-white' : '')}
  ${(p) => (!p.$onlyIcon ? '' : 'gap-1')}
  ${(p) => (p.disabled ? 'bg-gray-500 text-white opacity-40 hover:bg-gray-500' : '')}
  ${(p) => {
    switch (p.$size) {
      case 'xs':
        return p.$onlyIcon ? 'p-1' : 'px-1 py-1';
      case 'sm':
        return p.$onlyIcon ? 'p-2' : 'px-2 py-1';
      case 'md':
        return p.$onlyIcon ? 'p-2.5' : 'px-2.5 py-1.5';
      case 'lg':
        return p.$onlyIcon ? 'p-3' : 'px-3 py-2';
      case 'xl':
        return p.$onlyIcon ? 'p-3.5' : 'px-3.5 py-2.5';
    }
  }}
`;
