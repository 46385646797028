import { ProjectTypes } from '@platform/types';
import { LngLatLike, Map, MapGeoJSONFeature, Point2D } from 'maplibre-gl';
import React from 'react';
import useDatasetLastPublishedState from '../../../../hooks/useDatasetLastPublishedState';
import { columnFormatter } from '../../../Datasets/Dataset/DatasetTable/utils';
import useWorkingLayerTooltipData from './useWorkingLayerTooltipData';
import WorkingLayerTooltip from './WorkingLayerTooltip';
import WorkingLayerTooltipPositioner from './WorkingLayerTooltipPositioner';

interface Props {
  map: Map | null;
  workingLayer: ProjectTypes.WorkingLayerSpec;
  features: MapGeoJSONFeature[];
  latLong: LngLatLike | null;
  onFeaturesUnderMouse: (wId: string, f: MapGeoJSONFeature[], latLng: LngLatLike | null) => void;
  activeFeature: MapGeoJSONFeature | null;
  featureNavi?: React.ReactNode;
  emptyPlaceholder: React.ReactNode;
  onClose?: () => void;
}

const WorkingLayerTooltipWrapper = ({
  map,
  onFeaturesUnderMouse,
  features,
  latLong,
  workingLayer,
  activeFeature,
  featureNavi,
  onClose,
  emptyPlaceholder,
}: Props) => {
  const tooltip = workingLayer.tooltip as ProjectTypes.WorkingLayerTooltip;
  const { query: lastPublishedDataQuery } = useDatasetLastPublishedState({ datasetId: workingLayer.datasetId });
  const [featuresData, isLoading] = useWorkingLayerTooltipData({
    workingLayer,
    map,
    features,
    onFeaturesUnderMouse,
  });

  if (isLoading && !emptyPlaceholder) return null;

  const lastPublishedData = lastPublishedDataQuery.data;
  if (!activeFeature || !lastPublishedData) return null;
  const activeFeatureIndex = features.indexOf(activeFeature);

  if (activeFeatureIndex === -1) return null;
  if (!latLong) return null;

  const projectedAnchor =
    activeFeature.geometry.type === 'Point'
      ? (map?.project(activeFeature.geometry.coordinates as LngLatLike) as Point2D)
      : (map?.project(latLong) as Point2D);
  const activeFeatureData = featuresData[activeFeatureIndex];

  const title = tooltip.title ? (activeFeatureData?.[tooltip.title.name] as string) : undefined;

  const content = tooltip.content.map((x) => {
    const index = lastPublishedData.columns.findIndex((a) => a.id === x.id);
    const format = lastPublishedData.columns[index].format;
    return {
      id: x.id,
      title: x.label ?? x.name,
      value: format ? columnFormatter(activeFeatureData?.[x.name], format) : activeFeatureData?.[x.name],
    };
  });

  return (
    <WorkingLayerTooltipPositioner anchorPoint={projectedAnchor} showAnchor={activeFeature.geometry.type !== 'Point'}>
      <WorkingLayerTooltip
        title={title}
        heading={workingLayer.name}
        data={content}
        footer={featureNavi}
        onClose={onClose}
        emptyPlaceholder={emptyPlaceholder}
      />
    </WorkingLayerTooltipPositioner>
  );
};

export default WorkingLayerTooltipWrapper;
