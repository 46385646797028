import React from 'react';

export interface VisualBreakdown {
  color: string;
  label: string;
  widthPercentage: number;
  legendNote: string;
}

interface Props {
  items: VisualBreakdown[];
}

const UsageVisualBreakdown: React.FC<Props> = ({ items }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex h-[12px] w-full bg-gray-200">
        {items.map((item) => (
          <div key={`${item.label}`} style={{ width: `${item.widthPercentage}%`, backgroundColor: item.color }}></div>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        {items.map((item) => (
          <div key={`${item.label}_${item.legendNote}`} className="flex items-center gap-4">
            <div style={{ backgroundColor: item.color }} className="h-[12px] w-[12px]" />
            <span className="text-sm text-gray-600">{`${item.label} (${item.legendNote})`}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsageVisualBreakdown;
