import { MapsContext } from '@platform/maplibre';
import { CompareLegend, MapLegends } from '@platform/shared/integrated-ui';
import { MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSessionContext } from '../../../contexts/SessionContext';
import * as peApi from '../../../pe.api';
import SeProMaps from '../../Map/SeProMaps';
import Analysis from '../../MapSidebar/Analysis';
import { Loader } from '../../shared';
import ErrorPage from '../../shared/ErrorPage';

interface Props {
  profileId: string;
}

const PublicProfile: React.FC<Props> = ({ profileId }) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const { jwtToken } = useSessionContext();
  const profileDataQuery = useQuery(['profile', profileId], () => peApi.getProfile(profileId));
  if (profileDataQuery.isIdle || profileDataQuery.isLoading) return <Loader />;
  if (profileDataQuery.isError) return <ErrorPage />;

  const profile = profileDataQuery.data;

  return (
    <MapsContext.MapsContextProvider
      mapContextId={profile.mapContextId}
      jwtToken={jwtToken}
      doSave={false}
      apis={{
        mapContext: {
          get: peApi.getMapContext,
          update: peApi.patchMapContextItems,
        },
        baseMaps: { get: peApi.getBaseMapStyle },
        dataSourceLayers: { get: peApi.getDataSourceLayers },
      }}
    >
      <div className="flex h-full min-w-0">
        <MapsContext.MapsConsumer>
          {({ state, changeActiveMap, changeUseMapHighlight }) => {
            if (!state.registeredMaps.length) return null;
            const anyMapHasGeoSelection = state.registeredMaps.some((x) => !!x.styleSpec.geoFeatureSelection?.length);

            return (
              <div className="relative flex h-full min-w-0 flex-grow">
                {isPanelOpen && (
                  <div className="flex min-h-0 w-[460px] grow">
                    <Analysis isEditMode={false} onCloseRequest={() => setIsPanelOpen(false)} />
                  </div>
                )}
                <div className="relative flex h-full w-full">
                  <div className="absolute top-0 left-0 right-0 bottom-0 z-0 flex">
                    <SeProMaps
                      mapObjects={state.registeredMaps}
                      type={state.type}
                      inEditMode={false}
                      withLegend={false}
                    />
                  </div>
                  <div className="absolute bottom-4 left-4 z-10">
                    <div className="flex items-end gap-4">
                      {anyMapHasGeoSelection && !isPanelOpen && (
                        <button
                          className="hover:bg-primary-100 flex h-[72px] w-[72px] cursor-pointer flex-col items-center justify-center gap-1 rounded-xl bg-white shadow-lg"
                          onClick={() => setIsPanelOpen(true)}
                        >
                          <MUIcon name="monitoring" className="text-primary-600 text-[26px]" />
                          <span className="text-[11px] font-semibold text-gray-700">Analysis</span>
                        </button>
                      )}
                      <MapLegends.CollapsibleWrapper>
                        <CompareLegend mapObjects={state.registeredMaps} isInEditMode={false} />
                      </MapLegends.CollapsibleWrapper>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </MapsContext.MapsConsumer>
      </div>
    </MapsContext.MapsContextProvider>
  );
};

export default PublicProfile;
