import { ProjectTypes } from '@platform/types';
import React, { memo, useEffect, useRef, useState } from 'react';
import InfoPanelEmptyState from './InfoPanelEmptyState';
import Survey from './Survey';
import WorkingLayers from './WorkingLayers';

interface Props {
  styleSpec: ProjectTypes.MapSpec;
}

const InfoPanel: React.FC<Props> = ({ styleSpec }) => {
  const { geoFeatureSelection = [], dataLayer, workingLayers } = styleSpec;
  const [debouncedGeoFeatureSelection, setDebouncedGeoFeatureSelection] =
    useState<ProjectTypes.GeoFeatureSelection[]>(geoFeatureSelection);
  const { selection } = dataLayer;
  const changeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    clearTimeout(changeTimeoutRef.current as NodeJS.Timeout);

    changeTimeoutRef.current = setTimeout(() => {
      setDebouncedGeoFeatureSelection(geoFeatureSelection);
    }, 1000);
  }, [geoFeatureSelection]);

  return (
    <div className="flex h-full w-full flex-col">
      {geoFeatureSelection.length > 0 ? (
        <div className="flex min-h-0 grow flex-col gap-4">
          <div className="-mx-3 flex grow flex-col gap-2 pr-1">
            <Survey selection={selection} geoFeatures={debouncedGeoFeatureSelection} />
            <WorkingLayers workingLayers={workingLayers} geoFeatureSelection={debouncedGeoFeatureSelection} />
          </div>
        </div>
      ) : (
        <InfoPanelEmptyState />
      )}
    </div>
  );
};

export default memo(
  InfoPanel,
  (prev, next) =>
    prev.styleSpec.workingLayers === next.styleSpec.workingLayers &&
    prev.styleSpec.geoFeatureSelection === next.styleSpec.geoFeatureSelection &&
    prev.styleSpec.dataLayer.selection === next.styleSpec.dataLayer.selection
);
