import React from 'react';

import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useRecentProject from '../../hooks/useRecentProject';
import RecentProjectList from '../Projects/RecentProjectList';

const RecentProjects: React.FC = () => {
  const { activeWorkspace } = useWorkspaceContext();
  const { isError, isIdle, isLoading, data } = useRecentProject(activeWorkspace.id);

  if (isLoading || isIdle) return <span className="text-gray-500">Loading recent projects...</span>;
  if (isError) return <>Something went wrong</>;

  return <RecentProjectList projects={data} />;
};

export default RecentProjects;
