import { PeTypes } from '@platform/types';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import * as peApi from '../pe.api';

export const QUERY_KEY = 'dataset-log';

export default (datasetId: string, includeMetadata = false, options?: UseQueryOptions<PeTypes.DatasetLogItem[]>) => {
  const baseQueryKey = [QUERY_KEY, datasetId, includeMetadata];

  return useQuery<PeTypes.DatasetLogItem[]>(
    baseQueryKey,
    () => peApi.getDatasetUnpublishedLog(datasetId, includeMetadata),
    options
  );
};
