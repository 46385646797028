// @ts-nocheck

export const FilterComparisonType = {
  MATCH_RANGE: 0,
  MATCH_VALUE_STR: 1,
  MATCH_VALUE_NUM: 2,
  MATCH_NULL: 3,
  MATCH_DEFAULT_SYMBOL: 4,
  DO_NOT_MATCH_NULL: 5,
  MATCH_ANY_VALUE: 6,
};

function parseIntWithDefault(input, base, defValue) {
  const result = parseInt(input, base);
  return Number.isNaN(result) ? defValue : result;
}

function parseAndStripHtml(html) {
  if (html && html.length > 0) {
    // convert breaks to space
    // convert non-breaking space to space
    // convert all consecutive spaces with single space
    // trim whitespaces
    return html
      .replace(/<br \/>/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/ +/g, ' ')
      .trim();
  }

  return html;
}

function parseArrayOfElements(arrayString) {
  const elements = [];
  if (arrayString) {
    const splitted = arrayString.split(/[,;]/);
    splitted.forEach((element) => {
      if (element.length > 0) elements.push(element);
    });
  }

  return elements;
}

function parseFilter(filterNode) {
  const filter = {};

  filter.label = filterNode.getAttribute('label');
  filter.labelFrom = filterNode.getAttribute('labelFrom');
  filter.labelTo = filterNode.getAttribute('labelTo');
  filter.valueFormat = filterNode.getAttribute('valueFormat');

  let value = filterNode.getAttribute('value');
  const from = filterNode.getAttribute('from');
  const to = filterNode.getAttribute('to');
  const inclusiveTo = filterNode.getAttribute('inclusiveTo');

  if (
    (from && from.length !== 0 && !Number.isNaN(parseFloat(from))) ||
    (to && to.length !== 0 && !Number.isNaN(parseFloat(to)))
  ) {
    filter.from = from && from.length !== 0 ? parseFloat(from) : -Number.MAX_VALUE;
    filter.to = to && to.length !== 0 ? parseFloat(to) : Number.MAX_VALUE;
    filter.inclusiveTo = inclusiveTo && inclusiveTo.length !== 0 ? inclusiveTo.toLowerCase() === 'true' : 'true';
    filter.comparisonType = FilterComparisonType.MATCH_RANGE;
  } else if (!Number.isNaN(parseFloat(value))) {
    filter.valueNum = parseFloat(value);
    filter.comparisonType = FilterComparisonType.MATCH_VALUE_NUM;
  } else if (value === '[NULL]') {
    filter.comparisonType = FilterComparisonType.MATCH_NULL;
  } else if (value.length !== 0) {
    if (value.length >= 2 && value[0] === "'" && value[value.length - 1] === "'") {
      value = value.substring(1, value.length - 1);
    }
    filter.valueStr = value;
    filter.comparisonType = FilterComparisonType.MATCH_VALUE_STR;
  } else {
    filter.comparisonType = FilterComparisonType.MATCH_DEFAULT_SYMBOL;
  }
  return filter;
}

function parseCategoryFilters(categoryFiltersNode) {
  const categoryFilters = {};
  categoryFilters.name = categoryFiltersNode.getAttribute('name');
  categoryFilters.valueFormat = categoryFiltersNode.getAttribute('valueFormat');
  categoryFilters.filterSets = [];

  Array.from(categoryFiltersNode.getElementsByTagName('FilterSet')).forEach((filterSetNode) => {
    const filterSet = {};
    filterSet.valueFormat = filterSetNode.getAttribute('valueFormat') || '';
    if (filterSet.valueFormat.length === 0) filterSet.valueFormat = categoryFilters.valueFormat;
    filterSet.filters = Array.from(filterSetNode.getElementsByTagName('Filter')).map((filterNode) =>
      parseFilter(filterNode)
    );
    // add filter set only if there are any filters
    if (filterSet.filters.length > 0) categoryFilters.filterSets.push(filterSet);
  });

  return categoryFilters;
}

function parseSystemCategoryFilters(node) {
  const systemCategoryFilters = [];
  Array.from(node.getElementsByTagName('CategoryFilters')).forEach((categoryFiltersNode) => {
    const categoryFilters = parseCategoryFilters(categoryFiltersNode);
    // add category filters only if there are any filter sets in it
    if (categoryFilters.filterSets.length > 0) systemCategoryFilters.push(categoryFilters);
  });

  return systemCategoryFilters;
}

function parseDataCategories(node) {
  const dataCategories = {};
  Array.from(node.getElementsByTagName('Category')).forEach((categoryNode) => {
    const metaDataCategory = {};
    metaDataCategory.id = categoryNode.getAttribute('Id');
    metaDataCategory.name = categoryNode.getAttribute('Name');
    metaDataCategory.iconUrl = categoryNode.getAttribute('IconUrl');
    if (metaDataCategory.iconUrl && metaDataCategory.iconUrl.startsWith('http://'))
      metaDataCategory.iconUrl = metaDataCategory.iconUrl.replace('http://', 'https://');
    metaDataCategory.itemOrder = parseInt(categoryNode.getAttribute('ItemOrder'), 10) || undefined;
    if (metaDataCategory.name) {
      dataCategories[metaDataCategory.name] = metaDataCategory;
    }
  });

  return dataCategories;
}

function parseReports(node) {
  const reports = {};
  Array.from(node.getElementsByTagName('Report')).forEach((reportNode) => {
    const report = {};
    report.id = reportNode.getAttribute('Id');
    report.name = reportNode.getAttribute('Name');
    report.isSystemReport = (reportNode.getAttribute('IsSystemReport') || 'false').toLowerCase() === 'true';
    report.productTags = parseArrayOfElements(reportNode.getAttribute('ProductTags'));
    report.index = Object.keys(reports).length;
    reports[report.id] = report;
  });

  return reports;
}

function parseGeoTypes(node) {
  const geoTypes = {};
  Array.from(node.getElementsByTagName('GeoType')).forEach((geoTypeNode) => {
    const geoType = {};
    geoType.name = geoTypeNode.getAttribute('N');
    geoType.pluralName = geoTypeNode.getAttribute('PN');
    geoType.label = geoTypeNode.getAttribute('L');
    geoType.indent = parseInt(geoTypeNode.getAttribute('I'), 10) || undefined;
    geoType.summaryLevel = geoTypeNode.getAttribute('SL');
    geoType.fipsCodeFieldName = geoTypeNode.getAttribute('PFN');
    geoType.fipsCodeLength = parseInt(geoTypeNode.getAttribute('FIPSLen'), 10) || undefined;
    geoType.fipsCodeLengthPartial = parseInt(geoTypeNode.getAttribute('FIPSLenP'), 10) || undefined;
    geoType.index = Object.keys(geoTypes).length;

    geoTypes[geoType.name] = geoType;
  });

  return geoTypes;
}

function parseTable(tableNode) {
  const metaTable = {};
  metaTable.uuid = tableNode.getAttribute('GUID');
  metaTable.surveyName = tableNode.getAttribute('SurveyName');
  metaTable.datasetName = tableNode.getAttribute('DatasetName');
  metaTable.name = tableNode.getAttribute('Name');
  metaTable.displayName = tableNode.getAttribute('DisplayName');
  metaTable.title = tableNode.getAttribute('Title');
  metaTable.titleShort = tableNode.getAttribute('TitleShort');
  metaTable.visible = tableNode.getAttribute('Visible');
  metaTable.universe = tableNode.getAttribute('Universe');
  metaTable.dollarYear = parseInt(tableNode.getAttribute('DollarYear'), 10) || undefined;
  metaTable.notes = tableNode.getAttribute('Notes');
  metaTable.categories = parseArrayOfElements(tableNode.getAttribute('Categories'));
  metaTable.categoryPriorityOrder = tableNode.getAttribute('CategoryPriorityOrder');
  metaTable.showOnFirstPage = tableNode.getAttribute('ShowOnFirstPage');
  metaTable.defaultFilterSetName = tableNode.getAttribute('DefaultFilterSetName');
  metaTable.productTags = parseArrayOfElements(tableNode.getAttribute('ProductTags'));
  metaTable.percentBaseMin = parseInt(tableNode.getAttribute('BaseMin'), 10) || 20;
  metaTable.suggestedColorPaletteName = tableNode.getAttribute('PaletteName');
  metaTable.suggestedColorPaletteType = tableNode.getAttribute('PaletteType');
  metaTable.suggestedColorPaletteInverse =
    (tableNode.getAttribute('PaletteInverse') || 'false').toLowerCase() === 'true';
  metaTable.tableMapInfo = tableNode.getAttribute('TableMapInfo');
  metaTable.variables = [];

  if (tableNode.getElementsByTagName('Variable').length) {
    metaTable.variables = Array.from(tableNode.getElementsByTagName('Variable')).map((variableNode) => {
      const metaVariable = {};
      metaVariable.uuid = variableNode.getAttribute('GUID');
      metaVariable.name = variableNode.getAttribute('Name');
      metaVariable.label = variableNode.getAttribute('Label');
      if (metaVariable.label.endsWith(':')) {
        metaVariable.label = metaVariable.label.slice(0, -1);
      }
      metaVariable.qLabel = parseAndStripHtml(variableNode.getAttribute('QLabel'));
      metaVariable.dataType = variableNode.getAttribute('DataType');
      metaVariable.formatting = variableNode.getAttribute('Formatting');
      metaVariable.indent = parseInt(variableNode.getAttribute('Indent'), 10);
      metaVariable.notes = variableNode.getAttribute('Notes');
      metaVariable.suggestedPaletteName = variableNode.getAttribute('PaletteName');
      metaVariable.suggestedPaletteInverse =
        (variableNode.getAttribute('PaletteInverse') || 'false').toLowerCase() === 'true';
      metaVariable.suggestedPaletteType = variableNode.getAttribute('PaletteType');
      metaVariable.varType = variableNode.getAttribute('VarType');
      metaVariable.tooltipCustom = variableNode.getAttribute('TooltipCustom');
      metaVariable.bubbleSizeHint = parseIntWithDefault(variableNode.getAttribute('BubbleSizeHint'), 10, 1);
      metaVariable.defaultFilterSetName = variableNode.getAttribute('FilterRule');
      metaVariable.dbFieldName = variableNode.getAttribute('DbFieldName');
      metaVariable.mapInfoButtonHtmlText = variableNode.getAttribute('MapInfoButtonHtmlText');

      return metaVariable;
    });
  }

  return metaTable;
}

function parseTables(node) {
  const tables = {};
  Array.from(node.getElementsByTagName('Table')).forEach((tableNode) => {
    const table = parseTable(tableNode);
    table.index = Object.keys(tables).length;
    tables[table.name] = table;
  });

  return tables;
}

function parseDataset(datasetNode) {
  const metaDataset = {};
  metaDataset.uuid = datasetNode.getAttribute('GUID');
  metaDataset.surveyName = datasetNode.getAttribute('SurveyName');
  metaDataset.name = datasetNode.getAttribute('Name');
  metaDataset.displayName = datasetNode.getAttribute('DisplayName');
  metaDataset.abbrevation = datasetNode.getAttribute('Abbreviation');
  metaDataset.publisher = datasetNode.getAttribute('Publisher');
  metaDataset.source = datasetNode.getAttribute('Source');
  metaDataset.productTags = parseArrayOfElements(datasetNode.getAttribute('ProductTags'));
  metaDataset.partial = (datasetNode.getAttribute('Partial') || 'false').toLowerCase() === 'true';
  metaDataset.dataCategoriesNames = Array.from(datasetNode.getElementsByTagName('Category')).map((dataCategoryNode) =>
    dataCategoryNode.getAttribute('name')
  );
  metaDataset.tables = parseTables(datasetNode);

  return metaDataset;
}

function parseDatasets(node) {
  const datasets = {};
  Array.from(node.getElementsByTagName('Dataset')).forEach((datasetNode) => {
    const dataset = parseDataset(datasetNode);
    dataset.index = Object.keys(datasets).length;
    datasets[dataset.abbrevation] = dataset;
  });

  return datasets;
}

function parseSurvey(surveyNode) {
  const metaSurvey = {};
  metaSurvey.uuid = surveyNode.getAttribute('GUID');
  metaSurvey.name = surveyNode.getAttribute('Name');
  metaSurvey.displayName = surveyNode.getAttribute('DisplayName');
  metaSurvey.year = parseInt(surveyNode.getAttribute('Year'), 10) || undefined;
  metaSurvey.yearTo = parseInt(surveyNode.getAttribute('YearFrom'), 10) || undefined;
  metaSurvey.yearFrom = parseInt(surveyNode.getAttribute('YearTo'), 10) || undefined;
  metaSurvey.publisher = surveyNode.getAttribute('Publisher');
  metaSurvey.productTags = parseArrayOfElements(surveyNode.getAttribute('ProductTags'));
  metaSurvey.priority = parseInt(surveyNode.getAttribute('Priority'), 10) || 0;
  metaSurvey.datasets = parseDatasets(surveyNode);
  metaSurvey.reports = parseReports(surveyNode);
  metaSurvey.geoTypes = parseGeoTypes(surveyNode);

  return metaSurvey;
}

function parseSurveys(node) {
  const surveys = {};
  Array.from(node.getElementsByTagName('Survey')).forEach((surveyNode) => {
    const survey = parseSurvey(surveyNode);
    survey.index = Object.keys(surveys).length;
    surveys[survey.name] = survey;
  });

  return surveys;
}

function surveysToJson(metadataXmlDocument) {
  const originalSurveys = parseSurveys(metadataXmlDocument);
  return Object.values(originalSurveys);
}

function toJson(metadataXmlDocument) {
  const originalSurveys = parseSurveys(metadataXmlDocument);

  const result = {
    datasets: {},
    tables: {},
    surveys: {},
    reports: {},
    systemDataCategories: parseDataCategories(metadataXmlDocument),
    systemCategoryFilters: parseSystemCategoryFilters(metadataXmlDocument),
  };

  Object.values(originalSurveys).forEach((survey) => {
    const flattenSurvey = { ...survey };
    // flatten survey.datasets and survey.dataset.tables
    flattenSurvey.datasetIds = Object.values(survey.datasets).map((dataset) => {
      const flattenDataset = { ...dataset };
      flattenDataset.tableIds = Object.values(dataset.tables).map((table) => {
        const flattenTable = { ...table };
        flattenTable.datasetId = dataset.uuid;
        delete flattenTable.dataset;
        result.tables[table.uuid] = flattenTable;
        return table.uuid;
      });

      flattenDataset.surveyName = survey.name;

      delete flattenDataset.tables;
      result.datasets[dataset.uuid] = flattenDataset;

      return dataset.uuid;
    });

    delete flattenSurvey.datasets;

    // flatten survey.reports
    flattenSurvey.reportIds = Object.values(survey.reports).map((report) => {
      const flattenReport = { ...report };
      flattenReport.surveyId = survey.uuid;
      delete flattenReport.survey;
      result.reports[report.id] = flattenReport;
      return report.id;
    });
    delete flattenSurvey.reports;

    result.surveys[survey.name] = flattenSurvey;
  });

  return result;
}

function datasetToJson(metadataXmlDocument) {
  return parseDataset(metadataXmlDocument);
}

function tablesToJson(metadataXmlDocument) {
  const { tables, variables } = toJson(metadataXmlDocument);

  return {
    tables,
    variables,
  };
}

function tableToJson(metadataXmlDocument) {
  return parseTable(metadataXmlDocument);
}

function metadataToJson(metadataXmlDocument) {
  const surveys = surveysToJson(metadataXmlDocument.getElementsByTagName('Surveys')[0]);
  const systemCategoryFilters = parseSystemCategoryFilters(
    metadataXmlDocument.getElementsByTagName('SystemCategoryFilters')[0]
  );
  return {
    surveys,
    systemCategoryFilters,
  };
}

export { toJson, datasetToJson, tablesToJson, tableToJson, surveysToJson, metadataToJson };
