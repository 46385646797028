import { usePortal } from '@platform/shared/hooks';
import { Buttons, Modal } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { memo, useState } from 'react';
import { IMAGE_FORMAT } from '../../enums/imageDimension';
import ProfileMapExport from './ProfileMapExport';
import StoryMapExport from './StoryMapExport';

interface Props {
  assetId: string; // ID of map or story
  exporting: 'profile' | 'story';
  onCloseRequest: () => void;
}

const ExportModal: React.FC<Props> = ({ assetId, exporting, onCloseRequest }) => {
  const Portal = usePortal();
  const [imageDimension, setImageDimension] = useState<PeTypes.ImageDim>(IMAGE_FORMAT[0]);
  const [includeLegend, setIncludeLegend] = useState<boolean>(true);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const handleStartExport = () => {
    setIsExporting(true);
  };

  let exporterComponent;

  if (isExporting) {
    if (exporting === 'profile') {
      exporterComponent = (
        <ProfileMapExport
          profileId={assetId}
          onCloseRequest={() => {
            setIsExporting(false);
            onCloseRequest();
          }}
          imageDimension={imageDimension}
          includeLegend={includeLegend}
        />
      );
    } else if (exporting === 'story') {
      exporterComponent = (
        <StoryMapExport
          storyId={assetId}
          onCloseRequest={() => {
            setIsExporting(false);
            onCloseRequest();
          }}
          imageDimension={imageDimension}
          includeLegend={includeLegend}
        />
      );
    }
  }
  return (
    <>
      <Modal
        isVisible={!isExporting}
        title="Export as image"
        okLabel="Export"
        className="w-[448px]"
        onDone={handleStartExport}
        onCloseRequest={onCloseRequest}
      >
        <div className="mb-4 text-sm font-semibold">Image format</div>
        <div className="flex gap-2 border-b pb-6">
          {IMAGE_FORMAT.map((dim, idx) => {
            return (
              <Buttons.Primary
                className={`${
                  dim.label === imageDimension.label
                    ? 'bg-primary-600'
                    : 'border border-gray-200 bg-white text-gray-700 hover:bg-gray-50'
                }`}
                key={dim.label}
                onClick={() => setImageDimension(dim)}
              >
                {dim.label}
              </Buttons.Primary>
            );
          })}
        </div>
        <div className="flex items-start py-6">
          <div className="flex h-6 items-center">
            <input
              id="includeLegend"
              name="includeLegend"
              type="checkbox"
              className="text-primary-600 focus:ring-primary-600 h-4 w-4 rounded border-gray-300"
              onChange={(e) => setIncludeLegend(e.target.checked)}
              checked={includeLegend}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="includeLegend" className="font-medium text-gray-900">
              Include legend in image export
            </label>
          </div>
        </div>
      </Modal>
      {exporterComponent && <Portal key={assetId}>{exporterComponent}</Portal>}
    </>
  );
};

export default memo(ExportModal, (prev, next) => prev.assetId === next.assetId && prev.exporting === next.exporting);
