import { PeTypes } from '@platform/types';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useMemo, useRef, useState } from 'react';
import { useSessionContext } from '../../contexts/SessionContext';
import { useStory } from '../../hooks';
import { Loader } from '../shared';
import ExportLoader from './ExportLoader';
import MapExport from './MapExport';

interface Props {
  storyId: string;
  includeLegend: boolean;
  imageDimension: PeTypes.ImageDim;
  onCloseRequest: () => void;
}

const StoryMapExport: React.FC<Props> = ({ storyId, onCloseRequest, includeLegend, imageDimension }) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);

  const { jwtToken } = useSessionContext();

  const completedExports = useRef<
    {
      map: string | undefined;
      legend: string | undefined;
    }[]
  >([]);

  const { storyQuery, slidesQuery } = useStory(storyId);

  const handleItemExportDone = useMemo(
    () => async (mapExport: string | undefined, legendExport: string | undefined) => {
      completedExports.current.push({ map: mapExport, legend: legendExport });

      const allDone = completedExports.current.length === slides.length;
      if (!allDone) {
        setCurrentSlideIdx((p) => p + 1);
        return;
      }

      const zip = new JSZip();
      completedExports.current.forEach((exp, index) => {
        const slideFolder = zip.folder(`slide ${index + 1}`);

        if (exp.map) {
          slideFolder?.file(`map.png`, exp.map.slice('data:image/png;base64,'.length), { base64: true });
        }
        if (exp.legend) {
          slideFolder?.file(`legend.png`, exp.legend.slice('data:image/png;base64,'.length), { base64: true });
        }
      });
      const result = await zip.generateAsync({ type: 'blob' });
      saveAs(result, `${title}.zip`);

      onCloseRequest();
    },
    []
  );

  const isLoading = storyQuery.isIdle || storyQuery.isLoading || slidesQuery.isIdle || slidesQuery.isLoading;
  const isError = storyQuery.isError || slidesQuery.isError;
  if (isLoading) return <Loader />;
  if (isError) return <>Something is wrong...</>;

  const { title } = storyQuery.data;
  const slides = slidesQuery.data;
  const slide = slides[currentSlideIdx];

  return (
    <>
      <ExportLoader>
        <div>
          Exporting slide <strong>{currentSlideIdx + 1}</strong> of <strong>{slides.length}</strong>. This might take a
          while.
        </div>
      </ExportLoader>
      <div className="fixed top-[9999px] right-[9999px] z-0 flex h-[0px] w-[0px] flex-col bg-white">
        <MapExport
          key={slide.id}
          title={title}
          mapContextId={slide.mapContextId}
          onDone={handleItemExportDone}
          includeLegend={includeLegend}
          imageDimension={imageDimension}
          jwtToken={jwtToken}
        />
      </div>
    </>
  );
};

export default StoryMapExport;
