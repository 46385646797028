import { MUIcon } from '@platform/shared/ui';
import { FileParser } from '@platform/utils';
import React from 'react';
import { getMaxFileSizeInMB } from '../utils';
import BaseError from './BaseError';

interface Props {
  error: FileParser.Types.ParserError;
  onDismiss: () => void;
}

const ParserError: React.FC<Props> = ({ error, onDismiss }) => {
  let errorMessage;
  switch (error) {
    case FileParser.Types.ParserError.INPUT_TOO_LARGE:
      errorMessage = `The file(s) you are trying to upload is larger than ${getMaxFileSizeInMB()} (unzipped). Please try uploading a smaller file.`;
      break;
    case FileParser.Types.ParserError.INPUT_MALFORMED:
      errorMessage = `The file is malformed. Please upload a valid file.`;
      break;
    case FileParser.Types.ParserError.INPUT_TYPE_NOT_SUPPORTED:
      errorMessage = `The file type is not supported. Please upload a supported file format.`;
      break;
    case FileParser.Types.ParserError.PARSING_FAILED:
      errorMessage = `We encountered an issue while reading the file. Please ensure it is in a supported format and try again.`;
      break;
    case FileParser.Types.ParserError.UNZIPPING_FAILED:
      errorMessage = `We encountered an issue while trying to unzip the file. Please check the file and try again.`;
      break;
    case FileParser.Types.ParserError.AMBIGUOUS_CONTENT:
      errorMessage = `There are multiple supported files within the ZIP archive. Please ensure only one type of file is included.`;
      break;
    case FileParser.Types.ParserError.UNSUPPORTED_CONTENT:
      errorMessage = `The file contains unsupported content, such as images or videos. Please upload a file containing supported content only.`;
      break;
    case FileParser.Types.ParserError.UNKNOWN:
    default:
      errorMessage = `An unknown error occurred. Please try again later.`;
  }

  return (
    <BaseError>
      <div className="flex w-full items-center justify-between gap-2">
        <div>
          <span className="font-semibold">Failed to upload.</span>
          {errorMessage}
        </div>
        <div className="flex h-full items-center justify-center">
          <button onClick={onDismiss}>
            <MUIcon name="close" />
          </button>
        </div>
      </div>
    </BaseError>
  );
};

export default ParserError;
