import { Select } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import DatasetInfo from '../../Datasets/Dataset/DatasetInfo';
import { UpdatedAgo } from '../../shared';

interface Props {
  dataset: PeTypes.DatasetListItem;
  active: boolean;
  onClick: (item: PeTypes.DatasetListItem) => void;
}

const SelectableDataset: React.FC<Props> = ({ dataset, onClick, active }) => {
  const handleClick = () => {
    onClick(dataset);
  };

  return (
    <div
      className={classNames(
        'flex h-[76px] max-h-[76px] w-full flex-shrink-0 cursor-pointer items-center gap-6 border-b-2 border-neutral-100 bg-white px-6 last:border-none',
        {
          'bg-gray-100': active,
        }
      )}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <Select selected={active} onSelect={handleClick} />
      <DatasetInfo dataset={dataset} />
      {dataset.unPublishedChangesCount > 0 && dataset.hasEverBeenPublished && (
        <div className="shrink-0 rounded-xl bg-gray-100 p-1 px-3 text-xs text-gray-700">Unpublished changes</div>
      )}
      {!dataset.hasEverBeenPublished && (
        <div className="shrink-0 rounded-xl bg-gray-100 p-1 px-3 text-xs text-gray-700">Draft</div>
      )}
      <div className="min-w-[150px] text-end text-sm text-gray-500">
        {dataset.editedAt && <UpdatedAgo prefixLabel="" dateTime={dataset.editedAt} />}
      </div>
    </div>
  );
};

export default SelectableDataset;
