import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as peApi from '../../pe.api';
import InviteUser from './InviteUser';
import WorkspaceMember from './WorkspaceMember';
import WorkspacePendingUser from './WorkspacePendingUser';

interface Props {
  activeWorkspace: PeTypes.Workspace;
  isAdmin: boolean;
}

const WorkspaceUsers: React.FC<Props> = ({ activeWorkspace, isAdmin }) => {
  const workspaceMembersQuery = useQuery(['workspace-members', activeWorkspace.id], () => peApi.getWorkspaceMembers());
  const pendingWorkspaceMembersQuery = useQuery(['workspace-pending-invites', activeWorkspace.id], () => {
    if (isAdmin) return peApi.getPendingWorkspaceMembers();
  });

  const [showSearchUser, setShowSearchUser] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const inviteUserMutation = useMutation({
    mutationFn: async (payload: PeTypes.InviteMemberPayload) => peApi.inviteUser(payload),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['workspace-members', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['workspace-pending-invites', activeWorkspace.id] });
      setShowSearchUser(false);
    },
  });

  if (
    workspaceMembersQuery.isLoading ||
    workspaceMembersQuery.isIdle ||
    pendingWorkspaceMembersQuery.isLoading ||
    pendingWorkspaceMembersQuery.isIdle
  )
    return null;
  if (workspaceMembersQuery.isError || pendingWorkspaceMembersQuery.isError) return null;

  const users = workspaceMembersQuery.data;
  const pendingInvitations = pendingWorkspaceMembersQuery.data;

  const admins = users.filter((user) => user.isAdmin).map((user) => user);
  const editors = users.filter((user) => !user.isAdmin).map((user) => user);

  const handleUserSelection = (payload: PeTypes.InviteMemberPayload) => {
    inviteUserMutation.mutate(payload);
  };

  const adminsDiv = (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold text-gray-600">Admins</span>
      <div>
        {admins?.map((member) => (
          <WorkspaceMember
            activeWorkspace={activeWorkspace}
            key={member.id}
            member={member}
            isAdmin={isAdmin}
          ></WorkspaceMember>
        ))}
      </div>
    </div>
  );

  const editorsDiv = (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold text-gray-600">Editors</span>
      <div>
        {editors?.map((member) => (
          <WorkspaceMember
            activeWorkspace={activeWorkspace}
            key={member.id}
            member={member}
            isAdmin={isAdmin}
          ></WorkspaceMember>
        ))}
      </div>
    </div>
  );

  const pendingInvitationsDiv = pendingInvitations ? (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold text-gray-600">Pending invites</span>
      <div>
        {pendingInvitations?.map((invitation) => (
          <WorkspacePendingUser
            activeWorkspace={activeWorkspace}
            key={invitation.id}
            invitation={invitation}
          ></WorkspacePendingUser>
        ))}
      </div>
    </div>
  ) : (
    <div></div>
  );

  const alreadyMemberIds = [...admins.map((user) => user.id), ...editors.map((user) => user.id)];
  const pendingInvitationsEmails = pendingInvitations
    ? pendingInvitations.map((invitation) => invitation.invitedEmail)
    : [];

  return (
    <div className="flex flex-col gap-10">
      <div className="text-2xl font-semibold text-gray-900">Users</div>
      <div className="relative h-[1px] w-full bg-gray-200">
        {isAdmin && (
          <button
            onClick={() => setShowSearchUser(true)}
            className="absolute -top-6 right-24 bottom-0 flex h-[48px] w-[48px] items-center justify-center rounded-[999px] border border-gray-300 bg-white shadow-sm hover:cursor-pointer hover:shadow-md"
          >
            <MUIcon name="add" className="text-primary-600" />
          </button>
        )}
      </div>
      {showSearchUser && (
        <InviteUser
          onClose={() => setShowSearchUser(false)}
          activeWorkspace={activeWorkspace}
          onSelect={handleUserSelection}
          alreadyMemberIds={alreadyMemberIds}
          pendingInvitationsEmails={pendingInvitationsEmails}
        />
      )}
      <div className="flex flex-col gap-8">
        {!!admins.length && adminsDiv}
        {!!editors.length && editorsDiv}
        {pendingInvitations && !!pendingInvitations.length && pendingInvitationsDiv}
      </div>
    </div>
  );
};

export default WorkspaceUsers;
