import dayjs from 'dayjs';
import React, { useCallback, useContext, useMemo } from 'react';

import { CALENDAR_SIZE, DATE_FORMAT } from '../../constants';
import {
  formatDate,
  getDaysInMonth,
  getFirstDayInMonth,
  getFirstDaysInMonth,
  getLastDaysInMonth,
  getNumberOfDay,
  //loadLanguageModule,
  nextMonth,
  previousMonth,
} from '../../helpers';

import Days from './Days';
import Week from './Week';

import { Buttons } from '../../..';
import MUIcon from '../../../MUIcon';
import DatepickerContext from '../../contexts/DatepickerContext';
import { DateType } from './../../types';

interface Props {
  date: dayjs.Dayjs;
  minDate?: DateType | null;
  maxDate?: DateType | null;
  onClickPrevious: () => void;
  onClickNext: () => void;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  calendarPosition?: 'left' | 'right'; // used for DateRangePicker
}

const Calendar: React.FC<Props> = ({
  date,
  minDate,
  maxDate,
  onClickPrevious,
  onClickNext,
  changeMonth,
  changeYear,
  calendarPosition,
}) => {
  // Contexts
  const {
    period,
    changePeriod,
    changeDayHover,
    showFooter,
    changeDatepickerValue,
    asSingle,
    i18n,
    startWeekOn,
    useRange,
  } = useContext(DatepickerContext);
  //loadLanguageModule(i18n);

  // Functions
  const previous = useCallback(() => {
    return getLastDaysInMonth(previousMonth(date), getNumberOfDay(getFirstDayInMonth(date).ddd, startWeekOn));
  }, [date, startWeekOn]);

  const current = useCallback(() => {
    return getDaysInMonth(formatDate(date));
  }, [date]);

  const next = useCallback(() => {
    return getFirstDaysInMonth(previousMonth(date), CALENDAR_SIZE - (previous().length + current().length));
  }, [current, date, previous]);

  const clickDay = useCallback(
    (day: number, month = date.month() + 1, year = date.year()) => {
      const fullDay = `${year}-${month}-${day}`;
      let newStart;
      let newEnd = null;

      function chosePeriod(start: string, end: string) {
        changeDatepickerValue({
          startDate: dayjs(start).format(DATE_FORMAT),
          endDate: dayjs(end).format(DATE_FORMAT),
        });
      }

      if (period.start && period.end) {
        if (changeDayHover) {
          changeDayHover(null);
        }
        changePeriod({
          start: null,
          end: null,
        });
      }

      if ((!period.start && !period.end) || (period.start && period.end)) {
        if (!period.start && !period.end) {
          changeDayHover(fullDay);
        }
        newStart = fullDay;
        if (asSingle) {
          newEnd = fullDay;
          chosePeriod(fullDay, fullDay);
        }
      } else {
        if (period.start && !period.end) {
          // start not null
          // end null
          const condition = dayjs(fullDay).isSame(dayjs(period.start)) || dayjs(fullDay).isAfter(dayjs(period.start));
          newStart = condition ? period.start : fullDay;
          newEnd = condition ? fullDay : period.start;
        } else {
          // Start null
          // End not null
          const condition = dayjs(fullDay).isSame(dayjs(period.end)) || dayjs(fullDay).isBefore(dayjs(period.end));
          newStart = condition ? fullDay : period.start;
          newEnd = condition ? period.end : fullDay;
        }

        if (!showFooter) {
          if (newStart && newEnd) {
            chosePeriod(newStart, newEnd);
          }
        }
      }

      if (!(newEnd && newStart) || showFooter) {
        changePeriod({
          start: newStart,
          end: newEnd,
        });
      }
    },
    [asSingle, changeDatepickerValue, changeDayHover, changePeriod, date, period.end, period.start, showFooter]
  );

  const clickPreviousDays = useCallback(
    (day: number) => {
      const newDate = previousMonth(date);
      clickDay(day, newDate.month() + 1, newDate.year());
      onClickPrevious();
    },
    [clickDay, date, onClickPrevious]
  );

  const clickNextDays = useCallback(
    (day: number) => {
      const newDate = nextMonth(date);
      clickDay(day, newDate.month() + 1, newDate.year());
      onClickNext();
    },
    [clickDay, date, onClickNext]
  );

  // UseEffects & UseLayoutEffect

  // Variables
  const calendarData = useMemo(() => {
    return {
      date: date,
      days: {
        previous: previous(),
        current: current(),
        next: next(),
      },
    };
  }, [current, date, next, previous]);

  return (
    <div className="w-full md:w-[336px] md:min-w-[336px]">
      <div className="flex items-center space-x-1.5 rounded-md px-3 py-1.5">
        {useRange && calendarPosition === 'left' ? (
          <div className="flex-none">
            <Buttons.Soft className="rounded-full bg-transparent shadow-none" onClick={onClickPrevious}>
              <MUIcon name="chevron_left" className="text-[28px]" />
            </Buttons.Soft>
          </div>
        ) : !useRange ? (
          <div className="flex-none">
            <Buttons.Soft className="rounded-full bg-transparent shadow-none" onClick={onClickPrevious}>
              <MUIcon name="chevron_left" className="text-[28px]" />
            </Buttons.Soft>
          </div>
        ) : null}

        <div className="flex flex-1 items-center justify-center space-x-1.5">
          <div className="text-center font-['Inter'] text-base font-semibold leading-normal text-gray-800">
            {calendarData.date.locale(i18n).format('MMM')}
          </div>

          <div className=" text-center font-['Inter'] text-base font-semibold leading-normal text-gray-800">
            {calendarData.date.year()}
          </div>
        </div>

        {useRange && (calendarPosition === 'right' || !calendarPosition) ? (
          <div className="flex-none">
            <Buttons.Soft
              disabled={calendarData.date.isSame(dayjs(), 'month')}
              className="rounded-full bg-transparent shadow-none"
              onClick={onClickNext}
            >
              <MUIcon name="chevron_right" className="text-[28px]" />
            </Buttons.Soft>
          </div>
        ) : !useRange ? (
          <div className="flex-none">
            <Buttons.Soft className="rounded-full bg-transparent shadow-none" onClick={onClickNext}>
              <MUIcon name="chevron_right" className="text-[28px]" />
            </Buttons.Soft>
          </div>
        ) : null}
      </div>

      <div className="mt-0.5 min-h-[285px] px-0.5 sm:px-2">
        <Week />

        <Days
          calendarData={calendarData}
          onClickPreviousDays={clickPreviousDays}
          onClickDay={clickDay}
          onClickNextDays={clickNextDays}
        />
      </div>
    </div>
  );
};

export default Calendar;
