import { Modal, MUIcon } from '@platform/shared/ui';
import React from 'react';

interface Props {
  onModalClose: () => void;
}
const LearnMoreModal: React.FC<Props> = ({ onModalClose }) => {
  const CSV = (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-semibold">CSV</p>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Supported separators: comma, tab, semicolon, vertical line, space</p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">
          Must conform to the rfc 4180, i.e.{' '}
          <a
            href="https://tools.ietf.org/html/rfc4180"
            target="_blank"
            className="underline underline-offset-2 hover:text-gray-800"
          >
            csv specification{' '}
          </a>
        </p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must not be empty</p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must have at least two rows (header and data row)</p>
      </div>
    </div>
  );

  const SHP = (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-semibold">SHP</p>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must be in a zip package</p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must have a dbf (shx is optional and will be generated if possible)</p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must have a prj file</p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">
          In case of different encoding (i.e. something other than windows-1252) provide an optional cpg file with
          encoding inside
        </p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must not contain multiple geometry types</p>
      </div>
    </div>
  );

  const KML = (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-semibold">KML</p>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">
          Must follow the{' '}
          <a
            target={'_blank'}
            className="underline underline-offset-2 hover:text-gray-800"
            href="https://developers.google.com/kml/documentation/kmlreference"
          >
            specification
          </a>
        </p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must not contain multiple geometry types</p>
      </div>
    </div>
  );

  const GEOJSON = (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-semibold">GEOJSON</p>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">
          Must follow{' '}
          <a
            target={'_blank'}
            className="underline underline-offset-2 hover:text-gray-800"
            href={'https://geojson.org/geojson-spec.html'}
          >
            specification
          </a>
        </p>
      </div>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">Must not contain multiple geometry types</p>
      </div>
    </div>
  );
  const DBF = (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-semibold">DBF</p>
      <div className={'flex items-center justify-start gap-3'}>
        <MUIcon name="check_small" className="text-primary-600" />
        <p className="text-sm text-gray-600">
          Single dbf is also allowed without the shp file as long as it's a{' '}
          <a
            target={'_blank'}
            className="underline underline-offset-2 hover:text-gray-800"
            href={'https://www.dbase.com/KnowledgeBase/int/db7_file_fmt.htm'}
          >
            {' '}
            valid dbf file
          </a>
        </p>
      </div>
    </div>
  );
  return (
    <Modal
      title={'Supported formats and file constrains'}
      onDone={onModalClose}
      cancelClassNames={'hidden'}
      onCloseRequest={onModalClose}
      className={'w-[448px] max-w-[448px]'}
      okClassNames="w-[72px]"
    >
      <div className="flex max-h-[455px] flex-col gap-8 overflow-y-auto py-2 pr-4">
        <p className="text-sm text-gray-600">
          Supported formats for import include CSV, JSON, GeoJSON, KML, and ShapeFiles (in a zip package).
        </p>
        {CSV}
        {SHP}
        {KML}
        {GEOJSON}
        {DBF}
      </div>
    </Modal>
  );
};
export default LearnMoreModal;
