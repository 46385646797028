import { LoginForm } from '@platform/shared/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSessionContext } from '../../../contexts/SessionContext';
import * as peApi from '../../../pe.api';
import { LogoText } from '../../shared';

const Login: React.FC = () => {
  const { setJwtToken } = useSessionContext();
  return (
    <div className="flex h-full w-full flex-col items-center gap-8 bg-gray-50 pt-[60px]">
      <LogoText width={148} height={38} />
      <div className="items-left flex h-[460px] w-[460px] flex-col justify-center gap-10 rounded-xl border border-gray-200 bg-white shadow-md">
        <div className="px-10 text-2xl font-bold">Log In</div>
        <div className="flex w-full flex-col gap-8">
          <LoginForm onLoginSuccess={setJwtToken} loginMethod={peApi.login} />
          <div className="px-10">
            <Link className="text-primary-600 text-sm font-semibold" to="/reset-password">
              Forgot password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
