import { DatasetTypes } from '@platform/types';
import React from 'react';
import { MUIcon } from './..';

export const getSuffixIcon = (type: number | string) => {
  if (
    type === DatasetTypes.DataType.DOUBLE ||
    type === 'DOUBLE' ||
    type === DatasetTypes.DataType.INTEGER ||
    type === 'INTEGER'
  ) {
    return <Decimal className="h-[24px] w-[24px]" />;
  }

  let icon = 'match_case';
  switch (type) {
    case DatasetTypes.DataType.BOOLEAN:
    case 'BOOLEAN':
      icon = 'checklist_rtl';
      break;
    default:
      break;
  }
  return <MUIcon className="flex !font-[24-px] text-gray-400" name={icon} />;
};

const Decimal: React.FC<{ className: string }> = ({ className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_9360_106142" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#9CA3AF" />
      </mask>
      <g mask="url(#mask0_9360_106142)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.0577 16.75C8.55257 16.75 8.125 16.575 7.775 16.225C7.425 15.875 7.25 15.4474 7.25 14.9423V9.05768C7.25 8.55254 7.425 8.12498 7.775 7.775C8.125 7.425 8.55257 7.25 9.0577 7.25H10.9423C11.4474 7.25 11.875 7.425 12.225 7.775C12.575 8.12498 12.75 8.55254 12.75 9.05768V14.9423C12.75 15.4474 12.575 15.875 12.225 16.225C11.875 16.575 11.4474 16.75 10.9423 16.75H9.0577ZM10.9423 15.25H9.0577C8.96795 15.25 8.89423 15.2212 8.83652 15.1635C8.77882 15.1058 8.74998 15.032 8.74998 14.9423V9.05768C8.74998 8.96793 8.77882 8.8942 8.83652 8.8365C8.89423 8.7788 8.96795 8.74995 9.0577 8.74995H10.9423C11.032 8.74995 11.1058 8.7788 11.1635 8.8365C11.2212 8.8942 11.25 8.96793 11.25 9.05768V14.9423C11.25 15.032 11.2212 15.1058 11.1635 15.1635C11.1058 15.2212 11.032 15.25 10.9423 15.25ZM15.8077 16.75C15.3026 16.75 14.875 16.575 14.525 16.225C14.175 15.875 14 15.4474 14 14.9423V9.05768C14 8.55254 14.175 8.12498 14.525 7.775C14.875 7.425 15.3026 7.25 15.8077 7.25H17.6923C18.1974 7.25 18.625 7.425 18.975 7.775C19.325 8.12498 19.5 8.55254 19.5 9.05768V14.9423C19.5 15.4474 19.325 15.875 18.975 16.225C18.625 16.575 18.1974 16.75 17.6923 16.75H15.8077ZM17.6923 15.25H15.8077C15.7179 15.25 15.6442 15.2212 15.5865 15.1635C15.5288 15.1058 15.5 15.032 15.5 14.9423V9.05768C15.5 8.96793 15.5288 8.8942 15.5865 8.8365C15.6442 8.7788 15.7179 8.74995 15.8077 8.74995H17.6923C17.7821 8.74995 17.8558 8.7788 17.9135 8.8365C17.9712 8.8942 18 8.96793 18 9.05768V14.9423C18 15.032 17.9712 15.1058 17.9135 15.1635C17.8558 15.2212 17.7821 15.25 17.6923 15.25ZM5.24998 16.7499C5.03716 16.7499 4.85896 16.6782 4.71537 16.5346C4.57179 16.391 4.5 16.2128 4.5 16C4.5 15.7872 4.57179 15.609 4.71537 15.4654C4.85896 15.3218 5.03716 15.25 5.24998 15.25C5.46279 15.25 5.64099 15.3218 5.78458 15.4654C5.92816 15.609 5.99995 15.7872 5.99995 16C5.99995 16.2128 5.92816 16.391 5.78458 16.5346C5.64099 16.6782 5.46279 16.7499 5.24998 16.7499Z"
          fill="#9CA3AF"
        />
      </g>
    </svg>
  </div>
);
