import { MapsContext, MapsContextMap, Utils } from '@platform/maplibre';
import { MetadataTypes, PeTypes, ProjectTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import { LngLatBounds } from 'maplibre-gl';
import React from 'react';
import { RuleGroupType, transformQuery } from 'react-querybuilder';
import { useSessionContext } from '../../contexts/SessionContext';
import { useDatasetColumns } from '../../hooks';
import * as peApi from '../../pe.api';

const { getWorkingLayerFingerprint } = Utils;

interface Props {
  dataset: PeTypes.Dataset;
  filter: RuleGroupType;
}

const DatasetTiles: React.FC<Props> = ({ dataset, filter }) => {
  const { jwtToken } = useSessionContext();
  const datasetColumnQuery = useDatasetColumns({ datasetId: dataset.id, itemsPerPage: dataset.numberOfColumns });

  if (datasetColumnQuery.isLoading || datasetColumnQuery.isIdle) {
    return <>Loading...</>;
  }

  if (datasetColumnQuery.isError) {
    return <>Something went wrong</>;
  }

  const { projections = [] } = dataset.tiles;
  const [projection] = projections;
  const { xmin, xmax, ymin, ymax } = projection.extent;

  const bounds = new LngLatBounds(
    [xmax, ymin], // southwest coordinates
    [xmin, ymax] // northeast coordinates
  );

  const workingLayer = {
    id: dataset.id,
    name: dataset.name,
    datasetId: dataset.id,
    filter: transformQuery(
      {
        ...filter,
        rules: filter.rules.filter((rule) =>
          Helpers.checkIfValidRule(
            rule,
            datasetColumnQuery.data.map((col) => col.uuid)
          )
        ),
      },
      {
        ruleProcessor: (r) => ({
          ...r,
          field: datasetColumnQuery.data.find((col) => col.uuid === r.field)?.title, //column uuid must be turned into column title in order to get tiles
        }),
      }
    ),
    geoLayerId: dataset.tiles.geoLayerId?.toString() ?? '',
    visible: true,
    type: dataset.type,
    baseColor: '#4F46E5',
    opacities: [0.82, 0.82],
  };

  const fingerPrint = getWorkingLayerFingerprint(workingLayer);

  return (
    <div className="m-2 flex h-full flex-col overflow-hidden rounded-md border border-gray-200 bg-white">
      <MapsContext.MapsContextProvider
        mapContextId={fingerPrint}
        jwtToken={jwtToken}
        doSave={false}
        apis={{
          baseMaps: { get: peApi.getBaseMapStyle },
          mapContext: {
            get: () =>
              Promise.resolve({
                id: '1',
                type: 'single',
                mapObjects: [
                  {
                    id: '1',
                    styleSpec: {
                      baseMapId: 'streets-v2',
                      dataLayer: {} as ProjectTypes.DataLayerSpec,
                      workingLayers: [workingLayer],
                      center: [-97.31211029941215, 44.16617302763339],
                      zoom: 2,
                    },
                    order: 1,
                  },
                ],
              }),
            update: () => new Promise((s) => ({})),
          },
          dataSourceLayers: {
            get: () => new Promise<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]>((s) => ({})),
          },
        }}
      >
        <MapsContext.MapsConsumer>
          {({ state }) => {
            if (!state.registeredMaps.length) return null;
            const [mapObj] = state.registeredMaps;
            return (
              <MapsContextMap
                mapObj={mapObj}
                onMount={(mapRef) => mapRef.fitBounds(bounds, { padding: 100, animate: true })}
              />
            );
          }}
        </MapsContext.MapsConsumer>
      </MapsContext.MapsContextProvider>
    </div>
  );
};

export default DatasetTiles;
