import { LngLatLike, Map, MapGeoJSONFeature } from 'maplibre-gl';
import { useMemo, useRef } from 'react';
import useFeaturesUnderMouse from './useFeaturesUnderMouse';

function useFeatureHighlightSingleMap(
  map: Map | null | undefined,
  sourceLayerIds: string[] = [],
  featureState = 'hover'
) {
  const features = useRef<MapGeoJSONFeature[]>([]);
  const handleFeaturesUnderMouse = useMemo(
    () => (featuresInEvent: MapGeoJSONFeature[], lngLat: LngLatLike | null) => {
      try {
        if (!map || !lngLat) return;

        features.current.forEach((f) => {
          // https://github.com/mapbox/mapbox-gl-js/issues/9461
          const existingState = map.getFeatureState(f);
          // eslint-disable-next-line no-prototype-builtins
          if (existingState?.hasOwnProperty(featureState)) {
            map.removeFeatureState(f, featureState);
          }
        });
        features.current = [];

        features.current = [...featuresInEvent];

        features.current.forEach((f) => {
          map.setFeatureState(f, {
            ...f.state,
            [featureState]: true,
          });
        });
      } catch (e) {
        console.warn('useFeatureHighlightSingleMap', e);
      }
    },
    [map, sourceLayerIds]
  );

  useFeaturesUnderMouse(map, 'mousemove', handleFeaturesUnderMouse, sourceLayerIds, 10);
}

export default useFeatureHighlightSingleMap;
