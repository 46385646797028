import { ProjectTypes } from '@platform/types';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  cutPoints: ProjectTypes.CutPoint[];
  title?: React.ReactNode | string;
  insufficientData?: { label: string; color: string };
}

const BubbleLegend: React.FC<Props> = ({ title, cutPoints }) => {
  return (
    <Outer>
      <Title>{title}</Title>
      <Bubble style={{ backgroundColor: cutPoints[cutPoints.length - 1].color }} />
    </Outer>
  );
};

export default BubbleLegend;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 250px;
`;

const Title = styled.div`
  font-size: 16px;
`;

const Bubble = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 9999px;
`;
