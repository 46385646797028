import { AbacusTypes } from '@platform/types';
import { useQuery } from 'react-query';

interface Props {
  datasetId: string;
  columnNames: string[];
  api: (datasetId: string, payload: AbacusTypes.DataRequest) => Promise<AbacusTypes.Data>;
  whereClause?: string;
  limit?: number;
  offset?: number;
}

const useAbacusData = ({ api, datasetId, columnNames, whereClause, limit, offset }: Props) =>
  useQuery(
    ['abacus-data', datasetId, columnNames, whereClause, limit, offset],
    () => api(datasetId, { columnNames, whereClause, limit, offset }),
    {
      enabled: columnNames.length > 0,
    }
  );

export default useAbacusData;
