import html2canvas from 'html2canvas';

export const getImageElement = async (htmlElementId: string) => {
  try {
    const htmlElement = document.getElementById(htmlElementId);

    if (!htmlElement) {
      console.warn(`Can't find element with id: ${htmlElement}. Exiting...`);
      return null;
    }
    const canvas = await html2canvas(htmlElement);
    const imgElement = new Image();
    imgElement.src = canvas.toDataURL();
    return imgElement;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const htmlImageElementToPng = (htmlImage: HTMLImageElement) => {
  const newCanvas = document.createElement('canvas');

  newCanvas.width = htmlImage.width;
  newCanvas.height = htmlImage.height;

  const ctx = newCanvas.getContext('2d');
  if (!ctx) return;

  ctx.drawImage(htmlImage, 0, 0);
  return newCanvas.toDataURL('image/png');
};
