import { Buttons, Inputs, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { generateLink } from '../../../LinkGenerator';
import * as peApi from '../../../pe.api';

interface Props {
  accessCode: string;
}

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};

const NewPasswordForm: React.FC<Props> = ({ accessCode }) => {
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const changePasswordMutation = useMutation({
    mutationFn: (payload: PeTypes.NewPasswordPayload) => peApi.changePassword(payload),
    onSuccess: () => {
      navigate(generateLink('login'));
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });
  const watchedFormData = watch();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (accessCode) {
      changePasswordMutation.mutate({ accessCode: accessCode, password: data.newPassword });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8" data-cy="update-account-form">
      <div>
        <label
          htmlFor="firstName"
          className={classNames('mb-2 block text-xs font-semibold text-gray-800', { 'text-red-700': false })}
        >
          New password
        </label>
        <Controller
          control={control}
          name="newPassword"
          rules={{ required: 'New password is required' }}
          render={({ field }) => (
            <Inputs.Input
              type={newPasswordVisible ? 'text' : 'password'}
              id="new-password"
              placeholder="Enter new password"
              classes={classNames('rounded-lg h-[42px]', { 'ring-red-700': false })}
              data-cy="new-password-input"
              value={field.value}
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  disabled={!watchedFormData.newPassword.length}
                  data-rh={newPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={newPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.newPassword.length === 0,
                      'text-gray-600': watchedFormData.newPassword.length > 0,
                    })}
                  />
                </button>
              }
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.newPassword && <p className="pt-1 text-xs text-red-600">{errors.newPassword.message}</p>}
      </div>
      <div>
        <label
          htmlFor="lastName"
          className={classNames('mb-2 block text-xs font-semibold text-gray-800', { 'text-red-700': false })}
        >
          Confirm password
        </label>
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: 'Confirm password is required',
            validate: (value) => value === getValues('newPassword') || 'Passwords do not match',
          }}
          render={({ field }) => (
            <Inputs.Input
              type={confirmPasswordVisible ? 'text' : 'password'}
              id="confirm-password"
              placeholder="Confirm password"
              classes={classNames('rounded-lg h-[42px]', { 'ring-red-700': false })}
              data-cy="confirm-password-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  disabled={!watchedFormData.confirmPassword.length}
                  data-rh={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={confirmPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.confirmPassword.length === 0,
                      'text-gray-600': watchedFormData.confirmPassword.length > 0,
                    })}
                  />
                </button>
              }
            />
          )}
        />
        {errors.confirmPassword && <p className="pt-1 text-xs text-red-600">{errors.confirmPassword.message}</p>}
      </div>
      <Buttons.Primary
        type="submit"
        data-cy="submit-button"
        className="h-[40px] w-full"
        disabled={
          !watchedFormData.newPassword.length ||
          !watchedFormData.confirmPassword.length ||
          changePasswordMutation.isLoading
        }
      >
        Update password
      </Buttons.Primary>
      {changePasswordMutation.isError && (
        <div className="text-xs text-red-400" data-cy="error-message">
          Failed to reset password.
        </div>
      )}
      {changePasswordMutation.isSuccess && (
        <div className="text-xs text-green-400" data-cy="error-message">
          The password has been successfully changed.
        </div>
      )}
    </form>
  );
};

export default NewPasswordForm;
