import { Buttons, Inputs } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

export type Inputs = {
  firstName: string;
  lastName: string;
};

type Props = {
  user: PeTypes.User;
  onCancel: () => void;
  onSave: (data: Inputs) => void;
};

const AccountInfoForm: React.FC<Props> = ({ user, onSave, onCancel }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });
  const watchedFormData = watch();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    onSave(data);
  };
  const isSaveDisabled =
    !watchedFormData.firstName.length ||
    !watchedFormData.lastName.length ||
    (watchedFormData.firstName === user.firstName && watchedFormData.lastName === user.lastName);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-6"
      data-cy="update-account-form"
      autoComplete="off"
    >
      <div className="flex flex-col gap-5">
        <div>
          <label htmlFor="firstName" className="mb-2 block text-xs font-semibold text-gray-800">
            First name
          </label>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: 'First name is required' }}
            render={({ field }) => (
              <Inputs.Input
                type="text"
                id="first-name"
                classes="rounded-lg h-[42px]"
                data-cy="first-name-input"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          {errors.firstName && <p className="pt-1 text-xs text-red-600">{errors.firstName.message}</p>}
        </div>
        <div>
          <label htmlFor="lastName" className="mb-2 block text-xs font-semibold text-gray-800">
            Last name
          </label>
          <Controller
            control={control}
            name="lastName"
            rules={{ required: 'Last name is required' }}
            render={({ field }) => (
              <Inputs.Input
                type="text"
                id="last-name"
                classes="rounded-lg h-[42px]"
                data-cy="last-name-input"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          {errors.lastName && <p className="pt-1 text-xs text-red-600">{errors.lastName.message}</p>}
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-3 text-xs font-normal text-gray-500">
        <Buttons.Secondary type="button" onClick={() => onCancel()} className="py-2 px-3">
          Cancel
        </Buttons.Secondary>
        <Buttons.Primary type="submit" data-cy="submit-button" className="py-2 px-3" disabled={isSaveDisabled}>
          Save Changes
        </Buttons.Primary>
      </div>
    </form>
  );
};

export default AccountInfoForm;
