import { LngLatBounds, Map } from 'maplibre-gl';
import * as peApi from '../pe.api';

function useFitBounds(map: Map | null) {
  return async (datasetId: string) => {
    try {
      const dataset = await peApi.getDataset(datasetId);

      if (dataset.tiles.projections) {
        const [projection] = dataset.tiles.projections;
        const { xmin, xmax, ymin, ymax } = projection.extent;

        // sample:
        // { xmin: -82.1288, ymin: 38.6475, xmax: -81.6648, ymax: 38.2592 }

        const bounds = new LngLatBounds(
          [xmax, ymin], // southwest coordinates
          [xmin, ymax] // northeast coordinates
        );

        map?.fitBounds(bounds, { padding: 20, maxZoom: 15, duration: 2000 });
      }
    } catch (e) {
      console.warn("Can't fit to bounds.", e);
    }
  };
}

export default useFitBounds;
