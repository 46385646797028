import { Inputs, Modal, useSnackbars } from '@platform/shared/ui';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../../pe.api';

type Inputs = {
  rowSubject: string;
};

interface Props {
  datasetId: string;
  rowSubject: string | null;
  onCloseRequest: () => void;
}

const EditDataRowSubjectModal: React.FC<Props> = ({ datasetId, rowSubject, onCloseRequest }) => {
  const [notify] = useSnackbars();

  const { watch, control, handleSubmit } = useForm<Inputs>({ defaultValues: { rowSubject: rowSubject ?? '' } });

  const queryClient = useQueryClient();

  const updateRowSubjectMutation = useMutation({
    mutationFn: (payload: { rowSubject: string }) => peApi.editDatasetMetadata(datasetId, payload),
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    updateRowSubjectMutation.mutate(
      { rowSubject: data.rowSubject },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });

          notify({
            hideAfterSec: 2,
            content: `Data row subject updated.`,
          });
          onCloseRequest();
        },
        onError: async () => {
          notify({
            hideAfterSec: 2,
            content: `Something went wrong`,
          });
          onCloseRequest();
        },
      }
    );
  };

  return (
    <Modal
      title="Data row/subject"
      okLabel="Save"
      onDone={handleSubmit(onSubmit)}
      okDisabled={updateRowSubjectMutation.isLoading || watch('rowSubject') === rowSubject}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
      className="w-[480px]"
    >
      <div className="flex max-h-[600px] flex-col overflow-y-auto py-4">
        <div className="flex flex-col gap-4 pb-7 text-sm text-gray-700">
          <div>Providing information about the meaning of rows ensures clarity, accuracy, and effective analysis.</div>
          <div>
            A row in a table represent a particular object or a real-world individual i.e., person, employee, voter, a
            restaurant, product, etc.
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="rowSubject"
            control={control}
            render={({ field }) => {
              return (
                <div>
                  <label htmlFor="rowSubject" className="mb-3 block text-sm font-bold text-gray-900">
                    A row in this dataset represents:{' '}
                    <span className="text-sm font-normal text-gray-500">(optional)</span>
                  </label>
                  <Inputs.Input
                    autoFocus
                    id="rowSubject"
                    roundedClasses="rounded-lg"
                    value={field.value}
                    className="w-full py-1 outline-none"
                    onChange={(opt) => field.onChange(opt.target.value)}
                  />
                </div>
              );
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default EditDataRowSubjectModal;
