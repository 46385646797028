import { Buttons } from '@platform/shared/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundSVG } from '../../../assets/not-found.svg';
import { useSessionContext } from '../../contexts/SessionContext';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';

interface Props {
  imageComponent?: JSX.Element;
  title?: string;
  message?: string;
}

const ErrorPage: React.FC<Props> = ({ imageComponent, title, message }) => {
  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspaceContext();
  const { isAnonymousAccess } = useSessionContext();

  const showBackToDashboard = !isAnonymousAccess;

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex h-full w-1/2 max-w-[503px] flex-col items-center justify-center gap-12">
        {imageComponent ?? <NotFoundSVG />}
        <div className="inline-flex flex-col items-center justify-start gap-9 self-stretch">
          <div className="flex h-[100px] flex-col items-center justify-start gap-4">
            <div className="font-inter self-stretch text-center text-2xl font-bold leading-9 text-gray-800">
              {title ?? 'Oops! Something went wrong.'}
            </div>
            <div className="font-inter self-stretch text-center text-base font-normal leading-normal text-gray-600">
              {message ??
                'The page you are looking for might have been removed, had its name changed, or its temporary unavailable.'}
            </div>
          </div>
          {showBackToDashboard && (
            <div className="inline-flex items-center justify-center gap-1 rounded-md bg-indigo-600 px-3.5 py-2">
              <Buttons.Primary
                onClick={() => navigate(generateLink('workspace', { workspaceId: activeWorkspace.id }))}
                className="font-inter w-[364px] text-sm font-semibold text-white"
              >
                Back to dashboard
              </Buttons.Primary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
