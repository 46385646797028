import { MetadataTypes } from '@platform/types';
import { GetVariableUniverse } from '@platform/utils';
import useTableMetadata from './useTableMetadata';

interface Props {
  surveyName: string;
  datasetAbbreviation: string;
  tableUuid: string;
  variableUuid: string;
}

const useVariableMetadata = ({ surveyName, datasetAbbreviation, tableUuid, variableUuid }: Props) => {
  const tableMetadata = useTableMetadata({ surveyName, datasetAbbreviation, tableUuid });

  const isLoading = tableMetadata.isLoading || tableMetadata.isIdle;
  const isError = tableMetadata.isError;

  const variable = tableMetadata.data?.variables.find((x) => x.uuid === variableUuid);

  let universeVariablesMetadata: MetadataTypes.Variable[] = [];
  if (variable && tableMetadata.data) {
    universeVariablesMetadata = GetVariableUniverse(variable.uuid, tableMetadata.data);
  }

  return {
    isLoading,
    isError,
    variable,
    universeVariablesMetadata,
    table: tableMetadata.data,
  };
};

export default useVariableMetadata;
