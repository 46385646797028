import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';

interface Props {
  projectId: string | null;
  children: React.ReactNode;
}

const MenuOptionsWrapper: React.FC<Props> = ({ projectId, children }) => {
  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspaceContext();

  const navigationOptions: { title: string; icon: JSX.Element; route: string }[] = [
    {
      title: 'Back to Dashboard',
      icon: <MUIcon name="reply" />,
      route: generateLink('workspace', { workspaceId: activeWorkspace.id }),
    },
    {
      title: 'Open project',
      icon: <MUIcon name="folder" />,
      route: projectId
        ? generateLink('project', {
            projectId: projectId,
            workspaceId: activeWorkspace.id,
          })
        : generateLink('projects', { workspaceId: activeWorkspace.id }),
    },
  ];

  const handleClick = (route: string) => navigate(route);

  return (
    <div className="mt-6 flex w-full flex-col">
      {navigationOptions.map((option) => (
        <button
          key={option.title}
          onClick={() => handleClick(option.route)}
          className="flex w-full cursor-pointer items-center gap-5 py-3 px-6 hover:bg-gray-100"
        >
          {option.icon}
          <span className="text-sm text-gray-900">{option.title}</span>
        </button>
      ))}
      <div className="inset-0 w-full py-4">
        <div className="absolute left-0 right-0 h-[1px] bg-gray-200"></div>
      </div>
      {children}
      <div className="inset-0 w-full py-4">
        <div className="absolute left-0 right-0 h-[1px] bg-gray-200"></div>
      </div>
      <Link to={'https://www.socialexplorer.com/help/'} target="_blank">
        <div className="flex w-full cursor-pointer items-center gap-5 py-4 px-6 hover:bg-gray-100">
          <MUIcon name="help" />
          <span className="text-sm text-gray-900">Help</span>
        </div>
      </Link>
    </div>
  );
};

export default MenuOptionsWrapper;
