import { Dropdowns, Inputs, MUIcon } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React, { useState } from 'react';
import EmptyColumnsState from '../../Datasets/Dataset/ColumnPicker/EmptyColumnsState';
import { getSuffixIcon } from '../../Datasets/Dataset/DatasetTable/utils';
import { ImportProgress } from '../ImportWizard';
import DataFormattingItem from './DataFormattingItem';

const NON_EXISTING_DATA_TYPE = -1;

interface Props {
  importData: ImportProgress;
  onRowSubjectChange: (rowSubjectLabel: string) => void;
  onImportDataFormattingChange: (column: PeTypes.ImportDatasetPayloadColumn) => void;
}

const LABELS: Record<DatasetTypes.DataType, string> = {
  [DatasetTypes.DataType.TEXT]: 'String',
  [DatasetTypes.DataType.INTEGER]: 'Integer',
  [DatasetTypes.DataType.BOOLEAN]: 'Boolean',
  [DatasetTypes.DataType.DATE]: 'Date',
  [DatasetTypes.DataType.DOUBLE]: 'Number',
  [DatasetTypes.DataType.GEOGRAPHY]: 'Geography',
  [DatasetTypes.DataType.GEOGRAPHY_REFERENCE]: 'Geography Reference',
  [DatasetTypes.DataType.ID]: 'Id',
};

const TYPES_ALLOWED_ON_IMPORT = [
  DatasetTypes.DataType.DOUBLE,
  DatasetTypes.DataType.TEXT,
  DatasetTypes.DataType.BOOLEAN,
];

export const typeDropdownOptions: Dropdowns.DropdownOption[] = TYPES_ALLOWED_ON_IMPORT.map((type) => ({
  icon: getSuffixIcon(type),
  name: LABELS[type],
  value: type,
}));

const filterOptions = [
  { icon: <MUIcon name="close" />, name: 'View all', value: NON_EXISTING_DATA_TYPE },
  ...typeDropdownOptions,
];
const DataFormatting: React.FC<Props> = ({ importData, onRowSubjectChange, onImportDataFormattingChange }) => {
  const { columns, data, columnsAsParsed } = importData;
  const [searchValue, setSearchValue] = useState<string>('');
  const [rowSubject, setRowSubject] = useState<string>(importData.rowSubject ?? '');
  const [typeFilter, setTypeFilter] = useState<number>(NON_EXISTING_DATA_TYPE);

  const handleSubjectKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    onRowSubjectChange(rowSubject);
  };

  const filteredColumns = columns?.filter(
    (c) =>
      (typeFilter === -1 || c.type === typeFilter) &&
      (searchValue === '' || c.name.toLowerCase().includes(searchValue.toLowerCase()))
  );

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex h-full min-h-0 w-8/12 flex-col gap-5">
        <div className="flex flex-col gap-4">
          <p className="text-sm text-gray-800">
            What does a single row in the dataset represent? <span className="text-sm text-gray-500">(optional)</span>
          </p>
          <input
            className="focus:outline-primary-500 rounded-md border border-gray-300 p-3 text-sm"
            placeholder="e.g a person, voter, restaurant, etc."
            value={rowSubject}
            onChange={(e) => setRowSubject(e.target.value)}
            onKeyDown={handleSubjectKeyPress}
            onBlur={() => onRowSubjectChange(rowSubject)}
          ></input>
          <p className="mt-4 text-sm text-gray-800">
            Check the column types, formatting, and provide a alternate name for use in pop ups.
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex w-80 ">
            <Inputs.Input
              classes="flex bg-gray-100 rounded-lg ring-transparent"
              value={searchValue}
              onClear={() => setSearchValue('')}
              prefixComponent={<MUIcon name="search" className="px-2" />}
              className=" focus: w-80 bg-transparent  outline-none"
              placeholder="Search"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <Dropdowns.Dropdown
            triggerWrapperClasses="w-[120px]"
            menuWidth="full"
            triggerClasses="bg-white w-full px-4 flex grow-0 ring-0 text-gray-800 font-normal rounded-[999px] h-[44px] border border-gray-200 truncate"
            options={filterOptions}
            value={typeFilter}
            onSelect={(option) => setTypeFilter(option.value as number)}
            hideIconInTrigger
          />
        </div>
        {filteredColumns.length === 0 ? (
          <EmptyColumnsState searchTerm={searchValue} />
        ) : (
          <div className="mt-2 flex min-h-0 flex-col gap-6">
            <div className="flex items-center justify-between">
              <div className="basis-2/5 text-sm font-semibold">Column</div>
              <div className="basis-1/5 text-sm font-semibold">Type</div>
              <div className="basis-1/5 text-sm font-semibold">Formatting</div>
              <div className="basis-1/5 text-sm font-semibold">Alternate "Friendly" Name</div>
            </div>
            <div className="flex min-h-0 flex-col gap-5 pr-1">
              {filteredColumns?.map((column, idx) => {
                const sampleValues = data
                  .filter((x) => x[idx]?.toString().trim().length)
                  .slice(0, 5)
                  .map((x) => x[idx]);

                const disableFormatChange = ![DatasetTypes.DataType.DOUBLE, DatasetTypes.DataType.INTEGER].includes(
                  column.type
                );
                const originalColumnType = columnsAsParsed.find((x) => x.name === column.name)?.type;

                return (
                  <DataFormattingItem
                    sampleValues={sampleValues}
                    disableFormatChange={disableFormatChange}
                    column={column}
                    key={`${column.name}_${idx}`}
                    onImportDataFormattingChange={onImportDataFormattingChange}
                    originalColumnType={originalColumnType}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataFormatting;
