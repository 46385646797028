import { metadataApi } from '@platform/api';
import { MetadataTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';

interface Props {
  surveyName: string;
  datasetAbbreviation: string;
}

function useDatasetMetadata(
  { surveyName, datasetAbbreviation }: Props,
  options?: UseQueryOptions<MetadataTypes.DataSet>
) {
  return useQuery<MetadataTypes.DataSet>(
    ['metadata/dataset', surveyName, datasetAbbreviation],
    () => metadataApi.loadDataset(surveyName, datasetAbbreviation),
    options
  );
}

export default useDatasetMetadata;
