import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../components/shared/ErrorPage';
import { View } from '../components/Stories';

const ViewStoryPage: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  if (!storyId) return <ErrorPage />;

  return <View storyId={storyId} />;
};

export default ViewStoryPage;
