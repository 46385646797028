import { kml } from '@tmcw/togeojson';
import * as xmlDOM from '@xmldom/xmldom';
import * as GeoJSON from 'geojson';
import { parseGeoJsonFeatureCollection } from '../utils';

const xmlParser = new xmlDOM.DOMParser();

export default async (
  file: File,
  limit?: number
): Promise<{
  data: unknown[][] | null;
  columns: string[] | null;
  featureCollection?: GeoJSON.FeatureCollection;
  totalRowsCount: number;
  error?: unknown;
}> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const geoJsonObj = kml(
        xmlParser.parseFromString(reader.result as string, 'text/xml')
      ) as GeoJSON.FeatureCollection;
      if (!geoJsonObj) {
        resolve({ data: null, columns: null, totalRowsCount: -1, error: 'Error while parisng kml file' });
        return;
      }

      const { columns, data } = parseGeoJsonFeatureCollection(geoJsonObj, limit);
      resolve({ columns, data, featureCollection: geoJsonObj, totalRowsCount: geoJsonObj.features?.length });
    };
    reader.onerror = () => {
      resolve({ data: null, columns: null, totalRowsCount: -1, error: 'Error while loading kml file' });
    };
    reader.readAsText(file, 'text/xml');
  });
