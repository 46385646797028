import React, { useEffect, useState } from 'react';
import { useStory } from '../../hooks';
import { Loader } from '../shared';
import ErrorPage from '../shared/ErrorPage';
import Slide from './Slide';

interface Props {
  storyId: string;
  defaultSlideId?: string;
}

const View: React.FC<Props> = ({ storyId, defaultSlideId }) => {
  const [currentSlideId, setCurrentSlideId] = useState<string | undefined>(defaultSlideId);
  const { storyQuery, slidesQuery } = useStory(storyId);

  useEffect(() => {
    if (slidesQuery.data) {
      setCurrentSlideId(slidesQuery.data[0]?.id);
    }
  }, [slidesQuery.data]);

  const isLoading = storyQuery.isIdle || storyQuery.isLoading || slidesQuery.isIdle || slidesQuery.isLoading;
  const isError = storyQuery.isError || slidesQuery.isError;
  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  const slides = slidesQuery.data;

  const currentSlide = slides.find((x) => x.id === currentSlideId);
  if (!currentSlide) return <ErrorPage />;

  const currentIndex = slides.findIndex((x) => x.id === currentSlideId);

  const handleMoveToPrevSlideRequest = () => {
    const prevIndex = Math.max(0, currentIndex - 1);
    setCurrentSlideId(slides[prevIndex].id);
  };

  const handleMoveToNextSlideRequest = () => {
    const nextIndex = Math.min(currentIndex + 1, slides.length - 1);
    setCurrentSlideId(slides[nextIndex].id);
  };

  return (
    <div className="flex h-full w-full">
      <Slide
        story={storyQuery.data}
        slide={currentSlide}
        slideIndex={currentIndex}
        mode={'view'}
        onCurrentSlideChangeRequest={(sId) => setCurrentSlideId(sId)}
        onMoveToPrevSlideRequest={handleMoveToPrevSlideRequest}
        onMoveToNextSlideRequest={handleMoveToNextSlideRequest}
      />
    </div>
  );
};

export default View;
