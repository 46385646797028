import { useDebounce } from '@platform/shared/hooks';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface Props {
  className: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}

const Slider: React.FC<Props> = ({ className, value, onChange, min, max, step, ...otherInputProps }) => {
  const [inputValue, setInputValue] = useState<number>(value);
  const debouncedValue = useDebounce(inputValue, 200);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (value === debouncedValue) return;
    onChange(debouncedValue);
  }, [debouncedValue]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(parseFloat(e.target.value));
  };

  return (
    <div className={classNames('relative flex w-full items-center ', className)}>
      <input
        type="range"
        autoComplete="off"
        className="w-full"
        min={min}
        max={max}
        step={step}
        value={inputValue}
        {...otherInputProps}
        onChange={handleChange}
      />
      <div
        className="bg-primary-500 pointer-events-none absolute top-1/2 h-1 -translate-y-1/2 rounded-[999px]"
        style={{ width: `${inputValue * 100}%` }}
      />
    </div>
  );
};

export default Slider;
