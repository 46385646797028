import React from 'react';
import LogoText from '../shared/LogoText';

interface Props {
  children: React.ReactNode;
}

const ExportLoader: React.FC<Props> = ({ children }) => (
  <div className="fixed top-0 right-0 z-[99999] p-8">
    <div className="fixed top-0 left-0 z-[9999] flex h-full w-full flex-col items-center  justify-center gap-2 bg-white/80 text-gray-900">
      <LogoText />
      {children}
    </div>
  </div>
);

export default ExportLoader;
