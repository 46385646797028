import { Menu } from '@platform/shared/ui';
import { useState } from 'react';
import { HorizontalAlignmentType, VerticalAlignmentType } from '../Menu/menu.types';

interface Props {
  content: JSX.Element;
  trigger?: React.ReactNode;
  hAlignment?: HorizontalAlignmentType;
  vAlignment?: VerticalAlignmentType;
}

const Popup: React.FC<Props> = ({ content, trigger, hAlignment = 'right', vAlignment = 'bottom' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Menu
      visible={isOpen}
      onToggleVisibility={setIsOpen}
      trigger={trigger}
      hAlignment={hAlignment}
      menuClasses="py-0"
      vAlignment={vAlignment}
      menuMargin={10}
    >
      <div className="mr-2 flex w-[288px] p-4 text-xs">{content}</div>
    </Menu>
  );
};

export default Popup;
