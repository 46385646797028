import { RuleGroupType } from 'react-querybuilder';
import { DatasetTypes, ProjectTypes } from '..';

export interface ApiError {
  message: string;
  stack?: string;
}

export interface SeReportData {
  data: (string | number)[][];
  headers: string[];
  totals: number[];
}

export interface NewPasswordPayload {
  accessCode: string;
  password: string;
}

export interface ResetPasswordPayload {
  email: string;
}

export interface EmailVerificationPayload {
  email: string;
}

export interface ConfirmVerificationCodePayload {
  code: string;
  email: string;
}

export interface CreateAccountPayload {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

export interface AccountInfoPayload {
  userId: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  newPassword?: string;
  oldPassword?: string;
}

export interface InvitationDetails {
  id: number;
  invitorId: number;
  identityId: number;
  invitedEmail: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  pictureSmallURL?: string;
  pictureLargeURL?: string;
  roleInfo?: string;
  industryInfo?: string;
  organizationInfo?: string;
  updatedAt?: string;
}

export interface WorkspaceMember extends User {
  type?: number;
  initials?: string;
  createdAt?: string;
  updatedAt?: string;
  emailVerified?: boolean;
  isAdmin?: boolean;
}

export interface PendingInvitation {
  id: number;
  invitorId: number;
  identityId: number;
  identityPermissionLevel: string | number;
  invitedEmail: string;
  context: unknown;
  createdAt: string;
}

export interface InviteMemberPayload {
  isAdmin: boolean;
  groupId: number;
  memberId?: number;
  email?: string;
}

export interface RenameDatasetPayload {
  newTitle: string;
  oldTitle: string;
}

export interface CopyDatasetPayload {
  datasetId: string;
  ownerId: number;
  title: string;
  filter?: RuleGroupType;
}

export interface CreateProfilePayload {
  projectId: string | null;
  title: string;
  defaultMapId?: string;
}

export interface CopyProfilePayload {
  title: string;
  projectId: string | null;
}

export interface QueryProfilesPayload {
  projectId: string;
  watchedOnly: string;
}

export interface Profile {
  id: string;
  projectId: string | null;
  projectTitle: string;
  title: string;
  type: string;
  watched: boolean;
  workingLayers?: Array<{ id: string; datasetId: string; name: string; dataFilter: RuleGroupType }>;
  editedAt?: Date;
  geoSelectionId?: string;
  mapContextId: string;
  thumbPath?: string;
}

export interface CreateStoryPayload {
  title: string;
  projectId: string | null;
  defaultMapId?: string;
  mapContextId?: string;
  description?: string;
}

export interface CopyStoryPayload {
  title: string;
  projectId: string | null;
}

export interface Story {
  id: string;
  projectId: string | null;
  title: string;
  description: string;
  thumbPath: string;
  slidesCount: number;
  editedAt?: Date;
}

export interface RecentStory {
  id: string;
  title: string;
  thumbPath: string;
  slidesCount: number;
  projectId: string | null;
}

export interface AddStorySlide {
  copyOfSlideId: string;
  addAfterId?: string;
  addBeforeId?: string;
}

export interface NewSlideOrdering {
  slideId: string;
  startId: string | null;
  endId: string | null;
}

export interface StorySlide {
  id: string;
  storyId: string;
  text: string;
  mapContextId: string;
  thumbPath: string;
}

export interface CreateProject {
  title: string;
}

export interface RenameProject {
  newTitle: string;
}

export interface RenameStory {
  newTitle: string;
}

export interface Project {
  id: string;
  title: string;
  createdAt: string;
}

export interface QueryRecentProjectPayload {
  itemsPerPage: number;
  pageNumber: number;
}

export interface RecentProject {
  id: string;
  title: string;
  profileCount: number;
  storyCount: number;
}

export interface DataType {
  dataQueryResults: object;
  first_name: string;
  last_name: string;
}

export interface QueryDatasetDataPayload {
  datasetId: string;
  columnIds: string[];
  IDColumn: string;
  filter: RuleGroupType;
  itemsPerPage: number;
  pageData: DatasetTypes.DatasetPageData;
  sortBy?: string;
  sortOrder?: number;
}

export interface QueryDatasetColumnPayload {
  datasetId: string;
  pageNumber?: number;
  itemsPerPage?: number;
}

export interface QueryDataResponse {
  rows: unknown[][];
  isPartial: boolean;
}

export interface QueryDatasetsPayload {
  searchTerm: string;
  types: ProjectTypes.LayerType[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  pageSize?: number;
  pageNumber?: number;
}

export interface QueryDatasetsResponse {
  datasets: DatasetListItem[];
  totalCount: number;
}

export interface DatasetColumn {
  uuid: string;
  title: string;
  type: number;
  properties?: {
    min?: number;
    max?: number;
    avg?: number;
    sum?: number;
    discreteValues: any;
    unique: boolean;
    empty: boolean;
  };
  dirty?: boolean;
  dirtyInTiles: boolean;
  label?: string;
  formatting?: string;
  description: string;
  aggregationMethod?: string;
}

export interface EditColumnMetadataRequest {
  title: string | null;
  label: string | null;
  type: number | null;
  formatting: string | null;
}

export interface UpdateDatasetColumnMetadataPayload {
  columns: DatasetColumnMetadata[];
}

export interface DatasetColumnMetadata {
  datasetId: string;
  columnId: string;
  isFilterable: boolean;
}

export interface DatasetBasicInfo {
  id: string;
  ownerId: number;
  name: string;
  type: ProjectTypes.LayerType;
  editedAt: Date | null;
  numberOfRecords: number;
  numberOfColumns: number;
  sizeInMB: number;
}

export interface DatasetListItem extends DatasetBasicInfo {
  unPublishedChangesCount: number;
  hasEverBeenPublished: boolean;
  geoLayerId?: string;
}

export interface Dataset extends DatasetBasicInfo {
  rowSubject: string;
  tiles: {
    geoLayerId?: string;
    projections?: {
      extent: {
        xmin: number;
        ymin: number;
        xmax: number;
        ymax: number;
      };
      thumbnailUrl: string;
      thumbnailUrlLarge: string;
      code: string;
    }[];
    dataTilesDirty: boolean;
  };
}

export interface DatasetLogItem<T = object> {
  id: string;
  datasetId: string;
  userId: string | null; // null = system
  createdAt: Date;
  finishedAt: Date | null;
  actionType: string;
  status: string;
  metadata: T;
}

export interface RowDeletionLogItem {
  rowsUuids: string[];
}

export interface ExportCsvFromGeoBuffer {
  datasetId: string;
  columns: string[];
  type: string;
}

export interface ExportCsv {
  datasetId: string;
  query?: RuleGroupType;
  columns: Array<{ value: string; label: string }>;
  type: string;
}

export interface ChartSeries {
  colors: string[];
  data: string[];
  name: string;
  universe?: string;
  ignoreUniverse?: boolean; // If set to true we do not calculate percent (universe is used for totals)
  type: 'column' | 'bar' | 'line';
}

export interface ChartDefinition {
  categories: string[];
  valueFormat: ProjectTypes.ValueFormat;
  series: ChartSeries[];
  titleChart?: string;
  titleColor?: string;
}

export interface SliderDefinition {
  min: number;
  max: number;
  value: number;
  stops: { color?: string; label: string; labelColor?: string; colorPercentage?: string }[];
  valueFormat: ProjectTypes.ValueFormat;
}

export interface NumberDefinition {
  valueFormat: ProjectTypes.ValueFormat;
  label: string;
  value: string;
  steps?: NumberDefinitionSteps[];
}

export interface NumberDefinitionSteps {
  max: number;
  min: number;
  color: string;
  label: string;
}

export interface RowDefinition {
  valueFormat: ProjectTypes.ValueFormat;
  label: string;
  value: string;
  indent: number;
  pctg: string;
}

export interface RowDataDefinition {
  valueFormat: ProjectTypes.ValueFormat;
  label: string;
  value: number;
  indent: number;
  pctgValue: number;
}

export interface Visualization {
  title: string;
  info: string;
  valueModifier?: number;
  charts?: ChartDefinition[];
  slider?: SliderDefinition;
  number?: NumberDefinition;
  rows?: RowDefinition[];
  type: 'charts' | 'slider' | 'table' | 'number';
  withFilter?: boolean;
  chartType?: 'normal' | 'stacking';
  height?: number;
}

export interface DatasetInsightsBasicInfo {
  id: string;
  title: string;
}

export interface DatasetInsight extends DatasetInsightsBasicInfo {
  dataset_id: string;
  config: { [key: string]: string };
  visualizations: Visualization[];
  metadata: { [key: string]: string };
}

export interface SurveyInsightsBasicInfo {
  id: string;
  title: string;
}

export interface SurveyInsights extends SurveyInsightsBasicInfo {
  config: { surveyName: string; tables: string[] };
  visualizations: Visualization[];
  metadata: { [key: string]: string };
}

export interface SummarizeInsigths {
  datasetInsightsId: string;
  geoSelectionId?: string;
  dataFilter?: RuleGroupType;
}

export interface SummarizeTableRequest {
  varSelection: ProjectTypes.VariableSelection;
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[];
}

export interface SummarizeSurveyRequest {
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[];
}

export interface Report {
  data: { [key: string]: number };
  visualizations: Visualization[];
  metadata: { [key: string]: string };
}

export interface GeoRecord {
  id: string;
  label: string;
  title: string;
  boundingBox: { swLng: number; swLat: number; neLng: number; neLat: number };
}

export interface GeoSearch {
  searchTerm: string;
  activeSummaryLevelId?: string;
  defaultGbGeoRecordSearchIndex: string | undefined;
}

export interface DefaultMap {
  id: string;
  title: string;
  description: string;
  mapSpec: ProjectTypes.MapSpec;
  thumbPath: string;
  preselect: boolean;
}

export interface ImageDim {
  label: string;
  width: number;
  height: number;
}

export interface Workspace {
  id: number;
  name: string;
  description: string;
  isAdmin: boolean;
  imageURL: string;
  updatedAt: string;
  availableSurveys: ProjectTypes.SurveyToLoad[];
}

export interface WorkspaceSubscription {
  id: number;
  hasActiveLicence: boolean;
}

export interface Export {
  createdFileName: string;
  createdDownloadLink: string;
}

export interface HeaderOptions {
  id: number;
  icon: JSX.Element;
  suffixIcon?: JSX.Element;
  title: string;
  label: string;
  linkTo: string;
  triggerTitle: string;
  target?: string;
}

export enum Action {
  NEW_PROJECT,
  NEW_PROFILE,
  NEW_STORY,
}

export interface SignS3 {
  signedUrl: string;
  staticUrl: string;
}

export interface CellEditPayload {
  datasetId: string;
  columnUuid: string;
  rowsUuids: string[];
  newValue: unknown;
}

export interface AddRowPayload {
  indexAt?: number;
}

export interface AddColumnPayload {
  columnName: string;
  dataType: DatasetTypes.DataType;
  defaultValue: unknown;
}

export interface CombineColumnsPayload {
  columnIds: string[];
  separator: string;
}

export interface DuplicateColumnPayload {
  columnName: string;
  label: string;
  columnUuid: string;
}

export interface DuplicateDatasetPayload {
  ownerId: number;
  title: string;
}

export interface WorkspaceStatistics {
  totalInBytes: number;
  detailed: Detailed;
  permission: Permission;
  quota: Quota;
}

export interface Detailed {
  attachments: Attachments;
  versions: Versions;
  datasets: Datasets;
}

export interface Attachments {
  count: number;
  sizeInBytes: number;
}

export interface Versions {
  count: number;
  sizeInBytes: number;
}

export interface Datasets {
  count: number;
  geoTablesSizeInBytes: number;
  attTablesSizeInBytes: number;
  geoTileLayersSizeInBytes: number;
  dataTileLayersSizeInBytes: number;
}

export interface Permission {
  planId: string;
  level: number;
  maxColumns: number;
  maxStorage: number;
  maxSeGeocodes: number;
}

export interface Quota {
  seGeocoderEnabled: boolean;
  seGeocoderUsed: number;
  seGeocoderQuota: number;
  seGeocoderQuotaBase: number;
  seGeocoderQuotaAddon: number;
  hereGeocoderEnabled: boolean;
  hereGeocoderUsed: number;
  hereGeocoderQuota: number;
}

export enum DatasetActionEnum {
  COLUMN_CREATION = 'COLUMN-CREATION',
  COLUMN_METADATA_UPDATE = 'COLUMN-METADATA-UPDATE',
  ROW_SUBJECT_UPDATE = 'ROW-SUBJECT-UPDATE',
  COLUMN_RENAME = 'COLUMN-RENAME',
  CELL_UPDATE = 'CELL-UPDATE',
  PUBLISH = 'PUBLISH',
  ROW_CREATION = 'ROW-CREATION',
  ROW_DELETION = 'ROW-DELETION',
  COLUMN_COMBINATION = 'COLUMN-COMBINATION',
  COLUMN_DUPLICATION = 'COLUMN-DUPLICATION',
  COLUMN_DELETION = 'COLUMN-DELETION',
  COLUMN_METADATA = 'COLUMN-METADATA',
}

export enum DatasetLogStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface PublishDatasetError {
  error: string;
  columnsExceeded: boolean;
  storageExceeded: boolean;
}

export enum DatasetImportStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface PublishedColumn {
  id: string;
  name: string;
  label: string;
  format: string;
  type: number;
  filterable: boolean;
}

export interface DatasetPublication {
  id: string;
  datasetId: string;
  workspaceId: number;
  userId: number;
  rowCount: number | null;
  columnCount: number | null;
  lastKnownEditAt: Date | null;
  createdAt: Date | null;
  columns: PublishedColumn[];
  rowSubject?: string;
}

export interface ImportDatasetPayloadColumn {
  name: string;
  type: DatasetTypes.DataType;
  formatting?: string;
  label?: string;
}

export interface ImportDatasetPayload {
  uploadedFileName: string;
  fileNames: string[];
  contentType: string;
  contentLength: number;
  rowSubject?: string;
  columns: ImportDatasetPayloadColumn[];
  geo?: {
    latitude?: string;
    longitude?: string;
    geocode?: ImportDatasetGeoCode;
  };
}

export interface ImportDatasetGeoCodeSelection {
  address: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface ImportDatasetGeoCode {
  isNonQualifiedQuery: boolean;
  selection: ImportDatasetGeoCodeSelection;
}

export interface ImportDatasetResponse {
  importId: string;
  signedUrl: string;
}

export interface DatasetImport {
  id: string;
  datasetId: string;
  gbJobId: string;
  userId: number;
  ownedBy: number | null;
  createdAt: Date;
  finishedAt: Date | null;
  status: DatasetImportStatus;
  geoBufferError?: string;
  progress: number;
  metadata: DatasetImportMetadata;
}

export interface DatasetImportMetadata {
  id: string;
  datasetId: string | null;
  contentLength: number;
  fileNames: string[];
  uploadedFileName: string;
}

export interface GeoCodeAddress {
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface GeocodeRequestPayload {
  nonQualifiedQuery: boolean;
  values: GeoCodeAddress[];
}

export interface GeocodeResponse {
  result: GeocodeResultItem[];
  createdAt: Date;
}

export interface GeocodeResultItem {
  coordinates: { lat: string; lng: string };
  score: number;
  address: string;
}

export enum NotificationType {
  UNKNOWN = 'UNKNOWN',
  PUBLISH_DATASET = 'PUBLISH_DATASET',
  IMPORT_DATASET = 'IMPORT_DATASET',
  RENAME_DATASET = 'RENAME_DATASET',
  EXPORT_DATASET = 'EXPORT_DATASET',
  HARD_DELETE_DATASET = 'HARD_DELETE_DATASET',
  COPY_DATASET = 'COPY_DATASET',
  UPDATE_DATASET_METADATA = 'UPDATE_DATASET_METADATA',
  CELL_UPDATE = 'CELL_UPDATE',
  DUPLICATE_COLUMN = 'DUPLICATE_COLUMN',
  CREATE_COLUMN = 'CREATE_COLUMN',
  DELETE_COLUMN = 'DELETE_COLUMN',
  COMBINE_COLUMNS = 'COMBINE_COLUMNS',
  UPDATE_COLUMN = 'UPDATE_COLUMN',
  ROW_CREATION = 'ROW_CREATION',
  ROW_DELETION = 'ROW_DELETION',
}

export interface Notification<T> {
  id: string;
  ownedBy: number;
  createdBy: number;
  type: NotificationType;
  metadata: T;
  createdAt: Date;
  updatedAt?: Date;
}

export interface UserEvent<T extends object> {
  id: string;
  ownedBy: number;
  createdBy: number;
  type: NotificationType;
  metadata: T;
  createdAt: Date;
}

export interface UserNotification<T extends object> {
  id: string;
  ownedBy: number;
  userId: number;
  type: NotificationType;
  createdBy: number;
  metadata: T;
  createdAt: Date;
  updatedAt?: Date;
  read: boolean;
  readAt?: Date;
}

export interface UserNotificationCountPerWorkspace {
  count: number;
  workspaceId: number;
}

export interface PublishDatasetEvent {
  datasetId: string;
  title: string;
  status: DatasetLogStatus;
}

export interface PublishDatasetNotification extends PublishDatasetEvent {
  progress: number;
  datasetType: ProjectTypes.LayerType;
  error?: { message: string };
}

export interface ImportDatasetEvent {
  status: DatasetImportStatus;
  datasetId?: string;
  datasetTitle?: string;
  filename: string;
}

export interface ImportDatasetNotification extends ImportDatasetEvent {
  progress: number;
  datasetType?: ProjectTypes.LayerType;
  error?: { message: string };
}

export interface CopyDatasetEvent {
  datasetTitle: string;
  status: DatasetLogStatus;
  datasetId?: string;
}

export interface CopyDatasetNotification extends CopyDatasetEvent {
  sourceDatasetId: string;
  workspaceName: string;
  targetWorkspaceId: number;
  progress: number;
  datasetType: ProjectTypes.LayerType;
  error?: { message: string };
}

export interface NewColumnEvent {
  newColumnTitle: string;
  datasetId: string;
  status: DatasetLogStatus;
}

export interface CellUpdateEvent {
  datasetId: string;
  status: DatasetLogStatus;
}

export interface CombineColumnsEvent {
  datasetId: string;
  newColumnTitle: string;
  status: DatasetLogStatus;
}

export interface DeleteColumnEvent {
  datasetId: string;
  status: DatasetLogStatus;
}

export interface RowDeletionEvent {
  datasetId: string;
  status: DatasetLogStatus;
  deletedRowsCount: number;
}

export interface CreateRowEvent {
  datasetId: string;
  status: DatasetLogStatus;
}

export interface UpdateDatasetMetadataEvent {
  datasetId: string;
  rowSubject: string;
  status: DatasetLogStatus;
}

export interface ExportDatasetEvent {
  createdDownloadLink?: string;
  status: DatasetLogStatus;
  datasetTitle?: string;
}

export interface ExportDatasetNotification extends ExportDatasetEvent {
  datasetId: string;
  progress: number;
  datasetType: ProjectTypes.LayerType;
  error?: { message: string };
}

export interface UpdateColumnEvent {
  datasetId: string;
  status: DatasetLogStatus;
}

export interface HardDeleteDatasetEvent {
  title: string;
  status: DatasetLogStatus;
}

export interface HardDeleteDatasetNotification extends HardDeleteDatasetEvent {
  progress: number;
  datasetType: ProjectTypes.LayerType;
  error?: { message: string };
}

export interface RenameDatasetEvent {
  datasetId: string;
  newTitle: string;
  oldTitle: string;
  status: DatasetLogStatus;
}

export enum JobTypes {
  COMBINE_COLUMNS = 'combineColumns',
  DUPLICATE_COLUMN = 'duplicateColumn',
  CELL_EDIT = 'cellEdit',
  UPDATE_COLUMN_METADATA = 'updateColumnMetadata',
  COPY_DATASET = 'copyDataset',
  CREATE_COLUMN = 'createColumn',
  EXPORT_DATASET = 'exportDataset',
  ROW_CREATION = 'rowCreation',
  HARD_DELETE_DATASET = 'hardDeleteDataset',
  PUBLISH_DATASET = 'publishDataset',
  IMPORT_DATASET = 'importDataset',
}

export interface CombineColumnsJobData extends BaseJobData {
  datasetId: string;
  columnIds: string[];
  separator: string;
}

export interface EditCellJobData extends BaseJobData {
  datasetId: string;
  columnUuid: string;
  newValue: any;
  rowsUuids: string[];
}

export interface PublishDatasetJobData extends BaseJobData {
  dataset: {
    uuid: string;
    title: string;
    rowSubject: string;
    updatedAt: string;
    numberOfRecords: number | null;
    numberOfColumns: number | null;
    datasetType: ProjectTypes.LayerType;
  };
  logEntryId: string;
  publishedColumns: PublishedColumn[];
}

export interface DuplicateColumnJobData extends BaseJobData {
  datasetId: string;
  columnName: string;
  columnUuid: string;
  label: string;
}

export interface UpdateColumnJobData extends BaseJobData {
  datasetId: string;
  columnId: string;
  formatting: string;
  label: string;
  title: string;
  type: number;
}

export interface HardDeleteDatasetJobData extends BaseJobData {
  datasetId: string;
  datasetTitle: string;
  datasetType: ProjectTypes.LayerType;
}

export interface CreateColumnJobData extends BaseJobData {
  datasetId: string;
  dataType: number;
  columnName: string;
  defaultValue: any;
}

export interface RowCreationJobData extends BaseJobData {
  datasetId: string;
}

export interface CopyDatasetJobData extends BaseJobData {
  datasetId: string;
  title: string;
  ownerId: number;
}

export interface ExportDatasetJobData extends BaseJobData {
  datasetId: string;
}

export interface ImportDatasetJobData extends BaseJobData {
  userEmail: string;
  importId: string;
  uploadedFileName: string;
}

export interface BaseJobData {
  workspaceId: string;
  userId: number;
}

export interface Job<T extends BaseJobData> {
  id: string;
  token: string;
  type: JobTypes;
  data: T;
}
