import { LngLat, LngLatBoundsLike } from 'maplibre-gl';
import { RuleGroupType, RuleType } from 'react-querybuilder';

export const flattenRules = (ruleGroup: RuleGroupType): RuleType[] => {
  const flattenedRules: RuleType[] = [];

  function flatten(group: RuleGroupType) {
    group.rules.forEach((ruleOrGroup) => {
      if ('rules' in ruleOrGroup) {
        // This is a nested RuleGroupType
        flatten(ruleOrGroup as RuleGroupType);
      } else {
        // This is a RuleType
        flattenedRules.push(ruleOrGroup as RuleType);
      }
    });
  }

  flatten(ruleGroup);

  return flattenedRules;
};

export const getUniqueFields = (ruleGroup: RuleGroupType): string[] => {
  let fields: string[] = [];

  ruleGroup.rules.forEach((rule) => {
    if ('rules' in rule) {
      // If the rule is a RuleGroupType, recursively call the function
      fields = fields.concat(getUniqueFields(rule as RuleGroupType));
    } else {
      // If the rule is a RuleType, push the field name
      fields.push((rule as RuleType).field);
    }
  });

  return Array.from(new Set(fields));
};

export const checkIfValidRule = (rule: RuleType | RuleGroupType, validFields: string[] | null) => {
  const { operator, value, field, disabled } = rule as RuleType;

  // ignore disabled rules
  if (disabled) return false;

  // if validFields provided, please include in validity check
  if (validFields && !validFields.includes(field)) return false;

  switch (operator) {
    case 'between': {
      const [from, to] = value.toString().split(',');
      return from !== '' && !isNaN(from) && from !== null && to !== '' && !isNaN(to) && to !== null;
    }
    case 'null':
    case 'notNull':
      return true;
    default:
      return value != null && value !== '';
  }
};

export const deepCopy = <T>(input: object): T => JSON.parse(JSON.stringify(input)) as T;

export const sleep = (seconds: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const encode = (inputString: string, shift: number): string => {
  return inputString
    .split('')
    .map((char) => {
      const shiftedCharCode = (char.charCodeAt(0) + shift) % 256;
      return String.fromCharCode(shiftedCharCode);
    })
    .join('');
};

export const decode = (encodedString: string, shift: number): string => {
  return encodedString
    .split('')
    .map((char) => {
      const shiftedCharCode = (char.charCodeAt(0) - shift + 256) % 256;
      return String.fromCharCode(shiftedCharCode);
    })
    .join('');
};

export const isValidLatLong = (lat: number, lon: number) => isValidLatitude(lat) && isValidLongitude(lon);
export const isValidLatitude = (lat: number) => lat >= -90 && lat <= 90;
export const isValidLongitude = (lon: number) => lon >= -180 && lon <= 180;

export const isValidLngLatBounds = (bounds: LngLatBoundsLike): boolean => {
  if (Array.isArray(bounds)) {
    // Handle the array cases
    if (typeof bounds[0] === 'number' && bounds.length === 4) {
      // [west, south, east, north]
      const [west, south, east, north] = bounds;
      return isValidLongitude(west) && isValidLatitude(south) && isValidLongitude(east) && isValidLatitude(north);
    } else if (bounds.length === 2 && Array.isArray(bounds[0]) && Array.isArray(bounds[1])) {
      // [[lng, lat], [lng, lat]]
      const [sw, ne] = bounds;
      return isValidLongitude(sw[0]) && isValidLatitude(sw[1]) && isValidLongitude(ne[0]) && isValidLatitude(ne[1]);
    }
  } else if ('northeast' in bounds && 'southwest' in bounds) {
    // { northeast: LngLatLike, southwest: LngLatLike }
    const ne = bounds.northeast as LngLat;
    const sw = bounds.southwest as LngLat;
    const neLng = Array.isArray(ne) ? ne[0] : ne.lng;
    const neLat = Array.isArray(ne) ? ne[1] : ne.lat;
    const swLng = Array.isArray(sw) ? sw[0] : sw.lng;
    const swLat = Array.isArray(sw) ? sw[1] : sw.lat;
    return isValidLongitude(neLng) && isValidLatitude(neLat) && isValidLongitude(swLng) && isValidLatitude(swLat);
  }
  return false;
};
