import { Buttons, MUIcon, PanelHeader } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDatasetColumnMetadata, useDatasetColumns } from '../../../hooks';
import * as peApi from '../../../pe.api';
import ColumnPicker from './ColumnPicker';
import { SelectionType } from './ColumnPicker/types';

interface Props {
  dataset: PeTypes.Dataset;
  onCloseRequest: () => void;
}

const FilterableVariablesPanel: React.FC<Props> = ({ dataset, onCloseRequest }) => {
  const queryClient = useQueryClient();
  const [columnsMeta, setColumnsMeta] = useState<PeTypes.DatasetColumnMetadata[]>([]);
  const columnsMetadataQuery = useDatasetColumnMetadata({ datasetId: dataset.id });
  const datasetColumnsQuery = useDatasetColumns({ datasetId: dataset.id });

  useEffect(() => {
    if (!columnsMetadataQuery.query.data) return;
    if (!datasetColumnsQuery.data) return;

    const anyMetadataStoredBefore = columnsMetadataQuery.query.data.length > 0;

    setColumnsMeta(
      datasetColumnsQuery.data
        .filter((datasetColumn) => DatasetTypes.VisibleDataTypes.includes(datasetColumn.type))
        .map((datasetColumn) => {
          return {
            columnId: datasetColumn.uuid,
            datasetId: dataset.id,
            format: '',
            label: '',
            isFilterable:
              columnsMetadataQuery.query.data?.find((x) => x.columnId === datasetColumn.uuid)?.isFilterable ??
              !anyMetadataStoredBefore,
          };
        })
    );
  }, [columnsMetadataQuery.query.data, datasetColumnsQuery.data]);

  const updateColumnMetadataQuery = useMutation({
    mutationFn: (columns: PeTypes.DatasetColumnMetadata[]) => peApi.updateColumnsMetadata(dataset.id, { columns }),
    onSuccess: async () => {
      await columnsMetadataQuery.invalidate();
      await queryClient.invalidateQueries(['dataset-log', dataset.id]);
      onCloseRequest();
    },
  });

  const handleSelectionChange = (data: SelectionType | SelectionType[]) => {
    const selection = data as SelectionType[]; // as allow multiple is active
    setColumnsMeta(columnsMeta.map((x, idx) => ({ ...x, isFilterable: selection[idx][1] })));
  };

  const handleSaveFilters = () => updateColumnMetadataQuery.mutate(columnsMeta);

  const selectedColumnIds = columnsMeta.filter((x) => x.isFilterable).map((x) => x.columnId);

  const isLoading =
    datasetColumnsQuery.isLoading ||
    datasetColumnsQuery.isIdle ||
    columnsMetadataQuery.query.isLoading ||
    columnsMetadataQuery.query.isIdle;

  return (
    <div className="flex h-full w-[447px] flex-col border border-gray-200 bg-white">
      <PanelHeader icon={<MUIcon name="rubric" />} title="Filterable Variables" onClose={onCloseRequest} />
      {isLoading && <div>Loading...</div>}
      {datasetColumnsQuery.data && (
        <ColumnPicker
          columns={datasetColumnsQuery.data}
          selectedIds={selectedColumnIds}
          datasetId={dataset.id}
          allowMultiSelection
          onChange={handleSelectionChange}
        />
      )}
      <div className="flex items-center justify-end border-t border-gray-300 py-3 px-6">
        <Buttons.Primary disabled={updateColumnMetadataQuery.isLoading} onClick={handleSaveFilters}>
          Save
        </Buttons.Primary>
      </div>
    </div>
  );
};

export default FilterableVariablesPanel;
