import React from 'react';
import { LogoText } from '../../shared';
import NewPasswordForm from './NewPasswordForm';

interface Props {
  accessCode: string;
}

const NewPassword: React.FC<Props> = ({ accessCode }) => {
  return (
    <div className="flex h-full w-full flex-col items-center bg-gray-50 pt-[60px]">
      <LogoText width={148} height={38} />
      <div className="items-left mt-8 flex h-[452px] w-[460px] flex-col justify-center gap-14 rounded-xl border border-gray-200 bg-white px-8 shadow-md">
        <div className="flex flex-col gap-6">
          <p className="text-2xl font-bold">Reset password</p>
          <p className="text-base text-gray-600">Please set a new password for your account.</p>
        </div>
        <NewPasswordForm accessCode={accessCode} />
      </div>
    </div>
  );
};

export default NewPassword;
