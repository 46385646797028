import * as GeoJSON from 'geojson';

export type DataType = 'number' | 'boolean' | 'text' | 'unknown' | 'empty';
export type ColumnType = { name: string; type: DataType; order: number };
export type RowType = unknown[];

export enum ParserError {
  UNKNOWN = 'UNKNOWN',
  INPUT_TOO_LARGE = 'INPUT_TOO_LARGE',
  INPUT_MALFORMED = 'INPUT_MALFORMED',
  INPUT_TYPE_NOT_SUPPORTED = 'INPUT_TYPE_NOT_SUPPORTED',
  PARSING_FAILED = 'PARSING_FAILED',
  UNZIPPING_FAILED = 'UNZIPPING_FAILED',
  EMPTY = 'EMPTY',
  AMBIGUOUS_CONTENT = 'AMBIGUOUS_CONTENT',
  UNSUPPORTED_CONTENT = 'UNSUPPORTED_CONTENT',
}

export interface WorkerPayload {
  file: File;
  config: Config;
}

export enum Extension {
  CSV = 'csv',
  ZIP = 'zip',
  KML = 'kml',
  JSON = 'json',
  SHP = 'shp',
  DBF = 'dbf',
  PRJ = 'prj',
  SHX = 'shx',
  CPG = 'cpg',
}

export interface ReadResult {
  columns: null | ColumnType[];
  rows: null | RowType[];
  errors: ParserError[];
  totalRowsCount: number;
  featureCollection?: GeoJSON.FeatureCollection;
}

export interface Result extends ReadResult {
  sourceFile: File;
  files?: File[];
}

export interface CsvConfig {
  firstRowIsHeader: boolean;
}

export interface DbfConfig {
  placeholder?: boolean;
}

export interface KmlConfig {
  placeholder?: boolean;
}

export interface GeoJsonConfig {
  placeholder?: boolean;
}

export interface Config {
  maxFileSizeInBytes: number;
  sampleSize?: number;
  kml?: KmlConfig;
  dbf?: DbfConfig;
  csv?: CsvConfig;
  geoJson?: GeoJsonConfig;
}
