import axios from 'axios';
import { GeoJSONFeature } from 'maplibre-gl';

const ApiBase = axios.create({
  baseURL: process.env['NX_API_MAPBOX'],
});

const API_KEY = 'TEST'; // TODO: through backend prxy

export const getDrivingIsochrone = (
  lat: number,
  lng: number,
  minutes: number,
  polygons: boolean
): Promise<GeoJSONFeature> =>
  ApiBase.get<GeoJSONFeature>(`/isochrone/v1/mapbox/driving/${lng},${lat}`, {
    params: {
      polygons,
      access_token: API_KEY,
      contours_minutes: minutes,
    },
  }).then((r) => r.data);
