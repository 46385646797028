import { useSystemFilterSets } from '@platform/shared/hooks';
import { MetadataTypes, ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';

export interface Props {
  value: number;
  filterSetName: string | null;
  selection: ProjectTypes.VariableSelection;
}

const EnumeratedValue: React.FC<Props> = ({ value, selection, filterSetName }) => {
  const { get: getSystemFilterSets } = useSystemFilterSets();
  const [filterSet, setFilterSet] = useState<MetadataTypes.FilterSet>();
  const [loading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadFilterSets = async () => {
      try {
        setIsLoading(true);
        const filterSets = await getSystemFilterSets(selection.surveyName, selection.datasetAbbrev);
        setFilterSet(filterSets.find((x) => x.name === filterSetName)?.filterSets[0]);
      } finally {
        setIsLoading(false);
      }
    };
    loadFilterSets();
  }, []);

  if (loading) return <>?</>;
  if (!filterSet) return <>{value}</>;

  return <>{filterSet.filters.find((x) => x.valueNum?.toString() === value.toString())?.label}</>;
};

export default EnumeratedValue;
