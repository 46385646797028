import { PeTypes, ProjectTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import DatasetTypeIcon from '../../../../../../libs/shared/ui/src/lib/DatasetTypeIcon';

type Props = {
  type: ProjectTypes.LayerType | undefined;
  progress: number;
  status: PeTypes.DatasetLogStatus | PeTypes.DatasetImportStatus;
  iconColor?: string;
  backgroundColor?: string;
};

const DatasetIcon: React.FC<Props> = ({
  type,
  progress,
  status,
  iconColor,
  backgroundColor = 'bg-primary-500',
}: Props) => {
  let classes = backgroundColor;
  let iconColorClasses = iconColor;

  if (status === PeTypes.DatasetLogStatus.FAILED) {
    classes = 'bg-gray-400';
    iconColorClasses = 'white';
  }

  let progressValue = 0;

  if (status === PeTypes.DatasetLogStatus.RUNNING || status === PeTypes.DatasetLogStatus.PENDING) {
    classes = 'bg-gray-300';
    progressValue = progress;
    iconColorClasses = 'white';
  }

  const progressStyle = {
    background: `conic-gradient(blue ${progressValue}%, transparent ${progressValue}% 100%)`,
  };

  return (
    <div className="flex items-center justify-center pt-0.5">
      <div className="relative h-[36px] w-[36px]">
        <div
          className={classNames(
            'bg-primary-100 absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-full opacity-100',
            {
              'bg-transparent':
                status !== PeTypes.DatasetLogStatus.RUNNING && status !== PeTypes.DatasetLogStatus.PENDING,
            }
          )}
        >
          <div
            className={classNames(
              'absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-full opacity-100'
            )}
            style={progressStyle}
          >
            {type ? (
              <DatasetTypeIcon
                type={type}
                backgroundClasses={`${classes} h-[32px] w-[32px] p-1.5 justify-center items-center flex`}
                iconColor={iconColorClasses}
              />
            ) : (
              <div className="h-[32px] w-[32px] rounded-full bg-white p-1.5"></div>
            )}
          </div>
        </div>
        {status === PeTypes.DatasetLogStatus.FAILED && (
          <div className="absolute top-[2px] right-[-6px] z-10 flex h-[18px] w-[18px] items-center justify-center rounded-full border-2 border border-white bg-rose-700 text-[8px] text-white">
            !
          </div>
        )}
      </div>
    </div>
  );
};

export default DatasetIcon;
