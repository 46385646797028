import { PanelHeader } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { SelectionType } from '../../Datasets/Dataset/ColumnPicker/types';
import ExpandableContainer from '../../shared/ExpandableContainer';
import WorkingLayerTooltipEditor from './Tooltip/Editor/WorkingLayerTooltipEditor';
import WorkingLayerStyle from './WorkingLayerStyle';

const TOOLTIP_PROTOTYPE: ProjectTypes.WorkingLayerTooltip = {
  action: 'click',
  title: undefined,
  content: [],
  enabled: false,
};

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  isPublished: boolean;
  onUpdate: (workingLayer: ProjectTypes.WorkingLayerSpec) => void;
  onCloseRequest: () => void;
}

const WorkingLayerEdit: React.FC<Props> = ({ onCloseRequest, isPublished, workingLayer, onUpdate }) => {
  const updateMapTooltip = (tooltip: ProjectTypes.WorkingLayerTooltip) => {
    const updatedWorkingLayer = { ...workingLayer };
    updatedWorkingLayer.tooltip = tooltip;
    onUpdate(updatedWorkingLayer);
  };

  useEffect(() => {
    if (workingLayer.tooltip == null) {
      const { ...newWorkingLayer } = workingLayer;
      newWorkingLayer.tooltip = TOOLTIP_PROTOTYPE;
      onUpdate(newWorkingLayer);
    }
  }, [workingLayer, onUpdate]);

  const handleTitleVariableChange = (payload: SelectionType | SelectionType[]) => {
    if (!workingLayer.tooltip) return;
    const selection = payload as SelectionType;
    const modifiedTooltip = { ...workingLayer.tooltip };
    if (workingLayer.tooltip?.title?.name !== selection[0].title) {
      modifiedTooltip.title = {
        id: selection[0].uuid,
        name: selection[0].title,
      };
      updateMapTooltip(modifiedTooltip);
    }
  };

  const handleContentAdd = (payload: SelectionType) => {
    if (!workingLayer.tooltip) return;
    const selection = payload;
    const modifiedTooltip = { ...workingLayer.tooltip };
    if (workingLayer.tooltip.content.find((item) => item.id === selection[0].uuid) == null) {
      const newContentItem: ProjectTypes.TooltipContent = {
        id: selection[0].uuid,
        name: selection[0].title,
        label: selection[0].title,
      };
      modifiedTooltip.content = [...modifiedTooltip.content, newContentItem];
    }
    updateMapTooltip(modifiedTooltip);
  };

  const handleContentRemove = (id: string) => {
    if (!workingLayer.tooltip) return;
    const modifiedTooltip = { ...workingLayer.tooltip };
    modifiedTooltip.content = modifiedTooltip.content.filter((item) => item.id !== id);
    updateMapTooltip(modifiedTooltip);
  };

  const handleContentLabelChange = (id: string, label: string) => {
    if (!workingLayer.tooltip) return;

    const updatedTooltip = {
      ...workingLayer.tooltip,
      content: workingLayer.tooltip.content.map((item) => (item.id === id ? { ...item, label: label } : item)),
    };

    updateMapTooltip(updatedTooltip);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !workingLayer.tooltip) return;
    const updatedItems = Array.from(workingLayer.tooltip.content);

    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    const modifiedTooltip = { ...workingLayer.tooltip };
    modifiedTooltip.content = updatedItems;
    updateMapTooltip(modifiedTooltip);
  };

  const handleTooltipToggle = (value: boolean) => {
    if (!workingLayer.tooltip) return;
    const modifiedTooltip = { ...workingLayer.tooltip, enabled: value };
    updateMapTooltip(modifiedTooltip);
  };

  const handleTitleRemove = () => {
    if (!workingLayer.tooltip) return;
    const modifiedTooltip = { ...workingLayer.tooltip };
    modifiedTooltip.title = undefined;
    updateMapTooltip(modifiedTooltip);
  };

  return (
    <div className="relative flex h-full min-h-0 w-full flex-col gap-2">
      <PanelHeader title={`Edit ${workingLayer.name}`} onBack={onCloseRequest} onClose={onCloseRequest} />
      <div className="flex flex-col gap-3 overflow-y-auto bg-gray-100">
        <ExpandableContainer
          title="Style"
          children={<WorkingLayerStyle workingLayer={workingLayer} onUpdate={onUpdate} />}
          iconClasses="bg-gray-100 rounded-[999px] p-1.5"
          containerClasses="bg-white px-6 py-3"
          expandedByDefault={true}
          hasToggle={false}
        />
        <ExpandableContainer
          title="Popup"
          children={
            <WorkingLayerTooltipEditor
              workingLayer={workingLayer}
              onTitleVariableChange={handleTitleVariableChange}
              onContentAdd={handleContentAdd}
              onContentRemove={handleContentRemove}
              onContentLabelChange={handleContentLabelChange}
              onDragEnd={handleDragEnd}
              onTitleVariableRemove={handleTitleRemove}
            />
          }
          iconClasses="bg-gray-100 rounded-[999px] p-1.5"
          containerClasses="bg-white px-6 py-3"
          isToggleActive={workingLayer.tooltip?.enabled === true}
          onToggle={handleTooltipToggle}
          disabledMessage={!isPublished ? 'Publish dataset to enable popups' : null}
        />
      </div>
    </div>
  );
};

export default WorkingLayerEdit;
