import { ProjectTypes } from '@platform/types';
import React from 'react';

export interface Props {
  cutPoints: ProjectTypes.CutPoint[];
  title?: React.ReactNode | string;
  insufficientData?: { label: string; color: string };
  showLabels?: boolean;
}

const HorizontalLegend: React.FC<Props> = ({ cutPoints, showLabels = false }) => {
  const visibleCutPoints = cutPoints.filter((cp) => cp.displayInLegend);

  const stopsOnMap = visibleCutPoints.map((cutPoint, index) => {
    const isFirstItem = index === 0;
    const isLastItem = index === cutPoints.length - 1;

    return (
      <div
        className="flex grow flex-col items-center"
        title={cutPoint.title}
        key={`${cutPoint.color}-${cutPoint.from}-${cutPoint.to}`}
      >
        <div
          className="h-[12px] w-full"
          style={{
            backgroundColor: cutPoint.color,
            borderTopRightRadius: `${isLastItem ? '999px' : ''}`,
            borderBottomRightRadius: `${isLastItem ? '999px' : ''}`,
            borderTopLeftRadius: `${isFirstItem ? '999px' : ''}`,
            borderBottomLeftRadius: `${isFirstItem ? '999px' : ''}`,
          }}
        />
      </div>
    );
  });

  const labels = generateLabels(visibleCutPoints);

  return (
    <div className="flex min-w-[200px]">
      <div className="flex w-full flex-col">
        <div className="flex">{stopsOnMap}</div>
        {showLabels && <div className={`flex py-1`}>{labels}</div>}
      </div>
    </div>
  );
};

export default HorizontalLegend;

const generateLabels = (cutPoints: ProjectTypes.CutPoint[]) => {
  const labelsToBeDisplayed: string[] = [];

  // first
  labelsToBeDisplayed.push(cutPoints[0].title);

  if (cutPoints.length > 1) {
    if (cutPoints.length > 2) {
      // middle
      labelsToBeDisplayed.push(cutPoints[Math.floor(cutPoints.length / 2)].title);
    }
    // last
    labelsToBeDisplayed.push(cutPoints[cutPoints.length - 1].title);
  }

  return (
    <div className={`flex w-full ${cutPoints.length === 1 ? 'justify-center' : 'justify-between'}`}>
      {labelsToBeDisplayed.map((label, idx) => (
        <span key={`${idx}-${label}`} className="truncate text-xs font-light">
          {label}
        </span>
      ))}
    </div>
  );
};
