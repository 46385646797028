import React from 'react';

const InvalidFilterRule: React.FC = () => {
  return (
    <div className="bg-primary-50 w-full rounded-bl-lg rounded-br-lg border border-t-0 border-gray-200 px-4 py-3 text-xs text-gray-700">
      Input a value and press enter to apply it
    </div>
  );
};

export default InvalidFilterRule;
