import { PeTypes } from '@platform/types';
import React, { useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  DragUpdate,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { UseMutationResult } from 'react-query';
import { SlideMenuOption } from '.';
import SlideListItem from './SlideListItem';

const SLIDE_HEIGHT = 102; // 86px height of the slide plus 16px gap between slides

interface Props {
  currentSlideId: string;
  isEditMode: boolean;
  onJumpTo: (slideId: string) => void;
  thumbsInMemory?: Record<string, string>;
  slides: PeTypes.StorySlide[];
  reorderSlideMutation: UseMutationResult<string, unknown, PeTypes.NewSlideOrdering, unknown>;
  getSlideMenuOptions: (slide: PeTypes.StorySlide, index: number) => SlideMenuOption[];
}

// Component that uses react-beatuful-dnd for drag and drop of the slides with behavior as in Google slides
const SlideListDroppable: React.FC<Props> = ({
  slides,
  currentSlideId,
  thumbsInMemory = {},
  onJumpTo,
  isEditMode,
  reorderSlideMutation,
  getSlideMenuOptions,
}) => {
  const [dropDestination, setDropDestination] = useState<number>(-1);
  const listRef = useRef<HTMLDivElement>(null);

  const onDragEnd = (result: DropResult) => {
    if (result.destination == null) return;

    const draggableSlideIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (slides[draggableSlideIndex].id !== currentSlideId) {
      onJumpTo(slides[draggableSlideIndex].id);
    }

    let startSlideId;
    let endSlideId;

    if (destinationIndex === 0) {
      startSlideId = null;
      endSlideId = slides[0].id;
    } else if (destinationIndex === slides.length - 1) {
      startSlideId = slides[slides.length - 1].id;
      endSlideId = null;
    } else if (draggableSlideIndex < destinationIndex) {
      const startIndex = destinationIndex;
      startSlideId = slides[startIndex].id;
      endSlideId = slides[startIndex + 1].id;
    } else if (draggableSlideIndex > destinationIndex) {
      const endIndex = destinationIndex;
      endSlideId = slides[endIndex].id;
      startSlideId = slides[endIndex - 1].id;
    } else {
      return;
    }

    reorderSlideMutation.mutate({
      slideId: result.draggableId,
      endId: endSlideId,
      startId: startSlideId,
    });
  };

  const { clientHeight, scrollHeight } = listRef?.current ?? { clientHeight: 0, scrollHeight: 0 };
  const underlineWidth = clientHeight < scrollHeight ? 'w-[144px]' : 'w-[150px]'; // if we have scrollbar than the width of the thumb gets smaller

  return (
    <div ref={listRef} className="overflow-auto py-3">
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragUpdate={(update: DragUpdate) => setDropDestination(update.destination ? update.destination.index : -1)}
      >
        <Droppable droppableId="droppable">
          {(provided, dropSnapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col gap-4 pr-9">
              {slides.map((slide, index) => {
                const { draggingOverWith, draggingFromThisWith } = dropSnapshot;
                // Function used for styling the dnd behavior as in Google slides
                // For dragging we take the y position from the onDrag event itself and define x to 0 since we want dragging to be just by Y axis.
                // Also, when dropping the element translate x to 0.5 due to possible freezing error if we drop the draggable at exactly the same x position
                // similar situation described here: https://github.com/atlassian/react-beautiful-dnd/issues/1662
                const getStyle = (
                  style: DraggingStyle | NotDraggingStyle | undefined,
                  snapshot: DraggableStateSnapshot
                ) => {
                  const { isDragging, dropAnimation } = snapshot;
                  const transform = style?.transform ?? `translate(0px, 0px)`;
                  let y = parseFloat(transform.substring(transform.indexOf(',') + 1)); // get y from translation
                  let x = 0;
                  if (dropAnimation) {
                    // If dropDestination equals to -1, it means we are not dropping in the droppable area, so the draggable slide
                    // should be returned into his inital position. Otherwise, to ensure a smooth drop animation without oscillations,
                    // we calculate the appropriate y-coordinate.
                    y = dropDestination === -1 ? 0 : (dropDestination - index) * SLIDE_HEIGHT;
                    x = 0.5;
                  }

                  return {
                    ...style,
                    transform: isDragging ? `translate(${x}px, ${y}px)` : undefined, // we don't want other slides to be moved when not dragging them
                  };
                };
                return (
                  <div className="relative" key={`slide-item-${slide.id}`} id={`slide-item-${index}`}>
                    {draggingFromThisWith === slide.id && provided.placeholder}
                    <Draggable key={slide.id} draggableId={slide.id} index={index} isDragDisabled={!isEditMode}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getStyle(provided.draggableProps.style, snapshot)}
                          className="relative"
                        >
                          <SlideListItem
                            key={slide.id}
                            index={index}
                            slide={slide}
                            active={slide.id === currentSlideId}
                            onClick={(slide) => onJumpTo(slide.id)}
                            options={getSlideMenuOptions(slide, index)}
                            currentSlideThumb={thumbsInMemory[slide.id]}
                            isEditMode={isEditMode}
                            isDragging={snapshot.isDragging}
                          />
                        </div>
                      )}
                    </Draggable>
                    {draggingOverWith && index === dropDestination && (
                      <div className={`bg-primary-400 absolute -bottom-2 left-9 h-[2px] ${underlineWidth}`} />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SlideListDroppable;
