import { Hooks, Utils } from '@platform/maplibre';
import { ProjectTypes } from '@platform/types';
import { Map, MapGeoJSONFeature } from 'maplibre-gl';
import React, { useMemo, useState } from 'react';
import DataTooltipContent from './DataToolipContent';

const { useFeaturesUnderMouse2 } = Hooks;

const SELECTION_LABEL_PROP = 'Geo_QName'; // TODO: *assumption alert* label is Geo_QName!

export interface Props {
  mapObjects: ProjectTypes.MapObject[];
}

const CompareDataTooltip: React.FC<Props> = ({ mapObjects }) => {
  const [highlightedFeaturesPerMap, setHighlightedFeaturesPerMap] = useState<
    {
      ref: Map;
      features: MapGeoJSONFeature[];
    }[]
  >([]);

  const handleMouseOver = useMemo(
    () =>
      (
        features: {
          ref: Map;
          features: MapGeoJSONFeature[];
        }[]
      ) =>
        setHighlightedFeaturesPerMap(features),
    [setHighlightedFeaturesPerMap]
  );

  const mapsToLookInto = useMemo(
    () =>
      mapObjects
        .filter((mapObj) => mapObj.ref != null)
        .map((mapObj) => ({
          ref: mapObj.ref as Map,
          sourceLayerIds: [
            Utils.getDataLayerFingerprint(mapObj.styleSpec.dataLayer),
            Utils.getFilteredOutDataLayerId(mapObj.styleSpec.dataLayer),
          ],
        })),
    [mapObjects]
  );

  useFeaturesUnderMouse2(mapsToLookInto, 'mousemove', handleMouseOver);

  if (!highlightedFeaturesPerMap.length) return null;
  const tooltips: React.ReactNode[] = [];
  const titles: string[] = [];

  highlightedFeaturesPerMap.forEach((x) => {
    const mapObj = mapObjects.find((mapObj) => mapObj.ref === x.ref);
    if (mapObj && x.features.length > 0) {
      const [feature] = x.features;
      const title = Object.entries(feature.properties).find(
        ([key, value]) => key.toLowerCase() === SELECTION_LABEL_PROP.toLowerCase()
      )?.[1];
      if (title) {
        titles.push(title);
      }
      tooltips.push(<DataTooltipContent key={mapObj.id} mapObj={mapObj} feature={feature} />);
    } else {
      titles.push('');
      tooltips.push(<div className="px-2 text-xs italic">Data not available</div>);
    }
  });

  const sameGeography = mapObjects.every(
    (mapObject) =>
      mapObject.styleSpec.dataLayer.preferredSummaryLevelId ===
      mapObjects[0].styleSpec.dataLayer.preferredSummaryLevelId
  );

  const sameData = mapObjects.every(
    (mapObject) =>
      Utils.getDataLayerFingerprint(mapObject.styleSpec.dataLayer) ===
      Utils.getDataLayerFingerprint(mapObjects[0].styleSpec.dataLayer)
  );

  const [primaryTooltip, secondaryTooltip] = tooltips;
  const [primaryTitle] = titles;

  // compare is enabled only if:
  // 1. user is looking at the same geo level in the same survey
  // 2. it is not the same data on both map
  const showCompareTooltip = sameGeography && !sameData && secondaryTooltip != null;

  return (
    <div className="flex min-w-[120px] max-w-[520px] flex-col gap-2 rounded-md bg-white py-2 text-sm text-gray-700 shadow-md empty:hidden">
      {primaryTitle && <div className="px-3 font-semibold">{primaryTitle}</div>}
      {primaryTooltip}
      {showCompareTooltip && (
        <>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1 rounded-r-md bg-gray-200 px-4 py-1 text-xs text-gray-600">
              Compared to:
            </div>
            <div className="flex h-[1px] flex-grow bg-gray-300" />
          </div>
          {tooltips[1]}
        </>
      )}
    </div>
  );
};

export default CompareDataTooltip;
