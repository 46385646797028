import { useVariableMetadata } from '@platform/shared/hooks';
import { ProjectTypes } from '@platform/types';
import React from 'react';

interface Props {
  def: ProjectTypes.VariableSelection;
  universeOnly?: boolean;
  classNames: string;
}

const FilterVariableInfo: React.FC<Props> = ({ def, classNames = '', universeOnly = false }) => {
  const { surveyName, datasetAbbrev, tableGuid, variableGuid } = def;
  const { isLoading, variable, universeVariablesMetadata } = useVariableMetadata({
    surveyName,
    datasetAbbreviation: datasetAbbrev,
    tableUuid: tableGuid,
    variableUuid: variableGuid,
  });

  if (isLoading) return <>Loading variable meta...</>;

  if (universeOnly) return <span className={classNames}>{universeVariablesMetadata[0]?.label}</span>;

  const content = universeVariablesMetadata[0]
    ? `${universeVariablesMetadata[0].label}: ${variable?.label}`
    : `${variable?.label}`;

  return <span className={classNames}>{content}</span>;
};

export default FilterVariableInfo;
