import { Utils } from '@platform/maplibre';
import { ProjectTypes } from '@platform/types';
import { MapGeoJSONFeature } from 'maplibre-gl';
import React from 'react';
import DataTooltipLine from './DataTooltipLine';
import FilteredDataTooltipLine from './FilteredDataTooltipLine';

export interface Props {
  mapObj: ProjectTypes.MapObject;
  feature: MapGeoJSONFeature;
}

const DataTooltipContent: React.FC<Props> = ({ mapObj, feature }) => {
  const { styleSpec } = mapObj;
  const { dataLayer } = styleSpec;
  const { selection, rendering, filtersByData } = dataLayer;
  const filteredOutDataLayerId = Utils.getFilteredOutDataLayerId(dataLayer);
  const sameUniverseForAllVariables = new Set(selection.map((x) => x.universeVariableUuid)).size === 1;
  const isFilteredOut = feature.layer.id === filteredOutDataLayerId;
  const filterValue = filtersByData?.filters;

  const filters = filterValue
    ?.filter((f) => f.value.toString().trim().length)
    .map((f, idx) => (
      <FilteredDataTooltipLine
        key={`${f.variable.variableGuid}_${idx}`}
        filter={f}
        feature={feature}
        selection={f.variable}
      />
    ));

  return (
    <>
      <div className="flex min-w-[300px] flex-col">
        {selection.map((s, idx) => (
          <DataTooltipLine
            key={s.variableGuid}
            feature={feature}
            selection={s}
            isMultiSelection={selection.length > 1}
            rendering={rendering[idx]}
            allowPercents={sameUniverseForAllVariables}
            isLastLine={idx === selection.length - 1}
          />
        ))}
      </div>
      {isFilteredOut && !!filters?.length && (
        <div className="flex w-full flex-col gap-2 border-t px-3 py-3">
          <div className="text-xs font-semibold">Area doesn't meet the selected criteria:</div>
          <div className="shadow-1 rounded-md bg-white/10 p-2">{filters}</div>
        </div>
      )}
      {!isFilteredOut && !!filters?.length && !!filterValue?.length && (
        <div className="flex w-full flex-col gap-2 border-t px-3 py-3">
          <div className="text-xs font-semibold">Area meets the set criteria:</div>
          <div className="shadow-1 rounded-md bg-white/10 p-2">{filters}</div>
        </div>
      )}
    </>
  );
};

export default DataTooltipContent;
