import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export interface Props {
  isAllowed: boolean;
  redirectPath: string;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ isAllowed, redirectPath, children }) => {
  const location = useLocation();

  if (!isAllowed) {
    const { pathname, search, hash } = location;
    return <Navigate to={redirectPath} replace state={{ from: `${pathname}${search}${hash}` }} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
