import { Buttons, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import Compare from './Compare';

interface Props {
  classNames?: string;
}

const MoreMenu: React.FC<Props> = ({ classNames = '' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="relative w-full ">
      <div className="flex flex-col items-center gap-1 text-white">
        <Buttons.Soft
          icon={<MUIcon name="more_horiz" />}
          className={`h-[32px] w-[32px] bg-white/10 text-white transition-all ${classNames}`}
          onClick={(event) => {
            event.stopPropagation();
            setIsOpen(true);
          }}
        />
        <span className="text-[10px] text-gray-300">More</span>
      </div>
      {isOpen && (
        <>
          <button className="fixed inset-0 z-[9990] bg-black/30" onClick={() => setIsOpen(false)} />
          <div className="absolute top-0 left-full z-[9991] z-50 m-6 flex w-[268px] -translate-y-[80px] flex-col gap-6 rounded-lg bg-white px-6 py-4 shadow-lg">
            <Compare onCloseRequest={() => setIsOpen(false)} />
          </div>
        </>
      )}
    </div>
  );
};

export default MoreMenu;
