import { MapGeoJSONFeature } from 'maplibre-gl';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  feature: MapGeoJSONFeature;
  labelProp: string;
  valueProp: string;
  variable: string;
}

const SimpleDataTooltip: React.FC<Props> = ({ feature, variable, labelProp, valueProp }) => {
  return (
    <Outer>
      <Geo>{feature.properties[labelProp]}</Geo>
      <Value>{feature.properties[valueProp]?.toLocaleString() ?? 'N/A'}</Value>
      <VariableInfo>{variable}</VariableInfo>
    </Outer>
  );
};

export default SimpleDataTooltip;

const Outer = styled.div`
  min-width: 180px;
  background-color: #333;

  border-radius: 6px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: white;
`;

const Geo = styled.div`
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
const Value = styled.div`
  font-size: 13px;
  padding: 10px;
`;

const VariableInfo = styled.div`
  font-size: 13px;
  opacity: 0.8;
  padding: 0px 10px 10px 10px;
`;
