import { MUIcon } from '@platform/shared/ui';
import { DatasetTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import { ReactComponent as Decimal } from '../../../.././../assets/decimal.svg';

export const MAX_TILES_COLUMN_NAME_LENGTH = 36;

const getStringByteSize = (s: string) => encodeURI(s).split(/%..|./).length - 1;

export const columnFormatter = (v: unknown, format: string | undefined) => {
  if (format?.trim().length) {
    return NumberFormatter.format(v, format);
  }
  return `${v}`;
};

export const columnNameValidator =
  (columnTitles: string[] = [], oldTitle?: string) =>
  (newTitle = '') => {
    if (newTitle.trim() !== newTitle) {
      return 'Column name cannot contain leading or trailing spaces.';
    } else if (columnTitles.find((c) => c === newTitle && oldTitle !== newTitle)) {
      return 'A column with this name already exists.';
    } else if (getStringByteSize(newTitle) > MAX_TILES_COLUMN_NAME_LENGTH) {
      return 'Column name too long';
    } else if (newTitle !== '' && !/^[^,]+$/.test(newTitle)) {
      return 'Column name cannot contain comma character.';
    } else if (newTitle === 'ID') {
      return 'ID is a reserved column name';
    }
    return true;
  };

export const canParseToNumber = (value: string): boolean => {
  // Try to parse the value
  const parsed = parseFloat(value);

  // Check if the parsed value is a number and the input is not just a blank string
  return !isNaN(parsed) && value.trim() !== '';
};

export const isInteger = (value: string): boolean => {
  const num = parseInt(value, 10);
  return !isNaN(num) && value === num.toString();
};

export const getSuffixIcon = (type: number | string) => {
  if (
    type === DatasetTypes.DataType.DOUBLE ||
    type === 'DOUBLE' ||
    type === DatasetTypes.DataType.INTEGER ||
    type === 'INTEGER'
  ) {
    return <Decimal className="h-[24px] w-[24px]" />;
  }

  let icon = 'match_case';
  switch (type) {
    case DatasetTypes.DataType.BOOLEAN:
    case 'BOOLEAN':
      icon = 'checklist_rtl';
      break;
    default:
      break;
  }
  return <MUIcon className="flex !font-[24-px] text-gray-400" name={icon} />;
};
