import { Switch } from '@headlessui/react';
import React from 'react';

interface Props {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  labelClasses?: string;
  toggleOuterClasses?: string;
  toggleInnerClasses?: string;
  onChange?: (v: boolean) => void;
}

const Toggle: React.FC<Props> = ({
  label,
  onChange,
  checked,
  disabled,
  labelClasses,
  toggleOuterClasses,
  toggleInnerClasses,
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={checked}
        onChange={() => onChange?.(!checked)}
        disabled={disabled}
        className={`relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${toggleOuterClasses} ${
          checked ? 'bg-primary-600' : 'bg-gray-200'
        }`}
      >
        <span
          aria-hidden="true"
          className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${toggleInnerClasses} ${
            checked ? 'translate-x-5' : 'translate-x-0'
          }`}
        />
      </Switch>
      <Switch.Label as="span" className={`text-md ml-3 font-medium text-gray-900 ${labelClasses}`}>
        {label}
      </Switch.Label>
    </Switch.Group>
  );
};

export default Toggle;
