import { Dropdowns, Inputs, Modal } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useWorkspaceContext } from '../../../contexts/WorkspaceContext';
import { useDatasetColumns } from '../../../hooks';
import * as peApi from '../../../pe.api';
import { canParseToNumber, columnNameValidator, isInteger } from './DatasetTable/utils';

const TYPE_OPTIONS: Dropdowns.DropdownOption[] = [
  { value: DatasetTypes.DataType.INTEGER, name: 'Integer' },
  { value: DatasetTypes.DataType.DOUBLE, name: 'Decimal' },
  { value: DatasetTypes.DataType.TEXT, name: 'Text' },
];

type Inputs = {
  columnName: string;
  dataType:
    | DatasetTypes.DataType.INTEGER
    | DatasetTypes.DataType.DOUBLE
    | DatasetTypes.DataType.TEXT
    | DatasetTypes.DataType.BOOLEAN;
  defaultValue: string;
};

interface Props {
  datasetId: string;
  onCloseRequest: () => void;
}

const AddDatasetColumnModal: React.FC<Props> = ({ onCloseRequest, datasetId }) => {
  const columnsQuery = useDatasetColumns({ datasetId, itemsPerPage: 99999 });
  const { activeWorkspace } = useWorkspaceContext();
  const queryClient = useQueryClient();

  const addColumnMutation = useMutation({
    mutationFn: (payload: PeTypes.AddColumnPayload) => peApi.addDatasetColumn(datasetId, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['datasets', activeWorkspace.id]);
      await queryClient.invalidateQueries(['dataset-log', datasetId]);
      onCloseRequest?.();
    },
  });

  const {
    watch,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { columnName: '', defaultValue: '', dataType: DatasetTypes.DataType.TEXT } });

  const onSubmit: SubmitHandler<Inputs> = (data) => addColumnMutation.mutate(data);
  const watchedType = watch('dataType');

  const columnNameValidatorFunc = columnNameValidator(columnsQuery.data?.map((col) => col.title));

  const defaultValueValidatorFunc = (value: string) => {
    if (value.length && watchedType === DatasetTypes.DataType.DOUBLE) {
      return canParseToNumber(value) || 'Invalid default value for decimal type';
    }
    if (value.length && watchedType === DatasetTypes.DataType.INTEGER) {
      return isInteger(value) || 'Invalid default value for integer type';
    }
    return true;
  };

  return (
    <Modal
      title="Add column"
      okLabel="Add"
      okDisabled={addColumnMutation.isLoading}
      className="w-[448px]"
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div>
          <label htmlFor="columnName" className="mb-2 block text-sm font-semibold text-gray-900">
            Name
          </label>
          <input
            id="columnName"
            autoFocus
            className="focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none"
            placeholder="e.g. Column Name"
            required
            {...register('columnName', { validate: columnNameValidatorFunc, required: 'Name is required' })}
          />
          {errors.columnName && <span className="text-red-400">{errors.columnName.message}</span>}
        </div>
        <div className="flex gap-6">
          <Controller
            name="dataType"
            control={control}
            rules={{ required: 'Type is required' }}
            render={({ field }) => (
              <div>
                <label htmlFor="dataType" className="mb-2 block text-sm font-bold text-gray-900">
                  Type
                </label>
                <Dropdowns.Dropdown
                  options={TYPE_OPTIONS}
                  value={field.value}
                  triggerClasses="h-[42px]"
                  onSelect={(opt) => field.onChange(opt.value)}
                />
                {errors.dataType && <span className="text-red-400">{errors.dataType.message}</span>}
              </div>
            )}
          />
          <div className="flex flex-grow flex-col">
            <label htmlFor="defaultValue" className="mb-2 block text-sm font-semibold text-gray-900">
              Default Value
            </label>
            <input
              id="defaultValue"
              className="focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none"
              placeholder="e.g. Deafult Value"
              {...register('defaultValue', { validate: defaultValueValidatorFunc })}
            />
            {errors.defaultValue && <span className="text-red-400">{errors.defaultValue.message}</span>}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddDatasetColumnModal;
