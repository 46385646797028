import React from 'react';
import { ImportProgress } from '../ImportWizard';
import FilePreview from './FilePreview';
import FileUpload from './FileUpload';

interface Props {
  file: File | null;
  importData: ImportProgress;
  onSelect: (file: File) => void;
  onFileRemove: () => void;
}

const FileHandler: React.FC<Props> = ({ onSelect, importData, onFileRemove, file }) => {
  const previewReady = file && importData.columnsAsParsed.length > 0 && importData.data.length > 0;

  return previewReady ? (
    <FilePreview file={file} onFileRemove={onFileRemove} importData={importData} />
  ) : (
    <FileUpload onSelect={onSelect} isParsing={importData.isParsing} />
  );
};

export default FileHandler;
