import { MetadataTypes } from '@platform/types';

type ColorPalettesApiType = (input: string) => Promise<MetadataTypes.ColorPalette[]>;

let store: ColorPalettes;

function useSystemPalettes() {
  const initialize = (api: ColorPalettesApiType) => {
    store = new ColorPalettes(api);
  };

  const get = (surveyName: string) => {
    return store.get(surveyName);
  };

  return {
    initialize,
    get,
  };
}

export default useSystemPalettes;

class ColorPalettes {
  _api;
  _loaded: Record<string, MetadataTypes.ColorPalette[]>;

  constructor(api: ColorPalettesApiType) {
    this._api = api;
    this._loaded = {};
  }

  async get(surveyName: string) {
    if (!this._api) return [];

    if (this._loaded[surveyName]) return this._loaded[surveyName];
    try {
      const fetched = await this._api(surveyName);
      this._loaded[surveyName] = fetched;
      return fetched;
    } catch {
      // TODO:
      return [];
    }
  }
}
