import { Modal } from '@platform/shared/ui';
import { DatasetTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React, { useEffect, useState } from 'react';
import { useDatasetColumns } from '../hooks';
import * as peApi from '../pe.api';

interface Props {
  datasetId: string;
  onCloseRequest: () => void;
  hasFilters?: boolean;
}

const DownloadCsvModal: React.FC<Props> = ({ datasetId, onCloseRequest, hasFilters }) => {
  const [selectedColumnsIds, setSelectedColumnsIds] = useState<string[]>([]);
  const datasetColumnsQuery = useDatasetColumns({ datasetId });

  useEffect(() => {
    if (!datasetColumnsQuery.data) return;

    const allColumnIds = datasetColumnsQuery.data
      .filter((col) => DatasetTypes.VisibleDataTypes.indexOf(col.type) !== -1)
      .map((x) => x.uuid);
    setSelectedColumnsIds(allColumnIds);
  }, [datasetColumnsQuery.data]);

  if (datasetColumnsQuery.isIdle || datasetColumnsQuery.isLoading) return null;
  if (datasetColumnsQuery.isError) return null;

  const columns = datasetColumnsQuery.data.slice(2);

  const handleChange = (columnUuid: string) => {
    setSelectedColumnsIds((prevSelection) => {
      const updatedSelection = [...prevSelection];
      const index = prevSelection.findIndex((c) => c === columnUuid);

      if (index > -1) {
        updatedSelection.splice(index, 1);
      } else {
        updatedSelection.push(columnUuid);
      }
      return updatedSelection;
    });
  };

  const onDownload = async () => {
    try {
      await peApi.exportCsv({ datasetId, type: 'csv', columns: selectedColumnsIds });
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      onCloseRequest();
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedColumns = e.target.checked ? columns.map((x) => x.uuid) : [];
    setSelectedColumnsIds(checkedColumns);
  };

  const allColumnsChecked = selectedColumnsIds.length === columns.length;

  return (
    <Modal
      title="Choose columns for download"
      okLabel="Download CSV"
      okDisabled={!selectedColumnsIds.length}
      onDone={onDownload}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
      className="min-[576px]:max-w-lg"
    >
      <div className="flex max-h-96 flex-col gap-4">
        {hasFilters && (
          <div className="-mx-6 flex gap-2 bg-gray-100 p-3 px-6 text-gray-700">
            <div className="text-xs">
              <b className="pr-1">Note: </b>Download includes the original dataset without filters applied
            </div>
          </div>
        )}
        <div className="-mr-6 max-h-[600px] overflow-auto px-2 pr-6">
          <div className="relative flex items-start">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <div className="select-none text-sm text-gray-800">All columns</div>
            </div>
            <div className="ml-3 flex h-6 items-center gap-6">
              <p className="text-xs text-gray-500">
                {allColumnsChecked
                  ? `All (${ValueFormatter.format(
                      selectedColumnsIds.length,
                      ProjectTypes.ValueFormat.FORMAT_NUMBER
                    )} columns)`
                  : `${ValueFormatter.format(
                      selectedColumnsIds.length,
                      ProjectTypes.ValueFormat.FORMAT_NUMBER
                    )} columns`}
              </p>
              <input
                name="select all"
                id="all"
                type="checkbox"
                checked={allColumnsChecked}
                className="text-primary-600 focus:ring-primary-600 h-4 w-4 rounded border-gray-300 hover:cursor-pointer"
                onChange={handleSelectAll}
              />
            </div>
          </div>
          <div className="my-3 h-[1px] w-full bg-gray-300"></div>
          {columns.map((col) => (
            <div key={col.uuid} className="flex w-full justify-between gap-6 py-2 text-sm text-gray-800">
              <p>{col.title}</p>
              <input
                name={col.title}
                type="checkbox"
                checked={selectedColumnsIds.includes(col.uuid)}
                className="text-primary-600 focus:ring-primary-600 h-4 w-4 rounded border-gray-300 hover:cursor-pointer"
                onChange={() => handleChange(col.uuid)}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default DownloadCsvModal;
