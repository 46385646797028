import { Hooks, MapsContext } from '@platform/maplibre';
import { PanelHeader } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import ActiveMapSwitcher from '../Map/ActiveMapSwitcher';
import DataPanel from '../Map/DataPanel';
import { WorkingLayers } from '../Map/WorkingLayers';
import { useMapSidebarContext } from './MapSideBarContext';

const { useMapsContext } = MapsContext;
const { useMapObjectClick } = Hooks;

const Data: React.FC = () => {
  const { state, activeMap, changeActiveMap, changeUseMapHighlight } = useMapsContext();
  const { activeWorkspace } = useWorkspaceContext();
  const { changeActiveView } = useMapSidebarContext();

  useMapObjectClick((mapObj) => {
    changeActiveMap(mapObj.id);
  });

  let activeMapToUse = activeMap;
  let otherMaps: ProjectTypes.MapObject[] = [];

  // by default, all maps are available to switch to
  let mapSwitcherOptions = state.registeredMaps.map((x) => ({ mapId: x.id }));
  let mapSwitcherFallback = undefined;

  // type SIDE is the LOCATIONS mode, so layer settings (data and user data) gets mirrored from the active map to other maps
  if (state.type === 'side') {
    // activeMap is always the left (first) map
    activeMapToUse = state.registeredMaps[0];
    otherMaps = state.registeredMaps.slice(1);
    mapSwitcherOptions = [];
    mapSwitcherFallback = (
      <div className="flex items-center justify-start gap-2">
        <span className="text-xs font-semibold">Note:</span>
        <span className="text-xs">Data selection applies to both maps</span>
      </div>
    );
  }

  const mapsToApplyChanges: ProjectTypes.MapObject[] = [];

  if (activeMapToUse) {
    mapsToApplyChanges.push(activeMapToUse);
  }

  if (otherMaps.length) {
    mapsToApplyChanges.push(...otherMaps);
  }

  return (
    <div className="relative flex h-full w-[460px] flex-col">
      <PanelHeader title="Data Layers" onClose={() => changeActiveView(undefined)}>
        <ActiveMapSwitcher
          activeMapId={activeMap?.id}
          onChange={changeActiveMap}
          options={mapSwitcherOptions}
          fallback={mapSwitcherFallback}
          onToggleHighlight={changeUseMapHighlight}
        />
      </PanelHeader>
      <div className="overflow-auto">
        <div className="w-full overflow-auto bg-white py-6 px-3">
          {mapsToApplyChanges.length > 0 && (
            <DataPanel
              mapObjects={mapsToApplyChanges}
              activeWorkspace={activeWorkspace}
              onCloseRequest={() => changeActiveView(undefined)}
            />
          )}
        </div>
        <div className="h-[8px] w-full shrink-0 border-t border-b border-gray-200 bg-gray-100"></div>
        <div className="mt-2 flex flex-grow flex-col bg-white py-3 px-3">
          {mapsToApplyChanges.length > 0 && (
            <WorkingLayers mapObjects={mapsToApplyChanges} isEditMode={true} activeWorkspace={activeWorkspace} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Data;
