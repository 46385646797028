import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { toHumanReadableFileSize } from '../../../helpers/helpers';
import BaseError from './BaseError';

interface Props {
  workspaceName: string;
  workspaceStatistics: PeTypes.WorkspaceStatistics;
  onDismiss: () => void;
}

const StorageError: React.FC<Props> = ({ onDismiss, workspaceStatistics, workspaceName }) => {
  const freeStorageSpaceSizeInBytes = Math.max(
    workspaceStatistics.permission.maxStorage - workspaceStatistics.totalInBytes,
    0
  );

  const message = (
    <p>
      <span className="font-semibold">Failed to upload.</span>
      {` The file you're attempting to upload exceeds the storage limit for Workspace ${workspaceName}. You have ${toHumanReadableFileSize(
        freeStorageSpaceSizeInBytes
      )} of storage space remaining. Please reduce the file size or remove some files before attempting the upload again.`}
    </p>
  );

  return (
    <BaseError>
      <div className="flex w-full items-center justify-between gap-2">
        {message}
        <div className="flex h-full items-center justify-center">
          <button onClick={onDismiss}>
            <MUIcon name="close" />
          </button>
        </div>
      </div>
    </BaseError>
  );
};

export default StorageError;
