import { MetadataTypes } from '@platform/types';

export default (variableUuid: string, tableMeta: MetadataTypes.Table) => {
  const universeVariablesMetadata: MetadataTypes.Variable[] = [];

  const primaryVariableMeta = tableMeta.variables.find((x) => x.uuid === variableUuid);

  if (!primaryVariableMeta) return [];

  const { indent, uuid } = primaryVariableMeta;

  if (indent > 0) {
    const indexOfVariable = tableMeta.variables.findIndex((x) => x.uuid === uuid);

    // loop backwards through the variables and identify parent variables of the current selection
    for (let i = indexOfVariable; i > -1; i--) {
      if (tableMeta.variables[i].indent < indent) {
        universeVariablesMetadata.unshift(tableMeta.variables[i]);
      }
      if (tableMeta.variables[i].indent === 0) break;
    }
  }

  return universeVariablesMetadata;
};
