import { Buttons, Drawers, MUIcon, PanelHeader, Spinner } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { ReactComponent as EmptyStateSVG } from '../../../assets/bell.svg';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import * as peApi from '../../pe.api';
import Notification from './Notification';

const PAGE_SIZE = 20;

const Notifications: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { activeWorkspace } = useWorkspaceContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    // data can be set even before the component is mounted, that's why we need to invalidate the query on the component mount
    queryClient.invalidateQueries(['notifications', activeWorkspace.id]);
  }, []);

  const notificationsQuery = useInfiniteQuery<PeTypes.UserNotification<object>[]>(
    ['notifications', activeWorkspace.id],
    async ({ pageParam = 0 }) => {
      const data = await peApi.getUserNotifications(pageParam);

      let newState = data;
      if (data.length > PAGE_SIZE) {
        setHasMore(true);
        newState = newState.slice(0, -1);
      } else {
        setHasMore(false);
      }
      return newState;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return allPages.flat().length;
      },
    }
  );

  const isLoadingInitial = notificationsQuery.isLoading && notificationsQuery.data === undefined;
  const isLoadingMore = notificationsQuery.isLoading && !!notificationsQuery.data;

  const loadMore = () => {
    notificationsQuery.fetchNextPage();
  };

  const markAllAsReadMutation = useMutation(() => peApi.markAllUserNotificationsAsRead(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications', activeWorkspace.id]); // Refresh notifications after marking as read
    },
  });

  const handleDismissAllClick = () => {
    markAllAsReadMutation.mutate();
  };

  // Flatten the pages array into a single array of notifications
  const notifications = notificationsQuery.data ? notificationsQuery.data.pages.flatMap((page) => page) : [];

  const unreadCount = notifications.filter((x) => !x.read).length;
  const hasUnread = unreadCount > 0;

  const emptyScreen = (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <EmptyStateSVG />
        <div className="text-sm text-gray-700">No notifications yet</div>
      </div>
    </div>
  );

  return (
    <>
      <div className="relative">
        <Buttons.Secondary
          data-rh={hasUnread ? `${unreadCount} unread ${pluralize('notification', unreadCount)}` : undefined}
          data-cy={'header-notifications-button'}
          onClick={() => setOpen((prev) => !prev)}
          className="rounded-full bg-transparent p-2.5 text-gray-700 shadow-none ring-0 hover:bg-gray-100 focus:outline-none"
          icon={<MUIcon name="notifications" />}
        />
        {hasUnread && (
          <div className="text-sx bg-primary-600 absolute top-[6px] right-[9px] h-[6px] w-[6px] rounded-full text-white " />
        )}
      </div>
      <Drawers.RightDrawer open={open} onCloseRequest={() => setOpen(false)}>
        <div className="flex h-full w-full select-none flex-col bg-white text-gray-700 shadow-lg md:w-[512px]">
          <PanelHeader
            title="Notifications"
            onClose={() => setOpen(false)}
            action={
              <div className="flex gap-1">
                {notifications.length > 0 && hasUnread && (
                  <Buttons.Secondary
                    className="text-button rounded-full px-4 py-2 text-indigo-600 shadow-none ring-0 hover:bg-gray-100"
                    onClick={handleDismissAllClick}
                  >
                    Mark all as read
                  </Buttons.Secondary>
                )}
              </div>
            }
          />
          <div className="flex min-h-0 w-full flex-col overflow-auto py-4" data-cy={'notifications-panel'}>
            {isLoadingInitial && <Spinner />}
            {notifications.length === 0 && emptyScreen}
            {notifications.map((item) => (
              <Notification key={`${item.id}_${item.updatedAt}`} item={item} />
            ))}
            {hasMore && (
              <Buttons.Secondary
                className="mx-6 my-4 rounded-full bg-gray-100 text-gray-800 ring-gray-200"
                onClick={loadMore}
              >
                {isLoadingMore ? <Spinner /> : 'Load more'}
              </Buttons.Secondary>
            )}
          </div>
        </div>
      </Drawers.RightDrawer>
    </>
  );
};

export default Notifications;
