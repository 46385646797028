import { ProjectTypes } from '@platform/types';
import React from 'react';
import WorkingLayerColorPicker from './WorkingLayerColorPicker';
import WorkingLayerOpacity from './WorkingLayerOpacity';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onUpdate: (workingLayer: ProjectTypes.WorkingLayerSpec) => void;
}

const WorkingLayerStyle: React.FC<Props> = ({ workingLayer, onUpdate }) => {
  return (
    <div className="flex flex-col gap-6 py-3 ">
      <WorkingLayerColorPicker
        workingLayerId={workingLayer.id}
        onChange={(baseColor) => onUpdate({ ...workingLayer, baseColor })}
        value={workingLayer.baseColor}
      />
      <WorkingLayerOpacity
        workingLayer={workingLayer}
        onChange={(opacities) => onUpdate({ ...workingLayer, opacities })}
      />
    </div>
  );
};

export default WorkingLayerStyle;
