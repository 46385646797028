import { useQuery } from 'react-query';
import { NIL as NIL_UUID } from 'uuid';
import * as peApi from '../pe.api';

const useProject = (projectId: string | undefined) =>
  useQuery(['project', projectId], () => peApi.getProject(projectId as string), {
    enabled: projectId != null && projectId !== NIL_UUID,
  });

export default useProject;
