import { CompareLegend, MapLegends, Tooltips, Zoom } from '@platform/shared/integrated-ui';
import { MapComponents } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';
import SeProMap from './SeProMap';

const { Layouts, TooltipAnchor } = MapComponents;

interface Props {
  type: 'single' | 'side' | 'swipe' | 'side-locked';
  mapObjects: ProjectTypes.MapObject[];
  inEditMode: boolean;
  withLegend?: boolean;
}

const SeProMaps: React.FC<Props> = ({ mapObjects, inEditMode, withLegend = true, type }) => {
  const [primaryMapObj, secondaryMapObj] = mapObjects;
  let content;

  if (type === 'swipe' && primaryMapObj != null && secondaryMapObj != null) {
    content = (
      <>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-10">
          <Layouts.SwipeLayout
            leftMap={<SeProMap mapObj={primaryMapObj} inEditMode={inEditMode} />}
            rightMap={<SeProMap mapObj={secondaryMapObj} inEditMode={inEditMode} />}
          />
        </div>
        <TooltipAnchor tooltip={<Tooltips.CompareDataTooltip mapObjects={mapObjects} />} />
        <div className="absolute bottom-8 right-4 z-10 shadow-sm">
          <Zoom mapObj={primaryMapObj} />
        </div>
        {withLegend && (
          <div className="absolute bottom-8 left-4 z-10">
            <MapLegends.CollapsibleWrapper>
              <CompareLegend mapObjects={mapObjects} isInEditMode={inEditMode} />
            </MapLegends.CollapsibleWrapper>
          </div>
        )}
      </>
    );
  }

  if (type === 'side-locked' && primaryMapObj != null && secondaryMapObj != null) {
    content = (
      <>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-10">
          <Layouts.SideLayout
            leftMap={<SeProMap mapObj={primaryMapObj} inEditMode={inEditMode} withFooter={false} />}
            rightMap={<SeProMap mapObj={secondaryMapObj} inEditMode={inEditMode} />}
          />
        </div>
        <div className="absolute bottom-8 right-4 z-10 shadow-sm">
          <Zoom mapObj={primaryMapObj} />
        </div>
        <TooltipAnchor tooltip={<Tooltips.CompareDataTooltip mapObjects={mapObjects} />} />
        {withLegend && (
          <div className="absolute bottom-8 left-4 z-10">
            <MapLegends.CollapsibleWrapper>
              <CompareLegend mapObjects={mapObjects} isInEditMode={inEditMode} />
            </MapLegends.CollapsibleWrapper>
          </div>
        )}
      </>
    );
  }

  if (type === 'side' && primaryMapObj != null && secondaryMapObj != null) {
    content = (
      <>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-10">
          <Layouts.SideLayout
            leftMap={
              <SeProMap mapObj={primaryMapObj} inEditMode={inEditMode}>
                <div className="absolute bottom-8 right-4 z-10 shadow-sm">
                  <Zoom mapObj={primaryMapObj} />
                </div>
              </SeProMap>
            }
            rightMap={
              <SeProMap mapObj={secondaryMapObj} inEditMode={inEditMode}>
                <div className="absolute bottom-8 right-4 z-10 shadow-sm">
                  <Zoom mapObj={secondaryMapObj} />
                </div>
              </SeProMap>
            }
          />
        </div>
        <TooltipAnchor tooltip={<Tooltips.CompareDataTooltip mapObjects={mapObjects} />} />
        {withLegend && (
          <div className="absolute bottom-8 left-4 z-10">
            <MapLegends.CollapsibleWrapper>
              <CompareLegend mapObjects={mapObjects} isInEditMode={inEditMode} />
            </MapLegends.CollapsibleWrapper>
          </div>
        )}
      </>
    );
  }

  if (type === 'single' && primaryMapObj != null) {
    content = (
      <>
        <Layouts.SingleLayout map={<SeProMap mapObj={primaryMapObj} inEditMode={inEditMode} />} />
        <TooltipAnchor tooltip={<Tooltips.CompareDataTooltip mapObjects={mapObjects} />} />
        <div className="absolute bottom-8 right-4 z-50 shadow-sm">
          <Zoom mapObj={primaryMapObj} />
        </div>
        {withLegend && (
          <div className="absolute bottom-8 left-4 z-10">
            <MapLegends.CollapsibleWrapper>
              <CompareLegend mapObjects={mapObjects} isInEditMode={inEditMode} />
            </MapLegends.CollapsibleWrapper>
          </div>
        )}
      </>
    );
  }

  return <div className="relative z-0 h-full w-full">{content}</div>;
};

export default SeProMaps;
