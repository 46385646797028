import React from 'react';
import * as Buttons from '../Buttons';
import MUIcon from '../MUIcon';

const MAX_CHARACTER_LENGTH = 30;

interface Props {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  onBack?: () => void;
  onClose?: () => void;
}

const PanelHeader: React.FC<Props> = ({ children, title, subTitle, icon, action, onBack, onClose }) => {
  const hintDisabled = title.trim().length < MAX_CHARACTER_LENGTH;
  return (
    <div className="flex items-center justify-between">
      <div className={`flex w-full flex-col gap-2 border-gray-200 px-4 py-3 ${title ? 'border-b' : ''}`}>
        <div className="flex w-full items-center justify-between gap-6">
          <div className="flex min-w-0 items-center gap-2">
            {onBack && (
              <Buttons.Secondary
                onClick={(event) => {
                  event.stopPropagation();
                  onBack();
                }}
                icon={<MUIcon name="arrow_back" />}
                className="-ml-4 rounded-full text-gray-600 shadow-none ring-0 hover:bg-gray-100"
              />
            )}
            <div className="flex min-w-0 items-center gap-2">
              {icon}
              <div className="flex w-full flex-col">
                <span className="w-full truncate font-semibold text-gray-800" title={hintDisabled ? '' : title}>
                  {title}
                </span>
                {subTitle && <span className="text-xs text-gray-600">{subTitle}</span>}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-1">
            {action && <div className="flex items-center justify-center gap-3">{action}</div>}
            {action && onClose && <div className="h-[15px] w-[1px] bg-gray-300" />}
            {onClose && (
              <Buttons.Secondary
                onClick={(event) => {
                  event.stopPropagation();
                  onClose?.();
                }}
                icon={<MUIcon name="close" />}
                className="-mr-2 rounded-full text-gray-500 shadow-none ring-0 hover:bg-gray-100"
              />
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PanelHeader;
