import { ColumnType, Config, Extension, ParserError, ReadResult, Result, RowType } from '../types';
import unzipFile from '../unzipFile';
import * as Utils from '../utils';
import { doesMatch, extractExtension, extractFilenameAndExtension } from '../utils';
import parseCsv from './CsvParser';
import parseDbf from './DbfParser';
import parseJson from './JsonParser';
import parseKml from './KmlParser';

export const getImportableExtensions = () =>
  [Extension.ZIP, Extension.CSV, Extension.DBF, Extension.KML, Extension.JSON, Extension.KML].map((x) => x.toString());

export const parse = async (sourceFile: File, config: Config): Promise<Result> => {
  try {
    // 1. validate source file for extension, size etc
    const uploadErrors = validateUpload(sourceFile);
    if (uploadErrors.length) {
      return { sourceFile, columns: null, rows: null, totalRowsCount: -1, errors: uploadErrors };
    }

    // 2. preprocess inputs (unzip) and flatten entries
    let files: File[] = [];
    if (extractExtension(sourceFile.name) === Extension.ZIP) {
      try {
        // here is a list of all types which are allowed inside a ZIP
        // NOTE: SHP, PRJ, CPG and SHX are supported but only as part if a zip next to a DBF
        const allowedExtensions = [
          ...getImportableExtensions(),
          Extension.SHP,
          Extension.PRJ,
          Extension.CPG,
          Extension.SHX,
        ];

        const unzipped = await unzipFile(sourceFile, allowedExtensions);

        // unzip will fail if it contains anything which isn't stated in allowedExtensions (see above)
        if (unzipped.error) {
          return {
            sourceFile,
            columns: null,
            rows: null,
            totalRowsCount: -1,
            errors: [ParserError.UNSUPPORTED_CONTENT],
          };
        }
        files = unzipped.files;
      } catch {
        return { sourceFile, columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.UNZIPPING_FAILED] };
      }
    } else {
      files = [sourceFile];
    }

    // 3. validate files
    const fileErrors = validateFiles(files, config.maxFileSizeInBytes);
    if (fileErrors.length) {
      return { sourceFile, columns: null, rows: null, totalRowsCount: -1, errors: fileErrors };
    }

    // 4. read entries
    const result = await read(files, config);

    if (result.errors && result.errors?.length > 0) {
      // some errors occurred
      return { sourceFile, columns: null, rows: null, totalRowsCount: -1, errors: result.errors };
    }

    return { ...result, sourceFile, files };
  } catch {
    return { sourceFile, columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.UNKNOWN] };
  }
};

const validateUpload = (sourceFile: File): ParserError[] => {
  // 1. check the file types
  const importableExtensions = getImportableExtensions();
  if (!importableExtensions.includes(Utils.extractExtension(sourceFile.name))) {
    return [ParserError.INPUT_TYPE_NOT_SUPPORTED];
  }
  return [];
};

const validateFiles = (files: File[], maxFileSizeInBytes: number): ParserError[] => {
  // analyse entries for integrity
  // a single CSV or GeoJSON or KML or DBF
  // a single SHP paired with a DBF and PRJ of same name
  const importableExtensions = getImportableExtensions();

  // if none of supported extensions present...return error
  if (!files.some((x) => importableExtensions.includes(extractExtension(x.name)))) {
    return [ParserError.UNSUPPORTED_CONTENT];
  }

  // if more than a single CSV, DBF etc...return error
  if (files.filter((x) => importableExtensions.includes(extractExtension(x.name))).length > 1) {
    return [ParserError.AMBIGUOUS_CONTENT];
  }

  // check the file size
  const totalSizeInBytes = files.reduce((p, c) => p + c.size, 0);
  if (totalSizeInBytes > maxFileSizeInBytes) {
    return [ParserError.INPUT_TOO_LARGE];
  }

  // if SHP is present there must be a same-named dbf and prj present, too...otherwise return error
  if (files.some((x) => extractExtension(x.name) === Extension.SHP)) {
    const shpFile = files.find((x) => extractExtension(x.name) === Extension.SHP) as File;

    const { base: shpFileName } = extractFilenameAndExtension(shpFile.name);
    const isMatchingDbf = doesMatch(shpFileName, Extension.DBF);
    if (!files.some(isMatchingDbf)) {
      return [ParserError.INPUT_MALFORMED];
    }

    const isMatchingPrj = doesMatch(shpFileName, Extension.PRJ);
    if (!files.some(isMatchingPrj)) {
      return [ParserError.INPUT_MALFORMED];
    }
  }

  // nothing to complain...
  return [];
};

const read = async (files: File[], config: Config): Promise<ReadResult> => {
  // find the first acceptable file
  const file = files.find((x) => getImportableExtensions().includes(extractExtension(x.name)));

  if (!file) {
    // input files do not contain any of supported extensions, so exit...
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.INPUT_MALFORMED] };
  }

  switch (extractExtension(file.name)) {
    case Extension.CSV:
      return readCsvFile(file, config);
    case Extension.DBF:
      return readShpFiles(files, config);
    case Extension.KML:
      return readKmlOrJsonFile(file, true, config);
    case Extension.JSON:
      return readKmlOrJsonFile(file, false, config);
    default:
      return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.UNKNOWN] };
  }
};

const readShpFiles = async (sourceFiles: File[], config: Config): Promise<ReadResult> => {
  const dbfFile = sourceFiles.find((f) => extractExtension(f.name) === Extension.DBF);
  if (!dbfFile) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.INPUT_MALFORMED] };
  }

  const shpFile = sourceFiles.find((x) => extractExtension(x.name) === Extension.SHP);
  const prjFile = sourceFiles.find((x) => extractExtension(x.name) === Extension.PRJ);
  const cpgFile = sourceFiles.find((x) => extractExtension(x.name) === Extension.CPG);

  const parsed = await parseDbf(dbfFile, shpFile, prjFile, cpgFile, config.sampleSize);
  if (parsed.error) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.PARSING_FAILED] };
  }
  if (!parsed.columns || !parsed.data) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.EMPTY] };
  }

  const sampleRows = Utils.getSample(parsed.data);

  const columns = parsed.columns.map((x, colIdx) => {
    const columnSample = sampleRows.map((r) => r[colIdx]);
    const guessedType = Utils.guessStrictArrayType(columnSample);
    return { order: colIdx, name: x?.toString() ?? '?', type: guessedType };
  });

  const result: ReadResult = { columns, rows: parsed.data, totalRowsCount: parsed.totalRowsCount, errors: [] };

  if (parsed.featureCollection) {
    result.featureCollection = parsed.featureCollection;
  }

  return result;
};

const readKmlOrJsonFile = async (file: File, isKmlFile: boolean, config: Config): Promise<ReadResult> => {
  const parsed = isKmlFile ? await parseKml(file, config.sampleSize) : await parseJson(file, config.sampleSize);

  if (parsed.error) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.PARSING_FAILED] };
  }
  if (!parsed.data?.length || !parsed.columns?.length) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.EMPTY] };
  }
  const sampleRows = Utils.getSample(parsed.data);

  const columns = parsed.columns.map((x, colIdx) => {
    const columnSample = sampleRows.map((r) => r[colIdx]);
    const guessedType = Utils.guessStrictArrayType(columnSample);
    return { order: colIdx, name: x?.toString() ?? '?', type: guessedType };
  });

  return {
    columns,
    rows: parsed.data,
    featureCollection: parsed.featureCollection ?? undefined,
    totalRowsCount: parsed.totalRowsCount,
    errors: [],
  };
};

const readCsvFile = async (file: File, config: Config): Promise<ReadResult> => {
  const parsed = await parseCsv(file, config.sampleSize);
  if (parsed.error) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.PARSING_FAILED] };
  }

  if (!parsed.data) {
    return { columns: null, rows: null, totalRowsCount: -1, errors: [ParserError.EMPTY] };
  }

  let columns: ColumnType[] = [];
  let rows: RowType[] = [];

  if (config?.csv?.firstRowIsHeader) {
    const [headerRow, ...dataRows] = parsed.data;
    const sampleRows = Utils.getSample(dataRows);

    columns = headerRow.map((x, colIdx) => {
      const columnSample = sampleRows.map((r) => r[colIdx]);
      const guessedType = Utils.guessStrictArrayType(columnSample);
      return { order: colIdx, name: x?.toString() ?? '?', type: guessedType };
    });
    rows = [...dataRows];
  } else {
    // TODO: handle CSV where first row is data instead of header
  }

  return {
    columns,
    rows,
    totalRowsCount: parsed.totalRowsCount,
    errors: [],
  };
};
