import React from 'react';
import { Buttons, MUIcon } from '..';

export interface Props {
  zoom: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomTo?: (v: number) => void;
}

const ZoomControls: React.FC<Props> = ({ zoom, onZoomIn, onZoomOut, onZoomTo }) => (
  <div className="flex flex-col">
    <Buttons.Soft
      data-testid="zoomInBtn"
      className="h-[32px] w-[32px] rounded-none rounded-t-lg bg-white text-gray-500"
      onClick={onZoomIn}
      icon={<MUIcon name="add" />}
    />
    <div className="h-[1px] w-full bg-gray-300" />
    <Buttons.Soft
      data-testid="zoomOutBtn"
      className="h-[32px] w-[32px] rounded-none rounded-b-lg bg-white text-gray-500"
      onClick={onZoomOut}
      icon={<MUIcon name="remove" />}
    />
  </div>
);

export default ZoomControls;
