import classnames from 'classnames';
import React from 'react';

interface Props {
  classNames?: string;
}

const Placeholder: React.FC<Props> = ({ classNames = '' }) => {
  return <div className={classnames('w-12 rounded-md bg-gray-200 p-2', classNames)} />;
};

export default Placeholder;
