import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../assets/no-datasets-yet.svg';

const EmptyCatalog: React.FC = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="mt-4 w-full rounded-xl border-2 border-dotted border-gray-300 py-28">
        <div className="flex w-full flex-col items-center justify-center gap-8">
          <EmptyStateSVG />
          <div className="flex w-7/12 flex-col items-center gap-4">
            <div className="text-xl font-bold text-gray-800">No datasets yet.</div>
            <p className="flex items-center justify-center text-center text-base text-gray-600">
              Use the “Upload data” button to add your datasets, or you can use the “Data services” button to request
              that we process it for you.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCatalog;
