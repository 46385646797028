import { useEffect, useRef, useState } from 'react';

// Interface now uses T for input type and Q for output type
interface UseWebWorkerReturnType<T, Q> {
  postMessage: (message: T) => void; // Function to send messages of type T to the worker
  data: Q | null; // Data of type Q received from the worker
  error: ErrorEvent | null; // Any error from the worker
}

export const useWebWorker = <T, Q>(workerPath: string): UseWebWorkerReturnType<T, Q> => {
  const [data, setData] = useState<Q | null>(null);
  const [error, setError] = useState<ErrorEvent | null>(null);
  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    // Initialize the web worker with the provided path
    workerRef.current = new Worker(workerPath);
    // Event listener for messages from the worker, assuming event.data is of type Q
    workerRef.current.addEventListener('message', (event: MessageEvent) => {
      setData(event.data);
    });

    // Event listener for any worker errors
    workerRef.current.addEventListener('error', (event: ErrorEvent) => {
      setError(event);
    });

    // Clean up by terminating the worker when the component unmounts or the worker path changes
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    };
  }, [workerPath]);

  // Function to post messages of type T to the worker
  const postMessage = (message: T) => {
    if (workerRef.current) {
      workerRef.current.postMessage(message);
    }
  };

  return { postMessage, data, error };
};

export default useWebWorker;
