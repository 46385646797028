import { IChange } from 'json-diff-ts';
import { LngLatLike, Map } from 'maplibre-gl';
import { RuleGroupType } from 'react-querybuilder';
import { Survey } from './metadata.types';

export interface MapObject {
  id: string;
  ref: Map | null;
  styleSpec: MapSpec;
  updateMapSpec: (newMapSpec: MapSpec) => void;
}

export interface VariableSelection {
  variableGuid: string;
  tableGuid: string;
  datasetAbbrev: string;
  surveyName: string;
  universeVariableUuid: string | undefined;
  isUserSurvey?: boolean;
}

export interface SurveyInfo {
  isUserSurvey: boolean;
  enabled: boolean;
  metadata: Survey;
  availableDatasets: string[] | null;
  availableSummaryLevels: string[] | null;
  defaultGbGeorecordSearchIndexId?: string;
}

export interface MapSpec {
  baseMapId: string;
  dataLayer: DataLayerSpec;
  workingLayers: WorkingLayerSpec[];
  center: LngLatLike;
  zoom: number;
  geoFeatureSelection?: GeoFeatureSelection[];
}

export interface GeoFeatureSelection {
  label: string;
  fips: string;
  summaryLevelId: string;
  type?: string;
}

export interface MapContextObject {
  id: string;
  styleSpec: MapSpec;
  order: number;
}

export interface MapContext {
  id: string;
  type: 'single' | 'side' | 'side-locked' | 'swipe';
  mapObjects: MapContextObject[];
  sanitize?: { diffs: Record<string, IChange[]> };
}

export enum VisualizationType {
  SHADED = 'shadedArea',
  BUBBLES = 'bubbles',
  DOT_DENSITY = 'dotDensity',
}

export interface DataLayerSpec {
  type: VisualizationType;
  selection: VariableSelection[];
  opacity: number;
  colorPalette: {
    id: string;
    type: string;
  };
  percentBasedVisualization: boolean;
  rendering: CutPointSet[];
  preferredSummaryLevelId: string;
  filtersByData?: FiltersByData;
}

export interface CutPointSet {
  insufficientData?: {
    label: string;
    color: string;
  };
  cutPoints: CutPoint[];
}

export interface CutPoint {
  displayInLegend: boolean;
  from: number | undefined | string;
  to: number | undefined | string;
  title: string;
  color?: string;
}

export interface FiltersByData {
  rule: 'all' | 'any' | '!';
  filters: DataFilter[];
}

export interface DataFilter {
  id: string;
  variable: VariableSelection;
  value: number | string;
  operator: string;
  type: DataFilterType;
}

export enum DataFilterType {
  COUNT = 'count',
  STRING = 'string',
  ENUM = 'enum',
  PERCENTAGE = 'pct',
}

export interface WorkingLayerSpec {
  id: string;
  datasetId: string;
  geoLayerId: string;
  visible: boolean;
  type: LayerType;
  baseColor: string;
  opacities: number[];
  filter?: RuleGroupType;
  name?: string;
  tooltip?: WorkingLayerTooltip;
}

export interface TooltipTitle {
  id: string;
  name: string;
  format?: string;
}

export interface TooltipContent extends TooltipTitle {
  label: string | null;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface WorkingLayerTooltip {
  action: 'click';
  title: TooltipTitle | undefined;
  content: TooltipContent[];
  enabled: boolean;
}

export interface Variable {
  variableGuid: string;
  tableGuid: string;
  datasetAbbrev: string;
  surveyName: string;
  percentBaseVariableGuidUserOverride?: string;
}

export interface InitialView {
  centerLat: number;
  centerLon: number;
  zoom: number;
  boundingBoxNeLat: number;
  boundingBoxNeLng: number;
  boundingBoxSwLat: number;
  boundingBoxSwLng: number;
}

export interface SurveyToLoad {
  surveyCode: string;
  defaultGbGeorecordSearchIndexId?: string;
  availableSummaryLevels?: string[];
  availableDatasets?: string[];
  isUserSurvey?: boolean;
}

export interface PatchMapContext {
  type: string;
  diffs: Record<string, IChange[]>;
}

export enum ValueFormat {
  FORMAT_NONE = 'None',
  FORMAT_PERCENT = 'Percent',
  FORMAT_PERCENT_NO_DECIMAL = 'PercentNoDecimal',
  FORMAT_PERCENT_1_DECIMAL = 'Percent1Decimal',
  FORMAT_PERCENT_2_DECIMAL = 'Percent2Decimal',
  FORMAT_PERCENT_3_DECIMAL = 'Percent3Decimal',
  FORMAT_PERCENT_4_DECIMAL = 'Percent4Decimal',
  FORMAT_REAL_PERCENT = 'RealPercent',
  FORMAT_REAL_PERCENT_NO_DECIMAL = 'RealPercentNoDecimal',
  FORMAT_REAL_PERCENT_1_DECIMAL = 'RealPercent1Decimal',
  FORMAT_REAL_PERCENT_2_DECIMAL = 'RealPercent2Decimal',
  FORMAT_REAL_PERCENT_3_DECIMAL = 'RealPercent3Decimal',
  FORMAT_REAL_PERCENT_4_DECIMAL = 'RealPercent4Decimal',

  FORMAT_CURRENCY = 'Currency',
  FORMAT_CURRENCY_0_DECIMAL = 'Currency0Decimal',
  FORMAT_CURRENCY_1_DECIMAL = 'Currency1Decimal',
  FORMAT_CURRENCY_2_DECIMAL = 'Currency2Decimal',

  FORMAT_NUMBER = 'Number',
  FORMAT_NUMBER_NO_DECIMAL = 'NumberNoDecimal',
  FORMAT_NUMBER_1_DECIMAL = 'Number1Decimal',
  FORMAT_NUMBER_2_DECIMAL = 'Number2Decimal',
  FORMAT_NUMBER_3_DECIMAL = 'Number3Decimal',
  FORMAT_NUMBER_4_DECIMAL = 'Number4Decimal',
  FORMAT_NUMBER_5_DECIMAL = 'Number5Decimal',

  FORMAT_NUMBER_NO_FORMAT = 'NumberNoFormat',
  FORMAT_NUMBER_NO_FORMAT_NO_DECIMAL = 'NumberNoFormatNoDecimal',
  FORMAT_NUMBER_NO_FORMAT_1_DECIMAL = 'NumberNoFormat1Decimal',
  FORMAT_NUMBER_NO_FORMAT_2_DECIMAL = 'NumberNoFormat2Decimal',
  FORMAT_NUMBER_NO_FORMAT_3_DECIMAL = 'NumberNoFormat3Decimal',
  FORMAT_NUMBER_NO_FORMAT_4_DECIMAL = 'NumberNoFormat4Decimal',
  FORMAT_NUMBER_NO_FORMAT_5_DECIMAL = 'NumberNoFormat5Decimal',
}

export enum LayerType {
  POINT = 'POINT',
  POLYGON = 'POLYGON',
  LINE = 'LINE',
  TABLE = 'TABLE',
}

export enum MenuActions {
  WATCH,
  SHARE,
  EXPORT,
  CREATE_STORY,
  DELETE,
  COPY,
  RENAME,
}

export enum MapTileSource {
  MAPTILER_SOURCE = 'source-maptiler',
  SOCEX_SOURCE = 'source-social-explorer',
  USER_UPLOAD_SOURCE = 'source-user-uploads',
}

export const GEO_ROW_IDENTIFICATOR = 'ID';
