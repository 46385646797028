import { DatasetTypes } from '@platform/types';
import React from 'react';
import EditBooleanCellModal from '../../../Modals/EditBooleanCellModal';
import EditIntegerCellModal from '../../../Modals/EditIntegerCellModal';
import EditTextCellModal from '../../../Modals/EditTextCellModal';

interface Props {
  value: unknown;
  anchor: HTMLDivElement | null;
  onCloseRequest: () => void;
  type: DatasetTypes.DataType;
  onDone: (newValue: unknown) => void;
}

const EditCell: React.FC<Props> = ({ value, anchor, onCloseRequest, onDone, type }) => {
  const anchorRect = anchor?.getBoundingClientRect() ?? { top: 0, left: 0, width: 200 };
  switch (type) {
    case DatasetTypes.DataType.INTEGER:
    case DatasetTypes.DataType.DOUBLE:
      return (
        <EditIntegerCellModal
          value={value as number}
          onCloseRequest={onCloseRequest}
          onDone={onDone}
          anchorRect={anchorRect}
        />
      );
    case DatasetTypes.DataType.BOOLEAN:
      return (
        <EditBooleanCellModal
          value={value as boolean}
          onCloseRequest={onCloseRequest}
          onDone={onDone}
          anchorRect={anchorRect}
        />
      );
    default:
      return (
        <EditTextCellModal
          value={value as string}
          onCloseRequest={onCloseRequest}
          onDone={onDone}
          anchorRect={anchorRect}
        />
      );
  }
};

export default EditCell;
