import classNames from 'classnames';
import React, { CSSProperties } from 'react';

interface Props {
  name: string;
  iconStyle?: 'rounded' | 'filled';
  className?: string;
  style?: CSSProperties;
}

// https://fonts.google.com/icons
export const MUIcon: React.FC<Props> = ({ name, className = '', iconStyle = 'rounded', style }) => {
  let iconClassName;
  switch (iconStyle) {
    case 'filled':
      iconClassName = 'material-icons';
      break;
    default:
      iconClassName = 'material-symbols-rounded';
      break;
  }
  return (
    <i className={classNames('text-[20px]', iconClassName, className)} style={style}>
      {name}
    </i>
  );
};

export default MUIcon;
