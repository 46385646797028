import { Disclosure } from '@headlessui/react';
import { MUIcon } from '@platform/shared/ui';
import { Link, useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import Notifications from '../Notifications';
import { UserMenu } from '../shared';
import CreateNew from './CreateNew';
import WorkspaceMenu from './WorkspaceMenu';

export default function AppHeader() {
  const location = useLocation();
  const { activeWorkspace } = useWorkspaceContext();

  const homeLink = generateLink('workspace', { workspaceId: activeWorkspace.id });
  const projectsLink = generateLink('allProjects', { workspaceId: activeWorkspace.id });
  const catalogLink = generateLink('datasets', { workspaceId: activeWorkspace.id });

  const navigation: { name: string; href: string; active: boolean; disabled?: boolean; dataCy: string }[] = [
    { name: 'Home', href: homeLink, active: location.pathname === homeLink, dataCy: 'header-home-link' },
    {
      name: 'Projects',
      href: projectsLink,
      active: location.pathname.includes(projectsLink),
      dataCy: 'header-projects-link',
    },
    {
      name: 'Catalog',
      href: catalogLink,
      active: location.pathname.includes(catalogLink),
      dataCy: 'header-catalog-link',
    },
  ];

  return (
    <Disclosure as="nav" className="select-none border-b bg-white" style={{ zIndex: 1 }}>
      {({ open }) => (
        <div className="mx-auto max-w-7xl">
          <div className="flex h-16">
            <div className="flex">
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="hover:bg-primary-100 focus:ring-primary-500 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <MUIcon name="close" className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MUIcon name="menu" className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-shrink-0 items-center">
                <WorkspaceMenu />
              </div>
            </div>
            <div className="hidden md:ml-12 md:flex md:flex-grow md:space-x-6">
              {navigation.map((item) => (
                <StyledLink
                  $disabled={item.disabled}
                  $active={item.active}
                  to={item.href}
                  key={item.name}
                  data-cy={item.dataCy}
                >
                  {item.name}
                </StyledLink>
              ))}
            </div>
            <div className="flex items-center gap-4">
              <div className="flex flex-shrink-0">
                <CreateNew />
              </div>
              <Notifications />
              <UserMenu />
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
}

const StyledLink = tw(Link)<{ $active?: boolean; $disabled?: boolean }>`
  inline-flex
  items-center
  border-b-2
  border-transparent
  px-2
  pt-1
  text-sm
  font-semibold
  text-gray-500
  select-none
  hover:border-gray-300
  hover:text-gray-700
  ${(props) => props.$active && `border-primary-500 text-gray-900`}
  ${(props) => props.$disabled && `opacity-50 pointer-events-none text-gray-500`}
`;
