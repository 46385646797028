import { useSurveys } from '@platform/shared/hooks';
import { MetadataTypes, ProjectTypes } from '@platform/types';
import { GetVariableUniverse } from '@platform/utils';
import React, { useEffect, useState } from 'react';
import DatasetPicker from './DatasetPicker';
import TablePicker from './TablePicker';

export interface Props {
  selection: ProjectTypes.VariableSelection[];
  onChange: (selection: ProjectTypes.VariableSelection[], tableMetadata: MetadataTypes.Table) => void;
  isLoading?: boolean;
  allowMultiSelection?: boolean;
  surveysApi: () => Promise<ProjectTypes.SurveyInfo[]>;
  activeWorkspaceId: number;
  disableSurveyChange?: boolean;
}

export interface SurveyType {
  surveyName: string;
  isUserSurvey: boolean;
}

const DataBrowser: React.FC<Props> = ({
  selection,
  onChange,
  isLoading,
  allowMultiSelection,
  surveysApi,
  activeWorkspaceId,
  disableSurveyChange = false,
}) => {
  const surveysQuery = useSurveys({ surveysApi: surveysApi, activeWorkspaceId });

  const [selectedSurvey, setSelectedSurvey] = useState<SurveyType>({ surveyName: '', isUserSurvey: false });
  const [selectedDatasetAbbrev, setSelectedDatasetAbbrev] = useState<string | undefined>('');

  useEffect(() => {
    if (surveysQuery.data) {
      const surveyByDefault: SurveyType =
        selection.length > 0
          ? {
              surveyName: selection[0].surveyName,
              isUserSurvey: selection[0]?.isUserSurvey === true,
            }
          : {
              surveyName: surveysQuery.data[0].metadata.name,
              isUserSurvey: surveysQuery.data[0]?.isUserSurvey === true,
            };
      const datasetByDefault =
        selection.length > 0 ? selection[0].datasetAbbrev : surveysQuery.data[0].availableDatasets?.[0];

      setSelectedSurvey(surveyByDefault);
      setSelectedDatasetAbbrev(datasetByDefault);
    }
  }, [surveysQuery.data]);

  if (surveysQuery.isLoading || surveysQuery.isIdle) return <div className="p-4">Loading...</div>;
  if (surveysQuery.isError) return <div className="p-4">An error occurred</div>;

  const handleDatasetChange = ({
    dataset,
    survey,
  }: {
    dataset: MetadataTypes.DataSet;
    survey: ProjectTypes.SurveyInfo;
  }) => {
    setSelectedSurvey({ surveyName: survey.metadata.name, isUserSurvey: survey.isUserSurvey });
    setSelectedDatasetAbbrev(dataset.abbrevation);
  };

  const handleVariablesChange = (variables: MetadataTypes.Variable[], table: MetadataTypes.Table) => {
    const selection: ProjectTypes.VariableSelection[] = variables.map((x) => {
      const universes = GetVariableUniverse(x.uuid, table) ?? [];
      const universeVariableUuid = universes[0]?.uuid;
      return {
        universeVariableUuid: universeVariableUuid,
        variableGuid: x.uuid,
        datasetAbbrev: selectedDatasetAbbrev as string,
        tableGuid: table.uuid,
        surveyName: selectedSurvey.surveyName,
        isUserSurvey: selectedSurvey.isUserSurvey,
      };
    });

    onChange(selection, table);
  };

  return (
    <div className="relative flex h-full min-h-0 w-full flex-col">
      <DatasetPicker
        surveys={surveysQuery.data}
        selectedSurveyName={selectedSurvey.surveyName}
        selectedDatasetAbbr={selectedDatasetAbbrev}
        onChange={handleDatasetChange}
        disableSurveyChange={disableSurveyChange}
      />
      {selectedDatasetAbbrev && (
        <TablePicker
          disabled={isLoading}
          selection={selection}
          surveyName={selectedSurvey.surveyName}
          datasetAbbreviation={selectedDatasetAbbrev}
          onVariableChange={handleVariablesChange}
          allowMultiSelection={allowMultiSelection}
        />
      )}
    </div>
  );
};

export default DataBrowser;
