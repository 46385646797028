import { Inputs, MUIcon } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import * as peApi from '../../../../pe.api';
import { chunkArray } from '../../../../utils';
import ColumnPickerItem from './ColumnPickerItem';
import EmptyColumnsState from './EmptyColumnsState';
import { SelectionType } from './types';

interface Props {
  columns: PeTypes.DatasetColumn[];
  datasetId: string;
  selectedIds?: string[];
  allowMultiSelection?: boolean;
  onChange: (payload: SelectionType | SelectionType[]) => void;
}

const SAMPLE_SIZE = 5;
const MAX_COLUMN_PAGE_SIZE = 60;

const ColumnPicker: React.FC<Props> = ({
  columns,
  selectedIds = [],
  datasetId,
  allowMultiSelection = false,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [columnPages, setColumnPages] = useState<string[][]>([]);

  const IDColumn = columns.find((c) => c.type === DatasetTypes.DataType.ID)?.uuid ?? '';
  const availableColumns = columns.filter((c) => DatasetTypes.VisibleDataTypes.includes(c.type));

  useEffect(() => {
    if (!columns) return;
    const columnIds = columns.map((col) => col.uuid);
    setColumnPages(chunkArray(columnIds, MAX_COLUMN_PAGE_SIZE));
  }, []);

  // in order to get sample data for each column we have to make a proper request to GB api. Now, the issue is that GB api allows 64 cols at a time
  //  we have to fire split larger datasets (more than 64 cols) into multiple requests and then aggregate the responses into sample rows with all the columns
  const sampleDataQueries = useQueries(
    columnPages.map((columnIdsInPage) => ({
      queryKey: ['dataset-sample-data', columnIdsInPage],
      queryFn: () =>
        peApi.getDatasetData({
          IDColumn,
          datasetId,
          columnIds: columnIdsInPage,
          itemsPerPage: SAMPLE_SIZE,
          sortBy: IDColumn,
          filter: { combinator: 'and', rules: [] },
          pageData: {
            firstUuid: null,
            lastUuid: null,
            action: DatasetTypes.PageAction.FIRST,
          },
        }),
    }))
  );

  // aggregate each sample data response into a single collection
  const sampleData: Record<string, unknown[]> = sampleDataQueries
    .filter((query) => query.isSuccess)
    .reduce((prev, query, queryIdx) => {
      const columnIdsInPage = columnPages[queryIdx];
      return columnIdsInPage.reduce(
        (prev, columnId, columnIdx) => ({
          ...prev,
          [columnId]: query.data?.rows.map((row) => row[columnIdx]),
        }),
        { ...prev }
      );
    }, {});

  const handleAllColumnsCheck = () => {
    const checked = availableColumns.length === selectedIds.length;
    const newState: SelectionType[] = availableColumns.map((x) => [x, !checked]);
    onChange(newState);
  };

  const handleItemSelection = (colKey: string) => {
    let newState: SelectionType | SelectionType[];
    if (allowMultiSelection) {
      newState = availableColumns.map((x) => {
        const selected = x.uuid === colKey ? !selectedIds.includes(x.uuid) : selectedIds.includes(x.uuid);
        return [x, selected];
      }) as SelectionType[];
    } else {
      const column = availableColumns.find((col) => col.uuid === colKey) as PeTypes.DatasetColumn;
      newState = [column, !selectedIds.includes(colKey)] as SelectionType;
    }

    onChange(newState);
  };

  const filteredColumns = availableColumns.filter(
    (column) =>
      column.title.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
      column.label?.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  return (
    <div className="flex min-h-0 grow flex-col p-4 pb-2">
      <Inputs.Input
        autoFocus
        prefixComponent={<MUIcon name="search" />}
        classes="rounded-lg bg-gray-100 ring-transparent py-2.5" // ml-3 mr-2 flex-grow border-0 p-0 text-sm text-gray-900 focus:outline-none focus:ring-0 bg-transparent
        inputClasses="ml-3 mr-2  bg-transparent"
        placeholder="Search"
        value={searchTerm}
        onClear={() => setSearchTerm('')}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="-mr-3 -ml-4 mt-4 mb-1 flex flex-col overflow-y-auto">
        {allowMultiSelection && !searchTerm.trim().length && (
          <>
            <button
              className="flex w-full cursor-pointer items-center gap-2 p-4 hover:bg-gray-50"
              onClick={handleAllColumnsCheck}
            >
              <div className="ml-11 flex w-full items-center">
                <div className="text-sm text-gray-800">All variables</div>
                <input
                  name="select all"
                  id="all"
                  type="checkbox"
                  checked={selectedIds.length === availableColumns.length}
                  className="text-primary-600 focus:ring-primary-600 mr-3 ml-auto h-4 w-4 rounded border-gray-300 hover:cursor-pointer"
                  readOnly
                />
              </div>
            </button>
            <div className="my-2 ml-14 mr-3 border-b" />
          </>
        )}
        {!filteredColumns.length && <EmptyColumnsState searchTerm={searchTerm} />}
        {filteredColumns.map((column) => (
          <ColumnPickerItem
            key={column.uuid}
            title={column.title}
            label={column.label}
            id={column.uuid}
            type={column.type}
            showCheckbox={allowMultiSelection}
            selected={selectedIds.includes(column.uuid)}
            onSelect={handleItemSelection}
            sampleData={sampleData[column.uuid]}
          />
        ))}
      </div>
    </div>
  );
};

export default ColumnPicker;
