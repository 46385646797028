import { useVariableMetadata } from '@platform/shared/hooks';
import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import { MapGeoJSONFeature } from 'maplibre-gl';
import React from 'react';
import VariableValue from './VariableValue';

export interface Props {
  selection: ProjectTypes.VariableSelection;
  feature: MapGeoJSONFeature;
  filter: ProjectTypes.DataFilter;
}

const operatorText: {
  [key: string]: string;
} = {
  '>': 'greater than',
  '<': 'less than',
  '==': 'equal to',
  '!=': 'not equal to',
  '>=': 'greater than or equal to',
  '<=': 'less than or equal to',
};

const FilteredDataTooltipLine: React.FC<Props> = ({ feature, selection, filter }) => {
  const { isLoading, isError, variable, universeVariablesMetadata } = useVariableMetadata({
    surveyName: filter.variable.surveyName,
    datasetAbbreviation: filter.variable.datasetAbbrev,
    tableUuid: filter.variable.tableGuid,
    variableUuid: filter.variable.variableGuid,
  });

  if (isLoading) return <>Loading variable meta...</>;
  if (isError) return <>Something went wrong...</>;

  const formattingToUse =
    filter.type === 'pct'
      ? ProjectTypes.ValueFormat.FORMAT_PERCENT_2_DECIMAL
      : (variable?.formatting as ProjectTypes.ValueFormat);

  const filterValue = ValueFormatter.format(filter.value, formattingToUse);
  const filterUniverseVariable = universeVariablesMetadata[0] != null ? `${universeVariablesMetadata[0]?.label}:` : '';
  return (
    <div className="flex flex-col gap-2 text-xs">
      <div className="flex w-full items-center gap-4 py-2">
        <div className="flex w-full items-start justify-between gap-2">
          <div>
            {`${filterUniverseVariable} ${variable?.label}`}
            <span className="font-bold">{` is ${operatorText[filter.operator]} ${filterValue}`} </span>
          </div>
          <div className="align-end flex flex-col text-right">
            <VariableValue
              selection={selection}
              universeVariablesMetadata={universeVariablesMetadata}
              feature={feature}
              variable={variable}
              showPercentage={filter.type === 'pct' ?? false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilteredDataTooltipLine;
