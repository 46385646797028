interface IconProps {
  fillColor: string;
}

export default ({ fillColor }: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5671 15.6857L15.9001 10.3528C16.0457 10.1189 16.0325 9.81959 15.8668 9.59951L11.8671 4.26664C11.7413 4.09879 11.5437 4 11.3339 4H4.66777C4.41991 4.00041 4.19274 4.1383 4.07799 4.358C3.96325 4.5777 3.97987 4.84292 4.12115 5.04658L7.3342 9.78616L11.6005 15.999H12.0005C12.0714 15.9945 12.1412 15.9788 12.2071 15.9523L12.3338 15.899L12.3871 15.859L12.4738 15.7923L12.5204 15.739L12.5671 15.6857ZM10.0008 16.0004L6.66777 11.3341L4.10133 14.9805C3.97277 15.1862 3.96609 15.4455 4.08389 15.6575C4.20169 15.8696 4.42538 16.0009 4.66794 16.0004H10.0008Z"
      fill={fillColor}
    />
  </svg>
);
