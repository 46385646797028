import { Hooks, MapsContextMap, Utils } from '@platform/maplibre';
import { useGeoSelection } from '@platform/shared/integrated-ui';
import { ProjectTypes } from '@platform/types';
import React, { useRef } from 'react';
import useWorkingLayerTooltips from './WorkingLayers/Tooltip/useWorkingLayerTooltips';

const { useFeatureHighlight } = Hooks;

interface Props {
  mapObj: ProjectTypes.MapObject;
  inEditMode: boolean;
  children?: React.ReactNode;
  withFooter?: boolean;
}

const SeProMap: React.FC<Props> = ({ mapObj, inEditMode, children, withFooter }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { styleSpec, ref } = mapObj;
  const { dataLayer } = styleSpec;

  const seDataMapLayerId = Utils.getDataLayerFingerprint(dataLayer);
  const filteredOutDataLayerId = Utils.getFilteredOutDataLayerId(dataLayer);

  useGeoSelection(mapObj, inEditMode);
  useFeatureHighlight(ref, [seDataMapLayerId, filteredOutDataLayerId]);

  const workingLayerTooltip = useWorkingLayerTooltips(mapObj, inEditMode);

  return (
    <div className="relative h-full w-full" ref={containerRef} key={mapObj.id}>
      <MapsContextMap mapObj={mapObj} withFooter={withFooter} />
      {children}
      {workingLayerTooltip}
    </div>
  );
};

export default SeProMap;
