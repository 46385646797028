import { MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';

export const PROJECT_DROPDOWN_OPTIONS = [
  { value: ProjectTypes.MenuActions.RENAME, name: 'Rename', icon: <MUIcon name={'edit'} />, dataCy: 'rename-option' },
  {
    value: ProjectTypes.MenuActions.DELETE,
    name: 'Delete',
    icon: <MUIcon name={'delete'} />,
    dataCy: 'delete-option',
  },
];

export const STORY_DROPDOWN_OPTIONS = [
  { value: ProjectTypes.MenuActions.RENAME, name: 'Rename', icon: <MUIcon name={'edit'} />, dataCy: 'rename-option' },
  { value: ProjectTypes.MenuActions.COPY, name: 'Copy', icon: <MUIcon name={'content_copy'} />, dataCy: 'copy-option' },
  {
    value: ProjectTypes.MenuActions.DELETE,
    name: 'Delete',
    icon: <MUIcon name={'delete'} />,
    dataCy: 'delete-option',
  },
];

export const PROFILE_DROPDOWN_OPTIONS = [
  { value: ProjectTypes.MenuActions.RENAME, name: 'Rename', icon: <MUIcon name={'edit'} />, dataCy: 'rename-option' },
  { value: ProjectTypes.MenuActions.COPY, name: 'Copy', icon: <MUIcon name={'content_copy'} />, dataCy: 'copy-option' },
  {
    value: ProjectTypes.MenuActions.DELETE,
    name: 'Delete',
    icon: <MUIcon name={'delete'} />,
    dataCy: 'delete-option',
  },
];

export const WATCHLIST_DROPDOWN_OPTIONS = [
  { value: 'RENAME', name: 'Rename', disabled: true },
  { value: 'UNWATCH', name: 'Remove from Watchlist', icon: <MUIcon name={'bookmark'} /> },
  {
    value: 'DELETE',
    name: 'Delete',
    icon: <MUIcon name={'delete'} />,
  },
];
