import React from 'react';
import tw from 'tailwind-styled-components';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

export const SecondaryButton: React.FC<ButtonBaseProps> = (props) => <StyledButton {...props} />;
export default SecondaryButton;

const StyledButton = tw(ButtonBase)`
  ring-1
  ring-inset
  ring-gray-300
  hover:bg-gray-50
  bg-secondary
  text-gray-900
  hover:bg-gray-50
`;
