import Papa from 'papaparse';

export default async (
  file: File,
  limit?: number,
  delimiter = ','
): Promise<{
  data: unknown[][] | null;
  totalRowsCount: number;
  error?: unknown;
}> =>
  new Promise((resolve) => {
    // https://www.papaparse.com/docs#config
    Papa.parse<unknown[], File>(file, {
      header: false,
      worker: true,
      skipEmptyLines: true,
      dynamicTyping: false, // leave this to us
      complete: (results: any) => {
        // https://www.papaparse.com/docs#data
        resolve({ data: results.data.slice(0, limit ?? results.data.length), totalRowsCount: results.data.length });
      },
      error: (error: Error) => {
        // https://www.papaparse.com/docs#data
        resolve({ data: null, totalRowsCount: -1, error });
      },
    });
  });
