interface IconProps {
  fillColor: string;
}

export default ({ fillColor }: IconProps) => (
  <svg width="18" height="18" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9935 12H2.00642C1.58547 12 1.22917 11.8562 0.9375 11.5685C0.645833 11.2808 0.5 10.9294 0.5 10.5142V1.48579C0.5 1.07061 0.645833 0.71918 0.9375 0.431508C1.22917 0.143836 1.58547 0 2.00642 0H13.9935C14.4145 0 14.7708 0.143836 15.0625 0.431508C15.3541 0.71918 15.5 1.07061 15.5 1.48579V10.5142C15.5 10.9294 15.3541 11.2808 15.0625 11.5685C14.7708 11.8562 14.4145 12 13.9935 12ZM1.74998 10.5142L1.74998 4.15066H14.25V6.7513H1.75V8.00126H14.25V10.5142C14.25 10.5774 14.2233 10.6354 14.1699 10.6881C14.1164 10.7408 14.0577 10.7671 13.9935 10.7671H11.5897H2.00642C1.94231 10.7671 1.88353 10.7408 1.8301 10.6881C1.77669 10.6354 1.74998 10.5774 1.74998 10.5142Z"
      fill={fillColor}
    />
  </svg>
);
