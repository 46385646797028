import { Buttons, Inputs } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../pe.api';

interface Props {
  activeWorkspace: PeTypes.Workspace;
  isAdmin: boolean | undefined;
}

const WorkspaceName: React.FC<Props> = ({ activeWorkspace, isAdmin }) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const queryClient = useQueryClient();

  useEffect(() => {
    setWorkspaceName(activeWorkspace.name);
    setShowInput(false);
  }, [activeWorkspace]);

  const renameWorkspaceMutation = useMutation({
    mutationFn: (name: string) => peApi.renameWorkspace(name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspaces'] });
      queryClient.invalidateQueries({ queryKey: ['workspace', activeWorkspace.id] });
      setShowInput(false);
    },
  });

  const renameHandler = () => renameWorkspaceMutation.mutate(workspaceName);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkspaceName(e.target.value);
  };

  return (
    <div className="flex w-full flex-col items-center justify-between gap-8 border-b border-gray-200 py-8 px-6">
      <div className="flex w-full items-end justify-between">
        <div className="flex w-8/12 flex-col gap-6">
          <span className="text-base font-semibold text-gray-800">Workspace Name</span>
          <span className="text-sm text-gray-600">
            Workspace name is displayed in menus and headings. It will usually be (or include) the name of your company.
          </span>
        </div>
        {isAdmin && (
          <Buttons.Secondary className="h-[40px] px-4" onClick={() => setShowInput(!showInput)} disabled={!isAdmin}>
            {showInput ? 'Close' : 'Change Workspace Name'}
          </Buttons.Secondary>
        )}
      </div>
      <div className="w-full">
        <div className="flex w-8/12 flex-col items-start justify-center gap-4 whitespace-pre-line break-words">
          {!showInput && (
            <p className="text-sm text-gray-600 ">
              Your workspace name is{' '}
              <span className="break-words text-sm font-semibold text-gray-800">{activeWorkspace.name}</span>
            </p>
          )}
          {showInput && (
            <div className="flex w-full flex-col gap-8">
              <div className="flex flex-col gap-2">
                <span className="text-sm font-semibold text-gray-800">Name</span>
                <Inputs.Input
                  autoFocus
                  classes="h-[42px] w-full rounded-md border border-gray-200 px-4"
                  value={workspaceName}
                  onChange={onInputChange}
                ></Inputs.Input>
              </div>
              <Buttons.Primary
                onClick={renameHandler}
                className={classNames(
                  workspaceName === '' || workspaceName === activeWorkspace.name
                    ? 'pointer-events-none bg-gray-200 text-gray-500'
                    : '',
                  'mb-3 w-[280px] rounded-[6px] p-3'
                )}
              >
                Update Workspace Name
              </Buttons.Primary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceName;
