import { PeTypes } from '@platform/types';
import React from 'react';
import tw from 'tailwind-styled-components';
import { SlideMenuOption } from '.';
import Thumb from '../../Thumb';
import SlideMenu from './SlideMenu';

interface Props {
  active: boolean;
  index: number;
  slide: PeTypes.StorySlide;
  currentSlideThumb?: string | null;
  onClick: (slide: PeTypes.StorySlide) => void;
  options: SlideMenuOption[];
  isEditMode: boolean;
  isDragging: boolean;
}

const SlideListItem: React.FC<Props> = ({
  slide,
  active,
  onClick,
  index,
  currentSlideThumb,
  options,
  isEditMode,
  isDragging,
}) => {
  return (
    <div className="relative">
      <div className="flex items-center">
        {index != null && (
          <span
            className={`m-2 w-[25px] text-center text-sm font-semibold text-gray-500 ${
              isDragging ? 'invisible' : 'visible'
            }`}
          >
            {index + 1}
          </span>
        )}
        <SlideThumbWrapper $active={active} onClick={() => onClick(slide)}>
          <Thumb
            url={currentSlideThumb ?? slide.thumbPath}
            useLinearGradient={active}
            className="h-full w-full rounded-lg"
          />
        </SlideThumbWrapper>
      </div>
      {active && isEditMode && (
        <div className="absolute right-2 top-2">
          <SlideMenu slide={slide} options={options.filter((opt) => !opt.disabled)} />
        </div>
      )}
    </div>
  );
};

export default SlideListItem;

const SlideThumbWrapper = tw.div<{ $active: boolean }>`
    flex
    w-full
    h-[86px]
    cursor-pointer
    select-none
    rounded-lg
    bg-slate-700
    outline
    outline-gray-200
    outline-[1px]
    hover:outline-[3px]
    ${(p) => (p.$active ? 'outline-primary-400 outline-[3px]' : '')}
`;
