import { Buttons, MUIcon, Table } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { useSessionContext } from '../../../../contexts/SessionContext';
import { useWorkspaceContext } from '../../../../contexts/WorkspaceContext';
import { isNumericValue, parse } from '../../../../helpers/datasetHelpers';
import { useDataset, useDatasetColumns, useDatasetData } from '../../../../hooks';
import * as peApi from '../../../../pe.api';
import FilteredTableEmptyState from '../Filtering/FilteredTableEmptyState';
import OperateOnDatasetMenu from '../OperateOnDatasetMenu';
import DatasetColumnMenu from './DatasetColumnMenu';
import DatasetPagination from './DatasetPagination';
import EditCell from './EditCell';
import { columnFormatter, getSuffixIcon } from './utils';

const TableComponent = Table.default;

interface Props {
  datasetId: string;
  filter: RuleGroupType;
  onDataSizeChange?: (rows: number, columns: number) => void;
  selectedRows: string[];
  onRowSelection: (rows: string[]) => void;
  tableMenu?: ReactNode;
}

type TablePagingState = {
  rowPageNumber: number;
  columnPageNumber: number;
  pageData: DatasetTypes.DatasetPageData | undefined;
};

const DEFAULT_PAGING_STATE: TablePagingState = {
  columnPageNumber: 0,
  rowPageNumber: 0,
  pageData: undefined,
};

const DatasetTable: React.FC<Props> = ({
  datasetId,
  filter,
  onDataSizeChange,
  onRowSelection,
  selectedRows,
  tableMenu,
}) => {
  const { notify } = useSessionContext();
  const queryClient = useQueryClient();
  const [pagedColumns, setPagedColumns] = useState<Table.Column[]>([]);
  const [pagedRows, setPagedRows] = useState<unknown[][]>([]);
  const [tableSort, setTableSort] = useState<{ sortBy: string; sortOrder: Table.SortOrderDirection } | null>();
  const [pagingState, setPagingState] = useState<TablePagingState>(DEFAULT_PAGING_STATE);
  const [highlightedColumnId, setHighlightedColumnId] = useState<string>('');

  useEffect(() => {
    // reset paging state on each filter change
    setPagingState(DEFAULT_PAGING_STATE);
  }, [filter.rules]);

  const datasetQuery = useDataset(datasetId);
  const datasetColumnsQuery = useDatasetColumns({
    datasetId,
    pageNumber: pagingState.columnPageNumber,
    itemsPerPage: DatasetTypes.COLUMNS_PER_PAGE,
  });

  const columnIds = datasetColumnsQuery.data?.map((col) => col.uuid) ?? [];
  const IDColumn = datasetColumnsQuery.data?.find((c) => c.type === DatasetTypes.DataType.ID)?.uuid ?? '';
  const validFilteringRules = filter.rules.filter((rule) => Helpers.checkIfValidRule(rule, null));
  const filteringIsActive = validFilteringRules.length > 0;
  const totalNumberOfRecords = filteringIsActive ? 0 : datasetQuery.data?.numberOfRecords ?? 0;
  const totalNumberOfColumns = datasetQuery.data?.numberOfColumns ?? 0;

  const rowPageSize = DatasetTypes.ROWS_PER_PAGE;
  let numberOfItemsToFetch = rowPageSize;

  const numberOfRecordsOffset = totalNumberOfRecords % DatasetTypes.ROWS_PER_PAGE;
  // we can have less
  if (pagingState?.pageData?.action === DatasetTypes.PageAction.LAST && numberOfRecordsOffset > 0) {
    // if last page, pull number of rows left
    numberOfItemsToFetch = numberOfRecordsOffset;
  }

  const datasetDataQuery = useDatasetData({
    datasetId,
    IDColumn,
    columnIds,
    filter: { ...filter, rules: validFilteringRules },
    pageData: pagingState.pageData,
    itemsPerPage: numberOfItemsToFetch,
    sortOrder: tableSort?.sortOrder,
    sortBy: tableSort?.sortBy,
  });
  const { activeWorkspace } = useWorkspaceContext();

  useEffect(() => {
    onDataSizeChange?.(datasetDataQuery.data?.rows.length ?? 0, datasetColumnsQuery.data?.length ?? 0);
  }, [datasetDataQuery.data, datasetColumnsQuery.data]);

  const handleCellEdit = async (cIdx: number, rIdx: number, newValue: unknown, valueType: DatasetTypes.DataType) => {
    if (!datasetDataQuery.data) return;
    try {
      const rowUuid = datasetDataQuery.data.rows[rIdx][0];
      const parsedValue = parse(newValue, valueType);
      await peApi.editDatasetCell({
        datasetId,
        columnUuid: columnIds[cIdx],
        newValue: parsedValue,
        rowsUuids: [rowUuid as string],
      });

      await queryClient.invalidateQueries({ queryKey: ['dataset-data', datasetId] });
      await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });
      await queryClient.invalidateQueries({ queryKey: ['datasets', activeWorkspace.id] });
    } catch {
      notify({ content: <span>Failed to update value</span> });
    }
  };

  const handleColumnHighlightToggle = (columnId: string, isHighlighted: boolean) => {
    if (isHighlighted) {
      setHighlightedColumnId(columnId);
    } else if (columnId === highlightedColumnId) {
      setHighlightedColumnId('');
    }
  };

  const handleJumpToColumn = (columnId: string, page: number) => {
    setHighlightedColumnId(columnId);
    handleColumnPageChange(page);
  };

  useEffect(() => {
    if (!datasetColumnsQuery.data || !datasetDataQuery.data) return;

    const updatedDatasetColumns = datasetColumnsQuery.data.filter(
      (col) => DatasetTypes.VisibleDataTypes.indexOf(col.type) !== -1
    );
    const tableColumns: Table.Column[] = updatedDatasetColumns.reduce(
      (columns: Table.Column[], datasetColumn: PeTypes.DatasetColumn) => {
        const col: Table.Column = {
          id: datasetColumn.uuid,
          isHighlighted: datasetColumn.uuid === highlightedColumnId,
          title: datasetColumn.title,
          label: datasetColumn.label,
          classes: classNames({ 'justify-end': isNumericValue(datasetColumn.type) }),
          suffixIcon: getSuffixIcon(datasetColumn.type),
          formatter: (v: unknown) => columnFormatter(v, datasetColumn.formatting),
          renderMenu: (column: Table.Column) => (
            <DatasetColumnMenu
              title={datasetColumn.title}
              label={datasetColumn.label ?? ''}
              formatting={datasetColumn.formatting ?? ''}
              type={datasetColumn.type}
              datasetId={datasetId}
              column={column}
              columnTitles={updatedDatasetColumns.map((c) => c.title)}
            />
          ),
          renderEdit: (value, cIdx, rIdx, anchor, onCloseRequest) => (
            <EditCell
              anchor={anchor}
              value={value}
              onCloseRequest={onCloseRequest}
              type={datasetColumn.type}
              onDone={(value) => handleCellEdit(cIdx, rIdx, value, datasetColumn.type)}
            />
          ),
          sortDirection: tableSort && tableSort.sortBy === datasetColumn.uuid ? tableSort.sortOrder : null,
          onSort: (sortDirection: Table.SortOrderDirection) => {
            setTableSort({ sortBy: datasetColumn.uuid, sortOrder: sortDirection });
          },
          onClick: () => handleColumnHighlightToggle(datasetColumn.uuid, true),
          onClickOutside: () => handleColumnHighlightToggle(datasetColumn.uuid, false),
        };
        return [...columns, col];
      },
      [DatasetTypes.ID_COLUMN]
    );

    setPagedColumns(tableColumns);

    setPagedRows(datasetDataQuery.data.rows);
  }, [datasetColumnsQuery.data, datasetDataQuery.data, highlightedColumnId]);

  const handleColumnPageChange = (page: number) => {
    setPagingState((prev) => ({
      ...prev,
      columnPageNumber: page,
    }));
  };

  const handleCellSelection = (cell: Table.SelectedCell | null) => {
    if (!cell) return;
    const columnId = pagedColumns[cell.cIdx]?.id;
    setHighlightedColumnId(columnId);
  };

  const handleRowPageChange = (page: number, action: DatasetTypes.PageAction) => {
    const IDColumnIndex = datasetColumnsQuery.data?.findIndex((col) => col.type === DatasetTypes.DataType.ID) ?? -1;
    const rows = datasetDataQuery.data?.rows ?? [];

    setPagingState((prev) => ({
      ...prev,
      rowPageNumber: page,
      pageData: {
        action,
        firstUuid: rows[0][IDColumnIndex] as string,
        lastUuid: rows[rows.length - 1][IDColumnIndex] as string,
      },
    }));
  };

  const isLoading =
    datasetDataQuery.isLoading ||
    datasetDataQuery.isIdle ||
    datasetColumnsQuery.isIdle ||
    datasetColumnsQuery.isLoading;

  const hasNoMoreRows = pagedRows.length < rowPageSize;

  return (
    <div className="m-2 flex h-full flex-col overflow-hidden">
      <div className="relative flex min-h-0 flex-grow overflow-auto bg-white">
        {selectedRows && selectedRows.length > 0 && tableMenu}
        <TableComponent
          columns={pagedColumns}
          rows={pagedRows}
          emptyTableComponent={isLoading ? null : <FilteredTableEmptyState />}
          freezeHeader
          freezeFirstColumn
          onSelectCell={handleCellSelection}
          selectedRows={selectedRows}
          onRowSelection={onRowSelection}
          rowsOffset={pagingState.rowPageNumber * rowPageSize}
          allowRowSelection
        />
      </div>
      <DatasetPagination
        datasetId={datasetId}
        rowsPerPage={rowPageSize}
        rowPage={pagingState.rowPageNumber}
        colPage={pagingState.columnPageNumber}
        onJumpToColumn={handleJumpToColumn}
        onRowPageChange={handleRowPageChange}
        onColumnPageChange={handleColumnPageChange}
        availableNumberOfColumns={totalNumberOfColumns}
        availableNumberOfRows={totalNumberOfRecords}
        hasNoMoreRows={hasNoMoreRows}
        isColumnDataLoading={datasetColumnsQuery.isLoading || datasetColumnsQuery.isIdle}
        isRowDataLoading={datasetDataQuery.isLoading || datasetDataQuery.isIdle}
      >
        <div className="flex w-full items-center justify-between gap-4">
          <OperateOnDatasetMenu datasetId={datasetId} disabled={filteringIsActive} />
          {datasetDataQuery.isLoading && (
            <Buttons.Link icon={<MUIcon className="animate-spin" name="loop" />} className="text-xs text-gray-600">
              Loading data...
            </Buttons.Link>
          )}
        </div>
      </DatasetPagination>
    </div>
  );
};

export default DatasetTable;
