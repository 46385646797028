import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SlideMenuOption } from '.';

interface Props {
  options: SlideMenuOption[];
  slide: PeTypes.StorySlide;
}

const SlideMenu: React.FC<Props> = ({ slide, options }) => {
  const handleSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    const option = options.find((o) => o.value === opt.value);
    option?.action(slide);
  };

  return (
    <Dropdowns.Dropdown
      icon={<MUIcon name="more_horiz" className="rounded-full bg-white p-1 text-gray-700 ring-1 ring-gray-200" />}
      iconClose={null}
      options={options}
      triggerClasses="shadow-none ring-0 rounded-full p-0 bg-transparent hover:bg-transparent hover:text-red-400 gap-2"
      onSelect={handleSelectDropdownOption}
      hAlignment="left"
      vAlignment="bottom"
      menuClasses="flex flex-col gap-2 py-2 w-[234px]"
    />
  );
};

export default SlideMenu;
