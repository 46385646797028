import React from 'react';
import { getSuffixIcon } from '../utils';

interface Props {
  title: string;
  label: string | undefined;
  type: number | string;
  id: string;
  sampleData: unknown[] | undefined;
  onSelect: (colKey: string) => void;
  selected?: boolean;
  showCheckbox?: boolean;
}

const DatasetColumnPickerItem: React.FC<Props> = ({
  title,
  type,
  id,
  sampleData,
  selected,
  onSelect,
  showCheckbox,
  label,
}) => {
  const sampleDataLabel = sampleData
    ? sampleData
        .map((x) => x ?? 'null')
        .join(', ')
        .substring(0, 128) + ' ...'
    : 'Loading ...';

  return (
    <button className="flex w-full cursor-pointer gap-2 px-4 py-2 hover:bg-gray-50" onClick={() => onSelect(id)}>
      <div className="pl-3 pr-1">{getSuffixIcon(type)}</div>
      <div className="flex min-w-0 grow flex-col items-start gap-1">
        <div className="w-full truncate text-left text-gray-800">{title}</div>
        {label && label !== title && (
          <div className="truncate rounded bg-gray-100 p-1 text-left text-xs text-gray-800">{label}</div>
        )}
        <div className="w-full truncate whitespace-nowrap text-left text-xs text-gray-500">{sampleDataLabel}</div>
      </div>
      {showCheckbox && (
        <input
          name="select column"
          id="column"
          type="checkbox"
          readOnly
          checked={selected}
          className="text-primary-600 focus:ring-primary-600 mx-3 h-4 w-4 rounded border-gray-300 hover:cursor-pointer"
        />
      )}
    </button>
  );
};

export default DatasetColumnPickerItem;
