import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../../../../assets/empty-filter-dataset.svg';

interface Props {
  onClick: () => void;
  isDatasetFilter?: boolean;
}

const DataFilterEmptyState: React.FC<Props> = ({ onClick, isDatasetFilter }) => (
  <div className="mt-16 flex h-full w-full items-start justify-center">
    <div className="flex flex-col items-center gap-10">
      <EmptyStateSVG />
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="flex flex-col gap-2">
          <span className="w-full text-center text-sm text-gray-700">{`Select 'Add Criteria' to filter your data.`}</span>
          {isDatasetFilter && (
            <span className="w-full text-center text-sm text-gray-700">You can create new datasets from filters.</span>
          )}
        </div>
        <Buttons.Secondary
          onClick={onClick}
          className="text-primary-600 h-[36px] w-[148px] gap-2 rounded-[999px]"
          icon={<MUIcon name="add" />}
        >
          Add criteria
        </Buttons.Secondary>
      </div>
    </div>
  </div>
);

export default DataFilterEmptyState;
