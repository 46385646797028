import { Hooks, Utils } from '@platform/maplibre';
import { ProjectTypes } from '@platform/types';
import { Map, MapGeoJSONFeature } from 'maplibre-gl';
import React, { useMemo, useState } from 'react';
import DataTooltipContent from './DataToolipContent';

const { useFeaturesUnderMouse2 } = Hooks;

export interface Props {
  mapObj: ProjectTypes.MapObject;
}

const DataTooltip: React.FC<Props> = ({ mapObj }) => {
  const [highlightedFeatures, setHighlightedFeatures] = useState<MapGeoJSONFeature[]>([]);
  const { ref, styleSpec } = mapObj;
  const { dataLayer } = styleSpec;

  const handleMouseOver = useMemo(
    () => (result: { ref: Map; features: MapGeoJSONFeature[] }[]) => setHighlightedFeatures(result[0].features),
    [setHighlightedFeatures]
  );

  const mapToListenTo = useMemo(() => {
    if (!ref) return [];
    const dataLayerId = Utils.getDataLayerFingerprint(dataLayer);
    const filteredOutDataLayerId = Utils.getFilteredOutDataLayerId(dataLayer);
    return [{ ref, sourceLayerIds: [dataLayerId, filteredOutDataLayerId] }];
  }, [ref, dataLayer]);

  useFeaturesUnderMouse2(mapToListenTo, 'mousemove', handleMouseOver);

  if (!highlightedFeatures.length) return null;

  return (
    <div className="flex min-w-[300px] max-w-[520px] flex-col gap-2 rounded-md bg-gray-900 py-2 text-sm text-white shadow-md">
      <DataTooltipContent mapObj={mapObj} feature={highlightedFeatures[0]} />
    </div>
  );
};

export default DataTooltip;
