import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { useQuery } from 'react-query';
import * as peApi from '../../pe.api';

interface Props {
  workspaces: PeTypes.Workspace[];
  activeWorkspace: PeTypes.Workspace;
  onChange: (workspace: PeTypes.Workspace) => void;
  onSwitchRequested: () => void;
  menuClose: () => void;
}

const WorkspacePicker: React.FC<Props> = ({ workspaces, activeWorkspace, onChange, onSwitchRequested, menuClose }) => {
  const handleWorkSpaceChange = (workspaceId: number) => {
    const selected = workspaces.find((w) => w.id === workspaceId);
    onChange(selected ?? workspaces[0]);
    menuClose();
    onSwitchRequested();
  };

  const unreadNotificationCountQuery = useQuery(['notification-count'], () => peApi.getUnreadNotificationCount());

  const filteredWorkspaces = workspaces.filter((workspace) => workspace.id !== activeWorkspace.id);

  return (
    <div className="flex w-[320px] flex-col">
      <div
        className="flex cursor-pointer items-center border-b border-gray-200 px-6 py-0.5 text-gray-600"
        onClick={() => onSwitchRequested()}
      >
        <MUIcon name="arrow_back" className="text-gray-600" />
        <div className="flex flex grow items-center justify-center px-4 py-4 text-sm">Switch to..</div>
      </div>
      <div className="flex flex-col">
        {filteredWorkspaces.map((workspace) => {
          const unreadNotificationCount = unreadNotificationCountQuery.data?.find(
            (el) => el.workspaceId === workspace.id
          );
          return (
            <div
              key={workspace.id}
              className="flex cursor-pointer items-center justify-between border-b py-2 last:border-none "
              onClick={() => handleWorkSpaceChange(workspace.id)}
            >
              <div className="flex w-full items-center justify-start gap-3 px-4 py-2 hover:bg-gray-100">
                <div className="relative flex h-[34px] w-[34px] shrink-0">
                  <img
                    src={`${workspace.imageURL}?${workspace.updatedAt}`}
                    className="h-full w-full rounded-[8px] border border-gray-200"
                    alt={workspace.name}
                  />
                  {unreadNotificationCount && (
                    <div className="bg-primary-600 absolute right-[-4px] top-[-4px] rounded-full px-1 text-xs text-white">
                      {unreadNotificationCount.count}
                    </div>
                  )}
                </div>
                <div className="flex min-w-0 flex-col text-sm">
                  <span className="truncate font-bold text-gray-900">{workspace.name}</span>
                  <span className="text-xs text-gray-600">{`ID: ${workspace.id}`}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default WorkspacePicker;
