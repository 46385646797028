import { useDatasetMetadata } from '@platform/shared/hooks';
import { Inputs } from '@platform/shared/ui';
import { MetadataTypes, ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import DataTable from './DataTable';

export const getTableElementId = (guid: string) => `table-${guid}`;

export interface Props {
  surveyName: string;
  datasetAbbreviation: string;
  selection: ProjectTypes.VariableSelection[];
  onVariableChange: (variables: MetadataTypes.Variable[], table: MetadataTypes.Table) => void;
  disabled?: boolean;
  allowMultiSelection?: boolean;
}

export const TablePicker: React.FC<Props> = ({
  surveyName,
  datasetAbbreviation,
  selection,
  onVariableChange,
  allowMultiSelection,
  disabled,
}) => {
  const tableGuid = selection[0]?.tableGuid;
  const [filterValue, setFilterValue] = useState('');
  const datasetQuery = useDatasetMetadata({ surveyName, datasetAbbreviation });

  useEffect(() => {
    if (!tableGuid) return;
    const openedElement = document.getElementById(getTableElementId(tableGuid));
    if (datasetQuery.data && openedElement) {
      openedElement.scrollIntoView();
    }
  }, [datasetQuery.data]);

  if (datasetQuery.isLoading || datasetQuery.isIdle) return <div className="p-4">Loading tables...</div>;
  if (datasetQuery.isError) return <>An error occurred</>;

  const datasetTables = Object.values(datasetQuery.data.tables);

  const tableOptions = datasetTables
    .map((dt) => ({ value: dt.uuid, name: `${dt.displayName} - ${dt.title}` }))
    .filter((option) => option.name.toUpperCase().includes(filterValue.toUpperCase()));

  return (
    <div className="flex min-h-0 flex-col border-t-[1px] border-t-gray-200">
      <div className="py-4 px-6">
        <Inputs.Input
          value={filterValue}
          inputClasses="h-6"
          onChange={(e) => setFilterValue(e.target.value)}
          placeholder="Search by keyword..."
        />
      </div>
      <div className="flex flex-col overflow-auto px-6 pt-3">
        {!tableOptions.length && (
          <div className="mt-3 flex flex-col gap-6 py-12 px-4 text-sm text-gray-700">
            <div>
              No search results for <strong>{filterValue}</strong>
            </div>
            <div className="flex flex-col">
              <span>Make sure that all words are spelled correctly.</span>
              <span>Try different keywords.</span>
              <span>Try more general keywords.</span>
              <span>Try fewer keywords.</span>
            </div>
          </div>
        )}
        {tableOptions.map((option) => {
          return (
            <DataTable
              key={option.value}
              option={option}
              surveyName={surveyName}
              datasetAbbreviation={datasetAbbreviation}
              onVariableChange={onVariableChange}
              selection={selection}
              disabled={disabled}
              allowMultiSelection={allowMultiSelection}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TablePicker;
