// @ts-ignore
import { setOptions, unzip, ZipEntry } from 'unzipit';
import { extractExtension } from './utils';

setOptions({
  workerURL: '/assets/workers/unzipit/index.js',
  numWorkers: 2,
});

export default async (file: File, allowedExtensions: string[]): Promise<{ files: File[]; error?: boolean }> =>
  new Promise((resolve) => {
    return (async () => {
      const { entries } = await unzip(file);

      const allEntries = Object.values(entries);
      const allowedEntries = allEntries.filter((entry: ZipEntry) =>
        allowedExtensions.includes(extractExtension(entry.name))
      );
      if (allowedEntries.length < allEntries.length) {
        return resolve({ files: [], error: true });
      }

      // read allowed entries into files
      const resolvedPromises = await Promise.all(allowedEntries.map((entry: ZipEntry) => readEntryIntoFile(entry)));

      // extract only supported files (some promises resolved as null (unsupported)
      const files = resolvedPromises.filter((f) => f != null) as File[];
      resolve({ files });
    })();
  });

const readEntryIntoFile = async (entry: ZipEntry): Promise<File | null> => {
  const blob = await entry.blob();
  return new File([blob], entry.name);
};
