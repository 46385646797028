import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useSessionContext } from './contexts/SessionContext';
import * as peApi from './pe.api';

const API_KEY = process.env.NX_USER_PILOT_API_KEY;

const useIntegration = API_KEY != null;

if (API_KEY != null) {
  Userpilot.initialize(API_KEY);
}

const UserPilotIntegration: React.FC = () => {
  const { jwtToken } = useSessionContext();
  const location = useLocation();

  const userQuery = useQuery(['session', jwtToken], () => peApi.me(), {
    enabled: useIntegration && jwtToken != null,
    onSuccess: (user) => {
      if (user.id === -1) return;

      try {
        Userpilot.identify(user.id.toString(), {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        });
      } catch (e) {
        console.warn('Failed to identify user at UserPilotIntegration', e);
      }
    },
  });

  React.useEffect(() => {
    if (!useIntegration) return;
    if (!userQuery.data) return;

    Userpilot.reload();
  }, [location, useIntegration]);

  return null;
};

export default UserPilotIntegration;
