import { DatasetTypes } from '@platform/types';
import axios from 'axios';

export const parse = (value: unknown, valueType: DatasetTypes.DataType): unknown => {
  if (value === null) return null;
  if (typeof value === 'string' && value.trim().length === 0) {
    return null;
  }
  switch (valueType) {
    case DatasetTypes.DataType.DOUBLE:
    case DatasetTypes.DataType.INTEGER:
      return Number(value);
    case DatasetTypes.DataType.TEXT:
      return String(value);
    case DatasetTypes.DataType.BOOLEAN:
      return value === true || value === 'true';
    default:
      return value;
  }
};

export const isNumericValue = (valueType: DatasetTypes.DataType) =>
  valueType === DatasetTypes.DataType.INTEGER || valueType === DatasetTypes.DataType.DOUBLE;

export const uploadDatasetToS3 = async (signedUrl: string, file: File) => {
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
      // 'Content-Length': contentLength,
    },
  });
};
