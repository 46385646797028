import React from 'react';

interface Props {
  url: string;
  className?: string;
  useLinearGradient?: boolean;
}

const Thumb: React.FC<Props> = ({ url, className = '', useLinearGradient = false }) => {
  // Prefer images before urls
  let backgroundImage;

  if (url) {
    backgroundImage = useLinearGradient
      ? `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.00) 100%), url("${url}")`
      : `url("${url}")`;
  }

  return (
    <div
      className={`rounded bg-gray-100 bg-cover bg-center bg-no-repeat ${className}`}
      style={backgroundImage ? { backgroundImage } : {}}
    />
  );
};

export default Thumb;
