import { PeTypes } from '@platform/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../LinkGenerator';
import DownloadCsvButton from '../DownloadCsvButton';
import { UpdatedAgo } from '../shared';
import DatasetInfo from './Dataset/DatasetInfo';
import MoreMenu from './Dataset/MoreMenu';

interface Props {
  dataset: PeTypes.DatasetListItem;
}

const DatasetListItem: React.FC<Props> = ({ dataset }) => {
  const { id } = dataset;
  const hasUnpublishedChanges = dataset.unPublishedChangesCount && dataset.unPublishedChangesCount > 0;

  return (
    <div className="flex w-full items-center justify-between border-b bg-white">
      <Link
        to={generateLink('dataset', { workspaceId: dataset.ownerId, datasetId: dataset.id })}
        className="flex h-full w-full items-center justify-between py-4 px-8"
      >
        <DatasetInfo dataset={dataset} />
        {hasUnpublishedChanges && dataset.hasEverBeenPublished && (
          <div className="shrink-0 rounded-xl bg-gray-100 p-1 px-3 text-xs text-gray-700">Unpublished changes</div>
        )}
        {!dataset.hasEverBeenPublished && (
          <div className="shrink-0 rounded-xl bg-gray-100 p-1 px-3 text-xs text-gray-700">Draft</div>
        )}
        <div className="min-w-[150px] text-end text-sm text-gray-500">
          {dataset.editedAt && <UpdatedAgo prefixLabel="" dateTime={dataset.editedAt} />}
        </div>
      </Link>
      <div className="flex items-center gap-2 px-3">
        <div className="ml-auto text-sm text-gray-400">
          <DownloadCsvButton datasetId={id} />
        </div>
        <MoreMenu dataset={dataset} />
      </div>
    </div>
  );
};

export default DatasetListItem;
