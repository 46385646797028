import { Buttons, Inputs, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../../pe.api';

type Inputs = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

interface Props {
  userId: number;
  onCancel: () => void;
}

const AccountSignInPasswordForm: React.FC<Props> = ({ userId, onCancel }) => {
  const [oldPasswordVisible, setOldPasswordVisible] = useState<boolean>(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const updateAccountInfoMutation = useMutation({
    mutationFn: (payload: PeTypes.AccountInfoPayload) => peApi.updateAccountInfo(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['session']);
      onCancel();
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const watchedFormData = watch();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    updateAccountInfoMutation.mutate({ ...data, userId: userId });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-6 pt-4"
      data-cy="update-account-form"
      autoComplete={'off'}
    >
      <div>
        <label htmlFor="oldPassword" className="mb-2 block text-xs font-semibold text-gray-800">
          Current password
        </label>
        <Controller
          control={control}
          name="oldPassword"
          rules={{ required: 'Current password is required' }}
          render={({ field }) => (
            <Inputs.Input
              type={oldPasswordVisible ? 'text' : 'password'}
              id="oldPassword"
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
                  disabled={!watchedFormData.oldPassword.length}
                  data-rh={oldPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={oldPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.oldPassword.length === 0,
                      'text-gray-600': watchedFormData.oldPassword.length > 0,
                    })}
                  />
                </button>
              }
              placeholder="Enter current password"
              classes="rounded-lg h-[42px] font-normal"
              data-cy="oldPassword-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.oldPassword && <p className="pt-1 text-xs text-red-600">{errors.oldPassword.message}</p>}
      </div>
      <div>
        <label htmlFor="newPassword" className="mb-2 block text-xs font-semibold text-gray-800">
          New password
        </label>
        <Controller
          control={control}
          name="newPassword"
          rules={{ required: 'New password is required' }}
          render={({ field }) => (
            <Inputs.Input
              type={newPasswordVisible ? 'text' : 'password'}
              id="newPassword"
              placeholder="Enter new password"
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  disabled={!watchedFormData.newPassword.length}
                  data-rh={newPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={newPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.newPassword.length === 0,
                      'text-gray-600': watchedFormData.newPassword.length > 0,
                    })}
                  />
                </button>
              }
              classes="rounded-lg h-[42px] font-normal"
              data-cy="newPassword-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.newPassword && <p className="pt-1 text-xs text-red-600">{errors.newPassword.message}</p>}
      </div>
      <div>
        <label htmlFor="confirmPassword" className="mb-2 block text-xs font-semibold text-gray-800">
          Confirm password
        </label>
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: 'Confirm password is required',
            validate: (value) => value === getValues('newPassword') || 'Passwords do not match',
          }}
          render={({ field }) => (
            <Inputs.Input
              type={confirmPasswordVisible ? 'text' : 'password'}
              id="confirmPassword"
              placeholder="Enter confirm password"
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  disabled={!watchedFormData.confirmPassword.length}
                  data-rh={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={confirmPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.confirmPassword.length === 0,
                      'text-gray-600': watchedFormData.confirmPassword.length > 0,
                    })}
                  />
                </button>
              }
              classes="rounded-lg h-[42px] font-normal"
              data-cy="confirmPassword-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {errors.confirmPassword && <p className="pt-1 text-xs text-red-600">{errors.confirmPassword.message}</p>}
      </div>
      <div className="flex w-full items-center justify-end gap-3 text-xs font-normal text-gray-500">
        <Buttons.Secondary type="button" className="py-2 px-3" onClick={() => onCancel()}>
          Cancel
        </Buttons.Secondary>
        <Buttons.Primary
          type="submit"
          data-cy="submit-button"
          className="py-2 px-3"
          disabled={
            !watchedFormData.confirmPassword.length ||
            !watchedFormData.newPassword.length ||
            !watchedFormData.confirmPassword.length ||
            updateAccountInfoMutation.isLoading
          }
        >
          Save password
        </Buttons.Primary>
      </div>
      {updateAccountInfoMutation.isError && (
        <div className="text-xs text-red-400" data-cy="error-message">
          Failed to update account password.
        </div>
      )}
      {updateAccountInfoMutation.isSuccess && (
        <div className="text-xs text-green-500" data-cy="error-message">
          The account password has been successfully updated.
        </div>
      )}
    </form>
  );
};

export default AccountSignInPasswordForm;
