import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { PROFILE_DROPDOWN_OPTIONS } from '../../enums/dropdown';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import { UpdatedAgo } from '../shared';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import CopyProfileModal from './CopyProfileModal';
import ProfileWatchButton from './ProfileWatchButton';
import RenameProfileModal from './RenameProfileModal';

interface Props {
  profile: PeTypes.Profile;
}

const ProfilesListItem: React.FC<Props> = ({ profile }) => {
  const queryClient = useQueryClient();
  const { activeWorkspace } = useWorkspaceContext();

  const [requestedAction, setRequestedAction] = useState<ProjectTypes.MenuActions | null>(null);

  const deleteProfileMutation = useMutation({
    mutationFn: () => peApi.softDeleteProfile(profile.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects', activeWorkspace.id] });
    },
  });

  const onDeleteProfile = () => {
    deleteProfileMutation.mutate();
    setRequestedAction(null);
  };

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case ProjectTypes.MenuActions.DELETE:
        setRequestedAction(ProjectTypes.MenuActions.DELETE);
        break;
      case ProjectTypes.MenuActions.COPY:
        setRequestedAction(ProjectTypes.MenuActions.COPY);
        break;
      case ProjectTypes.MenuActions.RENAME:
        setRequestedAction(ProjectTypes.MenuActions.RENAME);
        break;
      default:
        return;
    }
  };

  const { id, title, watched, editedAt, projectId } = profile;

  return (
    <>
      <Link
        to={generateLink('map', { workspaceId: activeWorkspace.id, mapId: id, mode: 'edit' })}
        className="flex select-none flex-wrap items-center justify-between border-b bg-white p-4 pl-10 last:border-b-0"
      >
        <MUIcon name="location_on" className="mr-4 h-5 w-5 text-gray-500" aria-hidden="false" />
        <div className="flex-1 truncate font-semibold" data-rh={title}>
          {title}
        </div>
        <div className="flex-1">
          {editedAt && <UpdatedAgo dateTime={editedAt} className="flex-1 text-sm text-gray-400" />}
        </div>
        <div className="ml-auto mr-4 text-sm">
          <ProfileWatchButton profileId={id} watched={watched} />
        </div>
        <div onClick={(e) => e.preventDefault()}>
          <Dropdowns.Dropdown
            icon={<MUIcon name="more_horiz" className="text-gray-600" aria-hidden="false" />}
            iconClose={null}
            triggerClasses={'rounded-full border-gray-200 p-2'}
            options={PROFILE_DROPDOWN_OPTIONS}
            onSelect={onSelectDropdownOption}
            hAlignment="right"
          />
        </div>
      </Link>
      {requestedAction === ProjectTypes.MenuActions.DELETE && (
        <DeleteConfirmationModal
          title="Delete map"
          message={`Are you sure you want to delete this map? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={onDeleteProfile}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.COPY && (
        <CopyProfileModal
          profileId={id}
          profileTitle={title}
          activeProjectId={projectId ?? undefined}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.RENAME && (
        <RenameProfileModal
          profile={profile}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
        />
      )}
    </>
  );
};

export default ProfilesListItem;
