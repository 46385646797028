import { useEffect } from 'react';
import { useMapsContext } from '../MapsContext';
import { MapObjectClickHandler } from '../types';

export default function useMapObjectClick(handler: MapObjectClickHandler) {
  const { onClickMap } = useMapsContext();

  useEffect(() => {
    onClickMap(handler);
    return () => {
      onClickMap(null);
    };
  }, []);
}
