import { MetadataTypes } from '@platform/types';
import axios from 'axios';
import { DOMParser } from 'xmldom';

//@ts-ignore: MetadataParser must be turned into .ts
import { datasetToJson, metadataToJson, surveysToJson, tablesToJson, tableToJson } from './MetadataParser';

const metedataApi = axios.create({
  baseURL: process.env['NX_API_METADATA'],
  headers: {
    Accept: 'application/xml',
  },
});

export const getPartialMetadata = async (tables: string[]): Promise<MetadataTypes.Metadata> => {
  const params = {
    cmd: 'PartialMeta',
    Tables: tables.join(','),
  };

  const response = await metedataApi.get(`/SeMetadata.ashx`, { params });
  const asXml = new DOMParser().parseFromString(response.data, 'text/xml');
  return metadataToJson(asXml.documentElement) as unknown as MetadataTypes.Metadata;
};

export const getSurveys = async (surveyNames: string[]): Promise<MetadataTypes.Survey[]> => {
  if (!surveyNames.length) return [];

  const params = {
    cmd: 'Surveys',
    Names: surveyNames.join(','),
  };

  const response = await metedataApi.get(`/SeMetadata.ashx`, { params });
  const asXml = new DOMParser().parseFromString(response.data, 'text/xml');
  return surveysToJson(asXml.documentElement) as unknown as MetadataTypes.Survey[];
};

export const loadDataset = async (surveyName: string, datasetAbbrevation: string): Promise<MetadataTypes.DataSet> => {
  const params = {
    cmd: 'Dataset',
    SurveyName: surveyName,
    DatasetName: datasetAbbrevation,
    IncludeVariables: false,
  };

  const response = await metedataApi.get('/SeMetadata.ashx', { params });

  const asXml = new DOMParser().parseFromString(response.data, 'text/xml');
  return datasetToJson(asXml.documentElement) as unknown as MetadataTypes.DataSet;
};

export const loadCategoryTables = async (
  surveyNames: string[],
  categoryName: string,
  includeVariables = true
): Promise<MetadataTypes.Table[]> => {
  const params = {
    cmd: 'CategoryTables2',
    SurveyName: surveyNames.join(','),
    CategoryName: categoryName,
    IncludeVariables: includeVariables,
  };

  const response = await metedataApi.get('/SeMetadata.ashx', { params });
  const asXml = new DOMParser().parseFromString(response.data, 'text/xml');
  return tablesToJson(asXml.documentElement) as unknown as MetadataTypes.Table[];
};

export const loadTable = async (
  surveyName: string,
  datasetName: string,
  tableName: string
): Promise<MetadataTypes.Table> => {
  const params = {
    cmd: 'Table',
    SurveyName: surveyName,
    DatasetName: datasetName,
    TableName: tableName,
  };

  const response = await metedataApi.get('/SeMetadata.ashx', { params });
  const asXml = new DOMParser().parseFromString(response.data, 'text/xml');
  return tableToJson(asXml.documentElement) as unknown as MetadataTypes.Table;
};

export const getSystemFilterSets = async (
  surveyName: string,
  datasetAbbrv: string
): Promise<MetadataTypes.SystemCategoryFilters[]> => {
  const params = {
    cmd: 'PartialMeta',
    Tables: `${surveyName}.${datasetAbbrv}:*`,
  };

  const response = await metedataApi.get(`/SeMetadata.ashx`, { params });
  const asXml = new DOMParser().parseFromString(response.data, 'text/xml');

  return (metadataToJson(asXml.documentElement) as unknown as MetadataTypes.Metadata).systemCategoryFilters;
};
