import { MapsContext } from '@platform/maplibre';
import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSessionContext } from '../../contexts/SessionContext';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { hasText } from '../../helpers/helpers';
import { useUploadToS3 } from '../../hooks';
import * as peApi from '../../pe.api';
import { containCanvasToPng } from '../../utils';
import Analysis from '../MapSidebar/Analysis';
import MapSideBar, { SHARED_OPTIONS } from '../MapSidebar/MapSideBar';
import { MapSideBarContextProvider } from '../MapSidebar/MapSideBarContext';
import MoreMenu from '../MapSidebar/MoreMenu';
import SlideStory from '../MapSidebar/SlideStory';
import SlideMapsWrapper from './SlideMapsWrapper';
import SlideNavi from './SlideNavi';
import StoryLeftDrawer from './StoryLeftDrawer';

interface Props {
  story: PeTypes.Story;
  slide: PeTypes.StorySlide;
  slideIndex: number;
  mode: 'edit' | 'view';
  onCurrentSlideChangeRequest: (slideId: string) => void;
  onMoveToPrevSlideRequest: () => void;
  onMoveToNextSlideRequest: () => void;
  onThumbChange?: (newThumbImg: string) => void;
}

const Slide: React.FC<Props> = ({
  story,
  slide,
  onMoveToPrevSlideRequest,
  onMoveToNextSlideRequest,
  slideIndex,
  mode,
  onCurrentSlideChangeRequest,
  onThumbChange,
}) => {
  const isEditMode = mode === 'edit';
  const [slideThumbsInMemory, setSlideThumbs] = useState<Record<string, string>>({});
  const { jwtToken } = useSessionContext();
  const { activeWorkspace } = useWorkspaceContext();

  const uploadThumbToS3 = useUploadToS3(isEditMode ? `${slide.id}/thumb.png` : '');
  const queryClient = useQueryClient();

  const slideThumbMutation = useMutation({
    mutationFn: (path: string) => peApi.updateSlideThumbPath(story.id, slide.id, path),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['recent-stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['story-slides', slide.storyId] });
    },
  });

  const slideTimestampMutation = useMutation({
    mutationFn: () => peApi.updateSlideTimestamp(story.id, slide.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['story', story.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['stories', story.projectId] });
    },
  });

  const handleThumb = async (canvas: HTMLCanvasElement) => {
    if (!isEditMode) return;

    const targetWidth = 600;
    const targetHeight = targetWidth / (16.0 / 9.0);

    const mergedBase64Thumb = containCanvasToPng(canvas, targetWidth, targetHeight);
    const newThumbPath = await uploadThumbToS3(mergedBase64Thumb);

    if (newThumbPath && newThumbPath !== slide.thumbPath) {
      slideThumbMutation.mutate(newThumbPath);
    }
    setSlideThumbs((prev) => ({ ...prev, [slide.id]: mergedBase64Thumb }));
    onThumbChange?.(mergedBase64Thumb);
  };

  const handleUpdate = () => slideTimestampMutation.mutate();

  return (
    <MapsContext.MapsContextProvider
      mapContextId={slide.mapContextId}
      jwtToken={jwtToken}
      doSave={isEditMode}
      onUpdate={handleUpdate}
      onThumb={isEditMode ? handleThumb : undefined}
      apis={{
        mapContext: {
          get: peApi.getMapContext,
          update: peApi.patchMapContextItems,
        },
        baseMaps: { get: peApi.getBaseMapStyle },
        dataSourceLayers: { get: peApi.getDataSourceLayers },
      }}
    >
      <div className="flex h-full w-full">
        <MapsContext.MapsConsumer>
          {({ state }) => {
            const mapSideBarOptions = [];

            const showNotesPanel = isEditMode || hasText(slide.text);
            if (showNotesPanel) {
              mapSideBarOptions.push({
                icon: <MUIcon name="article" className="text-[24px]" />,
                title: 'Story',
                key: 'SLIDE_STORY',
                content: <SlideStory slide={slide} isEditMode={isEditMode} currentIndex={slideIndex} />,
              });
            }

            if (isEditMode) {
              mapSideBarOptions.push(SHARED_OPTIONS.SEARCH);
              mapSideBarOptions.push(SHARED_OPTIONS.DATA);
            }

            const showAnalysisPanel =
              isEditMode || state.registeredMaps.some((x) => !!x.styleSpec.geoFeatureSelection?.length);
            if (showAnalysisPanel) {
              mapSideBarOptions.push({
                icon: <MUIcon name="monitoring" className="text-[24px]" />,
                title: 'Analysis',
                key: 'ANALYSIS',
                content: <Analysis isEditMode={isEditMode} />,
              });
            }

            return (
              <MapSideBarContextProvider defaultView={isEditMode ? undefined : mapSideBarOptions[0]?.key}>
                <MapSideBar
                  options={mapSideBarOptions}
                  sidebarType="STORY"
                  leftDrawer={<StoryLeftDrawer story={story} />}
                >
                  <div className="flex flex-grow flex-col justify-between">
                    <div>{isEditMode && <MoreMenu classNames="hover:bg-acai-700" />}</div>
                    <SlideNavi
                      isEditMode={isEditMode}
                      currentSlide={slide}
                      story={story}
                      currentIndex={slideIndex}
                      totalCount={story.slidesCount}
                      onNext={onMoveToNextSlideRequest}
                      onPrev={onMoveToPrevSlideRequest}
                      onJumpTo={onCurrentSlideChangeRequest}
                      thumbsInMemory={slideThumbsInMemory}
                    />
                  </div>
                </MapSideBar>
              </MapSideBarContextProvider>
            );
          }}
        </MapsContext.MapsConsumer>

        <MapsContext.MapsConsumer>
          {({ state }) => {
            if (!state.registeredMaps.length) return null;
            return <SlideMapsWrapper story={story} slide={slide} mode={mode} />;
          }}
        </MapsContext.MapsConsumer>
      </div>
    </MapsContext.MapsContextProvider>
  );
};

export default Slide;
