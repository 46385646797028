import { Dropdowns, Inputs, Modal } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { typeDropdownOptions } from '../../Import/DataFormatting/DataFormatting';
import { NumberFormatDropdown } from '../../Import/DataFormatting/DataFormattingItem';
import { columnNameValidator } from './DatasetTable/utils';

type Input = {
  title: string;
  label: string;
  type: number;
  formatting: string;
};

interface Props {
  title: string;
  label: string;
  formatting: string;
  type: number;
  columnTitles: string[];
  onDone: (values: PeTypes.EditColumnMetadataRequest) => void;
  onCloseRequest: () => void;
}

const filterOptions = [...typeDropdownOptions];
const EditColumnModal: React.FC<Props> = ({ onCloseRequest, title, columnTitles, onDone, label, type, formatting }) => {
  // we agreed to treat integers as decimals/numbers to avoid both types
  const colType = type === DatasetTypes.DataType.INTEGER ? DatasetTypes.DataType.DOUBLE : type;
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Input>({ defaultValues: { title, label, type: colType, formatting } });
  const watchedValues = watch();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Input> = (data) => {
    if (data.type !== type && !showConfirmationModal) {
      setShowConfirmationModal(true);
      return;
    }

    const newValues: PeTypes.EditColumnMetadataRequest = {
      title: data.title !== title ? data.title : null,
      label: data.label !== label ? data.label : null,
      type: data.type !== type ? data.type : null,
      formatting: data.formatting !== formatting && data.type === DatasetTypes.DataType.DOUBLE ? data.formatting : null,
    };
    onDone(newValues);
    onCloseRequest();
  };

  const editMetadataForm = (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-8 flex flex-col gap-8">
      <Controller
        name="title"
        control={control}
        rules={{
          validate: columnNameValidator(columnTitles, watchedValues.title),
          required: 'Column name cannot be empty',
        }}
        render={({ field }) => {
          return (
            <div>
              <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
                Name
              </label>
              <Inputs.Input
                autoFocus
                id="title"
                roundedClasses="rounded-lg"
                value={field.value}
                className="w-full py-1 text-sm outline-none"
                onChange={(opt) => field.onChange(opt.target.value)}
              />
            </div>
          );
        }}
      />
      <Controller
        name="type"
        control={control}
        render={({ field }) => {
          return (
            <div>
              <label htmlFor="type" className="mb-2 block text-sm font-semibold text-gray-800">
                Column type
              </label>
              <Dropdowns.Dropdown
                options={filterOptions}
                value={field.value}
                menuWidth="full"
                triggerClasses="bg-white w-full flex grow-0 ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200 truncate"
                listClasses="max-h-[280px]"
                onSelect={(opt) => field.onChange(opt.value)}
              />
            </div>
          );
        }}
      />
      {watchedValues.type === DatasetTypes.DataType.DOUBLE && (
        <Controller
          name="formatting"
          control={control}
          render={({ field }) => {
            return (
              <div>
                <label htmlFor="formatting" className="mb-2 block text-sm font-semibold text-gray-800">
                  Formatting
                </label>
                <Dropdowns.Dropdown
                  options={NumberFormatDropdown}
                  value={field.value}
                  menuWidth="full"
                  triggerClasses="bg-white w-full flex grow-0 ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200 truncate"
                  listClasses="max-h-[280px]"
                  onSelect={(opt) => field.onChange(opt.value)}
                />
              </div>
            );
          }}
        />
      )}
      <Controller
        name="label"
        control={control}
        render={({ field }) => {
          return (
            <div>
              <label htmlFor="label" className="mb-2 block text-sm font-semibold text-gray-900">
                Alternate ˝Friendly˝ Name <span className="text-xs font-normal text-gray-700">(optional)</span>
              </label>
              <Inputs.Input
                autoFocus
                id="title"
                roundedClasses="rounded-lg"
                value={field.value}
                className="w-full py-1 text-sm outline-none"
                onChange={(opt) => field.onChange(opt.target.value)}
              />
            </div>
          );
        }}
      />
      {errors.title && <span className="text-red-400">{errors.title.message}</span>}
    </form>
  );

  const confirmationModalContent = (
    <div className="mb-8 flex flex-col gap-4 text-sm text-gray-700">
      <div className="mt-2">Changing the column type will impact the records and it may result in a loss of data.</div>
      <div className="font-semibold">This cannot be undone.</div>
      <div>Please use caution and consider duplicating the column before changing the column type.</div>
    </div>
  );

  const okDisabled =
    !showConfirmationModal &&
    title === watchedValues.title &&
    label === watchedValues.label &&
    colType === watchedValues.type &&
    formatting === watchedValues.formatting;

  const handleCancelRequest = showConfirmationModal ? () => setShowConfirmationModal(false) : onCloseRequest;

  return (
    <Modal
      title={showConfirmationModal ? 'Are you sure?' : 'Edit column'}
      okLabel={showConfirmationModal ? 'Confirm change' : 'Save'}
      cancelLabel={showConfirmationModal ? 'Back' : 'Cancel'}
      okDisabled={okDisabled}
      className="w-[448px]"
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
      onCancel={handleCancelRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
      titleClasses="text-base mb-3"
    >
      {showConfirmationModal ? confirmationModalContent : editMetadataForm}
    </Modal>
  );
};

export default EditColumnModal;
