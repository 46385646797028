import { Buttons } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import EmptyState from '../shared/EmptyState';
import StoriesListItem from './StoriesListItem';

interface Props {
  projectId?: string;
  stories: PeTypes.Story[] | undefined;
}

const StoriesList: React.FC<Props> = ({ projectId, stories }) => {
  if (!stories) {
    return (
      <div className="mt-10 flex w-full items-center justify-center">
        <span className="text-gray-500">Loading stories..</span>
      </div>
    );
  }
  if (stories.length === 0) {
    return (
      <div className="py-5">
        <EmptyState
          trigger={<Buttons.Primary>Create a Story</Buttons.Primary>}
          action={PeTypes.Action.NEW_STORY}
          projectId={projectId}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2 bg-white">
      <div className="w-full select-none bg-white">
        {stories.map((item) => (
          <StoriesListItem key={item.id} story={item} />
        ))}
      </div>
    </div>
  );
};

export default StoriesList;
