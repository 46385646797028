import { MapSpecModifiers } from '@platform/maplibre';
import { useSurveys } from '@platform/shared/hooks';
import { Buttons, DataBrowsing, Dropdowns, MUIcon, PanelHeader } from '@platform/shared/ui';
import { MetadataTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ReactComponent as EmptyStateSVG } from '../../../assets/empty-state-fabc.svg';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import * as peApi from '../../pe.api';
import AreaFilter, { EQUAL_TO, GREATER_THAN } from './AreaFilter';

const options: Dropdowns.DropdownOption[] = [
  { name: 'And', value: 'all' },
  { name: 'Or', value: 'any' },
  { name: 'Exclude', value: '!' },
];

type TempDataFilter = Partial<ProjectTypes.DataFilter>;
type Rule = 'all' | 'any' | '!';

interface Props {
  mapObjects: ProjectTypes.MapObject[];
  onClosePanel: () => void;
}

const updateFilterOnMap = (mapObj: ProjectTypes.MapObject, newFilterData: ProjectTypes.FiltersByData) =>
  mapObj.updateMapSpec(MapSpecModifiers.addFilterByCriteria(mapObj.styleSpec, newFilterData));

const AreaFilterWrapper: React.FC<Props> = ({ mapObjects, onClosePanel }) => {
  const [tempFilter, setTempFilter] = useState<TempDataFilter | undefined>();
  const [primaryMapObj] = mapObjects;
  const { activeWorkspace } = useWorkspaceContext();

  const selection = primaryMapObj.styleSpec.dataLayer.selection;
  const surveyQuery = useSurveys({ surveysApi: peApi.getSurveys, activeWorkspaceId: activeWorkspace.id });

  const { rule, filters } = primaryMapObj.styleSpec.dataLayer.filtersByData ?? {
    rule: 'all',
    filters: [],
  };

  const handleSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    mapObjects.forEach((mapToApply) => updateFilterOnMap(mapToApply, { rule: opt.value as Rule, filters }));
  };

  const clearFilters = () => {
    const newFilterByData: ProjectTypes.FiltersByData = {
      rule,
      filters: [],
    };

    mapObjects.forEach((mapToApply) => updateFilterOnMap(mapToApply, newFilterByData));
  };

  const handleFilterRemove = (filterToBeRemoved: ProjectTypes.DataFilter) => {
    const appliedFilters = [...filters];
    const indexOfFilter = appliedFilters.findIndex((f) => f.id === filterToBeRemoved.id);
    appliedFilters.splice(indexOfFilter, 1);

    const newFilterByData: ProjectTypes.FiltersByData = {
      rule,
      filters: appliedFilters,
    };

    mapObjects.forEach((mapToApply) => updateFilterOnMap(mapToApply, newFilterByData));
  };

  const handleFilterUpdate = (updatedFilter: ProjectTypes.DataFilter) => {
    const appliedFilters = [...filters];

    const indexOfUpdatedFilter = appliedFilters.findIndex((f) => f.id === updatedFilter.id);

    if (indexOfUpdatedFilter === -1) {
      // adding new filter
      appliedFilters.push(updatedFilter);
    } else {
      // updating existing filter
      appliedFilters[indexOfUpdatedFilter] = updatedFilter;
    }

    const newFilterByData: ProjectTypes.FiltersByData = {
      rule,
      filters: appliedFilters,
    };

    mapObjects.forEach((mapToApply) => updateFilterOnMap(mapToApply, newFilterByData));
  };

  const handleVariableChange = async (
    newSelection: ProjectTypes.VariableSelection[],
    tableMeta: MetadataTypes.Table
  ) => {
    if (!newSelection.length) return;
    if (!tempFilter) return;

    const [selectedVariable] = newSelection;

    const variableMetadata = tableMeta.variables.find((v) => v.uuid === selectedVariable.variableGuid);

    if (!variableMetadata) {
      console.warn('No metadata found. Existing...');
      return;
    }

    let type;
    let operator;
    switch (true) {
      case variableMetadata.dataType === 'String': // variable is a string
        type = ProjectTypes.DataFilterType.STRING;
        operator = EQUAL_TO.value;
        break;
      case variableMetadata.dataType !== 'String' && variableMetadata.varType === 'None': // variable is enumerated (dataType is numerical but its value is coded)
        type = ProjectTypes.DataFilterType.ENUM;
        operator = EQUAL_TO.value;
        break;
      default:
        type = ProjectTypes.DataFilterType.COUNT;
        operator = GREATER_THAN.value;
        break;
    }

    const updatedFilter: ProjectTypes.DataFilter = {
      operator,
      type,
      id: tempFilter.id ?? uuid(),
      value: '',
      variable: selectedVariable,
    };

    handleFilterUpdate(updatedFilter);

    setTempFilter(undefined);
  };

  const surveyToLoad = surveyQuery.data?.find((s) => s.metadata.name === selection[0].surveyName);

  const emptyFiltersState = (
    <div className="mt-16 flex h-full w-full items-start justify-center">
      <div className="flex flex-col items-center gap-12">
        <EmptyStateSVG />
        <div className="flex flex-col items-center justify-center gap-4">
          <span className="w-10/12 text-center text-sm text-gray-600">
            Discover areas on the map that match your specified criteria
          </span>
          <Buttons.Secondary
            className="text-primary-600 w-[148px] gap-2 rounded-[999px]"
            icon={<MUIcon name="add" />}
            onClick={() => setTempFilter({})}
          >
            Add criteria
          </Buttons.Secondary>
        </div>
      </div>
    </div>
  );

  const filtersWrapper = (
    <>
      <div className="flex w-full items-center justify-between px-6">
        <Dropdowns.Dropdown
          hAlignment="left"
          customLabel={options.find((option) => option.value === rule)?.name}
          onSelect={handleSelectDropdownOption}
          options={options}
          triggerClasses="text-sm font-semibold text-gray-700 bg-white border border-gray-300 ring-0 gap-3"
        />
        <button className="text-primary-700 cursor-pointer px-2 text-sm font-semibold" onClick={clearFilters}>
          Clear filters
        </button>
      </div>
      <div className="flex w-full flex-col items-center gap-4 overflow-auto px-6">
        {filters.map((filter) => (
          <div className="flex flex-col gap-4" key={filter.id}>
            <div className="ml-5 h-[16px] w-[2px] bg-gray-200"></div>
            <AreaFilter
              filter={filter}
              onUpdateFilter={handleFilterUpdate}
              onRemoveFilter={handleFilterRemove}
              onVariableChangeRequest={(filter: ProjectTypes.DataFilter) => setTempFilter(filter)}
            />
          </div>
        ))}
        <div className="flex w-full pb-4 text-sm font-semibold">
          <button className="text-primary-600 flex cursor-pointer gap-2" onClick={() => setTempFilter({})}>
            <MUIcon name="add" />
            <span>Add criteria</span>
          </button>
        </div>
      </div>
    </>
  );

  const browser =
    tempFilter && surveyToLoad ? (
      <div className="absolute top-0 left-0 bottom-0 right-0 flex flex-col items-start bg-white">
        <div className="flex w-full items-center justify-between border-b border-gray-200 px-6 py-1.5 font-semibold">
          <div>Browse data</div>
          <Buttons.Secondary
            onClick={(event) => {
              event.stopPropagation();
              setTempFilter(undefined);
            }}
            icon={<MUIcon name="close" />}
            className="-mr-2 rounded-full text-gray-600 shadow-none ring-0 hover:bg-gray-100"
          />
        </div>
        <DataBrowsing.DataBrowser
          surveysApi={peApi.getSurveys}
          onChange={handleVariableChange}
          activeWorkspaceId={activeWorkspace.id}
          selection={
            tempFilter.variable
              ? [tempFilter.variable]
              : [
                  {
                    ...selection[0],
                    variableGuid: '',
                    tableGuid: '',
                    universeVariableUuid: undefined,
                  },
                ]
          }
          allowMultiSelection={false}
          disableSurveyChange={true}
        />
      </div>
    ) : null;

  return (
    <div className="flex h-full w-full flex-col gap-4">
      <PanelHeader title="Filter Areas by Criteria" onBack={onClosePanel} />
      {browser}
      {filters.length ? filtersWrapper : emptyFiltersState}
    </div>
  );
};

export default AreaFilterWrapper;
