import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../../LinkGenerator';
import { LogoText } from '../../shared';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  return (
    <div className="flex h-full w-full flex-col items-center gap-10 bg-gray-50 pt-[60px]">
      <LogoText width={148} height={38} />
      <div className="items-left flex h-[452px] w-[460px] flex-col justify-center rounded-xl border border-gray-200 bg-white shadow-md">
        {email.trim().length > 0 ? (
          <ResetPasswordConfirmation email={email} />
        ) : (
          <ResetPasswordForm setUserEmail={(email: string) => setEmail(email)} />
        )}
      </div>
      <div className="px-10">
        <Link className="text-primary-600 text-sm font-semibold" to={generateLink('login')} data-cy={'back-to-login'}>
          Back to log in
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
