import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  menuOptions: PeTypes.HeaderOptions[];
  activeOptionId: number;
  menuClose: () => void;
}

const HeaderOptionsPicker: React.FC<Props> = ({ menuOptions, activeOptionId, menuClose }) => {
  return (
    <div className="flex w-[320px] flex-col">
      <div className="flex flex-col">
        {menuOptions.map((option, idx) => (
          <Link
            target={option.target}
            to={option.id === activeOptionId ? '' : option.linkTo}
            onClick={menuClose}
            key={option.id}
            className={`py-2 ${idx === menuOptions.length - 1 ? '' : 'border-b border-gray-200'}`}
          >
            <div
              className={`flex h-full w-full items-start justify-start gap-3 px-3 py-2 ${
                option.id === activeOptionId ? 'bg-primary-50' : 'hover:bg-gray-100'
              } `}
            >
              <div className="flex h-full items-start justify-end px-1 py-1">{option.icon}</div>
              <div className="flex flex w-full items-start justify-between gap-2">
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-semibold text-gray-900">{option.title}</span>
                  <span className="font-regular text-xs text-gray-500">{option.label}</span>
                </div>
                {option.suffixIcon && (
                  <div className="flex h-full w-[24px] items-center justify-start">{option.suffixIcon}</div>
                )}
                {option.id === activeOptionId && (
                  <div className="flex h-full w-[28px] items-center justify-start">
                    <MUIcon name="check_circle" className="text-primary-600 stroke-2" />
                  </div>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
export default HeaderOptionsPicker;
