import { MapSpecModifiers } from '@platform/maplibre';
import { useSystemFilterSets, useSystemPalettes } from '@platform/shared/hooks';
import { DataBrowsing } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';

const { DataBrowser } = DataBrowsing;

export interface Props {
  mapObjects: ProjectTypes.MapObject[];
  surveysApi: () => Promise<ProjectTypes.SurveyInfo[]>;
  activeWorkspaceId: number;
}

const Classic: React.FC<Props> = ({ mapObjects, surveysApi, activeWorkspaceId }) => {
  const { get: getFilterSets } = useSystemFilterSets();
  const { get: getPalettes } = useSystemPalettes();
  const [mapObj, ...otherMapObjects] = mapObjects;
  const { styleSpec } = mapObj;
  const { dataLayer } = styleSpec;
  const { selection } = dataLayer;
  const [{ surveyName, datasetAbbrev }] = selection;

  useEffect(() => {
    // preload palettes and filer sets used by the current selection
    getFilterSets(surveyName, datasetAbbrev);
    getPalettes(surveyName);
  }, []);

  const [loading, setIsLoading] = useState(false);

  const handleDataChange = async (newSelection: ProjectTypes.VariableSelection[]) => {
    try {
      setIsLoading(true);
      const { surveyName, datasetAbbrev } = newSelection[0];
      const filterSets = await getFilterSets(surveyName, datasetAbbrev);
      const palettes = await getPalettes(surveyName);

      let updated = await MapSpecModifiers.updateDataLayerSelection(
        mapObj.styleSpec,
        newSelection,
        palettes,
        filterSets
      );
      if (!updated) return;

      // if survey changes, we have to clear geo feature selection and data filters
      if (mapObj.styleSpec.dataLayer.selection[0].surveyName !== newSelection[0].surveyName) {
        updated = MapSpecModifiers.updateGeoSelection(updated, []);
        updated = MapSpecModifiers.addFilterByCriteria(updated, { filters: [], rule: 'all' });
      }

      mapObj.updateMapSpec(updated);

      if (otherMapObjects.length) {
        otherMapObjects.forEach(async (mapToApply) => {
          const updated = await MapSpecModifiers.updateDataLayerSelection(
            mapObj.styleSpec,
            newSelection,
            palettes,
            filterSets
          );
          if (!updated) return;
          mapToApply.updateMapSpec(updated);
        });
      }
    } catch (e) {
      // TODO:
      console.warn("Can't apply selection");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DataBrowser
      isLoading={loading}
      surveysApi={surveysApi}
      onChange={handleDataChange}
      activeWorkspaceId={activeWorkspaceId}
      selection={mapObj.styleSpec.dataLayer.selection ?? []}
    />
  );
};

export default Classic;
