import { ProjectTypes } from '@platform/types';
import React from 'react';
import { useQuery } from 'react-query';
import * as peApi from '../../../pe.api';

import InsightsWrapper, { ContextConsumer } from '../InsightsWrapper';
import WorkingLayerInsights from './WorkingLayerInsights';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[];
}

const WorkingLayerInsightsWrapper: React.FC<Props> = ({ workingLayer, geoFeatureSelection }) => {
  const insightsQuery = useQuery(['working-layer-insights', workingLayer.datasetId], () =>
    peApi.getDatasetsInsights(workingLayer.datasetId)
  );

  if (!insightsQuery.data?.length) return null;

  return (
    <>
      {insightsQuery.data.map((x) => (
        <InsightsWrapper key={x.id} title={x.title}>
          <ContextConsumer>
            {({ open }) => (
              <WorkingLayerInsights
                enabled={open}
                datasetInsightsId={x.id}
                workingLayer={workingLayer}
                geoFeatureSelection={geoFeatureSelection}
              />
            )}
          </ContextConsumer>
        </InsightsWrapper>
      ))}
    </>
  );
};

export default WorkingLayerInsightsWrapper;
