import { Dropdowns } from '@platform/shared/ui';
import React from 'react';

interface Props {
  value?: string;
  label: string;
  onSelect: (option: Dropdowns.DropdownOption | null) => void;
  dropDownOptions: Dropdowns.DropdownOption[];
  placeholder?: string;
  previewValue?: string;
}

const SelectColumnItem: React.FC<Props> = ({
  label,
  dropDownOptions,
  value,
  onSelect,
  previewValue,
  placeholder = 'Select column',
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex basis-1/5">
        <p className="text-sm text-gray-800">{label}</p>
      </div>
      <div className="relative flex basis-4/5 ">
        <Dropdowns.Dropdown
          value={value}
          options={dropDownOptions}
          onSelect={onSelect}
          onClear={() => onSelect(null)}
          triggerWrapperClasses="w-full"
          triggerClasses="w-full h-[44px] text-sm font-normal px-4 rounded-lg"
          menuWidth="full"
          placeholder={placeholder}
        />
        {previewValue && (
          <span className="absolute right-12 top-1/2 w-3/12 -translate-y-1/2 truncate text-xs text-gray-500">
            {previewValue}
          </span>
        )}
      </div>
    </div>
  );
};

export default SelectColumnItem;
