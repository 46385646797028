import { MetadataTypes, ProjectTypes } from '@platform/types';
import { ResourceType } from 'maplibre-gl';
import { extractFieldsFromRuleGroup } from './utils';

export const nonTileRequests = (url: string, resourceType?: ResourceType): string => {
  if (!resourceType || !['Tile', 'Glyphs'].includes(resourceType)) {
    return url;
  }
  return '';
};

export const mapTilerTilesRequests = (url: string): string => {
  // handling MapTiler tile (and glyphs) requests
  // make sure all MapTiler tile/glyph requests proxy through our platform API, so we can add API keys
  if (url.includes(ProjectTypes.MapTileSource.MAPTILER_SOURCE)) {
    return `${process.env.NX_PE_SERVER}/api/${url}`;
  }
  return '';
};

export const geoBufferTilesRequests = (
  url: string,
  mapSpec: ProjectTypes.MapSpec,
  sourceLayer?: MetadataTypes.MetadataSocialExplorerDataSourceLayer,
  defaultSourceLayer?: MetadataTypes.MetadataSocialExplorerDataSourceLayer
): string => {
  const layers: string[] = [];
  const columns: string[] = [];
  const { dataLayer, workingLayers } = mapSpec;
  const { selection = [], filtersByData } = dataLayer;
  const [firstSelectedVariable] = selection;

  // Rewrite the URL based on the source query params
  if (url.includes(`${ProjectTypes.MapTileSource.SOCEX_SOURCE}`) && sourceLayer != null) {
    const geoLayerId = sourceLayer['geo-layer-id'];
    const dataset = sourceLayer.datasets.find(
      (ds) => ds['dataset-abbreviation'] === firstSelectedVariable.datasetAbbrev
    );

    if (defaultSourceLayer) {
      layers.push(defaultSourceLayer['geo-layer-id']);
    }

    layers.push(geoLayerId);

    columns.push(`${geoLayerId}.${dataset?.['dataset-id']}.${dataset?.['primary-key-column']}`);
    columns.push(`${geoLayerId}.${dataset?.['dataset-id']}.${dataset?.['geo-qualified-name-column']}`);

    selection.forEach((s) => {
      columns.push(`${geoLayerId}.${dataset?.['dataset-id']}.${s.variableGuid}`);
      if (s.universeVariableUuid) columns.push(`${geoLayerId}.${dataset?.['dataset-id']}.${s.universeVariableUuid}`);
    });

    filtersByData?.filters
      .filter((f) => f.variable.surveyName === firstSelectedVariable.surveyName && f.value != null)
      .forEach((f) => {
        const datasetForFilter = sourceLayer.datasets.find(
          (ds) => ds['dataset-abbreviation'] === f.variable.datasetAbbrev
        );
        columns.push(`${geoLayerId}.${datasetForFilter?.['dataset-id']}.${f.variable.variableGuid.trim()}`);
        if (f.variable.universeVariableUuid)
          columns.push(`${geoLayerId}.${datasetForFilter?.['dataset-id']}.${f.variable.universeVariableUuid.trim()}`);
      });
  }

  if (url.includes(ProjectTypes.MapTileSource.USER_UPLOAD_SOURCE)) {
    //source-user-uploads:.*:(.*)$
    const regex = new RegExp(`${ProjectTypes.MapTileSource.USER_UPLOAD_SOURCE}:.*:(.*)$`);
    const match = regex.exec(url);
    const workingDataSetId = match ? match[1] : null;
    const workingDataset = workingLayers.find((x) => x.id === workingDataSetId);

    if (workingDataset) {
      const { geoLayerId, filter } = workingDataset;
      if (geoLayerId) {
        layers.push(geoLayerId);
        columns.push(`${geoLayerId}.0.${ProjectTypes.GEO_ROW_IDENTIFICATOR}`);
      }

      if (filter) {
        extractFieldsFromRuleGroup(filter).forEach((c) => {
          columns.push(`${geoLayerId}.0.${c.trim()}`);
        });
      }
    }
  }

  return url.replace('{layers}', layers.join('.')).replace('{columns}', encodeURIComponent(columns.join(',')));
};
