import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSessionContext } from '../../contexts/SessionContext';
import * as peApi from '../../pe.api';

interface Props {
  invitation: PeTypes.PendingInvitation;
  activeWorkspace: PeTypes.Workspace;
}

enum ACTIONS {
  RESEND,
  REVOKE,
}

const WorkspacePendingUser: React.FC<Props> = ({ invitation, activeWorkspace }) => {
  const queryClient = useQueryClient();
  const session = useSessionContext();

  const resendInvitationMutation = useMutation({
    mutationFn: async (invitationId: number) => peApi.resendWorkspaceInvitation(invitationId),
    onSuccess: async () => {
      invalidateQueries();
    },
  });

  const revokeInvitationMutation = useMutation({
    mutationFn: async ({ invitationId, identityId }: { invitationId: number; identityId: number }) =>
      peApi.revokeWorkspaceInvitation(invitationId, identityId),
    onSuccess: async () => {
      invalidateQueries();
    },
  });

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['workspace-members', activeWorkspace.id] });
    queryClient.invalidateQueries({ queryKey: ['workspace-pending-invites', activeWorkspace.id] });
  };

  const dropdownOptions = activeWorkspace.isAdmin
    ? [
        { value: ACTIONS.RESEND, name: 'Resend invitation' },
        { value: ACTIONS.REVOKE, name: 'Revoke invitation' },
      ]
    : [];

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case ACTIONS.RESEND:
        resendInvitationMutation.mutate(invitation.id);
        break;
      case ACTIONS.REVOKE:
        revokeInvitationMutation.mutate({ invitationId: invitation.id, identityId: invitation.identityId });
        break;
    }
  };

  return (
    <div className="flex w-full items-center justify-between border-b border-gray-200 py-5 px-3">
      <div className="flex gap-4">
        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-[999px] border border-dashed border-gray-400">
          <MUIcon name="person" className="text-gray-500" />
        </div>
        <div className="flex flex-col gap-1 py-1">
          <div className="flex gap-1 text-sm font-semibold text-gray-900">
            <span>{invitation.invitedEmail}</span>
          </div>
          <span className="text-xs text-gray-500">Invitation pending</span>
        </div>
      </div>
      {invitation.id !== session.user?.id && (
        <Dropdowns.Dropdown
          icon={<MUIcon name="more_horiz" className="text-gray-600" aria-hidden="false" />}
          iconClose={null}
          options={dropdownOptions}
          onSelect={onSelectDropdownOption}
          hAlignment="right"
          triggerClasses="p-2 rounded-full border-gray-200"
        />
      )}
    </div>
  );
};

export default WorkspacePendingUser;
