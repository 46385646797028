import { v4 as uuid } from 'uuid';

export const ROWS_PER_PAGE = 30;
export const COLUMNS_PER_PAGE = 20;

export enum PageAction {
  FIRST,
  PREVIOUS,
  NEXT,
  LAST,
}

export enum DataType {
  ID = 0,
  INTEGER = 1,
  DOUBLE = 2,
  TEXT = 3,
  DATE = 4,
  BOOLEAN = 5,
  GEOGRAPHY = 6,
  GEOGRAPHY_REFERENCE = 10,
}

export const VisibleDataTypes = [DataType.INTEGER, DataType.DOUBLE, DataType.TEXT, DataType.BOOLEAN];

export const ID_COLUMN = {
  id: uuid(),
  title: '#',
  classes: 'min-w-8 text-right',
  headerClasses: 'min-w-8 text-right justify-end',
  freeze: true,
};

export interface DatasetPageData {
  firstUuid: string | null;
  lastUuid: string | null;
  action: PageAction;
}

export enum Dataset {
  CSV = 'csv',
  DEFAULT_GEO = 'geo.default',
  LINKED_GEO = 'geo.linked',
}
