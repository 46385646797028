import { MapsContext } from '@platform/maplibre';
import { PeTypes } from '@platform/types';
import React from 'react';
import SeProMaps from '../Map/SeProMaps';
import StoryHeader from './StoryHeader';

const { useMapsContext } = MapsContext;

interface Props {
  story: PeTypes.Story;
  slide: PeTypes.StorySlide;
  mode: 'edit' | 'view';
}

const SlideMapsWrapper: React.FC<Props> = ({ mode, story }) => {
  const { state } = useMapsContext();
  const isEditMode = mode === 'edit';

  return (
    <div className="relative flex h-full min-w-0 flex-grow">
      <div className="absolute top-0 right-0 z-10">
        <StoryHeader story={story} isEditMode={isEditMode} />
      </div>
      <div className="absolute top-0 left-0 right-0 bottom-0 z-0 flex">
        <SeProMaps mapObjects={state.registeredMaps} type={state.type} inEditMode={isEditMode} />
      </div>
    </div>
  );
};

export default SlideMapsWrapper;
