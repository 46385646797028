import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import WorkingLayerPickerModal from './WorkingLayerPickerModal';

interface Props {
  onSelect: (datasets: PeTypes.DatasetListItem[]) => void;
}

const WorkingLayerPicker: React.FC<Props> = ({ onSelect }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleModalCloseRequest = () => setIsModalVisible(false);

  return (
    <div className="flex items-center justify-between px-3">
      <span className="text-sm font-semibold text-gray-800">Layer Data</span>
      <Buttons.Primary
        onClick={() => setIsModalVisible(true)}
        className="gap-2 rounded-full py-2 pl-2 pr-6"
        icon={<MUIcon name="add" />}
      >
        Add
      </Buttons.Primary>
      {isModalVisible && <WorkingLayerPickerModal onSelect={onSelect} onCloseRequest={handleModalCloseRequest} />}
    </div>
  );
};

export default WorkingLayerPicker;
