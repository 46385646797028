import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../pe.api';

interface Props {
  profileId: string;
  watched: boolean;
  className?: string;
}

const ProfileWatchButton: React.FC<Props> = ({ watched, profileId, className }) => {
  const queryClient = useQueryClient();
  const profileMutation = useMutation({
    mutationFn: () => peApi.watchProfile(profileId, !watched),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles'] });
      queryClient.invalidateQueries({ queryKey: ['profile', profileId] });
    },
  });

  return (
    <Buttons.Secondary
      className={`shadow-none ring-0 ${className}`}
      disabled={profileMutation.isLoading}
      onClick={(e) => {
        profileMutation.mutate();
        e.preventDefault();
      }}
      icon={watched ? <MUIcon name="bookmark" iconStyle="filled" /> : <MUIcon name="bookmark" />}
    ></Buttons.Secondary>
  );
};

export default ProfileWatchButton;
