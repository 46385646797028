import { Dropdowns } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import * as GeoJSON from 'geojson';
import { LngLat, LngLatLike } from 'maplibre-gl';
import React, { useEffect, useMemo, useState } from 'react';
import PreviewMap from '../common/PreviewMap';
import SelectColumnItem from './SelectColumnItem';

interface Props {
  lat: string | undefined;
  lng: string | undefined;
  data: unknown[][];
  columns: PeTypes.ImportDatasetPayloadColumn[];
  onSelect: (
    lat: string | undefined,
    lng: string | undefined,
    featureCollection: GeoJSON.FeatureCollection | undefined
  ) => void;
}

const LatLngGeography: React.FC<Props> = ({ columns, data, onSelect, lat, lng }) => {
  const [featureCollection, setFeatureCollection] = useState<GeoJSON.FeatureCollection | undefined>(undefined);

  const [latColumn, setLatColumn] = useState<string | undefined>(lat);
  const [lngColumn, setLngColumn] = useState<string | undefined>(lng);
  const dropdownOptions: Dropdowns.DropdownOption[] = columns
    .filter((c) => c.type === DatasetTypes.DataType.DOUBLE || c.type === DatasetTypes.DataType.INTEGER)
    .map((c) => ({ name: c.name, value: c.name }));

  const sampleData = useMemo(() => {
    const cleanSampleData: LngLatLike[] = [];
    const indexOfLatColumn = columns.findIndex((c) => c.name === latColumn);
    const indexOfLngColumn = columns.findIndex((c) => c.name === lngColumn);

    if (indexOfLatColumn > -1 && indexOfLngColumn > -1) {
      data.forEach((element: unknown[]) => {
        const lat = parseFloat(element?.[indexOfLatColumn]?.toString() ?? '');
        const lng = parseFloat(element?.[indexOfLngColumn]?.toString() ?? '');
        cleanSampleData.push([lat, lng]);
      });
    }

    return cleanSampleData;
  }, [columns, data, latColumn, lngColumn]);

  useEffect(() => {
    let featureCollection: GeoJSON.FeatureCollection | undefined = undefined;
    if (latColumn && lngColumn) {
      featureCollection = {
        type: 'FeatureCollection',
        features: sampleData.map((x) => {
          const type: GeoJSON.GeoJsonTypes = 'Point';
          let coordinates;
          switch (type.toString()) {
            case 'Point':
              coordinates = Array.isArray(x) ? [x[1], x[0]] : [(x as LngLat).lng, (x as LngLat).lat];
              break;
            case 'MultiPoint':
            case 'Polygon':
            case 'MultiPolygon':
              // TODO: what about these
              break;
            default:
              break;
          }

          return {
            type: 'Feature',
            properties: {},
            geometry: {
              type: type.toString(),
              coordinates,
            },
          } as GeoJSON.Feature;
        }),
      };
    }

    setFeatureCollection(featureCollection);

    onSelect(latColumn, lngColumn, featureCollection);
  }, [latColumn, lngColumn]);

  const indexOfLat = columns.findIndex((column) => column.name === latColumn);
  const indexOfLng = columns.findIndex((column) => column.name === lngColumn);

  const sampleLat = indexOfLat > -1 ? (data[0][indexOfLat] as string) : '';
  const sampleLng = indexOfLng > -1 ? (data[0][indexOfLng] as string) : '';

  return (
    <div className="flex flex-col gap-6">
      <p className="text-sm text-gray-600">
        As you select latitude and longitude columns, please note that only columns meeting specific criteria are
        displayed: each must be numeric and within valid geographical ranges (-90 to +90 degrees for latitude, -180 to
        +180 degrees for longitude).
      </p>
      <div className="flex flex-col gap-3">
        <SelectColumnItem
          label={'Latitude'}
          dropDownOptions={dropdownOptions}
          value={latColumn}
          placeholder="Select latitude column"
          onSelect={(option: Dropdowns.DropdownOption | null) =>
            setLatColumn(option != null ? (option.value as string) : undefined)
          }
          previewValue={sampleLat}
        />
        <SelectColumnItem
          label={'Longitude'}
          dropDownOptions={dropdownOptions}
          value={lngColumn}
          placeholder="Select longitude column"
          onSelect={(option: Dropdowns.DropdownOption | null) =>
            setLngColumn(option != null ? (option.value as string) : undefined)
          }
          previewValue={sampleLng}
        />
      </div>
      <div className="w-full">
        <PreviewMap featureCollection={featureCollection} />
      </div>
    </div>
  );
};

export default LatLngGeography;
