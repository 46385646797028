import React from 'react';
import { MUIcon } from '../MUIcon';

interface Props {
  selected: boolean;
  onSelect: () => void;
}

const Select: React.FC<Props> = ({ selected, onSelect }) => {
  const icon = selected ? (
    <MUIcon name="check_circle" className="text-primary-700" iconStyle="filled" />
  ) : (
    <MUIcon name="add_circle" className="text-gray-400" />
  );
  return (
    <div>
      <div
        className={`flex h-[18px] w-[18px] items-center justify-center rounded-xl border-2 border-solid`}
        onClick={onSelect}
      >
        {icon}
      </div>
    </div>
  );
};

export default Select;
