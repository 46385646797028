import { Buttons, Toggles } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import classNames from 'classnames';
import * as GeoJSON from 'geojson';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import * as peApi from '../../../pe.api';
import PreviewMap from '../common/PreviewMap';
import { ImportProgress } from '../ImportWizard';
import MultiColumnAddress from './MultiColumnAddress';
import SingleColumnAddress from './SingleColumnAddress';

interface Props {
  importData: ImportProgress;
  data: unknown[][];
  columns: PeTypes.ImportDatasetPayloadColumn[];
  onSelect: (
    geocodeObj: PeTypes.ImportDatasetGeoCode | undefined,
    featureCollection: GeoJSON.FeatureCollection | undefined
  ) => void;
}

const DEFAULT_GEOCODE_CONFIG: PeTypes.ImportDatasetGeoCode = {
  isNonQualifiedQuery: false,
  selection: {
    address: '',
    city: '',
    state: '',
    zipCode: '',
  },
};

const GEOCODING_LIMIT = 20;
const hasValuesSet = (obj: object) => Object.values(obj).some((value) => value !== '');

const GeocodeGeography: React.FC<Props> = ({ data, columns, onSelect, importData }) => {
  const [lastUsedConfig, setLastUsedConfig] = useState<string | undefined>(
    JSON.stringify(importData.geo?.geocode ?? DEFAULT_GEOCODE_CONFIG)
  );
  const [geocodeConfig, setGeocodeConfig] = useState<PeTypes.ImportDatasetGeoCode>(
    importData.geo?.geocode ?? DEFAULT_GEOCODE_CONFIG
  );

  const geocodeSampleMutation = useMutation({
    mutationFn: (payload: PeTypes.GeocodeRequestPayload) => peApi.geocodeSample(payload),
    onSuccess: (response) => {
      const date = new Date(response.createdAt);
      const shift = date.getTime() % 256;
      const featureCollection: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: response.result.map((x) => ({
          type: 'Feature',
          properties: { score: x.score, fullAddress: x.address },
          geometry: {
            type: 'Point',
            coordinates: [
              parseInt(Helpers.decode(x.coordinates.lng, shift)),
              parseInt(Helpers.decode(x.coordinates.lat, shift)),
            ],
          },
        })),
      };
      setLastUsedConfig(JSON.stringify(geocodeConfig));
      onSelect(geocodeConfig, featureCollection);
    },
  });

  useEffect(() => {
    if (hasValuesSet(geocodeConfig.selection)) {
      onSelect(geocodeConfig, importData.featureCollection);
    } else {
      onSelect(undefined, undefined);
    }
  }, [geocodeConfig]);

  const handleGeocodeSample = () => {
    const sampleData = data.slice(0, GEOCODING_LIMIT);

    const addressColIdx = columns.findIndex((el) => el.name === geocodeConfig.selection.address);

    let dataToGeoCode = [];
    if (geocodeConfig.isNonQualifiedQuery) {
      // provide address only
      dataToGeoCode = sampleData.map((el) => ({ address: el[addressColIdx] as string }));
    } else {
      // provide address, state, zip etc
      const cityColIdx = columns.findIndex((el) => el.name === geocodeConfig.selection.city);
      const stateColIdx = columns.findIndex((el) => el.name === geocodeConfig.selection.state);
      const zipColIdx = columns.findIndex((el) => el.name === geocodeConfig.selection.zipCode);

      dataToGeoCode = sampleData.map((el) => ({
        address: el[addressColIdx] as string,
        city: el[cityColIdx] as string,
        state: el[stateColIdx] as string,
        zipCode: el[zipColIdx] as string,
      }));
    }

    const payload: PeTypes.GeocodeRequestPayload = {
      nonQualifiedQuery: geocodeConfig.isNonQualifiedQuery,
      values: dataToGeoCode,
    };

    geocodeSampleMutation.mutate(payload);
  };

  const handleSingleColumnAddressChange = (address: string) =>
    setGeocodeConfig((p) => ({
      ...p,
      selection: { ...DEFAULT_GEOCODE_CONFIG.selection, address },
    }));

  const handleMultiColumnAddressChange = (multiAddress: PeTypes.ImportDatasetGeoCodeSelection) =>
    setGeocodeConfig((p) => ({
      ...p,
      selection: multiAddress,
    }));

  const addressHasChanged = JSON.stringify(geocodeConfig) !== lastUsedConfig;
  const isValidAddressInput = hasValuesSet(geocodeConfig.selection);
  const canGeoCode = !geocodeSampleMutation.isLoading && isValidAddressInput && addressHasChanged;

  return (
    <div
      className={classNames('flex flex-col gap-6', {
        'pointer-events-none opacity-60': importData.errors.geocodeLimit,
      })}
    >
      <p className="text-sm text-gray-600">
        To successfully geocode an address, a file must contain columns named: Address, City, & State - or - Address &
        Zip Code. Select one or more columns to assemble precise addresses for creating point geometry.{' '}
      </p>
      <Toggles.Toggle
        toggleOuterClasses="w-[35px] h-[15px] "
        toggleInnerClasses="w-[10px] h-[10px]"
        label={'Full address in a single column'}
        labelClasses={'text-sm font-semibold'}
        checked={geocodeConfig.isNonQualifiedQuery}
        onChange={() => setGeocodeConfig((p) => ({ ...p, isNonQualifiedQuery: !p.isNonQualifiedQuery }))}
      />
      <div className="flex flex-col gap-3 border-t border-gray-300">
        {geocodeConfig.isNonQualifiedQuery ? (
          <SingleColumnAddress
            columns={columns}
            data={data}
            value={geocodeConfig.selection.address}
            onChange={handleSingleColumnAddressChange}
          />
        ) : (
          <MultiColumnAddress
            data={data}
            columns={columns}
            value={geocodeConfig.selection}
            onChange={handleMultiColumnAddressChange}
          />
        )}
      </div>
      <div className="flex justify-end">
        <Buttons.Secondary
          disabled={!canGeoCode}
          className="text-primary-600 h-[34px] w-[214px] rounded-[999px] ring-1"
          onClick={handleGeocodeSample}
        >
          Test geocode on sample
        </Buttons.Secondary>
      </div>
      <div className="h-[328px] w-full rounded-md">
        <PreviewMap featureCollection={importData.featureCollection} />
      </div>
    </div>
  );
};

export default GeocodeGeography;
