import { MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { WorkspaceSettings, WorkspaceStatistics, WorkspaceUsers } from '../components/Workspace';
import { useWorkspaceContext } from '../contexts/WorkspaceContext';
import { generateLink } from '../LinkGenerator';

const WorkspacePage: React.FC = () => {
  const { activeWorkspace } = useWorkspaceContext();
  const location = useLocation();

  const sideBarOptions: { icon: React.ReactNode; label: string; disabled?: boolean; route: string }[] = [
    {
      icon: (
        <MUIcon
          name="settings"
          className={classNames(location.pathname.endsWith('/workspace') ? 'text-primary-700' : 'text-gray-600')}
        />
      ),
      label: 'Workspace Settings',
      route: generateLink('workspaceSettings', { workspaceId: activeWorkspace.id, section: 'workspace' }),
    },
    {
      icon: (
        <MUIcon
          name="group"
          className={classNames(location.pathname.endsWith('/users') ? 'text-primary-700' : 'text-gray-600')}
        />
      ),
      label: 'Users',
      route: generateLink('workspaceSettingsSection', { workspaceId: activeWorkspace.id, section: 'users' }),
    },
    {
      icon: (
        <MUIcon
          name="clock_loader_10"
          className={classNames(location.pathname.endsWith('/usage') ? 'text-primary-700' : 'text-gray-600')}
        />
      ),
      label: 'Usage Statistics',
      route: generateLink('workspaceSettingsSection', { workspaceId: activeWorkspace.id, section: 'usage' }),
    },
  ];

  const lastOption = sideBarOptions.pop();
  if (!activeWorkspace) return null;

  return (
    <div className="flex h-full flex-col bg-slate-50">
      <div className="h-full">
        <div className="mx-auto flex h-full max-w-7xl">
          <div className="flex h-full w-3/12 flex-col">
            <div className="flex flex-col py-10 pr-6">
              {sideBarOptions.map((option) => (
                <Link
                  key={option.label}
                  to={`${option.route}`}
                  className={classNames(
                    option.route === location.pathname ? 'bg-primary-100 text-primary-700' : 'text-gray-800',
                    'flex h-[44px] grow items-center gap-2 rounded-lg px-4 text-sm font-semibold'
                  )}
                >
                  {option.icon}
                  <span>{option.label}</span>
                </Link>
              ))}
              <div className="my-4 h-[1px] w-full bg-gray-200"></div>
              {lastOption && (
                <Link
                  to={lastOption.route}
                  className={classNames(
                    lastOption.route === location.pathname
                      ? 'bg-primary-100 text-primary-700'
                      : 'bg-transparent text-gray-800',
                    lastOption.disabled ? 'pointer-events-none text-gray-200' : 'text-gray-800',
                    'flex h-[44px] items-center gap-2 rounded-lg px-4 text-sm font-semibold '
                  )}
                >
                  {lastOption.icon}
                  <span>{lastOption.label}</span>
                </Link>
              )}
            </div>
          </div>
          <div className="flex w-8/12 flex-col flex-col overflow-auto border-l border-gray-200 px-12 py-10">
            <Routes>
              <Route
                path="/"
                element={<WorkspaceSettings activeWorkspace={activeWorkspace} isAdmin={activeWorkspace.isAdmin} />}
              />
              <Route
                path="/users"
                element={<WorkspaceUsers activeWorkspace={activeWorkspace} isAdmin={activeWorkspace.isAdmin} />}
              />
              <Route path="/usage" element={<WorkspaceStatistics activeWorkspace={activeWorkspace} />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspacePage;
