import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  intermediate: boolean;
  classes?: string;
  checkboxClasses?: string;
}

const TriStateCheckBox: React.FC<Props> = ({ checked, intermediate, classes, checkboxClasses, ...otherInputProps }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.checked = checked;
      ref.current.indeterminate = intermediate;
    }
  }, [checked, intermediate]);

  return (
    <div className={classes}>
      <input
        ref={ref}
        type="checkbox"
        className={classNames(
          'active:bg-primary-200 intermediate:bg-primary-200 text-primary-500 ml-2 mr-2 flex-grow rounded-sm border border-gray-500 p-0 text-sm hover:cursor-pointer focus:outline-none focus:ring-0',
          checkboxClasses
        )}
        autoComplete="off"
        {...otherInputProps}
      />
    </div>
  );
};

export default TriStateCheckBox;
