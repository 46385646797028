import { Menu, MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../../contexts/WorkspaceContext';
import { generateLink } from '../../../LinkGenerator';
import DownloadCsvModal from '../../DownloadCsvModal';
import DownloadModal from './Filtering/DownloadModal';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onRemove: (workingLayer: ProjectTypes.WorkingLayerSpec) => void;
  onFit: (workingLayer: ProjectTypes.WorkingLayerSpec) => void;
  onBeingEdited?: (edited: boolean) => void;
  hasAppliedFilters: boolean;
  isPublished: boolean;
}

const trigger = (
  <div className="flex h-[36px] w-[36px] items-center justify-center rounded-lg shadow-none hover:bg-gray-100">
    <MUIcon name="more_horiz" className="text-gray-600" />
  </div>
);

const WorkingLayerMenu: React.FC<Props> = ({
  workingLayer,
  onRemove,
  onFit,
  onBeingEdited,
  hasAppliedFilters,
  isPublished,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [downloadRequested, setDownloadRequested] = useState(false);
  const { activeWorkspace } = useWorkspaceContext();

  useEffect(() => {
    onBeingEdited?.(isOpen);
  }, [isOpen]);

  const downloadModal = isPublished ? (
    <DownloadModal workingLayer={workingLayer} onCloseRequest={() => setDownloadRequested(false)} />
  ) : (
    <DownloadCsvModal
      datasetId={workingLayer.datasetId}
      onCloseRequest={() => setDownloadRequested(false)}
      hasFilters={hasAppliedFilters}
    />
  );

  return (
    <>
      <Menu
        visible={isOpen}
        onToggleVisibility={setIsOpen}
        trigger={trigger}
        hAlignment="right"
        menuClasses="py-0 mt-2"
      >
        <div className="flex w-[335px] flex-col gap-2 pt-4 pb-2">
          <MenuItem icon={<MUIcon name="fit_screen" />} onClick={() => onFit(workingLayer)}>
            Center map on layer
          </MenuItem>
          <Link
            target="_blank"
            to={generateLink('dataset', { workspaceId: activeWorkspace.id, datasetId: workingLayer.datasetId })}
          >
            <MenuItem icon={<MUIcon name="view_list" />}>
              <div className="flex w-full items-center justify-between">
                View dataset
                <MUIcon name="open_in_new" />
              </div>
            </MenuItem>
          </Link>
          <MenuItem icon={<MUIcon name="download" />} onClick={() => setDownloadRequested(true)}>
            <div className="flex w-full items-center">Download data</div>
          </MenuItem>
          <MenuDivider />
          <MenuItem icon={<MUIcon name="delete" />} onClick={() => onRemove(workingLayer)}>
            Remove
          </MenuItem>
        </div>
      </Menu>
      {downloadRequested && downloadModal}
    </>
  );
};

export default WorkingLayerMenu;

const MenuItem = ({
  icon,
  children,
  onClick,
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
}) => (
  <button
    className="flex w-full cursor-pointer items-center justify-between py-4 px-4 hover:bg-gray-100"
    onClick={onClick}
  >
    <div className="flex w-full gap-3">
      {icon}
      <div className="flex flex-grow text-sm text-gray-600">{children}</div>
    </div>
  </button>
);

const MenuDivider = () => <div className="h-[1px] w-full bg-gray-200" />;
