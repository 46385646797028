import { useDebounce } from '@platform/shared/hooks';
import { Buttons, Inputs, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import { useEffect, useState } from 'react';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useDatasets from '../../hooks/useDatasets';
import FilterDatasetsByType from './FilterDatasetsByType';
import NoSearchResults from './NoSearchResults';
import SortDatasetsBy, { SORT_BY_OPTIONS } from './SortDatasetsBy';
import SortOrder from './SortOrder';

const PAGE_SIZE = 30;
const MAX_PAGES = 3;

export default (types: ProjectTypes.LayerType[]) => {
  const { activeWorkspace } = useWorkspaceContext();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>(SORT_BY_OPTIONS[0].value as string);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [typeFilters, setTypeFilters] = useState<ProjectTypes.LayerType | undefined>(undefined);
  const [results, setResults] = useState<PeTypes.QueryDatasetsResponse>({ datasets: [], totalCount: -1 });

  const debouncedSearch = useDebounce<string>(searchTerm, 200);
  const datasetsQuery = useDatasets({
    sortBy,
    sortOrder,
    workspaceId: activeWorkspace.id,
    searchTerm: debouncedSearch,
    types: typeFilters ? [typeFilters] : types,
    pageNumber: currentPage,
    pageSize: PAGE_SIZE,
  });

  const hasSearchTerm = debouncedSearch.trim().length > 0;
  const hasFiltersApplied = hasSearchTerm || typeFilters;
  const isEmptyCatalog = !hasFiltersApplied && results.totalCount === 0; // even though no filters applied, there are no items, thus catalog is empty
  const noSearchResults = hasFiltersApplied && results.datasets.length === 0;
  const isLoading = datasetsQuery.isLoading || datasetsQuery.isIdle;

  useEffect(() => {
    if (!datasetsQuery.data) return;
    setResults(datasetsQuery.data);
  }, [datasetsQuery.data]);

  useEffect(() => {
    setCurrentPage(0);
  }, [typeFilters, debouncedSearch]);

  useEffect(() => {
    if (!activeWorkspace) return;
    resetFilters();
  }, [activeWorkspace]);

  const resetFilters = () => {
    setTypeFilters(undefined);
    setSearchTerm('');
    setCurrentPage(0);
  };

  const handleClearFilters = () => resetFilters();
  const handleSortByChange = (sortBy: string) => setSortBy(sortBy);
  const handleSortOrderChange = (sortOrder: 'asc' | 'desc') => setSortOrder(sortOrder);
  const handleTypeFilterChange = (newFilter: ProjectTypes.LayerType | undefined) => setTypeFilters(newFilter);

  let paging;
  const numOfPages = Math.ceil(results.totalCount / PAGE_SIZE);

  if (numOfPages > 1) {
    // Determine the start page for the pagination display
    let startPage = Math.max(currentPage - 1, 1);
    // Calculate the end page for the pagination display, ensuring it doesn't exceed the penultimate page
    const endPage = Math.min(startPage + MAX_PAGES - 1, numOfPages - 2);

    // Adjust the startPage backwards if the range of pages is less than the maximum number of pages to be displayed
    if (endPage - startPage + 1 < MAX_PAGES) {
      startPage = Math.max(endPage - MAX_PAGES + 1, 1);
    }

    paging = (
      <div className="flex min-w-[250px] justify-center py-6">
        <div className="flex items-center justify-center gap-3">
          <Buttons.Link
            icon={<MUIcon name="chevron_left" />}
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prevState) => Math.max(0, prevState - 1))}
            className="h-8 w-8 cursor-pointer rounded-full bg-gray-200 font-semibold"
          />
          <button
            onClick={() => setCurrentPage(0)}
            className={`h-8 w-8 cursor-pointer rounded-full font-semibold ${
              currentPage === 0 ? 'bg-primary-600 text-white' : 'text-gray-800 hover:bg-gray-200'
            }`}
          >
            1
          </button>
          {startPage > 1 && <div className="flex h-8 w-8 items-center justify-center text-gray-800">...</div>}
          {Array.from({ length: endPage - startPage + 1 }, (_, k) => startPage + k).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`h-8 w-8 cursor-pointer rounded-full font-semibold ${
                page === currentPage ? 'bg-primary-600 text-white' : 'text-gray-800 hover:bg-gray-200'
              }`}
            >
              {page + 1}
            </button>
          ))}
          {endPage < numOfPages - 2 && (
            <div className="flex h-8 w-8 items-center justify-center text-gray-800">...</div>
          )}
          <button
            onClick={() => setCurrentPage(numOfPages - 1)}
            className={`h-8 w-8 cursor-pointer rounded-full font-semibold ${
              currentPage === numOfPages - 1 ? 'bg-primary-600 text-white' : 'text-gray-800 hover:bg-gray-200'
            }`}
          >
            {numOfPages}
          </button>
          <Buttons.Link
            icon={<MUIcon name="chevron_right" />}
            disabled={currentPage === numOfPages - 1}
            onClick={() => setCurrentPage((prevState) => Math.min(numOfPages - 1, prevState + 1))}
            className="h-8 w-8 cursor-pointer rounded-full bg-gray-200 font-semibold"
          />
        </div>
      </div>
    );
  }

  const searchControls = (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <div className="w-80">
          <Inputs.Input
            classes="bg-white rounded-lg"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            clearIcon={isLoading ? null : undefined}
            suffixComponent={
              isLoading ? (
                <MUIcon className="text-primary-600 animate-spin" name="progress_activity" />
              ) : (
                <MUIcon name="search" />
              )
            }
            onClear={() => setSearchTerm('')}
          />
        </div>
      </div>
      <div className="flex h-[48px] items-center gap-2">
        <FilterDatasetsByType onChange={handleTypeFilterChange} value={typeFilters} types={types} />
        <SortDatasetsBy onChange={handleSortByChange} value={sortBy} />
        <SortOrder onChange={handleSortOrderChange} value={sortOrder} />
      </div>
    </div>
  );

  const noHits = noSearchResults ? <NoSearchResults onClick={handleClearFilters} /> : undefined;

  return { searchControls, isEmptyCatalog, noHits, datasets: results.datasets, paging };
};
