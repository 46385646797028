import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { SlideMenuOptionType } from '.';
import { useStory } from '../../../hooks';
import * as peApi from '../../../pe.api';
import { Loader } from '../../shared';
import Droppable from './SlideListDroppable';

interface Props {
  storyId: string;
  currentSlideId: string;
  isEditMode: boolean;
  onJumpTo: (slideId: string) => void;
  thumbsInMemory?: Record<string, string>;
  onCloseList: () => void;
}

const SlideList: React.FC<Props> = ({
  storyId,
  currentSlideId,
  thumbsInMemory = {},
  onJumpTo,
  isEditMode,
  onCloseList,
}) => {
  const queryClient = useQueryClient();
  const { storyQuery, slidesQuery } = useStory(storyId);

  const isLoading = storyQuery.isIdle || storyQuery.isLoading || slidesQuery.isIdle || slidesQuery.isLoading;
  const isError = storyQuery.isError || slidesQuery.isError;

  const addSlideMutation = useMutation({
    mutationFn: (payload: PeTypes.AddStorySlide) => peApi.addSlideToStory(storyId, payload),
    onSuccess: async (newlyCreatedId) => {
      slidesQuery.refetch().then(() => {
        onJumpTo(newlyCreatedId);
      });
      queryClient.invalidateQueries({ queryKey: ['story', storyId] });
    },
  });

  const reorderSlideMutation = useMutation({
    mutationFn: (payload: PeTypes.NewSlideOrdering) => peApi.reorderStorySlides(storyId, payload),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['story-slides', storyId] });
    },
  });

  const deleteSlideMutation = useMutation({
    mutationFn: (slideId: string) => peApi.deleteSlideFromStory(storyId, slideId),
    onSuccess: () => {
      slidesQuery.refetch().then((response) => {
        if (response.data?.length) onJumpTo(response.data[0].id);
      });
      queryClient.invalidateQueries({ queryKey: ['story', storyId] });
    },
  });

  useEffect(() => {
    if (currentSlideId || !slidesQuery.data?.length) return;
    onJumpTo(slidesQuery.data[0].id);
  }, [currentSlideId, slidesQuery.data]);

  if (isLoading) return <Loader />;
  if (isError) return <>Something is wrong...</>;

  const slides = slidesQuery.data;
  const currentSlide = slides.find((x) => x.id === currentSlideId);

  const handleNewSlideClick = () => {
    if (!currentSlide) return;
    addSlideMutation.mutate({
      copyOfSlideId: currentSlide.id,
    });
  };
  const handleSlideDuplicate = (slide: PeTypes.StorySlide) => {
    addSlideMutation.mutate({
      copyOfSlideId: slide.id,
      addAfterId: slide.id,
    });
  };

  const handleSlideDelete = (slide: PeTypes.StorySlide) => deleteSlideMutation.mutate(slide.id);

  const handleSlideMove = (slide: PeTypes.StorySlide, startId: string | null, endId: string | null) => {
    reorderSlideMutation.mutate({
      slideId: slide.id,
      endId,
      startId,
    });
  };

  const getSlideMenuOptions = (slide: PeTypes.StorySlide, index: number) => [
    {
      action: handleSlideDuplicate,
      value: SlideMenuOptionType.DUPLICATE,
      name: 'Duplicate slide',
      icon: <MUIcon name="control_point_duplicate" />,
      additionalStyles: 'w-[288px]',
      borderBottom: slides.length !== 1,
    },
    {
      action: () => handleSlideMove(slide, null, slides[0].id),
      value: SlideMenuOptionType.MOVE_UP,
      name: 'Move slide to beginning',
      icon: <MUIcon name="vertical_align_top" />,
      disabled: index === 0,
      borderBottom: index === slides.length - 1,
    },
    {
      action: () => handleSlideMove(slide, slides[slides.length - 1].id, null),
      value: SlideMenuOptionType.MOVE_DOWN,
      name: 'Move slide to end',
      icon: <MUIcon name="vertical_align_bottom" />,
      disabled: index === slides.length - 1,
      borderBottom: true,
    },
    {
      action: handleSlideDelete,
      value: SlideMenuOptionType.DELETE,
      name: 'Delete slide',
      icon: <MUIcon name="delete" />,
      disabled: slides.length === 1,
    },
  ];

  return (
    <div className="flex h-full flex-grow flex-col justify-between">
      <div className="flex w-full flex-grow flex-col gap-3 overflow-hidden">
        <div className="flex items-center justify-between py-3 pl-6 pr-4">
          <div className="font-semibold text-gray-800">Slides</div>
          <button onClick={onCloseList} className="hover:bg-gray-50">
            <MUIcon name="close" className="p-[6px] text-gray-600" />
          </button>
        </div>
        <Droppable
          slides={slides}
          currentSlideId={currentSlideId}
          thumbsInMemory={thumbsInMemory}
          onJumpTo={onJumpTo}
          isEditMode={isEditMode}
          reorderSlideMutation={reorderSlideMutation}
          getSlideMenuOptions={getSlideMenuOptions}
        />
      </div>
      {isEditMode && (
        <>
          <div className="h-[1px] w-full bg-gray-300" />
          <div className="flex items-center px-9 py-4">
            <button
              onClick={handleNewSlideClick}
              className="text-primary-600 flex h-9 w-[150px] items-center justify-center gap-2 rounded-full py-2 pl-2 pr-5 font-semibold ring-1 ring-gray-300 hover:bg-gray-100"
            >
              <MUIcon name="add" className="text-gray-600" />
              <div>New slide</div>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SlideList;
