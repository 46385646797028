import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import MUIcon from '../../MUIcon';

interface Props {
  leftMap: React.ReactNode;
  rightMap: React.ReactNode;
}

const SwipeLayout: React.FC<Props> = ({ leftMap, rightMap }) => {
  const [swipePositionPercentage, setSwipePositionPercentage] = useState(50); // Initial position set to the middle (50%)
  const [isDragging, setIsDragging] = useState(false);
  const swipeLayerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = () => setIsDragging(true);
  const handleMouseUp = () => setIsDragging(false);
  const handleTouchStart = () => setIsDragging(true);
  const handleTouchEnd = () => setIsDragging(false);

  const updatePosition = (position: number) => {
    if (swipeLayerRef.current) {
      const rect = swipeLayerRef.current.getBoundingClientRect();
      let newPosX = position - rect.left;
      newPosX = Math.max(0, Math.min(newPosX, rect.width)); // Ensure the position is within bounds

      // Convert the position to a percentage of the container's width
      const percentage = (newPosX / rect.width) * 100;
      setSwipePositionPercentage(percentage);
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isDragging) return;
    updatePosition(event.clientX);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (!isDragging) return;
    updatePosition(event.touches[0].clientX);
  };

  return (
    <div
      className="h-full w-full"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 z-10">{rightMap}</div>
      <div
        ref={swipeLayerRef}
        className="absolute top-0 left-0 right-0 bottom-0 z-20"
        style={{ clipPath: `inset(0 ${100 - swipePositionPercentage}% 0 0)` }}
      >
        {leftMap}
      </div>
      <div
        className={classNames('absolute top-0 bottom-0 left-[50%] z-30', {
          'w-full': isDragging,
        })}
        style={{
          left: `${swipePositionPercentage}%`,
          transform: 'translateX(-50%)',
        }}
      >
        <div
          className="absolute top-0 bottom-0 left-[50%] w-[4px] cursor-pointer bg-slate-700 hover:bg-slate-700"
          style={{ transform: 'translateX(-50%)' }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        >
          <div
            style={{ opacity: isDragging ? 0 : 1 }}
            className="absolute top-1/2 left-1/2 flex h-[50px] w-[30px] -translate-x-1/2 -translate-y-1/2 cursor-pointer select-none items-center justify-center rounded-lg bg-slate-700 text-white transition-all hover:bg-slate-600"
          >
            <MUIcon name="drag_indicator" className="text-[24px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwipeLayout;
