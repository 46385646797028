export function containCanvasToPng(
  originalCanvas: HTMLCanvasElement,
  targetWidth: number,
  targetHeight: number
): string {
  // Create a new canvas element
  const containedCanvas = document.createElement('canvas');
  const ctx = containedCanvas.getContext('2d');
  if (!ctx) {
    throw new Error('Unable to get 2D context');
  }

  // Calculate scale factors for both width and height
  const scaleX = targetWidth / originalCanvas.width;
  const scaleY = targetHeight / originalCanvas.height;
  const scale = Math.max(scaleX, scaleY);

  // Calculate scaled dimensions
  const scaledWidth = originalCanvas.width * scale;
  const scaledHeight = originalCanvas.height * scale;

  // Set dimensions for the contained canvas
  containedCanvas.width = targetWidth;
  containedCanvas.height = targetHeight;

  // Calculate starting point to center the image
  const startX = (targetWidth - scaledWidth) / 2;
  const startY = (targetHeight - scaledHeight) / 2;

  // Draw the scaled and centered image onto the new canvas
  ctx.drawImage(originalCanvas, startX, startY, scaledWidth, scaledHeight);

  // Convert the contained canvas to a PNG URL
  return containedCanvas.toDataURL('image/png');
}

export function chunkArray<T>(arr: T[], size: number): T[][] {
  // Initialize an empty array to hold the chunks
  const chunkedArr: T[][] = [];

  // Use a loop to iterate through the original array
  for (let i = 0; i < arr.length; i += size) {
    // Slice out a chunk and push it to the chunked array
    chunkedArr.push(arr.slice(i, i + size));
  }

  // Return the array of chunks
  return chunkedArr;
}
