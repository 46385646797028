import { PeTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React from 'react';
import { getCategoriesForChart, getChartTitle, getDataForSeries, getStep } from '../../helpers/helpers';
import NumberChart from '../shared/NumberChart';
import Table from '../shared/Table';
import { MultiChartDataOptions, MultiChartOptions } from './ChartTypes';
import MultipleBarChart from './MultipleBarChart';
import ScoreSlider from './ScoreSlider';
import SingleChart from './SingleChart';
import StackingChart from './StackingChart';

interface Props {
  reportData: PeTypes.Report;
}

const Report: React.FC<Props> = ({ reportData }) => (
  <div className="flex grow flex-col">
    {!reportData.visualizations.length && <>Report is empty</>}
    <div className="flex grow select-none flex-col gap-4 py-3">
      {reportData.visualizations.map((visualization: PeTypes.Visualization) => (
        <div key={visualization.title} className="border-b border-gray-200 pb-4 last:border-0">
          {getVisualization(reportData.data, visualization, reportData.metadata)}
        </div>
      ))}
    </div>
  </div>
);

export default Report;

const getVisualization = (
  data: { [key: string]: number },
  visualization: PeTypes.Visualization,
  metadata: { [key: string]: string }
) => {
  switch (visualization.type) {
    case 'charts':
      return getChart(data, visualization, metadata);
    case 'slider':
      return getSlider(data, visualization);
    case 'number':
      return getNumber(data, visualization, metadata);
    case 'table':
      return getTable(data, visualization, metadata);
    default:
      return <div>No visualizations provided</div>;
  }
};

const getTable = (
  data: { [key: string]: number },
  visualization: PeTypes.Visualization,
  metadata: { [key: string]: string }
) => {
  if (!visualization.rows) {
    return <div>No number visualization loaded</div>;
  }
  const tableRows: PeTypes.RowDataDefinition[] = visualization.rows.map((row) => {
    return {
      label: metadata[row.label] ?? row.label,
      valueFormat: row.valueFormat,
      value: data[row.value] ?? 'N/A',
      indent: row.indent,
      pctgValue: data[row.pctg],
    };
  });

  return <Table title={visualization.title} rows={tableRows} />;
};

const getNumber = (
  data: { [key: string]: number },
  visualization: PeTypes.Visualization,
  metadata: { [key: string]: string }
) => {
  if (!visualization.number) {
    return <div>No number visualization loaded</div>;
  }

  const value = parseFloat(data[visualization.number.value]?.toFixed(2));
  const calculatedValue = visualization.valueModifier != null ? value - visualization.valueModifier : value;
  const formattedValue = Number.isInteger(calculatedValue)
    ? calculatedValue
    : ValueFormatter.format(Math.abs(calculatedValue), visualization.number.valueFormat);
  const step: { color: string; label: string } | null = getStep(visualization.number.steps, calculatedValue);
  const label =
    step != null
      ? `${step.label} +${formattedValue}`
      : metadata[visualization.number.label] ?? visualization.number.label;

  return (
    <NumberChart
      title={visualization.title}
      label={label}
      value={formattedValue}
      valueFormat={visualization.number.valueFormat}
      color={step?.color}
    />
  );
};

const getSlider = (data: { [key: string]: number }, visualization: PeTypes.Visualization) => {
  if (!visualization.slider) {
    return <div>No slider visualization loaded</div>;
  }

  const value = data[visualization.slider.value];

  return (
    <ScoreSlider
      min={visualization.slider.min}
      max={visualization.slider.max}
      title={visualization.title}
      info={visualization.info}
      value={value}
      stops={visualization.slider.stops}
      valueFormat={visualization.slider.valueFormat}
    />
  );
};

const getChart = (
  data: { [key: string]: number },
  visualization: PeTypes.Visualization,
  metadata: { [key: string]: string }
) => {
  if (!visualization.charts) {
    return <div>No charts visualization loaded</div>;
  }
  if (visualization.charts.length === 1 && visualization.chartType === 'stacking') {
    return (
      <StackingChart
        title={visualization.title}
        data={getDataForSeries(visualization.charts[0].series, data)[0].data}
        colors={visualization.charts[0].series[0].colors}
        labelColors={['#436983', '#0000009E', '#BF3927']}
        categories={getCategoriesForChart(visualization.charts[0].categories, metadata)}
      />
    );
  } else if (visualization.charts.length === 1) {
    const chartData = {
      series: getDataForSeries(visualization.charts[0].series, data),
      valueFormat: visualization.charts[0].valueFormat,
    };
    const singleChartOptions = {
      title: visualization.title,
      categories: getCategoriesForChart(visualization.charts[0].categories, metadata),
      withFilter: visualization.withFilter,
      chartData: chartData,
      chartType: visualization.chartType,
      info: visualization.info,
      height: visualization.height,
    };
    return <SingleChart options={singleChartOptions} />;
  } else {
    const chartData: MultiChartDataOptions[] = visualization.charts.map((viz: PeTypes.ChartDefinition) => {
      return {
        colors: viz.series[0].colors,
        valueFormat: viz.valueFormat,
        titleChart: viz.titleChart != null ? getChartTitle(viz.titleChart, metadata) : '',
        titleColor: viz.titleColor ?? '#ff0',
        series: getDataForSeries(viz.series, data),
      };
    });

    const chartOptions: MultiChartOptions = {
      chartData,
      categories: getCategoriesForChart(visualization.charts[0].categories, metadata),
      title: visualization.title,
      info: visualization.info,
      height: visualization.height,
    };
    return <MultipleBarChart options={chartOptions} />;
  }
};
