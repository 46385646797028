import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { useSessionContext } from '../../../../contexts/SessionContext';
import { useWorkspaceContext } from '../../../../contexts/WorkspaceContext';
import CopyDatasetModal from '../CopyDatasetModal';
import * as MenuTypes from './menu.types';

const useCopyOption = (
  datasetId: string,
  datasetName: string,
  action: number | null,
  handleCloseRequest: () => void
): [Dropdowns.DropdownOption, JSX.Element | null] => {
  const { activeWorkspace } = useWorkspaceContext();
  const { workspaces } = useSessionContext();

  const option = {
    value: MenuTypes.MenuOption.COPY,
    name: 'Make a copy',
    icon: <MUIcon name="content_copy" />,
    additionalStyles: 'text-sm',
  };

  const modal =
    action === MenuTypes.MenuOption.COPY ? (
      <CopyDatasetModal
        activeWorkspace={activeWorkspace}
        datasetId={datasetId}
        workspaces={workspaces}
        datasetName={datasetName}
        onCloseRequest={handleCloseRequest}
      />
    ) : null;

  return [option, modal];
};

export default useCopyOption;
