import { MapSpecModifiers } from '@platform/maplibre';
import { useSurvey } from '@platform/shared/hooks';
import { Buttons, GeoLevelPicker, MUIcon } from '@platform/shared/ui';
import { MetadataTypes, PeTypes, ProjectTypes } from '@platform/types';
import pluralize from 'pluralize';
import React from 'react';
import { useDataSourceLayers } from '../../hooks';
import * as peApi from '../../pe.api';
import SearchEmptyState from '../MapSidebar/SearchEmptyState';
import Search from '../shared/Search';

interface Props {
  mapObj: ProjectTypes.MapObject;
}

const GeoSelection: React.FC<Props> = ({ mapObj }) => {
  const { styleSpec } = mapObj;
  const { geoFeatureSelection = [], dataLayer } = styleSpec;
  const { selection, preferredSummaryLevelId } = dataLayer;
  const [firstSelectedVariable] = selection;
  const { surveyName, isUserSurvey = false } = firstSelectedVariable;
  const surveyQuery = useSurvey({ surveyName, isUserSurvey, surveyApi: peApi.getSurvey });

  const dataSourceLayersQuery = useDataSourceLayers(surveyName, !!isUserSurvey);

  if (surveyQuery.isLoading || surveyQuery.isIdle) return <div className="p-4">Loading...</div>;
  if (surveyQuery.isError) return <div className="p-4">An error occurred</div>;

  const defaultGbGeoRecordSearchIndex = surveyQuery.data.defaultGbGeorecordSearchIndexId;

  const firstGeoFeatureSelected = geoFeatureSelection[0];
  const dataSource = dataSourceLayersQuery.data?.find(
    (ds) => firstGeoFeatureSelected && ds['summary-level'].id === firstGeoFeatureSelected.summaryLevelId
  );

  const handleRemove = (itemToRemove: ProjectTypes.GeoFeatureSelection) => {
    const newSelection = geoFeatureSelection.filter((x) => x.fips !== itemToRemove.fips);
    const updated = MapSpecModifiers.updateGeoSelection(mapObj.styleSpec, newSelection);
    mapObj.updateMapSpec(updated);
  };

  const handleGeoLevelSelection = (newDataSourceLayer: MetadataTypes.MetadataSocialExplorerDataSourceLayer) => {
    const newPreferredSummaryLevelId = newDataSourceLayer['summary-level'].id;
    let updated = MapSpecModifiers.updateDataLayerSummaryLevel(mapObj.styleSpec, newPreferredSummaryLevelId);

    // when summary level changes we want to clear geo selections too...
    if (preferredSummaryLevelId !== newPreferredSummaryLevelId) {
      updated = MapSpecModifiers.updateGeoSelection(updated, []);
    }

    mapObj.updateMapSpec(updated);
  };

  const handleClearSelection = () => {
    const updated = MapSpecModifiers.updateGeoSelection(mapObj.styleSpec, []);
    mapObj.updateMapSpec(updated);
  };

  const handleSearchSelection = async (item: ProjectTypes.GeoFeatureSelection, record: PeTypes.GeoRecord) => {
    const newSelection = [...geoFeatureSelection];
    const existingIndex = newSelection.findIndex((x) => x.fips === item.fips);
    if (existingIndex === -1) {
      newSelection.push(item);
    }

    mapObj.ref?.fitBounds(
      [
        [record.boundingBox.swLng, record.boundingBox.swLat],
        [record.boundingBox.neLng, record.boundingBox.neLat],
      ],
      {
        padding: { top: 200, bottom: 200, left: 250, right: 250 },
      }
    );

    const updated = MapSpecModifiers.updateGeoSelection(mapObj.styleSpec, newSelection);
    mapObj.updateMapSpec(updated);
  };

  const handleDelayedAction = async (item: ProjectTypes.GeoFeatureSelection) => {
    try {
      const geoRecords = await peApi.getGeoRecords({
        searchTerm: item.label,
        activeSummaryLevelId: preferredSummaryLevelId,
        defaultGbGeoRecordSearchIndex,
      });
      if (geoRecords) {
        const { boundingBox } = geoRecords[0];
        mapObj.ref?.fitBounds(
          [
            [boundingBox.swLng, boundingBox.swLat],
            [boundingBox.neLng, boundingBox.neLat],
          ],
          {
            padding: { top: 200, bottom: 200, left: 250, right: 250 },
          }
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnGeoFeatureSelectionKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    item: ProjectTypes.GeoFeatureSelection
  ) => {
    if (event.key === 'Enter') {
      handleDelayedAction(item);
    }
  };

  return (
    <div className=":not:empty:border mb-4 flex min-h-0 w-full flex-col gap-4 pb-8">
      <div className="flex flex-col gap-4">
        <GeoLevelPicker
          showHeader={geoFeatureSelection.length > 0}
          options={dataSourceLayersQuery.data ?? []}
          value={dataSourceLayersQuery.data?.find((x) => x['summary-level'].id === preferredSummaryLevelId)}
          onChange={handleGeoLevelSelection}
        />
        <Search
          mapObj={mapObj}
          allowMultiple={true}
          onSelect={handleSearchSelection}
          selectedGeoFeatureIds={geoFeatureSelection.map((x) => x.fips)}
          defaultGbGeoRecordSearchIndex={defaultGbGeoRecordSearchIndex}
        />
      </div>
      {geoFeatureSelection.length === 0 && <SearchEmptyState />}
      {geoFeatureSelection.length > 0 && (
        <div className="flex min-h-0 flex-col gap-4 py-6 pt-4">
          <div className="flex justify-between px-4 text-sm font-semibold">
            <span className="text-gray-800">
              {`${geoFeatureSelection.length} ${pluralize(
                dataSource?.['summary-level']?.label ?? 'Geography',
                geoFeatureSelection.length
              )}`}{' '}
              selected
            </span>
            <Buttons.Secondary
              onClick={handleClearSelection}
              className={'text-primary-500 p-0 shadow-none ring-0 hover:bg-white'}
            >
              Clear all
            </Buttons.Secondary>
          </div>
          <div className="flex flex-col">
            {[...geoFeatureSelection]
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((x: ProjectTypes.GeoFeatureSelection) => (
                <div
                  key={`geo-feature-${x.fips}`}
                  onClick={() => handleDelayedAction(x)}
                  onKeyDown={(event) => handleOnGeoFeatureSelectionKeyDown(event, x)}
                  className="group flex cursor-pointer items-center justify-between gap-4 rounded-lg px-4 py-2 text-sm hover:bg-gray-100"
                >
                  <MUIcon name="location_on" className="text-gray-600" />
                  <div className="w-full">{x.label}</div>
                  <Buttons.Secondary
                    className="h-9 w-9 shadow-none ring-0 group-hover:bg-gray-100"
                    onClick={(event) => {
                      handleRemove(x);
                      event.stopPropagation();
                    }}
                    icon={<MUIcon name="close" className="rounded-full p-[6px] text-gray-600 hover:bg-gray-200 " />}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeoSelection;
