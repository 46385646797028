import Dropdown from './Dropdown';
import Soft from './Soft';

export type DropdownOptionValue = string | number | boolean | null | Array<DropdownOption>;

export interface DropdownOption {
  value: DropdownOptionValue;
  name: string;
  label?: string;
  nameClasses?: string;
  icon?: JSX.Element;
  suffixIcon?: JSX.Element;
  additionalStyles?: string;
  disabled?: boolean;
  dividerAfter?: boolean;
  dataCy?: string;
}

export { Dropdown, Soft };
