import React from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { WorkspaceContextProvider } from '../contexts';
import { WORKSPACE_STORAGE_ITEM_ID } from '../contexts/WorkspaceContext';
import { FullScreenLayout, RootLayout, WorkspaceLayout } from '../layout';
import { generateLink } from '../LinkGenerator';
import DashboardPage from './Dashboard.page';
import DatasetPage from './Dataset.page';
import DatasetsPage from './Datasets.page';
import EditStoryPage from './EditStory.page';
import ProfilePage from './Profile.page';
import ProjectsPage from './Projects.page';
import PublicProfilePage from './PublicProfile.page';
import PublicStoryPage from './PublicStory.page';
import StoryPage from './Story.page';
import ViewStoryPage from './ViewStory.page';
import WorkspacePage from './Workspace.page';

const urlParams = new URLSearchParams(window.location.search);
const workspaceIdParam = urlParams.get(WORKSPACE_STORAGE_ITEM_ID);
if (workspaceIdParam) {
  localStorage.setItem(WORKSPACE_STORAGE_ITEM_ID, workspaceIdParam.toString());
  window.history.replaceState(null, '', generateLink('workspace', { workspaceId: workspaceIdParam }));
}

const WorkspacePagesWrapper: React.FC = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const activeWorkspaceId = parseInt(workspaceId ?? localStorage.getItem(WORKSPACE_STORAGE_ITEM_ID) ?? '0', 10);

  return (
    <WorkspaceContextProvider workspaceId={activeWorkspaceId}>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="projects/:projectId?" element={<ProjectsPage />} />
          <Route path="stories">
            <Route path=":storyId" element={<StoryPage />} />
          </Route>
          <Route path="datasets" element={<DatasetsPage />} />
        </Route>
        <Route path="/" element={<WorkspaceLayout />}>
          <Route path="/workspace/*" element={<WorkspacePage />}></Route>
        </Route>
        <Route path="datasets" element={<FullScreenLayout />}>
          <Route path=":datasetId/:mode?" element={<DatasetPage />} />
        </Route>
        <Route path="/maps" element={<FullScreenLayout />}>
          <Route path=":profileId/:mode?" element={<ProfilePage />} />
          <Route path=":profileId/public" element={<PublicProfilePage />} />
        </Route>
        <Route path="/stories" element={<FullScreenLayout />}>
          <Route path=":storyId/edit" element={<EditStoryPage />} />
          <Route path=":storyId/public" element={<PublicStoryPage />} />
          <Route path=":storyId/view" element={<ViewStoryPage />} />
          <Route path=":storyId/play" element={<ViewStoryPage />} /> {/*just to be backwards compatible*/}
        </Route>
        <Route
          path="*"
          element={
            <div className="flex flex-col gap-4">
              There is nothing here<Link to="/">Home</Link>
            </div>
          }
        />
      </Routes>
    </WorkspaceContextProvider>
  );
};
export default WorkspacePagesWrapper;
