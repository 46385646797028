import { Modal, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import { AxiosError } from 'axios';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSessionContext } from '../../../contexts/SessionContext';
import { useWorkspaceContext } from '../../../contexts/WorkspaceContext';
import { useDataset, useDatasetLastPublishedState, useDatasetUnpublishedLog } from '../../../hooks';
import * as peApi from '../../../pe.api';
import { UpdatedAgo } from '../../shared';

interface Props {
  datasetId: string;
  onCloseRequest: () => void;
}

const getActionDefinition = (actionType: string, actions: PeTypes.DatasetLogItem[]) => {
  let icon;
  let desc;

  switch (actionType) {
    case PeTypes.DatasetActionEnum.ROW_CREATION:
      icon = 'menu';
      desc = `${actions.length} ${pluralize('Row', actions.length)} added`;
      break;
    case PeTypes.DatasetActionEnum.COLUMN_CREATION:
      icon = 'view_column_2';
      desc = `${actions.length} ${pluralize('Column', actions.length)} added`;
      break;
    case PeTypes.DatasetActionEnum.CELL_UPDATE:
      icon = 'activity_zone';
      desc = `${actions.length} ${pluralize('Cell', actions.length)} updated`;
      break;
    case PeTypes.DatasetActionEnum.COLUMN_METADATA:
      icon = 'rubric';
      desc = 'Filterable variables list updated';
      break;
    case PeTypes.DatasetActionEnum.COLUMN_COMBINATION:
      icon = 'cell_merge';
      desc = `${actions.length}  Combined ${pluralize('columns', actions.length)} created`;
      break;
    case PeTypes.DatasetActionEnum.COLUMN_RENAME:
      icon = 'rubric';
      desc = `${actions.length} ${pluralize('Column', actions.length)} renamed`;
      break;
    case PeTypes.DatasetActionEnum.COLUMN_METADATA_UPDATE:
      icon = 'view_column_2';
      desc = `${actions.length} ${pluralize('Column', actions.length)} updated`;
      break;
    case PeTypes.DatasetActionEnum.ROW_SUBJECT_UPDATE:
      icon = 'menu_open';
      desc = `${actions.length} Data row ${pluralize('subject', actions.length)} updated`;
      break;
    case PeTypes.DatasetActionEnum.COLUMN_DELETION:
      icon = 'remove_selection';
      desc = `${actions.length} ${pluralize('Column', actions.length)} deleted`;
      break;
    case PeTypes.DatasetActionEnum.COLUMN_DUPLICATION:
      icon = 'view_column_2';
      desc = `${actions.length} ${pluralize('Column', actions.length)} duplicated`;
      break;
    case PeTypes.DatasetActionEnum.ROW_DELETION: {
      const rowDeletionActions = actions as PeTypes.DatasetLogItem<PeTypes.RowDeletionLogItem>[];
      const deletedRowsCount = rowDeletionActions.reduce(
        (count, action) => count + action.metadata.rowsUuids.length,
        0
      );
      icon = 'remove_selection';
      desc = `${deletedRowsCount} ${pluralize('Row', deletedRowsCount)} deleted`;
      break;
    }

    default:
      icon = 'question_mark';
      desc = 'Unknown action';
  }
  return (
    <div className="flex items-center gap-3">
      <MUIcon name={icon} className="text-sm" />
      <span className="text-sm text-gray-800">{desc}</span>
    </div>
  );
};

const PublishDatasetModal: React.FC<Props> = ({ datasetId, onCloseRequest }) => {
  const { activeWorkspace } = useWorkspaceContext();
  const { notify } = useSessionContext();
  const datasetQuery = useDataset(datasetId);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const datasetLogQuery = useDatasetUnpublishedLog(datasetId, true);
  const { query: lastPublishedStateQuery } = useDatasetLastPublishedState({ datasetId });
  const queryClient = useQueryClient();

  const unpublishedChanges = datasetLogQuery.data?.filter((x) => x.actionType !== 'PUBLISH') ?? [];

  const groupedChanges = unpublishedChanges.reduce<Record<string, PeTypes.DatasetLogItem[]>>((acc, action) => {
    const { actionType } = action;

    if (!acc[actionType]) {
      acc[actionType] = [];
    }
    acc[actionType].push(action);

    return acc;
  }, {} as Record<string, PeTypes.DatasetLogItem[]>);

  const handlePublishRequest = async () => {
    setIsPublishing(true);

    try {
      await peApi.publishDataset(datasetId);

      notify({
        content: <span>Publishing changes...</span>,
        icon: <MUIcon className="animate-spin" name="progress_activity" />,
      });
    } catch (error) {
      const publishingError = error as AxiosError<PeTypes.PublishDatasetError>;

      let errorMessage = 'Failed to publish changes.';

      if (publishingError.response?.data.storageExceeded) {
        errorMessage +=
          ' It appears that you have reached your storage limit. Please take a moment to review and delete any unnecessary datasets.';
      }

      notify({
        content: <span>{errorMessage}</span>,
      });
    } finally {
      await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
      await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });
      await queryClient.invalidateQueries({ queryKey: ['datasets', activeWorkspace.id] });

      setIsPublishing(false);
      onCloseRequest();
    }
  };

  const editedAt = datasetQuery.data?.editedAt;
  const hasEverBeenPublished = !!lastPublishedStateQuery.data;

  return (
    <Modal
      title="Publish changes"
      className={'w-[480px]'}
      isVisible={true}
      okLabel={isPublishing ? 'Publishing...' : 'Publish'}
      okDisabled={isPublishing}
      onDone={handlePublishRequest}
      onCloseRequest={onCloseRequest}
    >
      <div className="mt-2 flex flex-col gap-8">
        {hasEverBeenPublished ? (
          <div className="text-sm text-gray-600">
            If you’ve created maps using this dataset, you need to publish these changes to see the updates on the maps.
          </div>
        ) : (
          <div className="mb-4 flex flex-col gap-4 text-sm text-gray-600">
            <span>
              This dataset was added for you by the Social Explorer team{' '}
              {editedAt && <UpdatedAgo prefixLabel="" dateTime={editedAt} className="text-sm font-semibold" />}.
            </span>
            <div>
              To start creating maps with it, all you need is to click <span className="font-semibold">'Publish'</span>.
            </div>
          </div>
        )}

        {unpublishedChanges.length > 0 && hasEverBeenPublished && (
          <div>
            <div className="mb-4 text-sm">
              <span className="font-semibold">Changes</span>{' '}
            </div>
            <div className="flex max-h-[400px] flex-col gap-2 overflow-auto">
              {Object.keys(groupedChanges).map((x) => (
                <div className="flex justify-between px-3 py-2" key={x}>
                  <div className="flex items-center">{getActionDefinition(x, groupedChanges[x])}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PublishDatasetModal;
