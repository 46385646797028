import React from 'react';
import tw from 'tailwind-styled-components';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  danger?: boolean;
  children: React.ReactNode;
}

export const Button: React.FC<Props> = ({ danger, children, ...other }) => (
  <StyledButton $danger={danger} {...other}>
    {children}
  </StyledButton>
);

export default Button;

const StyledButton = tw('button')<{ $danger?: boolean; disabled?: boolean }>`
  cursor-pointer
  select-none
  flex
  gap-1
  items-center
  ${(p) => (p.$danger ? 'bg-gray-500 hover:bg-gray-700' : 'bg-primary-500 hover:bg-primary-700')}
  ${(p) => (p.disabled ? 'opacity-50 pointer-events-none' : '')}
  text-white
  font-bold
  py-1
  px-4
  rounded
`;
