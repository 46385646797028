import { Dropdowns } from '@platform/shared/ui';
import React, { useState } from 'react';
import DatasetCellEditModal from './DatasetCellEditModal';

interface Props {
  value: boolean | null;
  onCloseRequest: () => void;
  anchorRect: DOMRect | { top: number; left: number; width: number };
  onDone: (value: boolean | null) => void;
}

type Input = boolean | null;

const EditBooleanCellModal: React.FC<Props> = ({ value, onCloseRequest, anchorRect, onDone }) => {
  const [inputValue, setInputValue] = useState<Input>(value);
  const options = [
    { name: 'true', value: true, additionalStyles: 'text-xs py-1' },
    { name: 'false', value: false, additionalStyles: 'text-xs py-1' },
    { name: 'null', value: null, additionalStyles: 'text-xs py-1' },
  ];
  const onSaveEdit = () => {
    if (value !== inputValue) {
      onDone(inputValue);
    }
    onCloseRequest();
  };

  return (
    <DatasetCellEditModal
      className="min-w-8 border-primary-700 shadow-none"
      position={{ top: anchorRect.top - 16, left: anchorRect.left - 16 }}
      style={{ width: anchorRect.width + 48 }}
      onCloseRequest={onCloseRequest}
      okLabel="Save"
      onDone={onSaveEdit}
    >
      <div className="flex min-h-[48px] items-center px-4">
        <Dropdowns.Dropdown
          value={inputValue}
          onSelect={(option) => setInputValue(option.value as Input)}
          options={options}
          triggerClasses="text-xs ring-0 shadow-none -ml-2"
          menuClasses="min-w-[158px] -ml-2"
        />
      </div>
    </DatasetCellEditModal>
  );
};

export default EditBooleanCellModal;
