import { ProjectTypes } from '@platform/types';
import React from 'react';
import WorkingLayerInsightsWrapper from './WorkingLayerInsightsWrapper';

interface Props {
  workingLayers: ProjectTypes.WorkingLayerSpec[];
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[];
}

const WorkingLayers: React.FC<Props> = ({ workingLayers, geoFeatureSelection }) => {
  return (
    <>
      {workingLayers.map((x) => (
        <WorkingLayerInsightsWrapper key={x.id} geoFeatureSelection={geoFeatureSelection} workingLayer={x} />
      ))}
    </>
  );
};

export default WorkingLayers;
