import { useWebWorker } from '@platform/shared/hooks';
import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import { FileParser } from '@platform/utils';
import { AxiosError } from 'axios';
import * as GeoJSON from 'geojson';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSessionContext } from '../../contexts/SessionContext';
import { uploadDatasetToS3 } from '../../helpers/datasetHelpers';
import * as peApi from '../../pe.api';
import CreateGeography from './CreateGeography/CreateGeography';
import DataFormatting from './DataFormatting/DataFormatting';
import ImportError from './Errors/ImportError';
import ParserError from './Errors/ParserError';
import StorageError from './Errors/StorageError';
import FileHandler from './FileUpload/FileHandler';
import ImportPreview from './ImportPreview/ImportPreview';
import { fileParserTypeToDataType } from './utils';

interface Props {
  workspaceName: string;
  workspaceStatistics: PeTypes.WorkspaceStatistics;
  onWizardClose: () => void;
}

interface UploadStep {
  stepTitle: string;
  stepContent: React.ReactNode;
  skipFn?: () => void;
}

interface ImportErrors {
  storageLimit?: boolean;
  geocodeLimit?: boolean;
  parser?: FileParser.Types.ParserError;
  import?: string;
}

export interface ImportProgress extends PeTypes.ImportDatasetPayload {
  isParsing: boolean;
  data: unknown[][];
  totalDataCount: number;
  columnsAsParsed: PeTypes.ImportDatasetPayloadColumn[];
  fileContainsGeographies: boolean;
  featureCollection?: GeoJSON.FeatureCollection;
  errors: ImportErrors;
}

export const SAMPLE_SIZE = 100; // how many rows should the parser limit to

const DEFAULT_IMPORT_PROGRESS_STATE: ImportProgress = {
  isParsing: false,
  fileNames: [],
  uploadedFileName: '',
  contentType: '',
  contentLength: 0,
  columns: [],
  columnsAsParsed: [], // the original columns as parsed by the parser
  data: [],
  totalDataCount: 0,
  fileContainsGeographies: false,
  errors: {},
};

const ImportWizard: React.FC<Props> = ({ workspaceName, workspaceStatistics, onWizardClose }) => {
  const { notify } = useSessionContext();
  const { postMessage, data } = useWebWorker<FileParser.Types.WorkerPayload, FileParser.Types.Result>(
    'assets/workers/file-parser/index.js'
  );
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [importData, setImportData] = useState<ImportProgress>(DEFAULT_IMPORT_PROGRESS_STATE);
  const freeStorageSpaceSizeInBytes = Math.max(
    workspaceStatistics.permission.maxStorage - workspaceStatistics.totalInBytes,
    0
  );

  useEffect(() => {
    if (file) {
      setImportData(DEFAULT_IMPORT_PROGRESS_STATE);
      try {
        postMessage({
          file,
          config: {
            maxFileSizeInBytes: parseInt(process.env.NX_IMPORT_FILE_SIZE_LIMIT_IN_BYTES as string, 10),
            sampleSize: SAMPLE_SIZE,
            csv: { firstRowIsHeader: true },
          },
        });
        setImportData((p) => ({ ...p, isParsing: true }));
      } catch {
        setImportData({ ...DEFAULT_IMPORT_PROGRESS_STATE, errors: { parser: FileParser.Types.ParserError.UNKNOWN } });
      }
    } else {
      // reset
      setCurrentStep(0);
    }
  }, [file]);

  useEffect(() => {
    if (data) {
      const { sourceFile, errors: parsingErrors, featureCollection, columns, files = [], rows, totalRowsCount } = data;
      if (parsingErrors.length) {
        setFile(null);
        setImportData({ ...DEFAULT_IMPORT_PROGRESS_STATE, errors: { parser: parsingErrors[0] } });
        return;
      }

      const errors: ImportErrors = {};
      const geocodeLimitReached =
        workspaceStatistics.quota.hereGeocoderQuota - workspaceStatistics.quota.hereGeocoderUsed < totalRowsCount ||
        workspaceStatistics.quota.hereGeocoderQuota < totalRowsCount;
      if (geocodeLimitReached) {
        errors.geocodeLimit = true;
      }
      const columnsToBeImported =
        columns?.map((x) => ({
          name: x.name,
          type: fileParserTypeToDataType(x.type),
        })) ?? [];

      setImportData({
        featureCollection,
        totalDataCount: totalRowsCount,
        isParsing: false,
        fileContainsGeographies: featureCollection != null,
        fileNames: files?.map((el: File) => el.name) ?? [],
        uploadedFileName: sourceFile.name,
        contentType: sourceFile.type,
        contentLength: sourceFile.size,
        columns: columnsToBeImported,
        columnsAsParsed: [...columnsToBeImported], // we store the original column types for future ref
        data: rows ?? [],
        errors,
      });
    }
  }, [data]);

  const importDatasetMutation = useMutation({
    mutationFn: async (payload: PeTypes.ImportDatasetPayload) => peApi.importDataset(payload),
    onSuccess: async (res) => {
      try {
        await uploadDatasetToS3(res.signedUrl, file as File);
        startDatasetImportMutation.mutate({ importId: res.importId });
      } catch (e) {
        notify({
          hideAfterSec: 10,
          content: `Something went wrong while uploading file`,
          icon: <MUIcon name="close" />,
        });
      }
    },
    onError: (error: AxiosError<PeTypes.ApiError>) => {
      let importError = 'Something went wrong. Please try again.';

      if (error.response?.data?.message) {
        importError = error.response.data.message.toString();
      }
      setImportData((prev) => ({ ...prev, errors: { import: importError } }));
    },
  });

  const startDatasetImportMutation = useMutation({
    mutationFn: (payload: { importId: string }) => peApi.startImport(payload.importId),
    onSuccess: () => {
      onWizardClose();
      notify({
        hideAfterSec: 3,
        content: `Importing “${file?.name.split('.')[0]}“`,
        icon: <MUIcon className="animate-spin" name="progress_activity" />,
      });
    },
  });

  const handleFileSelect = async (file: File) => {
    if (file.size < freeStorageSpaceSizeInBytes) {
      setFile(file);
    } else {
      setImportData((prevState) => ({
        ...prevState,
        errors: {
          storageLimit: true,
        },
      }));
    }
  };

  const handleImportGeoData = (
    latCol: string | undefined,
    lngCol: string | undefined,
    featureCollection: GeoJSON.FeatureCollection | undefined
  ) =>
    setImportData((prevState) => ({
      ...prevState,
      featureCollection,
      geo: { latitude: latCol, longitude: lngCol },
    }));

  const handleGeocodeData = (
    geocodeObj: PeTypes.ImportDatasetGeoCode | undefined,
    featureCollection: GeoJSON.FeatureCollection | undefined
  ) =>
    setImportData((prevState) => ({
      ...prevState,
      featureCollection,
      geo: { geocode: geocodeObj },
    }));

  const onFileRemove = () => setFile(null);

  const onRowSubjectChange = (rowSubject: string) => setImportData((prevState) => ({ ...prevState, rowSubject }));

  const onImportDataFormattingChange = (column: PeTypes.ImportDatasetPayloadColumn) => {
    setImportData((prevState) => {
      const columnIndex = prevState.columns.findIndex((x) => x.name === column.name);
      if (columnIndex === -1) {
        return prevState;
      }

      const newColumns = [...prevState.columns];
      newColumns[columnIndex] = column;

      return { ...prevState, columns: newColumns };
    });
  };

  const handleSkipStep = () => {
    handleRemoveGeo();
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleRemoveGeo = () =>
    setImportData((prevState) => {
      const { geo, ...newState } = prevState;
      return newState;
    });

  const handleImport = () => {
    const payload: PeTypes.ImportDatasetPayload = {
      uploadedFileName: importData.uploadedFileName,
      fileNames: importData.fileNames,
      contentType: importData.contentType,
      contentLength: importData.contentLength,
      rowSubject: importData.rowSubject,
      columns: importData.columns,
      geo: importData.geo,
    };
    importDatasetMutation.mutate(payload);
  };

  const fileUploadSteps: UploadStep[] = [];

  fileUploadSteps.push({
    stepTitle: 'Upload data',
    stepContent: (
      <FileHandler onSelect={handleFileSelect} onFileRemove={onFileRemove} importData={importData} file={file} />
    ),
  });

  fileUploadSteps.push({
    stepTitle: 'Set row & column types',
    stepContent: (
      <DataFormatting
        importData={importData}
        onImportDataFormattingChange={onImportDataFormattingChange}
        onRowSubjectChange={onRowSubjectChange}
      />
    ),
  });

  // the <SelectGeographies /> step depends on the imported file. If it contains geographies (SHP or KML) this step needs to be skipped
  if (!importData.fileContainsGeographies) {
    fileUploadSteps.push({
      stepTitle: 'Create a geography',
      stepContent: (
        <CreateGeography
          columns={importData.columns}
          data={importData.data}
          onSelectLatLong={handleImportGeoData}
          onSelectGeoCode={handleGeocodeData}
          importData={importData}
          onClear={handleRemoveGeo}
          workspaceStatistics={workspaceStatistics}
        />
      ),
      skipFn: handleSkipStep,
    });
  }

  fileUploadSteps.push({
    stepTitle: 'Review upload',
    stepContent: <ImportPreview importData={importData} />,
  });

  const isLastStep = currentStep === fileUploadSteps.length - 1;
  const isFirstStep = currentStep === 0;
  // ensure that the current step is Create geography step and validate the selected columns
  const isGeoStep = currentStep === 2;
  const isGeoDataValid =
    importData.geo && ((importData.geo.latitude && importData.geo.longitude) || importData.geo.geocode);
  const isNextDisabled = isLastStep || !file || importData.isParsing || (isGeoStep && !isGeoDataValid);
  const hasError = !!(importData.errors.import || importData.errors.storageLimit || importData.errors.parser);
  const importDisabled = importDatasetMutation.isLoading || !file || hasError;
  const { stepTitle, skipFn, stepContent } = fileUploadSteps[currentStep];

  return (
    <div className="absolute top-0 left-0 z-[999999] flex h-full w-full flex-col bg-white">
      <div className="flex items-center justify-end px-8 pt-8">
        <button onClick={onWizardClose}>
          <MUIcon name="close" />
        </button>
      </div>
      <div className="flex min-h-0 w-full justify-center">
        <div className="flex w-full flex-col items-center gap-8 py-4">
          <div className="flex w-8/12 flex-col">
            <div className="flex w-full items-center justify-between">
              <p className="text-gray-950 text-2xl font-semibold" data-cy="import-step-title">
                {stepTitle}
              </p>
              <div className="flex items-center justify-center gap-3">
                {!isFirstStep && (
                  <Buttons.Secondary
                    className="h-[36px] w-[80px]"
                    onClick={() => setCurrentStep((prevState) => prevState - 1)}
                  >
                    Back
                  </Buttons.Secondary>
                )}
                {isLastStep ? (
                  <Buttons.Primary
                    className="h-[36px] w-[148px]"
                    onClick={handleImport}
                    disabled={importDisabled}
                    data-cy="import-start-upload-button"
                  >
                    {importDatasetMutation.isLoading ? 'Importing..' : 'Import dataset'}
                  </Buttons.Primary>
                ) : (
                  <Buttons.Primary
                    className="h-[36px] w-[80px]"
                    onClick={() => setCurrentStep((prevState) => prevState + 1)}
                    disabled={isNextDisabled}
                    data-cy="upload-next-button"
                  >
                    Next
                  </Buttons.Primary>
                )}
              </div>
            </div>
            <p className="text-sm text-gray-500">{`Step ${currentStep + 1} of ${fileUploadSteps.length}`}</p>
          </div>
          <div className="flex min-h-0 w-full flex-grow flex-col items-center gap-4">
            {hasError && (
              <div className="w-8/12">
                {importData.errors.parser && (
                  <ParserError
                    error={importData.errors.parser}
                    onDismiss={() => setImportData(DEFAULT_IMPORT_PROGRESS_STATE)}
                  />
                )}
                {importData.errors.import && <ImportError error={importData.errors.import} />}
                {importData.errors.storageLimit && (
                  <StorageError
                    workspaceName={workspaceName}
                    workspaceStatistics={workspaceStatistics}
                    onDismiss={() => setImportData(DEFAULT_IMPORT_PROGRESS_STATE)}
                  />
                )}
              </div>
            )}
            <div className="flex h-full min-h-0 w-full flex-col overflow-y-auto">{stepContent}</div>
          </div>
          {skipFn && (
            <button
              className="flex items-center justify-center text-sm"
              onClick={skipFn}
              data-cy="import-skip-step-button"
            >
              <p>
                Or, <span className="text-primary-600 font-semibold">skip for now</span>
              </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ImportWizard;
