import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import CreateProfileModal from '../Profiles/CreateProfileModal';
import CreateProjectModal from '../Projects/CreateProjectModal';
import CreateStoryModal from '../Stories/CreateStoryModal';

const CreateNew: React.FC = () => {
  const { projectId } = useParams();
  const { activeWorkspace } = useWorkspaceContext();
  const [currentAction, setCurrentAction] = useState<PeTypes.Action | undefined>();

  const defaultProjectId = projectId === 'all' ? null : projectId;

  const options: Dropdowns.DropdownOption[] = [
    {
      name: 'Map',
      value: PeTypes.Action.NEW_PROFILE,
      icon: <MUIcon name="location_on" />,
      dataCy: 'create-map-option',
    },
    {
      name: 'Story',
      value: PeTypes.Action.NEW_STORY,
      icon: <MUIcon name="note_stack" />,
      dataCy: 'create-story-option',
    },
    {
      name: 'Project',
      value: PeTypes.Action.NEW_PROJECT,
      icon: <MUIcon name="folder" />,
      dataCy: 'create-project-option',
    },
  ];

  const handleSelectDropdownOption = (opt: Dropdowns.DropdownOption) => setCurrentAction(opt.value as PeTypes.Action);

  const actionableContent = useMemo(() => {
    switch (currentAction) {
      case PeTypes.Action.NEW_PROJECT:
        return <CreateProjectModal onCloseRequest={() => setCurrentAction(undefined)} />;
      case PeTypes.Action.NEW_PROFILE:
        return (
          <CreateProfileModal onCloseRequest={() => setCurrentAction(undefined)} defaultProjectId={defaultProjectId} />
        );
      case PeTypes.Action.NEW_STORY:
        return (
          <CreateStoryModal
            mapContextId=""
            onCloseRequest={() => setCurrentAction(undefined)}
            defaultProjectId={defaultProjectId}
          />
        );
      default:
        break;
    }
  }, [currentAction, projectId, activeWorkspace]);

  return (
    <>
      <Dropdowns.Dropdown
        name="create-new-asset"
        hAlignment="right"
        customLabel="Create"
        prefixIcon={<MUIcon name="add" />}
        icon={<MUIcon name="arrow_drop_down" />}
        iconClose={<MUIcon name="arrow_drop_up" className={'text-white'} />}
        onSelect={handleSelectDropdownOption}
        triggerContentClasses={'gap-1'}
        options={options}
        triggerClasses="bg-primary-600 hover:bg-primary-500 text-white ring-0 rounded-[999px] w-[114px] h-[40px] text-sm gap-0"
      />
      {actionableContent}
    </>
  );
};

export default CreateNew;
