import { Dropdowns, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NIL as NIL_UUID } from 'uuid';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useProfiles from '../../hooks/useProfiles';
import useProject from '../../hooks/useProject';
import useStories from '../../hooks/useStories';
import { generateLink, matchRoute } from '../../LinkGenerator';
import Profiles from '../Profiles/ProfilesList';
import { Tabs } from '../shared';
import Stories from '../Stories/StoriesList';
import CreateProjectButton from './CreateProjectButton';
import ProjectsList, { Item as ProjectItem } from './ProjectsList';
import RenameProjectModal from './RenameProjectModal';

export const DROPDOWN_OPTIONS = [{ value: 'RENAME', name: 'Rename' }];

interface Props {
  projectId: string | undefined;
}

const Projects: React.FC<Props> = ({ projectId }) => {
  const location = useLocation();
  const { activeWorkspace } = useWorkspaceContext();
  const profilesQuery = useProfiles(activeWorkspace.id, projectId);
  const storiesQuery = useStories(activeWorkspace.id, projectId);
  const projectQuery = useProject(projectId);

  const includeAllProjects = projectId === NIL_UUID;

  const [showProjectRenameModal, setShowProjectRenameModal] = useState<boolean>(false);

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case 'RENAME':
        setShowProjectRenameModal(true);
        break;
      default:
        return;
    }
  };

  const tabs = [
    {
      key: 'profiles',
      label: 'Maps',
      children: <Profiles profiles={profilesQuery.data} projectId={projectId} />,
    },
    {
      key: 'stories',
      label: 'Stories',
      children: <Stories stories={storiesQuery.data} projectId={projectId} />,
    },
  ];

  const projectsRoute = 'allProjects';
  const projectsLink = generateLink(projectsRoute, { workspaceId: activeWorkspace.id });

  let title;
  if (includeAllProjects) {
    title = 'All records';
  } else if (projectId) {
    title = projectQuery.data?.title ?? 'Loading project details';
  } else {
    title = 'Unsorted';
  }

  return (
    <>
      <div className="mx-auto flex h-full max-w-7xl grow gap-10">
        <div className="flex h-full w-80 flex-shrink-0 flex-col gap-4 overflow-y-auto border-r border-solid border-slate-200 pr-4">
          <div className="mt-12 flex flex-col gap-6">
            <ProjectItem $active={matchRoute(location.pathname) === projectsRoute} to={projectsLink}>
              All Records
            </ProjectItem>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center gap-2">
                <MUIcon name="folder" className="h-5 w-5" />
                <div className="px-2 text-lg font-bold">Projects</div>
              </div>
              <CreateProjectButton />
            </div>
            <ProjectsList />
          </div>
        </div>
        <div className="mt-12 flex flex-grow flex-col gap-6 pb-24">
          <div className="flex items-center justify-between px-4">
            <div className="mb-4 text-3xl">{title}</div>
            {projectId && !includeAllProjects && (
              <div onClick={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()}>
                <Dropdowns.Dropdown
                  icon={<MUIcon name="more_horiz" className="text-gray-400" aria-hidden="false" />}
                  iconClose={null}
                  options={DROPDOWN_OPTIONS}
                  onSelect={onSelectDropdownOption}
                  hAlignment="right"
                />
              </div>
            )}
          </div>
          <Tabs tabProps={tabs} tabStyle="button" />
        </div>
      </div>
      {showProjectRenameModal && projectQuery.data && (
        <RenameProjectModal
          project={projectQuery.data}
          onCloseRequest={() => {
            setShowProjectRenameModal(false);
          }}
        />
      )}
    </>
  );
};

export default Projects;
