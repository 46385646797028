import React from 'react';

interface Props {
  leftMap: React.ReactNode;
  rightMap: React.ReactNode;
  children?: React.ReactNode;
}

const SideLayout: React.FC<Props> = ({ leftMap, rightMap, children }) => {
  return (
    <div className="flex h-full w-full">
      {leftMap}
      <div className="flex h-full w-[4px] flex-shrink-0 bg-slate-700" />
      {rightMap}
      {children && <div className="absolute bottom-0 left-0 right-0 top-0">{children}</div>}
    </div>
  );
};

export default SideLayout;
