import { ProjectTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';

interface Props {
  surveyName: string;
  isUserSurvey: boolean;
  surveyApi: (surveyName: string, isUserSurvey: boolean) => Promise<ProjectTypes.SurveyInfo>;
}

function useSurvey({ surveyName, isUserSurvey, surveyApi }: Props, options?: UseQueryOptions<ProjectTypes.SurveyInfo>) {
  return useQuery<ProjectTypes.SurveyInfo>(['survey', surveyName], () => surveyApi(surveyName, isUserSurvey), options);
}

export default useSurvey;
