import { VariableInfo } from '@platform/shared/integrated-ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import tw from 'tailwind-styled-components';
import Thumb from '../Thumb';

interface Props {
  activeMapId?: string;
  onSelect: (map: PeTypes.DefaultMap) => void;
  maps: PeTypes.DefaultMap[] | undefined;
}

const DefaultMapPicker: React.FC<Props> = ({ activeMapId, onSelect, maps }) => {
  return (
    <div className="flex max-h-[256px] flex-col gap-2 overflow-auto" id="defaultMap" data-cy="default-map-picker">
      {maps &&
        maps.map((map) => {
          const active = map.id === activeMapId;
          const { mapSpec } = map;

          const [firstVariable] = mapSpec.dataLayer.selection;

          return (
            <Item key={map.id} $active={active} onClick={() => onSelect(map)}>
              <Thumb url={map.thumbPath} className="h-[80px] w-[80px] rounded-lg" />
              <div className="flex grow flex-col">
                <div className="text-sm font-bold text-gray-900">{map.title}</div>
                <span className="text-xs text-gray-500">
                  <div>
                    <VariableInfo def={firstVariable} showUniverse={false} />
                  </div>
                </span>
              </div>
              <button
                type="button"
                className={`h-[16px] w-[16px] rounded-lg border-2 border-solid border-gray-400 ${
                  active ? 'bg-primary-600 border-primary-400' : 'bg-transparent'
                }`}
              />
            </Item>
          );
        })}
    </div>
  );
};

export default DefaultMapPicker;

const Item = tw.div<{ $active: boolean }>`
  flex
  select-none
  items-center
  grow
  cursor-pointer
  gap-4
  border-2
  border-transparent
  rounder-lg
  pr-4
  transition-colors
  ${(p) => (p.$active ? ' bg-gray-50' : '')}
`;
