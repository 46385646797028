import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import EmptyState from '../shared/EmptyState';
import RecentStoriesListItem from './RecentStoriesListItem';

interface Props {
  title?: string;
  stories: PeTypes.RecentStory[];
}

const RecentStoriesList: React.FC<Props> = ({ title, stories }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-2">
        <div className="flex items-center justify-center gap-2">
          <MUIcon name="note_stack" className="text-gray-600" aria-hidden="false" />
          <div className="text-lg font-bold">{title}</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2" data-cy="recent-stories-list">
        {stories.length === 0 && (
          <EmptyState
            title="Transform your analyses into impactful data-driven stories that stand out."
            trigger={<Buttons.Primary>Create a Story</Buttons.Primary>}
            action={PeTypes.Action.NEW_STORY}
          />
        )}
        {stories.map((item) => (
          <RecentStoriesListItem key={item.id} story={item} />
        ))}
      </div>
    </div>
  );
};

export default RecentStoriesList;
