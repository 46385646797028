import { Buttons, Spinner } from '@platform/shared/ui';
import { FileParser } from '@platform/utils';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as DragDropIcon } from '../../../../assets/drag-and-drop.svg';
import { getMaxFileSizeInMB } from '../utils';
import LearnMoreModal from './LearnMoreModal';

interface Props {
  isParsing: boolean;
  onSelect: (file: File) => void;
}

const FileUpload: React.FC<Props> = ({ isParsing, onSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    // Prevent default behavior for dragover and drop events on the window
    const handleDragDrop = (e: DragEvent) => {
      e.preventDefault();
    };

    if (!isParsing) {
      window.addEventListener('dragover', handleDragDrop, false);
      window.addEventListener('drop', handleDragDrop, false);
    }
    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener('dragover', handleDragDrop);
      window.removeEventListener('drop', handleDragDrop);
    };
  }, [isParsing]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.[0]) {
      onSelect(files[0]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files?.[0]) {
      onSelect(files[0]);
    }
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>, isDragging: boolean) => {
    event.preventDefault();
    setIsDragging(isDragging);
  };

  const accept = FileParser.default.getImportableExtensions().join(',');
  const triggerFileInput = () => fileInputRef.current?.click();

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex h-full w-8/12 flex-col items-start gap-6 text-sm text-gray-700">
        <p>
          Import a single tabular or polygon dataset up to {getMaxFileSizeInMB()} (unzipped) at a time. Supported
          formats for import include CSV, JSON, GeoJSON, KML, and ShapeFiles.{' '}
          <button onClick={() => setIsModalVisible(true)} className="cursor-pointer underline underline-offset-2">
            Learn more
          </button>
        </p>

        <div
          className={classNames(
            'flex h-full min-h-[420px] w-full flex-col items-center justify-center gap-12 rounded-xl border border-dashed',
            {
              'border-primary-600 bg-gray-100': isDragging,
              'border-gray-300 bg-gray-50': !isDragging,
            }
          )}
          role="button"
          tabIndex={0}
          aria-label="File drop zone"
          onDrop={handleDrop}
          onDragOver={(e) => handleDrag(e, true)}
          onDragEnter={(e) => handleDrag(e, true)}
          onDragLeave={(e) => handleDrag(e, false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fileInputRef.current?.click();
            }
          }}
        >
          {isParsing ? (
            <>
              <Spinner />
              <span className="text-sm text-gray-600">Analyzing file. This might take a while.</span>
            </>
          ) : (
            <>
              <DragDropIcon />
              <div className="flex flex-col items-center gap-4">
                <p className="text-sm text-gray-700">
                  {isDragging ? 'Drop files here to upload' : 'Drag & drop files into this area or...'}
                </p>
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  accept={accept}
                  onChange={handleFileChange}
                  className="hidden"
                />
                {!isDragging && (
                  <Buttons.Secondary
                    className="text-primary-600 rounded-xl px-6"
                    onClick={triggerFileInput}
                    data-cy="browse-file-button"
                  >
                    Browse
                  </Buttons.Secondary>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {isModalVisible && <LearnMoreModal onModalClose={() => setIsModalVisible(false)} />}
    </div>
  );
};

export default FileUpload;
