import { Dropdowns, MUIcon, Spinner } from '@platform/shared/ui';
import { useDatasetPublishInfo } from '../../../../hooks/index';
import PublishDatasetModal from '../PublishDatasetModal';
import * as MenuTypes from './menu.types';

const usePublishOption = (
  datasetId: string,
  action: number | null,
  handleCloseRequest: () => void
): [Dropdowns.DropdownOption, JSX.Element | null] => {
  const { canBePublished, isPublishingInProcess, hasEverBeenPublished, unPublishedChangesCount } =
    useDatasetPublishInfo(datasetId);

  const getOptionName = () => {
    if (canBePublished === undefined) return 'Loading publishing info';
    if (isPublishingInProcess) return 'Publishing changes';
    if (canBePublished) {
      return `Publish ${hasEverBeenPublished ? 'changes' : 'dataset'}`;
    }
    return 'Published and up to date';
  };

  const getIcon = () => {
    if (isPublishingInProcess || canBePublished === undefined) {
      return (
        <div className="items-center">
          <Spinner width={20} height={20} />
        </div>
      );
    }
    const iconName = canBePublished ? 'published_with_changes' : 'check_circle';
    return <MUIcon name={iconName} />;
  };

  const getSuffixIcon = () => {
    if (isPublishingInProcess || !canBePublished || unPublishedChangesCount === undefined) return undefined;

    return (
      <div className="flex grow justify-end">
        <span className="rounded-full bg-rose-700 px-2 py-0.5 text-xs font-semibold text-white">
          {unPublishedChangesCount > 0 ? unPublishedChangesCount : '!'}
        </span>
      </div>
    );
  };

  const option = {
    value: MenuTypes.MenuOption.PUBLISH,
    name: getOptionName(),
    icon: getIcon(),
    suffixIcon: getSuffixIcon(),
    disabled: !canBePublished || isPublishingInProcess,
    additionalStyles: 'text-sm',
  };

  const modal =
    action === MenuTypes.MenuOption.PUBLISH ? (
      <PublishDatasetModal datasetId={datasetId} onCloseRequest={handleCloseRequest} />
    ) : null;

  return [option, modal];
};

export default usePublishOption;
