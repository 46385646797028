import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { STORY_DROPDOWN_OPTIONS } from '../../enums/dropdown';
import { useStory } from '../../hooks';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import { UpdatedAgo } from '../shared';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import CopyStoryModal from './CopyStoryModal';
import RenameStoryModal from './RenameStoryModal';

interface Props {
  story: PeTypes.Story;
}

const StoriesListItem: React.FC<Props> = ({ story }) => {
  const queryClient = useQueryClient();
  const { storyQuery } = useStory(story.id);
  const { activeWorkspace } = useWorkspaceContext();
  const [requestedAction, setRequestedAction] = useState<ProjectTypes.MenuActions | null>(null);

  const deleteStoryMutation = useMutation({
    mutationFn: async ({ storyId }: { storyId: string }) => peApi.softDeleteStory(storyId),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
      queryClient.invalidateQueries({ queryKey: ['recent-stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects', activeWorkspace.id] });
      await storyQuery.refetch();
    },
  });

  const onDeleteStory = () => {
    deleteStoryMutation.mutate({ storyId: story.id });
    setRequestedAction(null);
  };

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case ProjectTypes.MenuActions.DELETE:
        setRequestedAction(ProjectTypes.MenuActions.DELETE);
        break;
      case ProjectTypes.MenuActions.COPY:
        setRequestedAction(ProjectTypes.MenuActions.COPY);
        break;
      case ProjectTypes.MenuActions.RENAME:
        setRequestedAction(ProjectTypes.MenuActions.RENAME);
        break;
      default:
        return;
    }
  };

  const { id, title, slidesCount, editedAt } = story;

  const numberOfSlidesLabel = `${slidesCount} Slide${slidesCount === 1 ? '' : 's'}`;
  return (
    <>
      <Link
        to={generateLink('story', { workspaceId: activeWorkspace.id, storyId: id, mode: 'edit' })}
        className="flex select-none flex-wrap items-center justify-between border-b bg-white p-4 pl-10"
      >
        <MUIcon name="note_stack" className="mr-4 h-5 w-5 text-gray-500" aria-hidden="false" />
        <div className="flex-1 truncate font-semibold">{title}</div>
        <div className="flex-1 text-sm text-gray-400">{numberOfSlidesLabel}</div>
        {editedAt && <UpdatedAgo dateTime={editedAt} className="flex-1 text-sm text-gray-400" />}
        <div className="" onClick={(e) => e.preventDefault()}>
          <Dropdowns.Dropdown
            icon={<MUIcon name="more_horiz" className="text-[20px] text-gray-600" aria-hidden="false" />}
            iconClose={null}
            triggerClasses={'rounded-full border-gray-200 p-2'}
            options={STORY_DROPDOWN_OPTIONS}
            onSelect={onSelectDropdownOption}
            hAlignment="right"
          />
        </div>
      </Link>
      {requestedAction === ProjectTypes.MenuActions.DELETE && (
        <DeleteConfirmationModal
          title="Delete story"
          message={`Are you sure you want to delete this story? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={onDeleteStory}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.COPY && (
        <CopyStoryModal
          storyId={story.id}
          storyTitle={story.title}
          activeProjectId={story.projectId}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.RENAME && (
        <RenameStoryModal
          story={story}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
        />
      )}
    </>
  );
};

export default StoriesListItem;
