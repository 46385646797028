import { MetadataTypes, ProjectTypes } from '@platform/types';
import { LngLat, LngLatBounds, StyleSpecification } from 'maplibre-gl';

export type RegisteredMaps = ProjectTypes.MapObject[];
export type CompareType = 'single' | 'side' | 'side-locked' | 'swipe';
export type MapObjectClickHandler = ((mapObj: ProjectTypes.MapObject) => void) | null;

export type MapContextState = {
  type: CompareType;
  registeredMaps: RegisteredMaps;
};

export type MapContextSaveState = {
  type: CompareType;
  mapSpecs: Record<string, ProjectTypes.MapSpec>;
};

export type MapAPIs = {
  baseMaps: {
    get: (mapName: string) => Promise<StyleSpecification>;
  };
  mapContext: {
    get: (id: string) => Promise<ProjectTypes.MapContext>;
    update: (id: string, patch: ProjectTypes.PatchMapContext) => Promise<void>;
  };
  dataSourceLayers: {
    get: (surveyName: string, isUserSurvey: boolean) => Promise<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]>;
  };
};

export type MapPosition = {
  zoom: number;
  center: LngLat;
  mapBounds: LngLatBounds;
};
