import React from 'react';

interface Props {
  imgUrl: string;
  active: boolean;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const Compare: React.FC<Props> = ({ title, active, imgUrl, disabled, onClick }) => {
  return (
    <button
      disabled={disabled}
      className={`flex flex-col items-center gap-2 text-sm text-gray-900 ${disabled ? 'opacity-40' : 'cursor-pointer'}`}
      onClick={onClick}
    >
      <div
        style={{ backgroundImage: `url(${imgUrl})` }}
        className={`h-[44px] w-[44px] rounded-md bg-gray-200 bg-cover outline outline-offset-2 transition-all ${
          active ? 'outline-primary-400' : 'outline-transparent hover:outline-gray-100'
        }`}
      />
      <span>{title}</span>
    </button>
  );
};

export default Compare;
