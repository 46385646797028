import { ProjectTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';

interface Props {
  surveysApi: () => Promise<ProjectTypes.SurveyInfo[]>;
  activeWorkspaceId: number;
}

function useSurveys({ surveysApi, activeWorkspaceId }: Props, options?: UseQueryOptions<ProjectTypes.SurveyInfo[]>) {
  return useQuery<ProjectTypes.SurveyInfo[]>(['surveys', activeWorkspaceId], () => surveysApi(), options);
}

export default useSurveys;
