import { DatasetTypes, ProjectTypes } from '@platform/types';
import { FileParser, ValueFormatter } from '@platform/utils';

export const fileParserTypeToDataType = (dataType: FileParser.Types.DataType): DatasetTypes.DataType => {
  switch (dataType) {
    case 'number':
      return DatasetTypes.DataType.DOUBLE;
    case 'boolean':
      return DatasetTypes.DataType.BOOLEAN;
    case 'text':
      return DatasetTypes.DataType.TEXT;
    default:
      return DatasetTypes.DataType.TEXT;
  }
};

export const getMaxFileSizeInMB = () => {
  const maxSizeInBytes = parseInt(process.env.NX_IMPORT_FILE_SIZE_LIMIT_IN_BYTES as string, 10);
  const maxSizeInMB = Math.floor(maxSizeInBytes / 1024 / 1024);
  return `${ValueFormatter.format(maxSizeInMB, ProjectTypes.ValueFormat.FORMAT_NUMBER_NO_DECIMAL)} MB`;
};
