import { DatasetTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import classNames from 'classnames';
import React from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { Dropdowns } from '../index';
import { FilterHeader } from './index';
import InvalidRule from './InvalidFilterRule';
import { EQUAL_TO, IS_NOT_NULL, IS_NULL } from './options';

interface Props {
  rule: RuleType;
  title: string;
  type: string | DatasetTypes.DataType;
  onColumnChange: (rule: RuleType) => void;
  onFilterChange: (rule: RuleType, operator: string, value: unknown) => void;
  onRemoveRule: (rule: RuleType | RuleGroupType) => void;
}

const BooleanFilter: React.FC<Props> = ({ rule, title, type, onColumnChange, onFilterChange, onRemoveRule }) => {
  const value = rule.value;

  const operatorOptions = [EQUAL_TO, IS_NULL, IS_NOT_NULL];
  const currentOperator = operatorOptions.find((option) => option.value === rule.operator);
  const isNullishOperator = currentOperator === IS_NULL || currentOperator === IS_NOT_NULL;

  return (
    <div key={rule.id}>
      <div
        key={rule.id}
        className={classNames('flex flex-col gap-3 break-all rounded-lg border border-gray-200 bg-gray-100 p-3', {
          'rounded-bl-none rounded-br-none': !Helpers.checkIfValidRule(rule, null),
        })}
      >
        <FilterHeader
          handleColumnChange={() => onColumnChange(rule)}
          handleRemoveRule={() => onRemoveRule(rule)}
          title={title}
          type={type}
        />

        <div className="flex justify-between gap-2">
          <div className="flex grow basis-1/3">
            <Dropdowns.Dropdown
              customLabel={currentOperator?.name}
              onSelect={(opt) => onFilterChange(rule, opt ? (opt?.value as string) : '', value)}
              options={operatorOptions}
              triggerWrapperClasses="w-full"
              triggerClasses="bg-white w-full flex ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200"
            />
          </div>
          {!isNullishOperator && (
            <div className="flex basis-2/3">
              <Dropdowns.Dropdown
                placeholder="Select an option"
                value={value}
                options={[
                  { value: 1, name: 'True' },
                  {
                    value: 0,
                    name: 'False',
                  },
                ]}
                onSelect={(opt) => onFilterChange(rule, currentOperator ? currentOperator?.value : '', opt.value)}
                triggerWrapperClasses="w-full"
                triggerClasses="bg-white w-full flex ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200"
              />
            </div>
          )}
        </div>
      </div>
      {!Helpers.checkIfValidRule(rule, null) && <InvalidRule />}
    </div>
  );
};

export default BooleanFilter;
