import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.92381 15.8476C12.3 15.8476 15.8476 12.3 15.8476 7.92381C15.8476 3.54761 12.3 0 7.92381 0C3.54761 0 0 3.54761 0 7.92381C0 12.3 3.54761 15.8476 7.92381 15.8476Z"
        fill="#4F46E5"
      />
      <path
        d="M32 15.8476C36.3762 15.8476 39.9238 12.3 39.9238 7.92381C39.9238 3.54761 36.3762 0 32 0C27.6238 0 24.0762 3.54761 24.0762 7.92381C24.0762 12.3 27.6238 15.8476 32 15.8476Z"
        fill="#C7D2FE"
      />
      <path
        d="M7.92381 39.9238C12.3 39.9238 15.8476 36.3762 15.8476 32C15.8476 27.6238 12.3 24.0762 7.92381 24.0762C3.54761 24.0762 0 27.6238 0 32C0 36.3762 3.54761 39.9238 7.92381 39.9238Z"
        fill="#4F46E5"
      />
      <path
        d="M32 39.9238C36.3762 39.9238 39.9238 36.3762 39.9238 32C39.9238 27.6238 36.3762 24.0762 32 24.0762C27.6238 24.0762 24.0762 27.6238 24.0762 32C24.0762 36.3762 27.6238 39.9238 32 39.9238Z"
        fill="#374151"
      />
      <path
        d="M56.0762 39.9238C60.4524 39.9238 64 36.3762 64 32C64 27.6238 60.4524 24.0762 56.0762 24.0762C51.7 24.0762 48.1523 27.6238 48.1523 32C48.1523 36.3762 51.7 39.9238 56.0762 39.9238Z"
        fill="#C7D2FE"
      />
      <path
        d="M39.9238 56.0762C39.9238 60.3428 36.2667 64 32 64C27.7333 64 24.0762 60.3428 24.0762 56.0762C24.0762 51.8095 27.7333 48.1523 32 48.1523C36.2667 48.1523 39.9238 51.8095 39.9238 56.0762Z"
        fill="#374151"
      />
      <path
        d="M64 56.0762C64 60.3428 60.3428 64 56.0762 64C51.8095 64 48.1523 60.3428 48.1523 56.0762C48.1523 51.8095 51.8095 48.1523 56.0762 48.1523C60.3428 48.1523 64 51.8095 64 56.0762Z"
        fill="#6366F1"
      />
    </svg>
  );
};

export default Logo;
