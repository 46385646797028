import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from '../contexts/SessionContext';
import { generateLink } from '../LinkGenerator';

const WorkspaceDefault: React.FC = () => {
  const { workspaces } = useSessionContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!workspaces.length) return;
    navigate(generateLink('workspace', { workspaceId: workspaces[0].id }));
  }, [navigate, workspaces]);

  return null;
};
export default WorkspaceDefault;
