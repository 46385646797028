import { Buttons, MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import { LngLatLike, MapGeoJSONFeature } from 'maplibre-gl';
import React, { useEffect, useState } from 'react';
import WorkingLayerTooltipWrapper from './WorkingLayerTooltipWrapper';

export const REMOVE_TOOLTIPS_EVENT = 'REMOVE_TOOLTIPS_EVENT';

export interface FeaturesWithPoint {
  latLong: LngLatLike | null;
  features: Record<string, MapGeoJSONFeature[]>;
}

const FEATURES_WITH_POINT_INITIAL_STATE = {
  latLong: null,
  features: {},
};

const useWorkingLayerTooltips = (mapObj: ProjectTypes.MapObject, inEditMode: boolean) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [allFeatures, setAllFeatures] = useState<FeaturesWithPoint>(FEATURES_WITH_POINT_INITIAL_STATE);
  const [prevFeatures, setPrevFeatures] = useState<MapGeoJSONFeature[]>([]);

  useEffect(() => {
    const customHandler = () => setAllFeatures(FEATURES_WITH_POINT_INITIAL_STATE);
    mapObj.ref?.on(REMOVE_TOOLTIPS_EVENT, customHandler);
    return () => {
      mapObj.ref?.off(REMOVE_TOOLTIPS_EVENT, customHandler);
    };
  }, [mapObj.ref]);

  useEffect(() => {
    setAllFeatures(FEATURES_WITH_POINT_INITIAL_STATE);
  }, [mapObj.styleSpec.workingLayers.length]);

  useEffect(() => {
    if (!mapObj.ref) return;

    // clear
    prevFeatures
      .filter((f) => f.id)
      .forEach((f) => {
        mapObj.ref?.removeFeatureState({ source: f.source, sourceLayer: f.sourceLayer, id: f.id }, 'highlight');
      });

    // apply new
    const flattened = Object.values(allFeatures.features).flat();
    const activeFeature = flattened[activeIndex];
    if (activeFeature) {
      mapObj.ref?.setFeatureState(
        {
          source: activeFeature.source,
          sourceLayer: activeFeature.sourceLayer,
          id: activeFeature.id,
        },
        { ...(activeFeature.state ?? {}), highlight: true }
      );
    }

    setPrevFeatures(flattened);
  }, [mapObj.ref, activeIndex, allFeatures]);

  const handleFeaturesUnderMouse = (
    workingLayerId: string,
    features: MapGeoJSONFeature[],
    latLong: LngLatLike | null
  ) => {
    setActiveIndex(0);
    setAllFeatures((p) => ({ latLong: latLong, features: { ...p.features, [workingLayerId]: features } }));
  };

  const allFeaturesFlat = Object.values(allFeatures.features).flat();
  let featureNavi: React.ReactNode;

  if (allFeaturesFlat.length > 1) {
    featureNavi = (
      <div className="flex items-center justify-center gap-2 text-sm">
        <Buttons.Secondary
          icon={<MUIcon name="chevron_left" />}
          onClick={() => setActiveIndex((p) => Math.max(p - 1, 0))}
          className="h-[28px] w-[28px] rounded-full bg-gray-200 text-gray-700 ring-0 hover:bg-gray-100"
          disabled={activeIndex === 0}
        />
        <span className="flex-shrink-0 px-2">{`${activeIndex + 1} of ${allFeaturesFlat.length}`}</span>
        <Buttons.Secondary
          icon={<MUIcon name="chevron_right" />}
          onClick={() => setActiveIndex((p) => Math.min(p + 1, allFeaturesFlat.length - 1))}
          className="h-[28px] w-[28px] rounded-full bg-gray-200 text-gray-700 ring-0 hover:bg-gray-100"
          disabled={activeIndex === allFeaturesFlat.length - 1}
        />
      </div>
    );
  }

  return mapObj.styleSpec.workingLayers
    .filter((x) => x.tooltip?.enabled)
    .map((x) => {
      const handleTooltipClose = () => setAllFeatures(FEATURES_WITH_POINT_INITIAL_STATE);
      let emptyPlaceholder = null;
      if (inEditMode && !x.tooltip?.title && !x.tooltip?.content.length) {
        emptyPlaceholder = (
          <p className="p-3 text-sm text-gray-600">Add a title and select variables to display in the popup.</p>
        );
      }

      return (
        <WorkingLayerTooltipWrapper
          key={x.id}
          map={mapObj.ref}
          workingLayer={x}
          onFeaturesUnderMouse={handleFeaturesUnderMouse}
          features={allFeatures.features[x.id] ?? []}
          latLong={allFeatures.latLong}
          activeFeature={allFeaturesFlat[activeIndex]}
          featureNavi={featureNavi}
          onClose={handleTooltipClose}
          emptyPlaceholder={emptyPlaceholder}
        />
      );
    });
};

export default useWorkingLayerTooltips;
