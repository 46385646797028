import * as Sentry from '@sentry/react';
import * as ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import { SessionContextProvider } from './app/contexts';
import UserPilotIntegration from './app/UserPilotIntegration';

// initialize Sentry
Sentry.init({
  enabled: process.env.NX_APP_ENV === 'production',
  environment: process.env.NX_APP_ENV,
  dsn: 'https://e47b5e6dafd0ee0391d67a334fbc280c@o107988.ingest.sentry.io/4505788511551488',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', process.env.NX_PE_API as string],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NX_APP_ENV === 'production' ? 0.5 : 1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryDelay: 0,
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
      //  useErrorBoundary: true,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col">
        <SessionContextProvider>
          <App />
          <UserPilotIntegration />
        </SessionContextProvider>
      </div>
    </BrowserRouter>
  </QueryClientProvider>
  // </StrictMode>
);
