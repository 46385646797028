import { MetadataTypes } from '@platform/types';
const { VariableType } = MetadataTypes;

export const canUseBubbles = (v: MetadataTypes.Variable, t: MetadataTypes.Table): boolean => {
  const parentVariable = getParentVariable(v, t);
  if (
    v.varType === VariableType.NONE &&
    parentVariable &&
    parentVariable.varType === VariableType.COUNT
    // && this.defaultFilterSetName && this.defaultFilterSetName !== '' // TODO:
  ) {
    return true;
  }
  return canUseDotDensity(v);
};

export const canUseDotDensity = (v: MetadataTypes.Variable): boolean => {
  return ![
    VariableType.MEDIAN,
    VariableType.PERCENT,
    VariableType.AVERAGE,
    VariableType.STANDARD_ERROR,
    VariableType.NONE,
    VariableType.QUINTILE,
    VariableType.COMPUTED_INDEX,
    VariableType.RATE,
  ].includes(v.varType);
};

export const getParentVariable = (
  v: MetadataTypes.Variable,
  t: MetadataTypes.Table
): MetadataTypes.Variable | undefined => {
  if (v.indent === 0) return undefined;

  const variables = t.variables;

  const variableIndex = variables.findIndex((tv) => tv.uuid === v.uuid);

  for (let i = variableIndex; i >= 0; i -= 1) {
    if (variables[i].indent === 0) return variables[i];
  }
  return undefined;
};

export const isVariableCountOnly = (v: MetadataTypes.Variable, t: MetadataTypes.Table): boolean => {
  return (v.varType === VariableType.COUNT && t.variables[0]?.uuid === v.uuid) || v.indent === 0;
};
