import { Menu, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSessionContext } from '../../contexts/SessionContext';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink, matchRoute } from '../../LinkGenerator';
import WorkspaceOptions from './WorkspaceOptions';
import WorkspacePicker from './WorkspacePicker';

enum menuOptions {
  OPTIONS,
  PICKER,
}

const WorkspaceMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { workspaces } = useSessionContext();
  const { activeWorkspace } = useWorkspaceContext();
  const [activeMenu, setActiveMenu] = useState<menuOptions>(menuOptions.OPTIONS);

  const handleWorkspaceChange = (workspace: PeTypes.Workspace) => {
    // if the user is browsing projects or the catalog while switching workspaces we are going to keep the user there
    let navigateTo;
    const matchingRoute = matchRoute(location.pathname) ?? '';

    if (['projects', 'project', 'allProjects'].includes(matchingRoute)) {
      navigateTo = generateLink('allProjects', { workspaceId: workspace.id });
    } else if (['datasets'].includes(matchingRoute)) {
      navigateTo = generateLink('datasets', { workspaceId: workspace.id });
    } else {
      // the default navigation is to the workspace root
      navigateTo = generateLink('workspace', { workspaceId: workspace.id });
    }

    navigate(navigateTo);
  };

  const active: JSX.Element = (
    <div
      className="flex flex h-[48px] w-[206px] items-center justify-between gap-3 rounded-[8px] border border-gray-300 px-3 font-semibold"
      data-cy={'header-workspace-menu'}
    >
      <img
        className="w-[32px] rounded-[8px] border border-gray-200"
        src={`${activeWorkspace.imageURL}?${activeWorkspace.updatedAt}`}
        alt=""
      ></img>
      <span className="flex-grow truncate text-sm">{activeWorkspace.name}</span>
      <MUIcon name="keyboard_arrow_down" className="shrink-0 text-gray-600" />
    </div>
  );

  return (
    <Menu
      visible={isOpen}
      onToggleVisibility={setIsOpen}
      trigger={active}
      hAlignment="left"
      menuClasses="py-0"
      vAlignment="top"
    >
      <div className="flex w-[320px] flex-col">
        {(() => {
          switch (activeMenu) {
            case menuOptions.OPTIONS:
              return (
                <WorkspaceOptions
                  activeWorkspace={activeWorkspace}
                  onSwitchRequested={() => setActiveMenu(menuOptions.PICKER)}
                  menuClose={() => setIsOpen(false)}
                  isOnlyWorkspace={workspaces.length === 1}
                />
              );
            case menuOptions.PICKER:
              return (
                <WorkspacePicker
                  workspaces={workspaces}
                  onChange={handleWorkspaceChange}
                  activeWorkspace={activeWorkspace}
                  onSwitchRequested={() => setActiveMenu(menuOptions.OPTIONS)}
                  menuClose={() => setIsOpen(false)}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </Menu>
  );
};

export default WorkspaceMenu;
