import * as GeoJSON from 'geojson';
import React from 'react';
import { SAMPLE_SIZE } from '../../ImportWizard';
import MaptilerMap from './MaptilerGeoJsonMap';

interface Props {
  featureCollection: GeoJSON.FeatureCollection | undefined;
}

const PreviewMap: React.FC<Props> = ({ featureCollection }) => {
  if (!featureCollection) {
    return (
      <div className="flex h-[328px] w-full items-center justify-center rounded-md bg-gray-100">
        <p className="text-sm text-gray-600">
          Once you`ve selected the lat/long columns or geocode, a sample preview of your data will show up here.
        </p>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="h-[328px] w-full">
        <MaptilerMap
          note={`Showing preview for ${SAMPLE_SIZE} sample rows`}
          featureCollection={featureCollection}
          baseMapId="streets-v2"
        />
      </div>
    </div>
  );
};

export default PreviewMap;
