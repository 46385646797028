import React from 'react';
import { useParams } from 'react-router-dom';
import PublicProfile from '../components/Profiles/Public/PublicProfile';
import ErrorPage from '../components/shared/ErrorPage';

const PublicProfilePage: React.FC = () => {
  const { profileId } = useParams<{ profileId: string }>();
  if (!profileId) return <ErrorPage />;

  return <PublicProfile profileId={profileId} />;
};

export default PublicProfilePage;
