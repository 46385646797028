import { Modal } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../pe.api';

type Inputs = {
  title: string;
};

interface Props {
  onCloseRequest: () => void;
  story: PeTypes.Story | PeTypes.RecentStory;
  isRecentStory?: boolean;
}

const RenameStoryModal: React.FC<Props> = ({ onCloseRequest, story, isRecentStory = false }) => {
  const queryClient = useQueryClient();

  const renameDatasetMutation = useMutation({
    mutationFn: async (payload: string) => peApi.renameStory(story.id, payload),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [isRecentStory ? 'recent-stories' : 'story', story?.id] });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
      queryClient.invalidateQueries({ queryKey: ['story', story?.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-stories'] });
      onCloseRequest?.();
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { title: story.title } });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!data.title) return;

    const { title } = data;
    renameDatasetMutation.mutate(title);
  };

  return (
    <Modal
      title="Rename story"
      okLabel="Save"
      className="w-[512px]"
      okDisabled={renameDatasetMutation.isLoading}
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
          Name
        </label>
        <input
          id="title"
          autoFocus
          data-cy="rename-story-title-input"
          className="focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none"
          placeholder="e.g. Story Name"
          required
          {...register('title', { required: 'Story name is required' })}
        />
        {errors.title && <span className="text-red-400">{errors.title.message}</span>}
      </form>
    </Modal>
  );
};

export default RenameStoryModal;
