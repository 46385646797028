import React from 'react';
import BaseError from './BaseError';

interface Props {
  error: string;
}

const ImportError: React.FC<Props> = ({ error }) => (
  <BaseError>
    <div className="flex gap-2">
      <span className="font-semibold">Failed to import.</span>
      {error}
    </div>
  </BaseError>
);

export default ImportError;
