export type OperatorType = {
  name: string;
  value: string;
};

export const GREATER_THAN: OperatorType = { name: 'is greater than', value: '>' };
export const LESS_THAN: OperatorType = { name: 'is less than', value: '<' };
export const EQUAL_TO: OperatorType = { name: 'is equal to', value: '=' };
export const NOT_EQUAL_TO: OperatorType = { name: 'is not equal to', value: '!=' };
export const GREATER_OR_EQUAL: OperatorType = { name: 'is greater than or equal to', value: '>=' };
export const LESS_OR_EQUAL: OperatorType = { name: 'is less than or equal to', value: '<=' };
export const IS_BETWEEN: OperatorType = { name: 'is between', value: 'between' };
export const CONTAINS: OperatorType = { name: 'contains', value: 'contains' };
export const STARTS_WITH: OperatorType = { name: 'starts with', value: 'beginsWith' };
export const ENDS_WITH: OperatorType = { name: 'ends with', value: 'endsWith' };
export const IS_NULL: OperatorType = { name: 'is null', value: 'null' };
export const IS_NOT_NULL: OperatorType = { name: 'is not null', value: 'notNull' };
