import { Hooks, MapSpecModifiers, Utils } from '@platform/maplibre';
import { ProjectTypes } from '@platform/types';
import { MapGeoJSONFeature } from 'maplibre-gl';
import { useMemo } from 'react';

const { useFeatureSelection } = Hooks;

const SELECTION_LABEL_PROP = 'Geo_QName'; // TODO: *assumption alert* label is Geo_QName!
const SELECTION_KEY_PROP = 'Geo_FIPS'; // TODO: *assumption alert* key is Geo_FIPS!

const useGeoSelection = (mapObj: ProjectTypes.MapObject, enabled = true) => {
  const { styleSpec } = mapObj;
  const { geoFeatureSelection = [], dataLayer } = styleSpec;
  const { preferredSummaryLevelId } = dataLayer;

  const seDataMapLayerId = Utils.getDataLayerFingerprint(dataLayer);
  const seFilteredOutDataLayerId = Utils.getFilteredOutDataLayerId(dataLayer);

  const handleMapClickSelection = useMemo(
    () => async (featuresInSelectEvent: MapGeoJSONFeature[]) => {
      if (!enabled) return;

      const newSelection = [...geoFeatureSelection];

      featuresInSelectEvent
        .filter((f) => f.properties[SELECTION_KEY_PROP])
        .forEach((f) => {
          const existingIndex = newSelection.findIndex((x) => x.fips === getFeaturePropFn(f));

          if (existingIndex > -1) {
            // feature under mouse is already in selection, remove it
            newSelection.splice(existingIndex, 1);
          } else {
            newSelection.push({
              ...getItemFromFeature(f),
              summaryLevelId: preferredSummaryLevelId,
            });
          }
        });

      const updated = MapSpecModifiers.updateGeoSelection(styleSpec, newSelection);
      mapObj.updateMapSpec(updated);
    },
    [mapObj, geoFeatureSelection, enabled]
  );

  useFeatureSelection(mapObj.ref, [seDataMapLayerId, seFilteredOutDataLayerId], handleMapClickSelection);
};

export default useGeoSelection;

const getFeaturePropByValue = (propertyValue: string) => (f: MapGeoJSONFeature) => f.properties[propertyValue];
const getFeaturePropFn = getFeaturePropByValue(SELECTION_KEY_PROP);
const getItemFromFeature = (f: MapGeoJSONFeature) => ({
  fips: f.properties[SELECTION_KEY_PROP],
  label: f.properties[SELECTION_LABEL_PROP],
});
