import { MapComponents } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';

const { ZoomControls } = MapComponents;

export interface Props {
  mapObj: ProjectTypes.MapObject;
}

const Zoom: React.FC<Props> = ({ mapObj }) => {
  const { styleSpec } = mapObj;

  const handleZoomIn = () => mapObj.ref?.zoomIn();
  const handleZoomOut = () => mapObj.ref?.zoomOut();

  return <ZoomControls zoom={styleSpec.zoom} onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />;
};

export default Zoom;
