import { ButtonGroup, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { generateLink } from '../../../LinkGenerator';
import DatasetInfo from './DatasetInfo';
import { ModeType } from './index';

interface Props {
  dataset: PeTypes.Dataset;
  mode: ModeType;
  actions?: React.ReactNode;
}

const VIEW_OPTIONS = [
  {
    value: 'table',
    label: 'Table',
  },
  {
    value: 'map',
    label: 'Map',
  },
];

const DatasetHeader: React.FC<Props> = ({ actions, dataset, mode }) => {
  const navigate = useNavigate();
  const isTable = dataset.type === ProjectTypes.LayerType.TABLE;

  const handleViewTypeChange = (v: unknown) => {
    const routeKey = v === 'table' ? 'dataset' : 'datasetMap';
    navigate(generateLink(routeKey, { workspaceId: dataset.ownerId, datasetId: dataset.id }));
  };

  return (
    <div className="flex shrink-0 items-center gap-8 py-4 px-6">
      <Link to={generateLink('datasets', { workspaceId: dataset.ownerId })}>
        <MUIcon name="arrow_back" className="m-0 h-5 w-5" />
      </Link>
      <div className="min-w-0 flex-grow">
        <DatasetInfo dataset={dataset} />
      </div>
      <div className="flex gap-3">
        {!isTable && <ButtonGroup options={VIEW_OPTIONS} onChange={handleViewTypeChange} value={mode} />}
        <div className="w-6" />
        {actions}
      </div>
    </div>
  );
};

export default DatasetHeader;
