import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { POINT_COLORS } from '../../../enums/pointColors';

interface Props {
  workingLayerId: string;
  value: string | undefined;
  onChange: (color: string, workingLayerId: string) => void;
}

const WorkingLayerColorPicker: React.FC<Props> = ({ onChange, value, workingLayerId }) => {
  const changeWorkingLayerColor = (color: string) => onChange(color, workingLayerId);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex">
        <div className="flex items-center justify-between gap-3 text-gray-600">
          <MUIcon name="colors" />
          <span className="text-sm">Color</span>
        </div>
      </div>
      <div className="flex w-full flex-wrap gap-1.5 py-1">
        {POINT_COLORS.map((color) => (
          <div
            key={color}
            className={`mt-1 h-[22px] w-[22px] cursor-pointer rounded-[999px] ring-gray-700 hover:ring-2 ${
              value === color ? 'ring-2 ring-offset-2' : 'ring-0'
            }`}
            style={{ backgroundColor: color }}
            onClick={() => changeWorkingLayerColor(color)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default WorkingLayerColorPicker;
