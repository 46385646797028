import { Buttons } from '@platform/shared/ui';
import React from 'react';

interface Props {
  onClick: () => void;
}

const NoSearchResults: React.FC<Props> = ({ onClick }) => (
  <div className="flex min-h-[304px] w-full flex-col items-center justify-center gap-8 py-8">
    <p className="text-gray-500">No matching results. Try using different keywords or checking for typos.</p>
    <Buttons.Secondary className="text-primary-600" onClick={onClick}>
      Clear your filters and try again
    </Buttons.Secondary>
  </div>
);

export default NoSearchResults;
