import { useSystemFilterSets, useVariableMetadata } from '@platform/shared/hooks';
import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useEffect, useMemo, useState } from 'react';
import FilterVariableInfo from './FilterVariableInfo';

type OperatorType = {
  name: string;
  value: string;
};

export const GREATER_THAN: OperatorType = { name: 'is greater than', value: '>' };
export const LESS_THAN: OperatorType = { name: 'is less than', value: '<' };
export const EQUAL_TO: OperatorType = { name: 'is equal to', value: '==' };
export const NOT_EQUAL_TO: OperatorType = { name: 'is not equal to', value: '!=' };
export const GREATER_OR_EQUAL: OperatorType = { name: 'is greater than or equal to', value: '>=' };
export const LESS_OR_EQUAL: OperatorType = { name: 'is less than or equal to', value: '<=' };

interface Props {
  filter: ProjectTypes.DataFilter;
  onVariableChangeRequest: (filter: ProjectTypes.DataFilter) => void;
  onUpdateFilter: (updatedFilter: ProjectTypes.DataFilter) => void;
  onRemoveFilter: (filterToBeRemoved: ProjectTypes.DataFilter) => void;
  disabled?: boolean;
}

const AreaFilter: React.FC<Props> = ({ filter, onUpdateFilter, onRemoveFilter, disabled, onVariableChangeRequest }) => {
  const { get: getSystemFilterSets } = useSystemFilterSets();
  const [inputValue, setInputValue] = useState<string | number>(filter.value);

  useEffect(() => {
    setInputValue(filter.value);
  }, [filter.value]);

  const { datasetAbbrev, surveyName, tableGuid, variableGuid } = filter.variable;
  const variableMetadataQuery = useVariableMetadata({
    surveyName,
    variableUuid: variableGuid,
    tableUuid: tableGuid,
    datasetAbbreviation: datasetAbbrev,
  });

  const [valueOptions, setValueOptions] = useState<Dropdowns.DropdownOption[] | undefined>(undefined);

  useEffect(() => {
    if (!variableMetadataQuery.variable) return;
    if (filter.type === ProjectTypes.DataFilterType.COUNT) return;
    if (filter.type === ProjectTypes.DataFilterType.PERCENTAGE) return;

    const prepareValueOptions = async () => {
      try {
        const filterSets = await getSystemFilterSets(surveyName, datasetAbbrev);
        const filterSet = filterSets.find((x) => x.name === variableMetadataQuery.variable?.defaultFilterSetName)
          ?.filterSets[0];

        if (!filterSet) {
          console.warn('No filter set available. Exiting....');
          return;
        }

        const options = filterSet.filters
          .filter((x) => x.label != null)
          .map((f) => ({ name: f.label as string, value: f.valueNum ?? (f.valueStr as string) }));

        setValueOptions(options);
      } catch (e) {
        console.warn(e);
      }
    };
    prepareValueOptions();
  }, [filter.variable, variableMetadataQuery.variable]);

  const operatorDropdownOptions = useMemo(() => {
    if ([ProjectTypes.DataFilterType.STRING, ProjectTypes.DataFilterType.ENUM].includes(filter.type)) {
      return [EQUAL_TO, NOT_EQUAL_TO];
    }
    return [GREATER_THAN, LESS_THAN, EQUAL_TO, NOT_EQUAL_TO, GREATER_OR_EQUAL, LESS_OR_EQUAL];
  }, [filter.type]);

  const filterVariable = filter.variable;

  const handleSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    const updatedFilter = { ...filter };
    updatedFilter.operator = opt.value as string;
    onUpdateFilter(updatedFilter);
  };

  const handleSelectValueOption = (opt: Dropdowns.DropdownOption) => {
    const value = opt.value;
    handleValueUpdate(value);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value;
    if (event.target.value.trim().length) {
      value = event.target.value.trim();
    } else {
      value = '';
    }
    setInputValue(value);
  };

  const changeCalculationType = (dataType: ProjectTypes.DataFilterType) =>
    onUpdateFilter({ ...filter, type: dataType });

  const handleBlur = () => handleValueUpdate(inputValue);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    handleValueUpdate(inputValue);
  };

  const handleValueUpdate = (value: unknown) => {
    const updatedFilter = { ...filter };

    if (value != null) {
      const inputString = String(value);
      updatedFilter.value = !isNaN(parseInt(inputString, 10)) ? parseFloat(inputString) : inputString;
    } else {
      updatedFilter.value = '';
    }

    onUpdateFilter(updatedFilter);
  };

  const hasFilterValue = filter.value != null && filter.value !== '';

  return (
    <div>
      <div
        className={`flex w-[405px] grow items-center justify-center gap-4 rounded-lg bg-gray-100 py-3 px-2 ${
          hasFilterValue ? '' : 'rounded-bl-none rounded-br-none'
        }`}
      >
        <div className={`flex grow flex-col gap-4  ${disabled ? 'pointer-events-none opacity-40' : ''} `}>
          <div className="w-full">
            <div className="flex cursor-pointer items-start justify-between gap-6 px-2">
              <button
                className="border-none bg-transparent text-left font-normal shadow-none ring-0 hover:bg-transparent"
                onClick={() => onVariableChangeRequest(filter)}
              >
                <FilterVariableInfo def={filter.variable} classNames={'text-sm text-gray-700'} />
              </button>
              <button
                className="flex items-center justify-center rounded-lg p-1 hover:bg-gray-200"
                onClick={() => onRemoveFilter(filter)}
              >
                <MUIcon name="close" className="shrink-0 cursor-pointer text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex gap-2 px-2">
            <Dropdowns.Dropdown
              hAlignment="left"
              customLabel={operatorDropdownOptions.find((option) => option.value === filter.operator)?.name}
              onSelect={handleSelectDropdownOption}
              options={operatorDropdownOptions}
              triggerClasses="bg-white flex ring-0 gap-1 text-gray-700 font-normal font-semibold rounded-lg w-[150px] h-[44px]"
            />
            {(filter.type === ProjectTypes.DataFilterType.COUNT ||
              filter.type === ProjectTypes.DataFilterType.PERCENTAGE) && (
              <div className="flex rounded-lg bg-white shadow-sm">
                <input
                  type="number"
                  onChange={onInputChange}
                  onBlur={handleBlur}
                  autoFocus
                  onKeyDown={handleKeyPress}
                  className="h-full w-full rounded-lg px-3 text-xs focus:outline-none"
                  value={inputValue}
                  placeholder="Value: "
                />
                {filterVariable.universeVariableUuid && (
                  <div className="flex cursor-pointer items-center gap-1 px-2">
                    <button
                      className={`text-primary-600 flex h-[24px] w-[24px] items-center justify-center rounded-md text-sm ring-0 ${
                        filter.type === ProjectTypes.DataFilterType.COUNT
                          ? 'bg-primary-100 hover:bg-primary-200 font-semibold'
                          : ''
                      }`}
                      onClick={() => changeCalculationType(ProjectTypes.DataFilterType.COUNT)}
                    >
                      #
                    </button>
                    <button
                      className={`text-primary-600 flex h-[24px] w-[24px] items-center justify-center rounded-md text-sm ring-0 ${
                        filter.type === ProjectTypes.DataFilterType.PERCENTAGE
                          ? 'bg-primary-100 hover:bg-primary-200 font-bold'
                          : ''
                      } `}
                      onClick={() => changeCalculationType(ProjectTypes.DataFilterType.PERCENTAGE)}
                    >
                      %
                    </button>
                  </div>
                )}
              </div>
            )}
            {valueOptions &&
              (filter.type === ProjectTypes.DataFilterType.ENUM ||
                filter.type === ProjectTypes.DataFilterType.STRING) && (
                <div className="grow">
                  <Dropdowns.Dropdown
                    hAlignment="left"
                    customLabel={filter.value || 'Choose variable'}
                    onSelect={handleSelectValueOption}
                    options={valueOptions}
                    triggerClasses="bg-white w-full rounded-none ring-0 gap-3 text-gray-600 font-normal rounded-lg h-[44px]"
                  />
                </div>
              )}
          </div>
          {filter.type === ProjectTypes.DataFilterType.PERCENTAGE && (
            <div className="flex gap-1 px-2 text-sm text-gray-700">
              <span>of</span>
              <FilterVariableInfo def={filter.variable} classNames={'text-sm text-gray-700'} universeOnly={true} />
            </div>
          )}
        </div>
      </div>
      {!hasFilterValue && (
        <div className="bg-primary-50 flex rounded-br-lg rounded-bl-lg py-3 px-4 text-xs text-gray-700">
          <span>Enter a value and press enter for the criterion to apply</span>
        </div>
      )}
    </div>
  );
};

export default AreaFilter;
