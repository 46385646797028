import { Hooks, MapsContext } from '@platform/maplibre';
import { PanelHeader } from '@platform/shared/ui';
import React from 'react';
import ActiveMapSwitcher from '../Map/ActiveMapSwitcher';
import GeoSelection from '../Map/GeoSelection';
import { useMapSidebarContext } from './MapSideBarContext';

const { useMapObjectClick } = Hooks;
const { useMapsContext } = MapsContext;

const Search: React.FC = () => {
  const { activeMap, state, changeActiveMap, changeUseMapHighlight } = useMapsContext();
  const { changeActiveView } = useMapSidebarContext();

  useMapObjectClick((mapObj) => {
    changeActiveMap(mapObj.id);
  });

  // all maps are available to switch to
  const mapSwitcherOptions = state.registeredMaps.map((x) => ({ mapId: x.id }));

  return (
    <div className="flex h-full w-[460px] flex-col">
      <PanelHeader title="Search" onClose={() => changeActiveView(undefined)}>
        <ActiveMapSwitcher
          activeMapId={activeMap?.id}
          onChange={changeActiveMap}
          options={mapSwitcherOptions}
          onToggleHighlight={changeUseMapHighlight}
        />
      </PanelHeader>
      <div className="flex h-full flex-col overflow-y-auto px-6 py-3">
        {activeMap && <GeoSelection mapObj={activeMap} />}
      </div>
    </div>
  );
};
export default Search;
