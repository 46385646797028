import { MUIcon, SnackbarActionButton } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import pluralize from 'pluralize';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { InfiniteData, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { Loader } from '../components/shared';
import { downloadDataset } from '../helpers/helpers';
import { useWorkspace } from '../hooks';
import useWorkspaceSubscription from '../hooks/useWorkspaceSubscription';
import { generateLink } from '../LinkGenerator';
import NoWorkspacePage from '../pages/NoWorkspace.page';
import SubscriptionExpired from '../pages/SubscriptionExpired.page';
import { setWorkspace } from '../pe.api';
import { useSessionContext } from './SessionContext';

export const WORKSPACE_STORAGE_ITEM_ID = 'wid';

interface ContextState {
  activeWorkspace: PeTypes.Workspace;
}

const DEFAULT_VALUE: ContextState = {
  activeWorkspace: {
    id: -1,
    description: '',
    availableSurveys: [],
    name: 'dummy',
    imageURL: '',
    isAdmin: false,
    updatedAt: '',
  },
};

const WorkspaceContext = React.createContext<ContextState>(DEFAULT_VALUE);

interface Props {
  workspaceId: number;
  children: React.ReactNode;
}

export const WorkspaceContextProvider: React.FC<Props> = ({ workspaceId, children }) => {
  const workspaceQuery = useWorkspace(workspaceId);
  const { notify, realtimeSocket, user } = useSessionContext();
  const queryClient = useQueryClient();
  const workspaceSubscriptionQuery = useWorkspaceSubscription(workspaceId);

  const workspaceIdRef = useRef<number>();
  workspaceIdRef.current = workspaceId;

  useEffect(() => {
    const eventsListener = async (event: PeTypes.UserEvent<object>) => {
      // on-time events (not persisted in db)
      queryClient.invalidateQueries({ queryKey: ['notification-count'] });

      const { type } = event;
      switch (type) {
        case PeTypes.NotificationType.CELL_UPDATE:
          {
            const { metadata } = event as PeTypes.UserEvent<PeTypes.CellUpdateEvent>;
            const { status, datasetId } = metadata;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              queryClient.invalidateQueries({ queryKey: ['dataset-data', datasetId] });
              queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });
            }
          }
          break;
        case PeTypes.NotificationType.DUPLICATE_COLUMN:
        case PeTypes.NotificationType.CREATE_COLUMN:
          {
            const { metadata, createdBy } = event as PeTypes.UserEvent<PeTypes.NewColumnEvent>;
            const { status, newColumnTitle, datasetId } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset-columns', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });

              if (notifyUser) {
                notify({
                  hideAfterSec: 10,
                  content: `Column "${newColumnTitle}" created at the end of the table.`,
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Failed to create a column.</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.DELETE_COLUMN:
          {
            const { metadata, createdBy } = event as PeTypes.UserEvent<PeTypes.DeleteColumnEvent>;
            const { datasetId, status } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-columns', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });

              if (notifyUser) {
                notify({
                  content: `Deleting column completed.`,
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: `Deleting column failed.`,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.COMBINE_COLUMNS:
          {
            const { metadata, createdBy } = event as PeTypes.UserEvent<PeTypes.CombineColumnsEvent>;
            const { status, datasetId, newColumnTitle } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset-columns', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-data', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });

              if (notifyUser) {
                notify({
                  content: `Created combined column “${newColumnTitle}” at the end of the table.`,
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Failed to create combined column.</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.UPDATE_COLUMN:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.UpdateColumnEvent>;
            const { status, datasetId } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset-columns', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['datasets', ownedBy] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });

              if (notifyUser) {
                notify({
                  content: 'Column metadata updated.',
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Failed to update column.</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.UPDATE_DATASET_METADATA:
          {
            const { metadata, createdBy } = event as PeTypes.UserEvent<PeTypes.UpdateDatasetMetadataEvent>;
            const { rowSubject, datasetId, status } = metadata;
            const notifyUser = createdBy === user?.id;

            if (notifyUser && status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset-log', datasetId] });

              notify({
                content: <span>{`Dataset data row subject changed to "${rowSubject}".`}</span>,
                icon: <MUIcon name="check" />,
              });
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Failed to update data row subject.</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.ROW_CREATION:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.CreateRowEvent>;
            const { datasetId, status } = metadata;
            const notifyUser = createdBy === user?.id;

            if (notifyUser && status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries(['dataset', datasetId]);
              await queryClient.invalidateQueries(['dataset-data', datasetId]);
              await queryClient.invalidateQueries(['datasets', ownedBy]);
              await queryClient.invalidateQueries(['dataset-log', datasetId]);

              notify({
                hideAfterSec: 2,
                content: 'Created new row at the end of the table.',
                dismissible: true,
              });
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Failed to create new row.</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.ROW_DELETION:
          {
            const { metadata, createdBy } = event as PeTypes.UserEvent<PeTypes.RowDeletionEvent>;
            const { datasetId, status, deletedRowsCount } = metadata;
            const notifyUser = createdBy === user?.id;

            if (notifyUser && status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset-data', datasetId] });
              await queryClient.invalidateQueries(['dataset-log', datasetId]);

              notify({
                hideAfterSec: 2,
                content: `Deleting ${pluralize('row', deletedRowsCount)} completed.`,
              });
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Deleting {pluralize('row', deletedRowsCount)} failed.</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.IMPORT_DATASET:
          {
            const { metadata, createdBy, ownedBy } = event as PeTypes.UserEvent<PeTypes.ImportDatasetEvent>;
            const { status, datasetId, datasetTitle, filename } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetImportStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['datasets', ownedBy] });
              await queryClient.invalidateQueries({ queryKey: ['workspace-statistics', ownedBy] });

              if (notifyUser) {
                if (status === PeTypes.DatasetImportStatus.COMPLETED) {
                  notify({
                    hideAfterSec: 10,
                    content: `Importing completed “${datasetTitle}”`,
                    action: (
                      <Link
                        to={generateLink('dataset', {
                          workspaceId: ownedBy,
                          datasetId,
                        })}
                      >
                        <SnackbarActionButton>View Dataset</SnackbarActionButton>
                      </Link>
                    ),
                  });
                } else {
                  notify({
                    hideAfterSec: 10,
                    content: `Importing failed “${filename}”`,
                  });
                }
              }
            }
          }
          break;
        case PeTypes.NotificationType.PUBLISH_DATASET:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.PublishDatasetEvent>;
            const { status, title, datasetId } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['datasets', ownedBy] });
              await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['abacus-columns', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['dataset-last-published-state', datasetId] });
              await queryClient.invalidateQueries(['dataset-log', datasetId]);

              if (notifyUser) {
                notify({
                  content: <span>{`Publishing completed "${title}".`}</span>,
                  icon: <MUIcon name="check" />,
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>{`Publishing failed "${title}"`}</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.RENAME_DATASET:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.RenameDatasetEvent>;
            const { datasetId, oldTitle, newTitle, status } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['dataset', datasetId] });
              await queryClient.invalidateQueries({ queryKey: ['datasets', ownedBy] });
              if (notifyUser) {
                notify({
                  content: <span>{`Dataset "${oldTitle}" renamed to "${newTitle}".`}</span>,
                  icon: <MUIcon name="check" />,
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>{`Renaming dataset "${oldTitle}" failed.`}</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.COPY_DATASET:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.CopyDatasetEvent>;
            const { status, datasetTitle, datasetId } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['datasets', ownedBy] });
              if (notifyUser) {
                const closeHandle = notify({
                  hideAfterSec: 10,
                  content: `Copying completed for dataset “${datasetTitle}”.`,
                  action: (
                    <Link
                      to={generateLink('dataset', {
                        workspaceId: ownedBy,
                        datasetId,
                      })}
                      onClick={() => closeHandle?.()}
                    >
                      <SnackbarActionButton>View Dataset</SnackbarActionButton>
                    </Link>
                  ),
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>{`Copying failed for dataset “${datasetTitle}”. Please try again.`}</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.HARD_DELETE_DATASET:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.HardDeleteDatasetEvent>;
            const { status, title } = metadata;
            const notifyUser = createdBy === user?.id;

            if (status === PeTypes.DatasetLogStatus.COMPLETED) {
              await queryClient.invalidateQueries({ queryKey: ['datasets', ownedBy] });
              await queryClient.invalidateQueries({ queryKey: ['workspace-statistics', ownedBy] });
              if (notifyUser) {
                notify({
                  hideAfterSec: 10,
                  content: <span>{`Dataset "${title}" has been permanently deleted.`}</span>,
                });
              }
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>{`Failed to delete dataset "${title}". Please try again.`}</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
        case PeTypes.NotificationType.EXPORT_DATASET:
          {
            const { metadata, ownedBy, createdBy } = event as PeTypes.UserEvent<PeTypes.ExportDatasetEvent>;
            const { status, createdDownloadLink, datasetTitle } = metadata;
            const notifyUser = createdBy === user?.id;

            if (
              status === PeTypes.DatasetLogStatus.COMPLETED &&
              createdDownloadLink &&
              notifyUser &&
              workspaceId === ownedBy
            ) {
              downloadDataset(createdDownloadLink);
            } else if (status === PeTypes.DatasetLogStatus.FAILED && notifyUser) {
              notify({
                content: <span>Export failed for dataset {datasetTitle}</span>,
                icon: <MUIcon name="warning" />,
              });
            }
          }
          break;
      }
    };

    const notificationsListener = async (notification: PeTypes.UserNotification<object>) => {
      queryClient.setQueryData<InfiniteData<PeTypes.UserNotification<object>[]>>(
        ['notifications', workspaceIdRef.current],
        (oldData) => {
          if (!oldData) {
            return {
              pages: [[notification]],
              pageParams: [0],
            };
          }

          let notificationFound = false;

          const newPages = oldData.pages.map((page) =>
            page.map((n) => {
              if (n.id === notification.id) {
                notificationFound = true;
                return notification;
              }
              return n;
            })
          );

          if (notificationFound) {
            return {
              ...oldData,
              pages: newPages,
            };
          }

          return {
            ...oldData,
            pages: [[notification, ...oldData.pages[0]], ...oldData.pages.slice(1)],
          };
        }
      );
    };

    realtimeSocket?.on('notification', notificationsListener);
    realtimeSocket?.on('event', eventsListener);

    return () => {
      realtimeSocket?.off('notification', notificationsListener);
      realtimeSocket?.off('event', eventsListener);
    };
  }, [realtimeSocket]);

  useEffect(() => {
    // store workspaceId into localStorage (used by peApi and full page reloads)
    localStorage.setItem(WORKSPACE_STORAGE_ITEM_ID, workspaceId.toString());
    setWorkspace(workspaceId);
    realtimeSocket?.emit('joinRoom', `ws:${workspaceId}`);
  }, [workspaceId, realtimeSocket]);

  const memoizedState = useMemo(
    () => ({
      activeWorkspace: workspaceQuery.data ?? DEFAULT_VALUE.activeWorkspace,
    }),
    [workspaceQuery.data]
  );

  if (workspaceQuery.isError || workspaceSubscriptionQuery.error) return <NoWorkspacePage />;

  if (
    workspaceQuery.isLoading ||
    workspaceQuery.isIdle ||
    workspaceSubscriptionQuery.isLoading ||
    workspaceSubscriptionQuery.isIdle
  ) {
    return <Loader />;
  }

  if (!workspaceSubscriptionQuery.data?.hasActiveLicence) return <SubscriptionExpired id={workspaceId} />;

  return <WorkspaceContext.Provider value={memoizedState}>{children}</WorkspaceContext.Provider>;
};

export const useWorkspaceContext = (): ContextState => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error(`useWorkspaceContext must be used within a WorkspaceContextProvider`);
  }
  return context;
};
