import { Map, MapGeoJSONFeature } from 'maplibre-gl';
import { useMemo } from 'react';
import useFeaturesUnderMouse from './useFeaturesUnderMouse';

function useFeatureSelection(
  map: Map | null,
  layerIdsToSelectFrom: string[],
  onSelect: (featuresInSelection: MapGeoJSONFeature[]) => void,
  eventType: 'dblclick' | 'click' = 'click'
) {
  const selectionHandler = useMemo(
    () => (featuresInEvent: MapGeoJSONFeature[]) => onSelect(featuresInEvent),
    [onSelect]
  );

  useFeaturesUnderMouse(map, eventType, selectionHandler, layerIdsToSelectFrom);
}

export default useFeatureSelection;
