import { MetadataTypes, ProjectTypes } from '@platform/types';
import produce from 'immer';
import { diff, IChange } from 'json-diff-ts';
import { Map, MapMouseEvent, StyleSpecification } from 'maplibre-gl';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { v4 as uuid } from 'uuid';
import * as MapSpecModifiers from './mapSpecModifiers';
import {
  CompareType,
  MapAPIs,
  MapContextSaveState,
  MapContextState,
  MapObjectClickHandler,
  RegisteredMaps,
} from './types';
import { getThumbCanvas, mergeCanvases, migrateMapSpec } from './utils';

const PATTERNS_TO_LOAD = [
  { filePath: '/assets/filter_pattern.png', name: 'pattern' },
  { filePath: '/assets/gray_pattern.png', name: 'gray_pattern' },
];

const DEFAULT_APIS: MapAPIs = {
  baseMaps: {
    get: () => new Promise<StyleSpecification>((s) => ({})),
  },
  mapContext: {
    get: () => new Promise<ProjectTypes.MapContext>((s) => ({})),
    update: () => new Promise((s) => ({})),
  },
  dataSourceLayers: {
    get: () => new Promise<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]>((s) => ({})),
  },
};

interface ContextState {
  state: MapContextState;
  changeCompareType: (value: CompareType) => void;
  changeActiveMap: (id: string) => void;
  jwtToken: string | null;
  onMountMap: (mapId: string, mapRef: Map) => void;
  onUnMountMap: (mapId: string) => void;
  apis: MapAPIs;
  useMapHighlight: boolean;
  changeUseMapHighlight: (value: boolean) => void;
  mapClickHandler: MapObjectClickHandler;
  onClickMap: (value: MapObjectClickHandler) => void;
  activeMap?: ProjectTypes.MapObject;
}

const DEFAULT_STATE: MapContextState = { type: 'single', registeredMaps: [] };

const MapsContext = React.createContext<ContextState>({
  apis: DEFAULT_APIS,
  state: DEFAULT_STATE,
  jwtToken: null,
  changeCompareType: () => ({}),
  changeActiveMap: () => ({}),
  changeUseMapHighlight: () => ({}),
  onMountMap: () => ({}),
  onUnMountMap: () => ({}),
  useMapHighlight: false,
  mapClickHandler: () => ({}),
  onClickMap: () => ({}),
});

interface Props {
  jwtToken: string | null;
  mapContextId: string;
  doSave: boolean;
  children: React.ReactNode;
  apis: MapAPIs;
  thumbWidth?: number;
  thumbHeight?: number;
  onThumb?: (canvases: HTMLCanvasElement) => void;
  onUpdate?: () => void;
}

export const MapsContextProvider: React.FC<Props> = ({
  jwtToken,
  doSave,
  mapContextId,
  children,
  apis,
  onThumb,
  onUpdate,
}) => {
  const [mapContextState, setMapContextState] = useState<MapContextState>(DEFAULT_STATE);
  const [activeMapIndex, setActiveMapIndex] = useState<number>(0);
  const [useMapHighlight, setUseMapHighlight] = useState<boolean>(false);

  const lastSavedState = useRef<MapContextSaveState>({ type: 'single', mapSpecs: {} });
  const changeTimeoutRef = useRef<number | null>(null);
  const thumbTimeoutRef = useRef<number | null>(null);
  const syncingLockedMapsRef = useRef<boolean>(false);
  const mapContextStateRef = useRef<MapContextState>(mapContextState);
  const mapClickHandlerRef = useRef<MapObjectClickHandler>();

  useEffect(() => {
    setMapContextState(DEFAULT_STATE);
    // reset the active map index when map context data has been loaded
    setActiveMapIndex(0);
  }, [mapContextId]);

  mapContextStateRef.current = mapContextState;

  const mapContextQuery = useQuery(['map-contexts', mapContextId], () => apis.mapContext.get(mapContextId), {
    cacheTime: 0, // never cache these
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!mapContextQuery.data) return;

    const setup = async () => {
      const loadedMapContext = mapContextQuery.data;

      if (loadedMapContext.sanitize) {
        try {
          if (doSave) {
            await apis.mapContext.update(mapContextId, {
              diffs: loadedMapContext.sanitize.diffs,
              type: loadedMapContext.type,
            });

            await mapContextQuery.refetch();
            return;
          }
        } catch (e) {
          console.error('Sanitize error', e);
        }
      }

      // take the response as the last saved state
      lastSavedState.current = {
        type: loadedMapContext.type,
        mapSpecs: loadedMapContext.mapObjects.reduce((p, c) => ({ ...p, [c.id]: c.styleSpec }), {}),
      };

      // if transform function provided, run the loaded map context through it
      const registeredMaps: RegisteredMaps = loadedMapContext.mapObjects.map((c) => ({
        id: c.id,
        styleSpec: migrateMapSpec(c.styleSpec),
        ref: mapContextState.registeredMaps.find((x) => x.id === c.id)?.ref ?? null, // transfer over the map ref even if re-fetch happens
        updateMapSpec: updateMapSpec(c.id),
      }));

      setMapContextState({ registeredMaps, type: loadedMapContext.type });
    };
    setup();
  }, [mapContextQuery.data]);

  const updateItemsMutation = useMutation(
    (patch: ProjectTypes.PatchMapContext) => apis.mapContext.update(mapContextId, patch),
    {
      onSuccess: () => onUpdate?.(),
    }
  );

  useEffect(() => {
    if (!doSave) return;
    if (changeTimeoutRef.current) clearTimeout(changeTimeoutRef.current);

    const handleChange = () => {
      const { type, registeredMaps } = mapContextState;
      const diffs: Record<string, IChange[]> = {};

      registeredMaps.forEach((mapObj) => {
        diffs[mapObj.id] = diff(lastSavedState.current.mapSpecs[mapObj.id] ?? {}, mapObj.styleSpec);
      });
      const anyChangesToSave = Object.values(diffs).some((d) => d.length) || lastSavedState.current.type !== type;

      if (anyChangesToSave) {
        updateItemsMutation.mutate({ diffs, type });
      }

      lastSavedState.current = {
        type,
        mapSpecs: registeredMaps.reduce((p, c) => ({ ...p, [c.id]: c.styleSpec }), {}),
      };
    };
    changeTimeoutRef.current = window.setTimeout(() => {
      handleChange();
    }, 500);
  }, [doSave, mapContextState]);

  useEffect(() => {
    if (!onThumb) return;
    if (thumbTimeoutRef.current) clearTimeout(thumbTimeoutRef.current);

    const handleChange = async () => {
      try {
        const canvases = await Promise.all(
          mapContextState.registeredMaps.filter((x) => x.ref != null).map((x) => getThumbCanvas(x.ref as Map))
        );

        const allCanvasesReady = canvases.length > 0 && canvases.every((x) => x !== null);
        if (allCanvasesReady) {
          onThumb(mergeCanvases(canvases as HTMLCanvasElement[], mapContextState.type));
        }
      } catch {
        // ignore
      }
    };
    thumbTimeoutRef.current = window.setTimeout(() => {
      handleChange();
    }, 2000);
  }, [mapContextState]);

  const updateMapSpec = (mapId: string) => (updatedStyleSpec: ProjectTypes.MapSpec) =>
    setMapContextState((prevState) =>
      produce(prevState, (draft) => {
        const registeredMap = draft.registeredMaps.find((x) => x.id === mapId);
        if (registeredMap) {
          registeredMap.styleSpec = updatedStyleSpec;
        }
      })
    );

  const handleMapMove = (mapId: string) => (moveEvent: MapMouseEvent) => {
    if (syncingLockedMapsRef.current) return;

    try {
      syncingLockedMapsRef.current = true;

      const currentRegisteredMapObjects = mapContextStateRef.current.registeredMaps;
      const movedMapObj = currentRegisteredMapObjects.find((x) => x.id === mapId);

      if (!movedMapObj?.ref) {
        console.log('Lost reference to moved map. Exiting...');
        return;
      }

      const movedMap = moveEvent.target;
      const zoom = movedMap.getZoom();
      const center = movedMap.getCenter();

      if (['single', 'side'].includes(mapContextStateRef.current.type)) {
        movedMapObj.updateMapSpec(MapSpecModifiers.updatePosition(movedMapObj.styleSpec, zoom, center));
      } else {
        currentRegisteredMapObjects.forEach((x) => {
          if (x.id !== movedMapObj.id) {
            x.ref?.jumpTo({ center, zoom });
          }
          x.updateMapSpec(MapSpecModifiers.updatePosition(x.styleSpec, zoom, center));
        });
      }
    } finally {
      syncingLockedMapsRef.current = false;
    }
  };

  const handleMountMap = (mapId: string, mapRef: Map) => {
    mapRef.on('move', handleMapMove(mapId));

    PATTERNS_TO_LOAD.forEach((pattern) => {
      mapRef.loadImage(pattern.filePath, (err, image) => {
        if (err && image == null) {
          console.error(err);
        } else if (image) mapRef.addImage(pattern.name, image);
      });
    });

    const nextState: MapContextState = {
      ...mapContextStateRef.current,
      registeredMaps: mapContextStateRef.current.registeredMaps.map((x) => ({
        ...x,
        ref: x.id === mapId ? mapRef : x.ref,
      })),
    };

    mapContextStateRef.current = nextState;
    setMapContextState(nextState);
  };

  const handleUnMountMap = (mapId: string) => {
    const nextState: MapContextState = {
      ...mapContextStateRef.current,
      registeredMaps: mapContextStateRef.current.registeredMaps.map((x) => ({
        ...x,
        ref: x.id === mapId ? null : x.ref,
      })),
    };
    mapContextStateRef.current = nextState;
    setMapContextState(nextState);
  };

  const changeCompareType = (newType: CompareType) => {
    const [firstMapObj, secondMapObj] = mapContextState.registeredMaps;

    const newMapState: MapContextState = {
      type: newType,
      registeredMaps: [firstMapObj],
    };

    if (newType === 'side') {
      // side maps view aka compare locations
      // this means we have two maps with same working layers and data layer
      const newMapId = uuid();

      newMapState.registeredMaps.push({
        id: newMapId,
        styleSpec: JSON.parse(JSON.stringify(firstMapObj.styleSpec)),
        ref: null,
        updateMapSpec: updateMapSpec(newMapId),
      });
    } else if (['side-locked', 'swipe'].includes(newType)) {
      if (secondMapObj) {
        // if second map exists make sure it has the same location as first
        const updatedSecondMapSpec = MapSpecModifiers.updatePosition(
          secondMapObj.styleSpec,
          firstMapObj.styleSpec.zoom,
          firstMapObj.styleSpec.center
        );

        secondMapObj.ref?.jumpTo({ zoom: firstMapObj.styleSpec.zoom, center: firstMapObj.styleSpec.center });
        newMapState.registeredMaps.push({ ...secondMapObj, styleSpec: updatedSecondMapSpec });
      } else {
        // in this case we need to create another map beside the one already present
        const newMapId = uuid();

        newMapState.registeredMaps.push({
          id: newMapId,
          styleSpec: JSON.parse(JSON.stringify(firstMapObj.styleSpec)),
          ref: null,
          updateMapSpec: updateMapSpec(newMapId),
        });
      }
    }

    setActiveMapIndex(0);
    setMapContextState(newMapState);
  };

  const changeActiveMap = (mapId: string) =>
    setActiveMapIndex(mapContextStateRef.current.registeredMaps.findIndex((x) => x.id === mapId));

  const changeUseMapHighlight = (value: boolean) => setUseMapHighlight(value);

  const mapClickHandler = (mapObj: ProjectTypes.MapObject) => {
    mapClickHandlerRef.current?.(mapObj);
  };

  const activeMap = mapContextState.registeredMaps[activeMapIndex];

  const memoizedValues = useMemo(
    () => ({
      apis,
      jwtToken,
      activeMap,
      useMapHighlight,
      changeUseMapHighlight,
      changeActiveMap,
      changeCompareType,
      mapClickHandler,
      onMountMap: handleMountMap,
      onUnMountMap: handleUnMountMap,
      state: mapContextState,
      onClickMap: (handler: MapObjectClickHandler) => (mapClickHandlerRef.current = handler),
    }),
    [jwtToken, activeMap, mapClickHandler, mapContextState, useMapHighlight]
  );

  if (mapContextQuery.isError) {
    return <div className="h-full w-full">Something went wrong</div>;
  }

  return <MapsContext.Provider value={memoizedValues}>{children}</MapsContext.Provider>;
};

export const useMapsContext = (): ContextState => {
  const context = useContext(MapsContext);
  if (!context) {
    throw new Error(`useMapsContext must be used within a MapsContextProvider`);
  }
  return context;
};

export const MapsConsumer = MapsContext.Consumer;
