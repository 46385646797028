import { Abacus, Buttons, DatasetTypeIcon, MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import React, { useEffect, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { useDatasetLastPublishedState } from '../../../hooks';
import * as peApi from '../../../pe.api';
import Panel from '../../shared/Panel';
import WorkingDataFilter from './Filtering/WorkingDataFilter';
import WorkingLayerEdit from './WorkingLayerEdit';
import WorkingLayerMenu from './WorkingLayerMenu';

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onRemove: (wLayer: ProjectTypes.WorkingLayerSpec) => void;
  onUpdate: (wLayer: ProjectTypes.WorkingLayerSpec) => void;
  onFit: (wLayer: ProjectTypes.WorkingLayerSpec) => void;
  onBeingEdited?: (edited: boolean) => void;
  clearTooltips?: () => void;
  canEdit?: boolean;
}

const WorkingLayer: React.FC<Props> = ({
  workingLayer,
  canEdit,
  clearTooltips,
  onRemove,
  onUpdate,
  onFit,
  onBeingEdited,
}) => {
  const { datasetId, name, baseColor, type, filter } = workingLayer;
  const [isFilterPanelVisible, setIsFilterPanelVisible] = useState<boolean>(false);
  const [isEditPointLayersVisible, setIsEditPointLayersVisible] = useState<boolean>(false);

  const noOfValidFilters = filter?.rules.filter((rule) => Helpers.checkIfValidRule(rule, null)).length ?? 0;
  const hasAppliedFilters = noOfValidFilters > 0;
  const { query: lastPublishedStateQuery } = useDatasetLastPublishedState({ datasetId });
  const isPublished = lastPublishedStateQuery.data?.datasetId === datasetId;

  useEffect(() => {
    onBeingEdited?.(isFilterPanelVisible || isEditPointLayersVisible);
  }, [isFilterPanelVisible, isEditPointLayersVisible]);

  const handleQueryChangeRequest = (newFilter: RuleGroupType) => {
    clearTooltips?.();
    onUpdate({ ...workingLayer, filter: newFilter });
  };

  const isPointGeo = type.toUpperCase() === ProjectTypes.LayerType.POINT.toUpperCase();
  const isPolygonGeo = type.toUpperCase() === ProjectTypes.LayerType.POLYGON.toUpperCase();
  const canFilter = (isPointGeo || isPolygonGeo) && isPublished;

  let panelContent = null;

  if (isFilterPanelVisible) {
    panelContent = (
      <WorkingDataFilter
        workingLayer={workingLayer}
        onChange={handleQueryChangeRequest}
        onCloseRequest={() => setIsFilterPanelVisible(false)}
        onFit={onFit}
      />
    );
  } else if (isEditPointLayersVisible) {
    panelContent = (
      <WorkingLayerEdit
        onCloseRequest={() => setIsEditPointLayersVisible(false)}
        workingLayer={workingLayer}
        onUpdate={onUpdate}
        isPublished={isPublished}
      />
    );
  }

  return (
    <>
      <div className="group relative flex h-[60px] flex-col gap-4 rounded-lg border border-gray-300 bg-white px-2 py-2">
        <div className="absolute top-0 bottom-0 left-0 hidden items-center justify-center group-hover:flex">
          <MUIcon name="drag_indicator" className="text-gray-400" />
        </div>
        <div className="flex h-full w-full items-center justify-between gap-2">
          <div className="flex min-w-0 gap-1">
            <DatasetTypeIcon type={type} iconColor={baseColor} />
            <div className="flex min-w-0 flex-col">
              <span className="truncate text-sm font-semibold text-gray-800">{name ?? 'Untitled'}</span>
              <div className="text-xs">
                <Abacus.HitsCount
                  api={peApi.getAbacusRowCount}
                  datasetId={datasetId}
                  dataQuery={filter}
                  options={{ enabled: canFilter }}
                  suffixLabel={lastPublishedStateQuery.data?.rowSubject}
                />
              </div>
            </div>
          </div>
          {canEdit && (
            <div className="flex items-center gap-3">
              <Buttons.Soft
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditPointLayersVisible(true);
                }}
                icon={<MUIcon name="edit" className="text-gray-700" />}
                className="relative mr-1 flex h-[36px] w-[36px] items-center justify-center gap-0 bg-transparent shadow-none hover:bg-gray-100"
                data-rh={'Edit'}
              ></Buttons.Soft>
              <WorkingLayerMenu
                workingLayer={workingLayer}
                hasAppliedFilters={hasAppliedFilters}
                onRemove={onRemove}
                onFit={onFit}
                onBeingEdited={onBeingEdited}
                isPublished={isPublished}
              />
              <div className="h-[20px] w-[1px] bg-gray-300"></div>
              <Buttons.Soft
                disabled={!canFilter}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsFilterPanelVisible(true);
                }}
                icon={<MUIcon name="tune" className="text-gray-700" />}
                className={`relative mr-1 flex h-[36px] w-[36px] items-center justify-center gap-0 rounded-lg border-2 bg-transparent hover:bg-gray-100 ${
                  hasAppliedFilters ? 'border-primary-600' : 'border-0 shadow-none'
                }`}
                data-rh="Filter"
              >
                {hasAppliedFilters && (
                  <div className="bg-primary-600 absolute -top-1.5 -right-1.5 flex h-[18px] w-[18px] items-center justify-center rounded-xl border border-white">
                    <div className="text-[10px] font-semibold text-white">{noOfValidFilters}</div>
                  </div>
                )}
              </Buttons.Soft>
            </div>
          )}
        </div>
      </div>
      {panelContent && <Panel>{panelContent}</Panel>}
    </>
  );
};

export default WorkingLayer;
