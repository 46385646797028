import tw from 'tailwind-styled-components';
interface Props {
  values: string[] | undefined;
  active?: string;
  onClick: (category: string) => void;
}
const GroupedButtons: React.FC<Props> = ({ values, onClick, active }) => {
  const changeValue = (value: string) => {
    onClick(value);
  };

  if (values == null) {
    return <div>No data</div>;
  }
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {values.map((value: string, index) => {
        return (
          <Button $active={active === value} key={index} type="button" onClick={() => changeValue(value)}>
            {value}
          </Button>
        );
      })}
    </span>
  );
};

export default GroupedButtons;

const Button = tw.button<{ $active?: boolean }>`
relative 
inline-flex 
items-center
bg-white 
px-3 
py-2 
h-[28px]
text-sm 
font-semibold 
text-gray-900 
ring-1 
ring-inset 
ring-gray-300 
hover:bg-gray-50 
focus:z-10
first:rounded-l-lg
last:rounded-r-lg
text-[15px]
font-semibold
  ${(props) =>
    props.$active &&
    `bg-primary-100 text-primary-600 border-solid
  border
  border-primary-300 `}
`;
