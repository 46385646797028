import classNames from 'classnames';
import React from 'react';
import { Column } from './index';

interface Props {
  columns: Column[];
  freezeHeader?: boolean;
  freezeFirstColumn?: boolean;
}

const TableHeader: React.FC<Props> = ({ columns, freezeHeader = false, freezeFirstColumn = false }: Props) => {
  return (
    <thead>
      <tr
        className={classNames('text-gray-800', {
          'sticky top-0 z-[11] bg-white': freezeHeader,
        })}
      >
        {columns.map((column, index) => {
          return (
            <th
              onKeyDown={() => column.onClick?.(column.id)}
              onClick={() => column.onClick?.(column.id)}
              key={`column-header-${column.id}`}
              id={`column-header-${column.id}`}
              className={classNames('min-w-8 h-full max-w-[330px] cursor-pointer p-0 font-normal', {
                'sticky left-0 z-[12] bg-white': freezeFirstColumn && index === 0,
                'bg-gray-50': column.isHighlighted,
              })}
            >
              <div className="relative h-12 w-full border-b-[2px] border-r border-gray-300">
                <div className="relative flex h-full items-center gap-2 px-3">
                  <div className={classNames('flex flex-grow', column.headerClasses)}>
                    <div
                      className={classNames('flex items-center gap-3', {
                        'flex-grow': column.suffixIcon || column.renderMenu,
                      })}
                    >
                      {column.suffixIcon && <div>{column.suffixIcon}</div>}
                      <div className="flex max-w-[228px] flex-col">
                        <div className="truncate font-semibold">{column.title}</div>
                        {column.label && column.title !== column.label && (
                          <div className="truncate text-xs text-gray-500">{column.label}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  {column.renderMenu?.(column)}
                </div>
              </div>
            </th>
          );
        })}
        <th className="w-full" />
      </tr>
    </thead>
  );
};

export default TableHeader;
