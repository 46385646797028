import classNames from 'classnames';
import React from 'react';
import { Buttons, INotification } from '..';
import MUIcon from '../MUIcon';

interface Props {
  notification: INotification;
  onCloseRequest: () => void;
}

const Snackbar: React.FC<Props> = ({ notification, onCloseRequest }) => {
  const { icon, content, action, dismissible = true } = notification;

  const hasAction = action != null;
  const dismissButton = (
    <Buttons.Link
      className="-mr-3 h-[40px] w-[40px] rounded-full text-white hover:bg-zinc-700"
      size="sm"
      icon={<MUIcon name="close" className="text-xl" />}
      onClick={() => onCloseRequest()}
    />
  );

  return (
    <div
      className={classNames(
        'box-border flex min-w-[260px] max-w-[380px] items-start gap-2 rounded-lg bg-zinc-800 px-4 py-1 text-sm text-white shadow-lg'
      )}
    >
      {icon && <div className="flex h-[48px] items-center">{icon}</div>}
      <div className="flex w-full flex-col gap-4">
        <div
          className={classNames('flex min-h-[48px] flex-grow  items-center justify-between gap-2', {
            'pt-2': hasAction,
          })}
        >
          <div data-cy="snackbar-content">{content}</div>
          {!hasAction && dismissible && dismissButton}
        </div>
        {hasAction && (
          <div className="flex items-center justify-end gap-2 empty:hidden">
            {action}
            {dismissible && (
              <Buttons.Link
                className="h-[40px] w-[40px] rounded-full text-white hover:bg-zinc-700"
                size="sm"
                icon={<MUIcon name="close" className="text-xl" />}
                onClick={() => onCloseRequest()}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Snackbar;
