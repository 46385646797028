import { generatePath, matchPath } from 'react-router-dom';

type ApplicationRoute =
  | 'workspace'
  | 'workspaceSettings'
  | 'workspaceSettingsSection'
  | 'login'
  | 'invitation'
  | 'account'
  | 'resetPassword'
  | 'newPassword'
  | 'defaultWorkspace'
  | 'projects'
  | 'allProjects'
  | 'project'
  | 'datasets'
  | 'dataset'
  | 'datasetMap'
  | 'story'
  | 'map';

// Define your application's routes
const routes: Record<ApplicationRoute, string> = {
  login: '/login',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  invitation: '/invitation',
  account: '/account',
  defaultWorkspace: '/ws',
  workspace: '/ws/:workspaceId',
  workspaceSettings: '/ws/:workspaceId/workspace',
  workspaceSettingsSection: '/ws/:workspaceId/workspace/:section',
  projects: '/ws/:workspaceId/projects',
  allProjects: '/ws/:workspaceId/projects/all',
  project: '/ws/:workspaceId/projects/:projectId',
  datasets: '/ws/:workspaceId/datasets',
  dataset: '/ws/:workspaceId/datasets/:datasetId',
  datasetMap: '/ws/:workspaceId/datasets/:datasetId/map',
  story: '/ws/:workspaceId/stories/:storyId/:mode',
  map: '/ws/:workspaceId/maps/:mapId/:mode',
};

export function generateLink(routeKey: ApplicationRoute, params?: Record<string, unknown>): string {
  const pathTemplate = routes[routeKey];
  if (!pathTemplate) {
    throw new Error(`Route not found: ${routeKey}`);
  }

  // Check if all required params are provided
  const missingParams: string[] = [];
  const paramMatches = pathTemplate.match(/:\w+/g) || []; // Find all :param in the pathTemplate
  paramMatches.forEach((param) => {
    const cleanParam = param.replace(':', '');
    if (!params || !(cleanParam in params)) {
      missingParams.push(cleanParam);
    }
  });

  if (missingParams.length > 0) {
    throw new Error(`Missing required params for route "${routeKey}": ${missingParams.join(', ')}`);
  }

  return generatePath(pathTemplate, params as any);
}

export function matchRoute(pathToMatch: string): ApplicationRoute | null {
  const matchingRouteEntry = Object.entries(routes).find(([key, value]) => {
    return matchPath({ path: value }, pathToMatch);
  });
  return matchingRouteEntry ? (matchingRouteEntry[0] as ApplicationRoute) : null;
}
