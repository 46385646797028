import { AbacusTypes } from '@platform/types';
import React, { useState } from 'react';
import { Inputs, MUIcon, PanelHeader } from '../';
import { EmptyColumnsState } from '../Datasets';
import ColumnPickerItem from '../Datasets/DatasetColumnPickerItem';

interface Props {
  datasetId: string;
  columns: AbacusTypes.Column[];
  data: AbacusTypes.Data;
  onSelect: (colKey: string) => void;
  onCloseRequest: () => void;
}

const AbacusColumnPicker: React.FC<Props> = ({ datasetId, columns, data, onSelect, onCloseRequest }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const availableColumns = columns.filter(
    (column) =>
      column.name.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
      column.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  return (
    <div className="absolute top-0 left-0 flex h-full w-full flex-col bg-white">
      <PanelHeader title="Select Variable" onBack={onCloseRequest} />
      <div className="flex min-h-0 flex-col p-4 pb-2">
        <Inputs.Input
          autoFocus
          prefixComponent={<MUIcon name="search" />}
          classes="rounded-lg bg-gray-100 ring-transparent py-2.5" // ml-3 mr-2 flex-grow border-0 p-0 text-sm text-gray-900 focus:outline-none focus:ring-0 bg-transparent
          inputClasses="ml-3 mr-2  bg-transparent"
          placeholder="Search for column..."
          value={searchTerm}
          onClear={() => setSearchTerm('')}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="-mr-3 -ml-4 mt-4 mb-1 flex flex-col overflow-y-auto">
          {!availableColumns?.length && <EmptyColumnsState searchTerm={searchTerm} />}
          {availableColumns?.map((col) => {
            const colSample = data.data.map((key) => key[col.name]);
            return (
              <ColumnPickerItem
                key={col.name}
                title={col.name}
                label={col.title}
                id={col.name}
                type={col.type}
                onSelect={onSelect}
                sampleData={colSample}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AbacusColumnPicker;
