import React from 'react';
import tw from 'tailwind-styled-components';

export interface Props {
  test?: string;
}

export const UnhandledError: React.FC<Props> = () => <Outer>oops...Something went wrong</Outer>;

export default UnhandledError;

const Outer = tw.div`
  fixed
  flex
  flex-col
  z-50
  items-center
  justify-center
  gap-10
  w-full
  h-full
  top-0
  left-0
  bg-red-50
`;
