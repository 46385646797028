import { useTableMetadata } from '@platform/shared/hooks';
import { MetadataTypes } from '@platform/types';
import classnames from 'classnames';
import React from 'react';
import MUIcon from '../../MUIcon';
import Spinner from '../../Spinner';
import { canUseDotDensity, getParentVariable, isVariableCountOnly } from './utils';

const MAX_LENGTH_OF_SELECTION = 5;

export interface Props {
  surveyName: string;
  datasetAbbreviation: string;
  selectedTableUuid: string;
  selectedVariableUuids: string[];
  allowMultiSelection?: boolean;
  onChange: (variables: MetadataTypes.Variable[], table: MetadataTypes.Table) => void;
}

export const VariablesPicker: React.FC<Props> = ({
  selectedVariableUuids,
  surveyName,
  datasetAbbreviation,
  selectedTableUuid,
  onChange,
  allowMultiSelection = true,
}) => {
  const tableQuery = useTableMetadata({ surveyName, datasetAbbreviation, tableUuid: selectedTableUuid });
  if (tableQuery.isLoading || tableQuery.isIdle)
    return (
      <div className="flex h-[150px] w-full items-center justify-center">
        <Spinner width={41} height={41} />
      </div>
    );

  if (tableQuery.isError) return <>An error occurred</>;

  const tableMeta = tableQuery.data;
  const tableVariablesMeta = tableQuery.data.variables;

  const selection = tableVariablesMeta.reduce((p: MetadataTypes.Variable[], c: MetadataTypes.Variable) => {
    if (selectedVariableUuids.includes(c.uuid)) {
      return [...p, c];
    }
    return p;
  }, []);

  const handleVariableClick = (v: MetadataTypes.Variable) => onChange([v], tableMeta);

  const handleAddVariableClick = (evt: React.MouseEvent<HTMLButtonElement>, v: MetadataTypes.Variable) => {
    evt.stopPropagation();

    const alreadySelected = selectedVariableUuids.includes(v.uuid);

    let newSelection;
    switch (true) {
      case alreadySelected && selectedVariableUuids.length === 1:
        // no-op, can't deselect the only variable in selection
        newSelection = selection;
        break;
      case alreadySelected:
        // deselect it
        newSelection = selection.filter((x) => x.uuid !== v.uuid);
        break;
      default:
        // add to selection
        newSelection = [...selection, v];
        break;
    }

    onChange(newSelection, tableMeta);
  };

  let canSelectionUseDotDensity = true;
  let canSelectionUseShaded = true;

  selection.forEach((selectedVariable) => {
    if (canSelectionUseDotDensity && !canUseDotDensity(selectedVariable)) {
      canSelectionUseDotDensity = false;
    }
    if (canSelectionUseShaded && isVariableCountOnly(selectedVariable, tableMeta)) {
      canSelectionUseShaded = false;
    }
  });

  const parentVariable = selection.length ? getParentVariable(selection[0], tableMeta) : undefined;

  return (
    <div className="mt-2 flex min-h-0 flex-col gap-0.5 overflow-y-auto pb-2">
      {tableVariablesMeta.map((v) => {
        const isSelected = selectedVariableUuids.includes(v.uuid);
        const canAddMoreToSelection = selectedVariableUuids.length < MAX_LENGTH_OF_SELECTION;
        const isParentVariable = parentVariable && parentVariable.uuid === v.uuid;
        const canVariableUseDotDensity = canSelectionUseDotDensity && canUseDotDensity(v);
        const canVariableUseShaded = canSelectionUseShaded && !isVariableCountOnly(v, tableMeta);

        const availableForMultipleSelection =
          allowMultiSelection &&
          canAddMoreToSelection &&
          !isSelected &&
          !isParentVariable &&
          (canVariableUseDotDensity || canVariableUseShaded);

        const showCheckbox = isSelected || availableForMultipleSelection;

        return (
          <div
            className={classnames('relative flex cursor-pointer items-center gap-4 rounded-md p-2 hover:bg-gray-100', {
              'bg-primary-200': isSelected,
            })}
            style={{ paddingLeft: `${(v.indent + 1) * 12}px` }}
            onClick={() => handleVariableClick(v)}
            key={v.uuid}
          >
            <div className="flex flex-grow flex-col">
              <span className="text-sm">{v.label}</span>
              <span className="hidden text-xs text-gray-400">{v.uuid}</span>
            </div>
            {showCheckbox && (
              <div>
                <button className="" onClick={(event) => handleAddVariableClick(event, v)}>
                  {isSelected ? (
                    <MUIcon
                      name="check_box"
                      iconStyle="filled"
                      className=" text-primary-600 hover:text-primary-700 p-[6px]"
                    />
                  ) : (
                    <MUIcon name="check_box_outline_blank" className="p-[6px] text-gray-500 hover:text-gray-700" />
                  )}
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default VariablesPicker;
