import React from 'react';

interface Props {
  content: string;
}

const PreviewSlideNote: React.FC<Props> = ({ content }) => {
  return (
    <div className="h-full w-full">
      <div
        className="prose break-words"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
};

export default PreviewSlideNote;
