import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useState } from 'react';
import AccountSignInEmailForm from './AccountSignInEmailForm';
import AccountSignInPasswordForm from './AccountSignInPasswordForm';

interface Props {
  user: PeTypes.User;
}

const AccountSignInInformation: React.FC<Props> = ({ user }) => {
  const [isEmailExpanded, setIsEmailExpanded] = useState<boolean>(false);
  const [isPasswordExpanded, setIsPasswordExpanded] = useState<boolean>(false);
  const isAnyContainerExpanded = isPasswordExpanded || isEmailExpanded;
  return (
    <div className="flex flex-col gap-5 ">
      <p className="text-sm text-gray-700">Sign-In information</p>
      <div
        className={classNames('flex flex-col rounded-xl border border-gray-200', {
          'gap-6 border-none': isAnyContainerExpanded,
        })}
      >
        <div
          className={classNames(
            'font-regular flex flex-col items-center justify-center gap-2 rounded-t-xl bg-white shadow-md',
            { 'rounded-xl border border-gray-200': isAnyContainerExpanded }
          )}
        >
          <button
            className="flex h-full w-full items-center justify-between rounded-t-xl p-6 hover:cursor-pointer"
            onClick={() => setIsEmailExpanded(!isEmailExpanded)}
          >
            <div className="flex items-center justify-center gap-24">
              <p className="text-sm font-semibold">Email</p>
              <p className="text-sm text-gray-600">{isEmailExpanded ? 'Change email address' : user.email}</p>
            </div>
            <div className="flex items-center justify-center">
              <MUIcon name={isEmailExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
            </div>
          </button>
          {isEmailExpanded && (
            <div className="flex w-full p-6 pt-3">
              <AccountSignInEmailForm email={user.email} userId={user.id} onCancel={() => setIsEmailExpanded(false)} />
            </div>
          )}
        </div>

        <div
          className={classNames(
            'font-regular flex flex-col items-center justify-center gap-2 rounded-b-xl bg-white shadow-md',
            {
              'rounded-xl border border-gray-200': isAnyContainerExpanded,
              'border-t border-gray-200': !isAnyContainerExpanded,
            }
          )}
        >
          <button
            className="flex w-full items-center justify-between p-6 hover:cursor-pointer"
            onClick={() => setIsPasswordExpanded(!isPasswordExpanded)}
          >
            <div className="flex items-center justify-center gap-24">
              <p className="text-sm font-semibold">Password</p>
              {isPasswordExpanded && <p className="text-sm text-gray-600">Change password</p>}
            </div>
            <div className="flex items-center justify-center">
              <MUIcon name={isPasswordExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
            </div>
          </button>
          {isPasswordExpanded && (
            <div className="flex w-full p-6 pt-3">
              <AccountSignInPasswordForm userId={user.id} onCancel={() => setIsPasswordExpanded(false)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountSignInInformation;
