import { Dropdowns } from '@platform/shared/ui';
import React from 'react';

export const SORT_BY_OPTIONS: Dropdowns.DropdownOption[] = [
  { name: 'Last modified ', value: 'updatedAt' },
  { name: 'Title', value: 'title' },
  { name: 'Rows', value: 'numberOfRecords' },
  { name: 'Columns', value: 'numberOfColumns' },
];

interface Props {
  value: string;
  onChange: (v: string) => void;
}

const SortDatasetsBy: React.FC<Props> = ({ value, onChange }) => (
  <Dropdowns.Soft
    header={<div>Sort by</div>}
    options={SORT_BY_OPTIONS}
    onSelect={(opt) => onChange(opt.value as string)}
    value={value}
    menuClasses="w-[310px]"
    hAlignment="right"
    triggerLabelClasses="text-sm"
  />
);

export default SortDatasetsBy;
