import { useSurveysMetadata } from '@platform/shared/hooks';
import { TableInfo } from '@platform/shared/integrated-ui';
import { MUIcon, Popup } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';
import { useQuery } from 'react-query';
import * as peApi from '../../../pe.api';
import InsightsWrapper, { ContextConsumer } from '../InsightsWrapper';
import SelectionInsights from './SelectionInsights';
import SurveyInsights from './SurveyInsights';

interface Props {
  selection: ProjectTypes.VariableSelection[];
  geoFeatures: ProjectTypes.GeoFeatureSelection[];
}

const Survey: React.FC<Props> = ({ selection, geoFeatures }) => {
  const { surveyName, tableGuid, datasetAbbrev } = selection[0];
  const surveyInsightsQuery = useQuery(
    ['insights-for-survey', selection[0].surveyName],
    () => peApi.getSurveyInsights(selection[0].surveyName),
    { enabled: geoFeatures.length > 0 }
  );

  const surveysQuery = useSurveysMetadata({
    surveyNames: [surveyName],
  });
  return (
    <>
      <InsightsWrapper
        openByDefault={true}
        title={
          <div className="flex items-center justify-center gap-3">
            <TableInfo
              className="text-sm font-semibold text-gray-800"
              surveyName={surveyName}
              datasetAbbreviation={datasetAbbrev}
              tableUuid={tableGuid}
            />
            <div className="text-xs">
              <Popup
                content={
                  <div>
                    Extended table of the data currently displayed on the map.
                    {surveysQuery.data && (
                      <div>
                        <span className="font-semibold">Data source: </span>
                        {surveysQuery.data[0].displayName}
                      </div>
                    )}
                  </div>
                }
                trigger={
                  <button className="flex hover:text-gray-200" onClick={(e) => e.stopPropagation()}>
                    <MUIcon name="info" className="text-gray-500" />
                  </button>
                }
                vAlignment="top-bottom"
                hAlignment="center"
              />
            </div>
          </div>
        }
      >
        <ContextConsumer>
          {({ open }) => (
            <SelectionInsights
              enabled={open && geoFeatures.length > 0}
              selection={selection}
              geoFeatures={geoFeatures}
            />
          )}
        </ContextConsumer>
      </InsightsWrapper>
      {surveyInsightsQuery.data?.map((x) => (
        <InsightsWrapper
          key={x.id}
          title={
            <div className="flex items-center justify-center gap-3">
              <span className="text-sm font-semibold text-gray-800">{x.title}</span>
              <div className="text-xs">
                <Popup
                  content={
                    <span>
                      <b>Data Source</b>: Political Census Survey
                    </span>
                  }
                  trigger={
                    <button className="flex hover:text-gray-200" onClick={(e) => e.stopPropagation()}>
                      <MUIcon name="info" className="text-gray-500" />
                    </button>
                  }
                  vAlignment="top-bottom"
                  hAlignment="center"
                />
              </div>
            </div>
          }
        >
          <ContextConsumer>
            {({ open }) => <SurveyInsights surveyInsightsId={x.id} enabled={open} geoFeatureSelection={geoFeatures} />}
          </ContextConsumer>
        </InsightsWrapper>
      ))}
    </>
  );
};

export default Survey;
