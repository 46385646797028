import React from 'react';
import { Dropdown, Props as DropdownBaseProps } from './Dropdown';

const Soft: React.FC<DropdownBaseProps> = (props) => {
  return (
    <Dropdown
      {...props}
      triggerClasses="gap-2 text-xs font-semibold text-gray-600 bg-transparent hover:bg-transparent ring-0 shadow-none"
    />
  );
};

export default Soft;
