import { MapGeoJSONFeature } from 'maplibre-gl';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  feature: MapGeoJSONFeature;
  geoLabelProp?: string;
}

const GeoLabelTooltip: React.FC<Props> = ({ feature, geoLabelProp = 'Geo_QName' }) => {
  return (
    <Outer>
      <Geo>{feature.properties[geoLabelProp]}</Geo>
    </Outer>
  );
};

export default GeoLabelTooltip;

const Outer = styled.div`
  background-color: #333;
  min-width: 100px;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: white;
`;

const Geo = styled.div`
  padding: 10px;
  display: flex;
  font-size: 16px;
`;
