import { MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React from 'react';

interface Props {
  data: { id: string; title: string; value: unknown }[];
  title?: string;
  heading?: string;
  onClose?: () => void;
  footer?: React.ReactNode;
  emptyPlaceholder: React.ReactNode;
}

const WorkingLayerTooltip: React.FC<Props> = ({ footer, title, heading, data = [], emptyPlaceholder, onClose }) => {
  const titleHasContent = title?.trim().toString().length;
  let content;

  if (emptyPlaceholder) {
    content = emptyPlaceholder;
  } else {
    content = (
      <div className="flex w-full flex-col gap-2 overflow-y-auto text-sm">
        <div className="flex h-full w-full justify-between gap-8 px-3 pt-3">
          <div className="flex w-10/12 flex-col justify-between gap-1">
            {heading && (
              <div className="truncate text-xs text-gray-400" title={heading}>
                {heading}
              </div>
            )}
            {title && <div className="font-semibold">{title}</div>}
          </div>
          {onClose && (
            <button onClick={onClose} className="flex w-2/12 items-center justify-end">
              <MUIcon name="close" />
            </button>
          )}
        </div>
        <div className={'flex w-full flex-col gap-3 overflow-y-auto border-t border-gray-200 px-4 py-3 empty:hidden'}>
          {data.map((row) => {
            return (
              <div key={row.id} className={'flex w-full justify-between gap-5'}>
                <div className="flex min-w-0 flex-col gap-1">
                  <span>{row.title}</span>
                  <span className="w-full text-start font-semibold" title={row.value?.toString() || 'N/A'}>
                    {row.value?.toString() || 'N/A'}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'transition-height transition-width flex max-h-96 max-w-[320px] flex-col items-center rounded-xl bg-white py-2 shadow-md duration-300',
        {
          'min-w-[300px]': titleHasContent || data.length > 0,
        }
      )}
    >
      {content}
      {footer && content && (
        <div className="flex w-full justify-center border-t border-gray-200 px-4 pt-2">{footer}</div>
      )}
    </div>
  );
};

export default WorkingLayerTooltip;
