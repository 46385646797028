import { Map, MapGeoJSONFeature, MapMouseEvent } from 'maplibre-gl';
import { useEffect, useRef } from 'react';

type EventType = 'mousemove' | 'mousedown' | 'mouseup' | 'click'; // etc
type MouseEventListener = (e: MapMouseEvent) => void;

function useFeaturesUnderMouse2(
  maps: { ref: Map; sourceLayerIds: string[] }[],
  eventType: EventType,
  handler: (features: { ref: Map; features: MapGeoJSONFeature[] }[]) => void,
  throttleBy = 50
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const listenersRef = useRef<MouseEventListener[]>([]);

  useEffect(() => {
    const mapMouseHandler = (ref: Map) => {
      const listener = (e: MapMouseEvent) => {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);

        timeoutRef.current = setTimeout(() => {
          const point = e.point.clone();
          const lngLatInOriginalMap = ref.unproject(point).toArray();
          const featuresUnderMouse: { ref: Map; features: MapGeoJSONFeature[] }[] = [];

          const sortedByHovered = [...maps].sort((x) => (x.ref === ref ? -1 : 1)); //make sure the highlighted map spits out features first

          sortedByHovered.forEach((x) => {
            const features =
              x.ref?.queryRenderedFeatures(x.ref.project(lngLatInOriginalMap), {
                layers: x.sourceLayerIds ?? [],
              }) ?? [];

            featuresUnderMouse.push({ ref: x.ref, features });
          });

          handler(featuresUnderMouse);
        }, throttleBy);
      };
      return listener;
    };

    listenersRef.current = [];

    maps.forEach((m) => {
      const listener = mapMouseHandler(m.ref);
      listenersRef.current.push(listener);
      m.ref.on(eventType, listener);
    });

    return () => {
      maps.forEach((map, idx) => {
        map.ref.off(eventType, listenersRef.current[idx]);
      });
    };
  }, [maps, eventType, handler]);
}

export default useFeaturesUnderMouse2;
