import { MapGeoJSONFeature } from 'maplibre-gl';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  features: MapGeoJSONFeature[];
}

const Tooltip: React.FC<Props> = ({ features = [] }) => {
  return (
    <Outer>
      {features.map((f, i) => (
        <Inner key={`${i}-${new Date().getTime()}`}>
          <div>
            <strong>ID: </strong>
            <span>{f.id}</span>
          </div>
          <div>
            <strong>Layer: </strong>
            <span>{f.layer?.id ?? 'N/A'}</span>
          </div>
          <div>
            <strong>Properties</strong>
            {Object.entries(f.properties).map(([k, v]) => (
              <div key={k}>
                <strong>{k}: </strong>
                {v}
              </div>
            ))}
          </div>
        </Inner>
      ))}
    </Outer>
  );
};

export default Tooltip;

const Outer = styled.div`
  width: 400px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #333;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: white;
  gap: 6px;
`;
const Inner = styled.div`
  border-bottom: 1px solid silver;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
