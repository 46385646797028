import { Dropdowns, MUIcon } from '@platform/shared/ui';
import RenameDatasetModal from '../RenameDatasetModal';
import * as MenuTypes from './menu.types';

const useRenameOption = (
  datasetId: string,
  datasetName: string,
  action: number | null,
  handleCloseRequest: () => void
): [Dropdowns.DropdownOption, JSX.Element | null] => {
  const option = {
    value: MenuTypes.MenuOption.RENAME,
    name: 'Rename',
    icon: <MUIcon name="edit" />,
    additionalStyles: 'text-sm',
  };

  const modal =
    action === MenuTypes.MenuOption.RENAME ? (
      <RenameDatasetModal datasetId={datasetId} datasetName={datasetName} onCloseRequest={handleCloseRequest} />
    ) : null;

  return [option, modal];
};

export default useRenameOption;
