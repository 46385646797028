import 'react-hint/css/index.css';
import './app.css';

import { Routing } from '@platform/shared/ui';
import React from 'react';
import ReactHintFactory from 'react-hint';
import { Link, Route, Routes } from 'react-router-dom';

import { metadataApi } from '@platform/api';
import { useSystemFilterSets, useSystemPalettes } from '@platform/shared/hooks';
import { useSessionContext } from './contexts/SessionContext';
import { generateLink } from './LinkGenerator';
import AccountInfo from './pages/Account.page';
import InvitationPage from './pages/Invitation.page';
import LoginPage from './pages/Login.page';
import NewPasswordPage from './pages/NewPassword.page';
import ResetPasswordPage from './pages/ResetPassword.page';
import WorkspaceDefault from './pages/WorkspaceDefault';
import WorkspacePagesWrapper from './pages/WorkspacePagesWrapper';
import { getColorPalettes } from './pe.api';

const { PublicRoute, ProtectedRoute } = Routing;
const ReactHint = ReactHintFactory(React);

export function App() {
  const { isAuthenticated } = useSessionContext();
  // init filter sets cache
  useSystemFilterSets().initialize(metadataApi.getSystemFilterSets);
  // init color cache
  useSystemPalettes().initialize(getColorPalettes);

  return (
    <div className="h-full min-h-0 w-full">
      <ReactHint autoPosition events delay={{ show: 100, hide: 0 }} />
      <Routes>
        <Route
          path={generateLink('login')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('defaultWorkspace')}>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('resetPassword')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('login')}>
              <ResetPasswordPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('newPassword')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('login')}>
              <NewPasswordPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('invitation')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={'/'}>
              <InvitationPage />
            </PublicRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute isAllowed={isAuthenticated} redirectPath={generateLink('login')}>
              <Routes>
                <Route path="/ws/:workspaceId/*" element={<WorkspacePagesWrapper />} />
                <Route path="/*" element={<WorkspaceDefault />} />
                <Route path="/account" element={<AccountInfo />} />
              </Routes>
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <div className="flex flex-col gap-4">
              There is nothing here<Link to="/">Home</Link>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
