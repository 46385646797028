import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import DatasetCellEditModal from './DatasetCellEditModal';

interface Props {
  value: string | null;
  onCloseRequest: () => void;
  anchorRect: DOMRect | { top: number; left: number; width: number };
  onDone: (value: string | null) => void;
}

type Input = {
  formValue: string | null;
};

const EditTextCellModal: React.FC<Props> = ({ value, onCloseRequest, anchorRect, onDone }) => {
  const { register, handleSubmit } = useForm<Input>({ values: { formValue: value } });
  const onSubmit: SubmitHandler<Input> = (data) => {
    const formValue = data.formValue?.trim();
    if (value !== formValue) {
      onDone(data.formValue?.trim() ?? '');
    }
    onCloseRequest();
  };

  return (
    <DatasetCellEditModal
      className="min-w-8 border-primary-700 shadow-none"
      position={{ top: anchorRect.top - 16, left: anchorRect.left - 16 }}
      style={{ width: anchorRect.width + 48 }}
      onCloseRequest={onCloseRequest}
      okLabel="Save"
      onDone={handleSubmit(onSubmit)}
    >
      <div className="flex min-h-[48px] items-center px-4">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <textarea
            className="mt-[14px] w-full border-0 p-0 text-xs outline-none focus:border-none focus:ring-0"
            autoFocus
            {...register('formValue')}
          />
        </form>
      </div>
    </DatasetCellEditModal>
  );
};

export default EditTextCellModal;
