import { MUIcon } from '@platform/shared/ui';
import React from 'react';

const Search: React.FC = () => {
  return (
    <div
      className="inline-flex flex-col items-start justify-start space-y-2 px-6 py-4"
      style={{ width: 620, height: 186 }}
    >
      <div className="inline-flex items-center justify-start space-x-2" style={{ width: 572, height: 40 }}>
        <p className="text-lg font-semibold leading-relaxed text-gray-900" style={{ width: 444 }}>
          Search
        </p>
      </div>
      <div
        className="inline-flex items-center justify-start space-x-4 rounded-lg border border-gray-200 bg-gray-50 py-4 pl-4 pr-2"
        style={{ width: 572, height: 56 }}
      >
        <MUIcon name="search" className="h-5 w-5 text-gray-400" aria-hidden="true" />
        <p className="text-base leading-normal text-gray-600" style={{ width: 512 }}>
          Find a place, project or report
        </p>
      </div>
      <div className="inline-flex items-center justify-start space-x-2 px-3 py-2" style={{ width: 572, height: 42 }}>
        <p className="text-sm leading-tight text-gray-600">Recent searches</p>
        <div className="flex items-start justify-start space-x-2">
          <div className="flex items-center justify-start rounded-full bg-gray-200 px-2 py-1">
            <p className="text-center text-sm leading-tight text-gray-600">Pro-Choice</p>
          </div>
          <div className="flex items-center justify-start rounded-full bg-gray-200 px-2 py-1">
            <p className="text-center text-sm leading-tight text-gray-600">Ethnicity</p>
          </div>
          <div className="flex items-center justify-start rounded-full bg-gray-200 px-2 py-1">
            <p className="text-center text-sm leading-tight text-gray-600">Unemployement</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
