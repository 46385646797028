interface IconProps {
  fillColor: string;
}

export default ({ fillColor }: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49951 7C6.32794 7 6.99951 6.32843 6.99951 5.5C6.99951 4.67157 6.32794 4 5.49951 4C4.67108 4 3.99951 4.67157 3.99951 5.5C3.99951 6.32843 4.67108 7 5.49951 7ZM5.49951 12.168C6.32794 12.168 6.99951 11.4964 6.99951 10.668C6.99951 9.83954 6.32794 9.16797 5.49951 9.16797C4.67108 9.16797 3.99951 9.83954 3.99951 10.668C3.99951 11.4964 4.67108 12.168 5.49951 12.168ZM10.9995 9.5C10.9995 10.3284 10.3279 11 9.49951 11C8.67108 11 7.99951 10.3284 7.99951 9.5C7.99951 8.67157 8.67108 8 9.49951 8C10.3279 8 10.9995 8.67157 10.9995 9.5ZM14.4995 14C15.3279 14 15.9995 13.3284 15.9995 12.5C15.9995 11.6716 15.3279 11 14.4995 11C13.6711 11 12.9995 11.6716 12.9995 12.5C12.9995 13.3284 13.6711 14 14.4995 14ZM15.9995 7.5C15.9995 8.32843 15.3279 9 14.4995 9C13.6711 9 12.9995 8.32843 12.9995 7.5C12.9995 6.67157 13.6711 6 14.4995 6C15.3279 6 15.9995 6.67157 15.9995 7.5ZM10.4995 16C11.3279 16 11.9995 15.3284 11.9995 14.5C11.9995 13.6716 11.3279 13 10.4995 13C9.67108 13 8.99951 13.6716 8.99951 14.5C8.99951 15.3284 9.67108 16 10.4995 16Z"
      fill={fillColor}
    />
  </svg>
);
