import { AbacusTypes } from '@platform/types';
import { useQuery } from 'react-query';

interface Props {
  datasetId: string;
  api: (datasetId: string, filterableOnly: boolean) => Promise<AbacusTypes.Column[]>;
  filterableOnly?: boolean;
}

const useAbacusColumnsMetadata = ({ datasetId, api, filterableOnly = true }: Props) =>
  useQuery(['abacus-columns-metadata', datasetId, filterableOnly], () => api(datasetId, filterableOnly));

export default useAbacusColumnsMetadata;
