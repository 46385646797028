import React from 'react';

interface Props {
  searchTerm: string;
}

const EmptyColumnsState: React.FC<Props> = ({ searchTerm }) => (
  <div className="mt-16 flex h-full w-full items-start justify-center">
    <div className="flex flex-col items-center gap-10">
      <svg width="200" height="140" viewBox="0 0 200 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.109 87.0898L123.446 106.135C124.634 107.831 124.221 110.169 122.525 111.357V111.357C120.828 112.545 118.49 112.133 117.302 110.436L103.966 91.3915L110.109 87.0898Z"
          fill="#6366F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.43 91.9031C112.461 92.9887 111.532 93.8421 110.645 94.4635C109.757 95.0848 108.638 95.6652 107.286 96.2047L103.845 91.2899L106.916 89.1391L109.988 86.9883L113.43 91.9031Z"
          fill="#4338CA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.7799 48.8008C107.207 48.8008 117.281 58.8744 117.281 71.3008C117.281 83.7272 107.207 93.8008 94.7799 93.8008C82.3526 93.8008 72.2783 83.7272 72.2783 71.3008C72.2783 58.8744 82.3526 48.8008 94.7799 48.8008ZM94.7799 54.8008C85.6666 54.8008 78.2787 62.1881 78.2787 71.3008C78.2787 80.4135 85.6666 87.8008 94.7799 87.8008C103.893 87.8008 111.281 80.4135 111.281 71.3008C111.281 62.1881 103.893 54.8008 94.7799 54.8008Z"
          fill="#6366F1"
        />
        <path
          d="M96.027 75.811C95.6434 75.47 95.1652 75.3008 94.5908 75.3008C94.0163 75.3008 93.5407 75.4709 93.1572 75.811C92.7736 76.153 92.5801 76.5869 92.5801 77.1188C92.5801 77.6508 92.7736 78.0847 93.1572 78.424C93.5407 78.765 94.0189 78.9342 94.5908 78.9342C95.1626 78.9342 95.6434 78.7641 96.027 78.424C96.4105 78.0847 96.6014 77.649 96.6014 77.1188C96.6014 76.5886 96.4105 76.153 96.027 75.811Z"
          fill="#B3B3B2"
        />
        <path
          d="M98.4669 64.0418C97.5905 63.3024 96.3504 62.9336 94.7528 62.9336C93.1552 62.9336 91.8986 63.3345 91.0109 64.132C90.1205 64.9304 89.6684 66.0472 89.6562 67.4826H93.2897C93.3036 66.9445 93.4433 66.5193 93.7097 66.2034C93.9735 65.8876 94.3224 65.7296 94.7528 65.7296C95.6848 65.7296 96.1499 66.272 96.1499 67.355C96.1499 67.7993 96.012 68.2054 95.736 68.5734C95.4592 68.9448 95.0565 69.3509 94.5263 69.7944C93.9952 70.2387 93.6134 70.7637 93.3756 71.3703C93.1396 71.9751 93.0224 72.8013 93.0224 73.8478H96.0979C96.11 73.3028 96.1855 72.8542 96.3226 72.4984C96.4589 72.1435 96.7027 71.7981 97.0533 71.4605L98.2908 70.3098C98.8132 69.8013 99.195 69.3023 99.4319 68.8146C99.6671 68.3278 99.7851 67.7872 99.7851 67.1919C99.7851 65.8312 99.346 64.7794 98.4669 64.0409V64.0418Z"
          fill="#B3B3B2"
        />
        <path
          d="M164.852 101.785C164.852 101.416 163.96 101.093 162.675 100.952C162.306 100.911 162.019 100.624 161.977 100.254C161.831 98.9697 161.514 98.0781 161.145 98.0781C160.775 98.0781 160.452 98.9697 160.312 100.254C160.271 100.624 159.983 100.911 159.614 100.952C158.329 101.099 157.438 101.416 157.438 101.785C157.438 102.155 158.329 102.477 159.614 102.618C159.983 102.659 160.271 102.947 160.312 103.316C160.458 104.601 160.775 105.492 161.145 105.492C161.514 105.492 161.837 104.601 161.977 103.316C162.019 102.947 162.306 102.659 162.675 102.618C163.954 102.477 164.852 102.161 164.852 101.785Z"
          fill="#B3B3B2"
        />
        <path
          d="M161.343 48.4787C161.343 48.0813 160.384 47.7344 159.003 47.583C158.606 47.5388 158.296 47.2298 158.252 46.8324C158.095 45.451 157.754 44.4922 157.357 44.4922C156.959 44.4922 156.612 45.451 156.461 46.8324C156.417 47.2298 156.108 47.5388 155.71 47.583C154.329 47.7407 153.37 48.0813 153.37 48.4787C153.37 48.8761 154.329 49.223 155.71 49.3744C156.108 49.4185 156.417 49.7276 156.461 50.125C156.619 51.5064 156.959 52.4652 157.357 52.4652C157.754 52.4652 158.101 51.5064 158.252 50.125C158.296 49.7276 158.606 49.4185 159.003 49.3744C160.378 49.2167 161.343 48.8761 161.343 48.4787Z"
          fill="#B3B3B2"
        />
        <path
          d="M38.6156 43.4718C38.6156 43.0751 37.6584 42.7288 36.2794 42.5777C35.8827 42.5336 35.5742 42.2251 35.5301 41.8284C35.3727 40.4493 35.0326 39.4922 34.6359 39.4922C34.2392 39.4922 33.8929 40.4493 33.7417 41.8284C33.6977 42.2251 33.3891 42.5336 32.9924 42.5777C31.6134 42.7351 30.6562 43.0751 30.6562 43.4718C30.6562 43.8686 31.6134 44.2149 32.9924 44.366C33.3891 44.4101 33.6977 44.7186 33.7417 45.1153C33.8992 46.4944 34.2392 47.4515 34.6359 47.4515C35.0326 47.4515 35.379 46.4944 35.5301 45.1153C35.5742 44.7186 35.8827 44.4101 36.2794 44.366C37.6584 44.2149 38.6156 43.8749 38.6156 43.4718Z"
          fill="#B3B3B2"
        />
        <path
          d="M163.743 89.1609C164.201 89.1609 164.573 88.7891 164.573 88.3305C164.573 87.8718 164.201 87.5 163.743 87.5C163.284 87.5 162.912 87.8718 162.912 88.3305C162.912 88.7891 163.284 89.1609 163.743 89.1609Z"
          fill="#B3B3B2"
        />
        <path
          d="M125.937 31.4958C126.553 31.4958 127.052 30.9966 127.052 30.3807C127.052 29.7649 126.553 29.2656 125.937 29.2656C125.322 29.2656 124.822 29.7649 124.822 30.3807C124.822 30.9966 125.322 31.4958 125.937 31.4958Z"
          fill="#B3B3B2"
        />
        <path
          d="M107.263 19.6242C108.282 19.6242 109.109 18.798 109.109 17.7789C109.109 16.7598 108.282 15.9336 107.263 15.9336C106.244 15.9336 105.418 16.7598 105.418 17.7789C105.418 18.798 106.244 19.6242 107.263 19.6242Z"
          fill="#B3B3B2"
        />
        <path
          d="M48.2633 112.624C49.2824 112.624 50.1085 111.798 50.1085 110.779C50.1085 109.76 49.2824 108.934 48.2633 108.934C47.2441 108.934 46.418 109.76 46.418 110.779C46.418 111.798 47.2441 112.624 48.2633 112.624Z"
          fill="#B3B3B2"
        />
        <path
          d="M41.468 61.147C42.227 61.147 42.8423 60.5317 42.8423 59.7727C42.8423 59.0137 42.227 58.3984 41.468 58.3984C40.709 58.3984 40.0938 59.0137 40.0938 59.7727C40.0938 60.5317 40.709 61.147 41.468 61.147Z"
          fill="#B3B3B2"
        />
        <path
          d="M148.647 94.772C149.406 94.772 150.021 94.1567 150.021 93.3977C150.021 92.6387 149.406 92.0234 148.647 92.0234C147.888 92.0234 147.272 92.6387 147.272 93.3977C147.272 94.1567 147.888 94.772 148.647 94.772Z"
          fill="#B3B3B2"
        />
      </svg>

      <div className="flex max-w-[260px] gap-1 truncate">
        <span className="text-center text-sm text-gray-700">No results for</span>
        <span className="truncate text-center text-sm font-semibold text-gray-700">{searchTerm}</span>
      </div>
    </div>
  </div>
);

export default EmptyColumnsState;
