import { Menu, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSessionContext } from '../../contexts/SessionContext';
import { generateLink } from '../../LinkGenerator';

interface Props {
  avatarClassNames?: string;
  showManageYourAccount?: boolean;
}

const UserMenu: React.FC<Props> = ({ avatarClassNames = '', showManageYourAccount = true }) => {
  const { user, logout } = useSessionContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="hidden pr-2 md:flex md:flex-shrink-0 md:items-center">
      <Menu
        visible={isOpen}
        onToggleVisibility={setIsOpen}
        trigger={
          <img
            className={`h-[42px] w-[42px] rounded-full border border-white ${avatarClassNames}`}
            src={`${user?.pictureLargeURL}?${user?.updatedAt}`}
            alt={user?.firstName}
            data-cy={'header-user-menu'}
          />
        }
        hAlignment="right"
        menuMargin={12}
        menuClasses="border border-gray-200 mx-2 w-[320px]"
      >
        <div className="flex flex-col gap-1.5">
          <div className="p-6">
            <div className="flex gap-4">
              <div className="flex items-start justify-between">
                <img
                  className="h-[52px] w-[52px] rounded-full "
                  src={`${user?.pictureLargeURL}?${user?.updatedAt}`}
                  alt={user?.firstName}
                />
              </div>
              <div className="flex flex-col items-start justify-center gap-3">
                <div className="flex flex-col gap-1">
                  <p className="text-xs font-semibold">{`${user?.firstName} ${user?.lastName}`}</p>
                  <p className="text-xs font-light">{user?.email}</p>
                </div>
                {showManageYourAccount && (
                  <Link to={generateLink('account')} data-cy={'manage-account-link'}>
                    <div className="text-primary-600 rounded-full border border-gray-300 p-2 px-3 text-xs font-semibold hover:bg-gray-100">
                      Manage your account
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <button
            className="flex cursor-pointer items-center gap-8 border-t border-gray-200 py-3 px-8 text-sm text-gray-800 hover:bg-gray-100"
            onClick={logout}
            data-cy={'log-out-button'}
          >
            <MUIcon name="logout" />
            <p>Sign Out</p>
          </button>
        </div>
      </Menu>
    </div>
  );
};

export default UserMenu;
