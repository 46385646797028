import { Modal } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';

type Inputs = {
  title: string;
};

interface Props {
  onCloseRequest?: () => void;
}

const CreateProjectModal: React.FC<Props> = ({ onCloseRequest }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeWorkspace } = useWorkspaceContext();
  const createProjectMutation = useMutation({
    mutationFn: (payload: PeTypes.CreateProject) => peApi.createProject(payload),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects'] });
      navigate(generateLink('project', { workspaceId: activeWorkspace.id, projectId: id }));
      onCloseRequest?.();
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!data.title) return;

    const { title } = data;
    createProjectMutation.mutate({ title });
  };

  return (
    <Modal
      title="Create new project"
      className="w-[512px]"
      okLabel="Create"
      okDisabled={createProjectMutation.isLoading}
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
          Name
        </label>
        <input
          data-cy="create-project-title-input"
          id="title"
          autoFocus
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Give your project a name"
          required
          {...register('title', { required: 'Name is required' })}
        />
        {errors.title && (
          <span className="text-red-400" data-cy={'create-project-error-msg'}>
            {errors.title.message}
          </span>
        )}
      </form>
    </Modal>
  );
};

export default CreateProjectModal;
