import { Hooks, Utils } from '@platform/maplibre';
import { Abacus } from '@platform/shared/hooks';
import { ProjectTypes } from '@platform/types';
import { LngLatLike, Map, MapGeoJSONFeature } from 'maplibre-gl';
import { useMemo } from 'react';
import * as peApi from '../../../../pe.api';

const { useFeaturesUnderMouse, useFeatureHighlight } = Hooks;

interface Props {
  map: Map | null;
  features: MapGeoJSONFeature[];
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onFeaturesUnderMouse: (wId: string, f: MapGeoJSONFeature[], latLong: LngLatLike | null) => void;
}

const useWorkingLayerTooltipData = ({
  workingLayer,
  map,
  features,
  onFeaturesUnderMouse,
}: Props): [Record<string, unknown>[], boolean] => {
  const workingLayerHash = Utils.getWorkingLayerId(workingLayer);
  const workingLayerIdAsArray = useMemo(() => [workingLayerHash], [workingLayerHash]);

  const tooltip = workingLayer.tooltip as ProjectTypes.WorkingLayerTooltip;
  useFeatureHighlight(map, workingLayerIdAsArray);

  useFeaturesUnderMouse(
    map,
    tooltip.action,
    (featuresInEvent: MapGeoJSONFeature[], latLong) => onFeaturesUnderMouse(workingLayer.id, featuresInEvent, latLong),
    workingLayerIdAsArray
  );

  const columnNamesToLoad: string[] = [];

  if (tooltip.title) {
    columnNamesToLoad.push(tooltip.title.name);
  }

  if (tooltip.content.length) {
    columnNamesToLoad.push(...tooltip.content.map((x) => x.name));
  }

  const joinedIds = features.map((f) => `'${f.properties[ProjectTypes.GEO_ROW_IDENTIFICATOR]}'`).join(',');
  const whereClause = `${ProjectTypes.GEO_ROW_IDENTIFICATOR} in (${joinedIds})`;
  const dataQuery = Abacus.useAbacusData({
    whereClause,
    api: peApi.getAbacusData,
    datasetId: workingLayer.datasetId,
    columnNames: columnNamesToLoad,
    limit: features.length,
    offset: 0,
  });

  const isLoading = dataQuery.isIdle || dataQuery.isLoading;

  return [dataQuery.data ? dataQuery.data.data : [], isLoading];
};

export default useWorkingLayerTooltipData;
