import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import { LogoText } from '../components/shared';

const NoWorkspacePage: React.FC = () => {
  return (
    <div className="h-full w-full">
      <div className=" py-6 px-16">
        <LogoText width={148} height={38} />
      </div>
      <div className="flex h-full w-full justify-center">
        <div className="flex flex-col gap-8">
          <div className="mt-28 h-[404px] w-[460px] rounded-[16px] border border-gray-200">
            <div className="flex flex-col gap-8 py-8 px-10">
              <div className="flex items-center gap-3">
                <MUIcon name="lock" className="stroke-2 text-gray-500" />
                <span className="text-sm font-semibold text-gray-600">Restricted access</span>
              </div>
              <div className="text-2xl font-bold text-gray-800">
                Contact us and let's work together to set up the perfect workspace for you.
              </div>
              <div className="text-gray-600">
                Just one more step — request a workspace to access premium features and unlock your full potential with
                Social Explorer Pro.
              </div>
              <a href="mailto:info@socialexplorer.com">
                <Buttons.Primary className="h-[40px] w-full">Contact us</Buttons.Primary>
              </a>
            </div>
          </div>
          <div className="flex justify-center gap-1 text-sm text-gray-500">
            or,
            <a
              href="https://www.politicalexplorer.com/"
              target="_blank"
              rel="noreferrer"
              className="text-primary-600 font-semibold"
            >
              visit website
            </a>
            to learn more
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoWorkspacePage;
