import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import EmptyState from '../shared/EmptyState';
import ProfileWatchlistItem from './ProfileWatchlistItem';

interface Props {
  profiles: PeTypes.Profile[] | undefined;
  title?: string;
}

const ProfilesWatchlist: React.FC<Props> = ({ title, profiles }) => {
  if (!profiles) {
    return <span className="text-gray-500">Loading maps...</span>;
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-2">
        <div className="flex items-center justify-center gap-2">
          <MUIcon name="bookmark" className="text-gray-600" />
          <div className="text-lg font-bold">{title}</div>
        </div>
      </div>
      <div className="flex flex-grow flex-wrap gap-2">
        {profiles.length === 0 && (
          <EmptyState
            title="Once created, you can add profiles to your watchlist to easily monitor selected content across different projects."
            trigger={<Buttons.Primary>Create a Map</Buttons.Primary>}
            action={PeTypes.Action.NEW_PROFILE}
          />
        )}
        {profiles.map((item) => (
          <ProfileWatchlistItem key={item.id} profile={item} />
        ))}
      </div>
    </div>
  );
};

export default ProfilesWatchlist;
