import { ProjectTypes } from '@platform/types';
import React from 'react';

export interface Props {
  workingLayers: ProjectTypes.WorkingLayerSpec[];
}

const LayersInfo: React.FC<Props> = ({ workingLayers }) => {
  return (
    <>
      {workingLayers.map((layer) => (
        <div key={layer.id} className="flex items-center justify-start gap-3 border-t border-gray-200 py-4">
          <div className="flex items-center justify-center">
            <div className="h-[12px] w-[12px] rounded-xl" style={{ backgroundColor: layer.baseColor }}></div>
          </div>
          <div className="w-62 flex items-center justify-start">
            <span className="whitespace-normal text-xs text-gray-700">{layer.name}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default LayersInfo;
