import { useClipboard } from '@platform/shared/hooks';
import { Buttons, Modal } from '@platform/shared/ui';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import * as peApi from '../pe.api';

interface Props {
  onCloseRequest: () => void;
  urlToShare: string;
}

const ShareAssetModal: React.FC<Props> = ({ onCloseRequest, urlToShare }) => {
  const generateAccessTokenQuery = useMutation({
    mutationFn: () => peApi.generateAnonymousAccessToken(),
  });

  const [copiedText, copyToClipboard] = useClipboard();

  useEffect(() => {
    generateAccessTokenQuery.mutate();
  }, []);

  let shareLink = '';

  if (generateAccessTokenQuery.data) {
    shareLink = `${urlToShare}?aat=${generateAccessTokenQuery.data}`;
  }

  return (
    <Modal
      title="Share link"
      okLabel="Done"
      className="w-[448px]"
      onDone={onCloseRequest}
      onCloseRequest={onCloseRequest}
      hideActions
    >
      {shareLink && <textarea value={shareLink} className="w-full" rows={8} disabled />}
      <Buttons.Primary onClick={() => copyToClipboard(shareLink)}>
        {copiedText ? 'Copied' : 'Copy to clipboard'}
      </Buttons.Primary>
    </Modal>
  );
};

export default ShareAssetModal;
