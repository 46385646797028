import { Abacus as AbacusHooks } from '@platform/shared/hooks';
import {
  Abacus as AbacusUI,
  Buttons,
  Dropdowns,
  LayerFiltering,
  MUIcon,
  PanelHeader,
  Spinner,
} from '@platform/shared/ui';
import { DatasetTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { v4 as uuidv4 } from 'uuid';
import { useDatasetLastPublishedState } from '../../../../hooks';
import * as peApi from '../../../../pe.api';
import ColumnPicker from './ColumnPicker';
import DataFilterEmptyState from './Filters/DataFilterEmptyState';
import WorkingLayerFilterMenu from './Filters/WorkingLayerFilterMenu';
import WorkingLayerNoColumnsState from './Filters/WorkingLayerNoColumnsState';

const { VarCharFilter, NumericFilter, BooleanFilter } = LayerFiltering;

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onFit: (wLayer: ProjectTypes.WorkingLayerSpec) => void;
  onChange: (q: RuleGroupType) => void;
  onCloseRequest: () => void;
}

const INITIAL_QUERY: RuleGroupType = {
  combinator: 'and',
  rules: [],
};

const WorkingDataFilter: React.FC<Props> = ({ workingLayer, onChange, onCloseRequest, onFit }) => {
  const { datasetId, name, filter = INITIAL_QUERY } = workingLayer;
  const [activeRule, setActiveRule] = useState<RuleType | RuleGroupType | null>(null);

  const { query: lastPublishedStateQuery } = useDatasetLastPublishedState({ datasetId });

  const publishedColumns = lastPublishedStateQuery.data?.columns ?? [];
  const abacusColumnsMetadataQuery = AbacusHooks.useAbacusColumnsMetadata({
    api: peApi.getAbacusColumnsMetadata,
    datasetId,
    filterableOnly: true,
  });
  const filterableColumnsCount = abacusColumnsMetadataQuery.data?.length;

  const { rules, combinator } = filter;

  const handleNewRule = () => setActiveRule({ id: uuidv4(), value: null, field: '', operator: '' });

  const handleCombinatorChange = (combinator: string) => {
    const newFilter = structuredClone(filter);
    newFilter.combinator = combinator;
    onChange(newFilter);
  };

  const handleClearAllFilters = () => {
    const newFilter = structuredClone(filter);
    newFilter.rules = [];
    onChange(newFilter);
  };

  const handleRemoveRule = (rule: RuleType | RuleGroupType) => {
    const newFilter = structuredClone(filter);
    newFilter.rules = newFilter.rules.filter((r) => r.id !== rule.id);
    onChange(newFilter);
  };

  const handleFilterChange = (rule: RuleType, operator: string, value: unknown) => {
    const updatedFilter = structuredClone(filter);
    const indexOfRule = rules.findIndex((r) => r.id === rule.id);
    updatedFilter.rules[indexOfRule] = {
      ...rule,
      operator,
      value,
    };
    onChange(updatedFilter);
  };

  const handleRuleColChange = (colKey: string) => {
    const updatedFilter = structuredClone(filter);
    const indexOfRule = rules.findIndex((r) => r.id === activeRule?.id);

    const updatedRule = {
      ...activeRule,
      value: undefined,
      field: colKey,
      operator: '=',
    };

    if (indexOfRule > -1) {
      // updating existing rule
      updatedFilter.rules[indexOfRule] = updatedRule;
    } else {
      // adding new rule
      updatedFilter.rules.push(updatedRule);
    }
    onChange(updatedFilter);
    setActiveRule(null);
  };

  const isLoading =
    lastPublishedStateQuery.isLoading ||
    lastPublishedStateQuery.isIdle ||
    abacusColumnsMetadataQuery.isLoading ||
    abacusColumnsMetadataQuery.isIdle;

  let content;

  switch (true) {
    case isLoading:
      content = (
        <div className="flex w-full items-center">
          <Spinner width={60} height={60} />
        </div>
      );
      break;
    case !filterableColumnsCount:
      content = <WorkingLayerNoColumnsState datasetId={workingLayer.datasetId} />;
      break;
    case !activeRule && !filter?.rules.length:
      content = <DataFilterEmptyState onClick={handleNewRule} />;
      break;
    default:
      content = (
        <>
          <div className="flex w-full flex-col gap-6">
            <div className="flex items-center justify-between gap-6">
              <div className="text-sm text-gray-800">
                <AbacusUI.HitsCount
                  api={peApi.getAbacusRowCount}
                  datasetId={datasetId}
                  dataQuery={filter}
                  suffixLabel={lastPublishedStateQuery.data?.rowSubject}
                />
              </div>
              <Buttons.Link className="text-primary-600 text-sm font-semibold" onClick={handleClearAllFilters}>
                Clear filters
              </Buttons.Link>
            </div>
            <Dropdowns.Dropdown
              onSelect={(opt) => handleCombinatorChange(opt.value as string)}
              options={[
                { value: 'and', name: 'And' },
                { value: 'or', name: 'Or' },
              ]}
              value={combinator}
              triggerWrapperClasses="max-w-fit"
              triggerClasses="text-sm text-gray-700 bg-white border border-gray-300 rounded-lg ring-0 gap-2"
            />
          </div>
          <div className="flex flex-col  py-1">
            {rules
              .filter((rule) => publishedColumns.find((c) => c.name === (rule as RuleType).field))
              .map((rule) => {
                const { field } = rule as RuleType;
                const column = publishedColumns.find((c) => c.name === field);
                if (!column) return null;
                const title = column.label || column.name;

                const abacusColumnMetadata = abacusColumnsMetadataQuery.data?.find((c) => c.name === field);
                switch (column.type) {
                  case DatasetTypes.DataType.BOOLEAN: {
                    return (
                      <div key={rule.id} className="flex flex-col gap-2 py-2">
                        <div className="mx-6 my-1 h-[12px] w-[2px] bg-gray-300"></div>
                        <BooleanFilter
                          key={rule.id}
                          rule={rule as RuleType}
                          title={title}
                          type={column.type}
                          onFilterChange={handleFilterChange}
                          onRemoveRule={handleRemoveRule}
                          onColumnChange={(r) => setActiveRule(r)}
                        />
                      </div>
                    );
                  }
                  case DatasetTypes.DataType.DOUBLE:
                  case DatasetTypes.DataType.INTEGER: {
                    return (
                      <div key={rule.id} className="flex flex-col gap-2 py-2">
                        <div className="mx-6 my-1 h-[12px] w-[2px] bg-gray-300"></div>
                        <NumericFilter
                          key={rule.id}
                          rule={rule as RuleType}
                          title={title}
                          type={column.type}
                          onFilterChange={handleFilterChange}
                          onRemoveRule={handleRemoveRule}
                          onColumnChange={(r) => setActiveRule(r)}
                        />
                      </div>
                    );
                  }
                  case DatasetTypes.DataType.TEXT:
                    return (
                      <div key={rule.id} className="flex flex-col gap-2 py-2">
                        <div className="mx-6 my-1 h-[12px] w-[2px] bg-gray-300"></div>
                        <VarCharFilter
                          key={rule.id}
                          rule={rule as RuleType}
                          title={title}
                          type={column.type}
                          columnDiscreteValues={
                            abacusColumnMetadata?.metadata.values?.filter((x: unknown) => x != null) ?? []
                          }
                          onFilterChange={handleFilterChange}
                          onRemoveRule={handleRemoveRule}
                          onColumnChange={(r) => setActiveRule(r)}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              })}
          </div>
          <div className="py-2">
            <Buttons.Secondary
              className="text-primary-600 h-[36px] w-[148px] gap-2 rounded-[999px] shadow-none ring-gray-200"
              icon={<MUIcon name="add" />}
              onClick={handleNewRule}
            >
              Add criteria
            </Buttons.Secondary>
          </div>
          {activeRule && (
            <ColumnPicker datasetId={datasetId} onSelect={handleRuleColChange} onCloseRequest={onCloseRequest} />
          )}
        </>
      );
  }
  return (
    <div className="relative flex h-full min-h-0 w-full flex-col gap-4">
      <PanelHeader
        title={`Filter "${name}" data`}
        onBack={onCloseRequest}
        onClose={onCloseRequest}
        action={<WorkingLayerFilterMenu workingLayer={workingLayer} onFit={onFit} />}
      />
      <div className="flex flex-col overflow-y-auto px-6 pb-3">{content}</div>
    </div>
  );
};

export default WorkingDataFilter;
