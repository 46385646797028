import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

const fullServerUrl = new URL(process.env.NX_PE_SERVER as string);
const socketPath = process.env.NX_APP_ENV !== 'local' ? '/politicalexplorer/socket.io' : undefined;

const useWebSocket = () => {
  const webSocketRef = useRef<Socket | null>(null);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  const initialize = (jwt: string) => {
    const socket = io(fullServerUrl.origin, {
      path: socketPath,
      reconnectionDelayMax: 10000,
      auth: {
        token: jwt,
      },
    });

    socket.on('connect_error', (e) => {
      console.error('WebSocket.ConnectionError:', e);
    });

    socket.on('connect', () => {
      console.info('WebSocket.Connected');
    });

    webSocketRef.current = socket;
    return socket;
  };

  const close = () => {
    try {
      // if (!webSocketRef.current) return;
      //
      // const { readyState } = webSocketRef.current;
      //
      // if (readyState === WebSocket.OPEN || readyState === WebSocket.CONNECTING) {
      //   webSocketRef.current.close();
      // }
    } catch (e) {
      console.error('useWebSocket.close:', e);
    }
  };

  return { socket: webSocketRef.current, initialize, close };
};

export default useWebSocket;
