import { ProjectTypes } from '@platform/types';
import { useQuery } from 'react-query';
import * as peApi from '../pe.api';

interface Props {
  workspaceId: number | undefined;
  searchTerm: string;
  sortOrder?: 'asc' | 'desc';
  types: ProjectTypes.LayerType[];
  sortBy?: string;
  pageSize?: number;
  pageNumber?: number;
}

export default ({
  workspaceId,
  searchTerm,
  types,
  sortOrder = 'desc',
  sortBy = 'updatedAt',
  pageSize = 99,
  pageNumber = 0,
}: Props) => {
  return useQuery(
    ['datasets', workspaceId, searchTerm, types, sortOrder, sortBy, pageSize, pageNumber],
    () =>
      peApi.getDatasets({
        types,
        sortBy,
        sortOrder,
        searchTerm,
        pageSize,
        pageNumber,
      }),
    { enabled: workspaceId !== null }
  );
};
