import { Dropdowns } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import SlideList from './SlideList';

export enum SlideMenuOptionType {
  DUPLICATE,
  DELETE,
  MOVE_UP,
  MOVE_DOWN,
}

export type SlideMenuActionType = (slide: PeTypes.StorySlide, startId?: string, endId?: string) => void;

export interface SlideMenuOption extends Dropdowns.DropdownOption {
  action: SlideMenuActionType;
}

export default SlideList;
