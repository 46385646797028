import classNames from 'classnames';
import { Point2D } from 'maplibre-gl';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  anchorPoint: Point2D;
  showAnchor: boolean;
  children: React.ReactNode;
}

const TooltipPositioner: React.FC<Props> = ({ anchorPoint, showAnchor, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipElementRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState<Point2D>(anchorPoint);
  const [isAnchorOutOfViewPort, setIsAnchorOutOfViewPort] = useState<boolean>(false);

  const tX = -50;
  const tY = -100;

  useEffect(() => {
    if (!tooltipElementRef.current || !containerRef.current) return;

    const tooltipElementBbox = tooltipElementRef.current.getBoundingClientRect();
    const containerElementBbox = containerRef.current.getBoundingClientRect();
    const { width: tooltipWidth, height: tooltipHeight } = tooltipElementBbox;
    const { width: containerWidth, height: containerHeight } = containerElementBbox;

    const { x: anchorX, y: anchorY } = anchorPoint;

    const checkAnchorPosition = anchorX > 0 && anchorX < containerWidth && anchorY > 0 && anchorY < containerHeight;

    setIsAnchorOutOfViewPort(!checkAnchorPosition);

    // anchor/tooltip is out of position so return at this point
    if (!checkAnchorPosition) return;

    const preferredPositions = ['top', 'right', 'bottom', 'left'];
    let x = null;
    let y = null;

    for (const position of preferredPositions) {
      switch (position) {
        case 'top':
          if (
            checkTooltipWidth(anchorX - tooltipWidth / 2, anchorX + tooltipWidth / 2, containerWidth) &&
            checkTooltipHeight(anchorY - tooltipHeight, anchorY, containerHeight)
          ) {
            x = anchorX;
            y = anchorY;
          }
          break;
        case 'right':
          if (
            checkTooltipWidth(anchorX, anchorX + tooltipWidth, containerWidth) &&
            checkTooltipHeight(anchorY - tooltipHeight / 2, anchorY + tooltipHeight / 2, containerHeight)
          ) {
            x = anchorX + tooltipWidth / 2;
            y = anchorY + tooltipHeight / 2;
          }
          break;
        case 'bottom':
          if (
            checkTooltipWidth(anchorX - tooltipWidth / 2, anchorX + tooltipWidth / 2, containerWidth) &&
            checkTooltipHeight(anchorY, anchorY + tooltipHeight, containerHeight)
          ) {
            x = anchorX;
            y = anchorY + tooltipHeight;
          }
          break;
        case 'left':
          if (
            checkTooltipWidth(anchorX - tooltipWidth, anchorX, containerWidth) &&
            checkTooltipHeight(anchorY - tooltipHeight / 2, anchorY + tooltipHeight / 2, containerHeight)
          ) {
            x = anchorX - tooltipWidth / 2;
            y = anchorY + tooltipHeight / 2;
          }
          break;
        default:
          break;
      }

      if (x != null && y != null) {
        // we hava a position so break the loop at this point
        setTooltipPosition({ x, y });
        break;
      }
    }
  }, [anchorPoint, tooltipElementRef, containerRef]);

  return (
    <div
      className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 z-[99999999] opacity-100"
      ref={containerRef}
    >
      {showAnchor && !isAnchorOutOfViewPort && (
        <div
          className="pulse absolute h-[10px] w-[10px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-gray-300 bg-white"
          style={{ left: anchorPoint.x, top: anchorPoint.y }}
        />
      )}
      <div
        className={classNames('pointer-events-none absolute transition-opacity', {
          'opacity-0 ': isAnchorOutOfViewPort,
        })}
        style={{ left: tooltipPosition.x, top: tooltipPosition.y }}
      >
        <div
          className="pointer-events-auto p-4"
          style={{ transform: `translate(${tX}%, ${tY}%)` }}
          ref={tooltipElementRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default TooltipPositioner;

const checkTooltipWidth = (xLeftValue: number, xRightValue: number, containerWidth: number) =>
  xLeftValue > 0 && xRightValue <= containerWidth;
const checkTooltipHeight = (yTopValue: number, yBottomValue: number, containerHeight: number) =>
  yTopValue >= 0 && yBottomValue <= containerHeight;
