import { useTableMetadata } from '@platform/shared/hooks';
import React from 'react';

export interface Props {
  surveyName: string;
  datasetAbbreviation: string;
  tableUuid: string;
  className?: string;
}

const TableInfo: React.FC<Props> = ({ surveyName, datasetAbbreviation, tableUuid, className }) => {
  const { isLoading, isError, isIdle, data } = useTableMetadata({ surveyName, datasetAbbreviation, tableUuid });
  if (isLoading || isIdle) return <>Loading table meta...</>;
  if (isError) return <>Something went wrong...</>;

  return <span className={className}>{data.title}</span>;
};

export default TableInfo;
