import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../components/shared/ErrorPage';
import PublicStory from '../components/Stories/Public/PublicStory';

const PublicStoryPage: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  if (!storyId) return <ErrorPage />;

  return <PublicStory storyId={storyId} />;
};

export default PublicStoryPage;
