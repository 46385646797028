import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../components/Dashboard/AppHeader';

const Root: React.FC = () => {
  return (
    <div className="flex h-full flex-col bg-slate-50">
      <AppHeader />
      <div className="h-full overflow-auto">
        <div className="mx-auto h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Root;
