import { Buttons } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { ChangeEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../pe.api';

interface Props {
  activeWorkspace: PeTypes.Workspace;
  isAdmin: boolean | undefined;
}

const WorkspaceImage: React.FC<Props> = ({ activeWorkspace, isAdmin }) => {
  const queryClient = useQueryClient();

  const updateWorkspaceImageMutation = useMutation({
    mutationFn: (formData: FormData) => {
      return peApi.updateWorkspaceImage(formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pe-datahub-groups'] });
      queryClient.invalidateQueries({ queryKey: ['workspace', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['workspaces'] });
    },
  });

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      updateWorkspaceImageMutation.mutate(formData);
    }
  };

  return (
    <div className="flex w-full flex-col items-center justify-between gap-8 py-8 px-6">
      <div className="flex w-full items-center justify-start">
        <span className="font-semibold text-gray-800">Workspace Icon/Logo</span>
      </div>
      <div className="flex w-full items-start justify-between">
        <div className="flex w-8/12 gap-6">
          <img
            className="z-1 h-[120px] w-[120px]"
            src={`${activeWorkspace.imageURL}?${activeWorkspace.updatedAt}`}
            alt="Workspace"
          ></img>
          <span className="text-sm text-gray-600">
            Your workspace icon is a way to visually identify this workspace.
          </span>
        </div>
        <div className="relative flex">
          {isAdmin && (
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="absolute h-full w-full cursor-pointer bg-blue-200 opacity-0"
            />
          )}
          {isAdmin && (
            <Buttons.Secondary className="w-[210px] cursor-pointer p-3" disabled={!isAdmin}>
              Update Workspace Icon
            </Buttons.Secondary>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceImage;
