import { DatasetTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { RuleType } from 'react-querybuilder';
import { Dropdowns } from '../index';
import { FilterHeader } from './index';
import InvalidRule from './InvalidFilterRule';
import {
  EQUAL_TO,
  GREATER_OR_EQUAL,
  GREATER_THAN,
  IS_BETWEEN,
  IS_NOT_NULL,
  IS_NULL,
  LESS_OR_EQUAL,
  LESS_THAN,
  NOT_EQUAL_TO,
  OperatorType,
} from './options';

const DIVIDER = ',';

const handleFilterValue = (operator: OperatorType | undefined, fromValue: any, toValue: any) => {
  if (operator !== IS_BETWEEN) {
    const rawValue = fromValue.toString().replaceAll(DIVIDER, '');
    return !rawValue.trim().length || isNaN(rawValue) ? undefined : parseFloat(rawValue);
  }
  return [fromValue, toValue].join(DIVIDER);
};

interface Props {
  rule: RuleType;
  title: string;
  type: string | DatasetTypes.DataType;
  onColumnChange: (rule: RuleType) => void;
  onFilterChange: (rule: RuleType, operator: string, value: unknown) => void;
  onRemoveRule: (rule: RuleType) => void;
}

const NumericFilter: React.FC<Props> = ({ rule, title, type, onColumnChange, onFilterChange, onRemoveRule }) => {
  const [from = '', to = ''] = rule.value ? rule.value.toString().split(',') : [];

  const operatorOptions = [
    EQUAL_TO,
    NOT_EQUAL_TO,
    LESS_THAN,
    LESS_OR_EQUAL,
    GREATER_THAN,
    GREATER_OR_EQUAL,
    IS_BETWEEN,
    IS_NULL,
    IS_NOT_NULL,
  ];
  const currentOperator = operatorOptions.find((option) => option.value === rule.operator);
  const inputFromRef = useRef<HTMLInputElement>(null);
  const inputToRef = useRef<HTMLInputElement>(null);

  const handleBetweenValuesChange = () => {
    const fromValue = inputFromRef.current?.value;
    const toValue = inputToRef.current?.value;

    const value = handleFilterValue(currentOperator, fromValue, toValue);
    onFilterChange(rule, currentOperator ? currentOperator?.value : '', value);
  };

  const handleFilterOperatorChange = (opt: Dropdowns.DropdownOptionValue) => {
    const newOperator = operatorOptions.find((option) => option.value === opt);
    const value = handleFilterValue(newOperator, from, to);
    onFilterChange(rule, newOperator ? newOperator?.value : '', value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    handleBetweenValuesChange();
  };

  const isNullishOperator = currentOperator === IS_NULL || currentOperator === IS_NOT_NULL;
  const isValid = Helpers.checkIfValidRule(rule, null);

  return (
    <div key={rule.id}>
      <div
        className={classNames('flex flex-col gap-3 break-all rounded-lg border border-gray-200 bg-gray-100 p-3', {
          'rounded-bl-none rounded-br-none': !isValid,
        })}
      >
        <FilterHeader
          handleColumnChange={() => onColumnChange(rule)}
          handleRemoveRule={() => onRemoveRule(rule)}
          title={title}
          type={type}
        />
        <div className="flex justify-between gap-2">
          <div className="flex min-w-0 grow basis-1/3">
            <Dropdowns.Dropdown
              customLabel={currentOperator?.name}
              onSelect={(opt) => handleFilterOperatorChange(opt.value)}
              options={operatorOptions}
              menuWidth="full"
              triggerWrapperClasses="w-full"
              triggerClasses="bg-white w-full ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200 truncate"
            />
          </div>
          {!isNullishOperator && (
            <div className="flex basis-2/3 gap-2">
              <input
                disabled={currentOperator === IS_NULL || currentOperator === IS_NOT_NULL}
                defaultValue={from ?? ''}
                ref={inputFromRef}
                className="w-full rounded-lg text-sm text-gray-800 ring-1 ring-gray-200"
                type="number"
                onKeyDown={handleKeyPress}
                onBlur={handleBetweenValuesChange}
              />
              {currentOperator === IS_BETWEEN && (
                <>
                  <div className="flex items-center">-</div>
                  <input
                    defaultValue={to ?? ''}
                    ref={inputToRef}
                    className="w-full rounded-lg text-sm text-gray-800 ring-1 ring-gray-200"
                    type="number"
                    onKeyDown={handleKeyPress}
                    onBlur={handleBetweenValuesChange}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!isValid && <InvalidRule />}
    </div>
  );
};

export default NumericFilter;
