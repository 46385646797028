import { Buttons, MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';
import GeoSelectionSummary from '../../InfoPanel/GeoSelectionSummary';
import { useMapSidebarContext } from '../MapSideBarContext';

interface Props {
  mapObj: ProjectTypes.MapObject;
  isEditMode: boolean;
}

const AnalysisGeoSelection: React.FC<Props> = ({ mapObj, isEditMode }) => {
  const { changeActiveView } = useMapSidebarContext();
  const { styleSpec } = mapObj;
  const { geoFeatureSelection, dataLayer } = styleSpec;

  const hasGeoSelection = !!geoFeatureSelection?.length;
  if (!hasGeoSelection) return null;

  return (
    <div className="flex w-full items-start gap-3 border-b-[1px] border-b-gray-200 px-6 pb-4">
      <MUIcon name="location_on" className="bg-primary-100 text-primary-600 rounded-full p-1 text-[18px]" />
      <GeoSelectionSummary
        geoFeatures={geoFeatureSelection}
        surveyName={dataLayer.selection[0].surveyName}
        isUserSurvey={!!dataLayer.selection[0].isUserSurvey}
      />
      {isEditMode && (
        <Buttons.Link className="text-primary-600 p-0" onClick={() => changeActiveView('SEARCH')}>
          Change
        </Buttons.Link>
      )}
    </div>
  );
};

export default AnalysisGeoSelection;
