import { Modal } from '@platform/shared/ui';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as peApi from '../../../pe.api';

type Inputs = {
  title: string;
};

interface Props {
  datasetId: string;
  datasetName: string;
  onCloseRequest: () => void;
}

const RenameDatasetModal: React.FC<Props> = ({ onCloseRequest, datasetId, datasetName }) => {
  const renameDatasetMutation = useMutation({
    mutationFn: async (newTitle: string) => peApi.renameDataset(datasetId, { newTitle, oldTitle: datasetName }),
    onSuccess: async () => {
      onCloseRequest?.();
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { title: datasetName } });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!data.title) return;

    const { title } = data;
    renameDatasetMutation.mutate(title);
  };

  return (
    <Modal
      title="Rename dataset"
      okLabel="Save"
      okDisabled={renameDatasetMutation.isLoading}
      className="w-[448px]"
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="mb-8">
        <label htmlFor="title" className="mb-2 block text-sm font-semibold text-gray-900">
          Name
        </label>
        <input
          id="title"
          autoFocus
          className="focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 outline-none"
          placeholder="e.g. Dataset Name"
          required
          {...register('title', { required: 'Dataset name is required' })}
        />
        {errors.title && <span className="text-red-400">{errors.title.message}</span>}
      </form>
    </Modal>
  );
};

export default RenameDatasetModal;
