import { PeTypes } from '@platform/types';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React from 'react';
import { useQuery } from 'react-query';
import { useSessionContext } from '../../contexts/SessionContext';
import * as peApi from '../../pe.api';
import { Loader } from '../shared';
import ExportLoader from './ExportLoader';
import MapExport from './MapExport';

interface Props {
  profileId: string;
  includeLegend: boolean;
  imageDimension: PeTypes.ImageDim;
  onCloseRequest: () => void;
}

const ProfileMapExport: React.FC<Props> = ({ profileId, onCloseRequest, includeLegend, imageDimension }) => {
  const { jwtToken } = useSessionContext();
  const profileDataQuery = useQuery(['profile', profileId], () => peApi.getProfile(profileId));
  if (profileDataQuery.isIdle || profileDataQuery.isLoading) return <Loader />;
  if (profileDataQuery.isError) return <>Something is wrong...</>;

  const { title, mapContextId } = profileDataQuery.data;
  const handleExportDone = async (mapExport: string | undefined, legendExport: string | undefined) => {
    if (!mapExport) return;
    const zip = new JSZip();

    const zipFolder = zip.folder(title);

    zipFolder?.file(`map.png`, mapExport.slice('data:image/png;base64,'.length), { base64: true });

    if (legendExport) {
      zipFolder?.file(`legend.png`, legendExport.slice('data:image/png;base64,'.length), { base64: true });
    }

    const blob = await zip.generateAsync({ type: 'blob' });
    saveAs(blob, `${title}.zip`);

    onCloseRequest();
  };

  return (
    <div key={profileId}>
      <ExportLoader>Exporting map. This might take a while.</ExportLoader>
      <div className="fixed top-[9999px] right-[9999px] z-0 flex h-[0px] w-[0px] flex-col bg-white">
        <MapExport
          title={title}
          mapContextId={mapContextId}
          onDone={handleExportDone}
          includeLegend={includeLegend}
          imageDimension={imageDimension}
          jwtToken={jwtToken}
        />
      </div>
    </div>
  );
};

export default ProfileMapExport;
