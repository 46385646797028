import { Buttons, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import CreateProjectModal from './CreateProjectModal';

const CreateProjectButton: React.FC = () => {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  return (
    <div className="items-top">
      <Buttons.Secondary onClick={() => setModalIsVisible(true)} icon={<MUIcon name="add" />} />
      {modalIsVisible && <CreateProjectModal onCloseRequest={() => setModalIsVisible(false)} />}
    </div>
  );
};

export default CreateProjectButton;
