import React, { useEffect, useRef, useState } from 'react';
import tw from 'tailwind-styled-components';
import * as peApi from '../../pe.api';

import { Inputs } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';

interface Props {
  mapObj: ProjectTypes.MapObject;
  allowMultiple: boolean;
  selectedGeoFeatureIds: Array<string>;
  onSelect: (featureSelection: ProjectTypes.GeoFeatureSelection, record: PeTypes.GeoRecord) => void;
  defaultGbGeoRecordSearchIndex: string | undefined;
}

const Search: React.FC<Props> = ({ mapObj, onSelect, selectedGeoFeatureIds, defaultGbGeoRecordSearchIndex }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [geoRecords, setGeoRecords] = useState<PeTypes.GeoRecord[]>([]);

  const { preferredSummaryLevelId } = mapObj.styleSpec.dataLayer;

  const [shouldShowResults, setShouldShowResults] = useState(false);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShouldShowResults(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (searchTerm.length > 2) {
      const handleDelayedAction = async () => {
        try {
          const data = await peApi.getGeoRecords({
            searchTerm,
            activeSummaryLevelId: preferredSummaryLevelId,
            defaultGbGeoRecordSearchIndex,
          });
          setGeoRecords(data);
          setShouldShowResults(true);
        } catch (e) {
          console.error(e);
        }
      };

      const timer = setTimeout(() => {
        handleDelayedAction().catch(console.error);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setGeoRecords([]);
    }
  }, [searchTerm, preferredSummaryLevelId]);

  return (
    <div className="relative flex w-full flex-grow items-center" ref={wrapperRef}>
      <div className="relative w-full">
        <Inputs.Input
          value={searchTerm}
          onClick={() => setShouldShowResults(true)}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search to select a place..."
        />
        {!!geoRecords.length && shouldShowResults && (
          <div className="absolute z-10 mt-1 w-full overflow-hidden rounded-lg bg-white p-1 shadow-xl">
            {geoRecords.map((record) => {
              const isItemSelected = selectedGeoFeatureIds.find((el) => el === record.id) != null;

              return (
                <div
                  key={record.id}
                  className="flex w-full cursor-pointer items-center rounded-lg p-1 py-3 text-sm text-gray-600 hover:bg-gray-100"
                  onClick={() => {
                    onSelect({ fips: record.id, label: record.label, summaryLevelId: preferredSummaryLevelId }, record);
                    setShouldShowResults(false);
                  }}
                >
                  <ListItem $selected={isItemSelected}>{record.label}</ListItem>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const ListItem = tw.div<{ $selected?: boolean }>`
  px-2
  ${(p) => (p.$selected ? 'cursor-text font-semibold' : '')}
`;

export default Search;
