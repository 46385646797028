import { metadataApi } from '@platform/api';
import { MetadataTypes, ProjectTypes } from '@platform/types';
import { Helpers, ValueFormatter } from '@platform/utils';
import * as turf from '@turf/turf';
import { hex, scale, valid } from 'chroma-js';
import crc32 from 'crc/crc32';
import * as GeoJSON from 'geojson';
import * as maplibre from 'maplibre-gl';
import maplibregl, {
  CircleLayerSpecification,
  ColorSpecification,
  DataDrivenPropertyValueSpecification,
  ExpressionFilterSpecification,
  ExpressionSpecification,
  FillLayerSpecification,
  FilterSpecification,
  LayerSpecification,
  LineLayerSpecification,
  LngLat,
  Map,
  StyleSpecification,
} from 'maplibre-gl';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { SELECTION_KEY_PROP } from './MapsContextMap';

type AllowedLayerSpecification = CircleLayerSpecification | FillLayerSpecification | LineLayerSpecification;
export const DEFAULT_OPACITIES = [0.5, 0.5];

export const getDataLayerFingerprint = (dataLayer: ProjectTypes.DataLayerSpec) => `${crc32(JSON.stringify(dataLayer))}`;

export const getWorkingLayerFingerprint = (workingLayer: ProjectTypes.WorkingLayerSpec) => {
  const { datasetId, filter, geoLayerId } = workingLayer;
  return `${crc32(JSON.stringify({ datasetId, filter, geoLayerId }))}`;
};

export const getWorkingLayerId = (workingLayer: ProjectTypes.WorkingLayerSpec) =>
  `${ProjectTypes.MapTileSource.USER_UPLOAD_SOURCE}:${getWorkingLayerFingerprint(workingLayer)}:${workingLayer.id}`;

export const getFilteredOutDataLayerId = (dataLayer: ProjectTypes.DataLayerSpec) =>
  `socialexplorer-dataFilteredOut-${crc32(JSON.stringify(dataLayer))}`;

const COLOR_PALETTES_PER_VISUALIZATION_TYPE = {
  [ProjectTypes.VisualizationType.SHADED]: {
    single: ['polygon-sequential', 'polygon-diverging'],
    multi: ['MULTI-POLYGON'],
  },
  [ProjectTypes.VisualizationType.BUBBLES]: { single: ['bubble-single-color'], multi: [] },
  [ProjectTypes.VisualizationType.DOT_DENSITY]: { single: [], multi: [] },
};

const INSUFFICIENT_DATA_CUT_POINT = { label: 'No data', color: '#C0C0C0' };

const FALLBACK_PERCENT_BASED_CUT_POINT_SET: ProjectTypes.CutPointSet = {
  insufficientData: INSUFFICIENT_DATA_CUT_POINT,
  cutPoints: [
    { title: '< 5%', displayInLegend: true, from: -Number.MAX_VALUE, to: 5 },
    { title: '5% to 10%', displayInLegend: true, from: 5, to: 10 },
    { title: '10% to 25%', displayInLegend: true, from: 10, to: 25 },
    { title: '25% to 40%', displayInLegend: true, from: 25, to: 40 },
    { title: '40% to 60%', displayInLegend: true, from: 40, to: 60 },
    { title: '60% to 75%', displayInLegend: true, from: 60, to: 75 },
    { title: '75% to 90%', displayInLegend: true, from: 75, to: 90 },
    { title: '> 90%', displayInLegend: true, from: 90, to: Number.MAX_VALUE },
  ],
};

const FALLBACK_COUNT_BASED_CUT_POINT_SET: ProjectTypes.CutPointSet = {
  insufficientData: INSUFFICIENT_DATA_CUT_POINT,
  cutPoints: [
    { title: '< 50', displayInLegend: true, from: Number.MIN_VALUE, to: 50 },
    { title: '50 to 500', displayInLegend: true, from: 50, to: 500 },
    { title: '500 to 1,000', displayInLegend: true, from: 500, to: 1000 },
    { title: '1,000 to 3,000', displayInLegend: true, from: 1000, to: 3000 },
    { title: '3,000 to 5,000', displayInLegend: true, from: 3000, to: 5000 },
    { title: '5,000 to 7,000', displayInLegend: true, from: 5000, to: 7000 },
    { title: '7,000 to 15,000', displayInLegend: true, from: 7000, to: 15000 },
    { title: '> 15,000', displayInLegend: true, from: 15000, to: Number.MAX_VALUE },
  ],
};

export const mergeCanvases = (
  canvases: HTMLCanvasElement[],
  mode: 'single' | 'side' | 'side-locked' | 'swipe'
): HTMLCanvasElement => {
  // Create a new canvas
  const mergedCanvas = document.createElement('canvas');
  const ctx = mergedCanvas.getContext('2d');
  if (!ctx) {
    throw new Error('Unable to get 2D context');
  }

  const DIVIDER_WIDTH = 2;
  switch (mode) {
    case 'side':
    case 'side-locked':
      mergedCanvas.width = canvases.reduce((p, c) => p + c.width, 0) + canvases.length * DIVIDER_WIDTH;
      break;
    case 'single':
    default:
      mergedCanvas.width = Math.max(...canvases.map((c) => c.width));
      break;
  }

  mergedCanvas.height = Math.max(...canvases.map((c) => c.height));

  // Set the fill color
  ctx.fillStyle = 'rgb(51,65,85)';

  // Draw a rectangle that covers the entire canvas
  ctx.fillRect(0, 0, mergedCanvas.width, mergedCanvas.height);

  canvases.forEach((c, idx) => {
    if (mode === 'side' || mode === 'side-locked') {
      const dX = idx * (c.width + DIVIDER_WIDTH);
      ctx.drawImage(c, dX, 0);
    } else if (mode === 'swipe') {
      if (idx === 0) {
        ctx.drawImage(c, 0, 0);
      } else {
        const dX = c.width / (idx + 1);
        ctx.drawImage(c, dX, 0, dX, c.height, dX, 0, dX, mergedCanvas.height);
        // Draw a rectangle that covers the entire canvas
        ctx.fillRect(dX, 0, DIVIDER_WIDTH, mergedCanvas.height);
      }
    } else {
      // single
      ctx.drawImage(c, 0, 0);
    }
  });

  return mergedCanvas;
};

export const getThumbCanvas = async (map: Map): Promise<HTMLCanvasElement | null> =>
  new Promise((resolve) => {
    const getCanvas = (map: Map) => {
      try {
        const canvas = map.getCanvas();
        const newCanvas = document.createElement('canvas');

        newCanvas.width = canvas.width;
        newCanvas.height = canvas.height;

        const ctx = newCanvas.getContext('2d');
        ctx?.drawImage(canvas, 0, 0);

        return newCanvas;
      } catch {
        console.warn("Failed to create dataUrl of map's canvas");
        return null;
      }
    };
    if (!map) {
      console.warn('There is no map. Returning empty canvas');
      return null;
    }

    if (map.isStyleLoaded()) {
      resolve(getCanvas(map));
    } else {
      const handleMapIdle = () => {
        map.off('idle', handleMapIdle);
        resolve(getCanvas(map));
      };
      map.on('idle', handleMapIdle);
    }
  });

export const getPaintStops = (
  r: ProjectTypes.CutPointSet
): [number | undefined | string, string | undefined, boolean][] => {
  const paintStops: [number | undefined | string, string | undefined, boolean][] = [];

  if (r.insufficientData) {
    paintStops.push([undefined, toCSSColor(r.insufficientData.color), false]);
  }
  r.cutPoints.forEach((cp) => {
    paintStops.push(
      cp.to != null && cp.from != null
        ? [cp.to, toCSSColor(cp.color), cp.to === cp.from]
        : [undefined, toCSSColor(cp.color), cp.to === cp.from]
    );
  });

  return paintStops;
};

export const toCSSColor = (value: string | undefined, alpha = 1) => {
  if (!value || !valid(value)) return undefined;

  try {
    return hex(value).alpha(alpha).css();
  } catch {
    return value;
  }
};

export const figureOutDataTheme = async (
  selection: ProjectTypes.VariableSelection[],
  colorPalettes: MetadataTypes.ColorPalette[],
  systemCategoryFilters: MetadataTypes.SystemCategoryFilters[],
  defaultColorPalette?: {
    id: string;
    type: string;
  }
): Promise<
  | {
      type: ProjectTypes.VisualizationType;
      colorPalette: {
        id: string;
        type: string;
      };
      rendering: ProjectTypes.CutPointSet[];
      percentBasedVisualization: boolean;
    }
  | undefined
> => {
  const [firstSelectedVariable] = selection;
  const { variableMetadata, tableMetadata } = await getSelectionMetadata(firstSelectedVariable);
  if (!tableMetadata || !variableMetadata) {
    console.warn('metadata not available. Exiting...');
    return;
  }

  const percentBasedVisualization =
    variableMetadata.dataType !== 'String' && firstSelectedVariable.universeVariableUuid != null;

  const type = ProjectTypes.VisualizationType.SHADED;

  const applicableColorPalettes = getApplicableColorPalettes({
    colorPalettes,
    isMultiSelection: selection.length > 1,
    visualizationType: type,
  });

  // use the default palette
  let colorPaletteToUse = applicableColorPalettes.find(
    (cp) => cp.id === defaultColorPalette?.id && cp.type === defaultColorPalette?.type
  );

  // but check if the selected variable requires a palette specified by its metadata
  if (variableMetadata.suggestedPaletteName && variableMetadata.suggestedPaletteType) {
    colorPaletteToUse = applicableColorPalettes.find(
      (cp) =>
        cp.id === variableMetadata.suggestedPaletteName &&
        cp.type.toLowerCase() === `polygon-${variableMetadata.suggestedPaletteType}`.toLowerCase()
    );
  }

  if (!colorPaletteToUse) {
    colorPaletteToUse = applicableColorPalettes[0];
  }

  const suggestedFilterSetName = variableMetadata.defaultFilterSetName || tableMetadata.defaultFilterSetName;

  let cutPointSetToUse = percentBasedVisualization
    ? FALLBACK_PERCENT_BASED_CUT_POINT_SET
    : FALLBACK_COUNT_BASED_CUT_POINT_SET;

  if (suggestedFilterSetName) {
    const suggestedFilterSets = systemCategoryFilters.find((x) => x.name === suggestedFilterSetName);
    if (suggestedFilterSets?.filterSets?.length) {
      if (variableMetadata.dataType === 'String' || variableMetadata.varType === 'None') {
        cutPointSetToUse = {
          insufficientData: INSUFFICIENT_DATA_CUT_POINT,
          cutPoints: suggestedFilterSets.filterSets[0].filters.map((f) => ({
            title: f.label || f.valueStr || f.valueNum || 'N/A',
            to: f.valueStr || f.valueNum,
            from: f.valueStr || f.valueNum,
            displayInLegend: true,
          })),
        };
      } else if (variableMetadata.indent > 0) {
        const filterSetToUse =
          suggestedFilterSets?.filterSets.find((f) => f.filters.length >= 8) ?? suggestedFilterSets.filterSets[0];

        if (filterSetToUse) {
          cutPointSetToUse = {
            insufficientData: INSUFFICIENT_DATA_CUT_POINT,
            cutPoints: filterSetToUse.filters.map((f, idx) => {
              let title;
              if (idx === 0) {
                // first
                title = `< ${f.to}%`;
              } else if (idx === filterSetToUse.filters.length - 1) {
                // last
                title = `> ${f.from}%`;
              } else {
                // somewhere in the middle
                title = `${f.from}% to ${f.to}%`;
              }

              return {
                title,
                to: f.to,
                from: f.from,
                displayInLegend: true,
              };
            }),
          };
        }
      }
    }
  }

  const rendering = getRenderingRules(colorPaletteToUse, cutPointSetToUse, selection);

  return {
    type,
    rendering,
    colorPalette: { id: colorPaletteToUse.id, type: colorPaletteToUse.type },
    percentBasedVisualization,
  };
};

const getApplicableColorPalettes = ({
  colorPalettes,
  isMultiSelection,
  visualizationType,
}: {
  colorPalettes: MetadataTypes.ColorPalette[];
  isMultiSelection: boolean;
  visualizationType: ProjectTypes.VisualizationType;
}) => {
  const retVal: MetadataTypes.ColorPalette[] = [];
  COLOR_PALETTES_PER_VISUALIZATION_TYPE[visualizationType][isMultiSelection ? 'multi' : 'single'].forEach(
    (colorPaletteType) => {
      retVal.push(...colorPalettes.filter((cp) => cp.type === colorPaletteType));
    }
  );
  return retVal;
};

const getRenderingRules = (
  colorPalette: MetadataTypes.ColorPalette,
  cutPointSet: ProjectTypes.CutPointSet,
  selection: ProjectTypes.VariableSelection[],
  defaultRenderingOptions?: ProjectTypes.CutPointSet[]
): ProjectTypes.CutPointSet[] => {
  const renderingOptions: ProjectTypes.CutPointSet[] =
    defaultRenderingOptions ?? Array(selection.length).fill(cutPointSet);

  const renderingRules: ProjectTypes.CutPointSet[] = [];

  const isMultiVariable = selection.length > 1;

  if (isMultiVariable) {
    selection.forEach((_, idx) => {
      const selectionRendering = renderingOptions[idx];
      const cRamp = colorPalette['color-ramps'][idx];
      const colorsToApply = scale([cRamp.from, cRamp.to])
        .domain([cRamp.bias, 1])
        .colors(selectionRendering.cutPoints.length + 2);

      const cutPoints: ProjectTypes.CutPoint[] = selectionRendering.cutPoints.map((cp, idx) => ({
        ...cp,
        color: colorsToApply[idx],
      }));

      renderingRules.push({ ...selectionRendering, cutPoints });
    });
  } else {
    const cutPoints: ProjectTypes.CutPoint[] = [];
    const colorsToApply = interpolateColors(colorPalette, renderingOptions[0].cutPoints.length);

    cutPoints.push(...renderingOptions[0].cutPoints.map((cp, idx) => ({ ...cp, color: colorsToApply[idx] })));
    renderingRules.push({ ...renderingOptions[0], cutPoints });
  }

  return renderingRules;
};

const getSelectionMetadata = async (selection: ProjectTypes.VariableSelection) => {
  try {
    const tableMetadata = await metadataApi.loadTable(
      selection.surveyName,
      selection.datasetAbbrev,
      selection.tableGuid
    );
    if (!tableMetadata) return {};

    const variableMetadata = tableMetadata.variables.find((x) => x.uuid === selection.variableGuid);
    if (!variableMetadata) return {};

    return { tableMetadata, variableMetadata };
  } catch (error) {
    console.warn('cant load meta', error);
    return {};
  }
};

export const interpolateColors = (colorPalette: MetadataTypes.ColorPalette, count: number): string[] => {
  const { colors, type, ['color-ramps']: colorRamps } = colorPalette;
  if (colors.length > 0) {
    const colorsToUse: string[] = [];
    for (let i = 0; i < count; i += 1) {
      colors.push(colorsToUse[i % colors.length]);
    }
    return colorsToUse;
  }

  if (colorRamps.length > 0) {
    let bias = 0;
    const colorsInRamp: string[] = [];
    const domain: number[] = [];

    switch (type) {
      case 'polygon-diverging': {
        // TODO: needs more tweaking for sure
        const firstRamp = colorRamps[0];
        const lastRamp = colorRamps[colorRamps.length - 1];
        colorsInRamp.push(firstRamp.from, firstRamp.to);
        colorsInRamp.push(lastRamp.from, lastRamp.to);
        domain.push(0);
        domain.push(1);
        break;
      }
      case 'polygon-sequential':
      default: {
        colorRamps.forEach((colorRamp, colorRampIndex) => {
          colorsInRamp.push(colorRamp.from, colorRamp.to);
          const rampBias = colorRamp.bias === 0 ? (colorRampIndex + 1) / colorRamps.length : colorRamp.bias;
          domain.push(bias, bias + rampBias);
          bias += colorRamp.bias;
        });
      }
    }
    return scale(colorsInRamp).domain(domain).colors(count);
  }
  return [];
};

export const figureOutCutPoints = async (
  selection: ProjectTypes.VariableSelection[],
  steps: number[],
  colorPalette: MetadataTypes.ColorPalette
): Promise<ProjectTypes.CutPointSet[] | undefined> => {
  const [firstSelectedVariable] = selection;
  const { variableMetadata } = await getSelectionMetadata(firstSelectedVariable);

  if (!variableMetadata) {
    console.warn('metadata not available. Exiting...');
    return;
  }

  const valueFormat = selection[0].universeVariableUuid
    ? ProjectTypes.ValueFormat.FORMAT_PERCENT_1_DECIMAL
    : (variableMetadata.formatting as ProjectTypes.ValueFormat);

  const cutPointSetToUse: ProjectTypes.CutPointSet = {
    insufficientData: INSUFFICIENT_DATA_CUT_POINT,
    cutPoints: steps.map((step, idx) => {
      let title, to, from;
      if (idx === 0) {
        // first
        from = -Number.MAX_VALUE;
        to = step;
        title = `< ${ValueFormatter.format(to, valueFormat)}`;
      } else if (idx === steps.length - 1) {
        // last
        from = step;
        to = Number.MAX_VALUE;
        title = `> ${ValueFormatter.format(from, valueFormat)}`;
      } else {
        // somewhere in the middle
        from = step;
        to = steps[idx + 1];
        title = `${ValueFormatter.format(from, valueFormat)} to ${ValueFormatter.format(to, valueFormat)}`;
      }

      return {
        title,
        to,
        from,
        displayInLegend: true,
      };
    }),
  };

  return getRenderingRules(colorPalette, cutPointSetToUse, selection);
};

export const calculateEquidistantSteps = (values: number[], maxNumberOfBuckets: number): number[] => {
  const sortedValues = values.sort((a, b) => a - b);

  /**
   *  If the number of values in the input array is less than or equal to the specified,
   * then return sorted array
   * */
  if (values.length <= maxNumberOfBuckets) {
    return sortedValues;
  }

  /**
   * In this step, the initial centroids are generated. It divides the sorted values
   * into maxNumberOfBuckets equidistant intervals and selects a value from each
   *  interval as a centroid.
   */
  let centroids: number[] = [];
  for (let i = 0; i < maxNumberOfBuckets; i++) {
    const index = Math.floor((i / (maxNumberOfBuckets - 1)) * (sortedValues.length - 1));
    centroids.push(sortedValues[index]);
  }

  /**
   * This loop does the K-Means clustering process.
   * In each iteration, the centroids are recalculated
   * based on the current assignment of values to clusters.
   * For each value in the sorted input values, the code iterates
   * through the current centroids to find the one with the minimum
   * distance to the value. It assigns the value to the cluster associated
   * with that centroid.
   * */
  for (let iteration = 0; iteration < maxNumberOfBuckets; iteration++) {
    const clusters: number[][] = Array.from({ length: maxNumberOfBuckets }, () => []);

    for (const value of sortedValues) {
      let minDistance = Infinity;
      let clusterIndex = 0;

      for (let i = 0; i < centroids.length; i++) {
        const distance = Math.abs(value - centroids[i]);
        if (distance < minDistance) {
          minDistance = distance;
          clusterIndex = i;
        }
      }

      clusters[clusterIndex].push(value);
    }

    /**
     * After all values are assigned to clusters, new centroids are calculated by
     * taking the mean of the values in each cluster. This step repositions
     * the centroids based on the current assignments.
     * */
    const newCentroids = clusters.map((cluster) => cluster.reduce((a, b) => a + b, 0) / cluster.length);

    /**
     * The newly calculated centroids are updated with a fixed precision of 5
     * decimal places to avoid floating-point precision issues. This precision
     * control helps ensure that the centroids converge to stable values.
     */
    centroids = newCentroids.map((centroid) => parseFloat(centroid.toFixed(5)));
  }

  return centroids;
};

interface Props {
  styleSpec: ProjectTypes.MapSpec;
  sourceLayer: MetadataTypes.MetadataSocialExplorerDataSourceLayer | undefined;
  defaultSourceLayer: MetadataTypes.MetadataSocialExplorerDataSourceLayer | undefined;
  baseMapStyle: StyleSpecification;
}

export const getMapStyle = ({
  styleSpec,
  sourceLayer,
  defaultSourceLayer,
  baseMapStyle = { sources: {}, layers: [], version: 8 },
}: Props): StyleSpecification => {
  const { geoFeatureSelection = [], dataLayer } = styleSpec;
  const { selection = [] } = dataLayer;

  const layersToInsert: LayerSpecification[] = [];

  const mapStyle: StyleSpecification = JSON.parse(JSON.stringify(baseMapStyle));

  const layers = baseMapStyle.layers;

  let firstSymbolId = '';

  // Find the index of the first symbol layer in the map style
  for (const item of layers) {
    if (item.type === 'symbol') {
      firstSymbolId = item.id;
      break;
    }
  }

  const firstSymbolLayer = firstSymbolId
    ? mapStyle.layers.findIndex((x) => x.id === firstSymbolId)
    : mapStyle.layers.length;

  if (dataLayer.selection?.length && sourceLayer) {
    const [firstSelectedVariable] = selection;
    const columns = selection.map((v) => v.variableGuid.trim());

    if (firstSelectedVariable.universeVariableUuid != null) {
      columns.push(firstSelectedVariable.universeVariableUuid.trim());
    }

    const dataLayerFingerprint = getDataLayerFingerprint(dataLayer);
    const socialExplorerSourceId = `${ProjectTypes.MapTileSource.SOCEX_SOURCE}-${dataLayerFingerprint}`;

    const tileServerBase = firstSelectedVariable.isUserSurvey
      ? process.env.NX_USER_TILES_SERVER
      : process.env.NX_SOCEX_TILES_SERVER;

    // add data source (socex or user)
    mapStyle.sources[socialExplorerSourceId] = {
      type: 'vector',
      tiles: [
        `${tileServerBase}/?x={x}&y={y}&z={z}&layers={layers}&columns={columns}&projection=EPSG-3857&${ProjectTypes.MapTileSource.SOCEX_SOURCE}`,
      ],
    };

    const MISSING_DATA_COLOR = 'rgb(125,125,125)';
    const DEFAULT_COLOR = 'rgb(125,125,125)';

    const casesPerRendering = selection.map((x, idx) => {
      const isComputed = x.universeVariableUuid != null;

      const value = isComputed
        ? ['/', ['get', x.variableGuid], ['get', x.universeVariableUuid]]
        : ['get', x.variableGuid];

      const colorStops = getPaintStops(dataLayer.rendering[idx]);

      const paintFillColorCases: unknown[] = colorStops.reduce((p: unknown[], c) => {
        const [stopValue, stopColor, isEqualOperator] = c;

        if (stopValue == null) {
          return [...p, ['==', ['get', x.variableGuid], null], stopColor];
        }
        if (isEqualOperator) {
          return [...p, ['==', ['get', x.variableGuid], stopValue], stopColor];
        }

        return [...p, ['<=', value, isComputed ? Number(stopValue) / 100 : stopValue], stopColor];
      }, []);

      paintFillColorCases.push(MISSING_DATA_COLOR);
      return ['case', ...paintFillColorCases];
    });

    const fillColorExpression =
      selection.length === 1
        ? casesPerRendering[0]
        : generatePropertyComparisonExpression(
            selection.map((x) => x.variableGuid),
            casesPerRendering,
            MISSING_DATA_COLOR,
            DEFAULT_COLOR
          );

    const geoLayerId = sourceLayer['geo-layer-id'].toString();

    let seDefaultBoundariesLayer: LineLayerSpecification | undefined = undefined;

    if (defaultSourceLayer) {
      seDefaultBoundariesLayer = {
        id: `socialexplorer-data-default-boundaries-${dataLayerFingerprint}`,
        type: 'line',
        source: socialExplorerSourceId,
        'source-layer': defaultSourceLayer['geo-layer-id'].toString(),
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-width': 1,
          'line-color': 'rgb(170,170,170)',
        },
      };
    }

    const dataFilter = dataLayer.filtersByData;

    let filter: FilterSpecification = !dataFilter?.filters.length;
    if (dataFilter) {
      const filterToApply = dataFilter.filters
        .filter(
          (f) =>
            f.value.toString().trim().length > 0 &&
            styleSpec.dataLayer.selection[0].surveyName === f.variable.surveyName
        )
        .map((df) => {
          if (df.type === ProjectTypes.DataFilterType.PERCENTAGE) {
            return [
              df.operator,
              ['/', ['get', df.variable.variableGuid], ['get', df.variable.universeVariableUuid]],
              df.value != null ? (df.value as number) / 100 : df.value,
            ];
          } else {
            return [df.operator, ['get', df.variable.variableGuid], df.value];
          }
        });

      if (dataFilter.rule === '!') {
        filter = filterToApply.length ? (['!', ['any', ...filterToApply]] as FilterSpecification) : ['all'];
      } else {
        filter = filterToApply.length ? ([dataFilter.rule, ...filterToApply] as FilterSpecification) : ['all'];
      }
    }

    const seMapDataLayer: FillLayerSpecification = {
      id: dataLayerFingerprint,
      type: 'fill',
      source: socialExplorerSourceId,
      'source-layer': geoLayerId,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-opacity': 0.85,
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-color': fillColorExpression as DataDrivenPropertyValueSpecification<ColorSpecification>,
      },
      filter: filter,
    };

    const seMapFilteredOutDataLayer: FillLayerSpecification = {
      id: getFilteredOutDataLayerId(dataLayer),
      type: 'fill',
      source: socialExplorerSourceId,
      'source-layer': geoLayerId,
      paint: {
        'fill-pattern': [
          'case',
          [
            'all',
            ...selection.reduce(
              (p: unknown[], c) => [...p, ['==', ['get', c.variableGuid], null], ['==', ['get', c.variableGuid], NaN]],
              []
            ),
          ],
          'gray_pattern',
          'pattern',
        ] as DataDrivenPropertyValueSpecification<string>,
        'fill-opacity': 0.85,
      },
      filter: ['!', filter as ExpressionSpecification],
    };

    const seDataBoundariesLayer: LineLayerSpecification = {
      id: `socialexplorer-data-boundaries-${dataLayerFingerprint}`,
      type: 'line',
      source: socialExplorerSourceId,
      'source-layer': geoLayerId,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgb(255,255,255)',
        'line-opacity': 0.3,
      },
    };

    const seSelectionLayer: LineLayerSpecification = {
      id: `socialexplorer-data-selection-${dataLayerFingerprint}`,
      type: 'line',
      source: socialExplorerSourceId,
      'source-layer': geoLayerId,
      layout: {
        visibility: 'visible',
      },
      filter: ['in', SELECTION_KEY_PROP, ...geoFeatureSelection.map((x) => x.fips)],
      paint: {
        'line-color': 'rgb(40, 42, 45)',
        'line-width': 2,
      },
    };

    const seHighlightLayerOuter: LineLayerSpecification = {
      id: `socialexplorer-data-highlight-outer-${dataLayerFingerprint}`,
      type: 'line',
      source: socialExplorerSourceId,
      'source-layer': geoLayerId,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgb(125,125,125)',
        'line-width': 3,
        'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
      },
    };

    const seHighlightLayerInner: LineLayerSpecification = {
      id: `socialexplorer-data-highlight-inner-${dataLayerFingerprint}`,
      type: 'line',
      source: socialExplorerSourceId,
      'source-layer': geoLayerId,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgb(255,255,255)',
        'line-width': 2,
        'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
      },
    };

    layersToInsert.push(seMapFilteredOutDataLayer);
    layersToInsert.push(seMapDataLayer);

    if (seDefaultBoundariesLayer) {
      layersToInsert.push(seDefaultBoundariesLayer);
    }
    layersToInsert.push(seDataBoundariesLayer);
    layersToInsert.push(seSelectionLayer);
    layersToInsert.push(seHighlightLayerOuter);
    layersToInsert.push(seHighlightLayerInner);

    mapStyle.layers.splice(firstSymbolLayer, 0, ...layersToInsert);
  }

  if (styleSpec.workingLayers.length) {
    const workingLayersStartAt = firstSymbolLayer + layersToInsert.length;

    const workingLayersToAdd: AllowedLayerSpecification[] = [];
    const workingLayerHighlightsToAdd: AllowedLayerSpecification[] = [];

    styleSpec.workingLayers.forEach((workingLayer) => {
      const fingerprint = getWorkingLayerFingerprint(workingLayer);
      const userUploadedSource = `${ProjectTypes.MapTileSource.USER_UPLOAD_SOURCE}:${fingerprint}:${workingLayer.id}`;

      // add user uploaded source
      mapStyle.sources[userUploadedSource] = {
        type: 'vector',
        tiles: [
          `${process.env.NX_USER_TILES_SERVER}?x={x}&y={y}&z={z}&layers={layers}&columns={columns}&projection=EPSG-3857&${userUploadedSource}`,
        ],
      };

      const layerFilters: ExpressionFilterSpecification = ['all'];
      if (workingLayer.filter) {
        const filterToApply = ruleGroupToMapLibreFilter(workingLayer.filter);
        if (filterToApply) {
          layerFilters.push(filterToApply);
        }
      }

      const commonSpec: Partial<CircleLayerSpecification | FillLayerSpecification | LineLayerSpecification> = {
        id: getWorkingLayerId(workingLayer),
        source: userUploadedSource,
        'source-layer': workingLayer.geoLayerId,
        layout: {
          visibility: 'visible',
        },
        filter: layerFilters,
      };

      if (workingLayer.type === ProjectTypes.LayerType.POINT) {
        const [circleOpacity, strokeOpacity] = workingLayer.opacities;
        workingLayersToAdd.push({
          ...commonSpec,
          type: 'circle',
          paint: {
            'circle-opacity': circleOpacity,
            'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'highlight'], false], 1, strokeOpacity],
            'circle-stroke-width': ['case', ['boolean', ['feature-state', 'highlight'], false], 2, 1],
            'circle-radius': ['case', ['boolean', ['feature-state', 'highlight'], false], 6, 4],
            'circle-stroke-color': '#fff',
            'circle-color': [
              'case',
              ['boolean', ['feature-state', 'highlight'], false],
              'rgb(79, 70, 229)',
              workingLayer.baseColor,
            ],
          },
        } as CircleLayerSpecification);
      } else if (workingLayer.type === ProjectTypes.LayerType.POLYGON) {
        const [fillOpacity, outlineOpacity] = workingLayer.opacities;
        workingLayersToAdd.push({
          ...commonSpec,
          type: 'fill',
          paint: {
            'fill-opacity': ['case', ['boolean', ['feature-state', 'highlight'], false], 0.75, fillOpacity],
            'fill-color': [
              'case',
              ['boolean', ['feature-state', 'highlight'], false],
              'rgb(79, 70, 229)',
              workingLayer.baseColor,
            ],
          },
        } as FillLayerSpecification);

        workingLayersToAdd.push({
          ...commonSpec,
          id: `${commonSpec.id}-outline`,
          type: 'line',
          paint: {
            'line-color': workingLayer.baseColor,
            'line-opacity': outlineOpacity,
            'line-width': 2,
          },
        } as LineLayerSpecification);
      } else {
        // skip as it is unknown or not handled yet
        console.warn(`${workingLayer.id} skipped due to unsupported type ${workingLayer.type}`);
      }
    });

    // first add base working layers, then highlights layers...
    mapStyle.layers.splice(workingLayersStartAt, 0, ...workingLayersToAdd, ...workingLayerHighlightsToAdd); // magic number stands for number of socex layers
  }

  return mapStyle;
};

const generatePropertyComparisonExpression = (
  properties: string[],
  renderingColorExpressions: unknown[],
  missingColor: string,
  defaultColor: string
): unknown[] => {
  const maxExpression = [
    'max',
    ...properties.map((property) => ['coalesce', ['get', property], Number.MIN_SAFE_INTEGER]),
  ];

  const colorCases = properties.map((property, index) => [
    ['==', ['get', property], maxExpression],
    renderingColorExpressions[index],
  ]);

  return [
    'case',
    ['all', ...properties.reduce((p: unknown[], c) => [...p, ['==', ['get', c], null], ['==', ['get', c], NaN]], [])],
    missingColor,
    ...colorCases.flat(),
    defaultColor,
  ];
};

export const migrateMapSpec = (styleSpec: ProjectTypes.MapSpec): ProjectTypes.MapSpec => {
  // prefill default data
  return {
    ...styleSpec,
    workingLayers: styleSpec.workingLayers.map((wl) => {
      const opacities = Array.isArray(wl.opacities) ? wl.opacities : DEFAULT_OPACITIES;
      return { ...wl, opacities };
    }),
  };
};

const ruleGroupToMapLibreFilter = (ruleGroup: RuleGroupType): ExpressionSpecification | null => {
  const filters = ruleGroup.rules
    .filter((rule) => Helpers.checkIfValidRule(rule, null))
    .map((rule) => {
      if ((rule as RuleType).operator) {
        return ruleToMapLibreFilter(rule as RuleType);
      } else if ((rule as RuleGroupType).rules && (rule as RuleGroupType).rules.length > 0) {
        return ruleGroupToMapLibreFilter(rule as RuleGroupType);
      } else {
        return true;
      }
    });

  if (!filters.length) return null;

  let operator: 'all' | 'any';

  switch (ruleGroup.combinator.toLowerCase()) {
    case 'or':
      operator = 'any';
      break;
    case 'and':
    default:
      operator = 'all';
      break;
  }

  return [operator, ...filters] as ExpressionSpecification;
};

function ruleToMapLibreFilter(rule: RuleType): ExpressionSpecification {
  const ruleField = rule.field;
  switch (rule.operator) {
    case 'contains':
      return ['match', ['index-of', ['downcase', rule.value], ['downcase', ['get', ruleField]]], -1, false, true];
    case 'beginsWith':
      // use "slice" to extract the first N characters (N = rule.value.length) and compare it to the actual rule.value
      return ['==', ['downcase', ['slice', ['get', ruleField], 0, ['length', rule.value]]], ['downcase', rule.value]];
    case 'endsWith':
      // use "slice" to extract the last N characters (N  = rule.value.length * -1) and compare it to the actual rule.value
      return [
        '==',
        ['downcase', ['slice', ['get', ruleField], ['*', ['length', rule.value], -1]]],
        ['downcase', rule.value],
      ];
    case 'null':
      return ['!', ['has', ruleField]];
    case 'notNull':
      return ['has', ruleField];
    case '=':
      return ['==', ['get', ruleField], rule.value];
    case '!=':
      return ['!=', ['get', ruleField], rule.value];
    case '>':
      return ['>', ['get', ruleField], rule.value];
    case '>=':
      return ['>=', ['get', ruleField], rule.value];
    case '<':
      return ['<', ['get', ruleField], rule.value];
    case '<=':
      return ['<=', ['get', ruleField], rule.value];
    case 'between': {
      const [from, to] = rule.value.toString().split(',');
      return ['all', ['>=', ['get', ruleField], parseFloat(from)], ['<=', ['get', ruleField], parseFloat(to)]];
    }
    default:
      return ['all'];
  }
}

export const extractFieldsFromRuleGroup = (ruleGroup: RuleGroupType): string[] => {
  const fields: string[] = [];

  const extractFields = (group: RuleGroupType) => {
    group.rules
      .filter((rule) => Helpers.checkIfValidRule(rule, null))
      .forEach((ruleOrGroup) => {
        if ((ruleOrGroup as RuleType).field) {
          fields.push((ruleOrGroup as RuleType).field);
        } else if ((ruleOrGroup as RuleGroupType).rules) {
          extractFields(ruleOrGroup as RuleGroupType);
        }
      });
  };

  extractFields(ruleGroup);
  return fields;
};

export const calculateBounds = (
  featureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry>
): maplibregl.LngLatBoundsLike | null => {
  if (!featureCollection.features.length) return null;

  const [a, b, c, d] = turf.bbox(featureCollection);
  return [a, b, c, d];
};

// Helper function to normalize LngLatBoundsLike to a consistent format
const normalizeBounds = (bounds: maplibregl.LngLatBoundsLike): [number, number, number, number] => {
  if (Array.isArray(bounds) && bounds.length === 2) {
    const sw = bounds[0];
    const ne = bounds[1];
    const west = Array.isArray(sw) ? sw[0] : (sw as LngLat).lng;
    const south = Array.isArray(sw) ? sw[1] : sw.lat;
    const east = Array.isArray(ne) ? ne[0] : (sw as LngLat).lng;
    const north = Array.isArray(ne) ? ne[1] : ne.lat;
    return [west, south, east, north];
  } else if (Array.isArray(bounds) && bounds.length === 4) {
    return bounds;
  }
  // Add more cases if necessary, e.g., for LngLatBounds instances
  throw new Error('Unsupported bounds format');
};

export const calculateCenterOfBoundsLike = (boundsLike: maplibregl.LngLatBoundsLike): maplibregl.LngLatLike => {
  const [west, south, east, north] = normalizeBounds(boundsLike);
  const centerLng = (west + east) / 2;
  const centerLat = (south + north) / 2;
  return [centerLng, centerLat]; // Or { lng: centerLng, lat: centerLat } for object format
};

export const cleanupSource = (map: maplibre.Map, sourceId: string) => {
  if (!map.getSource(sourceId)) return;
  // Get all layers in the map
  const layers = map.getStyle().layers;

  // Loop through all layers
  for (const layer of layers) {
    // Check if the layer's source matches the specified sourceId
    // @ts-ignore
    if (layer.source === sourceId) {
      // Remove the layer from the map
      map.removeLayer(layer.id);
    }
  }

  // Remove the source itself if needed
  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }
};
