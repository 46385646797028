import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import Data from './Data';
import { useMapSidebarContext } from './MapSideBarContext';
import Search from './Search';

export type OPTION = {
  icon?: JSX.Element;
  trigger?: React.ReactNode;
  title: string;
  key: string;
  content: JSX.Element;
  contentClassNames?: string;
  triggerClassNames?: string;
};

interface Props {
  options: OPTION[];
  leftDrawer?: React.ReactNode;
  classNames?: string;
  children?: React.ReactNode;
  sidebarType?: 'MAP' | 'STORY';
}

const MapSideBar: React.FC<Props> = ({ children, classNames = '', leftDrawer, options = [], sidebarType = 'MAP' }) => {
  const { activeView, changeActiveView } = useMapSidebarContext();
  const handleClick = (key: string) => changeActiveView(key);
  const activeOption = options.find((opt) => opt.key === activeView);

  const BASE_COLORS: Record<
    string,
    {
      background: string;
      hover: string;
    }
  > = {
    MAP: {
      background: 'bg-gray-900',
      hover: 'hover:bg-gray-700',
    },
    STORY: {
      background: 'bg-acai-900',
      hover: 'hover:bg-acai-700',
    },
  };

  return (
    <div className={`relative flex h-full ${BASE_COLORS[sidebarType].background} ${classNames}`}>
      <div className="flex h-full flex-grow-0">
        <div className="flex flex-col items-center">
          {leftDrawer && (
            <div className="flex w-full flex-col items-center justify-center border-b border-white/10 px-3 py-4 text-white">
              {leftDrawer}
            </div>
          )}
          <div className="flex min-h-0 w-[72px] flex-grow select-none flex-col items-center gap-4 px-3 py-6 text-white">
            {options.map((opt) => (
              <button
                key={opt.key}
                onClick={() => handleClick(opt.key)}
                className={`flex w-full cursor-pointer flex-col items-center gap-1 ${opt.triggerClassNames}`}
              >
                {opt.trigger ?? (
                  <>
                    <div
                      className={`flex w-full flex-col items-center rounded-full py-1 ${
                        BASE_COLORS[sidebarType].hover
                      } ${
                        activeOption?.key === opt.key
                          ? 'bg-white text-gray-700 hover:bg-white hover:text-gray-700'
                          : 'bg-white/10'
                      }`}
                    >
                      {opt.icon}
                    </div>
                    <span className="text-[10px] text-gray-300">{opt.title}</span>
                  </>
                )}
              </button>
            ))}
            {children && (
              <div className="flex w-full flex-grow flex-col gap-6">
                <div className="h-[1px] w-full bg-white/10" />
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
      {activeOption && (
        <div className={`z-20 flex h-full flex-col bg-white shadow-lg ${activeOption.contentClassNames ?? ''}`}>
          {activeOption.content}
        </div>
      )}
    </div>
  );
};

export default MapSideBar;

export const SHARED_OPTIONS = {
  SEARCH: {
    icon: <MUIcon name="travel_explore" className="text-[24px]" />,
    title: 'Search',
    key: 'SEARCH',
    content: <Search />,
  },
  DATA: {
    icon: <MUIcon name="layers" className="text-[24px]" />,
    title: 'Data',
    key: 'DATA',
    content: <Data />,
  },
};
