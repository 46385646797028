import { PeTypes } from '@platform/types';
import moment from 'moment';
import { ChartSerie } from '../components/InfoPanel/ChartTypes';

const SUPPORTED_SIZE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'];

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const getDataForSeries = (
  series: PeTypes.ChartSeries[],
  preProcessedData: { [key: string]: number }
): ChartSerie[] => {
  return series.map((serie) => {
    const chartData: number[] = [];
    let total: number | undefined;

    if (serie.universe) {
      total = preProcessedData[serie.universe] as number;
    }
    serie.data.forEach((element: string) => {
      if (total != null && !serie.ignoreUniverse) {
        const value = preProcessedData[element] as number;
        const percent = (value / total) * 100;
        chartData.push(percent || 0);
      } else chartData.push(preProcessedData[element]);
    });

    return {
      total,
      name: serie.name,
      data: chartData,
      type: serie.type,
      colors: serie.colors,
    };
  });
};

export const getCategoriesForChart = (categories: string[], metadata: { [key: string]: string }): string[] => {
  const cats: string[] = [];
  categories.forEach((category: string) => {
    cats.push(metadata[category]);
  });
  return cats;
};

export const getChartTitle = (title: string, metadata: { [key: string]: string }): string => {
  return metadata[title];
};

export const getStep = (steps: PeTypes.NumberDefinitionSteps[] | undefined, value: number) => {
  if (steps == null) return null;

  for (const stepObj of steps) {
    const { min, max, color, label } = stepObj;
    if (value < 0) {
      if (value < min && value >= max) {
        return { color, label };
      }
    } else if (value > 0) {
      if (value > min && value <= max) {
        return { color, label };
      }
    } else if (value === 0 && min === 0 && max === 0) {
      return { color, label };
    }
  }

  return null;
};

export const getTimeFromNow = (dateTime: Date) => moment(new Date(dateTime).getTime()).fromNow();

export const hasText = (text: string) => {
  const element = document.createElement('span');
  element.innerHTML = text.trim();

  return !!element.innerText.trim().length;
};

export const toHumanReadableFileSize = (fileSizeInBytes: number) => {
  if (fileSizeInBytes === 0) {
    return '0 B';
  }
  const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
  const decimals = i > 2 ? 2 : 0;
  return `${(fileSizeInBytes / 1024 ** i).toFixed(decimals)} ${SUPPORTED_SIZE_UNITS[i]}`;
};

export const validateEmail = (email: string) => {
  return emailRegex.test(email);
};

export const downloadDataset = (downloadLink: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', downloadLink);
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
