import { AbacusTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';

interface Props {
  datasetId: string;
  api: (datasetId: string, payload: AbacusTypes.CountDatasetRowsRequest) => Promise<number>;
  dataQuery?: RuleGroupType;
  options?: UseQueryOptions<number>;
}

const useAbacusRowsCount = ({ datasetId, api, dataQuery, options }: Props) =>
  useQuery<number>(
    ['abacus-dataset-row-count', api, datasetId, dataQuery],
    () => api(datasetId, { dataQuery }),
    options
  );

export default useAbacusRowsCount;
