import { MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';
import Legend from './Legend';

export interface Props {
  mapObjects: ProjectTypes.MapObject[];
  isInEditMode: boolean;
}

const CompareLegend: React.FC<Props> = ({ mapObjects, isInEditMode }) => {
  const [primaryMapObj, secondaryMapObj] = mapObjects;

  return (
    <div className="flex max-h-[600px] w-64 flex-col gap-2 overflow-auto overflow-x-hidden">
      <Legend mapObj={primaryMapObj} isInEditMode={isInEditMode} />
      {secondaryMapObj && (
        <>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1 rounded-r-md bg-gray-200 px-4 py-1 text-xs text-gray-600">
              <MUIcon name="flip" />
              Compared to:
            </div>
            <div className="flex h-[1px] flex-grow bg-gray-200" />
          </div>
          <Legend mapObj={secondaryMapObj} isInEditMode={isInEditMode} />
        </>
      )}
    </div>
  );
};

export default CompareLegend;
