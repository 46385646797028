import { MetadataTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';
import * as peApi from '../pe.api';

function useDataSourceLayers(
  surveyName: string,
  isUserSurvey: boolean,
  options?: UseQueryOptions<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]>
) {
  return useQuery<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]>(
    ['se-data-source-layers', surveyName],
    () => peApi.getDataSourceLayers(surveyName, isUserSurvey),
    options
  );
}

export default useDataSourceLayers;
