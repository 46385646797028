import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import SimpleWorkspaceSwitcher from '../components/Dashboard/SimpleWorkspaceSwitcher';
import { LogoText } from '../components/shared';

interface Props {
  id: number;
}

const SubscriptionExpired: React.FC<Props> = ({ id }) => {
  return (
    <div className="h-full w-full">
      <div className=" py-6 px-16">
        <LogoText width={148} height={38} />
      </div>
      <div className="flex h-full w-full justify-center">
        <div className="flex flex-col gap-8">
          <div className="mt-28 h-[404px] w-[460px] rounded-[16px] border border-gray-200">
            <div className="flex flex-col gap-8 py-8 px-10">
              <div className="flex items-center gap-3">
                <MUIcon name="lock" className="stroke-2 text-gray-500" />
                <span className="text-sm font-semibold text-gray-600">Access Restricted</span>
              </div>
              <div className="text-2xl font-bold text-gray-800">
                It looks like your current workspace doesn't have an active subscription.
              </div>
              <div className="text-gray-600">
                To regain access and continue enjoying all the features, reach out to us. We'll work together to
                reactivate your workspace or set up a new one tailored to your needs
              </div>
              <a href="mailto:info@socialexplorer.com">
                <Buttons.Primary className="h-[40px] w-full">Contact us</Buttons.Primary>
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-3 self-center text-sm text-gray-500">
            <div className="mx-auto">or, switch to another workspace</div>
            <SimpleWorkspaceSwitcher id={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionExpired;
