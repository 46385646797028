import { useQuery, useQueryClient } from 'react-query';
import * as peApi from '../pe.api';

interface Props {
  datasetId: string;
}

const QUERY_KEY = 'dataset-last-published-state';

const useDatasetLastPublishedState = ({ datasetId }: Props) => {
  const queryClient = useQueryClient();
  const baseQueryKey = [QUERY_KEY, datasetId];

  const query = useQuery(baseQueryKey, () => peApi.getDatasetLastPublishedState(datasetId));

  const invalidate = (keys: unknown[] = []) => queryClient.invalidateQueries({ queryKey: [...baseQueryKey, ...keys] });

  return { query, invalidate };
};

export default useDatasetLastPublishedState;
