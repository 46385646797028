import { ProjectTypes } from '@platform/types';
import { Map, RequestParameters, ResourceType, StyleSpecification } from 'maplibre-gl';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import MapLibreMap from './Map';
import { geoBufferTilesRequests, mapTilerTilesRequests, nonTileRequests } from './mapRequestTransformations';
import { useMapsContext } from './MapsContext';
import * as MapSpecModifiers from './mapSpecModifiers';
import { getMapStyle } from './utils';

export const SELECTION_KEY_PROP = 'Geo_FIPS'; // TODO: *assumption alert* key is Geo_FIPS!

interface MapProps {
  mapObj: ProjectTypes.MapObject;
  children?: React.ReactNode;
  withFooter?: boolean;
  onMount?: (mapRef: Map) => void;
}

const MapsContextMap: React.FC<MapProps> = ({ children, withFooter = true, mapObj, onMount }) => {
  const { apis, jwtToken, onUnMountMap, onMountMap, useMapHighlight, activeMap, mapClickHandler } = useMapsContext();
  const [mapStyle, setMapStyle] = useState<StyleSpecification>();
  const { id, styleSpec } = mapObj;
  const { baseMapId, dataLayer, workingLayers } = styleSpec;
  const { selection = [], preferredSummaryLevelId } = dataLayer;
  const [firstSelectedVariable] = selection;

  const baseMapStyleQuery = useQuery(['se-basemaps', baseMapId], () => apis.baseMaps.get(styleSpec.baseMapId));

  const dataSourceLayersQuery = useQuery(
    ['se-data-source-layers', firstSelectedVariable?.surveyName],
    () => apis.dataSourceLayers.get(firstSelectedVariable.surveyName, !!firstSelectedVariable.isUserSurvey),
    {
      enabled: firstSelectedVariable?.surveyName != null,
    }
  );

  const defaultSourceLayer = dataSourceLayersQuery.data?.[0];
  const sourceLayer = dataSourceLayersQuery.data?.find((ds) => ds['summary-level'].id === preferredSummaryLevelId);
  const basemapStyle = baseMapStyleQuery.data;

  useEffect(() => {
    if (!basemapStyle) return;

    if (sourceLayer || !selection.length) {
      const mapStyle = getMapStyle({
        styleSpec,
        sourceLayer,
        defaultSourceLayer,
        baseMapStyle: baseMapStyleQuery.data,
      });
      setMapStyle(mapStyle);
    } else if (dataSourceLayersQuery.data?.length) {
      // the preferredSummaryLevelId layer doesn't exist in the data source layers for given dingo basemap
      // this usually happens when switching from survey to another survey with different data source layers
      // se we default to the first item (most probably States, SL040)
      const updated = MapSpecModifiers.updateDataLayerSummaryLevel(
        styleSpec,
        dataSourceLayersQuery.data[0]['summary-level'].id
      );
      mapObj.updateMapSpec(updated);
    }
  }, [styleSpec, basemapStyle, sourceLayer?.id, dataSourceLayersQuery.data]);

  const mapTilesRequestTransformation = useCallback(
    (url: string, resourceType?: ResourceType): RequestParameters => {
      let transformedUrl;

      // check for non tile requests
      transformedUrl = nonTileRequests(url, resourceType);
      if (transformedUrl) return { url: transformedUrl };

      // check for map tiler requests
      transformedUrl = mapTilerTilesRequests(url);
      if (transformedUrl) return { url: transformedUrl, headers: { Authorization: `Bearer ${jwtToken}` } };

      // handling GeoBuffer tile requests (social explorer layers and user uploaded layers)
      // in order to display GeoBuffer (tiles3) tiles on MapLibre map we need to rewrite tile requests by adding layers and columns to the request
      transformedUrl = geoBufferTilesRequests(url, styleSpec, sourceLayer, defaultSourceLayer);
      return {
        url: transformedUrl,
      };
    },
    [sourceLayer, workingLayers, dataLayer]
  );

  if (!mapStyle)
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 text-xl text-gray-400">
        Loading Map
      </div>
    );

  return (
    <div
      className="relative h-full w-full"
      onClick={() => mapClickHandler?.(mapObj)}
      onKeyDown={() => mapClickHandler?.(mapObj)}
    >
      <MapLibreMap
        style={mapStyle ?? ''}
        customTransformRequest={mapTilesRequestTransformation}
        zoom={styleSpec.zoom}
        center={styleSpec.center}
        onMount={(mapElementRef) => {
          onMountMap?.(id, mapElementRef);
          onMount?.(mapElementRef);
        }}
        onUnmount={() => {
          onUnMountMap?.(id);
        }}
      />
      {children && <div className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 z-10">{children}</div>}
      {withFooter && (
        <div className="z-1 absolute bottom-0 right-0 flex justify-between rounded-tl-md bg-white bg-opacity-75 px-4 py-0.5">
          <div />
          <div className="text-xs">{`Zoom: ${Math.round(styleSpec.zoom * 100) / 100}`}</div>
        </div>
      )}
      {useMapHighlight && activeMap?.id === mapObj.id && (
        <div className="border-primary-400 pointer-events-none absolute top-0 left-0 right-0 bottom-0 border-4 opacity-60" />
      )}
    </div>
  );
};

export default MapsContextMap;
