import { MUIcon, Spinner, Table } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import pluralize from 'pluralize';
import React from 'react';
import { toHumanReadableFileSize } from '../../../helpers/helpers';
import { ImportProgress } from '../ImportWizard';

const TableComponent = Table.default;
const MAX_ROWS = 20;

interface Props {
  file: File;
  importData: ImportProgress;
  onFileRemove: () => void;
}

const FilePreview: React.FC<Props> = ({ file, importData, onFileRemove }) => {
  const { columns, isParsing } = importData;
  const cols = columns.map((x, index) => ({ id: `${x.name}_${index}`, title: x.name })) ?? [];
  const formattedColumnsCount = ValueFormatter.format(cols.length, ProjectTypes.ValueFormat.FORMAT_NUMBER);
  const formattedRowsCount = ValueFormatter.format(importData.totalDataCount, ProjectTypes.ValueFormat.FORMAT_NUMBER);

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center gap-8">
      <div className="flex w-8/12 flex-col items-start gap-3">
        <div className="flex w-full items-center justify-between rounded-xl border border-gray-200 bg-gray-50 p-4 px-6">
          <div className="flex items-center justify-center gap-4">
            <div className="flex h-[32px] w-[32px] items-center justify-center rounded-[999px] bg-gray-400">
              <MUIcon name="description" className="text-[22px] text-white" />
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">{file.name}</p>
              <div className="flex items-center gap-2 text-xs text-gray-500">
                <p className="text-sm">{`${formattedColumnsCount} ${pluralize('column', cols.length)}`}</p>•
                <p className="text-sm">{`${formattedRowsCount} ${pluralize('row', importData.totalDataCount)}`}</p>•
                <p className="text-sm">{`${toHumanReadableFileSize(file.size ?? 0)} in size`}</p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center text-sm font-semibold">
            <button onClick={onFileRemove}>
              <MUIcon name="close" className="text-gray-600" />
            </button>
          </div>
        </div>
        <div className="mt-4 w-full">
          <div className="text-base font-semibold">Dataset preview</div>
        </div>
      </div>
      {isParsing ? (
        <div className="flex h-[300px] flex-col items-center justify-center gap-4">
          <Spinner />
          <span className="text-sm text-gray-600">Analyzing file. This might take a while.</span>
        </div>
      ) : (
        <div className="flex h-full min-h-0 w-11/12 flex-col items-start justify-center gap-4">
          <div className="min-h-0 w-full ">
            <TableComponent rows={importData.data.slice(0, MAX_ROWS)} columns={cols} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilePreview;
