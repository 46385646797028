import React from 'react';
import { ReactComponent as EmptyStateSVG } from '../../../../../assets/empty-columns-state.svg';

interface Props {
  searchTerm: string;
}

const EmptyColumnsState: React.FC<Props> = ({ searchTerm }) => (
  <div className="mt-16 flex h-full w-full items-start justify-center">
    <div className="flex flex-col items-center gap-10">
      <EmptyStateSVG />
      <div className="flex max-w-[260px] gap-1 truncate">
        <span className="text-center text-sm text-gray-700">No results for</span>
        <span className="truncate text-center text-sm font-semibold text-gray-700">{searchTerm}</span>
      </div>
    </div>
  </div>
);

export default EmptyColumnsState;
