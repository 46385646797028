import { useQuery } from 'react-query';
import * as peApi from '../pe.api';

interface Prop {
  datasetId: string;
  pageNumber?: number;
  itemsPerPage?: number;
}

const useDatasetColumns = ({ datasetId, pageNumber, itemsPerPage }: Prop) =>
  useQuery(
    ['dataset-columns', datasetId, pageNumber],
    () =>
      peApi.getDatasetColumns({
        datasetId,
        pageNumber,
        itemsPerPage,
      }),
    { enabled: true }
  );

export default useDatasetColumns;
