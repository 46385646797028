import { PeTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React, { useState } from 'react';

interface Props {
  title: string;
  rows: PeTypes.RowDataDefinition[];
  info?: string;
}

const Table: React.FC<Props> = ({ rows }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-col rounded-lg border ${
          rows.length > 10 ? 'overflow-hidden rounded-bl-none rounded-br-none border-b-0' : ''
        }`}
      >
        {rows.slice(0, isExpanded ? undefined : 10).map((row, index) => {
          return (
            <div
              key={`${index}-${row.label}`}
              className={`flex items-center justify-between border-b p-3 text-sm text-gray-700 last:border-b-0 ${
                row.indent === 0 ? 'bg-gray-100' : ''
              }`}
            >
              <div className="w-8/12" style={{ marginLeft: `${row.indent * 16}px` }}>
                {row.label}
              </div>
              <div className="flex items-center justify-center gap-1">
                <span>{ValueFormatter.format(row.value, row.valueFormat)}</span>
                <span className="text-xs text-gray-500">
                  {row.pctgValue != null &&
                    `(${ValueFormatter.format(row.pctgValue, ProjectTypes.ValueFormat.FORMAT_PERCENT_1_DECIMAL)})`}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {rows.length > 10 && (
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-primary-600 flex cursor-pointer items-center justify-center rounded-bl-lg rounded-br-lg border py-2.5 text-sm font-semibold"
        >
          {isExpanded ? 'View less' : 'View more'}
        </div>
      )}
    </div>
  );
};

export default Table;
