import { MapSpecModifiers, Utils } from '@platform/maplibre';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { POINT_COLORS } from '../../../enums/pointColors';
import useFitBounds from '../../../hooks/useFitBounds';
import { REMOVE_TOOLTIPS_EVENT } from './Tooltip/useWorkingLayerTooltips';
import WorkingLayer from './WorkingLayer';
import WorkingLayerPicker from './WorkingLayerPicker';
import WorkingLayersEmptyState from './WorkingLayersEmptyState';

interface Props {
  mapObjects: ProjectTypes.MapObject[];
  activeWorkspace: PeTypes.Workspace;
  isEditMode?: boolean;
}

const WorkingLayers: React.FC<Props> = ({ isEditMode, mapObjects, activeWorkspace }) => {
  const [isDragDisabled, setIsDragDisabled] = useState<boolean>(false);
  const [primaryMapObj, ...otherMapObjects] = mapObjects;

  const fitBounds = useFitBounds(primaryMapObj.ref);

  const handleRemoveLayerRequested = (workingLayerToBeRemoved: ProjectTypes.WorkingLayerSpec) => {
    const updated = MapSpecModifiers.removeWorkingLayer(primaryMapObj.styleSpec, workingLayerToBeRemoved.id);
    primaryMapObj.updateMapSpec(updated);

    if (otherMapObjects.length) {
      otherMapObjects.forEach((mapToMirrorUpdate) => {
        const updated = MapSpecModifiers.removeWorkingLayer(mapToMirrorUpdate.styleSpec, workingLayerToBeRemoved.id);
        mapToMirrorUpdate.updateMapSpec(updated);
      });
    }
  };

  const handleAddWorkingLayer = (datasets: PeTypes.DatasetListItem[] = []) => {
    const addedDatasets: ProjectTypes.WorkingLayerSpec[] = datasets
      .filter((dataset) => dataset.geoLayerId)
      .map((dataset, idx) => ({
        name: dataset.name,
        id: uuidv4(),
        datasetId: dataset.id,
        geoLayerId: dataset.geoLayerId ?? '',
        type: dataset.type,
        visible: true,
        opacities: Utils.DEFAULT_OPACITIES,
        baseColor: POINT_COLORS[(primaryMapObj.styleSpec.workingLayers.length + idx) % POINT_COLORS.length],
      }));

    const updated = MapSpecModifiers.addWorkingLayer(primaryMapObj.styleSpec, addedDatasets);
    primaryMapObj.updateMapSpec(updated);

    if (otherMapObjects.length) {
      otherMapObjects.forEach((mapToMirrorUpdate) => {
        const updated = MapSpecModifiers.addWorkingLayer(mapToMirrorUpdate.styleSpec, addedDatasets);
        mapToMirrorUpdate.updateMapSpec(updated);
      });
    }

    if (addedDatasets.length) {
      fitBounds(addedDatasets[0].datasetId);
    }
  };

  const handleFitLayerRequest = (layer: ProjectTypes.WorkingLayerSpec) => fitBounds(layer.datasetId);

  const handleUpdateLayerRequest = (layer: ProjectTypes.WorkingLayerSpec) => {
    const updated = MapSpecModifiers.updateWorkingLayer(primaryMapObj.styleSpec, layer);
    primaryMapObj.updateMapSpec(updated);

    if (otherMapObjects.length) {
      otherMapObjects.forEach((mapToMirrorUpdate) => {
        const updated = MapSpecModifiers.updateWorkingLayer(mapToMirrorUpdate.styleSpec, layer);
        mapToMirrorUpdate.updateMapSpec(updated);
      });
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = Array.from(workingLayers);

    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    const updated = MapSpecModifiers.setWorkingLayers(primaryMapObj.styleSpec, updatedItems);
    primaryMapObj.updateMapSpec(updated);

    if (otherMapObjects.length) {
      otherMapObjects.forEach((mapToMirrorUpdate) => {
        const updated = MapSpecModifiers.setWorkingLayers(mapToMirrorUpdate.styleSpec, updatedItems);
        mapToMirrorUpdate.updateMapSpec(updated);
      });
    }
  };
  const workingLayers = primaryMapObj.styleSpec.workingLayers ?? [];

  return (
    <div className="flex w-full flex-col gap-2">
      {isEditMode && <WorkingLayerPicker onSelect={handleAddWorkingLayer} />}
      {workingLayers.length === 0 && <WorkingLayersEmptyState />}
      {workingLayers.length > 0 && (
        <div className="overflow-auto">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-grow flex-col gap-2.5 bg-white py-4"
                >
                  {workingLayers.map((workingLayerSpec, idx) => (
                    <Draggable
                      key={workingLayerSpec.id}
                      draggableId={workingLayerSpec.id}
                      index={idx}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <WorkingLayer
                            canEdit={isEditMode}
                            workingLayer={workingLayerSpec}
                            onRemove={handleRemoveLayerRequested}
                            onUpdate={handleUpdateLayerRequest}
                            onFit={handleFitLayerRequest}
                            clearTooltips={() => primaryMapObj.ref?.fire(REMOVE_TOOLTIPS_EVENT)}
                            onBeingEdited={(isBeingEdited) => setIsDragDisabled(isBeingEdited)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default WorkingLayers;
