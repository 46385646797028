import { PeTypes } from '@platform/types';
import React from 'react';
import { UserMenu } from '../shared';
import HeaderOptionsMenu from './HeaderOptionsMenu';

interface Props {
  profile: PeTypes.Profile;
  isEditMode: boolean;
  furtherActions?: React.ReactNode;
}

const ProfileHeader: React.FC<Props> = ({ isEditMode, profile }) => {
  const { id } = profile;
  return (
    <div className={`flex grow items-center justify-end gap-2 px-6 py-4`}>
      <div className="z-10 flex items-center gap-4">
        <HeaderOptionsMenu isEdit={isEditMode} profileId={id} />
        <UserMenu avatarClassNames="shadow-lg" />
      </div>
    </div>
  );
};

export default ProfileHeader;
