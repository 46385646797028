import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import CopyDatasetNotification from './types/CopyDataset.notification';
import ExportDatasetNotification from './types/ExportDataset.notification';
import HardDeleteDatasetNotification from './types/HardDeleteDataset.notification';
import ImportDataset from './types/ImportDataset.notification';
import PublishDataset from './types/PublishDataset.notification';

interface Props {
  item: PeTypes.UserNotification<object>;
}

const Notification: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { type, read, createdBy } = item;
  const markAsReadMutation = useMutation({
    mutationFn: (notificationId: string) => peApi.markUserNotificationAsRead(notificationId),
  });
  const { activeWorkspace } = useWorkspaceContext();

  let notificationInfo;
  let navigateTo: string;
  let notificationData;

  switch (type) {
    case PeTypes.NotificationType.PUBLISH_DATASET:
      notificationData = item as PeTypes.Notification<PeTypes.PublishDatasetNotification>;
      navigateTo = generateLink('dataset', {
        workspaceId: notificationData.ownedBy,
        datasetId: notificationData.metadata.datasetId,
      });
      notificationInfo = <PublishDataset item={notificationData} />;
      break;
    case PeTypes.NotificationType.IMPORT_DATASET:
      notificationData = item as PeTypes.Notification<PeTypes.ImportDatasetNotification>;
      if (notificationData.metadata.datasetId) {
        navigateTo = generateLink('dataset', {
          workspaceId: notificationData.ownedBy,
          datasetId: notificationData.metadata.datasetId,
        });
      }
      notificationInfo = <ImportDataset item={notificationData} />;
      break;
    case PeTypes.NotificationType.HARD_DELETE_DATASET:
      notificationInfo = (
        <HardDeleteDatasetNotification item={item as PeTypes.Notification<PeTypes.HardDeleteDatasetNotification>} />
      );
      break;
    case PeTypes.NotificationType.COPY_DATASET:
      notificationData = item as PeTypes.Notification<PeTypes.CopyDatasetNotification>;
      if (notificationData.metadata.datasetId) {
        navigateTo = generateLink('dataset', {
          workspaceId: notificationData.metadata.targetWorkspaceId,
          datasetId: notificationData.metadata.datasetId,
        });
      }
      notificationInfo = <CopyDatasetNotification item={notificationData} />;
      break;
    case PeTypes.NotificationType.EXPORT_DATASET:
      notificationInfo = (
        <ExportDatasetNotification item={item as PeTypes.Notification<PeTypes.ExportDatasetNotification>} />
      );
      break;
    default:
      notificationInfo = <span className="truncate">TODO</span>;
  }

  const handleItemClick = (item: PeTypes.Notification<unknown>) => {
    markAsReadMutation.mutate(item.id, {
      onSuccess: () => {
        queryClient.setQueryData<InfiniteData<PeTypes.UserNotification<object>[]>>(
          ['notifications', activeWorkspace.id],
          (oldData) => {
            if (!oldData) {
              return {
                pages: [],
                pageParams: [],
              };
            }

            let notificationFound = false;
            const newPages = oldData.pages.map((page) =>
              page.map((n) => {
                if (n.id === item.id) {
                  notificationFound = true;
                  return { ...n, read: true };
                }
                return n;
              })
            );

            if (notificationFound) {
              return {
                ...oldData,
                pages: newPages,
              };
            }

            return oldData;
          }
        );

        if (navigateTo) {
          navigate(navigateTo);
        }
      },
    });
  };

  return (
    <div
      className="group flex min-w-0 cursor-pointer items-center gap-3 py-4 pl-4 pr-6 hover:bg-gray-100"
      onClick={() => handleItemClick(item)}
    >
      <div className="flex w-full gap-2 truncate">
        <div
          className={classNames('flex h-[7px] w-[7px] flex-shrink-0 rounded-full ', {
            'bg-[#4F46E5]': !read,
          })}
        />
        {notificationInfo}
      </div>
    </div>
  );
};

export default Notification;
