import { MapsContext } from '@platform/maplibre';
import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import { SHARED_OPTIONS } from '../MapSideBar';
import { useMapSidebarContext } from '../MapSideBarContext';
import CompareTypeButton from './CompareTypeButton';

const { useMapsContext } = MapsContext;

interface Props {
  onCloseRequest: () => void;
}

const Compare: React.FC<Props> = ({ onCloseRequest }) => {
  const { activeView, changeActiveView } = useMapSidebarContext();
  const { changeCompareType, changeActiveMap, state } = useMapsContext();
  const currentCompareType = state.type;

  const isComparingData = currentCompareType === 'swipe' || currentCompareType === 'side-locked';
  const isComparingLocations = currentCompareType === 'side';

  const handleModeChange = (newType: 'single' | 'side' | 'side-locked' | 'swipe') => changeCompareType(newType);

  const handlePanelRequest = (key: string) => {
    if (activeView !== key) {
      changeActiveView(key);

      if (isComparingData) {
        const rightMap = state.registeredMaps[1];
        if (rightMap) {
          changeActiveMap(rightMap.id);
        }
      }
    }
    onCloseRequest();
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex h-[32px] items-center justify-between">
        <div className="font-semibold text-gray-800">Compare</div>
        {isComparingData && (
          <div className="flex gap-1">
            <Buttons.Soft
              data-rh="Swipe"
              onClick={() => handleModeChange('swipe')}
              icon={<MUIcon name="flip" />}
              className={`h-[32px] w-[32px] rounded-full text-gray-800 shadow-none transition-all ${
                currentCompareType === 'swipe' ? 'bg-primary-100 text-primary-600' : 'bg-white'
              }`}
            />
            <Buttons.Soft
              data-rh="Side by side"
              onClick={() => handleModeChange('side-locked')}
              icon={<MUIcon name="view_column_2" />}
              className={`h-[32px] w-[32px] rounded-full text-gray-800 shadow-none transition-all ${
                currentCompareType === 'side-locked' ? 'bg-primary-100 text-primary-600' : 'bg-white'
              }`}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row gap-6 px-1">
          <CompareTypeButton
            imgUrl="/assets/thumbnail-compare-data.png"
            active={isComparingData}
            title="Data"
            onClick={() => handleModeChange(isComparingData ? 'single' : 'swipe')}
          />
          <CompareTypeButton
            imgUrl="/assets/thumbnail-compare-locations.png"
            active={isComparingLocations}
            title="Locations"
            onClick={() => handleModeChange(isComparingLocations ? 'single' : 'side')}
          />
        </div>
      </div>
      {isComparingData && (
        <CustomLinkButton onClick={() => handlePanelRequest(SHARED_OPTIONS.DATA.key)}>Select data</CustomLinkButton>
      )}
      {isComparingLocations && (
        <CustomLinkButton onClick={() => handlePanelRequest(SHARED_OPTIONS.SEARCH.key)}>
          Select location
        </CustomLinkButton>
      )}
    </div>
  );
};

export default Compare;

const CustomLinkButton = (props: { children: React.ReactNode; onClick: () => void }) => (
  <Buttons.Link
    onClick={props.onClick}
    className="text-primary-600 flex gap-3 rounded-full py-2 pl-3 pr-6 font-semibold ring-1 ring-gray-300 hover:bg-gray-100"
  >
    {props.children}
  </Buttons.Link>
);
