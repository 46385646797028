import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as HelpBook } from '../../assets/help_book.svg';
import { ReactComponent as HelpWheel } from '../../assets/help_wheel.svg';
import RecentProfiles from '../components/Dashboard/RecentProfiles';
import RecentProjects from '../components/Dashboard/RecentProjects';
import RecentStories from '../components/Dashboard/RecentStories';
import Search from '../components/Search';
import { useSessionContext } from '../contexts/SessionContext';

const Dashboard: React.FC = () => {
  const { user } = useSessionContext();

  return (
    <div className="flex flex-col pb-20">
      <div className="border-b bg-white">
        <div className="mx-auto max-w-7xl py-12">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <div
                className="text-3xl font-bold leading-tight tracking-tight text-gray-900"
                data-cy={'welcome-message'}
              >
                Welcome {user?.firstName}
              </div>
              <div className="border-primary-400 mt-3 w-12 border" />
            </div>
            <div className="hidden">
              <Search />
            </div>
            <div className="flex h-full items-center justify-center gap-4">
              <Link to={'https://www.socialexplorer.com/help/'} target="_blank">
                <div className="bg-primary-50 flex min-h-[86px] min-w-[220px] max-w-[220px] items-center justify-center gap-5 rounded-[16px] py-2 shadow hover:shadow-lg">
                  <HelpWheel />
                  <div className="flex max-w-[50%] flex-col gap-1 py-2">
                    <span className="text-sm font-semibold text-gray-900">Get Assistance</span>
                    <span className="break-words text-left text-xs text-gray-600">View help docs and tutorials</span>
                  </div>
                </div>
              </Link>
              <Link to={'https://www.socialexplorer.com/data/metadata'} target="_blank">
                <div className="bg-primary-50 flex min-h-[86px] min-w-[220px] max-w-[220px] items-center justify-center gap-5 rounded-[16px] py-2 shadow hover:shadow-lg">
                  <HelpBook />
                  <div className="flex max-w-[50%] flex-col gap-1 py-2">
                    <span className="text-sm font-semibold text-gray-900">Data Dictionary</span>
                    <span className="break-words text-left text-xs text-gray-600">View available data sources </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <main className="flex flex-col gap-12">
        <div className="mx-auto w-full max-w-7xl pt-8">
          <RecentProjects />
        </div>
        <div className="mx-auto w-full max-w-7xl">
          <RecentStories />
        </div>
        <div className="mx-auto w-full max-w-7xl">
          <RecentProfiles />
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
