import { metadataApi } from '@platform/api';
import { MetadataTypes, ProjectTypes } from '@platform/types';
import { Map } from 'maplibre-gl';
import { useQuery } from 'react-query';
import { calculateEquidistantSteps } from '../utils';

const useAutoAdjust = (
  map: Map | null,
  variableSelection: ProjectTypes.VariableSelection[],
  sourceLayerId: string,
  maxNumberOfBuckets = 8
) => {
  const [firstVariable] = variableSelection;

  const { surveyName, datasetAbbrev, tableGuid, universeVariableUuid } = firstVariable;

  const tableMetadataQuery = useQuery<MetadataTypes.Table>(
    ['metadata/table', surveyName, datasetAbbrev, tableGuid],
    () => metadataApi.loadTable(surveyName, datasetAbbrev, tableGuid)
  );

  const variableGuid = firstVariable.variableGuid;
  const selectedVariableMetadata = tableMetadataQuery.data?.variables.find((v) => v.uuid === variableGuid);

  const isAllowed =
    variableSelection.length === 1 &&
    selectedVariableMetadata?.dataType !== 'String' &&
    selectedVariableMetadata?.varType !== 'None';

  const autoAdjust = async () => {
    if (!map) {
      console.warn("Can't auto adjust. Map is missing");
      return;
    }
    const queryRenderedFeatures = map.queryRenderedFeatures(undefined, { layers: [sourceLayerId] });

    if (!queryRenderedFeatures.length) return;

    const variables: number[] = queryRenderedFeatures
      .map((feature) => {
        if (
          universeVariableUuid != null &&
          feature.properties[universeVariableUuid] != null &&
          feature.properties[variableGuid] != null
        ) {
          return (feature.properties[variableGuid] / feature.properties[universeVariableUuid]) * 100;
        } else {
          return feature.properties[variableGuid];
        }
      })
      .filter((value: number) => value != null && isFinite(value));

    if (!variables.length) return;

    return calculateEquidistantSteps([...new Set(variables)], maxNumberOfBuckets);
  };

  return { autoAdjust, isAllowed };
};

export default useAutoAdjust;
