import React, { useEffect, useState } from 'react';
import { useStory } from '../../../hooks';
import { Loader } from '../../shared';
import ErrorPage from '../../shared/ErrorPage';
import Slide from '../Slide';

interface Props {
  storyId: string;
  defaultSlideId?: string;
}

const Edit: React.FC<Props> = ({ storyId, defaultSlideId }) => {
  const { storyQuery, slidesQuery } = useStory(storyId);
  const [currentSlideId, setCurrentSlideId] = useState<string | undefined>(defaultSlideId);
  const isLoading = storyQuery.isIdle || storyQuery.isLoading || slidesQuery.isIdle || slidesQuery.isLoading;
  const isError = storyQuery.isError || slidesQuery.isError;

  useEffect(() => {
    if (currentSlideId || !slidesQuery.data?.length) return;
    setCurrentSlideId(slidesQuery.data[0].id);
  }, [currentSlideId, slidesQuery.data]);

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  const slides = slidesQuery.data;
  const currentSlide = slides.find((x) => x.id === currentSlideId);
  const currentIndex = slides.findIndex((x) => x.id === currentSlideId);

  const handleMoveToPrevSlideRequest = () => {
    const prevIndex = Math.max(0, currentIndex - 1);
    setCurrentSlideId(slides[prevIndex].id);
  };
  const handleMoveToNextSlideRequest = () => {
    const nextIndex = Math.min(currentIndex + 1, slides.length - 1);
    setCurrentSlideId(slides[nextIndex].id);
  };

  return (
    <div className="flex h-full w-full">
      {currentSlide && (
        <Slide
          story={storyQuery.data}
          slide={currentSlide}
          slideIndex={currentIndex}
          mode="edit"
          onCurrentSlideChangeRequest={(sId) => setCurrentSlideId(sId)}
          onMoveToPrevSlideRequest={handleMoveToPrevSlideRequest}
          onMoveToNextSlideRequest={handleMoveToNextSlideRequest}
        />
      )}
    </div>
  );
};

export default Edit;
