import { Abacus as AbacusHooks } from '@platform/shared/hooks';
import { Modal } from '@platform/shared/ui';
import { AbacusTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React, { useEffect, useState } from 'react';
import * as peApi from '../../../../pe.api';
import ExpandableContainer from '../../../shared/ExpandableContainer';

const { useAbacusDatasetDownload, useAbacusHitsCount, useAbacusColumnsMetadata } = AbacusHooks;

interface Props {
  workingLayer: ProjectTypes.WorkingLayerSpec;
  onCloseRequest: () => void;
}

const formatCount = (value: number) => ValueFormatter.format(value, ProjectTypes.ValueFormat.FORMAT_NUMBER);

const DownloadModal: React.FC<Props> = ({ workingLayer, onCloseRequest }) => {
  const { datasetId, filter, name } = workingLayer;
  const [selectedCols, setSelectedCols] = useState<AbacusTypes.Column[]>([]);
  const [isRowsFilterIncluded, setIsRowsFilterIncluded] = useState<boolean>(true);

  const downloadMutation = useAbacusDatasetDownload({ api: peApi.getAbacusDataDownload });
  const abacusHitsResult = useAbacusHitsCount({ api: peApi.getAbacusRowCount, datasetId, dataQuery: filter });
  const abacusColumnsMetadataQuery = useAbacusColumnsMetadata({
    api: peApi.getAbacusColumnsMetadata,
    datasetId,
    filterableOnly: false,
  });

  const columns = abacusColumnsMetadataQuery.data;

  useEffect(() => {
    if (columns?.length && !selectedCols.length) {
      // set the initial state where all columns are selected
      setSelectedCols(columns);
    }
  }, [columns]);

  const handleDone = () => {
    downloadMutation.mutate(
      {
        datasetId,
        payload: {
          name,
          columnNames: selectedCols.map((x) => x.name),
          dataQuery: isRowsFilterIncluded ? filter : undefined,
        },
      },
      {
        onSuccess: () => {
          onCloseRequest();
        },
      }
    );
  };

  const handleChange = (col: AbacusTypes.Column) => {
    setSelectedCols((prevSelection) => {
      const index = prevSelection.findIndex((c) => c.name === col.name);
      const updatedSelection = [...prevSelection];

      if (index > -1) {
        // deselect
        updatedSelection.splice(index, 1);
      } else {
        // select
        updatedSelection.push(col);
      }

      return updatedSelection;
    });
  };

  const handleSelectAll = () => {
    if (!columns) return;
    if (selectedCols.length === columns.length) {
      setSelectedCols([]);
    } else {
      setSelectedCols([...columns]);
    }
  };

  const formattedTotalCount = abacusHitsResult.total != null ? formatCount(abacusHitsResult.total) : '?';
  const formattedFilteredCount = abacusHitsResult.hits != null ? formatCount(abacusHitsResult.hits) : '?';

  const rowsContainer = (
    <div className="flex w-full flex-col gap-5 py-2">
      <div className="flex w-full items-center justify-between gap-6">
        <div className="flex shrink-0 text-sm text-gray-800">Current view with filters</div>
        <div className="flex items-center gap-6 text-xs text-gray-500">
          <p className="truncate whitespace-pre-line break-all">{`${formattedFilteredCount} of ${formattedTotalCount} rows`}</p>
          <input
            name="select all"
            id="all"
            type="checkbox"
            checked={isRowsFilterIncluded}
            className="text-primary-600 focus:ring-primary-600 h-4 w-4 rounded-xl border-gray-300 hover:cursor-pointer"
            onChange={() => setIsRowsFilterIncluded(true)}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-6">
        <div className="flex text-sm text-gray-800">All rows</div>
        <div className="flex items-center gap-6 text-xs text-gray-500">
          <p className="flex shrink-0">{`${formattedTotalCount} rows`}</p>
          <input
            name="select all"
            id="all"
            type="checkbox"
            checked={!isRowsFilterIncluded}
            className="text-primary-600 focus:ring-primary-600 h-4 w-4 rounded-xl border-gray-300 hover:cursor-pointer"
            onChange={() => setIsRowsFilterIncluded(false)}
          />
        </div>
      </div>
    </div>
  );

  const columnsContainer = (
    <div className="flex w-full flex-col gap-4 py-2">
      <div className="flex w-full flex-col items-center justify-between">
        <div className="flex w-full items-center justify-between gap-6 py-2 text-sm text-gray-800">
          <p>{`All columns`}</p>
          <input
            name="select all"
            id="all"
            type="checkbox"
            checked={selectedCols.length === columns?.length}
            className="text-primary-600 focus:ring-primary-600 h-4 w-4 border-gray-300 hover:cursor-pointer"
            onChange={handleSelectAll}
          />
        </div>
        <div className="my-3 h-[1px] w-full bg-gray-300"></div>
        {columns?.map((col) => {
          const isChecked = selectedCols.find((c) => c.name === col.name) != null;
          return (
            <div key={col.name} className="flex w-full justify-between gap-6 py-2 text-sm text-gray-800">
              <p>{col.title}</p>
              <input
                name={col.title}
                id={col.name}
                type="checkbox"
                checked={isChecked}
                className="text-primary-600 focus:ring-primary-600 h-4 w-4 border-gray-300 hover:cursor-pointer"
                onChange={() => handleChange(col)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
  let columnsHint;

  if (selectedCols.length === columns?.length) {
    columnsHint = `All columns (${selectedCols.length})`;
  } else {
    const columnText = selectedCols.length === 1 ? 'column' : 'columns';
    columnsHint = `${selectedCols.length} ${columnText}`;
  }

  const rowsHint = isRowsFilterIncluded
    ? `Current view (${formattedFilteredCount} of ${formattedTotalCount} rows)`
    : 'All rows';

  return (
    <Modal
      title={`Download "${name}" data`}
      className="w-[512px]"
      okLabel={downloadMutation.isLoading ? 'Downloading...' : 'Download CSV'}
      okDisabled={
        downloadMutation.isLoading || !selectedCols.length || abacusHitsResult.hits === 0 || !abacusHitsResult.hits
      }
      onDone={handleDone}
      onCloseRequest={onCloseRequest}
    >
      <div className="-mx-6 flex flex-col">
        <div className="max-h-[440px] min-h-[440px] flex-col overflow-auto px-6">
          <div className=" flex flex-col gap-2">
            <ExpandableContainer
              title="Rows"
              hint={rowsHint}
              children={rowsContainer}
              containerClasses={'border-b border-gray-300 pt-2 pb-4'}
              hasToggle={false}
            />
            <ExpandableContainer
              title="Columns"
              hint={columnsHint}
              children={columnsContainer}
              containerClasses="py-2"
              hasToggle={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
