import NewPassword from '../components/Account/NewPassword/NewPassword';

const NewPasswordPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const accessCode = searchParams.get('accessCode');
  if (!accessCode) {
    return null;
  }
  return <NewPassword accessCode={accessCode} />;
};

export default NewPasswordPage;
