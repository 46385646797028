import { Buttons, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import DownloadCsvModal from './DownloadCsvModal';

interface Props {
  datasetId: string;
  disabled?: boolean;
  hasFilters?: boolean;
}

const DownloadCsvButton: React.FC<Props> = ({ datasetId, disabled = false, hasFilters }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOnDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowModal(true);
    e.preventDefault();
  };

  return (
    <div className="flex">
      <Buttons.Secondary
        disabled={disabled}
        icon={<MUIcon name="download" className="text-gray-600" />}
        onClick={handleOnDownload}
        data-rh="Download"
        className="rounded-full border-0 bg-white p-2.5 shadow-none ring-gray-200"
      />
      {showModal && (
        <DownloadCsvModal onCloseRequest={() => setShowModal(false)} datasetId={datasetId} hasFilters={hasFilters} />
      )}
    </div>
  );
};

export default DownloadCsvButton;
