import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { STORY_DROPDOWN_OPTIONS } from '../../enums/dropdown';
import { useStory } from '../../hooks';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import Thumb from '../Thumb';
import CopyStoryModal from './CopyStoryModal';
import RenameStoryModal from './RenameStoryModal';

interface Props {
  story: PeTypes.RecentStory;
}

const RecentStoriesListItem: React.FC<Props> = ({ story }) => {
  const queryClient = useQueryClient();
  const { storyQuery } = useStory(story.id);
  const { activeWorkspace } = useWorkspaceContext();
  const [requestedAction, setRequestedAction] = useState<ProjectTypes.MenuActions | null>(null);

  const deleteStoryMutation = useMutation({
    mutationFn: async ({ storyId }: { storyId: string }) => peApi.softDeleteStory(storyId),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects', activeWorkspace.id] });
      await storyQuery.refetch();
    },
  });

  const onDeleteStory = () => {
    deleteStoryMutation.mutate({ storyId: story.id });
    setRequestedAction(null);
  };

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case ProjectTypes.MenuActions.DELETE:
        setRequestedAction(ProjectTypes.MenuActions.DELETE);
        break;
      case ProjectTypes.MenuActions.RENAME:
        setRequestedAction(ProjectTypes.MenuActions.RENAME);
        break;
      case ProjectTypes.MenuActions.COPY:
        setRequestedAction(ProjectTypes.MenuActions.COPY);
        break;
      default:
        return;
    }
  };

  const { id, title, slidesCount, thumbPath, projectId } = story;
  const numberOfSlidesLabel = `${slidesCount} Slide${slidesCount === 1 ? '' : 's'}`;

  return (
    <>
      <Link
        to={generateLink('story', { workspaceId: activeWorkspace.id, storyId: story.id, mode: 'edit' })}
        className="group flex min-w-[24.53%] max-w-[300px] flex-col rounded-[16px] hover:shadow-lg"
      >
        <div className="relative">
          <div className="group flex flex-shrink-0 flex-col rounded-[16px] border text-left shadow">
            <Thumb url={thumbPath} className={'h-[120px] w-full rounded-t-[16px] rounded-b-none'} />
            <div className="flex flex-col rounded-b-[16px] bg-white px-4 text-left text-base font-bold leading-6 text-gray-800">
              <span className="mt-3 truncate group-hover:relative">{title}</span>
              <span className="mt-2 text-sm font-normal text-gray-500">{numberOfSlidesLabel}</span>
              <div className="group-hover:text-primary-600 my-4 flex justify-between text-sm font-semibold text-gray-600">
                <span className="flex">Open</span>
                <MUIcon
                  name="arrow_forward"
                  className="group-hover:text-primary-600 m-0 mr-2 flex h-5 w-5 text-gray-600"
                />
              </div>
            </div>
            <div className="absolute right-5 top-6">
              <div onClick={(e) => e.preventDefault()} data-cy="recent-stories-options">
                <Dropdowns.Dropdown
                  icon={<MUIcon name="more_horiz" className="text-gray-600" />}
                  iconClose={null}
                  triggerClasses={'rounded-full'}
                  options={STORY_DROPDOWN_OPTIONS}
                  onSelect={onSelectDropdownOption}
                  hAlignment="right"
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
      {requestedAction === ProjectTypes.MenuActions.DELETE && (
        <DeleteConfirmationModal
          title="Delete story"
          message={`Are you sure you want to delete this story? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={onDeleteStory}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.RENAME && (
        <RenameStoryModal
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
          story={story}
          isRecentStory
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.COPY && (
        <CopyStoryModal
          storyId={id}
          storyTitle={title}
          activeProjectId={projectId}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
    </>
  );
};

export default RecentStoriesListItem;
