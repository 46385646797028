import { AbacusTypes, MetadataTypes, PeTypes, ProjectTypes } from '@platform/types';
import axios, { AxiosResponse } from 'axios';
import { StyleSpecification } from 'maplibre-gl';
import { ANONYMOUS_ACCESS_TOKEN_STORAGE_ITEM_ID } from './contexts/SessionContext';

export const ApiBase = axios.create({
  baseURL: `${process.env.NX_PE_SERVER}/api`,
  withCredentials: true,
});

let workspaceId = 0;

export const setWorkspace = (workspaceIdToUse: number) => {
  workspaceId = workspaceIdToUse;
};

const JWT_TOKEN = process.env.NX_JWT_TOKEN as string;

// Add a request interceptor
ApiBase.interceptors.request.use(function (config) {
  const jwt = localStorage.getItem(JWT_TOKEN);
  const anonymousAccessToken = sessionStorage.getItem(ANONYMOUS_ACCESS_TOKEN_STORAGE_ITEM_ID);

  if (anonymousAccessToken) {
    config.headers.Authorization = `Anonymous ${anonymousAccessToken}`;
  } else if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
    config.headers.Workspace = workspaceId;
  }

  return config;
});

export const getUser = (userId: number): Promise<PeTypes.User> =>
  ApiBase.get<PeTypes.User>(`/session/users/${userId}`).then((r) => r.data);

export const me = (): Promise<PeTypes.User> =>
  ApiBase.get<PeTypes.User>(`/session/whoami`, { withCredentials: true }).then((r) => r.data);

export const changePassword = (payload: PeTypes.NewPasswordPayload): Promise<string> =>
  ApiBase.post<string>(`/public/auth/change-password`, payload).then((r) => r.data);

export const login = (payload: ProjectTypes.LoginPayload): Promise<string> =>
  ApiBase.post<string>(`/public/auth/login`, payload).then((r) => r.data);

export const resetPassword = (payload: PeTypes.ResetPasswordPayload): Promise<string> =>
  ApiBase.post<string>(`/public/auth/reset-password-request`, payload).then((r) => r.data);

export const sendEmailVerification = (payload: PeTypes.EmailVerificationPayload): Promise<void> =>
  ApiBase.post<void>(`/public/auth/send-email-verification`, payload).then((r) => r.data);

export const confirmVerificationCode = (payload: PeTypes.ConfirmVerificationCodePayload): Promise<void> =>
  ApiBase.post<void>(`/public/auth/confirm-verification-code`, payload).then((r) => r.data);

export const getInvitationDetails = (invitationId: string): Promise<PeTypes.InvitationDetails> =>
  ApiBase.get<PeTypes.InvitationDetails>(`/public/auth/invitation-details`, { params: { invitationId } }).then(
    (r) => r.data
  );

export const createAccount = (payload: PeTypes.CreateAccountPayload): Promise<void> =>
  ApiBase.post<void>(`/public/auth/create-account`, payload).then((r) => r.data);

export const updateAccountInfo = (payload: PeTypes.AccountInfoPayload): Promise<string> =>
  ApiBase.post<string>(`/user`, payload).then((r) => r.data);

export const updateAccountImage = (formData: FormData | null): Promise<void> =>
  ApiBase.post<void>(`/user/image`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((r) => r.data);

export const logout = (): Promise<void> =>
  ApiBase.delete<void>(`/session`, { withCredentials: true }).then((r) => r.data);

export const getDefaultMaps = (): Promise<PeTypes.DefaultMap[]> =>
  ApiBase.get<PeTypes.DefaultMap[]>(`/default-maps`).then((r) => r.data);

export const getCalculationData = (payload: PeTypes.SummarizeInsigths): Promise<PeTypes.Report> =>
  ApiBase.post<PeTypes.Report>('/reporting/summarize', payload).then((r) => r.data);

export const getBaseMapStyle = (baseMapId: string): Promise<StyleSpecification> =>
  ApiBase.get<StyleSpecification>(`/map-tiler/base/${baseMapId}`).then((r) => r.data);

export const getProfiles = (projectId: string | undefined, watchedOnly = false): Promise<PeTypes.Profile[]> =>
  ApiBase.get<PeTypes.Profile[]>(`/profiles`, {
    params: {
      projectId: projectId,
      watchedOnly,
    },
  }).then((r) => r.data);

export const createProfile = (payload: PeTypes.CreateProfilePayload): Promise<string> =>
  ApiBase.post<string>(`/profiles`, payload).then((r) => r.data);

export const watchProfile = (profileId: string, watch: boolean): Promise<void> =>
  ApiBase.post<void>(`/profiles/${profileId}/watch`, { watch }).then((r) => r.data);

export const getProfile = (profileId: string): Promise<PeTypes.Profile> =>
  ApiBase.get<PeTypes.Profile>(`/profiles/${profileId}`).then((r) => r.data);

export const softDeleteProfile = (profileId: string): Promise<string> =>
  ApiBase.delete<string>(`/profiles/${profileId}`).then((r) => r.data);

export const renameProfile = (profileId: string, newTitle: string): Promise<void> =>
  ApiBase.post<void>(
    `/profiles/${profileId}/rename`,
    { newTitle },
    {
      withCredentials: true,
    }
  ).then((r) => r.data);

export const copyProfile = (profileId: string, payload: PeTypes.CopyProfilePayload): Promise<string> =>
  ApiBase.post<string>(`/profiles/${profileId}/copy`, payload, { withCredentials: true }).then((r) => r.data);

export const updateProfileThumbPath = (profileId: string, thumbPath: string): Promise<string> =>
  ApiBase.post<string>(
    `/profiles/${profileId}/thumb`,
    { thumbPath },
    {
      withCredentials: true,
    }
  ).then((r) => r.data);

export const updateProfileTimestamp = (profileId: string): Promise<void> =>
  ApiBase.post<void>(`/profiles/${profileId}/timestamp`, {
    withCredentials: true,
  }).then((r) => r.data);

export const updateSlideThumbPath = (storyId: string, slideId: string, thumbPath: string): Promise<string> =>
  ApiBase.post<string>(
    `/stories/${storyId}/slides/${slideId}/thumb`,
    { thumbPath },
    {
      withCredentials: true,
    }
  ).then((r) => r.data);

export const updateSlideTimestamp = (storyId: string, slideId: string): Promise<void> =>
  ApiBase.post<void>(`/stories/${storyId}/slides/${slideId}/timestamp`, {
    withCredentials: true,
  }).then((r) => r.data);

export const getProjects = (): Promise<PeTypes.Project[]> =>
  ApiBase.get<PeTypes.Project[]>(`/projects`).then((r) => r.data);

export const getRecentProjects = (payload: PeTypes.QueryRecentProjectPayload): Promise<PeTypes.RecentProject[]> =>
  ApiBase.get<PeTypes.RecentProject[], AxiosResponse, PeTypes.QueryRecentProjectPayload>(`/projects/recent`, {
    params: payload,
  }).then((r) => r.data);

export const getProject = (id: string): Promise<PeTypes.Project> =>
  ApiBase.get<PeTypes.Project>(`/projects/${id}`).then((r) => r.data);

export const softDeleteProject = (id: string): Promise<void> =>
  ApiBase.delete<void>(`/projects/${id}`).then((r) => r.data);

export const renameProject = (projectId: string, newTitle: string): Promise<void> =>
  ApiBase.post<void, AxiosResponse, PeTypes.RenameProject>(
    `/projects/${projectId}/rename`,
    { newTitle },
    {
      withCredentials: true,
    }
  ).then((r) => r.data);

export const createProject = (payload: PeTypes.CreateProject): Promise<string> =>
  ApiBase.post<string>(`/projects`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const getDatasets = (payload: PeTypes.QueryDatasetsPayload): Promise<PeTypes.QueryDatasetsResponse> =>
  ApiBase.post<PeTypes.QueryDatasetsResponse>(`/datasets`, payload, { withCredentials: true }).then((r) => r.data);

export const getDataset = (datasetId: string): Promise<PeTypes.Dataset> =>
  ApiBase.get<PeTypes.Dataset>(`/datasets/${datasetId}`, {
    withCredentials: true,
  }).then((r) => r.data);

export const getDatasetLastPublishedState = (datasetId: string): Promise<PeTypes.DatasetPublication> =>
  ApiBase.get<PeTypes.DatasetPublication>(`/datasets/${datasetId}/last-published-state`, {
    withCredentials: true,
  }).then((r) => r.data);

export const getDatasetUnpublishedLog = (
  datasetId: string,
  includeMetadata: boolean
): Promise<PeTypes.DatasetLogItem[]> =>
  ApiBase.get<PeTypes.DatasetLogItem[]>(`/datasets/${datasetId}/log/unpublished`, {
    params: { includeMetadata },
    withCredentials: true,
  }).then((r) => r.data);

export const getDatasetColumns = (payload: PeTypes.QueryDatasetColumnPayload): Promise<PeTypes.DatasetColumn[]> =>
  ApiBase.post<PeTypes.DatasetColumn[]>(`/datasets/${payload.datasetId}/columns`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const getDatasetData = (payload: PeTypes.QueryDatasetDataPayload): Promise<PeTypes.QueryDataResponse> =>
  ApiBase.post<PeTypes.QueryDataResponse>(`/datasets/${payload.datasetId}/data`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const editColumnMetadata = (
  datasetId: string,
  columnId: string,
  payload: PeTypes.EditColumnMetadataRequest
): Promise<void> =>
  ApiBase.post<void>(`/datasets/${datasetId}/column/${columnId}`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const editDatasetMetadata = (datasetId: string, payload: { rowSubject: string }): Promise<void> =>
  ApiBase.post<void>(`/datasets/${datasetId}/metadata`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const addDatasetRow = (datasetId: string, payload: PeTypes.AddRowPayload): Promise<void> =>
  ApiBase.post(`/datasets/${datasetId}/rows`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const addDatasetColumn = (datasetId: string, payload: PeTypes.AddColumnPayload): Promise<void> =>
  ApiBase.post(`/datasets/${datasetId}/columns/create`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const combineDatasetColumns = (datasetId: string, payload: PeTypes.CombineColumnsPayload): Promise<void> =>
  ApiBase.post(`/datasets/${datasetId}/columns/combine`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const editDatasetCell = (payload: PeTypes.CellEditPayload): Promise<string> =>
  ApiBase.post<string>(`/datasets/${payload.datasetId}/cell-edit`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const duplicateColumn = (datasetId: string, payload: PeTypes.DuplicateColumnPayload): Promise<string> =>
  ApiBase.post<string>(`/datasets/${datasetId}/columns/duplicate`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const deleteColumn = (datasetId: string, columnId: string): Promise<string> =>
  ApiBase.delete<string>(`/datasets/${datasetId}/${columnId}`).then((r) => r.data);

export const publishDataset = (datasetId: string): Promise<string> =>
  ApiBase.post<string>(`/datasets/${datasetId}/publish`, {
    withCredentials: true,
  }).then((r) => r.data);

export const exportCsv = (payload: PeTypes.ExportCsvFromGeoBuffer): Promise<PeTypes.Export> =>
  ApiBase.post<PeTypes.Export>(`/datasets/${payload.datasetId}/export`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const deleteDataset = (id: string, soft: boolean): Promise<void> =>
  ApiBase.delete<void>(`/datasets/${id}/delete`, {
    params: {
      soft,
    },
  }).then((r) => r.data);

export const renameDataset = (id: string, payload: PeTypes.RenameDatasetPayload): Promise<string> =>
  ApiBase.post<string>(`/datasets/${id}/rename`, payload).then((r) => r.data);

export const copyDataset = (datasetId: string, payload: PeTypes.CopyDatasetPayload): Promise<void> =>
  ApiBase.post<void>(`/datasets/${datasetId}/copy`, payload).then((r) => r.data);

export const getDatasetsInsights = (datasetId: string): Promise<PeTypes.DatasetInsightsBasicInfo[]> =>
  ApiBase.get<PeTypes.DatasetInsightsBasicInfo[]>(`/datasets/${datasetId}/insights`, {
    withCredentials: true,
  }).then((r) => r.data);

export const getStories = (projectId?: string, pageNumber?: number, itemsPerPage?: number): Promise<PeTypes.Story[]> =>
  ApiBase.get<PeTypes.Story[]>(`/stories`, { params: { projectId, pageNumber, itemsPerPage } }).then((r) => r.data);

export const getRecentStories = (pageNumber: number, itemsPerPage: number): Promise<PeTypes.Story[]> =>
  ApiBase.get<PeTypes.Story[]>(`/stories/recent`, { params: { pageNumber, itemsPerPage } }).then((r) => r.data);

export const getStory = (storyId: string): Promise<PeTypes.Story> =>
  ApiBase.get<PeTypes.Story>(`/stories/${storyId}`).then((r) => r.data);

export const softDeleteStory = (storyId: string): Promise<string> =>
  ApiBase.delete<string>(`/stories/${storyId}`).then((r) => r.data);

export const renameStory = (storyId: string, newTitle: string): Promise<string> =>
  ApiBase.post<string>(
    `/stories/${storyId}/rename`,
    { newTitle },
    {
      withCredentials: true,
    }
  ).then((r) => r.data);

export const getStorySlides = (storyId: string): Promise<PeTypes.StorySlide[]> =>
  ApiBase.get<PeTypes.StorySlide[]>(`/stories/${storyId}/slides`).then((r) => r.data);

export const updateSlideText = (slideId: string, storyId: string, text: string): Promise<string> =>
  ApiBase.post<string>(`/stories/${storyId}/slides/${slideId}/text`, { text }).then((r) => r.data);

export const reorderStorySlides = (storyId: string, payload: PeTypes.NewSlideOrdering): Promise<string> =>
  ApiBase.post<string>(`/stories/${storyId}/slides/${payload.slideId}/reorder`, payload).then((r) => r.data);

export const addSlideToStory = (storyId: string, payload: PeTypes.AddStorySlide): Promise<string> =>
  ApiBase.put<string>(`/stories/${storyId}/slides`, payload).then((r) => r.data);

export const deleteSlideFromStory = (storyId: string, slideId: string): Promise<void> =>
  ApiBase.delete<void>(`/stories/${storyId}/slides/${slideId}`).then((r) => r.data);

export const getSignedUrl = (path: string): Promise<PeTypes.SignS3> =>
  ApiBase.get<PeTypes.SignS3>(`/s3/sign`, { params: { path } }).then((r) => r.data);

export const createStory = (payload: PeTypes.CreateStoryPayload): Promise<string> =>
  ApiBase.post<string>(`/stories`, payload).then((r) => r.data);

export const copyStory = (storyId: string, payload: PeTypes.CopyStoryPayload): Promise<string> =>
  ApiBase.post<string>(`/stories/${storyId}/copy`, payload, { withCredentials: true }).then((r) => r.data);

export const getSelectionInsights = (
  varSelection: ProjectTypes.VariableSelection,
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[]
): Promise<PeTypes.Report> =>
  ApiBase.post<PeTypes.Report>(
    `/survey-insights/${varSelection.surveyName}/tables/${varSelection.tableGuid}/summarize`,
    { varSelection, geoFeatureSelection },
    {
      withCredentials: true,
    }
  ).then((r) => r.data);

export const getSurveyInsights = (surveyName: string): Promise<PeTypes.SurveyInsightsBasicInfo[]> =>
  ApiBase.get<PeTypes.SurveyInsightsBasicInfo[]>(`/survey-insights/${surveyName}`, {
    withCredentials: true,
  }).then((r) => r.data);

export const summarizeSurveyInsights = (
  surveyInsightsId: string,
  payload: PeTypes.SummarizeSurveyRequest
): Promise<PeTypes.Report> =>
  ApiBase.post<PeTypes.Report>(`/survey-insights/${surveyInsightsId}/summarize`, payload, {
    withCredentials: true,
  }).then((r) => r.data);

export const getGeoRecords = (payload: PeTypes.GeoSearch): Promise<PeTypes.GeoRecord[]> =>
  ApiBase.get<PeTypes.GeoRecord[]>(`/geo-search`, {
    params: payload,
    withCredentials: true,
  }).then((r) => r.data);

export const generateAnonymousAccessToken = (): Promise<string> =>
  ApiBase.get<string>(`/session/aat`, {
    withCredentials: true,
  }).then((r) => r.data);

export const getMapContext = (mapContextId: string): Promise<ProjectTypes.MapContext> =>
  ApiBase.get<ProjectTypes.MapContext>(`/map-contexts/${mapContextId}`, {
    withCredentials: true,
  }).then((r) => r.data);

export const patchMapContextItems = (mapContextId: string, patch: ProjectTypes.PatchMapContext): Promise<void> =>
  ApiBase.patch<void>(`/map-contexts/${mapContextId}`, patch, {
    withCredentials: true,
  }).then((r) => r.data);

export const getColorPalettes = (surveyName: string): Promise<MetadataTypes.ColorPalette[]> =>
  ApiBase.get<MetadataTypes.ColorPalette[]>(`/dingo/${surveyName}/color-palettes`).then((r) => r.data);

export const getWorkspace = (workspaceId: number): Promise<PeTypes.Workspace> =>
  ApiBase.get<PeTypes.Workspace>(`/session/workspaces/${workspaceId}`, { withCredentials: true }).then((r) => r.data);

export const getWorkspaceSubscription = (workspaceId: number): Promise<PeTypes.WorkspaceSubscription> =>
  ApiBase.get<PeTypes.WorkspaceSubscription>(`/session/workspaces/${workspaceId}/subscription`, {
    withCredentials: true,
  }).then((r) => r.data);

export const getWorkspaces = (): Promise<PeTypes.Workspace[]> =>
  ApiBase.get<PeTypes.Workspace[]>(`/session/workspaces`, { withCredentials: true }).then((r) => r.data);

export const renameWorkspace = (name: string): Promise<void> =>
  ApiBase.post<void>(`/workspace/rename`, { name }).then((r) => r.data);

export const getWorkspaceMembers = (): Promise<PeTypes.WorkspaceMember[]> =>
  ApiBase.get<PeTypes.WorkspaceMember[]>(`/workspace/users`).then((r) => r.data);

export const getPendingWorkspaceMembers = (): Promise<PeTypes.PendingInvitation[]> =>
  ApiBase.get<PeTypes.PendingInvitation[]>(`/workspace/invites/pending`).then((r) => r.data);

export const searchUser = (searchTerm: string): Promise<PeTypes.WorkspaceMember[]> =>
  ApiBase.get<PeTypes.WorkspaceMember[]>(`/workspace/users/search`, { params: { searchTerm } }).then((r) => r.data);

export const inviteUser = (payload: PeTypes.InviteMemberPayload): Promise<void> =>
  ApiBase.post<void>(`/workspace/invites`, { ...payload }).then((r) => r.data);

export const removeUser = (userId: number): Promise<void> =>
  ApiBase.delete<void>(`/workspace/users/${userId}`).then((r) => r.data);

export const promoteUser = (userId: number): Promise<void> =>
  ApiBase.post<void>(`/workspace/users/${userId}/promote`, { userId }).then((r) => r.data);

export const demoteUser = (userId: number): Promise<void> =>
  ApiBase.post<void>(`/workspace/users/${userId}/demote`, { userId }).then((r) => r.data);

export const resendWorkspaceInvitation = (invitationId: number): Promise<void> =>
  ApiBase.post<void>(`/workspace/invites/${invitationId}/resend`).then((r) => r.data);

export const revokeWorkspaceInvitation = (invitationId: number, identityId: number): Promise<void> =>
  ApiBase.post<void>(`/workspace/invites/${invitationId}/revoke`, { identityId }).then((r) => r.data);

export const updateWorkspaceImage = (formData: FormData): Promise<void> =>
  ApiBase.post<void>(`/workspace/image`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((r) => r.data);

export const getWorkspaceStatistics = (): Promise<PeTypes.WorkspaceStatistics> =>
  ApiBase.get<PeTypes.WorkspaceStatistics>(`/workspace/statistics`).then((r) => r.data);

export const getSurveys = (): Promise<ProjectTypes.SurveyInfo[]> =>
  ApiBase.get<ProjectTypes.SurveyInfo[]>(`/surveys`).then((r) => r.data);

export const getSurvey = (surveyName: string, isUserSurvey: boolean): Promise<ProjectTypes.SurveyInfo> =>
  ApiBase.get<ProjectTypes.SurveyInfo>(`/surveys/${surveyName}`, { params: { isUserSurvey } }).then((r) => r.data);

export const getDataSourceLayers = (
  surveyName: string,
  isUserSurvey: boolean
): Promise<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]> =>
  ApiBase.get<MetadataTypes.MetadataSocialExplorerDataSourceLayer[]>(
    `/surveys/data-sources/${surveyName}?isUserSurvey=${isUserSurvey}`
  ).then((r) => r.data);

export const getAbacusColumnsMetadata = (datasetId: string, filterableOnly: boolean): Promise<AbacusTypes.Column[]> =>
  ApiBase.get<AbacusTypes.Column[]>(`/abacus/${datasetId}/metadata/columns`, { params: { filterableOnly } }).then(
    (r) => r.data
  );

export const getAbacusData = (datasetId: string, payload: AbacusTypes.DataRequest): Promise<AbacusTypes.Data> =>
  ApiBase.post<AbacusTypes.Data>(`/abacus/${datasetId}/data`, payload).then((r) => r.data);

export const getAbacusRowCount = (datasetId: string, payload: AbacusTypes.CountDatasetRowsRequest): Promise<number> =>
  ApiBase.post<number>(`/abacus/${datasetId}/rows/count`, payload).then((r) => r.data);

export const getAbacusDataDownload = (
  datasetId: string,
  payload: AbacusTypes.DownloadAbacusDataRequest
): Promise<AxiosResponse> =>
  ApiBase.post<AxiosResponse>(`/abacus/${datasetId}/download`, payload, { responseType: 'blob' });

export const getColumnsMetadata = (datasetId: string): Promise<PeTypes.DatasetColumnMetadata[]> =>
  ApiBase.get<PeTypes.DatasetColumnMetadata[]>(`/datasets/${datasetId}/columns/metadata`).then((r) => r.data);

export const updateColumnsMetadata = (
  datasetId: string,
  payload: PeTypes.UpdateDatasetColumnMetadataPayload
): Promise<void> => ApiBase.post<void>(`/datasets/${datasetId}/columns/metadata`, payload).then((r) => r.data);

export const importDataset = (payload: PeTypes.ImportDatasetPayload): Promise<PeTypes.ImportDatasetResponse> =>
  ApiBase.post<PeTypes.ImportDatasetResponse>(`/dataset-imports/imports`, payload).then((r) => r.data);

export const getRecentImports = (): Promise<PeTypes.DatasetImport[]> =>
  ApiBase.get<PeTypes.DatasetImport[]>(`/dataset-imports/imports`).then((r) => r.data);

export const startImport = (id: string): Promise<void> =>
  ApiBase.post<void>(`/dataset-imports/imports/${id}/start`).then((r) => r.data);

export const geocodeSample = (payload: PeTypes.GeocodeRequestPayload): Promise<PeTypes.GeocodeResponse> =>
  ApiBase.post<PeTypes.GeocodeResponse>(`/dataset-imports/imports/geocode`, payload).then((r) => r.data);

export const getUserNotifications = (offset: number): Promise<PeTypes.UserNotification<object>[]> =>
  ApiBase.get<PeTypes.UserNotification<object>[]>(`/notifications`, {
    params: {
      offset,
    },
  }).then((r) => r.data);

export const markUserNotificationAsRead = (notificationId: string): Promise<void> =>
  ApiBase.put<void>(`/notifications/${notificationId}/mark-as-read`).then((r) => r.data);

export const markAllUserNotificationsAsRead = (): Promise<void> =>
  ApiBase.put<void>(`/notifications/mark-as-read`).then((r) => r.data);

export const getUnreadNotificationCount = (): Promise<PeTypes.UserNotificationCountPerWorkspace[]> =>
  ApiBase.get<PeTypes.UserNotificationCountPerWorkspace[]>(`/notifications/count`).then((r) => r.data);

export const deleteRows = (datasetId: string, rowsUuids: string[]): Promise<string> =>
  ApiBase.delete<string>(`/datasets/${datasetId}/rows/bulk-delete`, { data: { rowsUuids } }).then((r) => r.data);
