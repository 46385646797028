import * as GeoJSON from 'geojson';
import { DataType, Extension } from './types';

export const extractFilenameAndExtension = (
  filename: string
): {
  base: string;
  extension: string | null;
} => {
  // Find the last occurrence of '.'
  const lastDotIndex = filename.lastIndexOf('.');

  // Check if the '.' is found and not at the start of the filename
  if (lastDotIndex > 0) {
    return {
      base: filename.substring(0, lastDotIndex),
      extension: filename.substring(lastDotIndex + 1),
    };
  }
  // Handle cases without an extension or starting with '.'
  return {
    base: filename,
    extension: null, // No extension found
  };
};

export const extractExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf('.');

  // If there's no dot or the dot is at the start, it's not an extension
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return ''; // No extension found
  }

  return fileName.substring(lastDotIndex + 1);
};

const guessType = (value: unknown): DataType => {
  // Check for nullish or empty values first
  if (value == null || !value.toString().trim().length) return 'unknown';

  // Process non-nullish values as strings for further type guessing
  const stringValue = value.toString().trim().toLowerCase();

  // Check for boolean values represented as strings
  if (stringValue === 'true' || stringValue === 'false') return 'boolean';

  // Check for numeric values
  if (!isNaN(Number(stringValue)) && stringValue.length) return 'number';

  // Fallback to text if it doesn't match other types
  if (stringValue.length) return 'text';

  // Return 'unknown' for anything else, like empty strings or objects without a meaningful toString() representation
  return 'unknown';
};

export const guessStrictArrayType = (values: Array<unknown | null>): DataType => {
  // Filter out null values first
  const nonNullValues = values.filter((value) => value?.toString().trim().length);

  if (nonNullValues.length === 0) return 'empty';

  const [firstNonNullValue, ...restNonNullValues] = nonNullValues;
  const firstType: DataType = guessType(firstNonNullValue);

  // Check if all non-null values are of the same type
  for (const value of restNonNullValues) {
    const guessedType = guessType(value);
    if (guessedType === 'unknown') continue;
    if (guessedType !== firstType) {
      return 'text'; // Default to 'text' if types are inconsistent among non-null values
    }
  }

  return firstType !== 'unknown' ? firstType : 'text'; // Return the type or 'text' if undetermined
};

export const getSample = (dataRows: unknown[][], sampleSize?: number) => {
  // TODO: is it possible to get a better sample (like every other or from start, middle and end etc)
  return dataRows.slice(0, sampleSize ?? dataRows.length);
};

export const doesMatch = (shpFileName: string, ext: Extension) => (file: File) => {
  const { base, extension } = extractFilenameAndExtension(file.name);
  return base === shpFileName && extension === ext;
};

export const parseGeoJsonFeatureCollection = (
  featureCollection: GeoJSON.FeatureCollection,
  limit?: number
): {
  columns: string[];
  data: unknown[][];
} => {
  const { features } = featureCollection;

  if (!features?.length) {
    return { columns: [], data: [] };
  }

  // map geo feature props into array of columns and data rows
  const columns = Object.keys(features[0].properties ?? {});
  const data = features.slice(0, limit ?? features.length).reduce((p: unknown[][], feature) => {
    return [...p, columns.map((col) => (feature.properties ? feature.properties[col] : null))];
  }, []);

  return { columns, data };
};
