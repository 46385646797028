import { Abacus } from '@platform/shared/hooks';
import { AbacusTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import pluralize from 'pluralize';
import React from 'react';
import { UseQueryOptions } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';

interface Props {
  datasetId: string;
  api: (datasetId: string, payload: AbacusTypes.CountDatasetRowsRequest) => Promise<number>;
  showOnlyTotal?: boolean;
  suffixLabel?: string;
  dataQuery?: RuleGroupType;
  baseQuery?: RuleGroupType;
  options?: UseQueryOptions<number>;
}

const formatCount = (value: number | null) =>
  value != null ? ValueFormatter.format(value, ProjectTypes.ValueFormat.FORMAT_NUMBER) : '?';

const HitsCount: React.FC<Props> = ({
  datasetId,
  api,
  dataQuery,
  baseQuery,
  suffixLabel,
  options,
  showOnlyTotal = false,
}) => {
  const abacusHitsResult = Abacus.useAbacusHitsCount({
    api,
    datasetId,
    dataQuery,
    baseQuery,
    options: { ...options },
  });

  const { hits, total, isError, isLoading } = abacusHitsResult;

  if (isLoading) return <span className="text-gray-500">...</span>;
  if (isError || total == null || hits == null) return <span>N/A</span>;

  let counts;

  if (showOnlyTotal || hits === total) {
    counts = formatCount(total);
  } else {
    counts = `${formatCount(hits)} of ${formatCount(total)}`;
  }

  return (
    <span className="text-sm text-gray-500">{`${counts} ${pluralize(
      suffixLabel ?? 'record',
      total
    ).toLowerCase()}`}</span>
  );
};

export default HitsCount;
