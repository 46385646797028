import { PeTypes } from '@platform/types';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useMutation, useQueryClient } from 'react-query';
import * as peApi from '../../../pe.api';

const toolbarOptions = {
  options: ['blockType', 'inline', 'list', 'textAlign'],
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  inline: {
    options: ['bold', 'italic'],
  },
  textAlign: {
    options: ['left', 'center', 'right'],
  },
};

interface Props {
  slide: PeTypes.StorySlide;
  onTextChange?: (slide: PeTypes.StorySlide, newText: string) => void;
}

const EditSlideNote: React.FC<Props> = ({ slide }) => {
  const queryClient = useQueryClient();
  const blocksFromHTML = convertFromHTML(slide.text);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  const [, setContentState] = useState(convertToRaw(ContentState.createFromText(slide.text)));
  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(slide.text);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(state));
  }, [slide]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateSlideMutation = useMutation({
    mutationFn: async ({ slideId, storyId, text }: { slideId: string; storyId: string; text: string }) => {
      await peApi.updateSlideText(slideId, storyId, text);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['story-slides', slide.storyId],
      });
    },
  });

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const plainText = draftToHtml(rawContentState);

      if (slide.text === plainText) return;

      updateSlideMutation.mutate({
        slideId: slide.id,
        storyId: slide.storyId,
        text: plainText,
      });
    }, 350);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [editorState]);

  return (
    <div className="h-full overflow-y-auto p-4">
      <Editor
        editorState={editorState}
        placeholder="Enter text..."
        wrapperClassName={'bg-white text-black overflow-y-auto'}
        editorClassName="overflow-y-auto h-full prose"
        stripPastedStyles={true}
        toolbar={toolbarOptions}
        toolbarStyle={{
          backgroundColor: '#F3F4F6',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          borderRadius: '8px',
        }}
        onEditorStateChange={setEditorState}
        spellCheck
        onContentStateChange={setContentState}
      />
    </div>
  );
};

export default memo(EditSlideNote, (prevProps, nextProps) => prevProps.slide.id === nextProps.slide.id);
