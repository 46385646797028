import { Pagination } from '@platform/shared/ui';
import { DatasetTypes } from '@platform/types';
import React from 'react';
import DatasetJumpToColumn from './DatasetJumpToColumn';

interface Props {
  rowPage: number;
  colPage: number;
  availableNumberOfColumns: number;
  rowsPerPage: number;
  availableNumberOfRows: number;
  isRowDataLoading: boolean;
  hasNoMoreRows: boolean;
  isColumnDataLoading: boolean;
  datasetId: string;
  onRowPageChange: (page: number, action: DatasetTypes.PageAction) => void;
  onColumnPageChange: (page: number) => void;
  onJumpToColumn: (columnId: string, page: number) => void;
  children?: React.ReactNode;
}

const DatasetPagination: React.FC<Props> = ({
  rowPage,
  colPage,
  rowsPerPage,
  hasNoMoreRows,
  datasetId,
  availableNumberOfColumns,
  availableNumberOfRows,
  isRowDataLoading,
  isColumnDataLoading,
  children,
  onColumnPageChange,
  onRowPageChange,
  onJumpToColumn,
}) => {
  const handlePageChange = (page: number) => {
    const offsetPage = availableNumberOfRows % rowsPerPage > 0 ? 1 : 0;
    const numberOfPages = Math.floor(availableNumberOfRows / rowsPerPage) + offsetPage;

    let action = DatasetTypes.PageAction.FIRST;
    if (page > rowPage) {
      action = page === numberOfPages - 1 ? DatasetTypes.PageAction.LAST : DatasetTypes.PageAction.NEXT;
    } else if (page < rowPage && page > 0) {
      action = DatasetTypes.PageAction.PREVIOUS;
    }
    onRowPageChange(page, action);
  };

  return (
    <div className="flex items-center gap-4 py-3 px-6">
      <div className="flex flex-grow">{children}</div>
      <Pagination
        label="Rows:"
        page={rowPage}
        onPageChange={handlePageChange}
        itemsPerPage={rowsPerPage}
        count={availableNumberOfRows}
        lastPageDisabled={availableNumberOfRows === 0}
        disabled={isRowDataLoading}
        nextPageDisabled={hasNoMoreRows}
      />
      <div className="h-[16px] w-[1px] bg-gray-300"></div>
      <Pagination
        label="Columns:"
        page={colPage}
        onPageChange={onColumnPageChange}
        itemsPerPage={DatasetTypes.COLUMNS_PER_PAGE}
        count={availableNumberOfColumns}
        disabled={isColumnDataLoading}
      />
      <div className="h-[16px] w-[1px] bg-gray-300 last:hidden" />
      <DatasetJumpToColumn datasetId={datasetId} onJump={onJumpToColumn} numberOfColumns={availableNumberOfColumns} />
    </div>
  );
};

export default DatasetPagination;
