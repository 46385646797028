import { Hooks, MapsContext } from '@platform/maplibre';
import { PanelHeader } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useEffect, useRef } from 'react';
import InfoPanel from '../../InfoPanel/InfoPanel';
import ActiveMapSwitcher from '../../Map/ActiveMapSwitcher';
import { useMapSidebarContext } from '../MapSideBarContext';
import AnalysisGeoSelection from './AnyalysGeoSelection';

const { useMapsContext } = MapsContext;
const { useMapObjectClick } = Hooks;

interface Props {
  isEditMode: boolean;
  onCloseRequest?: () => void;
}

const Analysis: React.FC<Props> = ({ isEditMode = false, onCloseRequest }) => {
  const { changeActiveView } = useMapSidebarContext();
  const { activeMap, state, changeActiveMap, changeUseMapHighlight } = useMapsContext();

  const registeredMapsRef = useRef<ProjectTypes.MapObject[]>([]);
  const eligibleMapsRef = useRef<ProjectTypes.MapObject[]>([]);
  const eligibleMaps = registeredMapsRef.current.filter((x) => isEditMode || x.styleSpec.geoFeatureSelection?.length);

  eligibleMapsRef.current = eligibleMaps;

  useEffect(() => {
    registeredMapsRef.current = [...state.registeredMaps];
  }, [state.registeredMaps]);

  useMapObjectClick((mapObj: ProjectTypes.MapObject) => {
    if (eligibleMapsRef.current.find((x) => x.id === mapObj.id)) {
      changeActiveMap(mapObj.id);
    }
  });

  useEffect(() => {
    if (isEditMode) return;
    if (!eligibleMaps.length) return;

    // if the active map isn't allowed try switching to the first eligible
    if (activeMap && !eligibleMaps.includes(activeMap)) {
      changeActiveMap(eligibleMaps[0].id);
    }
  }, [isEditMode, eligibleMaps]); // do not touch deps array

  let mapSwitcherFallback;
  // logic to know which string to present in the note
  if (!isEditMode && registeredMapsRef.current.length > 1 && eligibleMaps.length === 1) {
    const mapPosition = registeredMapsRef.current.indexOf(eligibleMaps[0]);
    const mapPositionText = mapPosition === 0 ? 'left' : 'right';

    mapSwitcherFallback = (
      <div className="flex items-center justify-start gap-2">
        <span className="text-xs font-semibold">Note:</span>
        <span className="text-xs">Displayed analysis refers to the map on the {mapPositionText}.</span>
      </div>
    );
  }

  const mapSwitcherOptions = state.registeredMaps.map((x) => ({
    mapId: x.id,
    disabled: !isEditMode && !x.styleSpec.geoFeatureSelection?.length,
  }));

  const handleCloseRequest = () => {
    changeActiveView(undefined);
    onCloseRequest?.();
  };

  return (
    <div className="flex min-h-0 w-[460px] grow flex-col">
      <PanelHeader title="Area Analysis" onClose={handleCloseRequest}>
        <ActiveMapSwitcher
          activeMapId={activeMap?.id}
          options={mapSwitcherOptions}
          onChange={changeActiveMap}
          onToggleHighlight={changeUseMapHighlight}
          fallback={mapSwitcherFallback}
        />
      </PanelHeader>
      <div className="flex min-h-0 flex-grow flex-col gap-4 overflow-y-auto px-6 pt-4">
        {activeMap && (
          <div className="-mx-6 border-gray-200">
            <AnalysisGeoSelection mapObj={activeMap} isEditMode={isEditMode} />
          </div>
        )}
        {activeMap && <InfoPanel styleSpec={activeMap.styleSpec} />}
      </div>
    </div>
  );
};

export default Analysis;
