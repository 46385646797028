import { MapsContext } from '@platform/maplibre';
import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSessionContext } from '../../contexts/SessionContext';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { useUploadToS3 } from '../../hooks';
import * as peApi from '../../pe.api';
import { containCanvasToPng } from '../../utils';
import Analysis from '../MapSidebar/Analysis';
import MapSideBar, { SHARED_OPTIONS } from '../MapSidebar/MapSideBar';
import { MapSideBarContextProvider } from '../MapSidebar/MapSideBarContext';
import MoreMenu from '../MapSidebar/MoreMenu';
import { Loader } from '../shared';
import ErrorPage from '../shared/ErrorPage';
import ProfileLeftDrawer from './ProfileLeftDrawer';
import ProfileMapsWrapper from './ProfileMapsWrapper';

interface Props {
  profileId: string;
  mode: 'edit' | 'view';
}

const Profile: React.FC<Props> = ({ profileId, mode = 'edit' }) => {
  const isEditMode = mode === 'edit';
  const { jwtToken } = useSessionContext();
  const { activeWorkspace } = useWorkspaceContext();

  const profileDataQuery = useQuery(['profile', profileId], () => peApi.getProfile(profileId));
  const uploadThumbToS3 = useUploadToS3(isEditMode ? `${profileId}/thumb.png` : '');

  const doInvalidate = () => {
    if (activeWorkspace) {
      queryClient.invalidateQueries({ queryKey: ['profiles', activeWorkspace.id] });
    }

    queryClient.invalidateQueries({ queryKey: ['profile', profileId] });
  };

  const queryClient = useQueryClient();
  const profileThumbMutation = useMutation({
    mutationFn: (path: string) => peApi.updateProfileThumbPath(profileId, path),
    onSuccess: doInvalidate,
  });

  const profileTimestampMutation = useMutation({
    mutationFn: () => peApi.updateProfileTimestamp(profileId),
    onSuccess: doInvalidate,
  });
  if (profileDataQuery.isIdle || profileDataQuery.isLoading) return <Loader />;
  if (profileDataQuery.isError) return <ErrorPage />;

  const profile = profileDataQuery.data;

  const handleThumb = async (canvas: HTMLCanvasElement) => {
    if (!isEditMode) return;

    const targetWidth = 600;
    const targetHeight = targetWidth / (16.0 / 9.0);

    const mergedBase64Thumb = containCanvasToPng(canvas, targetWidth, targetHeight);
    const newThumbPath = await uploadThumbToS3(mergedBase64Thumb);

    if (newThumbPath && newThumbPath !== profile.thumbPath) {
      profileThumbMutation.mutate(newThumbPath);
    }
  };

  const handleUpdate = () => profileTimestampMutation.mutate();
  return (
    <MapsContext.MapsContextProvider
      mapContextId={profile.mapContextId}
      jwtToken={jwtToken}
      doSave={isEditMode}
      onUpdate={handleUpdate}
      onThumb={isEditMode ? handleThumb : undefined}
      apis={{
        mapContext: {
          get: peApi.getMapContext,
          update: peApi.patchMapContextItems,
        },
        baseMaps: { get: peApi.getBaseMapStyle },
        dataSourceLayers: { get: peApi.getDataSourceLayers },
      }}
    >
      <MapsContext.MapsConsumer>
        {({ state }) => {
          if (!state.registeredMaps.length) return null;

          const mapSideBarOptions = [];

          if (isEditMode) {
            mapSideBarOptions.push(SHARED_OPTIONS.SEARCH);
            mapSideBarOptions.push(SHARED_OPTIONS.DATA);
          }

          const showAnalysisPanel =
            isEditMode || state.registeredMaps.some((x) => !!x.styleSpec.geoFeatureSelection?.length);

          if (showAnalysisPanel) {
            mapSideBarOptions.push({
              icon: <MUIcon name="monitoring" className="text-[24px]" />,
              title: 'Analysis',
              key: 'ANALYSIS',
              content: <Analysis isEditMode={isEditMode} />,
            });
          }

          return (
            <div className="flex h-full min-w-0">
              <MapSideBarContextProvider>
                <MapSideBar options={mapSideBarOptions} leftDrawer={<ProfileLeftDrawer profile={profile} />}>
                  {isEditMode && <MoreMenu classNames="hover:bg-gray-700" />}
                </MapSideBar>
              </MapSideBarContextProvider>
              <ProfileMapsWrapper profile={profile} mode={mode} />
            </div>
          );
        }}
      </MapsContext.MapsConsumer>
    </MapsContext.MapsContextProvider>
  );
};

export default Profile;
