import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';

interface Props {
  value: 'asc' | 'desc';
  onChange: (v: 'asc' | 'desc') => void;
}

const SortOrder: React.FC<Props> = ({ value, onChange }) => {
  const icon = value === 'desc' ? 'arrow_upward_alt' : 'arrow_downward_alt';

  const handleClick = () => {
    const toggledOrder = value === 'asc' ? 'desc' : 'asc';
    onChange(toggledOrder);
  };

  return (
    <Buttons.Link
      className="rounded-full p-2"
      icon={<MUIcon className="text-[24px]" name={icon} />}
      onClick={handleClick}
    />
  );
};

export default SortOrder;
