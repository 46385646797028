import { Modal } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DefaultMapPicker from '../DefaultMaps/DefaultMapPicker';
import ProjectsPicker from '../Projects/ProjectsPicker';

import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';

type Inputs = {
  title: string;
  projectId: string | null;
  defaultMapId: string;
};

interface Props {
  defaultProjectId?: string | null;
  onCloseRequest: () => void;
}

const CreateProfileModal: React.FC<Props> = ({ defaultProjectId, onCloseRequest }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeWorkspace } = useWorkspaceContext();

  const defaultMapsQuery = useQuery({
    queryKey: ['default-map'],
    queryFn: () => peApi.getDefaultMaps(),
  });

  const createProfileMutation = useMutation({
    mutationFn: (p: PeTypes.CreateProfilePayload) => peApi.createProfile(p),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: ['profiles'] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects'] });
      navigate(generateLink('map', { workspaceId: activeWorkspace.id, mapId: id, mode: 'edit' }));
    },
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      title: '',
      projectId: defaultProjectId,
      defaultMapId: defaultMapsQuery.data?.find((x) => x.preselect)?.id ?? '',
    },
  });

  useEffect(() => {
    setValue('defaultMapId', defaultMapsQuery.data?.find((x) => x.preselect)?.id ?? '');
  }, [defaultMapsQuery.data, setValue]);

  const onSubmit: SubmitHandler<Inputs> = (data) => createProfileMutation.mutate(data);

  return (
    <Modal
      title="Create a Map"
      okLabel="Create"
      className="w-[512px]"
      okDisabled={createProfileMutation.isLoading}
      onDone={handleSubmit(onSubmit)}
      onCloseRequest={onCloseRequest}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-col gap-6">
        <div data-cy={'create-map-title-div'}>
          <label htmlFor="title" className="mb-2 block text-sm font-bold text-gray-900">
            Name
          </label>
          <input
            autoFocus
            id="title"
            data-cy="create-map-title-input"
            placeholder="Give your map a name"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
            {...register('title', { required: 'Name is required' })}
          />
          {errors.title && <span className="text-red-400">{errors.title.message}</span>}
        </div>
        <Controller
          name="projectId"
          control={control}
          render={({ field }) => (
            <div>
              <label htmlFor="Project" className="mb-2 block text-sm font-bold text-gray-900">
                Project (optional)
              </label>
              <ProjectsPicker onSelect={(x) => field.onChange(x.id)} value={field.value} />
            </div>
          )}
        />
        {defaultMapsQuery.isIdle || defaultMapsQuery.isLoading ? (
          <>Loading maps...</>
        ) : defaultMapsQuery.isError ? (
          <>Something went wrong</>
        ) : (
          defaultMapsQuery.data && (
            <Controller
              name="defaultMapId"
              control={control}
              rules={{ required: 'Map is required' }}
              render={({ field }) => (
                <div>
                  <label htmlFor="defaultMap" className="mb-2 block text-sm font-bold text-gray-900">
                    Start with...
                  </label>
                  <DefaultMapPicker
                    activeMapId={field.value}
                    onSelect={(x) => {
                      field.onChange(x.id);
                    }}
                    maps={defaultMapsQuery.data}
                  />
                  {errors.defaultMapId && (
                    <span className="text-red-400" data-cy={'create-map-error-msg'}>
                      {errors.defaultMapId.message}
                    </span>
                  )}
                </div>
              )}
            />
          )
        )}
      </form>
    </Modal>
  );
};

export default CreateProfileModal;
