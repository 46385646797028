import { MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React, { useRef } from 'react';

interface Props {
  tooltipContent: ProjectTypes.TooltipContent;
  onContentRemove: (value: string) => void;
  onContentLabelChange: (id: string, value: string) => void;
}

const WorkingLayerTooltipColumn: React.FC<Props> = ({ tooltipContent, onContentRemove, onContentLabelChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef.current || event.key !== 'Enter') return;
    const value = inputRef.current.value;
    if (value.trim().length > 0) {
      onContentLabelChange(tooltipContent.id, value);
    } else {
      inputRef.current.value = tooltipContent.label ?? tooltipContent.name;
    }

    inputRef.current.blur();
  };

  const handleInputBlur = () => {
    if (!inputRef.current) return null;
    const value = inputRef.current.value.trim();
    if (value.length > 0) {
      onContentLabelChange(tooltipContent.id, value);
    } else {
      inputRef.current.value = tooltipContent.label ?? tooltipContent.name;
    }
  };

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        key={tooltipContent.id}
        className="group relative flex items-center justify-between gap-14 rounded-lg border border-gray-300 bg-gray-50 p-2 px-3 text-sm"
      >
        <div className="absolute top-0 bottom-0 left-0 mr-3 hidden items-center justify-center group-hover:flex">
          <MUIcon name="drag_indicator" className="text-gray-400" />
        </div>
        <div className="flex w-full items-center justify-center px-2">
          <input
            ref={inputRef}
            className="focus:outline-primary-400 flex grow rounded-lg bg-gray-50 p-2 outline-1 outline-gray-200 hover:bg-white hover:outline focus:outline focus:outline-1"
            onFocus={handleInputFocus}
            defaultValue={tooltipContent.label ?? tooltipContent.name}
            placeholder={tooltipContent.label ?? tooltipContent.name}
            onKeyDown={handleKeyDown}
            onBlur={handleInputBlur}
          ></input>
        </div>
        <div className="flex items-center justify-center gap-4">
          <button className="flex items-center justify-center" onClick={() => onContentRemove(tooltipContent.id)}>
            <MUIcon name="close" className="text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkingLayerTooltipColumn;
