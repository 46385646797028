import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const PORTAL_CLASS = 'body-div-portal';

const usePortal = (id?: string) => {
  const [isPortalReady, setIsPortalReady] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const portalIdRef = useRef<string>(id ?? `portal-${new Date().getTime()}`);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`) as HTMLDivElement;

    if (!existingParent) {
      const container = document.createElement('div');
      container.setAttribute('id', portalIdRef.current);
      container.classList.add(PORTAL_CLASS);
      container.style.position = 'fixed';
      container.style.zIndex = (document.getElementsByClassName(PORTAL_CLASS).length + 1).toString();
      document.body.appendChild(container);
      containerRef.current = container;
    } else {
      containerRef.current = existingParent;
    }

    setIsPortalReady(true);

    return () => {
      if (containerRef.current && !containerRef.current.hasChildNodes()) {
        containerRef.current.remove();
      }
    };
  }, [id]);

  const Portal = ({ children }: { children: ReactNode }) => {
    if (!isPortalReady || !containerRef.current) {
      return null;
    }

    return ReactDOM.createPortal(children, containerRef.current);
  };

  return Portal;
};

export default usePortal;
