import React from 'react';
import { useParams } from 'react-router-dom';
import Dataset, { ModeType } from '../components/Datasets/Dataset';
import ErrorPage from '../components/shared/ErrorPage';

const DatasetPage: React.FC = () => {
  const { datasetId, mode = 'table' } = useParams<{ datasetId: string; mode: ModeType }>();
  if (!datasetId) return <ErrorPage />;
  return (
    <div className="h-full w-full bg-gray-50">
      <Dataset datasetId={datasetId} mode={mode} />
    </div>
  );
};

export default DatasetPage;
