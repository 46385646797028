import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { generateLink } from '../../../LinkGenerator';
import * as peApi from '../../../pe.api';
import { Loader, LogoText } from '../../shared';
import ErrorPage from '../../shared/ErrorPage';
import CreateAccountForm from '../SignUp/CreateAccountForm';

interface Props {
  invitationId: string;
}

const Invitation: React.FC<Props> = ({ invitationId }) => {
  const invitationDetailsQuery = useQuery(['invitation-details'], () => peApi.getInvitationDetails(invitationId));
  if (invitationDetailsQuery.isIdle || invitationDetailsQuery.isLoading) return <Loader />;
  if (invitationDetailsQuery.isError) return <ErrorPage />;

  const invitationDetails = invitationDetailsQuery.data;

  return (
    <div className="flex h-full w-full flex-col items-center gap-8 rounded-lg bg-gray-50 pt-[60px]">
      <LogoText width={148} height={38} />
      <div className="flex w-[512px] flex-col gap-3 rounded-xl bg-white  shadow-md">
        <div className="flex h-[92px] w-full flex-col items-center justify-center gap-2 rounded-t-lg bg-gray-100 text-xs">
          {invitationDetails.invitedEmail ? (
            <div className="flex flex-col items-center justify-center gap-2 ">
              <p className="text-gray-600">You're invited to join as</p>
              <div className="flex h-[28px] items-center justify-center gap-2 rounded-xl bg-gray-200 px-2">
                <div className="flex items-center rounded-full bg-white">
                  <MUIcon name="person" className="text-gray-500" />
                </div>
                <p>{invitationDetails?.invitedEmail}</p>
              </div>
            </div>
          ) : (
            <div>You already have an account with this email</div>
          )}
        </div>
        <div className="px-8 py-6">
          <div className="text-2xl font-bold">Create an account</div>
          <CreateAccountForm email={invitationDetails?.invitedEmail ?? ''} disabled={!invitationDetails.invitedEmail} />
        </div>
      </div>
      <p className="text-sm text-gray-600">
        Already have an account?{' '}
        <span>
          <Link className="text-primary-600 px-1 text-sm font-semibold" to={generateLink('login')}>
            Log In
          </Link>
        </span>
      </p>
    </div>
  );
};

export default Invitation;
