import React from 'react';
import DatasetsList from './DatasetsList';

const Datasets: React.FC = () => {
  return (
    <div className="mx-auto flex h-full max-w-7xl grow gap-10">
      <div className="mt-12 flex min-w-0 flex-grow flex-col gap-6">
        <DatasetsList />
      </div>
    </div>
  );
};

export default Datasets;
