import React from 'react';
import { Outlet } from 'react-router-dom';
import WorkspaceHeader from '../components/Dashboard/WorkspaceHeader';

const WorkspaceLayout: React.FC = () => {
  return (
    <div className="flex h-full flex-col bg-slate-50">
      <WorkspaceHeader />
      <div className="h-full overflow-auto">
        <div className="mx-auto h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceLayout;
