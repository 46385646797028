import { MetadataTypes, ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import { MapGeoJSONFeature } from 'maplibre-gl';

interface Props {
  universeVariablesMetadata: MetadataTypes.Variable[];
  feature: MapGeoJSONFeature;
  selection: ProjectTypes.VariableSelection;
  showPercentage: boolean;
  variable: MetadataTypes.Variable | undefined;
}

const VariableValue: React.FC<Props> = ({
  universeVariablesMetadata,
  feature,
  selection,
  variable,
  showPercentage,
}) => {
  const showingPercents = showPercentage ?? universeVariablesMetadata.length > 0;
  const isVariableNumber = typeof feature.properties[selection.variableGuid] === 'number';
  const value = isVariableNumber
    ? parseFloat(feature.properties[selection.variableGuid])
    : feature.properties[selection.variableGuid];

  let pctg = 0;
  let variableValue;
  if (showingPercents && isVariableNumber) {
    const universeCount = parseFloat(feature.properties[universeVariablesMetadata[0].uuid]);
    pctg = (value / universeCount) * 100;
    variableValue = pctg;
  }

  if (isVariableNumber) {
    variableValue = Number.isNaN(value)
      ? 'N/A'
      : ValueFormatter.format(value, variable?.formatting as ProjectTypes.ValueFormat);
  } else {
    variableValue = value ? value : 'N/A';
  }
  return (
    <>
      {showingPercents && isVariableNumber && (
        <span className="font-semibold">
          {ValueFormatter.format(pctg, ProjectTypes.ValueFormat.FORMAT_PERCENT_2_DECIMAL)}
        </span>
      )}
      <span>{variableValue}</span>
    </>
  );
};

export default VariableValue;
