import classNames from 'classnames';
import React, { RefObject, useEffect, useRef } from 'react';
import MUIcon from '../MUIcon';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  prefixComponent?: JSX.Element;
  suffixComponent?: JSX.Element;
  classes?: string;
  inputClasses?: string;
  roundedClasses?: string;
  onClear?: () => void;
  clearIcon?: JSX.Element | null;
  maxLength?: number;
}

const Input: React.FC<Props> = ({
  classes,
  inputClasses,
  prefixComponent,
  suffixComponent,
  onClear,
  roundedClasses = 'rounded-full',
  clearIcon = <MUIcon name="close" className="text-gray-600" />,
  ...otherInputProps
}) => {
  const inputRef: RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (otherInputProps.autoFocus) {
      setTimeout(() => inputRef.current?.focus(), 10);
    }
  }, [otherInputProps.autoFocus]);

  const handleClearClick = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';

    inputRef.current.focus();
    onClear?.();
  };

  const showClear = otherInputProps.value && onClear && clearIcon;

  return (
    <div
      className={classNames(
        'relative flex w-full items-center border-0 px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300',
        classes,
        roundedClasses
      )}
    >
      {prefixComponent}
      <input
        ref={inputRef}
        className={classNames(
          'ml-2 mr-2 flex-grow border-0 p-0 text-sm text-gray-900 focus:outline-none focus:ring-0',
          inputClasses
        )}
        autoComplete="off"
        {...otherInputProps}
      />
      {showClear ? (
        <button onClick={handleClearClick} className="flex hover:rounded-full hover:bg-gray-50">
          {clearIcon}
        </button>
      ) : (
        suffixComponent
      )}
    </div>
  );
};

export default Input;
