import { PeTypes } from '@platform/types';
import { useQuery, UseQueryOptions } from 'react-query';

interface Props {
  userId: number;
  userApi: (userId: number) => Promise<PeTypes.User>;
}

function useSurvey({ userId, userApi }: Props, options?: UseQueryOptions<PeTypes.User>) {
  return useQuery<PeTypes.User>(['user', userId], () => userApi(userId), options);
}

export default useSurvey;
