import { Dropdowns, Modal, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { NIL as NIL_UUID } from 'uuid';
import { useSessionContext } from '../../contexts/SessionContext';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { useProjects } from '../../hooks';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';

type Inputs = {
  title: string;
  projectId: string | null;
};

interface Props {
  profileId: string;
  profileTitle: string;
  activeProjectId: string | undefined;
  onCloseRequest: () => void;
}

const sortByDate = (projects: PeTypes.Project[]) =>
  projects.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

const CopyProfileModal: React.FC<Props> = ({ profileId, profileTitle, onCloseRequest, activeProjectId = NIL_UUID }) => {
  const queryClient = useQueryClient();
  const { notify } = useSessionContext();
  const { activeWorkspace } = useWorkspaceContext();
  const { data: projects } = useProjects(activeWorkspace.id);

  const {
    watch,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { title: `Copy of ${profileTitle}`, projectId: activeProjectId } });

  const copyProfileMutation = useMutation({
    mutationFn: (payload: PeTypes.CopyProfilePayload) => peApi.copyProfile(profileId, payload),
    onSuccess: (data) => {
      const title = watch('title');
      notify({
        hideAfterSec: 2,
        content: `Creating "${title}"...`,
        icon: <MUIcon className="animate-spin" name="progress_activity" />,
      });

      queryClient.invalidateQueries({ queryKey: ['profiles', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-projects', activeWorkspace.id] });
      onCloseRequest();

      const url = generateLink('map', { workspaceId: activeWorkspace.id, mapId: data, mode: 'edit' });
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    onError: () => {
      notify({
        content: 'Failed to copy profile',
        hideAfterSec: 2,
      });
    },
  });

  const { options } = useMemo(() => {
    const sortedProjects = [
      { id: NIL_UUID, title: 'Unsorted', createdAt: new Date().toISOString() },
      ...sortByDate(projects ?? []),
    ];
    const options: Dropdowns.DropdownOption[] = sortedProjects.map((project) => ({
      value: project.id,
      name: project.title,
      icon: <MUIcon name="folder" />,
    }));
    return { options };
  }, [projects]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    copyProfileMutation.mutate({ title: data.title, projectId: data.projectId });
  };

  return (
    <Modal
      title={'Copy profile'}
      okLabel={'Make a copy'}
      onDone={handleSubmit(onSubmit)}
      okDisabled={copyProfileMutation.isLoading}
      onCloseRequest={onCloseRequest}
      actionClasses="border-t-[1px] border-t-gray-300"
      className="w-[480px]"
    >
      <div className="flex max-h-[600px] flex-col overflow-y-auto py-4">
        <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-col gap-8">
          <div>
            <label htmlFor="title" className="mb-2 block text-sm font-bold text-gray-900">
              Name
            </label>
            <input
              autoFocus
              id="title"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:outline-blue-500"
              {...register('title', { required: 'Name is required' })}
            />
            {errors.title && <span className="text-red-400">{errors.title.message}</span>}
          </div>
          <Controller
            name="projectId"
            control={control}
            rules={{ required: 'Project is required' }}
            render={({ field }) => {
              return (
                <div>
                  <label htmlFor="dataType" className="mb-2 block text-sm font-semibold text-gray-800">
                    Project
                  </label>
                  <Dropdowns.Dropdown
                    options={options}
                    value={field.value}
                    menuWidth="full"
                    triggerLabelClasses="flex items-center"
                    triggerClasses="w-full flex items-center hover:bg-gray-100 border border-gray-300 ring-0"
                    listClasses="max-h-[280px]"
                    onSelect={(opt) => field.onChange(opt.value)}
                  />
                  {errors.projectId && <span className="text-red-400">{errors.projectId.message}</span>}
                </div>
              );
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CopyProfileModal;
