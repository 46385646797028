import { MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';

interface Props {
  openByDefault?: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
}

const InsightsWrapper: React.FC<Props> = ({ title, children, openByDefault = false }) => {
  const [open, setOpen] = useState<boolean>(openByDefault);
  return (
    <ContextProvider value={{ open }}>
      <div className="flex select-none flex-col last:pb-6">
        <div
          className="flex min-h-[48px] cursor-pointer items-center justify-between rounded-lg bg-gray-100 py-2 pl-4 pr-4"
          onClick={() => setOpen((p) => !p)}
        >
          <div>{title}</div>
          {open ? (
            <MUIcon name="expand_less" className=" text-gray-600" />
          ) : (
            <MUIcon name="expand_more" className=" text-gray-600" />
          )}
        </div>
        {open && <div className="min-h-[200px] px-3">{children}</div>}
      </div>
    </ContextProvider>
  );
};

export default InsightsWrapper;

interface ContextState {
  open: boolean;
}

const InsightsWrapperContext = React.createContext<ContextState>({ open: false });

export const ContextProvider = InsightsWrapperContext.Provider;
export const ContextConsumer = InsightsWrapperContext.Consumer;
