import { MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../LinkGenerator';

interface Props {
  activeWorkspace: PeTypes.Workspace;
  onSwitchRequested?: () => void;
  menuClose: () => void;
  isOnlyWorkspace?: boolean;
}

const WorkspaceOptions: React.FC<Props> = ({ activeWorkspace, onSwitchRequested, menuClose, isOnlyWorkspace }) => {
  const menuOptions = [
    {
      icon: <MUIcon name="settings" />,
      label: 'Workspace Settings',
      link: generateLink('workspaceSettings', { workspaceId: activeWorkspace.id, section: 'workspace' }),
      dataCy: 'workspace-menu-workspace-settings',
    },
    {
      icon: <MUIcon name="group" />,
      label: 'Manage Users',
      link: generateLink('workspaceSettingsSection', { workspaceId: activeWorkspace.id, section: 'users' }),
      dataCy: 'workspace-menu-workspace-users',
    },
    {
      icon: <MUIcon name="clock_loader_10" />,
      label: 'Usage Statistics',
      link: generateLink('workspaceSettingsSection', { workspaceId: activeWorkspace.id, section: 'usage' }),
      dataCy: 'workspace-menu-workspace-statistics',
    },
  ];
  return (
    <div className="flex w-[320px] flex-col">
      <div className="border-b border-gray-200 px-4 py-3 text-xs font-semibold">
        <div className="flex flex w-full items-center justify-between gap-3 font-semibold">
          <img
            className="w-[32px] rounded-[8px] border border-gray-200"
            src={`${activeWorkspace.imageURL}?${activeWorkspace.updatedAt}`}
            alt=""
          ></img>
          <span className="flex-grow truncate text-sm">{activeWorkspace.name}</span>
        </div>
      </div>
      <div className="flex flex-col py-2" data-cy={'header-workspace-menu-options'}>
        {menuOptions.map((workspaceOption) => (
          <Link
            key={workspaceOption.label}
            to={workspaceOption.link}
            className="flex h-[34px] cursor-pointer items-center justify-start gap-2 px-5 py-4 text-gray-600 hover:bg-gray-100"
            onClick={() => menuClose()}
            data-cy={workspaceOption.dataCy}
          >
            {workspaceOption.icon}
            <span className="text-sm">{workspaceOption.label}</span>
          </Link>
        ))}
      </div>
      {!isOnlyWorkspace && (
        <div
          className="cursor-pointer border-t border-gray-200 px-5 py-4 text-sm text-gray-600"
          onClick={() => onSwitchRequested?.()}
        >
          <div className="flex flex w-full items-center justify-between gap-3">
            <span>Switch to another workspace</span>
            <MUIcon name="keyboard_arrow_right" />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkspaceOptions;
