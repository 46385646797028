import { Spinner } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as peApi from '../../../pe.api';
import Report from '../Report';

interface Props {
  enabled: boolean;
  surveyInsightsId: string;
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[];
}

const SurveyInsights: React.FC<Props> = ({ enabled, surveyInsightsId, geoFeatureSelection }) => {
  const surveyInsightsQuery = useQuery(
    ['survey-insights', surveyInsightsId, geoFeatureSelection],
    () => peApi.summarizeSurveyInsights(surveyInsightsId, { geoFeatureSelection }),
    { enabled }
  );
  const [reportData, setReportData] = useState<PeTypes.Report | undefined>();

  useEffect(() => {
    // we use this as kind of a buffer, so reports gets rendered once new data is loaded
    if (surveyInsightsQuery.data) setReportData(surveyInsightsQuery.data);
  }, [surveyInsightsQuery.data]);

  return (
    <div className="relative flex flex-col">
      {(surveyInsightsQuery.isLoading || surveyInsightsQuery.isIdle) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-[2] flex justify-center bg-white/10 pt-12 backdrop-blur transition-all">
          <Spinner />
        </div>
      )}
      {reportData && <Report reportData={reportData} />}
    </div>
  );
};

export default SurveyInsights;
