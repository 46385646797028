import { PeTypes } from '@platform/types';
import { useDatasetLastPublishedState, useDatasetUnpublishedLog } from './index';

const useDatasetPublishInfo = (datasetId: string) => {
  const { query: lastPublishedStateQuery } = useDatasetLastPublishedState({ datasetId });
  const datasetLogQuery = useDatasetUnpublishedLog(datasetId, false);

  if (lastPublishedStateQuery.isLoading || datasetLogQuery.isLoading) {
    return {
      canBePublished: undefined,
      hasEverBeenPublished: undefined,
      unpublishedChangesCount: undefined,
      isPublishingInProcess: undefined,
    };
  }

  const unpublishedChangesCount = datasetLogQuery.data?.filter(
    (x) => x.actionType !== PeTypes.DatasetActionEnum.PUBLISH
  )?.length;

  const latestPublishAction = datasetLogQuery.data?.find((log) => log.actionType === PeTypes.DatasetActionEnum.PUBLISH);
  const isPublishingInProcess =
    latestPublishAction?.status === PeTypes.DatasetLogStatus.PENDING ||
    latestPublishAction?.status === PeTypes.DatasetLogStatus.RUNNING;

  return {
    canBePublished: (unpublishedChangesCount && unpublishedChangesCount > 0) || !lastPublishedStateQuery.data,
    hasEverBeenPublished: lastPublishedStateQuery.data !== undefined,
    unPublishedChangesCount: unpublishedChangesCount,
    isPublishingInProcess,
  };
};

export default useDatasetPublishInfo;
