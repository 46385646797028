import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useCallback, useContext } from 'react';
import DatepickerContext from '../../contexts/DatepickerContext';
import { classNames as cn, formatDate, nextMonth, previousMonth } from '../../helpers';
import { Period } from '../../types';

dayjs.extend(isBetween);

interface Props {
  calendarData: {
    date: dayjs.Dayjs;
    days: {
      previous: number[];
      current: number[];
      next: number[];
    };
  };
  onClickPreviousDays: (day: number) => void;
  onClickDay: (day: number) => void;
  onClickNextDays: (day: number) => void;
}

const Days: React.FC<Props> = ({ calendarData, onClickPreviousDays, onClickDay, onClickNextDays }) => {
  // Contexts
  const { period, changePeriod, dayHover, changeDayHover, minDate, maxDate, disabledDates } =
    useContext(DatepickerContext);

  // Functions
  const currentDateClass = useCallback(
    (item: number) => {
      const itemDate = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${item >= 10 ? item : '0' + item}`;
      if (formatDate(dayjs()) === formatDate(dayjs(itemDate)))
        return `text-primary-900 font-normal font-['Inter'] leading-normal rounded-full border border-primary-600 hover:bg-secondary-200 disabled:border-gray-400 disabled:text-gray-400`;
      return '';
    },
    [calendarData.date]
  );

  const activeDateData = useCallback(
    (day: number) => {
      const fullDay = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${day}`;
      let className = '';

      className = ` bg-primary-800 text-white font-medium rounded-full z-10 transition-all hover:bg-primary-500 focus:bg-primary-500 focus:ring-primary-500 pressed:bg-primary-500 disabled:text-gray-400 disabled:border-gray-400 disabled:cursor-not-allowed`;

      return {
        active: dayjs(fullDay).isSame(period.start) || dayjs(fullDay).isSame(period.end),
        className: className,
      };
    },
    [calendarData.date, period.end, period.start]
  );

  const roundedBackground = useCallback(
    (day: number) => {
      const fullDay = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${day}`;
      const baseClass = ` bg-secondary-100 hover:bg-secondary-100 pressed:bg-secondary-100 focus:bg-secondary-100 z-10`;

      if (dayjs(fullDay).isSame(period.start)) {
        return `rounded-l-full ${baseClass}`;
      }

      if (dayjs(fullDay).isSame(period.end)) {
        return `rounded-r-full ${baseClass}`;
      }

      const isAfterStart = dayHover && dayjs(dayHover).isAfter(period.start, 'day');
      const isBeforeEnd = dayHover && dayjs(dayHover).isBefore(period.end, 'day');
      const isSameDayHover = dayjs(fullDay).isSame(dayHover);

      if (isAfterStart && isSameDayHover) {
        return ` rounded-r-full ${baseClass}`;
      }

      if (isBeforeEnd && isSameDayHover) {
        return ` rounded-l-full ${baseClass}`;
      }

      return '';
    },
    [calendarData.date, period.end, period.start, dayHover]
  );

  const hoverClassByDay = useCallback(
    (day: number) => {
      let className = currentDateClass(day);
      const fullDay = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${day >= 10 ? day : '0' + day}`;

      if (period.start && period.end) {
        if (dayjs(fullDay).isBetween(period.start, period.end, 'day', '[)')) {
          return ` bg-secondary-100 hover:bg-secondary-200`;
        }
      }

      if (!dayHover) {
        return className;
      }

      if (period.start && dayjs(fullDay).isBetween(period.start, dayHover, 'day', '[)')) {
        className = ` bg-secondary-100`;
      }

      if (period.end && dayjs(fullDay).isBetween(dayHover, period.end, 'day', '[)')) {
        className = ` bg-secondary-100`;
      }

      if (dayHover === fullDay) {
        const highlightColor = 'bg-primary-800 ';
        className = `transition-all duration-500 text-white font-medium rounded-full ${highlightColor}`;
      }

      return className;
    },
    [calendarData.date, currentDateClass, dayHover, period.end, period.start]
  );

  const isDateTooEarly = useCallback(
    (day: number, type: 'current' | 'previous' | 'next') => {
      if (!minDate) {
        return false;
      }
      const object = {
        previous: previousMonth(calendarData.date),
        current: calendarData.date,
        next: nextMonth(calendarData.date),
      };
      const newDate = object[type as keyof typeof object];
      const formattedDate = newDate.set('date', day);
      return dayjs(formattedDate).isSame(dayjs(minDate), 'day') ? false : dayjs(formattedDate).isBefore(dayjs(minDate));
    },
    [calendarData.date, minDate]
  );

  const isDateTooLate = useCallback(
    (day: number, type: 'current' | 'previous' | 'next') => {
      if (!maxDate) {
        return false;
      }
      const object = {
        previous: previousMonth(calendarData.date),
        current: calendarData.date,
        next: nextMonth(calendarData.date),
      };
      const newDate = object[type as keyof typeof object];
      const formattedDate = newDate.set('date', day);
      return dayjs(formattedDate).isSame(dayjs(maxDate), 'day') ? false : dayjs(formattedDate).isAfter(dayjs(maxDate));
    },
    [calendarData.date, maxDate]
  );

  const isDateDisabled = useCallback(
    (day: number, type: 'current' | 'previous' | 'next') => {
      if (isDateTooEarly(day, type) || isDateTooLate(day, type)) {
        return true;
      }
      const object = {
        previous: previousMonth(calendarData.date),
        current: calendarData.date,
        next: nextMonth(calendarData.date),
      };
      const newDate = object[type as keyof typeof object];
      const formattedDate = `${newDate.year()}-${newDate.month() + 1}-${day >= 10 ? day : '0' + day}`;

      if (!disabledDates || (Array.isArray(disabledDates) && !disabledDates.length)) {
        return false;
      }

      let matchingCount = 0;
      disabledDates?.forEach((dateRange) => {
        if (dayjs(formattedDate).isAfter(dateRange.startDate) && dayjs(formattedDate).isBefore(dateRange.endDate)) {
          matchingCount++;
        }
        if (dayjs(formattedDate).isSame(dateRange.startDate) || dayjs(formattedDate).isSame(dateRange.endDate)) {
          matchingCount++;
        }
      });
      return matchingCount > 0;
    },
    [calendarData.date, isDateTooEarly, isDateTooLate, disabledDates]
  );

  const buttonClass = useCallback(
    (day: number, type: 'current' | 'next' | 'previous') => {
      const baseClass =
        'flex items-center justify-center w-12 h-12 hover:cursor-pointer hover:bg-primary-100 focus:outline-none press:bg-primary-200';
      if (type === 'current') {
        return cn(
          baseClass,
          !activeDateData(day).active ? hoverClassByDay(day) : activeDateData(day).className,
          isDateDisabled(day, type) && 'text-gray-400 disabled:hover:bg-transparent'
        );
      }
      return cn(
        baseClass,
        isDateDisabled(day, type) && 'text-gray-400 disabled:hover:bg-transparent',
        `text-primary-400 ${type}`
      );
    },
    [activeDateData, hoverClassByDay, isDateDisabled]
  );

  const checkIfHoverPeriodContainsDisabledPeriod = useCallback(
    (hoverPeriod: Period) => {
      if (!Array.isArray(disabledDates)) {
        return false;
      }
      for (let i = 0; i < disabledDates.length; i++) {
        if (
          dayjs(hoverPeriod.start).isBefore(disabledDates[i].startDate) &&
          dayjs(hoverPeriod.end).isAfter(disabledDates[i].endDate)
        ) {
          return true;
        }
      }
      return false;
    },
    [disabledDates]
  );

  const getMetaData = useCallback(() => {
    return {
      previous: previousMonth(calendarData.date),
      current: calendarData.date,
      next: nextMonth(calendarData.date),
    };
  }, [calendarData.date]);

  const hoverDay = useCallback(
    (day: number, type: string) => {
      const object = getMetaData();
      const newDate = object[type as keyof typeof object];
      const newHover = `${newDate.year()}-${newDate.month() + 1}-${day >= 10 ? day : '0' + day}`;

      if (period.start && !period.end) {
        const hoverPeriod = { ...period, end: newHover };
        if (dayjs(newHover).isBefore(dayjs(period.start))) {
          hoverPeriod.start = newHover;
          hoverPeriod.end = period.start;
          if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
            changePeriod({
              start: null,
              end: period.start,
            });
          }
        }
        if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
          changeDayHover(newHover);
        }
      }

      if (!period.start && period.end) {
        const hoverPeriod = { ...period, start: newHover };
        if (dayjs(newHover).isAfter(dayjs(period.end))) {
          hoverPeriod.start = period.end;
          hoverPeriod.end = newHover;
          if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
            changePeriod({
              start: period.end,
              end: null,
            });
          }
        }
        if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
          changeDayHover(newHover);
        }
      }
    },
    [changeDayHover, changePeriod, checkIfHoverPeriodContainsDisabledPeriod, getMetaData, period]
  );

  const handleClickDay = useCallback(
    (day: number, type: 'previous' | 'current' | 'next') => {
      function continueClick() {
        if (type === 'previous') {
          onClickPreviousDays(day);
        }

        if (type === 'current') {
          onClickDay(day);
        }

        if (type === 'next') {
          onClickNextDays(day);
        }
      }

      if (disabledDates?.length) {
        const object = getMetaData();
        const newDate = object[type as keyof typeof object];
        const clickDay = `${newDate.year()}-${newDate.month() + 1}-${day >= 10 ? day : '0' + day}`;

        if (period.start && !period.end) {
          dayjs(clickDay).isSame(dayHover) && continueClick();
        } else if (!period.start && period.end) {
          dayjs(clickDay).isSame(dayHover) && continueClick();
        } else {
          continueClick();
        }
      } else {
        continueClick();
      }
    },
    [
      dayHover,
      disabledDates?.length,
      getMetaData,
      onClickDay,
      onClickNextDays,
      onClickPreviousDays,
      period.end,
      period.start,
    ]
  );

  return (
    <div className="my-1 grid grid-cols-7 gap-y-0.5">
      {calendarData.days.previous.map((item, index) => (
        <button type="button" key={index} disabled={isDateDisabled(item, 'previous')}></button>
      ))}

      {calendarData.days.current.map((item, index) => (
        <div key={index} className={`${roundedBackground(item)}`}>
          <button
            type="button"
            disabled={isDateDisabled(item, 'current')}
            className={`relative ${buttonClass(item, 'current')}`}
            onClick={() => handleClickDay(item, 'current')}
            onMouseOver={() => {
              hoverDay(item, 'current');
            }}
          >
            {item}
          </button>
        </div>
      ))}

      {calendarData.days.next.map((item, index) => (
        <button type="button" key={index} disabled={isDateDisabled(item, 'next')}></button>
      ))}
    </div>
  );
};

export default Days;
