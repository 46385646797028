export const POINT_COLORS: string[] = [
  'rgb(121, 50, 86)',
  'rgb(158, 59, 46)',
  'rgb(136, 110, 46)',
  'rgb(108, 136, 58)',
  'rgb(65, 142, 101)',
  'rgb(60, 128, 154)',
  'rgb(56, 104, 211)',
  'rgb(104, 75, 182)',
  'rgb(171, 57, 114)',
  'rgb(79, 66, 63)',
  'rgb(78, 91, 101)',
  'rgb(230, 103, 98)',
  'rgb(241, 161, 65)',
  'rgb(225, 187, 71)',
  'rgb(163, 201, 98)',
  'rgb(89, 200, 140)',
  'rgb(121, 173, 193)',
  'rgb(92, 142, 231)',
  'rgb(143, 131, 217)',
  'rgb(178, 100, 164)',
  'rgb(143, 114, 104)',
  'rgb(110, 134, 151)',
];
