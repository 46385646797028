import { Inputs, Menu, MUIcon } from '@platform/shared/ui';
import { DatasetTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import { useDatasetColumns } from '../../../../hooks';
import { getSuffixIcon } from './utils';

interface Props {
  datasetId: string;
  numberOfColumns: number;
  onJump: (columnId: string, page: number) => void;
}

const JumpToColumn: React.FC<Props> = ({ datasetId, numberOfColumns, onJump }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const datasetColumnsQuery = useDatasetColumns({
    datasetId,
    itemsPerPage: numberOfColumns,
  });

  useEffect(() => {
    if (isOpen) return;
    setSearchTerm('');
  }, [isOpen]);

  if (!datasetColumnsQuery.data) {
    return null;
  }

  const visibleColumns = datasetColumnsQuery.data.filter(
    (column) => DatasetTypes.VisibleDataTypes.indexOf(column.type) !== -1
  );

  const filteredColumns = visibleColumns.filter(
    (column) =>
      column.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      column.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const onSelectOption = (id: string) => {
    const index = visibleColumns.findIndex((column) => column.uuid === id);
    const page = Math.floor(index / DatasetTypes.COLUMNS_PER_PAGE);
    onJump(visibleColumns[index].uuid, page);

    setIsOpen(false);
  };

  const trigger = (
    <div data-rh="Jump to column" className="flex items-center gap-2 rounded-[999px] bg-gray-200 p-1.5">
      <MUIcon name="search" />
    </div>
  );

  return (
    <Menu
      visible={isOpen}
      onToggleVisibility={setIsOpen}
      trigger={trigger}
      hAlignment="left"
      menuClasses="py-0"
      vAlignment="top"
    >
      <div className="flex h-[280px] w-[300px] flex-col">
        <Inputs.Input
          autoFocus
          placeholder="Search for column"
          suffixComponent={<MUIcon name="search" />}
          classes="rounded-sm rounded-tr-md rounded-tl-md"
          onChange={(e) => setSearchTerm(e.target.value)}
          type="text"
          onClear={() => setSearchTerm('')}
        />
        <div className="flex flex-col overflow-auto text-sm">
          {!filteredColumns.length && <div className="px-6 py-2">Nothing found</div>}
          {filteredColumns.map((column) => (
            <button
              key={column.uuid}
              className="flex justify-start px-6 py-2  text-gray-800 hover:cursor-pointer hover:bg-gray-100"
              onClick={() => onSelectOption(column.uuid)}
            >
              <div className="flex w-full min-w-0 items-center justify-between gap-2">
                <span className="truncate">{column.label || column.title}</span>
                {getSuffixIcon(column.type)}
              </div>
            </button>
          ))}
        </div>
      </div>
    </Menu>
  );
};

export default JumpToColumn;
