import { useSurveysMetadata } from '@platform/shared/hooks';
import { DataBrowsing, VariableInfo } from '@platform/shared/integrated-ui';
import { Buttons, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import * as peApi from '../../pe.api';
import AreaFilterWrapper from './AreaFilterWrapper';

const { Classic } = DataBrowsing;

export interface Props {
  mapObjects: ProjectTypes.MapObject[];
  activeWorkspace: PeTypes.Workspace;
  onCloseRequest: () => void;
}

const DataPanel: React.FC<Props> = ({ mapObjects, onCloseRequest, activeWorkspace }) => {
  const [primaryMapObj] = mapObjects;
  const [isFilterPanelVisible, setIsFilterPanelVisible] = useState<boolean>(false);
  const [browserVisible, setBrowserVisible] = useState<boolean>(false);
  const { filtersByData, selection, rendering } = primaryMapObj.styleSpec.dataLayer;
  const surveyMetadataQuery = useSurveysMetadata({ surveyNames: [selection[0].surveyName] });
  const [surveyMetadata] = surveyMetadataQuery.data || [];
  const appliedFilters = filtersByData?.filters.filter((f) => f.value !== null && f.value !== '');
  const hasFilters = appliedFilters && appliedFilters.length > 0;

  return (
    <div className="flex flex-col gap-6">
      <div className="px-3 text-sm font-semibold text-gray-800">Map Data</div>
      <div className="flex grow justify-between rounded-lg border border-gray-300">
        <div className="flex h-full w-full items-center justify-between gap-3 px-3 py-3">
          <div className="flex select-none flex-col gap-3">
            {selection.map((s, idx) => (
              <div key={s.variableGuid} className="flex flex-grow items-center gap-3">
                <div className="h-full py-2">
                  <MapSvg colors={getColors(rendering[idx])} />
                </div>
                <div className="flex flex-col gap-1">
                  <VariableInfo
                    def={s}
                    showUniverse={false}
                    showTable={true}
                    className="gap-1"
                    variableClassName={'font-semibold text-sm'}
                  />
                  <span className="text-xs text-gray-500">{surveyMetadata?.displayName ?? 'N/A'}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-start gap-3 py-3 pr-3">
          <Buttons.Secondary
            className="text-primary-600 rounded-[999px] px-3.5 text-sm shadow-none hover:bg-gray-100"
            onClick={() => setBrowserVisible(true)}
            data-rh="Change data"
          >
            Change
          </Buttons.Secondary>
          <div className="flex h-[36px] items-center justify-center">
            <div className="h-[20px] w-[1px] bg-gray-300"></div>
          </div>
          <Buttons.Soft
            onClick={(e) => {
              e.stopPropagation();
              setIsFilterPanelVisible(true);
            }}
            icon={<MUIcon name="tune" className="text-gray-700" />}
            className={`relative h-[36px] w-[36px] gap-0 rounded-lg border-2 bg-transparent hover:bg-gray-100 ${
              hasFilters ? 'border-primary-600' : 'border-0 shadow-none'
            }`}
            data-rh="Filter Areas by Criteria"
          >
            {hasFilters && (
              <div className="bg-primary-600 absolute -top-1.5 -right-1.5 flex h-[18px] w-[18px] items-center justify-center rounded-xl border border-white">
                <div className="text-[10px] font-semibold text-white">{appliedFilters.length}</div>
              </div>
            )}
          </Buttons.Soft>
        </div>
      </div>
      {isFilterPanelVisible && (
        <div className="absolute top-0 left-0 bottom-0 right-0 z-10 flex flex-col items-start bg-white">
          <AreaFilterWrapper mapObjects={mapObjects} onClosePanel={() => setIsFilterPanelVisible(false)} />
        </div>
      )}
      {browserVisible && (
        <div className="absolute top-0 left-0 bottom-0 right-0 z-10 flex flex-col items-start bg-white">
          <div className="flex w-full items-center gap-2 border-b border-gray-200 px-6 py-3 font-semibold">
            <Buttons.Secondary
              onClick={(event) => {
                event.stopPropagation();
                setBrowserVisible(false);
              }}
              icon={<MUIcon name="arrow_back" />}
              className="text-gray-600 shadow-none ring-0"
            />
            <div className="flex-grow text-center">Browse data</div>
            <Buttons.Secondary
              onClick={(event) => {
                event.stopPropagation();
                setBrowserVisible(false);
                onCloseRequest();
              }}
              icon={<MUIcon name="close" />}
              className="text-gray-600 shadow-none ring-0"
            />
          </div>
          <Classic mapObjects={mapObjects} surveysApi={peApi.getSurveys} activeWorkspaceId={activeWorkspace.id} />
        </div>
      )}
    </div>
  );
};

export default DataPanel;

const MapSvg: React.FC<{ colors: string[] }> = ({ colors }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66005 1.6665C3.45474 1.6665 1.66699 3.45426 1.66699 5.65956V14.3332C1.66699 16.5423 3.45785 18.3332 5.66699 18.3332H9.6531L5.66005 1.6665Z"
      fill={colors[0]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3337 1.6665C16.5428 1.6665 18.3337 3.45737 18.3337 5.6665V11.0415L9.41286 7.83139L7.91699 1.6665H14.3337Z"
      fill={colors[1]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3333 18.3332C16.5425 18.3332 18.3333 16.5423 18.3333 14.3332V12.9153L10 10.0415L11.4782 18.3332H14.3333Z"
      fill={colors[2]}
    />
  </svg>
);

function getArrayItems<T>(arr: T[]): [T, T, T] {
  const length = arr.length;
  const middleIndex = Math.floor(length / 2);
  return [arr[0], arr[middleIndex], arr[length - 1]];
}

const getColors = (rendering: ProjectTypes.CutPointSet) =>
  getArrayItems<string>(rendering.cutPoints.filter((x) => !!x.color).map((x) => x.color as string));
