import { Dropdowns, MUIcon, SVGs } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import React from 'react';

const { Polygons, Points, TableIcon } = SVGs;

const ICON_COLOR = '#4B5563';

export const FILTER_BY_OPTIONS: Dropdowns.DropdownOption[] = [
  { name: 'Point', value: ProjectTypes.LayerType.POINT, icon: <Points fillColor={ICON_COLOR} /> },
  { name: 'Polygon', value: ProjectTypes.LayerType.POLYGON, icon: <Polygons fillColor={ICON_COLOR} /> },
  {
    name: 'Table',
    value: ProjectTypes.LayerType.TABLE,
    icon: <TableIcon fillColor={ICON_COLOR} />,
  },
];

interface Props {
  value: ProjectTypes.LayerType | undefined;
  types: ProjectTypes.LayerType[];
  onChange: (v: ProjectTypes.LayerType | undefined) => void;
}

const FilterDatasetsByType: React.FC<Props> = ({ value, onChange, types }) => {
  const handleClear = () => onChange(undefined);
  const availableFilterOptions = FILTER_BY_OPTIONS.filter((option) =>
    types.includes(option.value as ProjectTypes.LayerType)
  );

  const customLabel =
    value != null ? (
      <div className=" bg-primary-200 flex items-center gap-2 rounded-full py-2 pl-3 pr-4">
        <div className="m-0 flex" onClick={handleClear} onKeyDown={handleClear} role="button" tabIndex={0}>
          <MUIcon name="close" />
        </div>
        <span>{availableFilterOptions.find((opt) => opt.value === value)?.name}</span>
        <MUIcon name="arrow_drop_down" className="text-gray-600" iconStyle="filled" />
      </div>
    ) : (
      <p className="text-sm">Type</p>
    );

  return (
    <Dropdowns.Soft
      header={<div>Filter by Type</div>}
      options={availableFilterOptions}
      onSelect={(opt) => onChange(opt.value as ProjectTypes.LayerType)}
      value={value}
      customLabel={customLabel}
      icon={value && null}
      hAlignment="right"
    />
  );
};

export default FilterDatasetsByType;
