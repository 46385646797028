import { DatasetTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { Dropdowns, Inputs } from '../index';
import FilterHeader from './FilterHeader';
import InvalidFilterRule from './InvalidFilterRule';

import { CONTAINS, ENDS_WITH, IS_NOT_NULL, IS_NULL, OperatorType, STARTS_WITH } from './options';

// abacus creates this bucket as soon as the number of unique values passes some threshold (100 by default)
const OTHER_BUCKET = '[other]';

const EQUAL_TO: OperatorType = { name: 'is', value: '=' };
const NOT_EQUAL_TO: OperatorType = { name: 'is not', value: '!=' };

interface Props {
  rule: RuleType;
  title: string;
  type: string | DatasetTypes.DataType;
  columnDiscreteValues: string[];
  onColumnChange: (rule: RuleType) => void;
  onFilterChange: (rule: RuleType, operator: string, value: unknown) => void;
  onRemoveRule: (rule: RuleType | RuleGroupType) => void;
}

const VarCharFilter: React.FC<Props> = ({
  rule,
  title,
  type,
  columnDiscreteValues,
  onColumnChange,
  onFilterChange,
  onRemoveRule,
}) => {
  const value = rule.value;
  const inputRef = useRef<HTMLInputElement>(null);
  const operatorOptions = [EQUAL_TO, NOT_EQUAL_TO, CONTAINS, STARTS_WITH, ENDS_WITH, IS_NULL, IS_NOT_NULL];
  const currentOperator = operatorOptions.find((option) => option.value === rule.operator);

  const handleValueChange = () => {
    const value = inputRef.current?.value;
    onFilterChange(rule, currentOperator ? currentOperator?.value : '', value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    handleValueChange();
  };

  const isOperatorDropdown =
    currentOperator !== STARTS_WITH && currentOperator !== ENDS_WITH && currentOperator !== CONTAINS;
  const isNullishOperator = currentOperator === IS_NULL || currentOperator === IS_NOT_NULL;

  const dropDownOptions = columnDiscreteValues
    .filter((x: string) => x !== '')
    .map((x: string) => ({ value: x, name: x || 'No value' }))
    .sort((a: Dropdowns.DropdownOption, b: Dropdowns.DropdownOption) => a.name.localeCompare(b.name));

  const fallback = columnDiscreteValues.includes(OTHER_BUCKET) ? (
    <div className="break-normal p-3 text-sm text-gray-800">Too many variables to return a suggestion.</div>
  ) : null;

  const valueInput =
    isOperatorDropdown && dropDownOptions?.length > 0 ? (
      <Inputs.InputWithOptions
        value={value}
        disabled={!dropDownOptions.length}
        options={dropDownOptions}
        onSelect={(opt) => onFilterChange(rule, currentOperator ? currentOperator?.value : '', opt.value)}
        menuClasses="border border-gray-200 !max-h-[320px]"
        menuWidth="full"
        inputClasses="bg-transparent w-full flex gap-1 text-gray-700 rounded-lg border-gray-200 px-3"
        fallback={fallback}
      />
    ) : (
      <input
        ref={inputRef}
        defaultValue={value ?? ''}
        className="w-full rounded-lg border border-gray-200"
        type="text"
        onKeyDown={handleKeyPress}
        onBlur={handleValueChange}
      />
    );

  return (
    <div key={rule.id}>
      <div
        className={classNames('flex flex-col gap-3 break-all rounded-lg border border-gray-200 bg-gray-100 p-3', {
          'rounded-bl-none rounded-br-none': !Helpers.checkIfValidRule(rule, null),
        })}
      >
        <FilterHeader
          handleColumnChange={() => onColumnChange(rule)}
          handleRemoveRule={() => onRemoveRule(rule)}
          title={title}
          type={type}
        />
        <div className="flex justify-between gap-2">
          <div className="flex min-w-0 shrink-0 grow basis-1/3">
            <Dropdowns.Dropdown
              customLabel={currentOperator?.name}
              onSelect={(opt) => onFilterChange(rule, opt ? (opt?.value as string) : '', value)}
              options={operatorOptions}
              triggerWrapperClasses="w-full"
              menuWidth="full"
              triggerClasses="bg-white w-full flex grow-0 ring-0 gap-1 text-gray-800 font-normal rounded-lg h-[44px] border border-gray-200 truncate"
            />
          </div>
          {!isNullishOperator && <div className="flex min-w-0 basis-2/3">{valueInput}</div>}
        </div>
      </div>
      {!Helpers.checkIfValidRule(rule, null) && <InvalidFilterRule />}
    </div>
  );
};

export default VarCharFilter;
