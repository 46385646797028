import maplibregl, {
  LngLatBoundsLike,
  LngLatLike,
  MapMouseEvent,
  RequestTransformFunction,
  StyleSpecification,
} from 'maplibre-gl';
import React, { useEffect, useRef, useState } from 'react';
import { MapPosition } from './types';

export interface MapProps {
  style: StyleSpecification | string;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  center?: LngLatLike;
  children?: React.ReactNode;
  debug?: boolean;
  bounds?: LngLatBoundsLike;
  maxBounds?: LngLatBoundsLike;
  onMove?: (p: MapPosition) => void;
  onRender?: (mapRef: maplibregl.Map) => void;
  onIdle?: (mapRef: maplibregl.Map) => void;
  onMount?: (mapRef: maplibregl.Map) => void;
  onUnmount?: () => void;
  customTransformRequest?: RequestTransformFunction;
}

const Map: React.FC<MapProps> = ({
  zoom,
  minZoom = 1,
  maxZoom = 20,
  center,
  bounds,
  maxBounds,
  style,
  debug,
  customTransformRequest,
  onMount,
  onMove,
  onRender,
  onIdle,
  onUnmount,
}) => {
  const mapElementRef = useRef<HTMLDivElement>(null);
  const [mapRef, setMapRef] = useState<maplibregl.Map>();

  const mouseMoveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mapRenderTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mapIdleTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!mapRef) return;
    if (customTransformRequest) {
      // important to make SE tiles work
      mapRef.setTransformRequest(customTransformRequest);
    }
  }, [mapRef, customTransformRequest]);

  useEffect(() => {
    if (!mapElementRef.current) return;

    const mapLibreMap = new maplibregl.Map({
      zoom,
      center,
      style,
      minZoom,
      maxZoom,
      bounds,
      maxBounds,
      preserveDrawingBuffer: true,
      attributionControl: false,
      dragPan: true,
      dragRotate: false,
      touchZoomRotate: true,
      container: mapElementRef.current,
      transformRequest: customTransformRequest,
    });

    const handleMapMove = (e: MapMouseEvent) => {
      // if (!e.originalEvent) return;   // not moved by human
      onMove?.({
        zoom: e.target.getZoom(),
        center: e.target.getCenter(),
        mapBounds: e.target.getBounds(),
      });
    };

    const handleMapRender = () => {
      clearTimeout(mapRenderTimeoutRef.current as NodeJS.Timeout);
      mapRenderTimeoutRef.current = setTimeout(() => {
        onRender?.(mapLibreMap);
      }, 20);
    };

    const handleMapIdle = () => {
      clearTimeout(mapIdleTimeoutRef.current as NodeJS.Timeout);
      mapIdleTimeoutRef.current = setTimeout(() => {
        onIdle?.(mapLibreMap);
      }, 400);
    };

    mapLibreMap.on('move', handleMapMove);
    mapLibreMap.on('render', handleMapRender);
    mapLibreMap.on('idle', handleMapIdle);

    mapLibreMap.resize();

    if (debug) {
      mapLibreMap.showTileBoundaries = true;
    }

    mapLibreMap.once('load', () => {
      setMapRef(mapLibreMap);
      onMount?.(mapLibreMap);
    });

    return () => {
      clearTimeout(mouseMoveTimeoutRef.current as NodeJS.Timeout);
      clearTimeout(mapRenderTimeoutRef.current as NodeJS.Timeout);

      mapLibreMap.off('move', handleMapMove);
      mapLibreMap.off('render', handleMapRender);
      mapLibreMap.off('idle', handleMapIdle);
      mapLibreMap.remove();

      onUnmount?.();
    };
  }, []);

  useEffect(() => {
    if (!mapRef) return;
    mapRef.setStyle(style);
  }, [mapRef, style]);

  return (
    <div className="relative h-full w-full">
      <div className="t-0 l-0 z-2 absolute h-full w-full" ref={mapElementRef} />
    </div>
  );
};

export default Map;
