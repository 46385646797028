import React from 'react';

import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useProfiles from '../../hooks/useProfiles';
import ProfilesWatchlist from '../Profiles/ProfilesWatchlist';

const RecentProfiles: React.FC = () => {
  const { activeWorkspace } = useWorkspaceContext();
  const { data, isLoading, isIdle, isError } = useProfiles(activeWorkspace.id, undefined, true);

  if (isLoading || isIdle) return <span className="text-gray-500">Loading maps...</span>;
  if (isError) return <>Something went wrong</>;

  return <ProfilesWatchlist profiles={data} title="Watchlist" />;
};

export default RecentProfiles;
