import { ProjectTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import { Points, Polygons, TableIcon } from './Svgs';

interface Props {
  type: ProjectTypes.LayerType;
  iconColor?: string;
  backgroundClasses?: string;
}

const DatasetTypeIcon: React.FC<Props> = ({ type, backgroundClasses = 'p-2', iconColor = '#fff' }) => {
  let icon;
  switch ((type ?? '').toLowerCase()) {
    case ProjectTypes.LayerType.POINT.toLowerCase():
      icon = <Points fillColor={iconColor} />;
      break;
    case ProjectTypes.LayerType.POLYGON.toLowerCase():
      icon = <Polygons fillColor={iconColor} />;
      break;
    case ProjectTypes.LayerType.TABLE.toLowerCase():
      icon = <TableIcon fillColor={iconColor} />;
      break;
    default:
      icon = <div className="h-[20px] w-[20px] rounded-full text-center text-white">?</div>;
      break;
  }

  return <div className={classNames('rounded-full', backgroundClasses)}>{icon}</div>;
};

export default DatasetTypeIcon;
