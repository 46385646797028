import { LngLatLike, Map, MapGeoJSONFeature, MapMouseEvent, PointLike } from 'maplibre-gl';
import { useEffect, useRef } from 'react';

function useFeaturesUnderMouse(
  map: Map | null | undefined,
  eventType: 'mouseover' | 'mousemove' | 'mousedown' | 'mouseup' | 'click' | 'dblclick',
  handler: (f: MapGeoJSONFeature[], latLong: LngLatLike, point: PointLike, originalEvent: MapMouseEvent) => void,
  sourceLayerIds: string[] = [], // if not specified, take all layers into consideration
  throttleBy = 50
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!map) return;

    const handleEvent = (e: MapMouseEvent) => {
      clearTimeout(timeoutRef.current as NodeJS.Timeout);
      const point = e.point.clone();
      const projectedPoint = map.unproject(point).toArray();

      timeoutRef.current = setTimeout(() => {
        const featuresUnderMouse = map.queryRenderedFeatures(point, { layers: sourceLayerIds });

        handler(featuresUnderMouse, projectedPoint, point, e);
      }, throttleBy);
    };
    map.on(eventType, handleEvent);

    return () => {
      map.off(eventType, handleEvent);
    };
  }, [map, eventType, handler, sourceLayerIds]);
}

export default useFeaturesUnderMouse;
