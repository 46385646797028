export enum MenuOption {
  RENAME,
  DELETE,
  COPY,
  PUBLISH,
  FILTER,
  OPEN_IN_NEW_TAB,
  DATA_ROW_SUBJECT,
  DELETE_ROWS,
}
