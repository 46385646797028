import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React from 'react';
import InfoPopup from './InfoPopup';

interface Props {
  min: number;
  max: number;
  value: number;
  title: string;
  info: string;
  stops: { color?: string; label: string; labelColor?: string; colorPercentage?: string }[];
  valueFormat: ProjectTypes.ValueFormat;
}

const ScoreSlider: React.FC<Props> = ({ min, max, value, stops, title, valueFormat, info = '' }) => {
  const gradient = stops
    .filter((stop) => stop.color != null)
    .map((stop) => `${stop.color} ${stop.colorPercentage ?? ''}`);
  const valPercentage = value != null ? ((value - min) / (max - min)) * 100 : 50;

  return (
    <div>
      <div className="flex justify-between">
        <h5 className="font-semibold">{title}</h5>
        {info && <InfoPopup content={info} title="Popup title" />}
      </div>
      <div className="mt-16 flex flex-col gap-2">
        <div className="relative h-[15px] w-full">
          <div
            className="start-0 absolute z-0 flex h-full w-full"
            style={{ background: `linear-gradient(to right, ${gradient})` }}
          />
          <div
            className="z-2 absolute bottom-0 top-[-20px] w-[1px] border-r-2 border-slate-800 transition-all"
            style={{ left: `${Math.max(Math.min(valPercentage, 100), 0)}%` }}
          >
            <div className="absolute bottom-[100%] left-[-50%] text-[18px] font-bold">
              <div className="absolute translate-y-[-100%] translate-x-[-50%]">
                {value != null ? ValueFormatter.format(value, valueFormat) : 'N/A'}
              </div>
            </div>
          </div>
        </div>

        <div className="flex h-[25px] w-full text-[15px] font-semibold">
          {stops.map((stop) => (
            <div
              key={stop.label}
              className="flex w-full grow justify-center first:justify-start last:justify-end"
              style={{ color: `${stop.labelColor ?? stop.color}` }}
            >
              {stop.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScoreSlider;
