import { PeTypes } from '@platform/types';
import { useQuery, useQueryClient } from 'react-query';
import * as peApi from '../pe.api';

interface Prop {
  datasetId: string;
}

const QUERY_KEY = 'dataset-column-metadata';

const useDatasetColumnMetadata = ({ datasetId }: Prop) => {
  const queryClient = useQueryClient();

  const baseQueryKey = [QUERY_KEY, datasetId];
  const query = useQuery<PeTypes.DatasetColumnMetadata[]>(baseQueryKey, () => peApi.getColumnsMetadata(datasetId));
  const invalidate = (keys: unknown[] = []) => queryClient.invalidateQueries({ queryKey: [...baseQueryKey, ...keys] });

  return { query, invalidate };
};

export default useDatasetColumnMetadata;
