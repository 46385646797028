import { MUIcon } from '@platform/shared/ui';
import { ValueFormatter } from '@platform/utils';
import Highcharts, { Options, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import GroupedButtons from '../shared/GroupedButtons';
import { ChartSerie, SingleChartOptions } from './ChartTypes';

interface Props {
  options: SingleChartOptions;
}

const getSeriesForStackedChart = (data: number[]): SeriesOptionsType => {
  const newSeriesData = data.map((value) => 100 - value);
  const newSerie: SeriesOptionsType = {
    type: 'bar',
    showInLegend: false,
    data: newSeriesData,
    colorByPoint: true,
    colors: ['rgba(0,0,0,0.08)'],
    dataLabels: {
      style: {
        color: 'black',
      },
    },
  };
  return newSerie;
};
export const SingleChart: React.FC<Props> = ({ options }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const firstChartOptions = options.chartData;
  const [selectedFilter, setSelectedFilter] = useState(options.chartData.series[0].name);
  const filters: string[] = firstChartOptions.series.map((s) => s.name);
  const isFilterChart = filters.length > 1 && options.withFilter;
  const handleFilterClick = (category: string) => setSelectedFilter(category);
  const isStacking = options.chartType === 'stacking';
  let chartSeries: SeriesOptionsType[] = [];

  if (isStacking) {
    chartSeries = options.chartData.series.map((serie): SeriesOptionsType => {
      const chartData = { name: serie.name, data: serie.data };

      return {
        type: serie.type,
        showInLegend: false,
        //@ts-ignore
        data: [...chartData.data],
        colorByPoint: serie.colors.length > 1,
        colors: serie.colors,
      };
    });
    if (chartSeries.length === 1) {
      // @ts-ignore
      const newChart = getSeriesForStackedChart(chartSeries[0].data);
      chartSeries.unshift(newChart);
    }
  } else {
    let chartSerie: ChartSerie = {
      type: options.chartData.series[0].type,
      name: options.chartData.series[0].name,
      data: options.chartData.series[0].data,
      colors: options.chartData.series[0].colors,
    };
    if (isFilterChart) {
      const filteredData = options.chartData.series.filter((serie: ChartSerie) => serie.name === selectedFilter);
      chartSerie = filteredData[0];
    }
    chartSeries = [
      {
        type: chartSerie.type,
        showInLegend: false,
        //@ts-ignore
        data: [...chartSerie.data],
        colors: chartSerie.colors,
        colorByPoint: chartSerie.colors.length > 1,
        style: {
          fontWeight: 'light',
          fontSize: '12px',
        },
      },
    ];
  }
  const stacking = isStacking ? (options.chartData.series.length === 1 ? 'percent' : 'normal') : undefined;
  const chartOptions: Options = {
    chart: {
      height: options.height ?? 160,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    tooltip: {
      hideDelay: 0,
      pointFormatter: function () {
        return `${ValueFormatter.format(this.y, options.chartData.valueFormat)}`;
      },
    },
    series: chartSeries,
    xAxis: {
      gridLineWidth: 0,
      categories: options.categories,
      lineColor: 'transparent',
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '0.75rem',
          fontWeight: 'regular',
          color: '#1F2937',
        },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      visible: false,
    },
    plotOptions: {
      bar: { stacking: stacking, pointPadding: 0, groupPadding: 0, pointWidth: 32 },
      column: { stacking: stacking, pointPadding: 0, groupPadding: 0 },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return ValueFormatter.format(this.point.y, options.chartData.valueFormat);
          },
          style: {
            fontWeight: 'light',
            fontSize: '11px',
            textOutline: 'none',
            color: '#1F2937',
          },
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              itemDistance: 10,
            },
          },
        },
      ],
    },
  };
  return (
    <div>
      <div className="flex cursor-pointer items-center justify-between" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="text-sm font-semibold">{options.title}</div>
        <div className="mr-0 rounded-[9999px] bg-gray-100 p-1.5">
          {isExpanded ? <MUIcon name="keyboard_arrow_up" /> : <MUIcon name="keyboard_arrow_down" />}
        </div>
      </div>
      {isExpanded && (
        <>
          <div className="mt-4 flex justify-end">
            <span className="isolate inline-flex rounded-md shadow-sm">
              {isFilterChart && <GroupedButtons onClick={handleFilterClick} values={filters} active={selectedFilter} />}
            </span>
          </div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
      )}
    </div>
  );
};

export default SingleChart;
