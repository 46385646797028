import { MUIcon } from '@platform/shared/ui';
import { ProjectTypes } from '@platform/types';
import { ValueFormatter } from '@platform/utils';
import React, { useState } from 'react';

interface Props {
  value: number | string | null;
  label: string;
  valueFormat: ProjectTypes.ValueFormat;
  color?: string | null;
  title?: string;
}

const NumberChart: React.FC<Props> = ({ value, label, valueFormat, color, title }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  if (value == null) return null;
  return (
    <div>
      <div className="flex cursor-pointer items-center justify-between" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="text-sm font-semibold">{title}</div>
        <div className="mr-0 rounded-[9999px] bg-gray-100 p-1.5">
          {isExpanded ? <MUIcon name="keyboard_arrow_up" /> : <MUIcon name="keyboard_arrow_down" />}
        </div>
      </div>
      {isExpanded && (
        <div className="mt-4 flex gap-3 px-2">
          {color != null && <div className="h-[54px] w-[4px]" style={{ backgroundColor: color }} />}
          <div className="flex flex-col justify-end">
            <span className="text-2xl font-bold text-gray-800">{ValueFormatter.format(value, valueFormat)}</span>
            <span className="font-regular text-sm text-gray-800">{label}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NumberChart;
