import { Buttons, Dropdowns, LayerFiltering, MUIcon, PanelHeader } from '@platform/shared/ui';
import { DatasetTypes, PeTypes } from '@platform/types';
import { Helpers } from '@platform/utils';
import React, { useState } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { v4 as uuidv4 } from 'uuid';
import { useSessionContext } from '../../../../contexts/SessionContext';
import { useWorkspaceContext } from '../../../../contexts/WorkspaceContext';
import { useDatasetColumns } from '../../../../hooks';
import DataFilterEmptyState from '../../../Map/WorkingLayers/Filtering/Filters/DataFilterEmptyState';
import CopyDatasetModal from '../CopyDatasetModal';
import ColumnPickerPanel from './ColumnPickerPanel';

const { VarCharFilter, BooleanFilter, NumericFilter } = LayerFiltering;

interface Props {
  dataset: PeTypes.Dataset;
  filter: RuleGroupType;
  onChange: (newFilter: RuleGroupType) => void;
  onCloseRequest: () => void;
  disabled?: boolean;
}

const DatasetFilter: React.FC<Props> = ({ dataset, filter, onChange, onCloseRequest, disabled }) => {
  const { activeWorkspace } = useWorkspaceContext();
  const { workspaces } = useSessionContext();

  const [tempFilter, setTempFilter] = useState<RuleType | undefined>();
  const [isCopyModalVisible, setIsCopyModalVisible] = useState<boolean>(false);
  const columnsQuery = useDatasetColumns({ datasetId: dataset.id, itemsPerPage: dataset.numberOfColumns });

  const { rules, combinator } = filter;

  if (!columnsQuery.data) {
    return <div className="p-4">Loading...</div>;
  }

  const handleColumnSelection = (colKey: string) => {
    const index = rules.findIndex((r) => r.id === tempFilter?.id);
    const newFilter: RuleGroupType = structuredClone(filter);
    if (index > -1) {
      newFilter.rules[index] = { ...newFilter.rules[index], field: colKey, value: undefined, operator: '=' };
    } else {
      newFilter.rules.push({ id: uuidv4(), operator: '=', value: undefined, field: colKey });
    }

    onChange(newFilter);
    setTempFilter(undefined);
  };

  const handleFilterChange = (rule: RuleType, operator: string, value: unknown) => {
    const updatedFilter = structuredClone(filter);
    const indexOfRule = rules.findIndex((r) => r.id === rule.id);
    updatedFilter.rules[indexOfRule] = {
      ...rule,
      operator,
      value,
    };
    onChange(updatedFilter);
  };

  const handleCombinatorChange = (combinator: string) => {
    const newFilter = structuredClone(filter);
    newFilter.combinator = combinator;
    onChange(newFilter);
  };

  const handleClearAllFilters = () => {
    const newFilter = structuredClone(filter);
    newFilter.rules = [];
    onChange(newFilter);
  };

  const handleRemoveRule = (rule: RuleType | RuleGroupType) => {
    const newFilter = structuredClone(filter);
    newFilter.rules = newFilter.rules.filter((r) => r.id !== rule.id);
    onChange(newFilter);
  };

  const countOfValidRules = filter.rules.filter((rule) => Helpers.checkIfValidRule(rule, null)).length;

  const handleFilterColumn = (rule?: RuleType | RuleGroupType) => {
    const selectedRule = rule as RuleType;
    if (rule) {
      setTempFilter({ id: selectedRule.id, operator: '=', value: undefined, field: '' });
    } else {
      setTempFilter({ id: uuidv4(), operator: '=', value: undefined, field: '' });
    }
  };

  let content;
  if (!filter.rules.length) {
    content = (
      <div className="p-4">
        <DataFilterEmptyState onClick={() => handleFilterColumn()} isDatasetFilter />
      </div>
    );
  } else {
    content = (
      <>
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-2">
            <Dropdowns.Dropdown
              onSelect={(opt) => handleCombinatorChange(opt.value as string)}
              options={[
                { value: 'and', name: 'And' },
                { value: 'or', name: 'Or' },
              ]}
              value={combinator}
              triggerClasses="text-sm text-gray-700 bg-white border border-gray-200 rounded-lg ring-0 gap-3"
            />
          </div>
          <Buttons.Link className="text-primary-600 text-sm font-semibold" onClick={handleClearAllFilters}>
            Clear filters
          </Buttons.Link>
        </div>
        <div className="flex flex-col gap-4 px-4 pb-4">
          {filter.rules.map((rule) => {
            const column = columnsQuery.data.find((x) => x.uuid === (rule as RuleType).field);
            if (!column) return null;
            switch (column.type) {
              case DatasetTypes.DataType.BOOLEAN:
                return (
                  <div key={rule.id} className="flex flex-col gap-3 py-2">
                    <div className="mx-6 my-1 h-[16px] w-[2px] bg-gray-300"></div>
                    <BooleanFilter
                      title={column.title}
                      type={column.type}
                      rule={rule as RuleType}
                      onFilterChange={handleFilterChange}
                      onRemoveRule={handleRemoveRule}
                      onColumnChange={handleFilterColumn}
                    />
                  </div>
                );
              case DatasetTypes.DataType.DOUBLE:
              case DatasetTypes.DataType.INTEGER:
                return (
                  <div key={rule.id} className="flex flex-col gap-3">
                    <div className="mx-6 my-1 h-[16px] w-[2px] bg-gray-300"></div>
                    <NumericFilter
                      title={column.title}
                      type={column.type}
                      rule={rule as RuleType}
                      onFilterChange={handleFilterChange}
                      onRemoveRule={handleRemoveRule}
                      onColumnChange={handleFilterColumn}
                    />
                  </div>
                );

              case DatasetTypes.DataType.TEXT:
                return (
                  <div key={rule.id} className="flex flex-col gap-3">
                    <div className="mx-6 my-1 h-[16px] w-[2px] bg-gray-300"></div>
                    <VarCharFilter
                      key={rule.id}
                      rule={rule as RuleType}
                      title={column.title}
                      type={column.type}
                      columnDiscreteValues={column.properties?.discreteValues?.filter((x: unknown) => x != null) ?? []}
                      onColumnChange={handleFilterColumn}
                      onFilterChange={handleFilterChange}
                      onRemoveRule={handleRemoveRule}
                    />
                  </div>
                );
              default:
                return <div key={rule.id}>Unknown type</div>;
            }
          })}
        </div>
        <div className="px-4">
          <Buttons.Secondary
            className="text-primary-600 h-[36px] w-[148px] gap-2 rounded-[999px] shadow-none ring-gray-200"
            icon={<MUIcon name="add" />}
            onClick={() => setTempFilter({ id: uuidv4(), operator: '=', value: undefined, field: '' })}
          >
            Add criteria
          </Buttons.Secondary>
        </div>
      </>
    );
  }

  return (
    <div className="relative bottom-0 flex min-h-0 w-[448px] flex-col border-l border-gray-200 bg-white">
      <PanelHeader title="Filter dataset" icon={<MUIcon name="tune" />} onClose={onCloseRequest} />
      <div className="flex grow flex-col overflow-y-auto pb-6">{content}</div>
      {filter.rules.length > 0 && (
        <div className="flex items-center justify-center border-t border-gray-200 p-3">
          <Buttons.Primary
            icon={<MUIcon name="magnify_fullscreen" />}
            disabled={disabled || countOfValidRules === 0}
            onClick={() => setIsCopyModalVisible(true)}
            className="rounded-[999px] py-2 px-4 hover:text-white"
          >
            Create new dataset from filter
          </Buttons.Primary>
        </div>
      )}
      {tempFilter && (
        <div className="absolute inset-0 bg-white">
          <ColumnPickerPanel
            dataset={dataset}
            onCloseRequest={() => setTempFilter(undefined)}
            onSelect={handleColumnSelection}
          />
        </div>
      )}
      {isCopyModalVisible && (
        <CopyDatasetModal
          datasetId={dataset.id}
          datasetName={dataset.name}
          workspaces={workspaces}
          filter={filter}
          activeWorkspace={activeWorkspace}
          onCloseRequest={() => setIsCopyModalVisible(false)}
        />
      )}
    </div>
  );
};

export default DatasetFilter;
