import * as GeoJSON from 'geojson';
import shp from 'shpjs';

export default async (
  dbfFile: File,
  shpFile: File | undefined,
  prjFile: File | undefined,
  cpgFile: File | undefined,
  limit?: number
): Promise<{
  data: unknown[][] | null;
  columns: string[] | null;
  featureCollection: GeoJSON.FeatureCollection | null;
  totalRowsCount: number;
  error?: unknown;
}> => {
  try {
    const filesToLoad: Promise<ArrayBuffer | string | null>[] = [readFileAsArrayBuffer(dbfFile)];
    if (shpFile) filesToLoad.push(readFileAsArrayBuffer(shpFile));
    if (prjFile) filesToLoad.push(readFileAsText(prjFile));
    if (cpgFile) filesToLoad.push(readFileAsArrayBuffer(cpgFile));

    const [dbfArrayBuffer, shpArrayBuffer, prjString, cpgArrayBuffer] = await Promise.all(filesToLoad);

    // dbf is mandatory
    if (!dbfArrayBuffer) {
      return { data: null, columns: null, error: 'dbf not read', totalRowsCount: -1, featureCollection: null };
    }
    const dbfData: GeoJSON.GeoJsonProperties[] = shp.parseDbf(
      dbfArrayBuffer as ArrayBuffer,
      cpgArrayBuffer as ArrayBuffer
    );

    const columns = Object.keys(dbfData[0] ?? {});

    const data: unknown[][] = dbfData.slice(0, limit ?? dbfData.length).map((item) =>
      columns.map((col) => {
        const value = item ? item[col] : null;
        return Number.isNaN(value) ? null : value;
      })
    );

    let featureCollection: GeoJSON.FeatureCollection | null = null;
    if (shpArrayBuffer) {
      featureCollection = shp.combine([shp.parseShp(shpArrayBuffer as ArrayBuffer, prjString as string), dbfData]);
    }

    return { columns, data, totalRowsCount: dbfData.length, featureCollection };
  } catch {
    return { data: null, columns: null, error: 'unknown error', totalRowsCount: -1, featureCollection: null };
  }
};
const readFileAsArrayBuffer = (file: File) =>
  new Promise<ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = () => {
      resolve(null);
    };
    reader.readAsArrayBuffer(file);
  });

const readFileAsText = (file: File) =>
  new Promise<string | null>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      resolve(null);
    };
    reader.readAsText(file);
  });
