import { Spinner } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as peApi from '../../../pe.api';
import Report from '../Report';

interface Props {
  enabled: boolean;
  datasetInsightsId: string;
  workingLayer: ProjectTypes.WorkingLayerSpec;
  geoFeatureSelection: ProjectTypes.GeoFeatureSelection[];
}

const WorkingLayerInsights: React.FC<Props> = ({ enabled, datasetInsightsId, geoFeatureSelection, workingLayer }) => {
  const [reportData, setReportData] = useState<PeTypes.Report | undefined>();
  const { filter } = workingLayer;

  const summarizeQuery = useQuery(
    ['working-layer-summarize', datasetInsightsId, geoFeatureSelection],
    () =>
      peApi.getCalculationData({
        datasetInsightsId,
        dataFilter: filter,
      }),
    { enabled: false }
  );

  useEffect(() => {
    // we use this as kind of buffer, so reports gets rerendered once new data is loaded
    if (summarizeQuery.data) setReportData(summarizeQuery.data);
  }, [summarizeQuery.data]);

  return (
    <div className="relative flex flex-col">
      {(summarizeQuery.isLoading || summarizeQuery.isIdle) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-[2] flex justify-center bg-white/10 pt-12 backdrop-blur transition-all">
          <Spinner />
        </div>
      )}

      {reportData && <Report reportData={reportData} />}
    </div>
  );
};

export default WorkingLayerInsights;
