import dayjs from 'dayjs';
import React, { useContext, useMemo } from 'react';

import { DAYS } from '../../constants';
import DatepickerContext from '../../contexts/DatepickerContext';
import { shortString, ucFirst } from '../../helpers';

const Week: React.FC = () => {
  const { i18n, startWeekOn } = useContext(DatepickerContext);
  //loadLanguageModule(i18n);
  const startDateModifier = useMemo(() => {
    if (startWeekOn) {
      switch (startWeekOn) {
        case 'mon':
          return 1;
        case 'tue':
          return 2;
        case 'wed':
          return 3;
        case 'thu':
          return 4;
        case 'fri':
          return 5;
        case 'sat':
          return 6;
        case 'sun':
          return 0;
        default:
          return 0;
      }
    }
    return 0;
  }, [startWeekOn]);

  return (
    <div className="grid grid-cols-7 border-gray-300 py-2">
      {DAYS.map((item) => (
        <div key={item} className="text-center font-['Inter'] text-sm font-normal leading-normal text-gray-600">
          {ucFirst(
            shortString(
              dayjs(`2022-11-${6 + (item + startDateModifier)}`)
                .locale(i18n)
                .format('ddd'),
              1
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default Week;
