import { INotification, useSnackbars } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import { Loader } from '../components/shared';
import { useWorkspaces } from '../hooks';
import useWebSocket from '../hooks/useWebSocket';
import { generateLink } from '../LinkGenerator';
import * as peApi from '../pe.api';

interface ContextState {
  isAuthenticated: boolean;
  isAnonymousAccess: boolean;
  jwtToken: string | null;
  setJwtToken: (token: string) => void;
  logout: () => void;
  workspaces: PeTypes.Workspace[];
  user: PeTypes.User | undefined;
  notify: (n: INotification) => () => void;
  realtimeSocket: Socket | null;
}

const DEFAULT_VALUE: ContextState = {
  user: undefined,
  isAuthenticated: false,
  isAnonymousAccess: false,
  jwtToken: null,
  workspaces: [],
  realtimeSocket: null,
  logout: () => ({}),
  notify: () => () => ({}),
  setJwtToken: () => ({}),
};

const ANONYMOUS_ACCESS_TOKEN = 'aat';
const JWT_TOKEN = process.env.NX_JWT_TOKEN as string;
export const ANONYMOUS_ACCESS_TOKEN_STORAGE_ITEM_ID = ANONYMOUS_ACCESS_TOKEN;

const SessionContext = React.createContext<ContextState>(DEFAULT_VALUE);

interface Props {
  children: React.ReactNode;
}

const urlParams = new URLSearchParams(window.location.search);
const anonymousAccessToken = urlParams.get(ANONYMOUS_ACCESS_TOKEN);
const isAnonymousAccess = !!anonymousAccessToken;
let storedJwt: string | null = localStorage.getItem(JWT_TOKEN);

if (isAnonymousAccess) {
  sessionStorage.setItem(ANONYMOUS_ACCESS_TOKEN, anonymousAccessToken);
} else {
  storedJwt = localStorage.getItem(JWT_TOKEN);
}

export const SessionContextProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [jwtToken, setJwtToken] = useState<string | null>(storedJwt || anonymousAccessToken);
  const { socket: realtimeSocket, initialize: initializeSocket, close: closeSocket } = useWebSocket();

  const userQuery = useQuery(['session', jwtToken], () => peApi.me(), {
    enabled: jwtToken != null,
    staleTime: Infinity,
    onError: () => {
      localStorage.removeItem(JWT_TOKEN);
      localStorage.removeItem(ANONYMOUS_ACCESS_TOKEN);
      navigate(generateLink('login'));
    },
  });

  const workspacesQuery = useWorkspaces(userQuery.data?.id ?? -1);

  const [notify, Notifications] = useSnackbars();

  useEffect(() => {
    // clear the AAT token so it can't be reused
    const handleBeforeUnload = () => sessionStorage.removeItem(ANONYMOUS_ACCESS_TOKEN);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (jwtToken) {
      // Save JWT in localStorage form the memory
      localStorage.setItem(JWT_TOKEN, jwtToken);
    } else {
      localStorage.removeItem(JWT_TOKEN);
    }
  }, [jwtToken]);

  useEffect(() => {
    if (userQuery.data && jwtToken) {
      // handle socket connection
      const realtimeSocket = initializeSocket(jwtToken);
      realtimeSocket.emit('joinRoom', `user:${userQuery.data.id}`);
    }

    return () => {
      closeSocket();
    };
  }, [userQuery.data, jwtToken]);

  const logoutMutation = useMutation(() => peApi.logout(), {
    onSuccess: () => setJwtToken(null),
  });

  const logout = useCallback(() => logoutMutation.mutate(), [logoutMutation]);

  if (userQuery.isLoading) return <Loader />;
  if (userQuery.isError) {
    navigate(generateLink('login'));
  }

  return (
    <SessionContext.Provider
      value={{
        user: userQuery.data,
        workspaces: workspacesQuery.data ?? [],
        isAuthenticated: userQuery.data != null,
        isAnonymousAccess: userQuery.data?.id === -1,
        logout,
        setJwtToken,
        jwtToken,
        notify,
        realtimeSocket,
      }}
    >
      {children}
      <div className="fixed bottom-8 left-20 z-[9999]">
        <Notifications />
      </div>
    </SessionContext.Provider>
  );
};

export const useSessionContext = (): ContextState => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error(`useSessionContext must be used within a SessionContextProvider`);
  }
  return context;
};
