import { AbacusTypes } from '@platform/types';
import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

interface Props {
  api: (datasetId: string, payload: AbacusTypes.DownloadAbacusDataRequest) => Promise<AxiosResponse>;
}

const useAbacusDatasetDownload = ({ api }: Props) =>
  useMutation(
    ({ datasetId, payload }: { datasetId: string; payload: AbacusTypes.DownloadAbacusDataRequest }) =>
      api(datasetId, payload),
    {
      onSuccess: (response) => {
        // Create an object URL and trigger the download
        const blob = new Blob([response.data], { type: 'application/octet-stream' });

        // Extract the filename from the Content-Disposition header
        const contentDispositionHeader = response.headers['content-disposition'];
        const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);

        let filename = '';
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}`;
        a.click();
        URL.revokeObjectURL(url);
      },
    }
  );

export default useAbacusDatasetDownload;
