import { MUIcon, Toggles } from '@platform/shared/ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
  hasToggle?: boolean;
  expandedByDefault?: boolean;
  hint?: string;
  containerClasses?: string;
  iconClasses?: string;
  isToggleActive?: boolean;
  titleClasses?: string;
  hintClasses?: string;
  disabledMessage?: string | null;
  onToggle?: (value: boolean) => void;
  footer?: React.ReactNode;
}

const ExpandableContainer: React.FC<Props> = ({
  title,
  titleClasses,
  hintClasses,
  hint,
  children,
  hasToggle = true,
  containerClasses = '',
  iconClasses = '',
  expandedByDefault = false,
  isToggleActive,
  disabledMessage,
  footer,
  onToggle,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expandedByDefault);
  const isToggleActiveWithToggle = hasToggle && isToggleActive;
  const showIcon = !hasToggle || isToggleActiveWithToggle;
  useEffect(() => {
    if (isToggleActive != null) {
      isToggleActive ? setIsExpanded(true) : setIsExpanded(false);
    }
  }, [isToggleActive]);

  return (
    <div
      className={classNames('flex w-full flex-col gap-2 font-semibold', containerClasses, {
        'pointer-events-none opacity-70': disabledMessage,
      })}
    >
      <div className="flex gap-2">
        {hasToggle && (
          <Toggles.Toggle
            checked={isToggleActive}
            onChange={onToggle}
            toggleOuterClasses="w-[36px] h-[16px]"
            toggleInnerClasses="w-[12px] h-[12px]"
          />
        )}
        <button
          className="flex w-full items-center justify-between gap-6 py-2 hover:cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          disabled={hasToggle && !isToggleActive}
        >
          <div className={classNames('flex text-sm text-gray-800', titleClasses)}>{title}</div>
          {disabledMessage && <p className="w-full text-right text-xs">{disabledMessage}</p>}
          <div className="flex items-center gap-6">
            {!isExpanded && <p className={classNames('font-regular text-xs text-gray-500', hintClasses)}>{hint}</p>}
            {showIcon && (
              <MUIcon className={iconClasses} name={!isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
            )}
          </div>
        </button>
      </div>
      {isExpanded && children}
      {isExpanded && footer}
    </div>
  );
};

export default ExpandableContainer;
