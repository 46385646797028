import { Modal } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useEffect, useState } from 'react';
import EmptyCatalog from '../../Datasets/EmptyCatalog';
import useDatasetList from '../../Datasets/useDatasetList';
import SelectableDataset from './SelectableDataset';

interface Props {
  onSelect: (datasets: PeTypes.DatasetListItem[]) => void;
  onCloseRequest: () => void;
}

const WorkingLayerPickerModal: React.FC<Props> = ({ onCloseRequest, onSelect }) => {
  const [selectedDatasets, setSelectedDatasets] = useState<PeTypes.DatasetListItem[]>([]);
  const datasetsTypes = [ProjectTypes.LayerType.POLYGON, ProjectTypes.LayerType.POINT];
  const { isEmptyCatalog, searchControls, noHits, datasets, paging } = useDatasetList(datasetsTypes);

  useEffect(() => {
    // if input datasets changed (after filters were applied or search term...), clear the selection
    setSelectedDatasets([]);
  }, [datasets]);

  const handleToggleDataset = (dataset: PeTypes.DatasetListItem) => {
    const updatedSelection = [...selectedDatasets];
    const layerIndex = selectedDatasets.findIndex((layer) => layer.id === dataset.id);

    if (layerIndex > -1 && selectedDatasets.length > 0) {
      updatedSelection.splice(layerIndex, 1);
    } else {
      updatedSelection.push(dataset);
    }
    setSelectedDatasets(updatedSelection);
  };

  const handleAddLayers = () => {
    onSelect(selectedDatasets);
    setSelectedDatasets([]);
    onCloseRequest();
  };

  return (
    <Modal
      title="Add layer to map"
      onDone={handleAddLayers}
      okLabel="Add"
      className="w-[1000px]"
      okDisabled={!selectedDatasets.length}
      onCloseRequest={onCloseRequest}
      okClassNames="w-28 px-6"
    >
      <div className="-mx-6 flex flex-col">
        <div className="w-full border-t py-4 px-6">{searchControls}</div>
        <div className="flex h-[400px] flex-col overflow-y-auto bg-gray-50">
          {isEmptyCatalog && (
            <div className="flex h-full w-full items-center justify-center py-2">
              <EmptyCatalog />
            </div>
          )}
          <div className="flex min-h-0 w-full flex-col">
            {noHits}
            {datasets.map((dataset) => (
              <SelectableDataset
                key={dataset.id}
                dataset={dataset}
                active={selectedDatasets.includes(dataset)}
                onClick={handleToggleDataset}
              />
            ))}
            {paging}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WorkingLayerPickerModal;
