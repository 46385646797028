import { Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useDatasetPublishInfo } from '../../../hooks';
import * as MenuTypes from './Menu/menu.types';
import useCopyOption from './Menu/useCopyOption';
import useDataRowSubjectOption from './Menu/useDataRowSubjectOption';
import useDeleteOption from './Menu/useDeleteOption';
import usePublishOption from './Menu/usePublishOption';
import useRenameOption from './Menu/useRenameOption';

interface Props {
  dataset: PeTypes.Dataset;
  onShowFilterableVars: () => void;
}

const MoreDatasetOptionsButton: React.FC<Props> = ({ dataset, onShowFilterableVars }) => {
  const [action, setAction] = useState<MenuTypes.MenuOption | null>(null);
  const isTable = dataset.type === ProjectTypes.LayerType.TABLE;

  const handleCloseRequest = () => setAction(null);

  const [publishOption, publishModal] = usePublishOption(dataset.id, action, handleCloseRequest);
  const { canBePublished } = useDatasetPublishInfo(dataset.id);

  const [renameOption, renameModal] = useRenameOption(dataset.id, dataset.name, action, handleCloseRequest);
  const [copyOption, copyModal] = useCopyOption(dataset.id, dataset.name, action, handleCloseRequest);
  const [dataRowSubjectOption, dataRowSubjectModal] = useDataRowSubjectOption(
    dataset.id,
    dataset.rowSubject,
    action,
    handleCloseRequest
  );
  const [deleteOption, deleteModal] = useDeleteOption(dataset.id, action, handleCloseRequest);

  const options: Dropdowns.DropdownOption[] = [
    renameOption,
    copyOption,
    {
      value: MenuTypes.MenuOption.FILTER,
      name: 'Filterable Variables',
      icon: <MUIcon name="rubric" />,
      additionalStyles: 'text-sm border-t border-gray-200',
    },
    dataRowSubjectOption,
    deleteOption,
  ];

  const DROPDOWN_OPTIONS = isTable ? options : [publishOption, ...options];

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    if (opt.value === MenuTypes.MenuOption.FILTER) {
      onShowFilterableVars();
      return;
    }
    setAction(opt.value as MenuTypes.MenuOption);
  };

  return (
    <>
      <div className="relative">
        <Dropdowns.Dropdown
          customLabel="More"
          prefixIcon={<MUIcon name="more_horiz" />}
          iconClose={null}
          icon={null}
          options={DROPDOWN_OPTIONS}
          onSelect={onSelectDropdownOption}
          hAlignment="right"
          menuClasses="w-[256px]"
          triggerClasses="rounded-full gap-2 shadow-none px-3 py-2"
        />
        {canBePublished && !isTable && (
          <div className="absolute top-0 right-0 h-[10px] w-[10px] rounded-full bg-rose-700" />
        )}
      </div>
      {deleteModal}
      {renameModal}
      {copyModal}
      {publishModal}
      {dataRowSubjectModal}
    </>
  );
};

export default MoreDatasetOptionsButton;
