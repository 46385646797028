import { Disclosure } from '@headlessui/react';
import { MUIcon } from '@platform/shared/ui';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { generateLink } from '../../LinkGenerator';
import { UserMenu } from '../shared';
import WorkspaceMenu from './WorkspaceMenu';

export default function WorkspaceHeader() {
  const { activeWorkspace } = useWorkspaceContext();
  return (
    <Disclosure as="nav" className="select-none border-b bg-white" style={{ zIndex: 1 }}>
      <div className="mx-auto flex max-w-7xl justify-between">
        <div className="flex h-16">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <WorkspaceMenu />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-12">
          <div className="flex items-center justify-center gap-2">
            <MUIcon name="reply" className="text-gray-500" />
            <Link
              to={generateLink('workspace', { workspaceId: activeWorkspace.id })}
              className="text-sm font-semibold text-gray-700 underline underline-offset-2"
            >
              Back to dashboard
            </Link>
          </div>
          <UserMenu />
        </div>
      </div>
    </Disclosure>
  );
}
