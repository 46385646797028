import { PeTypes } from '@platform/types';
import React from 'react';
import WorkspaceImage from './WorkspaceImage';
import WorkspaceName from './WorkspaceName';

interface Props {
  activeWorkspace: PeTypes.Workspace;
  isAdmin: boolean;
}

const WorkspaceSettings: React.FC<Props> = ({ activeWorkspace, isAdmin }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-10">
      <div className="flex w-full flex-col justify-start gap-3">
        <span className="text-2xl font-semibold text-gray-900">About this Workspace</span>
        <span className="text-sm text-gray-700">
          Adjust workspace-wide settings and preferences here. You can also manage users and roles.
        </span>
      </div>
      <div className="w-full rounded-xl border border-gray-200 bg-white">
        <WorkspaceName isAdmin={isAdmin} activeWorkspace={activeWorkspace} />
        <WorkspaceImage isAdmin={isAdmin} activeWorkspace={activeWorkspace} />
      </div>
    </div>
  );
};

export default WorkspaceSettings;
