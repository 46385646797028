import { Dropdowns, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import * as peApi from '../../../pe.api';
import AddDatasetColumnModal from './AddDatasetColumnModal';
import CombineDatasetColumnsModal from './CombineDatasetColumnsModal';

interface Props {
  datasetId: string;
  disabled?: boolean;
}

enum ACTION {
  ADD_COLUMN,
  ADD_ROW,
  COMBINE_COLUMNS,
}

const OperateOnDatasetMenu: React.FC<Props> = ({ datasetId, disabled }) => {
  const [action, setAction] = useState<ACTION | null>(null);
  const addRowMutation = useMutation({
    mutationFn: () => peApi.addDatasetRow(datasetId, {}),
  });

  const DROPDOWN_OPTIONS: Dropdowns.DropdownOption[] = [
    {
      value: ACTION.ADD_COLUMN,
      name: 'Add column',
      icon: <MUIcon name="splitscreen_vertical_add" />,
      additionalStyles: 'text-sm',
    },
    {
      value: ACTION.COMBINE_COLUMNS,
      name: 'Combine columns',
      icon: <MUIcon name="cell_merge" />,
      additionalStyles: 'text-sm',
      dividerAfter: true,
    },
    {
      value: ACTION.ADD_ROW,
      name: 'Add row',
      icon: <MUIcon name="splitscreen_add" />,
      additionalStyles: 'text-sm',
    },
  ];

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    if (opt.value === ACTION.ADD_ROW) {
      addRowMutation.mutate();
      return;
    }
    setAction(opt.value as ACTION);
  };

  return (
    <>
      <Dropdowns.Dropdown
        disabled={disabled}
        icon={<MUIcon name="add" />}
        options={DROPDOWN_OPTIONS}
        onSelect={onSelectDropdownOption}
        hAlignment="left"
        triggerClasses="w-[32px] h-[32px] rounded-full bg-gray-200 hover:bg-gray-100 shadow-none ring-0 justify-center"
      />
      {action === ACTION.ADD_COLUMN && (
        <AddDatasetColumnModal datasetId={datasetId} onCloseRequest={() => setAction(null)} />
      )}
      {action === ACTION.COMBINE_COLUMNS && (
        <CombineDatasetColumnsModal datasetId={datasetId} onCloseRequest={() => setAction(null)} />
      )}
    </>
  );
};

export default OperateOnDatasetMenu;
