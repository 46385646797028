import { MUIcon } from '@platform/shared/ui';
import React from 'react';

interface Props {
  email: string;
}

const ResetPasswordConfirmation: React.FC<Props> = ({ email }) => {
  return (
    <div className="flex h-full flex-col gap-10 py-10">
      <MUIcon name="check_circle" className="px-10 text-[30px] text-green-600" />
      <div className="px-10 text-2xl font-bold">Check your email</div>
      <div className="flex w-full flex-col gap-10">
        <p className="px-10 text-base text-gray-600">
          {`We've sent an email to ${email} with instructions to reset your password.`}
        </p>
        <p className="px-10 text-base text-gray-600">
          Didn't receive anything? Check in spam and other folders, or resend the email if needed.
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordConfirmation;
